import {
  Grid,
  Dialog,
  IconButton,
  Tooltip,
  Checkbox,
  Box,
  Typography,
} from "@mui/material";

import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { Close } from "@mui/icons-material";
import CFTypography from "components/CFTypography";
import CFBox from "components/CFBox";
import CFButton from "components/CFButton";
import TillageCalculatorHelp from "assets/cFarmImages/HelpImages/tillageCalculatorHelp.webp";
import { preloadImage } from "utils/generic";
import CFStep from "components/CFarmParts/CFStep";
import colors from "assets/theme/base/colors";

export default function TilalgeImplementCalculator({
  isOpen,
  setIsOpen,
  tillageImplements,
  tillageIntensities,
  setPrePlantingTillage,
  setPostHarvestTillage,
  setBoth,
  hidePlanting,
}) {
  preloadImage(TillageCalculatorHelp);
  // need to track whats checked
  // either show the live calculation as we go or at the end
  // then need to set the actual tillage on the ce when user clicks button
  const [plantingList, setPlantingList] = useState({});
  const [harvestList, setHarvestList] = useState({});
  const [preTillage, setPreTillage] = useState({ id: 0, name: "none" });
  const [postTillage, setPostTillage] = useState({ id: 0, name: "none" });
  const [prePlantingTillageString, setprePlantingTillageString] =
    useState("none");
  const [postHarvestTillageString, setpostHarvestTillageString] =
    useState("none");

  const populateIntensity = () => {
    let setpre = false;
    let setpost = false;
    if (preTillage && preTillage.id !== 0) {
      setpre = true;
    }
    if (postTillage && postTillage.id !== 0) {
      setpost = true;
    }

    if (setpre && setpost) {
      setBoth(preTillage, postTillage);
    } else if (setpre) {
      setPrePlantingTillage(preTillage);
    } else if (setpost) {
      setPostHarvestTillage(postTillage);
    }

    setIsOpen(false);
  };
  const RecalculateTilalgeStrings = () => {
    const plantingImplements = tillageImplements.filter(
      (x) => plantingList[String(x.id)] === true
    );
    const harvestImplements = tillageImplements.filter(
      (x) => harvestList[String(x.id)] === true
    );

    let plantingScore = 0;
    if (plantingImplements.length > 0) {
      plantingScore = plantingImplements
        .map((i) => i.score)
        .reduce((a, b) => a + b);
    }

    let harvestScore = 0;
    if (harvestImplements.length > 0) {
      harvestScore = harvestImplements
        .map((i) => i.score)
        .reduce((a, b) => a + b);
    }

    let newPlantString = "None";
    let newHarvString = "None";
    let prePlantTillage = preTillage;
    let postHarvTillage = postTillage;

    let maxIntensity = tillageIntensities[0];

    for (let i = 0; i < tillageIntensities.length; i += 1) {
      const intensity = tillageIntensities[i];
      if (
        plantingScore <= intensity.upperScoreRange &&
        plantingScore > intensity.lowerScoreRange
      ) {
        newPlantString = "Not NONE";
        prePlantTillage = intensity;
      }
      if (
        harvestScore <= intensity.upperScoreRange &&
        harvestScore > intensity.lowerScoreRange
      ) {
        newHarvString = "Not NONE";
        postHarvTillage = intensity;
      }
      if (maxIntensity.upperScoreRange < intensity.upperScoreRange) {
        maxIntensity = intensity;
      }
    }

    if (plantingScore > maxIntensity.upperScoreRange) {
      newPlantString = "Not NONE";
      prePlantTillage = maxIntensity;
    }
    if (harvestScore > maxIntensity.upperScoreRange) {
      newHarvString = "Not NONE";
      postHarvTillage = maxIntensity;
    }

    setPreTillage(prePlantTillage);
    setPostTillage(postHarvTillage);
    setprePlantingTillageString(newPlantString);
    setpostHarvestTillageString(newHarvString);
  };

  const setPrePlanting = (check, cell) => {
    const oldlist = { ...plantingList };
    oldlist[String(cell.row.id)] = !oldlist[String(cell.row.id)];

    setPlantingList({ ...oldlist });
  };
  const setPostHarvest = (check, cell) => {
    const oldlist = { ...harvestList };
    oldlist[String(cell.row.id)] = !oldlist[String(cell.row.id)];
    setHarvestList({ ...oldlist });
  };
  useEffect(() => {
    RecalculateTilalgeStrings();
  }, [plantingList, harvestList]);

  function title(str) {
    return str.replace(/(^|\s)\S/g, (t) => t.toUpperCase());
  }
  const renderTitleCase = (cell) => {
    const form = title(cell.formattedValue);
    return form;
  };
  const renderCell = (cell) => (
    <CFBox>
      {cell.field === "planting" && (
        <Checkbox
          checked={
            plantingList[cell.row.id]
              ? plantingList[cell.row.id] === true
              : false
          }
          onChange={(e) => {
            setPrePlanting(e.target.checked, cell);
          }}
        />
      )}
      {cell.field === "harvest" && (
        <Checkbox
          checked={
            harvestList[cell.row.id] ? harvestList[cell.row.id] === true : false
          }
          onChange={(e) => {
            setPostHarvest(e.target.checked, cell);
          }}
        />
      )}
    </CFBox>
  );
  const renderNull = (cell) => (
    <CFBox
      sx={{ backgroundColor: "lightgray", width: "100%", height: "100%" }}
    />
  );

  const columns = [
    {
      field: "name",
      headerName: "Implement",
      flex: 2,
      renderCell: renderTitleCase,
      headerAlign: "center",
      headerClassName: "darkheader",
      disableColumnMenu: true,
    },
    {
      field: "planting",
      headerName: "Planting",
      flex: 1,

      headerAlign: "center",
      align: "center",
      headerClassName: hidePlanting ? "nullheader" : "darkheader",
      sortable: false,
      renderCell: hidePlanting ? renderNull : renderCell,
      disableColumnMenu: true,
    },
    {
      field: "harvest",
      headerName: "Harvest",
      flex: 1,

      headerAlign: "center",
      align: "center",
      headerClassName: "darkheader",
      renderCell,
      sortable: false,
      disableColumnMenu: true,
    },
  ];

  return (
    <Dialog
      open={isOpen}
      fullScreen
      sx={{ padding: "3em", maxWidth: "90ch", zIndex: 5 }}
      onClose={() => setIsOpen(false)}
    >
      <CFBox sx={{ paddingLeft: "1em", paddingTop: "1em" }}>
        <CFStep
          stepLabel="Tillage Intensity Calculator"
          helpText={
            <CFBox
              sx={{ overflow: "auto", height: "600px", maxHeight: "60vh" }}
            >
              <p>
                To use the Tillage Intensity Calculator, please select any
                planting, harvest and tillage events that occur in the
                planting/harvest season. The{" "}
                <em>Pre-Planting/Planting Season Tillage System Intensity</em>
                and{" "}
                <em>
                  Post-Harvest/Harvest Season Tillage System Intensity
                </em>{" "}
                will update at the bottom of the calculator window as each
                implement is added. Selecting “Populate Intensity” will populate
                the intensities under the Tillage System Intensity and Field
                Operations section.
              </p>
              <br />
              <p>
                Per Equation 3-13 in the{" "}
                <a href="https://www.usda.gov/oce/entity-scale-ghg-methods">
                  USDA Inventory Methods document
                </a>
                , the Tillage System Intensity Calculator will calculate the
                system intensity based on the mixing efficiency and tillage
                depth (cm) for all implements used. If users are unable to
                locate their exact implement from the list, please refer to
                Table 3-15 for each implement&apos;s respective mixing
                efficiency and tillage depth and select the closest implement.
              </p>
              <br />
              <p>
                For crops with multiple harvest events in a year, users should
                add all implements used in any harvest event throughout the crop
                season. A single tillage system intensity will be added
                following the final harvest event of the year.
              </p>
              <img
                src={TillageCalculatorHelp}
                width="100%"
                alt="Tillage Calculator Help"
              />
            </CFBox>
          }
        />
      </CFBox>

      <IconButton
        onClick={() => setIsOpen(false)}
        style={{ position: "absolute", right: 0, margin: ".5em", padding: 0 }}
      >
        <Tooltip title="Close without applying">
          <Close />
        </Tooltip>
      </IconButton>

      <CFBox sx={{ padding: "2em", height: "100vh", overflowY: "none" }}>
        <Grid container sx={{}}>
          <Grid item container xs={12}>
            Select any planting, harvest and tillage events that occur in the
            planting/harvest season
          </Grid>

          <Grid
            item
            container
            xs={12}
            sx={{
              height: "50vh",
              "& .darkheader": {
                backgroundColor: colors.primary.main,
                borderColor: "black",
                color: colors.text.light,
              },
              "& .nullheader": {
                backgroundColor: "lightgray",
                borderColor: "black",
                color: "grey",
              },
            }}
          >
            <DataGrid
              showColumnVerticalBorder
              showCellVerticalBorder
              rows={tillageImplements}
              columns={columns}
              // onColumnHeaderClick={testColClick}
              pageSizeOptions={[5]}
              disableRowSelectionOnClick
            />
          </Grid>
        </Grid>
        <CFTypography>
          Based on the implements added we suggest the following tillage
          intensities
        </CFTypography>
        {prePlantingTillageString === "None" && (
          <Typography component="div">{postHarvestTillageString}</Typography>
        )}
        {prePlantingTillageString !== "None" && (
          <Typography component="div">
            -Pre-Planting/Planting:{" "}
            <Box fontWeight="fontWeightMedium" display="inline">
              {preTillage.name}
              {/* {preTillage.name} STIR {preTillage.lowerScoreRange}-{preTillage.upperScoreRange} */}
            </Box>
          </Typography>
        )}
        {postHarvestTillageString === "None" && (
          <Typography component="div">{postHarvestTillageString}</Typography>
        )}
        {postHarvestTillageString !== "None" && (
          <Typography component="div">
            -Harvest/Post-Harvest:{" "}
            {postTillage && postTillage.name !== "none" && (
              <Box fontWeight="fontWeightMedium" display="inline">
                {postTillage.name}
                {/* {postTillage.name} STIR {postTillage.lowerScoreRange}-{postTillage.upperScoreRange} */}
              </Box>
            )}
          </Typography>
        )}

        <Grid container py={2} px={2} justifyContent="space-between">
          <CFButton color="secondary" onClick={() => setIsOpen(false)}>
            Cancel
          </CFButton>
          <CFButton color="progress" onClick={() => populateIntensity()}>
            Populate Intensity
          </CFButton>
        </Grid>
      </CFBox>
    </Dialog>
  );
}
