/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/prop-types */
/* eslint-disable arrow-body-style */
/* eslint-disable react/button-has-type */
/* eslint-disable react/function-component-definition */
/* eslint-disable import/prefer-default-export */
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { BsCalendar3, BsFillPinMapFill } from "react-icons/bs";
import { TbChartTreemap } from "react-icons/tb";
import { HiDocumentReport } from "react-icons/hi";
import { GiCorn as CropFutureImage } from "react-icons/gi";
import AgroForImage from "assets/cFarmImages/Projects/AgroForestryP.png";
import AnimalAg from "assets/cFarmImages/Projects/AnimalAgP.png";
import CropImage from "assets/cFarmImages/Projects/CroplandP.png";
import ForestryImage from "assets/cFarmImages/Projects/ForestryP.png";
import colors from "assets/theme/base/colors";
import CFBox from "components/CFBox";
import CFTypography from "components/CFTypography";
import styles from "./Roadmap.module.css";

const imageWrap = (icon) => (
  <img
    src={icon}
    alt="icon"
    style={{ padding: ".25rem", width: "2.2rem", height: "2.2rem" }}
  />
);

const reactIconProps = {
  color: colors.slate.main,
  size: 35,
  style: { padding: ".5rem" },
};

const reactIconDarkProps = {
  color: colors.svgAdjusted.main,
  size: 35,
  style: { padding: ".5rem" },
};

const stageStateProps = {
  state: {
    // completed: true,
    // active: true,
    // disabled: true,
  },
  tooltip: "Please complete Project Timeline",
};

export const timelineProps = [
  {
    label: "Projects",
    to: "/projects",
    element: <div>project</div>,
    icon: <BsCalendar3 {...reactIconProps} />,
    ...stageStateProps,
    state: { active: null, disabled: false, completed: true },
  },
];
export const reportProps = [
  {
    ...stageStateProps,
    label: "Report",
    to: "/report",
    element: <div>report</div>,
    icon: (
      <HiDocumentReport {...reactIconProps} style={{ padding: ".25rem" }} />
    ),
    tooltip: "Please complete at least one Activity",
  },
];

export const activityProps = [
  {
    id: 10,
    stage: 1,
    state: {},
    label: "Field Location",
    to: "/map-croplands",
    element: <div>field-l</div>,
    icon: <TbChartTreemap {...reactIconProps} style={{ padding: ".25rem" }} />,
  },
  {
    id: 10,
    activity_type: "AG",
    stage: 2,
    src: CropImage,
    state: {},
    label: "Field Management",
    to: "/croplands",
    element: <div>field-m</div>,
    tooltip: "Please complete Field Location",
  },
  {
    id: 10,
    activity_type: "AG",
    stage: 3,
    icon: (
      <CropFutureImage {...reactIconDarkProps} style={{ padding: ".25rem" }} />
    ),
    state: {},
    label: "Scenario Management",
    to: "/croplandsScenario",
    element: <div>field-s</div>,
    tooltip: "Please complete Field Management",
  },
  {
    id: 12,
    activity_type: "ENGY_USE",
    stage: 1,
    state: {},
    label: "On-Farm Energy Usage",
    to: "/home/energy-use",
    element: <div>energy-</div>,
  },
  {
    id: 212320,
    src: AnimalAg,
    stage: 1,
    state: {},
    activity_type: "LIVESTCK",
    label: "Animal Agriculture",
    to: "/animal-agriculture",
    element: <div>animal-</div>,
  },
  {
    id: 13,
    activity_type: "AGRO",
    stage: 1,
    state: {},
    src: AgroForImage,
    label: "Agroforestry",
    to: "/agroforestry",
    element: <div>agrofor</div>,
  },
  {
    id: 14,
    activity_type: "FOR",
    stage: 1,
    state: {},
    src: ForestryImage,
    icon: <BsFillPinMapFill {...reactIconProps} />,
    label: "Stand Location",
    to: "/map-forestry",
    element: <div>standlocation</div>,
  },
  {
    id: 14,
    activity_type: "FOR",
    state: {},
    src: ForestryImage,
    stage: 2,
    label: "Forestry",
    to: "/forestry",
    element: <div>forestr</div>,
    tooltip: "Please complete Stand Location",
  },
].map((obj) => ({
  ...stageStateProps,
  icon: imageWrap(obj.src),
  ...obj,
}));

export const StageIcon = (props) => {
  const { icon, to } = props;
  return (
    <Link
      to={to || "/"}
      className={`cf-flex cf-stageicon-link ${styles.stageIcon}`}
    >
      {icon}
    </Link>
  );
};

export const EventStatus = (props) => {
  const { tooltip, className, state, label, to, ...rest } = props;
  const { pathname } = useLocation();

  const active =
    typeof state.active === "boolean" ? state.active : to === pathname;
  return (
    <CFBox
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
      title={state.disabled ? tooltip : label}
      className={`
      ${state.completed && styles.completed}
      ${state.disabled && styles.disabled}
      ${active && styles.active}
      ${className}
      `}
    >
      <StageIcon to={to} {...rest} />
      <em>
        <CFTypography
          fontSize=".8rem"
          pt=".5em"
          sx={{
            color: colors.slate.main,
            textAlign: "center",
            lineHeight: "1em",
          }}
        >
          {label}
        </CFTypography>
      </em>
    </CFBox>
  );
};

export const RoadmapUserGuide = () => {
  const exampleProps = [
    {
      ...activityProps[1],
      tooltip: "Please complete Previous Section",
      state: { active: false, disabled: true },
      label: "Unavailable Section",
      description:
        "This section requires action in a previous section. Hover over the icon to see which previous section.",
    },
    {
      ...activityProps[1],
      state: { active: false },
      label: "Incomplete Section",
      description:
        "One or more steps in this section are incomplete. You can access this section at any time.",
    },
    {
      ...activityProps[1],
      state: { active: true },
      label: "Selected Section",
      description:
        "This section is currently selected. Click on any available icon to access the cooresponding section.",
    },
    {
      ...activityProps[1],
      state: { active: false, completed: true },
      label: "Complete Section",
      description:
        "This section is complete. You can return to completed sections at any time.",
    },
  ];
  return (
    <>
      <CFTypography variant="h5" mb={2}>
        Example Icons
      </CFTypography>
      <div className="cf-flex">
        {exampleProps.map((obj) => (
          <EventStatus {...obj} className={styles.exampleIcons} />
        ))}
      </div>
      <hr style={{ margin: ".5em 0" }} />
      <CFBox component="ul">
        {exampleProps.map((e) => (
          <li style={{ margin: "1rem" }}>
            <b>{e.label}: </b>
            <em>{e.description}</em>
          </li>
        ))}
      </CFBox>
    </>
  );
};

/* == DELETE THIS: Example list of IDs to filter by == */
// const getSelectedActivitiesFromRedux = [10];
// const getSelectedActivitiesFromRedux = [10, 11];
// const getSelectedActivitiesFromRedux = [10, 14, 11, 13];

/* == Filter available Activities by Selected Activities == */
export const selectedActivities = () => {
  const currentUser = useSelector((state) => state.user);
  const reducedActivites = currentUser?.activeProject?.activities?.map(
    (x) => x.activity
  );
  if (!reducedActivites) {
    return [];
  }

  const filteredProps = activityProps.filter((obj) =>
    reducedActivites.includes(obj.id)
  );

  for (let i = 0; i < filteredProps.length; i += 1) {
    if (filteredProps[i].stage) {
      const progressThing = currentUser?.activeProject?.activities?.find(
        (x) => x.activity === filteredProps[i].id
      );

      if (filteredProps[i].stage <= progressThing.progress) {
        filteredProps[i].state.completed = true;
        filteredProps[i].state.disabled = false;
      } else {
        filteredProps[i].state.completed = false;
      }
      if (filteredProps[i].stage > progressThing.progress + 1) {
        // if 1 is done, allow access to 2 but forbid access to 3
        filteredProps[i].state.disabled = true;
      } else {
        filteredProps[i].state.disabled = false;
      }
    }
  }

  return filteredProps;
};

export const getContinueText = (projectActivities, activityId) => {
  let continueText;
  // get index of current step
  const finalStep = projectActivities.indexOf(
    projectActivities.findLast((step) => step.id === activityId)
  );
  // If this is the final step, go to reports
  if (finalStep === projectActivities.length - 1) {
    continueText = "Continue to Report";
  } else {
    continueText = `Continue to ${projectActivities[finalStep + 1]?.label}`;
  }
  return continueText;
};
export const getGoBackText = (projectActivities, activityId) => {
  let goBackText;
  // Find the index of this step
  const index = projectActivities.findIndex(
    (activity) => activity.id === activityId
  );

  // If this is the first activity, go back to projects
  if (index === 0) {
    goBackText = "Projects";
  } else {
    // Find the previous step with state.disabled = false
    const availableSteps = projectActivities.slice(0, index - 1);
    let lastStep = availableSteps.findLast(
      (step) => step?.state?.disabled === false
    );
    if (!lastStep) lastStep = projectActivities[index - 1];
    goBackText = lastStep?.label;
  }
  return `Go back to ${goBackText}`;
};

export const HandleContinue = (projectActivities, navigate, activityId) => {
  // Find the last animal ag step
  const finalStep = projectActivities.indexOf(
    projectActivities.findLast((step) => step.id === activityId)
  );
  // If this is the final step, go to reports
  if (finalStep === projectActivities.length - 1) {
    navigate("/report");
  }
  // Otherwise, go to the next step
  else {
    navigate(projectActivities[finalStep + 1].to);
  }
};

export const HandleGoBack = (projectActivities, navigate, activityId) => {
  // Find the index of this step
  const index = projectActivities.findIndex(
    (activity) => activity.id === activityId
  );
  // If this is the first activity, go back to projects
  if (index === 0) {
    navigate("/projects");
  } else {
    // Find the previous step with state.disabled = false
    const availableSteps = projectActivities.slice(0, index - 1);
    let lastStep = availableSteps.findLast(
      (step) => step?.state?.disabled === false
    );
    if (!lastStep) lastStep = projectActivities[index - 1];
    navigate(lastStep.to);
  }
};
