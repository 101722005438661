// React
import { useDispatch } from "react-redux";

// MUI
import { Divider, Grid } from "@mui/material";
import colors from "assets/theme/base/colors";

// Our Components
import CFBox from "components/CFBox";
import CFTypography from "components/CFTypography";
import CFButton from "components/CFButton";
import { post } from "utils/api";
import { updateLoading } from "store/reducers/mask";
import { popupError } from "utils/generic";
import { DELETE_SELECTED_LOCATIONS } from "constants/api";
import { fetchUser } from "actions/user";

export default function DeleteParcel({
  setIsOpen,
  features,
  parcelWording,
  currentUser,
  activityTypeId,
  updateLocations,
  toggleDeleteChckbx,
  toggleResetToggle,
  buttonType,
  redrawMap,
}) {
  const dispatch = useDispatch();

  const onCancelClicked = () => {
    redrawMap();
    toggleResetToggle();
    toggleDeleteChckbx();
    setIsOpen(false);
  };
  const handleReturn = (res) => {
    if (res.error) {
      console.log("Error obj ", res.error);
      popupError(
        `Error deleting the selected ${parcelWording.toLowerCase()}(s).  Please try again.`,
        dispatch
      );
    } else {
      dispatch(fetchUser(currentUser));
      updateLocations(res);
    }
    setIsOpen(false);
    toggleResetToggle();
    toggleDeleteChckbx();
    dispatch(updateLoading({ loading: false }));
  };
  const handlePost = (event, postString, errorMessage, postObject) => {
    event.preventDefault();
    dispatch(updateLoading({ loading: true }));
    post(postString, postObject)
      .then(handleReturn)
      .catch((err) => {
        popupError(errorMessage, dispatch);
        dispatch(updateLoading({ loading: false }));
        setIsOpen(false);
        toggleResetToggle();
        console.log(err);
      });
  };

  const headerText = `Delete ${parcelWording}`;
  const warningText = `All data associated with these ${parcelWording.toLowerCase()}(s) will be deleted. Are you sure you want to delete the selected ${parcelWording.toLowerCase()}(s)?`;
  const errorMessage = `Error deleting the ${parcelWording.toLowerCase()}(s).  Please try again.`;
  const handlePostCall = (event) => {
    handlePost(event, DELETE_SELECTED_LOCATIONS, errorMessage, {
      userId: currentUser.id,
      locationIds: features,
      activityTypeId,
    });
  };

  return (
    <CFBox component="form" onSubmit={handlePostCall}>
      {
        <Grid container direction="row">
          {/* Window Header */}
          <CFTypography variant="h6" pl={1.5} pt={1} pb={0.5}>
            {headerText}
          </CFTypography>
          <Divider sx={{ width: "100%", mt: 0, mb: 2 }} />
          <CFTypography fontSize={18} px={1.5}>
            {warningText}
          </CFTypography>
          <Grid item xs={10.5}>
            <CFBox
              style={{
                fontSize: "0.67em",
                color: "red",
                marginTop: "0.25em",
              }}
            />
          </Grid>
          {/* Action buttons */}
          <Grid container py={2} px={2} justifyContent="space-between">
            {/* Return to map page */}
            <CFButton color="secondary" onClick={onCancelClicked}>
              Cancel
            </CFButton>
            {/* Submit changes */}
            <CFButton type="submit" color="progress">
              Yes
            </CFButton>
          </Grid>
        </Grid>
      }
    </CFBox>
  );
}
