// React
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

// MUI
import { Grid } from "@mui/material";
import Stack from "@mui/material/Stack";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import ArrowCircleDownTwoToneIcon from "@mui/icons-material/ArrowCircleDownTwoTone";

import AddCircleTwoToneIcon from "@mui/icons-material/AddCircleTwoTone";

// Our Components
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import Tooltip from "@mui/material/Tooltip";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CFTypography from "components/CFTypography";
import CFBox from "components/CFBox";
import CFInput from "components/CFInput";
import CFDialog from "components/CFDialog";
import colors from "assets/theme/base/colors";
import CFButton from "components/CFButton";
import { updateLoading } from "store/reducers/mask";
import { post } from "utils/api";
import { popupError } from "utils/generic";
import { FETCH_SHEEP_DMI, UPDATE_GOAT_FEED } from "constants/api";
import CFLookupPicker from "components/CroplandsComponents/CFLookupPicker/CFLookupPicker";
import CFLoading from "components/CFLoading";
import FeedAdditives from "./FeedAdditives";
import FeedCalculator from "./FeedCalculator";
import BeefFeedAdditives from "./BeefFeedAdditives";
import { getTextInput } from "./AnimalAgCommonFunc";

// Constants

export default function GoatFeed({
  id,
  yearValue,
  animalCategory,
  animalCategoryId,
  groupId,
  anagObject,
  anagObjectChanged,
  verifyDone,
}) {
  const dispatch = useDispatch();

  const { info } = colors;

  const [goatDE, setGoatDE] = useState("");
  const [calculatorOpen, setCalculatorOpen] = useState(false);
  const [populateFeeds, setPopulateFeeds] = useState(false);
  const [saveSuccessMsg, setSaveSuccessMsg] = useState(false);
  const [loading, setLoading] = useState(false);
  const currentUser = useSelector((state) => state.user);

  const handleGoatDEChange = (e) => {
    const value = getTextInput(e.target.value);
    setGoatDE(value);
  };

  function openFeedCalculator() {
    setCalculatorOpen(true);
    // window.location.href = "/managementEntry";
  }
  const handlePopulateFeeds = () => {
    setPopulateFeeds(true);
  };

  const fetchGoatFeed = () => {
    setLoading(true);
    const popObj = {
      popid: id,
    };
    post(FETCH_SHEEP_DMI, popObj)
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          setLoading(false);
        } else {
          const json = JSON.parse(res.data);
          const pop = json.GoatPopMonth;

          setGoatDE(pop.DigEngry);
          if (pop.DigEngry > 0) {
            verifyDone("green");
          } else {
            verifyDone("grey");
          }
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const updateGoatFeed = () => {
    setLoading(true);
    const animalTypesObj = {
      de: goatDE,

      popid: id,
    };
    post(UPDATE_GOAT_FEED, animalTypesObj)
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          setLoading(false);
        } else {
          setSaveSuccessMsg(true);
          verifyDone("green");
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchGoatFeed();
  }, []);

  useEffect(() => {
    setPopulateFeeds(false);
    fetchGoatFeed();
  }, [populateFeeds]);

  return (
    <CFBox width="100%">
      {loading && <CFLoading local />}
      {/* Main form */}
      <CFBox width="100%">
        <Grid container direction="row" xs={12} sm={12} md={12}>
          <Grid item xs={12}>
            <CFTypography
              sx={{
                fontSize: "0.9em",
                fontWeight: "bold",
                color: info.dullFocus,

                marginBottom: "0.7em",
              }}
            >
              Feed Details
            </CFTypography>
          </Grid>
        </Grid>
        <Grid container direction="row" xs={12} sm={12} md={12}>
          <Grid item xs={12}>
            <CFTypography
              sx={{
                fontSize: "0.8em",

                color: info.dullFocus,

                marginBottom: "0.7em",
              }}
            >
              Enter the average annual digestible energy of the goat diet. If
              the digestible energy is not known, use the Feed Calculator to
              estimate the average digestible energy in the goat&apos;s diet.
            </CFTypography>
          </Grid>
        </Grid>
        <Grid container direction="row" xs={12} sm={12} md={12}>
          <Grid item xs={4}>
            <CFButton
              sx={{
                backgroundColor: colors.info.dullFocus,
                color: "white.main",
                float: "left",
                marginBottom: "1em",
              }}
              onClick={() => openFeedCalculator()}
            >
              Feed Calculator
            </CFButton>
          </Grid>
        </Grid>
        <Grid container direction="row" xs={12} sm={12} md={12}>
          <Grid container direction="row">
            <Grid item xs={12} sm={12} md={12}>
              <CFBox sx={{ display: "flex" }}>
                <CFBox
                  style={{ width: "18em" }}
                  sx={{
                    backgroundColor: colors.background.header,
                    padding: "16px",
                    boxShadow: "0px 1px 3px 0px #bbb",
                    display: "flex",
                  }}
                >
                  <CFTypography
                    sx={{
                      fontSize: "16px",
                      paddingTop: ".5em",
                      color: info.dullFocus,
                      marginRight: "1em",
                    }}
                  >
                    Digestible Energy
                  </CFTypography>

                  <CFInput
                    sx={{
                      fieldset: {
                        borderColor: info.dullFocus,
                        borderStyle: "solid",
                        borderWidth: "2px",
                        // marginBottom: "0.5em",
                      },
                    }}
                    style={{ width: "6em" }}
                    type="number"
                    InputProps={{
                      inputProps: { min: 0, max: 100 },
                      endAdornment: "%",
                    }}
                    color="primary"
                    id="gtde"
                    name="gtde"
                    defaultValue="0"
                    value={goatDE}
                    onChange={handleGoatDEChange}
                    placeholder=""
                    // inputProps={{ disabled: value }}
                    fullWidth
                  />
                </CFBox>
              </CFBox>
            </Grid>
          </Grid>
        </Grid>

        <Grid container py={2} px={2} justifyContent="space-around">
          <Grid item xs={12} sm={12} md={12}>
            <CFButton
              type="submit"
              sx={{
                float: "right",
              }}
              color="progress"
              onClick={() => {
                updateGoatFeed();
              }}
              disabled={!(goatDE > 0)}
            >
              Save
            </CFButton>
          </Grid>
        </Grid>
        {saveSuccessMsg && (
          <Grid container justifyContent="space-around">
            <Grid item xs={2} sm={2} md={2} alignItems="center">
              <CFBox id="feedSuccessMessage">
                <CFTypography
                  sx={{
                    fontSize: "15px",
                    color: "green",

                    marginBottom: "0.5em",
                    marginTop: "0.5em",
                  }}
                >
                  Save was successful.
                </CFTypography>
              </CFBox>
            </Grid>
          </Grid>
        )}

        <FeedCalculator
          isOpen={calculatorOpen}
          setIsOpen={setCalculatorOpen}
          // fetchAllProjects={fetchAllProjects}

          Id={id}
          YearVal={yearValue}
          AnimalCat={animalCategory}
          AnimalCatId={animalCategoryId}
          animalObj={anagObject}
          populateFeedsClicked={handlePopulateFeeds}
        />
      </CFBox>
    </CFBox>
  );
}
