import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Grid, Select, MenuItem } from "@mui/material";

import CFStep from "components/CFarmParts/CFStep";
import {
  AGROFORESTRY_STATE_LIST,
  AGROFORESTRY_COUNTY_LIST,
  AGROFORESTRY_UPDATE_LOCATION,
} from "constants/api";
import { updateLoading } from "store/reducers/mask";
import { popupError } from "utils/generic";
import { post } from "utils/api";
import { fetchUser } from "actions/user";
import AgroforestryInput from "./AgroforestryInput";

function AgroforestryLocation({
  stepNumber,
  agroforestryOperation,
  setAgroforestryOp,
}) {
  const currentUser = useSelector((state) => state.user);
  const dispatch = useDispatch();

  // Fetch state list
  const [stateList, setStateList] = useState([]);
  const fetchStateList = () => {
    dispatch(updateLoading({ loading: true }));

    post(AGROFORESTRY_STATE_LIST)
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          dispatch(updateLoading({ loading: false }));
        } else {
          setStateList(res.data);
        }

        dispatch(updateLoading({ loading: false }));
      })
      .catch((err) => {
        console.log(err);
        dispatch(updateLoading({ loading: false }));
      });
  };

  // Fetch county list
  const [countyList, setCountyList] = useState([]);
  const fetchCountyList = (stateId) => {
    dispatch(updateLoading({ loading: true }));

    post(AGROFORESTRY_COUNTY_LIST, { StateId: stateId })
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          dispatch(updateLoading({ loading: false }));
        } else {
          setCountyList(res.data);
        }

        dispatch(updateLoading({ loading: false }));
      })
      .catch((err) => {
        console.log(err);
        dispatch(updateLoading({ loading: false }));
      });
  };

  // Local value for state
  const [state, setState] = useState("Not Selected");
  useEffect(() => {
    setState(agroforestryOperation.StateName);
    if (agroforestryOperation.StateName !== "Not Selected") {
      fetchCountyList(agroforestryOperation.StateId);
    }
  }, [agroforestryOperation.StateName]);

  // Local value for county
  const [county, setCounty] = useState("Not Selected");
  useEffect(() => {
    setCounty(agroforestryOperation.CountyName);
  }, [agroforestryOperation.CountyName]);

  // Fetch list of states on initial render
  useEffect(() => {
    fetchStateList();
  }, []);

  // Fetch county list when state is updated
  function handleStateChange(value) {
    setAgroforestryOp({
      ...agroforestryOperation,
      CountyName: "Not Selected",
      CountyId: 0,
    });
    setCounty("Not Selected");
    setState(value);
  }
  useEffect(() => {
    if (state !== "Not Selected") {
      stateList.forEach((stateObj) => {
        if (state === stateObj.name) {
          fetchCountyList(stateObj.id);
        }
      });
    }
  }, [state]);

  // Submit state and county when county is updated
  const submitStateAndCounty = (stateId, countyId) => {
    dispatch(updateLoading({ loading: true }));

    post(AGROFORESTRY_UPDATE_LOCATION, {
      UserId: currentUser.id,
      StateId: stateId,
      CountyId: countyId,
      ScenarioName: "Baseline",
    })
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          dispatch(updateLoading({ loading: false }));
        } else {
          setAgroforestryOp({
            ProjectId: res.data.projectId,
            ScenarioId: res.data.scenarioId,
            ScenarioName: res.data.scenarioName,
            UserDefinedName: res.data.userDefinedName,
            StateName: res.data.stateName,
            StateId: res.data.stateId,
            CountyName: res.data.countyName,
            CountyId: res.data.countyId,
            DetailsList: res.data.scenarioDetails
              ? res.data.scenarioDetails.map((detail) => ({
                  Id: detail.detailId,
                  Group: {
                    Id: detail.groupId,
                    CommonName: detail.groupName,
                  },
                  DBH: detail.dbh,
                  AmountPerAcre: detail.amountPerAcre,
                  HasType: detail.hasType,
                  TypeLookUp: {
                    Id: detail.typeLookUpId,
                    Afpractice: detail.typeLookUpName,
                  },
                  Type: { Id: detail.typeId },
                  Age: detail.age,
                  Size: detail.size,
                  HasAge: detail.hasAge,
                  Scenario: { Id: detail.scenarioId },
                  IsNew: detail.isNew,
                }))
              : [],
          });
          dispatch(fetchUser(currentUser));
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(updateLoading({ loading: false }));
      })
      .finally(() => {
        dispatch(updateLoading({ loading: false }));
      });
  };
  useEffect(() => {
    if (county !== "Not Selected") {
      countyList.forEach((countyObj) => {
        if (county === countyObj.name) {
          submitStateAndCounty(countyObj.countyStateId, countyObj.id);
        }
      });
    }
  }, [county]);

  return (
    <Grid container direction="row">
      <CFStep
        stepLabel="Operation Location"
        subText="Select the location of your agroforestry operation"
        helpText="Select the state and county in which your operation is located, and COMET-Farm will access agroforestry data for that region."
        stepNumber={stepNumber}
      />
      <Grid item container direction="column" sx={{ width: "30%" }}>
        {/* State */}
        <AgroforestryInput
          label="State:"
          control={
            <Select
              value={stateList.length > 0 ? state : "Not Selected"}
              onChange={(e) => handleStateChange(e.target.value)}
              sx={{ width: "100%" }}
            >
              <MenuItem disabled value="Not Selected">
                Options...
              </MenuItem>
              {stateList.map((stateObj) => (
                <MenuItem key={stateObj.id} value={stateObj.name}>
                  {stateObj.name}
                </MenuItem>
              ))}
            </Select>
          }
        />
        {/* County */}
        <AgroforestryInput
          label="County:"
          control={
            <Select
              value={countyList.length > 0 ? county : "Not Selected"}
              disabled={state === "Not Selected"}
              onChange={(e) => setCounty(e.target.value)}
              sx={{ width: "100%" }}
            >
              <MenuItem disabled value="Not Selected">
                Options...
              </MenuItem>
              {countyList.map((countyObj) => (
                <MenuItem key={countyObj.id} value={countyObj.name}>
                  {countyObj.name}
                </MenuItem>
              ))}
            </Select>
          }
        />
      </Grid>
    </Grid>
  );
}

export default AgroforestryLocation;
