/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
import React from "react";
import CFBox from "components/CFBox";
import colors from "assets/theme/base/colors";
import { navbarRoutes } from "./cfarmheader.config";
import { Branding, DropdownLink, LinkButton, UserAvatar } from "./components";

export default function CFarmHeader() {
  return (
    <CFBox
      className="cf-flex gap-half"
      p={1}
      sx={{
        backgroundColor: colors.background.header,
        borderBottom: "1px solid #ccc",
      }}
    >
      <Branding />
      <CFBox className="cf-grow">
        <CFBox
          className="cf-float-right cf-flex"
          style={{ gap: "1.1em" }}
          component="nav"
        >
          <LinkButton to="/home" label="Home" />
          <LinkButton to="/projects" label="Projects" />

          {navbarRoutes.map((r) => (
            <DropdownLink key={r.label} {...r} />
          ))}
          <UserAvatar />
        </CFBox>
      </CFBox>
    </CFBox>
  );
}
