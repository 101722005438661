// React
import PropTypes from "prop-types";
import { useState } from "react";

// MUI
import { Grid, Dialog, FormControlLabel, Checkbox } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";

// CFarm components
import CFTypography from "components/CFTypography";
import CFButton from "components/CFButton";

export default function CroplandsScenarioSelect({
  isOpen,
  setIsOpen,
  displayedScenarios,
  setDisplayedScenarios,
  futureScenarios,
}) {
  // Store user selection
  const [newDisplay, setNewDisplay] = useState(displayedScenarios);

  // Update selected scenarios in table and close the dialog
  function ApplyChanges() {
    setDisplayedScenarios(newDisplay);
    setIsOpen(false);
  }

  // Add or remove scenario from display list
  function DisplayChange(e, scenarioId) {
    let selectionCopy;
    if (newDisplay.includes(scenarioId)) {
      selectionCopy = newDisplay.filter((scenario) => scenario !== scenarioId);
    } else {
      selectionCopy = [...newDisplay];
      selectionCopy.push(scenarioId);
    }
    setNewDisplay(selectionCopy);
  }

  return (
    <Dialog open={isOpen} fullWidth maxWidth="sm" sx={{ zIndex: 5 }}>
      <Grid p={1}>
        {/* Dialog header and close */}
        <Grid container direction="row" pb={1} justifyContent="space-between">
          <CFTypography variant="h5">
            Croplands Future Scenario Selection
          </CFTypography>
          <CloseIcon
            fontSize="medium"
            sx={{ cursor: "pointer" }}
            onClick={() => setIsOpen(false)}
          />
        </Grid>
        <Divider sx={{ my: 0 }} />
        {/* Description */}
        <CFTypography variant="h6" fontWeight="regular" py={1}>
          Select from the available scenarios below to compare emissions for
          your provided future field rotations:
        </CFTypography>
        {/* Add checkbox for each future scenario */}
        <Grid container direction="column" pl={2} pb={1}>
          {futureScenarios.map((scenario) => (
            <FormControlLabel
              key={scenario.scenario.id}
              control={
                <Checkbox
                  onChange={(e) => DisplayChange(e, scenario.scenario.id)}
                  checked={newDisplay.includes(scenario.scenario.id)}
                />
              }
              label={scenario.scenario.name}
            />
          ))}
        </Grid>
        <Divider sx={{ my: 0 }} />
        {/* Apply changes, only enabled if new selection is made */}
        <Grid container direction="row" pt={1} justifyContent="flex-end">
          <CFButton
            variant="gradient"
            color="primary"
            onClick={() => ApplyChanges()}
            disabled={
              newDisplay.length === displayedScenarios.length &&
              newDisplay.every((id) => displayedScenarios.includes(id))
            }
          >
            Apply
          </CFButton>
        </Grid>
      </Grid>
    </Dialog>
  );
}

CroplandsScenarioSelect.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  displayedScenarios: PropTypes.arrayOf(PropTypes.number).isRequired,
  setDisplayedScenarios: PropTypes.func.isRequired,
  futureScenarios: PropTypes.arrayOf(
    PropTypes.shape({
      scenario: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
    })
  ).isRequired,
};
