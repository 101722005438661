import { PropTypes } from "prop-types";
import { DeleteTwoTone } from "@mui/icons-material";

export default function DeleteButton({ deleteAction, fontSize, ...rest }) {
  return (
    <DeleteTwoTone
      onClick={() => deleteAction()}
      color="black"
      fontSize={fontSize}
      style={{ cursor: "pointer", margin: ".5em", ...rest.style }}
    />
  );
}
DeleteButton.defaultProps = {
  fontSize: "large",
};
DeleteButton.propTypes = {
  deleteAction: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
    .isRequired,
  fontSize: PropTypes.string,
};
