// React
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

// MUI
import { Dialog } from "@mui/material";

// OL
import { transform } from "ol/proj";

// Our Components
import { post } from "utils/api";
import { GET_SOIL_DATA, DOWNLOAD_SOIL_DATA } from "constants/api";
import { popupError, convertGeometry, getGeoType } from "utils/generic";
import { updateLoading } from "store/reducers/mask";
import {
  POLYGON,
  RECTANGLE,
  CIRCLE,
  POINT,
  DELETE,
  EDIT,
  ESRI,
  FIND,
  SOILS,
  SOILS_DOWNLOAD,
} from "constants/mapButtons";

import SoilByClick from "./SoilByClick";
import SearchLocation from "./SearchLocation";
import ESRIUpload from "./ESRIUpload";
import DeleteParcel from "./DeleteParcel";
import ConfigureParcel from "./ConfigureParcel";

export default function CFMapPopup({
  setIsOpen,
  isOpen,
  deleteOldMapInteractions,
  toggleResetToggle,
  toggleDeleteChckbx,
  parcelWording,
  parcelNames,
  currentUser,
  updateLocations,
  buttonClickObj,
  setButtonClickObj,
  activityTypeId,
  redrawMap,
  mainMap,
}) {
  const dispatch = useDispatch();
  const [control, setControl] = useState("");
  const { buttonType, feature } = buttonClickObj;

  /* eslint no-underscore-dangle: 0 */
  const parcelDrawn = () => {
    let showArea = false;
    let parcel;
    if (getGeoType(feature) === "point") showArea = true;
    if (buttonType === EDIT) parcel = feature.values_;
    const newGeometry = convertGeometry(feature);
    setIsOpen(true);
    setControl(
      <ConfigureParcel
        setIsOpen={setIsOpen}
        buttonType={buttonType}
        parcel={parcel}
        parcelWording={parcelWording}
        parcelNames={parcelNames}
        geometry={newGeometry}
        currentUser={currentUser}
        activityTypeId={activityTypeId}
        updateLocations={updateLocations}
        showArea={showArea}
        toggleResetToggle={toggleResetToggle}
        deleteOldMapInteractions={deleteOldMapInteractions}
        redrawMap={redrawMap}
      />
    );
  };

  /* Parcel obj example
  "id": "95850",
  "name": "Name",
  "acres": "338",
  "acresLabel": "acres",
  "area": "338.04028",
  "wktString": "POLYGON ((-11566958.123924568 4897069.73447302, -11565817.986884112 4896433.147039052, -11564107.799789418 4897232.96202019, -11565659.238160148 4898090.201734817, -11566958.123924568 4897069.73447302))",
  "soilComponentStatus": "running",
  "canPerformAnalysis": false,
  "dataComplete": false */
  const processParcelDelete = () => {
    setIsOpen(true);
    const parcels = feature.map((f) => f.values_.id);
    setControl(
      <DeleteParcel
        setIsOpen={setIsOpen}
        features={parcels}
        parcelWording={parcelWording}
        currentUser={currentUser}
        activityTypeId={activityTypeId}
        updateLocations={updateLocations}
        buttonType={buttonType}
        toggleResetToggle={toggleResetToggle}
        toggleDeleteChckbx={toggleDeleteChckbx}
        redrawMap={redrawMap}
      />
    );
  };

  const processESRI = () => {
    setIsOpen(true);
    setControl(
      <ESRIUpload
        setIsOpen={setIsOpen}
        parcelWording={parcelWording}
        currentUser={currentUser}
        activityTypeId={activityTypeId}
        updateLocations={updateLocations}
        toggleResetToggle={toggleResetToggle}
      />
    );
  };
  const searchLocation = () => {
    setIsOpen(true);
    setControl(
      <SearchLocation
        setIsOpen={setIsOpen}
        toggleResetToggle={toggleResetToggle}
        parcelWording={parcelWording}
        mainMap={mainMap}
      />
    );
  };

  const soilByClick = () => {
    dispatch(updateLoading({ loading: true }));
    if (feature) {
      const lonlat = transform(feature, "EPSG:3857", "EPSG:4326");
      post(GET_SOIL_DATA, { longitude: lonlat[0], latitude: lonlat[1] })
        .then((res) => {
          if (res.error) {
            console.log("Error obj ", res.error);
            popupError(res.error, dispatch);
          } else {
            const parsedRes = JSON.parse(res.data.value);
            setControl(
              <SoilByClick setIsOpen={setIsOpen} soilInfo={parsedRes.Value} />
            );
          }
          setIsOpen(true);
          dispatch(updateLoading({ loading: false }));
        })
        .catch((error) => {
          console.log(error);
          dispatch(updateLoading({ loading: false }));
        });
    }
  };

  const downloadSoils = () => {
    if (parcelNames.length > 0) {
      dispatch(updateLoading({ loading: true }));
      post(DOWNLOAD_SOIL_DATA, {
        userId: currentUser.id,
        activityId: activityTypeId,
      })
        .then((res) => {
          if (res.error) {
            console.log("Error obj ", res.error);
            popupError(res.error, dispatch);
          } else {
            const text = res.data;
            const a = document.createElement("a");
            a.style.display = "none";
            document.body.appendChild(a);

            // Set the HREF to a Blob representation of the data to be downloaded
            a.href = window.URL.createObjectURL(new Blob([text], { text }));

            // Use download attribute to set set desired file name
            a.setAttribute("download", `SoilInfo.csv`);

            // Trigger the download by simulating click
            a.click();

            // Cleanup
            window.URL.revokeObjectURL(a.href);
            document.body.removeChild(a);
          }
          toggleResetToggle();
          dispatch(updateLoading({ loading: false }));
        })
        .catch((error) => {
          popupError(
            "Error downloading soil data.  Please try again.",
            dispatch
          );
          dispatch(updateLoading({ loading: false }));
          console.log(error);
        });
    } else {
      popupError(
        `Please define a ${parcelWording.toLowerCase()} before exporting soil.`,
        dispatch
      );
      toggleResetToggle();
    }
  };

  useEffect(() => {
    switch (buttonType) {
      case POLYGON:
      case RECTANGLE:
      case CIRCLE:
      case POINT:
      case EDIT:
        parcelDrawn();
        break;
      case DELETE:
        processParcelDelete();
        break;
      case ESRI:
        processESRI();
        break;
      case FIND:
        searchLocation();
        break;
      case SOILS:
        soilByClick();
        break;
      case SOILS_DOWNLOAD:
        downloadSoils();
        break;
      default:
        break;
    }
  }, [buttonClickObj]); // was feature

  return (
    <Dialog open={isOpen} sx={{ zIndex: 5 }}>
      <div style={{ padding: "15px", width: "355px" }}>{control}</div>
    </Dialog>
  );
}
