// MUI
import { Divider, Grid } from "@mui/material";
import colors from "assets/theme/base/colors";

// Our Components
import CFBox from "components/CFBox";
import CFTypography from "components/CFTypography";
import CFButton from "components/CFButton";

export default function SoilByClick({ setIsOpen, soilInfo }) {
  return (
    <Grid container direction="row">
      {/* Window Header */}
      <CFTypography variant="h6" pl={1.5} pt={1} pb={0.5}>
        Soil Information
      </CFTypography>
      <Divider sx={{ width: "100%", mt: 0, mb: 2 }} />
      <Grid item xs={10.5}>
        <CFTypography fontSize={15} pl={3} py={0.5}>
          <b>Map Unit :</b>
          {` ${soilInfo.MapUnit.MapUnitKey}`}
        </CFTypography>
      </Grid>
      <Grid item xs={10.5}>
        <CFTypography fontSize={15} pl={3} py={0.5}>
          <b>Texture :</b>
          {` ${soilInfo.Texture}`}
        </CFTypography>
      </Grid>
      <Grid item xs={10.5}>
        <CFTypography fontSize={15} pl={3} py={0.5}>
          <b>Sand/Silt/Clay Fractions :</b>
          {` ${soilInfo.FractionSand.toFixed(0)} / 
        ${soilInfo.FractionSilt.toFixed(0)} / ${soilInfo.FractionClay.toFixed(
            0
          )}`}
        </CFTypography>
      </Grid>
      <Grid item xs={10.5}>
        <CFTypography fontSize={15} pl={3} py={0.5}>
          <b>Bulk Density :</b>
          {` ${soilInfo.BulkDensity.toFixed(2)} g/cm3`}
        </CFTypography>
      </Grid>
      <Grid item xs={10.5}>
        <CFTypography fontSize={15} pl={3} py={0.5}>
          <b>Hydric :</b>
          {` ${soilInfo.HydricRating}`}
        </CFTypography>
      </Grid>
      <Divider sx={{ width: "100%", mt: 0, mb: 1 }} />
      {/* Action buttons */}
      <Grid container py={1.5} px={2} justifyContent="center">
        {/* Return to map page */}
        <CFButton color="secondary" onClick={() => setIsOpen(false)}>
          Close
        </CFButton>
      </Grid>
    </Grid>
  );
}
