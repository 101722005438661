/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/prop-types */
import { React } from "react";
import { Grid } from "@mui/material";

import { PropTypes } from "prop-types";

import CFStep from "components/CFarmParts/CFStep";

import CFBox from "components/CFBox";

import CFMiniMap from "pages/CFMap/CFMiniMap/CFMiniMap";
import CFLookupPicker from "components/CroplandsComponents/CFLookupPicker/CFLookupPicker";
import mapLegend from "assets/images/field/map_legend.png";

function SelectField({
  fields,
  stepNumber,
  setSelectedField,
  selectedField,
  locationMapFeatureHolder,
  activityTypeId,
  disabled,
}) {
  return (
    <CFBox
      sx={{
        position: "relative",
        opacity: disabled ? 0.5 : 1,
        pointerEvents: disabled ? "none" : "auto",
      }}
    >
      <CFStep
        stepLabel="Select Field"
        stepNumber={stepNumber}
        helpText={
          <div>
            Using the dropdown menu below or the interactive map, select each
            field to add crops and management. Fields{" "}
            <strong>outlined in yellow</strong> indicate that they have been
            selected and users can now enter management for that field. Fields{" "}
            <strong>filled with blue</strong> have incomplete data or
            management. Users will need to finish entering all management before
            they can proceed to the scenario or report pages. Fields{" "}
            <strong>filled with green</strong> have complete management. Once
            all fields have complete data, users can proceed to the scenario or
            report pages.
          </div>
        }
        subText="Using the dropdown menu below or the interactive map, select each
        field to add crops and management."
      />

      <Grid
        container
        direction="row"
        sx={{ paddingTop: "1em", paddingBottom: "1em" }}
      >
        <Grid container item xs={6} sm={3} md={3} direction="column">
          <Grid item sx={{ width: "14.5em" }}>
            <CFLookupPicker
              key="selectField"
              name="selectField"
              required
              onChange={(event, newValue) => {
                setSelectedField(newValue);
              }}
              value={selectedField.id !== 0 ? selectedField : null}
              label="Select a field"
              options={fields}
            />
          </Grid>

          <Grid item xs={4} sm={4} md={4} sx={{ padding: "1em" }}>
            <CFMiniMap
              selectedParcel={selectedField}
              locationMapFeatureHolder={locationMapFeatureHolder}
              activityTypeId={activityTypeId}
            />
          </Grid>
          <Grid>
            <img alt="Minimap Legend" src={mapLegend} />
          </Grid>
        </Grid>
      </Grid>
    </CFBox>
  );
}

SelectField.defaultProps = {
  stepNumber: "",
};

SelectField.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ).isRequired,
  stepNumber: PropTypes.string,
};
export default SelectField;
