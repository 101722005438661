import { PropTypes } from "prop-types";
import CFBox from "components/CFBox";
import colors from "assets/theme/base/colors";

export default function CFStepMarker({ label, ...rest }) {
  return (
    // We've been having trouble with the circle getting squashed horizontally if the space on the page is too small.
    // May have to figure out something other than height/width to enforce the circularness.
    <div style={{ position: "relative" }}>
      <CFBox
        sx={{
          display: "grid",
          borderRadius: "50%",
          height: "2em",
          width: "2em",
          backgroundColor: colors.primary.main,
          color: "white.main",
          placeItems: "center",
          fontSize: "0.65em",
        }}
        {...rest}
      >
        {label}
      </CFBox>
    </div>
  );
}

CFStepMarker.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
