/* eslint-disable import/prefer-default-export */
import { Icon, Grid } from "@mui/material";

export function getInitials(user) {
  const { firstName, lastName } = user;
  const firstInitial = firstName.charAt(0);
  const lastInitial = lastName.charAt(0);
  return `${firstInitial}${lastInitial}`;
}

export const navbarRoutes = [
  {
    label: "Resources",
    icon: <Icon>dashboard</Icon>,
    collapse: [
      {
        label: "Documentation",
        description: "COMET-Farm and USDA bluebook documentation.",
        type: "link",
        to: "/documentation",
      },
      {
        label: "Trainings",
        description: "COMET-Farm tutorial and 'how to'.",
        type: "link",
        to: "/training",
      },
      {
        label: "Version Log",
        description: "Version Log.",
        type: "link",
        to: "/version",
      },
      {
        label: "Terms and Conditions",
        description: (
          <Grid container direction="column" p={2}>
            <p>
              The methodologies supporting COMET-Farm are not intended as an
              accounting framework for emission reduction crediting or trading.
              Neither COMET-Farm nor the provided results constitute an offset
              protocol or a full lifecycle GHG analysis.
            </p>
            <br />
            <p>
              Users agree not to use the software or tools provided on this
              website for the purpose of engaging in an offset protocol or full
              lifecycle GHG analysis. Any user found in violation of this
              policy, including but not limited to using the software tool for
              purposes related to an offset protocol, may receive a warning.
              Continued violation of the policy may result in further action,
              including the suspension or termination of the user&apos;s access
              to the software tool.
            </p>
            <br />
            <p>
              COMET-Farm and related software is provided &quot;as is&quot;
              without any warranties, express or implied, including but not
              limited to the implied warranties of merchantability, fitness for
              a particular purpose, and non-infringement.
            </p>
            <br />
            <p>
              CSU or USDA makes no representations or warranties regarding the
              performance or results that may be obtained from the use of the
              COMET API and related Software.
            </p>
            <br />
            <p>
              In no event shall CSU or USDA be liable for any direct, indirect,
              incidental, special, or consequential damages arising out of or in
              any way connected with the use of the COMET API and related
              Software, even if CSU has been advised of the possibility of such
              damages.
            </p>
          </Grid>
        ),
        style: {
          minWidth: "750px",
          maxHeight: "75vh",
          overflow: "auto",
        },
        type: "popup",
      },
    ],
  },
  {
    name: "Other Tools",
    label: "Other Tools",
    icon: <Icon>dashboard</Icon>,
    collapse: [
      {
        label: "COMET-Planner",
        description: `Carbon and greenhouse gas evaluation for NRCS conservation practice planning.`,
        type: "link",
        href: "http://comet-planner.com/",
      },
      {
        label: "COMET-Energy",
        description: `Stand-alone tool that allows you to calculate reductions in greenhouse gas emissions
       based on anticipated fuel savings.`,
        type: "link",
        href: "/quick-energy",
      },
      {
        label: "API Access",
        description: `Croplands API page.`,
        type: "link",
        href: "/api-access",
      },
    ],
  },
];
