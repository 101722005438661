import { Grid } from "@mui/material";
import { PropTypes } from "prop-types";
import colors from "assets/theme/base/colors";
import CFLoading from "components/CFLoading";

export default function CFManagementSelectionCard({
  label,
  input,
  labelWidth,
  disabled,
  bgColor,
  textColor,
  sx,
  ...rest
}) {
  return (
    // We've been having trouble with the circle getting squashed horizontally if the space on the page is too small.
    // May have to figure out something other than height/width to enforce the circularness.

    <Grid
      sx={{
        backgroundColor: bgColor,
        color: textColor,
        borderRadius: ".3em",
        boxShadow: "0px 1px 3px 0px #bbb",
        margin: ".3em",
        position: "relative",
        padding: ".5em",
        width: "100%",
        minWidth: "300px",
        ...sx,
      }}
      alignItems="center"
      {...rest}
      container
      flexDirection="row"
    >
      {disabled && (
        <CFLoading local message=" " showCircle={false} minHeight={0} />
      )}
      {labelWidth !== 0 && (
        <Grid
          item
          xs={labelWidth}
          sx={{
            padding: ".5em",
            position: "relative",
          }}
        >
          {label}
        </Grid>
      )}

      <Grid
        xs={12 - labelWidth}
        alignItems="center"
        justifyContent="flex-end"
        item
        container
        sx={{}}
      >
        {" "}
        {input}
      </Grid>
    </Grid>
  );
}

CFManagementSelectionCard.defaultProps = {
  labelWidth: 2,
  disabled: false,
  bgColor: colors.background.header,
  textColor: colors.info.main,
};

CFManagementSelectionCard.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  input: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  labelWidth: PropTypes.number,
  bgColor: PropTypes.string,
  textColor: PropTypes.string,
};
