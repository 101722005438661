import React, { useEffect, useState } from "react";
import { Grid, IconButton, Tooltip } from "@mui/material";

import { Close, ContentCopy, EditTwoTone } from "@mui/icons-material";

import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import CFBox from "components/CFBox";
import CFLoading from "components/CFLoading";
import CFButton from "components/CFButton";
import {
  SAVE_CROPYEARS,
  CROPLANDS_MANAGEMENT,
  COPY_ROTATION,
} from "constants/api";

import { post } from "utils/api";
import { popupError, jsonCopy } from "utils/generic";

import CFManagementSelectionCard from "components/CroplandsComponents/CFManagementSelectionCard/CFManagementSelectionCard";
import CFLookupPicker from "components/CroplandsComponents/CFLookupPicker/CFLookupPicker";
import { fetchUser } from "actions/user";
import CopyHistoryCard from "pages/Croplands/HistoricManagement/CopyHistoryCard/CopyHistoryCard";
import UserGuidePopup from "components/CFUserGuidePopup/UserGuidePopup";
import colors from "assets/theme/base/colors";
import style from "../../CroplandStyle";
import EditCropCard from "../EditCropCard/EditCropCard";
import RequiredComplete from "../../../../components/CFarmParts/CFStep/RequiredComplete";
import CopyYearsCard from "./CopyYearsCard/CopyYearsCard";
import ReviewInputsCard from "./ReviewInputsCard/ReviewInputsCard";
import CPACard from "./CPA/CPA";
import ConfirmCropChangePopup from "../../../../components/CroplandsComponents/SubComponents/ConfirmCropChangePopup";
import PredefinedTemplatesWindow from "./PredefinedTemplatesWindow/PredefinedTemplatesWindow";
import CPAHelperText from "./CPA/CPA_helper_text";

function CropRotationManagement({
  allOtherFields,
  field,
  stepNumber,
  cropRotationId,
  scenarioName,
  goToPrevious,
  fetchProjectInformation, // used to check if all fields and all scenarios are complete, need to trigger after a save to do a completeness check
  setIsComplete,
}) {
  const dispatch = useDispatch();

  const { name, id } = field;
  const otherFields = allOtherFields.filter((x) => x.id !== 0 && x.id !== id);
  const allHistoryComplete =
    allOtherFields.every((x) => x.id === 0 || x.historyComplete) &&
    field.historyComplete;

  const [loadingMessage, setLoading] = useState("Select a field...");

  const [saveEnabled, setSaveEnabled] = useState(false);

  const currentUser = useSelector((state) => state.user);
  const [possibleCrops, setPossibleCrops] = useState([]);
  const [cropYears, setCropYears] = useState([]);

  const [allManagement, setAllManagement] = useState({});
  const [fertilizerRatios, setfertilizerRatios] = useState({});
  const [tillageImplements, settillageImplements] = useState({});
  const [tillageIntensities, settillageIntensities] = useState({});

  const [baselineManagement, setBaselineManagement] = useState({});
  const [originalManagement, setOriginalManagement] = useState({});

  const [editingCropEvent, setEditingCropEvent] = useState({});
  const [otherCropEventsForYear, setotherCropEventsForYear] = useState({});
  const [previousCropId, setpreviousCropId] = useState(0);
  const [editingPreviousPlantingDate, setEditingPreviousPlantingDate] =
    useState(null);

  const [otherFieldDataComplete, setOtherFieldDataComplete] = useState({});

  const [editingSingleCrop, setEditingSingleCrop] = useState(false);
  const [copyYearsOpen, setCopyYearsOpen] = useState(false);
  const [reviewInputsOpen, setReviewInputsOpen] = useState(false);
  const [copyFieldPanel, setCopyFieldPanel] = useState(false);

  const [cropTemplatesOpen, setCropTemplatesOpen] = useState(false);
  const [cropYearToCopy, setCropYearToCopy] = useState("");
  const [cpaOpen, setcpaOpen] = useState(false);

  const [areYouSureOpen, setareYouSureOpen] = useState(false);
  const [areYouSureOldYear, setareYouSureOldYear] = useState("0");
  const [areYouSureOldNum, setareYouSureOldNum] = useState(0);
  const [areYouSurenewVal, setareYouSurenewVal] = useState({});

  if (!scenarioName) {
    setCropYears([]);
  }

  const backClicked = () => {
    goToPrevious(stepNumber);
  };

  const openCPA = () => {
    setcpaOpen(true);
  };

  const openCropTemplates = () => {
    setCopyYearsOpen(false);
    setEditingSingleCrop(false);
    setReviewInputsOpen(false);
    setCropTemplatesOpen(true);
  };

  const openCopyFieldPanel = () => {
    setCopyFieldPanel(true);
  };

  const opedReviewInputsPanel = () => {
    setReviewInputsOpen(true);
  };
  const openCopyYear = (year) => {
    setCropYearToCopy(year);
    setCopyYearsOpen(true);
  };

  const checkForComplete = () => {
    let complete = true;
    if (cropYears.length === 0) {
      return false;
    }
    for (let i = 0; i < cropYears.length; i += 1) {
      complete =
        complete &&
        baselineManagement[cropYears[i]] &&
        baselineManagement[cropYears[i]].crops[0].id !== 0;
      if (!complete) {
        break;
      }
    }
    setIsComplete(complete); // tells the step component up one level that it is complete
    return complete;
  };

  const fetchManagement = (signal) => {
    if (id !== 0 && cropRotationId && cropRotationId !== 0) {
      setLoading("Loading...");
      // console.log("starting the fetch on ", cropRotationId, id, new Error());
      // console.log("fetching", cropRotationId, id);
      // should also fetch year-completeness information for all other fields in this scenario for the copy crop information window
      post(CROPLANDS_MANAGEMENT, {
        user: currentUser,
        fieldid: id,
        cropRotationId,
      })
        .then((res) => {
          //  console.log("management: got back", res);
          if (res.error) {
            popupError(`Fetch Crop Management Error ${res.error}`, dispatch);
            setLoading("");
          } else {
            if (signal && signal.aborted) {
              return;
            }
            const inportedMan = { ...res.data.management };

            setBaselineManagement(JSON.parse(JSON.stringify(inportedMan)));

            setOtherFieldDataComplete(
              JSON.parse(JSON.stringify(res.data.otherFieldData))
            );

            setOriginalManagement(JSON.parse(JSON.stringify(inportedMan)));
            setSaveEnabled(false);

            setPossibleCrops(res.data.allManagementOptions.croplist);
            // console.log("rotation", cropRotationId, inportedMan);
            setCropYears(res.data.cropyears ?? []);
            setAllManagement({ ...res.data.allManagementOptions });
            const fertilizerMineralRatiosAssocArray = {};
            for (
              let i = 0;
              i < res.data.fertilizerMineralRatios.length;
              i += 1
            ) {
              fertilizerMineralRatiosAssocArray[
                res.data.fertilizerMineralRatios[i].id
              ] = res.data.fertilizerMineralRatios[i];
            }
            setfertilizerRatios({ ...fertilizerMineralRatiosAssocArray });

            settillageImplements(res.data.tillageImplements);
            settillageIntensities(res.data.tillageIntensities);
          }
          setLoading("");
        })
        .catch((err) => {
          console.log(err);
          setLoading("");
        })
        .finally(() => {});
    } else {
      setBaselineManagement({});
    }
    // fetch historic management from field id
    // and fetch available historic managements for that area for field
  };
  const copyFieldLocationManagement = (listToCopy) => {
    setLoading("Saving...");

    post(COPY_ROTATION, {
      user: currentUser,
      copyFromCropRotationId: cropRotationId,

      copyToFieldIds: JSON.stringify(listToCopy),
    })
      .then((res) => {
        if (res.error) {
          popupError(`Copy Management Save Error ${res.error}`, dispatch);
        } else {
          dispatch(fetchUser(currentUser));
          checkForComplete();
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading("");
      });
  };
  const editCropManagement = (year, cropnum) => {
    // console.log("cehcking on ", { ...baselineManagement[year].crops[cropnum] });

    // find previous crop event, get the planting date
    const localEditingCropEvent = {
      ...baselineManagement[year].crops[cropnum],
    };
    let previousPlanting = null;
    let localpreviousCropId = 0;
    if (baselineManagement[year - 1]) {
      for (let i = 0; i < 3; i += 1) {
        if (baselineManagement[year - 1].crops[i]) {
          if (
            baselineManagement[year - 1].crops[i].id ===
            baselineManagement[year].crops[cropnum].id
          ) {
            previousPlanting =
              baselineManagement[year - 1].crops[i].plantingDate;
            localpreviousCropId = baselineManagement[year - 1].crops[i].id;
          }
        }
      }
    } else {
      const futDate = dayjs(
        baselineManagement[year].crops[cropnum].plantingDate
      );

      previousPlanting = dayjs()
        .set("year", year - 1)
        .set("day", futDate.day())
        .set("month", futDate.month())
        .startOf("day")
        .toISOString();
    }
    setpreviousCropId(localpreviousCropId);
    if (previousPlanting !== null) {
      setEditingPreviousPlantingDate(previousPlanting);
    }

    setEditingCropEvent(localEditingCropEvent);
    setotherCropEventsForYear({
      ...baselineManagement[year],
    });
    setEditingSingleCrop(true);
    // console.log("opening new crop", {      ...baselineManagement[year].crops[cropnum],    });
  };

  const yesEditCrop = () => {
    const newBaselineManagement = { ...baselineManagement };

    const newCrop = {
      id: areYouSurenewVal.id,
      name: areYouSurenewVal.name,
      parentId: areYouSurenewVal.parentId,
      cropNumber: areYouSureOldNum + 1,
      plantingDate: dayjs("1900-1-1").toISOString(),
      cropYear: areYouSureOldYear,
      seedDrill: "water",
      continueFromPrevious: false,
      liming:
        newBaselineManagement[areYouSureOldYear].crops[areYouSureOldNum].liming,
      grazings: [],
      harvests: [],
      irrigations: [],
      bioCharAmendments: [],
      prePlantingTillage: null,
      postHarvestTillage: null,
      otherFieldOperations: [],
      nitrogenApplications: [],
      manureApplications: [],
    };

    newBaselineManagement[areYouSureOldYear].crops[areYouSureOldNum] = newCrop;

    setBaselineManagement(newBaselineManagement);
    if (areYouSurenewVal.id !== 0) {
      editCropManagement(areYouSureOldYear, areYouSureOldNum);
    }
    setareYouSureOpen(false);
    setEditingSingleCrop(true);
  };
  const noEditCrop = () => {
    setareYouSureOpen(false);
  };

  const upperSave = () => {
    if (id !== 0 && cropRotationId !== 0) {
      return post(SAVE_CROPYEARS, {
        user: currentUser,
        fieldid: id,
        managementyears: baselineManagement,
        cropRotationId,
      });
    }
    return true;
  };

  const save = () => {
    if (id !== 0 && cropRotationId !== 0) {
      setLoading("Saving...");
      upperSave()
        .then((res) => {
          if (res.error) {
            popupError(`Saving Crop Management Error ${res.error}`, dispatch);
            setLoading("");
          } else {
            // try the user store fetch here

            dispatch(fetchUser(currentUser));

            fetchManagement();
            fetchProjectInformation();
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading("");
        });
    }
  };

  const handleSubmit = (e) => {
    // console.log("full rotation save is clicked");
    e.preventDefault();
    save();
  };

  const checkForSave = (newBaselineManagement) => {
    // if (checkForComplete()) {
    if (true) {
      let setsave = false;
      // console.log("checking for save");
      Object.keys(newBaselineManagement).forEach((key, index) => {
        for (let i = 0; i < 3; i += 1) {
          for (const k in newBaselineManagement[key].crops[i]) {
            if (
              Object.prototype.hasOwnProperty.call(
                newBaselineManagement[key].crops[i],
                k
              )
            ) {
              /*     console.log(
                "checking",
                k,
                newBaselineManagement[key].crops[i][k]
              ); */
              if (
                newBaselineManagement[key].crops[i][k] !==
                originalManagement[key].crops[i][k]
              ) {
                setsave = true;

                break;
              }
            }
          }
        }
      });

      setSaveEnabled(setsave);
    }
  };
  const updateCropEvent = (cEvent) => {
    const newBaselineManagement = { ...baselineManagement };
    // console.log("trying to save ", cEvent, newBaselineManagement);
    newBaselineManagement[cEvent.cropYear].crops[
      Math.abs(cEvent.cropNumber) - 1
    ] = cEvent;
    // console.log("here is the NEW event", cEvent, newBaselineManagement);
    jsonCopy(newBaselineManagement).then((res) => {
      setBaselineManagement({ ...res });
      if (cEvent.name !== "Select crop...") {
        save();
      }
    });
  };
  const deleteCrop = (year, cropnum) => {
    // console.log("cehcking on ", { ...baselineManagement[year].crops[cropnum] });

    // find previous crop event, get the planting date
    const localEditingCropEvent = {
      ...baselineManagement[year].crops[cropnum],
    };
    //  console.log("here is the local event", localEditingCropEvent);
    // localEditingCropEvent.cropEventId = 0;
    localEditingCropEvent.id = 0;
    updateCropEvent(localEditingCropEvent);

    // console.log("opening new crop", {      ...baselineManagement[year].crops[cropnum],    });
  };
  const checkIfNeedToClear = () => {
    const { plantingDate } =
      baselineManagement[editingCropEvent.cropYear].crops[
        Math.abs(editingCropEvent.cropNumber) - 1
      ];

    if (dayjs(plantingDate) < dayjs("1950-1-1")) {
      // date was never set and the new date wasnt saved so delete that crop event
      const removeAgain = { ...editingCropEvent };
      removeAgain.id = 0;
      removeAgain.name = "Select crop...";
      updateCropEvent(removeAgain);
    }
  };

  const setCropYearNum = (year, cropNum, newValue) => {
    const newBaselineManagement = { ...baselineManagement };

    if (newBaselineManagement[year].crops[cropNum].id !== 0) {
      setareYouSureOldYear(year);
      setareYouSureOldNum(cropNum);
      setareYouSurenewVal(newValue);
      setareYouSureOpen(true);
    } else {
      newBaselineManagement[year].crops[cropNum].id = newValue.id;
      newBaselineManagement[year].crops[cropNum].name = newValue.name;
      newBaselineManagement[year].crops[cropNum].parentId = newValue.parentId;

      setBaselineManagement(newBaselineManagement);
      if (newValue.id !== 0) {
        editCropManagement(year, cropNum);
      }
    }

    checkForSave(newBaselineManagement);
  };

  useEffect(() => {
    if (id) {
      if (allHistoryComplete) {
        const controller = new AbortController();
        fetchManagement(controller.signal);
        return () => {
          // This function is called when the data is changing and the
          // effect callback is about to be re-run
          controller.abort();
        };
      }

      setLoading("Please Complete Historic Management for all Fields");
      return null;
    }

    return null;
  }, [id, cropRotationId, allHistoryComplete]);

  useEffect(() => {
    checkForComplete();
  }, [baselineManagement, cropYears]);

  return (
    <CFBox
      component="form"
      method="post"
      autoComplete="off"
      onSubmit={handleSubmit}
      sx={{
        width: style.width,
        position: "relative",
        marginTop: "1em",
      }}
    >
      {(loadingMessage !== "" ||
        id === 0 ||
        !cropRotationId ||
        cropRotationId === 0) && (
        <CFLoading
          message={loadingMessage}
          local
          showCircle={loadingMessage.indexOf("field") === -1}
        />
      )}{" "}
      <CFBox
        sx={{
          width: style.width,
          position: "relative",
          paddingBottom: "1em",
          paddingRight: ".5em",
        }}
      >
        <CFBox sx={{ display: "flex", alignItems: "center", margin: "1em" }}>
          <CFButton sx={{ margin: "1em" }} onClick={() => openCropTemplates()}>
            Crop Templates
          </CFButton>
          {scenarioName !== "Baseline" && (
            <CFBox
              sx={{ display: "flex", alignItems: "center", margin: "1em" }}
            >
              <CFButton onClick={() => openCPA()}>
                Conservation Practice Advisor
              </CFButton>
              <UserGuidePopup
                // width={userGuidePopupWidth}
                content={CPAHelperText()}
                userGuideLabel="Conservation Practice Advisor"
                variant="h3"
                sx={{ marginTop: ".2em", padding: ".1em" }}
              />
            </CFBox>
          )}
        </CFBox>
        <CFManagementSelectionCard
          label="Year"
          labelWidth={0.9}
          bgColor={colors.primary.main}
          textColor={colors.text.light}
          input={
            <Grid item container spacing={1} style={{ textAlign: "center" }}>
              <Grid xs={3.7} item container style={{ textAlign: "center" }}>
                Planted Crop 1<div style={{}}>✵</div>
              </Grid>
              <Grid xs={3.7} item container style={{ textAlign: "center" }}>
                Planted Crop 2
              </Grid>
              <Grid xs={3.7} item container style={{ textAlign: "center" }}>
                Planted Crop 3
              </Grid>
            </Grid>
          }
        />
        <CFBox>
          {Object.keys(baselineManagement).length !== 0 &&
            cropYears.map((year) => (
              <CFManagementSelectionCard
                label={`${year}`}
                key={`scenario_${year}`}
                labelWidth={0.9}
                input={
                  <Grid
                    item
                    container
                    alignItems="center"
                    justifyContent="flex-end"
                    spacing={1}
                    wrap="nowrap"
                  >
                    <Grid xs={3.7} item container spacing={1}>
                      <Grid xs={9.5} item container>
                        <CFLookupPicker
                          label="Select a crop..."
                          key={`${year}crop1`}
                          name={`${year}crop1`}
                          sx={{ width: "100%" }}
                          value={
                            baselineManagement[year].crops[0].id !== 0
                              ? baselineManagement[year].crops[0]
                              : null
                          }
                          onChange={(event, newValue) => {
                            setCropYearNum(year, 0, newValue);
                          }}
                          options={possibleCrops}
                        />
                      </Grid>
                      <Grid xs={0.5} item>
                        <RequiredComplete
                          complete={baselineManagement[year].crops[0].id !== 0}
                        />
                      </Grid>
                      <Grid xs={0.5} item>
                        <Tooltip title="Edit Crop Management">
                          <span>
                            <IconButton
                              disabled={
                                baselineManagement[year].crops[0].id === 0
                              }
                              onClick={() => editCropManagement(year, 0)}
                              style={{ margin: 0, padding: 0 }}
                            >
                              <EditTwoTone
                                color="black"
                                fontSize="large"
                                style={{
                                  cursor:
                                    baselineManagement[year].crops[0].id === 0
                                      ? "default"
                                      : "pointer",
                                  opacity:
                                    baselineManagement[year].crops[0].id === 0
                                      ? 0.4
                                      : 1,
                                }}
                              />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </Grid>
                    </Grid>

                    <Grid xs={3.7} item container spacing={1}>
                      <Grid xs={9.5} item container>
                        <CFLookupPicker
                          label="Select a crop..."
                          key={`${year}crop2`}
                          name={`${year}crop2`}
                          required
                          sx={{ width: "100%" }}
                          value={
                            baselineManagement[year].crops[1].id !== 0
                              ? baselineManagement[year].crops[1]
                              : null
                          }
                          onChange={(event, newValue) => {
                            setCropYearNum(year, 1, newValue);
                          }}
                          options={possibleCrops}
                        />
                      </Grid>
                      <Grid xs={1} item sx={{ display: "flex" }}>
                        <Tooltip title="Edit Crop Management">
                          <span>
                            <IconButton
                              disabled={
                                baselineManagement[year].crops[1].id === 0
                              }
                              onClick={() => editCropManagement(year, 1)}
                              style={{ margin: 0, padding: 0 }}
                            >
                              <EditTwoTone
                                color="black"
                                fontSize="large"
                                style={{
                                  cursor:
                                    baselineManagement[year].crops[1].id === 0
                                      ? "default"
                                      : "pointer",
                                  opacity:
                                    baselineManagement[year].crops[1].id === 0
                                      ? 0.4
                                      : 1,
                                }}
                              />
                            </IconButton>
                          </span>
                        </Tooltip>
                        <Tooltip title="Delete Crop">
                          <span>
                            <IconButton
                              disabled={
                                baselineManagement[year].crops[1].id === 0
                              }
                              onClick={() => deleteCrop(year, 1)}
                              style={{ margin: 0, padding: 0 }}
                            >
                              <Close
                                fontSize="large"
                                style={{
                                  color:
                                    baselineManagement[year].crops[1].id === 0
                                      ? "black"
                                      : "red",
                                  cursor:
                                    baselineManagement[year].crops[1].id === 0
                                      ? "default"
                                      : "pointer",
                                  opacity:
                                    baselineManagement[year].crops[1].id === 0
                                      ? 0.4
                                      : 1,
                                }}
                              />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </Grid>
                    </Grid>
                    <Grid xs={3.7} item container spacing={1}>
                      <Grid xs={9.5} item container>
                        <CFLookupPicker
                          key={`${year}crop3`}
                          name={`${year}crop3`}
                          required
                          label="Select a crop..."
                          sx={{ width: "100%" }}
                          value={
                            baselineManagement[year].crops[2].id !== 0
                              ? baselineManagement[year].crops[2]
                              : null
                          }
                          onChange={(event, newValue) => {
                            setCropYearNum(year, 2, newValue);
                          }}
                          options={possibleCrops}
                        />
                      </Grid>
                      <Grid xs={1} item sx={{ display: "flex" }}>
                        <Tooltip title="Edit Crop Management">
                          <span>
                            <IconButton
                              disabled={
                                baselineManagement[year].crops[2].id === 0
                              }
                              onClick={() => editCropManagement(year, 2)}
                              style={{ margin: 0, padding: 0 }}
                            >
                              <EditTwoTone
                                color="black"
                                fontSize="large"
                                style={{
                                  cursor:
                                    baselineManagement[year].crops[2].id === 0
                                      ? "default"
                                      : "pointer",
                                  opacity:
                                    baselineManagement[year].crops[2].id === 0
                                      ? 0.4
                                      : 1,
                                }}
                              />
                            </IconButton>
                          </span>
                        </Tooltip>
                        <Tooltip title="Delete Crop">
                          <span>
                            <IconButton
                              disabled={
                                baselineManagement[year].crops[2].id === 0
                              }
                              onClick={() => deleteCrop(year, 2)}
                              style={{ margin: 0, padding: 0 }}
                            >
                              <Close
                                fontSize="large"
                                style={{
                                  color:
                                    baselineManagement[year].crops[2].id === 0
                                      ? "black"
                                      : "red",
                                  cursor:
                                    baselineManagement[year].crops[2].id === 0
                                      ? "default"
                                      : "pointer",
                                  opacity:
                                    baselineManagement[year].crops[2].id === 0
                                      ? 0.4
                                      : 1,
                                }}
                              />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </Grid>
                    </Grid>
                    <Grid item container xs={0.9}>
                      <Tooltip title="Copy Year's Management">
                        <span>
                          <IconButton
                            disabled={
                              baselineManagement[year].crops[0].id === 0 &&
                              baselineManagement[year].crops[1].id === 0 &&
                              baselineManagement[year].crops[2].id === 0
                            }
                            onClick={() => openCopyYear(year)}
                            style={{ margin: 0, padding: 0 }}
                          >
                            <ContentCopy
                              color="black"
                              fontSize="large"
                              style={{
                                cursor:
                                  baselineManagement[year].crops[0].id === 0 &&
                                  baselineManagement[year].crops[1].id === 0 &&
                                  baselineManagement[year].crops[2].id === 0
                                    ? "default"
                                    : "pointer",
                                opacity:
                                  baselineManagement[year].crops[0].id === 0 &&
                                  baselineManagement[year].crops[1].id === 0 &&
                                  baselineManagement[year].crops[2].id === 0
                                    ? 0.4
                                    : 1,
                              }}
                            />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </Grid>
                  </Grid>
                }
              />
            ))}
        </CFBox>
      </CFBox>
      {/* <CFButton
        size="large"
        color="secondary"
        variant="gradient"
        onClick={() => backClicked()}
        sx={{ textTransform: "Select crop..." }}
      >
        Back
      </CFButton> */}
      <div style={{ display: "flex", justifyContent: "center" }}>
        {otherFields.length > 0 && (
          <CFButton
            size="large"
            color="primary"
            variant="gradient"
            onClick={() => openCopyFieldPanel()}
            sx={{ textTransform: "None", margin: "1em" }}
          >
            Copy Rotation to Other Field
          </CFButton>
        )}
        <CFButton
          size="large"
          color="primary"
          variant="gradient"
          onClick={() => opedReviewInputsPanel()}
          sx={{ textTransform: "None", margin: "1em" }}
        >
          Review Inputs
        </CFButton>
      </div>
      <ConfirmCropChangePopup
        areYouSureOpen={areYouSureOpen}
        noEditCrop={noEditCrop}
        areYouSurenewVal={areYouSurenewVal}
        yesEditCrop={yesEditCrop}
        oldValue={
          baselineManagement[areYouSureOldYear]?.crops[areYouSureOldNum]
        }
      />
      <EditCropCard
        isOpen={editingSingleCrop}
        setIsOpen={setEditingSingleCrop}
        scenarioName={scenarioName}
        allManagementOptions={allManagement}
        checkIfNeedToClear={checkIfNeedToClear}
        cropEvent={editingCropEvent}
        fullCropYear={otherCropEventsForYear}
        previousCrop={previousCropId}
        fertilizerRatios={fertilizerRatios}
        stepNumber=""
        updateCropEvent={updateCropEvent}
        tillageImplements={tillageImplements}
        tillageIntensities={tillageIntensities}
        field={field}
        previousPlantingDate={editingPreviousPlantingDate}
      />
      {/*
      // so copy year. should just plug into the existing backend on this
      // but we need information on the other fields. which crop years are defined or not
              */}
      <CopyYearsCard
        isOpen={copyYearsOpen && !editingSingleCrop}
        setIsOpen={setCopyYearsOpen}
        selectedCropYear={cropYearToCopy}
        selectedField={id}
        fieldAndCropYearFinishedData={otherFieldDataComplete}
        fetchManagement={fetchManagement}
        scenarioName={scenarioName}
        setLoading={setLoading}
        upperSave={upperSave}
      />
      <PredefinedTemplatesWindow
        isOpen={cropTemplatesOpen}
        setIsOpen={setCropTemplatesOpen}
        currentUser={currentUser}
        fieldId={id}
        cropRotationId={cropRotationId}
        fetchManagement={fetchManagement}
        allManagementOptions={allManagement}
      />
      <ReviewInputsCard
        isOpen={reviewInputsOpen}
        setIsOpen={setReviewInputsOpen}
        baselineManagement={baselineManagement}
        locationName={name}
        scenarioName={scenarioName}
        stepNumber={stepNumber}
      />
      <CPACard
        isOpen={cpaOpen}
        setIsOpen={setcpaOpen}
        locationName={name}
        scenarioName={scenarioName}
        coverCrops={allManagement.cpa_cover_crop_list}
        coverCropsAndFertReduction={
          allManagement.cpa_cover_crop_and_fert_reduction_list
        }
        currentUser={currentUser}
        fieldId={id}
        cropRotationId={cropRotationId}
        fetchManagement={fetchManagement}
      />
      <CopyHistoryCard
        title="Management"
        isOpen={copyFieldPanel}
        setIsOpen={setCopyFieldPanel}
        locationName={name}
        fieldsInRegion={otherFields}
        copyFieldsHistories={copyFieldLocationManagement} // pipe in the new copy-to-field function
      />
    </CFBox>
  );
}
export default CropRotationManagement;
