// MUI
import {
  Grid,
  RadioGroup,
  Radio,
  FormControlLabel,
  Link as ExternalLink,
} from "@mui/material";

// React-Redux
import { useDispatch, useSelector } from "react-redux";

// React
import { useEffect, useState } from "react";

// Miscellaneous
import dayjs from "dayjs";
import JSZip from "jszip";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

// API Calls
import {
  FETCH_VERSION,
  EMAIL_EXPORT_PROJECT,
  DOWNLOAD_EXPORT_PROJECT,
  DOWNLOAD_PROJECT,
} from "constants/api";
import { post } from "utils/api";
import { updateLoading } from "store/reducers/mask";
import { popupError } from "utils/generic";

// CFarm Components
import CFInput from "components/CFInput";
import CFLink from "components/CFLink/CFLink";
import CFarmPage from "components/CFarmParts/CFarmPage";
import CFBox from "components/CFBox";
import CFButton from "components/CFButton";
import CFTabView from "components/CFTabView";
import CFTypography from "components/CFTypography";
import CFDialog from "components/CFDialog";
import { MdOutlineNotificationImportant } from "react-icons/md";

// Local Components
import { displayedActivities } from "pages/ProjectManagement/ChooseActivities/constants";
import Roadmap from "components/CFarmParts/CFarmPage/CFRoadmap/Roadmap";

// Report Components

import { selectedActivities } from "components/CFarmParts/CFarmPage/CFRoadmap/roadmap.config";
import { Link } from "react-router-dom";
import AgroforestryReport from "./Agroforestry/AgroforestryReport";
import ForestryReport from "./Forestry/ForestryReport";
import CroplandsReport from "./Croplands/CroplandsReport";
import AnimalAgReport from "./AnimalAgriculture/AnimalAgReport";

function ProjectReport() {
  const dispatch = useDispatch();

  // User info
  const currentUser = useSelector((state) => state.user);
  const [softwareVer, setSoftwareVer] = useState("Loading...");
  const lastActivity = selectedActivities();

  useEffect(() => {
    post(FETCH_VERSION)
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
        } else {
          setSoftwareVer(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // List of activities selected for current project
  const [activityList, setActivities] = useState(() =>
    currentUser.activeProject.activities !== undefined
      ? currentUser.activeProject.activities
      : []
  );
  useEffect(() => {
    setActivities(() =>
      currentUser.activeProject.activities !== undefined
        ? currentUser.activeProject.activities
        : []
    );
  }, [currentUser]);

  // Current date
  const currentDate = dayjs().format("LLLL");

  // Status/Interpret popup
  const [localPopupState, updateLocalPopup] = useState({});

  // Download report data
  const [verboseDisplay, setVerbose] = useState(false);
  async function GetModuleGraphImage(graphId) {
    const g = await document.createElement("g");
    await document.body.appendChild(g);
    await g.setAttribute("id", `module-graphs-${graphId}`);

    const graphList = await document.querySelectorAll(graphId);
    await Promise.all(
      [...graphList].map(async (graph) => {
        const newNode = await document.importNode(graph, true);
        await g.appendChild(newNode);
      })
    );

    // Get screen grab
    const canvas = await html2canvas(
      await document.getElementById(`module-graphs-${graphId}`)
    );

    // Cleanup
    document.body.removeChild(g);

    const imgData = canvas.toDataURL("image/png");
    const doc = new jsPDF("l", "px", "a4"); // eslint-disable-line
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();

    const widthRatio = pageWidth / canvas.width;
    const heightRatio = pageHeight / canvas.height;
    const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

    const canvasWidth = canvas.width * ratio * 0.95;
    const canvasHeight = canvas.height * ratio * 0.95;

    const marginX = (pageWidth - canvasWidth) / 2;
    const marginY = (pageHeight - canvasHeight) / 2;

    // Create PDF and add to zip
    doc.addImage(imgData, "JPEG", marginX, marginY, canvasWidth, canvasHeight);

    return doc;
  }
  async function DownloadReport() {
    dispatch(updateLoading({ loading: true }));

    post(DOWNLOAD_PROJECT, currentUser?.activeProject.id)
      .then(async (res) => {
        if (res.error) {
          popupError(res.error, dispatch);
        } else {
          const zip = new JSZip();
          const fileZip = zip.folder(
            `COMET-Farm_Project_${currentUser?.activeProject.id}`
          );

          // Add each file to the zip folder
          const fileList = res.data;
          await Promise.all(
            fileList.map(async (file) => {
              const fileName = file.name;
              const fileData = file.data;

              // Create a blob of each file and add it to the zip folder
              const blob = new Blob([fileData], { fileData });

              fileZip.file(fileName, blob);
            })
          );

          // Get PDF of graphs, add to zip
          await Promise.all(
            displayedActivities.map(async (activityType) => {
              if (
                activityList.map((x) => x.activity).includes(activityType.id)
              ) {
                // Add module graphs to node
                switch (activityType.id) {
                  case 10: {
                    // Croplands
                    const imageData = await GetModuleGraphImage(
                      "#graphDownload_croplands"
                    );

                    const blob = await imageData.output("blob");
                    fileZip.file("croplands_graphical.pdf", blob);
                    break;
                  }
                  case 13: {
                    // Agroforestry
                    const imageData = await GetModuleGraphImage(
                      "#graphDownload_agroforestry"
                    );

                    const blob = await imageData.output("blob");
                    fileZip.file("agroforestry_graphical.pdf", blob);
                    break;
                  }
                  case 14: {
                    // Forestry
                    const imageData = await GetModuleGraphImage(
                      "#graphDownload_forestry"
                    );

                    const blob = await imageData.output("blob");
                    fileZip.file("forestry_graphical.pdf", blob);
                    break;
                  }
                  case 212320: {
                    // Animal Ag
                    const imageData = await GetModuleGraphImage(
                      "#graphDownload_animalAg"
                    );

                    const blob = await imageData.output("blob");
                    fileZip.file("animalAg_graphical.pdf", blob);
                    break;
                  }
                  default:
                    break;
                }
              }
            })
          );

          // Download the zip folder
          zip.generateAsync({ type: "blob" }).then((content) => {
            const a = document.createElement("a");
            a.style.display = "none";
            document.body.appendChild(a);
            // Set the HREF to a Blob representation of the data to be downloaded
            a.href = window.URL.createObjectURL(content);
            // Use download attribute to set desired file name
            a.setAttribute(
              "download",
              `COMET-Farm_Project_${currentUser?.activeProject.id}`
            );

            // Trigger the download by simulating click
            a.click();

            // Cleanup
            window.URL.revokeObjectURL(a.href);
            document.body.removeChild(a);
          });
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setVerbose(false);
        dispatch(updateLoading({ loading: false }));
      });
  }

  // Email project to user
  const [exportDestination, setExportDestination] = useState("local");
  const [alternateEmail, setAlternateEmail] = useState("");
  function ExportProject() {
    dispatch(updateLoading({ loading: true }));

    if (exportDestination === "local") {
      post(DOWNLOAD_EXPORT_PROJECT, currentUser?.activeProject?.id)
        .then((res) => {
          if (res.error) {
            popupError(res.error, dispatch);
          } else {
            const text = res.data;
            const a = document.createElement("a");
            a.style.display = "none";
            document.body.appendChild(a);

            // Set the HREF to a Blob representation of the data to be downloaded
            a.href = window.URL.createObjectURL(new Blob([text], { text }));

            // Use download attribute to set set desired file name
            a.setAttribute(
              "download",
              `export_${currentUser?.activeProject?.id}.cmt`
            );

            // Trigger the download by simulating click
            a.click();

            // Cleanup
            window.URL.revokeObjectURL(a.href);
            document.body.removeChild(a);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          dispatch(updateLoading({ loading: false }));
        });
    } else {
      post(EMAIL_EXPORT_PROJECT, {
        ProjectId: currentUser?.activeProject?.id,
        ExportDestination: exportDestination,
        AlternateEmail: alternateEmail,
      })
        .then((res) => {
          if (res.error) {
            popupError(res.error, dispatch);
          } else {
            updateLocalPopup({
              // Popup is simple so can use the global popup
              title: "Project Export Started",
              message: "Please check the indicated email for the results",
              type: "OK",
              showPopup: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          dispatch(updateLoading({ loading: false }));
        });
    }
  }
  useEffect(() => {
    if (localPopupState && localPopupState.showPopup) {
      updateLocalPopup({
        title: "Project Export",
        children: [
          <Grid container px={2}>
            <CFTypography
              variant="body2"
              color="secondary"
              fontWeight="regular"
              pt={2}
            >
              Select a location for the exported project to be sent:
            </CFTypography>
            <Grid container direction="column" alignItems="center" py={2}>
              <RadioGroup
                value={exportDestination}
                onChange={(e) => {
                  setExportDestination(e.target.value);
                }}
              >
                <FormControlLabel
                  value="local"
                  control={<Radio />}
                  label="Download locally to this computer"
                />
                <FormControlLabel
                  value="accountEmail"
                  control={<Radio />}
                  label="Use the email associated with my account"
                />
                <Grid container direction="row" alignItems="center">
                  <FormControlLabel
                    value="otherEmail"
                    control={<Radio />}
                    label="Use an alternate email:"
                  />
                  <CFInput
                    label="Alternate Email"
                    color="primary"
                    placeholder="Alternate Email"
                    size="small"
                    disabled={exportDestination !== "otherEmail"}
                    value={alternateEmail}
                    onChange={(e) => {
                      setAlternateEmail(e.target.value);
                    }}
                  />
                </Grid>
              </RadioGroup>
            </Grid>
          </Grid>,
        ],
        button1: {
          text: "Export",
          onClick: () => {
            ExportProject();
            setExportDestination("local");
            setAlternateEmail("");
            updateLocalPopup({ showPopup: false });
          },
        },
        showPopup: true,
        closeAction: () => {
          setExportDestination("local");
          setAlternateEmail("");
          updateLocalPopup({ showPopup: false });
        },
      });
    }
  }, [exportDestination, alternateEmail]);

  // Store the report data at the top level, so we don't have to fetch again on re-render
  const [croplandsReport, setCroplandsReport] = useState(null);
  const [waterHoldingReport, setWaterHoldingReport] = useState(null);
  const [anAgReport, setAnAgReport] = useState(null);
  const [agroforestryReport, setAgroReport] = useState(null);
  const [forestryReport, setForestryReport] = useState(null);

  // Create array of tab objects dynamically
  function CreateTabs() {
    const tempTabs = { TabsList: [] };

    displayedActivities.forEach((activityType) => {
      if (activityList.map((x) => x.activity).includes(activityType.id)) {
        let tab = {};

        // Detemine correct report component
        switch (activityType.id) {
          case 10: // Croplands
            tab = {
              Tab: activityType.name,
              Data: (
                <CroplandsReport
                  reportInfo={croplandsReport}
                  setReport={setCroplandsReport}
                  waterHoldingInfo={waterHoldingReport}
                  setWaterHoldingInfo={setWaterHoldingReport}
                />
              ),
            };
            break;
          case 13: // Agroforestry
            tab = {
              Tab: activityType.name,
              Data: (
                <AgroforestryReport
                  reportInfo={agroforestryReport}
                  setReport={setAgroReport}
                />
              ),
            };
            break;
          case 14: // Forestry
            tab = {
              Tab: activityType.name,
              Data: (
                <ForestryReport
                  reportInfo={forestryReport}
                  setReport={setForestryReport}
                />
              ),
            };
            break;
          case 212320: // Animal Ag
            tab = {
              Tab: activityType.name,
              Data: (
                <AnimalAgReport
                  reportInfo={anAgReport}
                  setReport={setAnAgReport}
                />
              ),
            };
            break;
          default:
            break;
        }

        // Add tab to array
        tempTabs.TabsList.push(tab);
      }
    });

    if (tempTabs.TabsList.length === 0) {
      const defaultTab = {
        Tab: "Project Report(s)",
        Data: (
          <Grid container direction="column" columns={1} alignItems="center">
            Loading...
          </Grid>
        ),
      };
      tempTabs.TabsList.push(defaultTab);
    }

    return tempTabs;
  }
  const [ReportTabs, setReportTabs] = useState(CreateTabs());
  useEffect(() => {
    setReportTabs(CreateTabs());
  }, [
    activityList,
    croplandsReport,
    waterHoldingReport,
    anAgReport,
    agroforestryReport,
    forestryReport,
  ]);

  useEffect(() => {
    updateLocalPopup({
      title: (
        <>
          <MdOutlineNotificationImportant style={{ color: "red" }} />
          {" Important "}
          <MdOutlineNotificationImportant style={{ color: "red" }} />
        </>
      ),
      children: [
        <Grid container px={2}>
          <CFTypography
            variant="body2"
            color="secondary"
            fontWeight="bold"
            pt={2}
          >
            Reported values are still undergoing the verification process.
            Please do not use or share these values for any research, outreach,
            or private business. Thank you for your cooperation.
          </CFTypography>
        </Grid>,
      ],
      showPopup: true,
      closeAction: () => {
        updateLocalPopup({ showPopup: false });
      },
    });
  }, []);

  return (
    <CFarmPage>
      {/* Project Roadmap */}
      <Roadmap />

      {/* Report Body */}
      <CFBox
        id="Report Body"
        sx={{ width: "100%", maxWidth: "94vw", marginBottom: "5%" }}
      >
        <Grid container item direction="column" alignItems="center">
          {/* Project Report Header */}
          <Grid
            key="ProjectReportHeader"
            item
            container
            direction="column"
            pb={1}
          >
            {/* Title */}
            <Grid>
              <CFBox>
                <CFTypography variant="h4">COMET-Farm Report</CFTypography>
              </CFBox>
            </Grid>
            <Grid item container pl={3}>
              {/* User Name */}
              <Grid item container direction="row">
                <CFTypography variant="h5">User:</CFTypography>
                <CFTypography variant="h5" fontWeight="regular" pl={1}>
                  {`${currentUser?.firstName} ${currentUser?.lastName}`}
                </CFTypography>
              </Grid>
              {/* Project Name */}
              <Grid item container direction="row">
                <CFTypography variant="h5">Project Name:</CFTypography>
                <CFTypography variant="h5" fontWeight="regular" pl={1}>
                  {`${currentUser?.activeProject?.name}`}
                </CFTypography>
              </Grid>
              {/* App Version */}
              <Grid item container direction="row">
                <CFTypography variant="h5">Version:</CFTypography>
                <CFTypography variant="h5" fontWeight="regular" pl={1}>
                  {`${softwareVer}`}
                </CFTypography>
              </Grid>
              {/* Timestamp */}
              <Grid item container direction="row">
                <CFTypography variant="h5">Date:</CFTypography>
                <CFTypography variant="h5" fontWeight="regular" pl={1}>
                  {`${currentDate}`}
                </CFTypography>
              </Grid>
            </Grid>
          </Grid>
          {/* Project report body */}
          <CFBox sx={{ width: "100%" }}>
            <CFTabView verbose={verboseDisplay}>{ReportTabs}</CFTabView>
          </CFBox>
          {/* Action buttons */}
          <Grid container key="ActionButtons" direction="row" pt={1}>
            {/* Left buttons */}
            <Grid
              container
              direction="row"
              item
              xs={2}
              pr={1}
              justifyContent="flex-start"
            >
              {/* Go Back To Editing */}
              <Grid>
                <CFButton
                  variant="gradient"
                  color="secondary"
                  to={lastActivity?.slice(-1)[0]?.to || "/"}
                  component={Link}
                >
                  Back to Management
                </CFButton>
              </Grid>
            </Grid>
            {/* Right buttons */}
            <Grid
              container
              direction="row"
              item
              xs={10}
              pr={1}
              justifyContent="flex-end"
            >
              {/* GHG Equivalencies Calculator */}
              <Grid item pr={1}>
                <ExternalLink
                  href="https://www.epa.gov/energy/greenhouse-gas-equivalencies-calculator"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <CFButton variant="gradient" color="primary">
                    GHG Equivalencies Calculator
                  </CFButton>
                </ExternalLink>
              </Grid>
              {/* Interpret */}
              <Grid item pl={1}>
                <CFButton
                  variant="gradient"
                  color="primary"
                  onClick={() =>
                    updateLocalPopup({
                      title: "Interpret COMET-Farm Reports",
                      children: [
                        <Grid container px={2}>
                          <CFTypography
                            variant="body2"
                            color="secondary"
                            fontWeight="regular"
                            pt={2}
                          >
                            Select from one of the option(s) below for more
                            information on interpreting your report(s).
                          </CFTypography>
                          <Grid container direction="column" py={2}>
                            {displayedActivities.map((activityType) => {
                              if (
                                activityList
                                  .map((x) => x.activity)
                                  .includes(activityType.id)
                              ) {
                                let info = {};

                                // Detemine correct report component
                                switch (activityType.id) {
                                  case 10: // Croplands
                                    info = {
                                      to: "/report",
                                      label: "Croplands",
                                    };
                                    break;
                                  case 13: // Agroforestry
                                    info = {
                                      to: "/report",
                                      label: "Agroforestry",
                                    };
                                    break;
                                  case 14: // Forestry
                                    info = {
                                      to: "/report",
                                      label: "Forestry",
                                    };
                                    break;
                                  case 212320: // Animal Ag
                                    info = {
                                      to: "/report",
                                      label: "Animal Agriculture",
                                    };
                                    break;
                                  default:
                                    break;
                                }

                                return (
                                  <Grid
                                    item
                                    py={1}
                                    container
                                    direction="column"
                                    alignItems="center"
                                    key={`activity_${activityType.id}`}
                                  >
                                    <CFLink {...info} />
                                  </Grid>
                                );
                              }
                              return null;
                            })}
                          </Grid>
                        </Grid>,
                      ],
                      showPopup: true,
                    })
                  }
                >
                  Interpret Report
                </CFButton>
              </Grid>
              {/* Download */}
              <Grid item px={1}>
                <CFButton
                  variant="gradient"
                  color="progress"
                  onClick={() => setVerbose(true, DownloadReport())}
                >
                  Download Report
                </CFButton>
              </Grid>
              {/* Export */}
              <Grid item pr={1}>
                <CFButton
                  variant="gradient"
                  color="progress"
                  onClick={() =>
                    updateLocalPopup({
                      title: "Project Export",
                      children: [
                        <Grid container px={2}>
                          <CFTypography
                            variant="body2"
                            color="secondary"
                            fontWeight="regular"
                            pt={2}
                          >
                            Select a location for the exported project to be
                            sent:
                          </CFTypography>
                          <Grid
                            container
                            direction="column"
                            alignItems="center"
                            py={2}
                          >
                            <RadioGroup
                              value={exportDestination}
                              onChange={(e) => {
                                setExportDestination(e.target.value);
                              }}
                            >
                              <FormControlLabel
                                value="local"
                                control={<Radio />}
                                label="Download locally to this computer"
                              />
                              <FormControlLabel
                                value="accountEmail"
                                control={<Radio />}
                                label="Use the email associated with my account"
                              />
                              <Grid
                                container
                                direction="row"
                                alignItems="center"
                              >
                                <FormControlLabel
                                  value="otherEmail"
                                  control={<Radio />}
                                  label="Use an alternate email:"
                                />
                                <CFInput
                                  label="Alternate Email"
                                  color="primary"
                                  placeholder="Alternate Email"
                                  size="small"
                                  disabled={exportDestination !== "otherEmail"}
                                  value={alternateEmail}
                                  onChange={(e) => {
                                    setAlternateEmail(e.target.value);
                                  }}
                                />
                              </Grid>
                            </RadioGroup>
                          </Grid>
                        </Grid>,
                      ],
                      button1: {
                        text: "Export",
                        onClick: () => {
                          ExportProject();

                          setExportDestination("local");
                          setAlternateEmail("");
                          updateLocalPopup({ showPopup: false });
                        },
                      },
                      showPopup: true,
                      closeAction: () => {
                        setExportDestination("local");
                        setAlternateEmail("");
                        updateLocalPopup({ showPopup: false });
                      },
                    })
                  }
                >
                  Export Project
                </CFButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* Report Action popup */}
        {localPopupState && localPopupState.showPopup && (
          <CFDialog
            {...localPopupState}
            updatePopup={(e) => updateLocalPopup(e)}
          />
        )}
      </CFBox>
    </CFarmPage>
  );
}
export default ProjectReport;
