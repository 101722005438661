const { default: styled } = require("@emotion/styled");
const { DatePicker } = require("@mui/x-date-pickers-pro");

export default styled(DatePicker)`
  & .MuiInputBase-input {
    font-size: 1.75em;
    background-color: white;
    padding: 0.25em;
    min-width: 5em;
  }
`;
