import { createSlice } from "@reduxjs/toolkit";
import { SetStateFromPayload } from "../../utils/generic";
import { takeIfExists } from "../../utils/localStorage";

const initialProjectState = {
  name: "LOADING",
  id: -1,
  activities: [10, 13, 14, 212320],
};

// eslint-disable-next-line no-unused-vars
const initialState = {
  id: takeIfExists("id"),
  firstName: "",
  lastName: "",
  emailId: "",
  apiKey: "",
  userName: "",
  devAccess: false,
  apiLimit: 0,
  apiLimitRemaining: 0,
  purchasedAPIRuns: 0,
  isVerifiedByEmail: false,
  activeProject: initialProjectState,
};

const user = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateUser(state, action) {
      // eslint-disable-next-line no-param-reassign
      SetStateFromPayload(state, action.payload, initialState);
    },

    receiveUser(state, action) {
      // eslint-disable-next-line no-param-reassign
      SetStateFromPayload(state, action.payload, initialState);
    },
  },
});

export default user.reducer;

export const { updateUser, receiveUser } = user.actions;
