/* eslint-disable react/no-array-index-key */

import { Grid, Input, InputAdornment, TextField } from "@mui/material";

// Date picker
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";

import colors from "assets/theme/base/colors";
import CFManagementSelectionCard from "components/CroplandsComponents/CFManagementSelectionCard/CFManagementSelectionCard";

import GrazeHelp1 from "assets/cFarmImages/HelpImages/grazeHelp1.webp";
import GrazeHelp2 from "assets/cFarmImages/HelpImages/grazeHelp2.webp";
import { preloadImage } from "utils/generic";
import HelpAndButton from "../../../../../components/CroplandsComponents/EditCropCard_StructuralComponents/HelpAndButton";

import DeleteButton from "../../../../../components/CroplandsComponents/EditCropCard_StructuralComponents/DeleteButton";
import WhiteBackgroundDatepicker from "../../../../../components/CroplandsComponents/EditCropCard_StructuralComponents/WhiteBackgroundDatepicker";

export default function ({ CropEvent, SetPropertyFunction, FieldName }) {
  preloadImage(GrazeHelp1);
  preloadImage(GrazeHelp2);
  const minDate = dayjs()
    .set("year", CropEvent.cropYear)
    .set("date", 1)
    .set("month", 0);
  const maxDate = dayjs()
    .set("year", CropEvent.cropYear + 2)
    .set("date", 1)
    .set("month", 0);

  const setdailyUtilization = (newVal, index) => {
    let setVal = newVal;
    if (setVal > 100) {
      setVal = 100;
    }
    if (setVal < 0) {
      setVal = 0;
    }
    const newCE = { ...CropEvent };
    newCE.grazings[index].utilizationPct = setVal;
    SetPropertyFunction(newCE);
  };

  const setRestDays = (newVal, index) => {
    let setVal = newVal;
    if (setVal < 0) {
      setVal = 0;
    }
    const newCE = { ...CropEvent };
    newCE.grazings[index].restPeriod = setVal;
    SetPropertyFunction(newCE);
  };

  const setAnimalUnits = (newVal, index) => {
    let setVal = newVal;
    if (setVal < 0) {
      setVal = 0;
    }
    const newCE = { ...CropEvent };
    newCE.grazings[index].animalUnits = setVal;
    SetPropertyFunction(newCE);
  };

  const addNewGrazingEvent = () => {
    const newCE = { ...CropEvent };

    newCE.grazings.push({
      grazingStart: minDate.toISOString(),
      animalUnits: 0,
      grazingEnd: minDate.toISOString(),
      restPeriod: 0,
      utilizationPct: 0,

      id: 0,
    });

    SetPropertyFunction(newCE);
  };

  const deleteIrrigation = (index) => {
    const newCE = { ...CropEvent };
    newCE.grazings.splice(index, 1);

    SetPropertyFunction(newCE);
  };

  const setStartDate = (newVal, index) => {
    if (dayjs(newVal, true).isValid()) {
      const newCE = { ...CropEvent };
      const startDate = dayjs(newVal);
      let endDate = dayjs(newCE.grazings[index].grazingEnd);
      if (endDate <= startDate) {
        endDate = startDate.add(1, "day");
      }

      newCE.grazings[index].grazingStart = startDate.toISOString();
      newCE.grazings[index].grazingEnd = endDate.toISOString();
      SetPropertyFunction(newCE);
    }
  };

  const setEndDate = (newVal, index) => {
    if (dayjs(newVal, true).isValid()) {
      const newCE = { ...CropEvent };
      const endDate = dayjs(newVal);
      let startDate = dayjs(newCE.grazings[index].grazingStart);
      if (endDate <= startDate) {
        startDate = endDate.add(-1, "day");
      }
      newCE.grazings[index].grazingStart = startDate.toISOString();
      newCE.grazings[index].grazingEnd = endDate.toISOString();
      SetPropertyFunction(newCE);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid alignItems="center" container flexDirection="row">
        <Grid item xs={12}>
          {CropEvent.grazings.map((app, ind) => (
            <Grid
              item
              xs={12}
              container
              flexDirection="row"
              alignItems="center"
              key={`grazing${ind}`}
              sx={{ paddingTop: ".5em", marginTop: "1em" }}
            >
              <Grid
                item
                xs={12}
                container
                flexDirection="row"
                alignItems="center"
              >
                <Grid item xs={11} xl={5.5}>
                  <CFManagementSelectionCard
                    label="Start Date"
                    labelWidth={6}
                    input={
                      <WhiteBackgroundDatepicker
                        views={["year", "month", "day"]}
                        label="Start Date"
                        maxDate={maxDate}
                        minDate={minDate}
                        style={{ backgroundColor: colors.white.main }}
                        slotProps={{ textField: { variant: "outlined" } }}
                        value={dayjs(app.grazingStart)}
                        onChange={(newValue) => setStartDate(newValue, ind)}
                      />
                    }
                  />
                </Grid>{" "}
                <Grid item xs={11} xl={5.5}>
                  <CFManagementSelectionCard
                    label="End Date"
                    labelWidth={6}
                    input={
                      <WhiteBackgroundDatepicker
                        views={["year", "month", "day"]}
                        label="End Date"
                        maxDate={maxDate}
                        minDate={minDate}
                        style={{ backgroundColor: colors.white.main }}
                        slotProps={{ textField: { variant: "outlined" } }}
                        value={dayjs(app.grazingEnd)}
                        onChange={(newValue) => setEndDate(newValue, ind)}
                      />
                    }
                  />
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                container
                flexDirection="row"
                alignItems="center"
              >
                {/*  <Grid item xs={11} xl={5.5} xxl={3}>
                  <CFManagementSelectionCard
                    label="Animal Units"
                    labelWidth={8}
                    input={
                      <Input
                        label="units"
                        type="number"
                        style={{
                          backgroundColor: colors.white.main,
                          fontSize: "1.25em",
                        }}
                        value={app.animalUnits}
                        onChange={(e) => setAnimalUnits(e.target.value, ind)}
                      />
                    }
                  />
                </Grid> */}
                <Grid item xs={11} xl={5.5}>
                  <CFManagementSelectionCard
                    label="Daily Utilization %"
                    labelWidth={5}
                    input={
                      <Input
                        label="%"
                        endAdornment={
                          <InputAdornment position="end">%</InputAdornment>
                        }
                        type="number"
                        sx={{
                          backgroundColor: colors.white.main,
                          fontSize: "1.25em",
                          paddingInline: ".3em",
                          borderRadius: ".3em",
                        }}
                        value={app.utilizationPct}
                        onChange={(e) =>
                          setdailyUtilization(e.target.value, ind)
                        }
                      />
                    }
                  />
                </Grid>
                <Grid item xs={11} xl={5.5}>
                  <CFManagementSelectionCard
                    label="Rest Days"
                    labelWidth={8}
                    input={
                      <Input
                        label="units"
                        type="number"
                        sx={{
                          backgroundColor: colors.white.main,
                          fontSize: "1.25em",
                          paddingInline: ".3em",
                          borderRadius: ".3em",
                        }}
                        value={app.restPeriod}
                        onChange={(e) => setRestDays(e.target.value, ind)}
                      />
                    }
                  />
                </Grid>

                <Grid item xs={1}>
                  <DeleteButton deleteAction={() => deleteIrrigation(ind)} />
                </Grid>
              </Grid>
            </Grid>
          ))}
          <HelpAndButton
            buttonLabel="+ Add Grazing Event"
            buttonAction={addNewGrazingEvent}
            userGuidePopupWidth="900px"
            helperText={
              <div style={{ overflow: "auto", height: "750px" }}>
                <p>
                  {" "}
                  The inclusion of a grazing event within the
                  cropland/pasture/range/orchard/vineyard module means that
                  soil-related emissions and removal as a result of grazing will
                  be accounted for.{" "}
                  <strong>
                    An assessment of the enteric or manure emissions associated
                    with any grazing livestock will have to be accounted for
                    separately in the Animal Agriculture module
                  </strong>
                  .
                </p>
                <br />
                <p>
                  The <em>Start Date</em> refers to the date that the livestock
                  are first put on the field to graze, and the <em>End Date</em>{" "}
                  is the date the livestock are removed from the property and
                  the day grazing ends. <em>Daily utilization %</em> allows
                  users to enter the percent of aboveground forage utilized
                  (chewed down) by the livestock during a given day of grazing.
                </p>
                <br />
                <p>
                  Grazing <em>rest days</em> indicate the average approximate
                  number of days before livestock return to graze an individual
                  patch of ground within the field, either because they are free
                  to roam in the field and the grass has regrown enough since it
                  was last grazed to attract them back, or because they were
                  moved back onto the field at that planned time interval. If
                  rest days are entered in a grazing period that is longer than
                  one day, the system will simulate one day of grazing followed
                  by the number of rest days entered, and that sequence will
                  repeat though the end date (see the Intensive Rotational
                  example below). If no rest days are entered, it is assumed
                  that the parcel will be continuously grazed between the start
                  and end dates of a grazing period. Any days of the year not
                  included in a grazing period are treated as rest periods (see
                  the Extensive Rotational example below).
                </p>
                <br />
                <p>
                  <strong>Intensive rotational example</strong>: Livestock graze
                  for a single day on each parcel, separated by rest periods -
                  for example, a pasture is separated into twenty-one paddocks
                  and livestock rotate through the paddocks, grazing for only
                  one day in each paddock and returning twenty-one days later to
                  graze again.
                </p>
                <img
                  src={GrazeHelp1}
                  width="100%"
                  alt="Grazing for one day only example"
                />
                <br />
                <p>
                  <strong>Extensive rotational example</strong>: Year-round
                  extensive rotational grazing with 1 month grazing periods
                  followed by 3 months of rest.
                </p>{" "}
                <img
                  src={GrazeHelp2}
                  width="100%"
                  alt="Grazing for one month on and 3 months off"
                />
              </div>
            }
          />{" "}
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
}
