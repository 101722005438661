import { useEffect, useState } from "react";

import { Box } from "@mui/system";
import CFButton from "components/CFButton";
import { DELETE } from "constants/mapButtons";

export default function CFMapGridFooter({
  showButtons,
  toggleDeleteButtons,
  toggleResetToggle,
  setButtonClickObj,
  getDeleteFeatureList,
  fieldLocationNumber,
}) {
  const handleOnCancel = () => {
    toggleDeleteButtons();
    toggleResetToggle();
  };

  const handleOnDelete = () => {
    if (getDeleteFeatureList().length > 0)
      setButtonClickObj({
        buttonType: DELETE,
        feature: getDeleteFeatureList(),
      });
  };

  const [height, setHeight] = useState();
  const setPercentHeight = () => {
    let percentHeight = ((50 - (50 - fieldLocationNumber)) / 50) * 15;
    if (percentHeight < 4) percentHeight = 4;
    percentHeight += "em";
    setHeight(percentHeight);
  };

  useEffect(() => {
    if (showButtons) {
      setPercentHeight();
    } else {
      setHeight("");
    }
  }, [showButtons]);
  return (
    <Box
      sx={{
        p: 1,
        display: "flex",
        height,
        width: "100%",
        justifyContent: "center",
        overflowX: "auto",
        whiteSpace: "nowrap",
        overflowY: "hidden",
      }}
    >
      {showButtons && (
        <div>
          <CFButton
            color="secondary"
            sx={{
              width: "6.8em",
              height: "1em",
              margin: ".1em",
              fontSize: ".9rem",
            }}
            onClick={handleOnCancel}
          >
            Cancel
          </CFButton>
          <CFButton
            color="progress"
            sx={{
              width: "10.8em",
              height: "1em",
              margin: ".1em",
              fontSize: ".9rem",
            }}
            onClick={handleOnDelete}
          >
            Delete Selected
          </CFButton>
        </div>
      )}
    </Box>
  );
}
