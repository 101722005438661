/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import CFButton from "components/CFButton";
import CFBox from "components/CFBox";
import CFTypography from "components/CFTypography";
import placeholderIcon from "assets/cFarmImages/MainPages/PlaceholderBottomIcons.png";
import colors from "assets/theme/base/colors";
import RecapchaDisclosure from "./RecapchaDisclosure";

const socialMediaIconProps = {
  sx: { color: colors.slate[25] },
  fontSize: "large",
};

const socialMediaProps = [
  {
    ariaLabel: "Instagram",
    href: "https://www.instagram.com/comet_farm/",
    icon: <InstagramIcon {...socialMediaIconProps} />,
  },

  {
    ariaLabel: "Twitter",
    href: "http://twitter.com/cometfarm",
    icon: <TwitterIcon {...socialMediaIconProps} />,
  },

  {
    ariaLabel: "Facebook",
    href: "http://facebook.com/COMETFarmTool",
    icon: <FacebookRoundedIcon {...socialMediaIconProps} />,
  },

  /*   {
    ariaLabel: "Linkedin",
    href: "https://www.linkedin.com/",
    icon: <LinkedInIcon {...socialMediaIconProps} />,
  }, */

  {
    ariaLabel: "Youtube",
    href: "https://www.youtube.com/channel/UCQKxUt7MtFKeXTN94CDVZaQ/videos",
    icon: <YouTubeIcon {...socialMediaIconProps} />,
  },
];

export default function CFarmSocialMediaBottomBox({ ...rest }) {
  return (
    <CFBox {...rest} bgColor="inherit">
      <FooterBranding />
      <SocialMediaButtons />
      <RecapchaDisclosure />
    </CFBox>
  );
}

const FooterBranding = () => (
  <>
    <CFBox
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{
        borderBottom: "1px dashed black",
        margin: "auto",
        padding: ".0000001em",
      }}
    >
      <CFTypography
        sx={{ lineHeight: "1em" }}
        fontSize="3rem"
        fontWeight="bold"
      >
        COMET-
      </CFTypography>
      <CFTypography fontSize="3rem">Farm</CFTypography>
    </CFBox>
    <img
      src={placeholderIcon}
      alt="NRCS, USDA, NREL and Colorado State University logos"
      style={{ display: "block", margin: "auto" }}
    />
  </>
);

const SocialButtonWrapper = ({ icon, ariaLabel, href }) => (
  <CFBox borderRadius="section" shadow="lg">
    <CFButton
      aria-label={ariaLabel}
      component="a"
      href={href}
      target="_blank"
      rel="noreferrer"
      sx={{ backgroundColor: colors.primary.main }}
      iconOnly
      circular
      varient="gradient"
      size="large"
    >
      {icon}
    </CFButton>
  </CFBox>
);

const SocialMediaButtons = () => (
  <CFBox
    display="flex"
    justifyContent="space-between"
    alignItems="center"
    sx={{ padding: ".5em" }}
  >
    {socialMediaProps.map((e) => (
      <SocialButtonWrapper key={e.ariaLabel} {...e} />
    ))}
  </CFBox>
);
