import { Grid, TextField } from "@mui/material";

// Date picker
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";

import { useEffect, useState } from "react";
import CFTypography from "components/CFTypography";

import colors from "assets/theme/base/colors";
import CFManagementSelectionCard from "components/CroplandsComponents/CFManagementSelectionCard/CFManagementSelectionCard";
import CFLookupPicker from "components/CroplandsComponents/CFLookupPicker/CFLookupPicker";
import HelpAndButton from "../../../../../components/CroplandsComponents/EditCropCard_StructuralComponents/HelpAndButton";

import DeleteButton from "../../../../../components/CroplandsComponents/EditCropCard_StructuralComponents/DeleteButton";
import WhiteBackgroundDatepicker from "../../../../../components/CroplandsComponents/EditCropCard_StructuralComponents/WhiteBackgroundDatepicker";

export default function ({
  CropEvent,
  SetPropertyFunction,
  FieldName,
  otherFieldOperations,
  fullCropYear,
  yesBurnList,
}) {
  // logic to detect growing seasons dates

  const yesBurn = yesBurnList.map((x) => x.id);

  const [knownPlantings, setKnownPlantings] = useState([]);
  const [knownHarvests, setKnownHarvests] = useState([]);
  const [eventsToWarn, setEventsToWarn] = useState({});
  const [showBurnHelp, setShowBurnHelp] = useState(false);

  const [BurnHelpMessage, setBurnHelpMessage] = useState(
    "Burning events can take place either before or after planting. NOTE: The yield that you entered in your crop rotation will only impact your report if you also include a burning event."
  );
  let otherFieldOperationsfiltered = [...otherFieldOperations];

  if (yesBurn.indexOf(CropEvent.id) < 0) {
    otherFieldOperationsfiltered = otherFieldOperationsfiltered.filter(
      (x) => x.id !== 1034
    );
  }
  const CalculateGrowingPeriods = () => {
    const newPlantings = [];
    const newLastHarvests = [];

    newPlantings.push(dayjs(CropEvent.plantingDate));
    let lstharv = dayjs(CropEvent.plantingDate);

    for (let i = 0; i < CropEvent.harvests.length; i += 1) {
      const tharv = dayjs(CropEvent.harvests[i].harvestDate);

      if (lstharv.isBefore(tharv)) {
        lstharv = tharv;
      }
    }
    newLastHarvests.push(lstharv);

    for (let j = 0; j < fullCropYear.crops.length; j += 1) {
      if (
        fullCropYear.crops[j] &&
        fullCropYear.crops[j].cropNumber !== CropEvent.cropNumber
      ) {
        newPlantings.push(dayjs(fullCropYear.crops[j].plantingDate));
        lstharv = dayjs(fullCropYear.crops[j].plantingDate);
        for (let i = 0; i < fullCropYear.crops[j].harvests.length; i += 1) {
          const tharv = dayjs(fullCropYear.crops[j].harvests[i].harvestDate);
          if (lstharv.isBefore(tharv)) {
            lstharv = tharv;
          }
        }
        newLastHarvests.push(lstharv);
      }
    }
    setKnownPlantings(newPlantings);
    setKnownHarvests(newLastHarvests);
  };
  const checkOverlapPlanting = (tillDateString) => {
    const theDate = dayjs(tillDateString);

    for (let i = 0; i < knownPlantings.length; i += 1) {
      if (
        knownPlantings[i].isBefore(theDate) &&
        knownHarvests[i].isAfter(theDate)
      ) {
        return true;
      }
    }
    return false;
  };

  const CheckOperationBurnKill = (index) => {
    let showWarning = false;

    const event = CropEvent.otherFieldOperations[index];
    if (event.tillage.id === 1034 && checkOverlapPlanting(event.tillageDate)) {
      showWarning = true;
    }

    const oldEvents = { ...eventsToWarn };
    oldEvents[index] = showWarning;

    setEventsToWarn(oldEvents);
  };

  const CheckOperationBurnKillAll = () => {
    let showBurn = false;
    for (let i = 0; i < CropEvent.otherFieldOperations.length; i += 1) {
      CheckOperationBurnKill(i);
      const event = CropEvent.otherFieldOperations[i];

      if (event.tillage.id === 1034) {
        showBurn = true;
        console.log(CropEvent, CropEvent.id);
        if (yesBurn.indexOf(CropEvent.id) < 0) {
          setBurnHelpMessage("We cannot model biomass burning for this crop.");
        }
      }
    }
    setShowBurnHelp(showBurn);
  };

  useEffect(() => {
    CheckOperationBurnKillAll();
  }, [knownPlantings, knownHarvests]);

  useEffect(() => {
    CalculateGrowingPeriods();
  }, [fullCropYear, CropEvent]);

  const minDateOther = dayjs(`${CropEvent.cropYear}-1-1 00:00`);
  const maxDate = dayjs(`${CropEvent.cropYear + 2}-1-1 00:00`);

  const addNewTillageEvent = () => {
    const newCE = { ...CropEvent };

    newCE.otherFieldOperations.push({
      tillageDate: minDateOther.toISOString(),
      strawStoverHayPct: 0,
      tillage: otherFieldOperationsfiltered[0],
      id: 0,
    });
    SetPropertyFunction(newCE);
  };

  const deleteTillage = (index) => {
    const newCE = { ...CropEvent };
    newCE.otherFieldOperations.splice(index, 1);
    SetPropertyFunction(newCE);
  };
  const SetOtherOperation = (newVal, index) => {
    const newCE = { ...CropEvent };
    newCE.otherFieldOperations[index].tillage = newVal;
    SetPropertyFunction(newCE);
    CheckOperationBurnKill(index);
  };
  const SetOtherOperationTillageDate = (newVal, index) => {
    if (dayjs(newVal, true).isValid()) {
      const newCE = { ...CropEvent };
      newCE.otherFieldOperations[index].tillageDate =
        dayjs(newVal).toISOString();
      SetPropertyFunction(newCE);

      CheckOperationBurnKill(index);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid alignItems="center" container flexDirection="row">
        <Grid item xs={12} sx={{ paddingLeft: "2em" }}>
          <CFTypography fontSize="1em">
            Please enter any other field operations including: growing season
            cultivation, crimp, mow, and burn events. You can also add soil
            disturbing tillages but those will kill any standing crop.
          </CFTypography>
        </Grid>
        <Grid item xs={12}>
          {CropEvent.otherFieldOperations.map((till, ind) => (
            <Grid
              // eslint-disable-next-line react/no-array-index-key
              key={`${till.tillage.Id}_${till.Id}_${ind}_${till.tillageDate}`}
              item
              xs={12}
              container
              flexDirection="row"
              alignItems="center"
            >
              <Grid item xs={11} xl={5.5}>
                <CFManagementSelectionCard
                  label="Operation"
                  labelWidth={6}
                  input={
                    <CFLookupPicker
                      label="Select a field operation"
                      key="otherOperationTilalge"
                      name="otherOperationTilalge"
                      required
                      sx={{ width: "100%" }}
                      value={till.tillage}
                      onChange={(event, newValue) => {
                        SetOtherOperation(newValue, ind);
                      }}
                      options={otherFieldOperationsfiltered}
                    />
                  }
                />
              </Grid>
              <Grid item xs={11} xl={5.5}>
                <CFManagementSelectionCard
                  label="Operation Date"
                  labelWidth={6}
                  input={
                    <WhiteBackgroundDatepicker
                      views={["year", "month", "day"]}
                      label="Operation Date"
                      maxDate={maxDate}
                      minDate={minDateOther}
                      style={{ backgroundColor: colors.white.main }}
                      slotProps={{ textField: { variant: "outlined" } }}
                      value={dayjs(till.tillageDate)}
                      onChange={(newValue) =>
                        // false
                        SetOtherOperationTillageDate(newValue, ind)
                      }
                    />
                  }
                />
              </Grid>
              <Grid item xs={1}>
                <DeleteButton deleteAction={() => deleteTillage(ind)} />
              </Grid>
              {eventsToWarn[ind] && (
                <Grid item sx={11}>
                  <div style={{ color: "red", padding: ".5em" }}>
                    Warning: this will kill your standing crop
                  </div>{" "}
                </Grid>
              )}
            </Grid>
          ))}
          {showBurnHelp && (
            <Grid item sx={11}>
              <div style={{ padding: "1em" }}>{BurnHelpMessage}</div>{" "}
            </Grid>
          )}
          <HelpAndButton
            buttonLabel="+Add Field Operation"
            buttonAction={addNewTillageEvent}
            helperText="Add other field operations. Note any actual tillage events applied between planting and harvest will kill the crop and stop growth in simulation."
          />{" "}
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
}
