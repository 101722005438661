// React
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import ArrowCircleRightTwoToneIcon from "@mui/icons-material/ArrowCircleRightTwoTone";
import CheckIcon from "@mui/icons-material/Check";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
// MUI
import { Grid, Dialog } from "@mui/material";

import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Stack from "@mui/material/Stack";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";

import CFStep from "components/CFarmParts/CFStep";
import CFCheckbox from "components/CFCheckbox/CFCheckbox";
import CFDialog from "components/CFDialog";
import colors from "assets/theme/base/colors";
// Our Components
import CFBox from "components/CFBox";

import CFTypography from "components/CFTypography";
import CFButton from "components/CFButton";
import { updateLoading } from "store/reducers/mask";
import { post } from "utils/api";
import { popupError } from "utils/generic";
import { DELETE_RATION } from "constants/api";
import CFLoading from "components/CFLoading";

// Constants

export default function DeleteRation({
  isRationOpen,
  setIsRationOpen,
  fdlotid,
  fdlotherdName,
  fdlotyearValue,
  fdlotanimalCategory,
  fdlotanimalCategoryId,
  fdlotanagobject,
  fdlotanagobjectChanged,
  deleteRationClicked,
}) {
  const dispatch = useDispatch();

  const { info } = colors;

  const [localPopupState, updateLocalPopup] = useState({});
  const [loading, setLoading] = useState(false);

  const currentUser = useSelector((state) => state.user);
  const deleteRationGroup = () => {
    setLoading(true);
    const popObj = {
      popid: fdlotid,
      rationName: fdlotherdName,
    };
    post(DELETE_RATION, popObj)
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          setLoading(false);
        } else {
          const noDataRationList = JSON.parse(res.data);
          setIsRationOpen(false);
          deleteRationClicked(noDataRationList);
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const handleClose = () => {
    setIsRationOpen(false);
  };

  return (
    <Dialog
      open={isRationOpen}
      fullWidth
      maxWidth="md"
      maxHeight="md"
      sx={{ zIndex: 5 }}
    >
      {/* Main form */}
      <CFBox width="100%" sx={{ padding: "2em", display: "flex" }}>
        {loading && <CFLoading local />}
        {/* Grid with four rows: title, step one and two, step three, and action buttons */}
        <Grid container direction="row" xs={12} sm={12} md={12}>
          <Grid item container xs={12} alignItems="center" direction="row">
            <Grid item>
              {/* Window Header */}
              <CFStep
                stepLabel={`Delete ${fdlotherdName} Management`}
                stepNumber=""
                helpText="help"
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            justifyContent="flex-end"
            direction="row"
          >
            <Grid item xs={12}>
              <CFBox width="100%">
                <CFTypography
                  sx={{
                    fontSize: "20px",
                    color: info.dullFocus,
                    fontWeight: "bold",

                    marginBottom: "1em",
                    marginLeft: "1em",
                  }}
                >
                  Are you sure you want to delete {fdlotherdName} ?
                </CFTypography>
              </CFBox>
            </Grid>
          </Grid>

          {/* Grid for steps one and two */}

          <Grid container py={2} px={2} justifyContent="space-around">
            {/* Return to projects */}
            <Grid item xs={6} sm={6} md={6}>
              <CFButton color="secondary" onClick={() => handleClose()}>
                Cancel
              </CFButton>
            </Grid>

            {/* Management Details */}
            <Grid item xs={1} sm={1} md={1}>
              <CFButton
                color="progress"
                variant="gradient"
                onClick={() => deleteRationGroup()}
                // disabled={showNameError}
              >
                Delete
              </CFButton>
            </Grid>
          </Grid>
        </Grid>
      </CFBox>

      {localPopupState && localPopupState.showPopup && (
        <CFDialog
          {...localPopupState}
          updatePopup={(e) => updateLocalPopup(e)}
        />
      )}
    </Dialog>
  );
}
