export const getTextInput = (val) => {
  let value = val;

  if (value > 100) value = 100;
  if (value < 0) value = 0;
  if (value == null || value === "") value = 0;
  return value;
};

export const getAdditiveUnits = (additiveId) => {
  let addUnits = "";
  if (additiveId === 215200) {
    addUnits = "mg/kg of DM";
  }
  if (additiveId === 215201) {
    addUnits = "g/kg of DM";
  }
  if (additiveId === 215202) {
    addUnits = "% EE in Diet";
  }
  return addUnits;
};
