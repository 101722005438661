// MUI
import {
  Grid,
  Switch,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@mui/material";

// React
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// CFarm Components
import CFTypography from "components/CFTypography";
import { FORESTRY_GET_REPORT } from "constants/api";

import { updateLoading } from "store/reducers/mask";
import { popupError } from "utils/generic";
import { post } from "utils/api";

// Local Components
import CFTabView from "components/CFTabView";
import CFDialog from "components/CFDialog";
import ForestryStandTable from "./ForestryStandTable";
import ForestryStandGraph from "./ForestryStandGraph";
import ForestryProjectGraph from "./ForestryProjectGraph";

function ForestryReport({ reportInfo, setReport }) {
  // User info
  const currentUser = useSelector((state) => state.user);

  const dispatch = useDispatch();

  // Display settings
  const [isPerAcre, setIsPerAcre] = useState(false);

  // Fetch report data on initial render
  const [forestryReport, setForestryReport] = useState([]);
  const fetchForestryReport = () => {
    dispatch(updateLoading({ loading: true }));

    post(FORESTRY_GET_REPORT, {
      UserId: currentUser.id,
    })
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          dispatch(updateLoading({ loading: false }));
        } else {
          const reportGridData = JSON.parse(res.data.reportGridData);
          setForestryReport(reportGridData);
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(updateLoading({ loading: false }));
      })
      .finally(() => {
        dispatch(updateLoading({ loading: false }));
      });
  };
  // Flag indicates if agroforestry is present in project
  useEffect(() => {
    if (reportInfo && reportInfo.length > 0) {
      setForestryReport(reportInfo);
    } else fetchForestryReport();
  }, []);

  // Overall project total emissions
  const ComputeProjectTotals = () => {
    if (forestryReport && forestryReport.length > 0) {
      const tempTotals = [];

      const currentTotal = {
        yearField: "currentYear",
        total: 0,
      };
      const tenTotal = {
        yearField: "tenYear",
        total: 0,
      };
      const twentyTotal = {
        yearField: "twentyYear",
        total: 0,
      };
      const thirtyTotal = {
        yearField: "thirtyYear",
        total: 0,
      };
      const fortyTotal = {
        yearField: "fortyYear",
        total: 0,
      };
      const fiftyTotal = {
        yearField: "fiftyYear",
        total: 0,
      };
      const emissionsYearly = {
        yearField: "emissionsYearly",
        total: 0,
      };

      // Iterate through each species
      forestryReport.forEach((stand) => {
        // Current
        if (!stand.invalid2015) {
          currentTotal.total += isPerAcre
            ? stand.year2015.value / stand.standArea
            : stand.year2015.value;
          currentTotal.total += isPerAcre
            ? stand.year2015H.value / stand.standArea
            : stand.year2015H.value;
        }
        // Ten
        if (!stand.invalid2025) {
          tenTotal.total += isPerAcre
            ? stand.year2025.value / stand.standArea
            : stand.year2025.value;
          tenTotal.total += isPerAcre
            ? stand.year2025H.value / stand.standArea
            : stand.year2025H.value;
        }
        // Twenty
        if (!stand.invalid2035) {
          twentyTotal.total += isPerAcre
            ? stand.year2035.value / stand.standArea
            : stand.year2035.value;
          twentyTotal.total += isPerAcre
            ? stand.year2035H.value / stand.standArea
            : stand.year2035H.value;
        }
        // Thirty
        if (!stand.invalid2045) {
          thirtyTotal.total += isPerAcre
            ? stand.year2045.value / stand.standArea
            : stand.year2045.value;
          thirtyTotal.total += isPerAcre
            ? stand.year2045H.value / stand.standArea
            : stand.year2045H.value;
        }
        // Forty
        if (!stand.invalid2055) {
          fortyTotal.total += isPerAcre
            ? stand.year2055.value / stand.standArea
            : stand.year2055.value;
          fortyTotal.total += isPerAcre
            ? stand.year2055H.value / stand.standArea
            : stand.year2055H.value;
        }
        // Fifty
        if (!stand.invalid2065) {
          fiftyTotal.total += isPerAcre
            ? stand.year2065.value / stand.standArea
            : stand.year2065.value;
          fiftyTotal.total += isPerAcre
            ? stand.year2065H.value / stand.standArea
            : stand.year2065H.value;
        }
        // Emissions Change
        if (!stand.invalid2015 && !stand.invalid2065) {
          let emissions =
            (stand.year2065.value +
              stand.year2065H.value -
              (stand.year2015.value + stand.year2015H.value)) /
            50;
          if (isPerAcre) {
            emissions /= stand.standArea;
          }
          emissionsYearly.total += emissions;
        }
      });

      tempTotals.push(currentTotal);
      tempTotals.push(tenTotal);
      tempTotals.push(twentyTotal);
      tempTotals.push(thirtyTotal);
      tempTotals.push(fortyTotal);
      tempTotals.push(fiftyTotal);
      tempTotals.push(emissionsYearly);

      return tempTotals;
    }

    return [];
  };
  const [projectTotals, setProjectTotals] = useState(ComputeProjectTotals());
  useEffect(() => {
    setProjectTotals(ComputeProjectTotals());

    // Update top level if report is complete and new
    if (
      forestryReport &&
      forestryReport.length > 0 &&
      reportInfo !== forestryReport
    ) {
      setReport(forestryReport);
    }
  }, [forestryReport, isPerAcre]);

  // Graph/Dual display swap
  const [display, setDisplay] = useState("dual");

  // Display tabs
  function CreateTabs() {
    const reportData =
      reportInfo && reportInfo.length > 0 ? reportInfo : forestryReport;

    const tempTabs = { TabsList: [] };

    if (reportData && reportData.length > 0) {
      reportData.forEach((stand) => {
        tempTabs.TabsList.push({
          Tab: stand.standname,
          Data: (
            <Grid py={0.5} px={2}>
              <Grid>
                <ForestryStandTable
                  stand={stand}
                  projectTotals={projectTotals}
                  isPerAcre={isPerAcre}
                />
              </Grid>
              <Grid>
                <ForestryStandGraph stand={stand} isPerAcre={isPerAcre} />
              </Grid>
            </Grid>
          ),
        });
      });
    }

    if (tempTabs.TabsList.length === 0) {
      const defaultTab = {
        Tab: "Forestry Report Data",
        Data: (
          <Grid container direction="column" columns={1} alignItems="center">
            Loading...
          </Grid>
        ),
      };
      tempTabs.TabsList.push(defaultTab);
    }

    return tempTabs;
  }
  const [forestryTabs, setTabs] = useState(CreateTabs());
  useEffect(() => {
    setTabs(CreateTabs);
  }, [display, isPerAcre, projectTotals, reportInfo, forestryReport]);

  // Demo info popup
  const [demoPopupShown, setDemoPopupShown] = useState(false);
  const [localPopupState, updateLocalPopup] = useState({});
  useEffect(() => {
    if (currentUser?.activeProject?.name.includes("Demo") && !demoPopupShown) {
      setDemoPopupShown(true);
      updateLocalPopup({
        title: "Forestry Demo Project",
        style: {
          minWidth: "750px",
          maxHeight: "75vh",
          overflow: "auto",
        },
        children: [
          <Grid container direction="column" alignItems="center" p={2}>
            <CFTypography variant="h4" py={1}>
              Forestry Report
            </CFTypography>
            <p>
              The report shows a net sequestration over the next 50 years for
              all stands. East Big Meadow shows a continual rate of
              sequestration over all ecosystem-based carbon pools as the reserve
              continues to grow. In West Big Meadow and Joe&apos;s cut, clear
              cutting periodically removes live tree biomass resulting in
              variability of ecosystem-based carbon and a growing accrual of CO2
              equivalent mass in the form of forest products in use and in
              landfills.
            </p>
          </Grid>,
        ],
        showPopup: true,
        closeAction: () => {
          updateLocalPopup({ showPopup: false });
        },
      });
    }
  }, [currentUser.activeProject]);

  return (
    <Grid container direction="column">
      {/* Title and Per Acre Swap */}
      <Grid item container direction="column" alignItems="start" pl={1}>
        <Grid container direction="row" alignItems="center" py={2}>
          <Grid item xs={8}>
            <CFTypography variant="h4">Forestry Report Summary</CFTypography>
          </Grid>
          <Grid
            item
            xs={4}
            container
            direction="row"
            justifyContent="end"
            alignItems="center"
          >
            <CFTypography variant="h6" fontWeight="regular" pr={2}>
              Report Display:
            </CFTypography>
            <RadioGroup
              defaultValue="dual"
              row
              onChange={(e) => setDisplay(e.target.value)}
            >
              <FormControlLabel value="dual" control={<Radio />} label="Dual" />
              <FormControlLabel
                value="graph"
                control={<Radio />}
                label="Graph"
              />
            </RadioGroup>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          pb={1}
          pr={1}
        >
          {/* Units message */}
          <Grid>
            <CFTypography variant="h6" fontWeight="regular" fontStyle="italic">
              All estimates are presented in terms of metric tonnes of CO
              <sub>2</sub>-equivalent over the indicated decade in the
              fifty-year scenario period.
            </CFTypography>
          </Grid>
          {/* Acre/stand swap */}
          <Grid>
            <FormControlLabel
              control={
                <Switch
                  onChange={() => {
                    setIsPerAcre(!isPerAcre);
                  }}
                />
              }
              label="Emissions Per Acre"
            />
          </Grid>
        </Grid>
      </Grid>

      {display === "dual" ? (
        // Graph Selection and Display
        <Grid m={1} maxWidth="99%" width="100%">
          <CFTabView verbose>{forestryTabs}</CFTabView>
        </Grid>
      ) : (
        // Graph Selection and Display
        <Grid m={1} maxWidth="99%" width="100%" item>
          <ForestryProjectGraph
            forestryData={forestryReport}
            isPerAcre={isPerAcre}
          />
        </Grid>
      )}

      {localPopupState && localPopupState.showPopup && (
        <CFDialog
          {...localPopupState}
          updatePopup={(e) => updateLocalPopup(e)}
        />
      )}
    </Grid>
  );
}
export default ForestryReport;
