import { Divider, Grid } from "@mui/material";
import { useState } from "react";
import { fromLonLat } from "ol/proj";
import { View } from "ol";
import CFBox from "components/CFBox";
import CFInput from "components/CFInput";
import CFTypography from "components/CFTypography";
import CFButton from "components/CFButton";
import colors from "assets/theme/base/colors";
import { plainGet } from "utils/api";
import { getZoomLevel } from "utils/generic";
import { GEOLOCATE_START, GEOLOCATE_END } from "constants/api";

export default function SearchLocation({
  setIsOpen,
  toggleResetToggle,
  parcelWording,
  mainMap,
}) {
  const [location, setLocation] = useState();
  const [showError, setShowError] = useState(false);
  const [searchError, setSearchError] = useState();

  const onClickSearch = (event) => {
    event.preventDefault();
    const locationString = `${GEOLOCATE_START}${encodeURIComponent(
      location
    )}${GEOLOCATE_END}`;

    plainGet(locationString)
      .then((response) => {
        const country =
          response.resourceSets[0].resources[0].address.countryRegion;
        if (country !== "United States")
          throw new Error(
            `Please search in the continental United States. You are currently searching in: ${country}`
          );
        const lat =
          response.resourceSets[0].resources[0].geocodePoints[0].coordinates[0];
        const long =
          response.resourceSets[0].resources[0].geocodePoints[0].coordinates[1];
        const boundingBox = response.resourceSets[0].resources[0].bbox;

        const lonlat = fromLonLat([long, lat]);
        const zoom = getZoomLevel(boundingBox);

        mainMap.setView(
          new View({
            center: lonlat,
            zoom,
          })
        );
        toggleResetToggle();
        setIsOpen(false);
      })
      .catch((error) => {
        setSearchError(error.toString());
        setShowError(true);
      });
  };

  const onClickCancel = () => {
    setIsOpen(false);
    toggleResetToggle();
  };

  return (
    <CFBox component="form" onSubmit={onClickSearch}>
      {
        <Grid container direction="row">
          {/* Window Header */}
          <CFTypography variant="h5" pl={1.5} pt={1} pb={0.5}>
            Search for Your {parcelWording}
          </CFTypography>
          <Divider sx={{ width: "100%", mt: 0, mb: 1.5 }} />
          <Grid item xs={10.5}>
            <CFTypography variant="h8" pl={2} py={2}>
              Where are your {parcelWording.toLowerCase()}s located?
            </CFTypography>
            <CFInput
              required
              fullWidth
              placeholder="Enter an address, coordinates, zip code or landmark."
              className="cfmap-input"
              value={location || ""}
              onChange={(e) => setLocation(e.target.value)}
            />
            <CFBox
              style={{
                fontSize: "0.67em",
                color: "red",
                marginTop: "0.25em",
              }}
            >
              {showError ? searchError : ""}
            </CFBox>
          </Grid>
          {/* Action buttons */}
          <Grid container py={2} px={2} justifyContent="space-between">
            {/* Return to map page */}
            <CFButton color="secondary" onClick={onClickCancel}>
              Cancel
            </CFButton>
            {/* Submit changes */}
            <CFButton type="submit" color="progress">
              Search
            </CFButton>
          </Grid>
        </Grid>
      }
    </CFBox>
  );
}
