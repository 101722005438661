import { CircularProgress, Stack } from "@mui/material";
import PropTypes from "prop-types";
import { forwardRef } from "react";
import CFBox from "components/CFBox";
import CFTypography from "components/CFTypography";
import { loadingMaskText } from "constants/pageText";

function CFLoading({ message, local, showCircle, minHeight }) {
  return (
    <CFBox
      sx={{
        width: "100%",
        top: 0,
        left: 0,
        position: "absolute",
        height: "100%",
        minHeight,
        zIndex: 6,
      }}
    >
      <CFBox
        sx={{
          width: "100%",
          top: 0,
          left: 0,
          position: "absolute",
          height: "100%",
          minHeight,
          backgroundColor: "rgba(80,80,80,.6)",
          pointerEvents: "none",
        }}
      />
      <Stack
        marginLeft={local ? "0" : "50%"}
        sx={{
          margin: local ? "auto" : "",
          marginTop: local ? "25%" : "150px",
          width: local ? "50%" : "auto",
          transform: local ? "" : "translate(-50%, 0)",
          position: local ? "" : "fixed",
        }}
        alignItems="center"
      >
        {showCircle && (
          <CircularProgress
            status="loading"
            color="white"
            size={local ? "3rem" : "15rem"}
            style={{}}
          />
        )}

        <CFBox
          sx={{
            top: 0,
            left: 0,

            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CFTypography
            variant="caption"
            component="div"
            color="white"
            fontSize="1.0em"
            sx={{ textShadow: "0 0 .5em black" }}
          >
            {message}
          </CFTypography>
        </CFBox>
      </Stack>
    </CFBox>
  );
}

// Setting default values for the props of CFPagination
CFLoading.defaultProps = {
  local: false,
  showCircle: true,
  message: loadingMaskText,
  minHeight: "100px",
};

// Typechecking props for the CFPagination
CFLoading.propTypes = {
  message: PropTypes.string,
  local: PropTypes.bool,
  showCircle: PropTypes.bool,
  minHeight: PropTypes.string,
};

export default CFLoading;
