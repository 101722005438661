import PropTypes from "prop-types";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Slide from "@mui/material/Slide";

// @mui icons
import CloseIcon from "@mui/icons-material/Close";

// COMET Componets
import CFBox from "components/CFBox";
import CFButton from "components/CFButton";
import CFTypography from "components/CFTypography";
import CFInput from "components/CFInput";

function RenderComponent({ type, ...rest }) {
  switch (type) {
    case "input":
      return <CFInput {...rest} sx={{ width: "100%" }} />;

    default:
      break;
  }
}
RenderComponent.defaultProps = {
  type: "",
};

// Typechecking props for the CFInput
RenderComponent.propTypes = {
  type: PropTypes.string,
};

function CFDialog({
  title,
  showPopup,
  message,
  updatePopup,
  closeAction,
  closable,
  zIndex = 5,
  style,
  ...rest
}) {
  let closeModel = () => updatePopup({ showPopup: false });
  if (closeAction) {
    closeModel = () => closeAction();
  }
  const { children } = rest;

  return (
    <Modal
      open={showPopup}
      onClose={closable ? closeModel : undefined}
      sx={{ display: "grid", placeItems: "center", zIndex }}
    >
      <Slide direction="down" in={showPopup} timeout={500}>
        <CFBox
          position="relative"
          width="500px"
          display="flex"
          flexDirection="column"
          borderRadius="xl"
          bgColor="white"
          shadow="xl"
          sx={{ ...style }}
        >
          <CFBox
            display="flex"
            align="center"
            justifyContent="space-between"
            p={2}
          >
            <CFTypography variant="h5">{title}</CFTypography>
            {closable && (
              <CloseIcon
                fontSize="medium"
                sx={{ cursor: "pointer" }}
                onClick={closeModel}
              />
            )}
          </CFBox>
          <Divider sx={{ my: 0 }} />
          {children && children}
          {!children && (
            <CFBox p={2}>
              <CFTypography
                variant="body2"
                color="secondary"
                fontWeight="regular"
              >
                {message}
              </CFTypography>
              {rest && rest.component && RenderComponent(rest.component)}
            </CFBox>
          )}
          <Divider sx={{ my: 0 }} />
          <CFBox display="flex" justifyContent="space-between" p={1.5}>
            {closable && (
              <CFButton
                variant="gradient"
                color="secondary"
                onClick={closeModel}
                sx={{ margin: "1em" }}
              >
                Close
              </CFButton>
            )}

            {rest && rest.button1 && (
              <CFButton
                color="progress"
                {...rest.button1}
                sx={{ margin: "1em" }}
              >
                {rest.button1.text}
              </CFButton>
            )}
            {rest && rest.button2 && (
              <CFButton
                color="progress"
                {...rest.button2}
                sx={{ margin: "1em" }}
              >
                {rest.button2.text}
              </CFButton>
            )}
          </CFBox>
        </CFBox>
      </Slide>
    </Modal>
  );
}

// Setting default values for the props of CFInput
CFDialog.defaultProps = {
  title: "COMET-Farm Popup",
  showPopup: false,
  message: "",
  updatePopup: "",
  closeAction: null,
  closable: true,
};

// Typechecking props for the CFInput
CFDialog.propTypes = {
  title: PropTypes.node,
  showPopup: PropTypes.bool,
  message: PropTypes.node,
  updatePopup: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  closeAction: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  closable: PropTypes.bool,
};

export default CFDialog;
