import { AccordionSummary } from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import colors from "assets/theme/base/colors";
import CFTypography from "components/CFTypography";

export default function SwineHeader({ text }) {
  return (
    <AccordionSummary
      expandIcon={<GridExpandMoreIcon sx={{ color: colors.text.light }} />}
      aria-controls="animalDetailsCard-content"
      id="animalDetailsCard-header"
      sx={{
        backgroundColor: colors.highlight.main,
        borderRadius: ".3em",
        boxShadow: "0px 1px 3px 0px #bbb",
        borderStyle: "outset",
        flexDirection: "row-reverse",
      }}
    >
      <CFTypography color="white" sx={{ fontWeight: "600" }} variant="body2">
        {text}
      </CFTypography>
    </AccordionSummary>
  );
}
