import SendIcon from "@mui/icons-material/Send";
import CircleIcon from "@mui/icons-material/Circle";
import CFBox from "components/CFBox";
import CFTypography from "components/CFTypography";
import CFLink from "components/CFLink/CFLink";

const pointTypes = {
  arrow: (
    <SendIcon
      style={{
        padding: 2.5,
        textAlign: "center",
        lineStylePosition: "inside",
        paddingLeft: 0,
        color: "primary",
      }}
    />
  ),
  dot: (
    <CircleIcon
      style={{
        padding: 4.5,
        textAlign: "center",
        lineStylePosition: "inside",
        paddingLeft: 0,
      }}
    />
  ),
  none: (
    <CircleIcon
      style={{
        visibility: "hidden",
        paddingLeft: 0,
      }}
    />
  ),
};

function InPage(children, point, noname, fontSize) {
  const { name, collapse } = children;

  return (
    <CFBox>
      {name && !noname && (
        <CFTypography fontWeight="bold" fontSize={fontSize}>
          {name}{" "}
        </CFTypography>
      )}
      <CFBox>
        {collapse.map((listItem) => {
          let mainText = listItem.name;

          if (!listItem.name) {
            mainText = listItem.text;
          }
          let mainLink = listItem.href;
          if (!mainLink) {
            mainLink = listItem.route;
          }

          return (
            <CFBox key={listItem.key ? listItem.key : mainText}>
              {mainLink && (
                <CFLink
                  to={mainLink}
                  label={
                    <CFTypography variant="button" fontSize={fontSize}>
                      {pointTypes[point]}
                      {`${mainText}`}{" "}
                    </CFTypography>
                  }
                />
              )}
              {!mainLink && (
                <CFBox
                  component={listItem.route || listItem.href ? "a" : ""}
                  display="block"
                  sx={{ marginLeft: "1em" }}
                  route={mainLink}
                  href={mainLink}
                  target={listItem.href ? "_blank" : ""}
                >
                  <CFTypography variant="button" fontSize={fontSize}>
                    {pointTypes[point]}
                    {`${mainText}`}{" "}
                  </CFTypography>
                </CFBox>
              )}
              {listItem.answer && (
                <CFBox
                  component={listItem.route ? "a" : ""}
                  display="block"
                  sx={{ marginLeft: "1em" }}
                  route={mainLink}
                  href={mainLink}
                  key={mainText}
                >
                  <CFTypography variant="button" fontSize={fontSize}>
                    {pointTypes.none}
                    {pointTypes.none}
                    {`${listItem.answer}`}{" "}
                  </CFTypography>
                </CFBox>
              )}
            </CFBox>
          );
        })}
      </CFBox>
    </CFBox>
  );
}
function CFBulletListRoot(props) {
  return InPage(props.children, props.point, props.noname, props.fontSize);
}
export default CFBulletListRoot;
