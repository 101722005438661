import { ToggleButton, styled } from "@mui/material";
import colors from "assets/theme/base/colors";

const CFToggleButton = styled(ToggleButton)(({ theme }) => ({
  border: `1px solid ${colors.primary.main}`,
  minWidth: "0px",
  width: "3em",
  height: "3em",
  alignItems: "center",
  marginLeft: "0px !important",
  "&.Mui-selected": {
    border: `1px solid ${colors.primary.main}`,
    borderRadius: "0.4em",
    boxShadow: "inset -1px -1px 4px 0px rgba(0, 0, 0, 0.2)",
    minWidth: "0px",
    width: "3em",
    height: "3em",
    alignItems: "center",
    "&:hover": {
      border: `1px solid ${colors.primary.main}`,
      borderRadius: "0.4em",
      backgroundColor: "#50B848",
      boxShadow: "inset -1px -1px 4px 0px rgba(0, 0, 0, 0.2)",
      marginInline: ".35em",
      minWidth: "0px",
      width: "3em",
      height: "3em",
      color: "#fff",
      alignItems: "center",
    },
  },
  "&:hover": {
    border: `1px solid ${colors.primary.main} !important`,
    marginInline: ".35em",
    minWidth: "0px",
    width: "3em",
    height: "3em",
    alignItems: "center",
  },
}));

export default CFToggleButton;
