import { useState, useEffect } from "react";

import { Grid, Box, Typography } from "@mui/material";
import { BarChart } from "@mui/x-charts/BarChart";
import { axisClasses } from "@mui/x-charts";
import colors from "assets/theme/base/colors";

function ForestryStandGraph({ stand, isPerAcre }) {
  // Chart display settings
  const chartSetting = {
    tooltip: { trigger: "item" },
    xAxis: [
      {
        scaleType: "band",
        dataKey: "year",
        label: "Year",
        labelStyle: { fontSize: 16, fontWeight: "bold" },
        tickLabelStyle: {
          fontSize: 16,
        },
      },
    ],
    yAxis: [
      {
        label: "Carbon Stock (tonnes CO\u2082 equivalent)",
      },
    ],
    legend: {
      direction: "row",
      position: {
        vertical: "bottom",
        horizontal: "middle",
      },
      padding: 5,
    },
    margin: { top: 50, right: 75, left: 75, bottom: 150 },
    sx: {
      [`.${axisClasses.left} .${axisClasses.label}`]: {
        transform: "translate(-33px, 0px)",
        fontWeight: "bold",
      },
      "--ChartsLegend-rootOffsetX": "2em",
      "--ChartsLegend-rootSpacing": "1em",
    },
  };

  // Format chart values and stack labels
  const valueFormatter = (value) => {
    const roundedVal = Math.round((value + Number.EPSILON) * 10) / 10;
    return `${roundedVal.toLocaleString("en-US")} (tonnes CO\u2082 equiv.)`;
  };
  function labelFormatter(value, total) {
    const roundedTotal = Math.round((total + Number.EPSILON) * 10) / 10;
    return `${value} (Total: ${roundedTotal.toLocaleString("en-US")})`;
  }

  // Generate dataset for chart
  function GenerateDataset() {
    const tempData = [];
    const tempSeries = [];

    let currentTotal = 0;
    let totalTen = 0;
    let totalTwenty = 0;
    let totalThirty = 0;
    let totalForty = 0;
    let totalFifty = 0;

    // One object for each year of data
    const dataCurrent = {};
    const dataTen = {};
    const dataTwenty = {};
    const dataThirty = {};
    const dataForty = {};
    const dataFifty = {};

    // Populate data objects
    if (!stand.invalid2015) {
      dataCurrent[stand.year2015.emission] = isPerAcre
        ? stand.year2015.value / stand.standArea
        : stand.year2015.value;
      dataCurrent[stand.year2015H.emission] = isPerAcre
        ? stand.year2015H.value / stand.standArea
        : stand.year2015H.value;
      currentTotal += isPerAcre
        ? (stand.year2015.value + stand.year2015H.value) / stand.standArea
        : stand.year2015.value + stand.year2015H.value;
    } else {
      dataCurrent[stand.year2015.emission] = 0;
      dataCurrent[stand.year2015H.emission] = 0;
    }
    if (!stand.invalid2025) {
      dataTen[stand.year2025.emission] = isPerAcre
        ? stand.year2025.value / stand.standArea
        : stand.year2025.value;
      dataTen[stand.year2025H.emission] = isPerAcre
        ? stand.year2025H.value / stand.standArea
        : stand.year2025H.value;
      totalTen += isPerAcre
        ? (stand.year2025.value + stand.year2025H.value) / stand.standArea
        : stand.year2025.value + stand.year2025H.value;
    } else {
      dataTen[stand.year2025.emission] = 0;
      dataTen[stand.year2025H.emission] = 0;
    }
    if (!stand.invalid2035) {
      dataTwenty[stand.year2035.emission] = isPerAcre
        ? stand.year2035.value / stand.standArea
        : stand.year2035.value;
      dataTwenty[stand.year2035H.emission] = isPerAcre
        ? stand.year2035H.value / stand.standArea
        : stand.year2035H.value;
      totalTwenty += isPerAcre
        ? (stand.year2035.value + stand.year2035H.value) / stand.standArea
        : stand.year2035.value + stand.year2035H.value;
    } else {
      dataTwenty[stand.year2035.emission] = 0;
      dataTwenty[stand.year2035H.emission] = 0;
    }
    if (!stand.invalid2045) {
      dataThirty[stand.year2045.emission] = isPerAcre
        ? stand.year2045.value / stand.standArea
        : stand.year2045.value;
      dataThirty[stand.year2045H.emission] = isPerAcre
        ? stand.year2045H.value / stand.standArea
        : stand.year2045H.value;
      totalThirty += isPerAcre
        ? (stand.year2045.value + stand.year2045H.value) / stand.standArea
        : stand.year2045.value + stand.year2045H.value;
    } else {
      dataThirty[stand.year2045.emission] = 0;
      dataThirty[stand.year2045H.emission] = 0;
    }
    if (!stand.invalid2055) {
      dataForty[stand.year2055.emission] = isPerAcre
        ? stand.year2055.value / stand.standArea
        : stand.year2055.value;
      dataForty[stand.year2055H.emission] = isPerAcre
        ? stand.year2055H.value / stand.standArea
        : stand.year2055H.value;
      totalForty += isPerAcre
        ? (stand.year2055.value + stand.year2055H.value) / stand.standArea
        : stand.year2055.value + stand.year2055H.value;
    } else {
      dataForty[stand.year2055.emission] = 0;
      dataForty[stand.year2055H.emission] = 0;
    }
    if (!stand.invalid2065) {
      dataFifty[stand.year2065.emission] = isPerAcre
        ? stand.year2065.value / stand.standArea
        : stand.year2065.value;
      dataFifty[stand.year2065H.emission] = isPerAcre
        ? stand.year2065H.value / stand.standArea
        : stand.year2065H.value;
      totalFifty += isPerAcre
        ? (stand.year2065.value + stand.year2065H.value) / stand.standArea
        : stand.year2065.value + stand.year2065H.value;
    } else {
      dataFifty[stand.year2065.emission] = 0;
      dataFifty[stand.year2065H.emission] = 0;
    }

    // Add label for year and total value
    dataCurrent.year = labelFormatter(stand.year2015.name, currentTotal);
    dataTen.year = labelFormatter(stand.year2025.name, totalTen);
    dataTwenty.year = labelFormatter(stand.year2035.name, totalTwenty);
    dataThirty.year = labelFormatter(stand.year2045.name, totalThirty);
    dataForty.year = labelFormatter(stand.year2055.name, totalForty);
    dataFifty.year = labelFormatter(stand.year2065.name, totalFifty);

    // Add to array
    tempData.push(dataCurrent);
    tempData.push(dataTen);
    tempData.push(dataTwenty);
    tempData.push(dataThirty);
    tempData.push(dataForty);
    tempData.push(dataFifty);

    // One series object for each stand subcategory
    const standSeries = {
      dataKey: "Total Stand Carbon",
      stack: stand.standname,
      label: "Total Stand Carbon",
      color: colors.graphColors.forestry.totalStand,
    };
    const harvestedSeries = {
      dataKey: "Harvested Carbon",
      stack: stand.standname,
      label: "Harvested Carbon",
      color: colors.graphColors.forestry.harvested,
    };
    tempSeries.push(standSeries);
    tempSeries.push(harvestedSeries);

    return [tempData, tempSeries];
  }
  const [dataset, setDataset] = useState(() => {
    if (stand) {
      const [chartData, chartSeries] = GenerateDataset();
      return chartData;
    }

    return [];
  });
  const [dataSeries, setDataSeries] = useState(() => {
    if (stand) {
      const [chartData, chartSeries] = GenerateDataset();
      return chartSeries;
    }

    return [];
  });
  useEffect(() => {
    if (stand) {
      const [chartData, chartSeries] = GenerateDataset();

      setDataset(chartData);
      setDataSeries(chartSeries);
    }
  }, [stand, isPerAcre]);

  return (
    <Grid
      container
      direction="column"
      pt={6}
      pb={2}
      id="graphDownload_forestry"
    >
      {/* Chart Title */}
      <Grid item container direction="column" alignItems="center" xs={1}>
        <Typography variant="h5">{`Total Carbon Stock for Forestry: ${stand.standname}`}</Typography>
      </Grid>
      {/* Chart */}
      <Grid item container direction="column" alignItems="center" xs={7}>
        <Box sx={{ height: 750, width: "100%" }}>
          <BarChart
            dataset={dataset}
            series={dataSeries.map((series) => ({
              ...series,
              highlightScope: {
                highlighted: "series",
                faded: "global",
              },
              valueFormatter,
            }))}
            grid={{ horizontal: true }}
            {...chartSetting}
          />
        </Box>
      </Grid>
    </Grid>
  );
}
export default ForestryStandGraph;
