// React
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

// MUI
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import Stack from "@mui/material/Stack";

import ArrowCircleDownTwoToneIcon from "@mui/icons-material/ArrowCircleDownTwoTone";

import AddCircleTwoToneIcon from "@mui/icons-material/AddCircleTwoTone";

// Our Components

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CFTypography from "components/CFTypography";
import CFBox from "components/CFBox";
import CFInput from "components/CFInput";
import CFDialog from "components/CFDialog";
import colors from "assets/theme/base/colors";
import CFButton from "components/CFButton";
import { updateLoading } from "store/reducers/mask";
import { post } from "utils/api";
import { popupError } from "utils/generic";
import { GET_FEEDLOT_WEIGHTS, UPDATE_FEEDLOT_WEIGHTS } from "constants/api";
import CFLookupPicker from "components/CroplandsComponents/CFLookupPicker/CFLookupPicker";
import CFLoading from "components/CFLoading";
import FeedAdditives from "./FeedAdditives";
import FeedCalculator from "./FeedCalculator";

// Constants

export default function FeedLotWeight({
  fdlotid,
  fdlotherdName,
  fdlotyearValue,
  fldlotRation,
  fdlotExpanded,
  fdlotanimalCategory,
  fdlotanimalCategoryId,
  fdlotanagObject,
  fdlotanagObjectChanged,
  fdlotWeightComplete,
  fdlotPopFlag,
  expanded,
  expandToggle,
}) {
  const dispatch = useDispatch();

  const { info } = colors;

  const [localPopupState, updateLocalPopup] = useState({});

  const [fdlotSteerStartWeight, setFdlotSteerStartWeight] = useState("");
  const [fdlotSteerEndWeight, setFdlotSteerEndWeight] = useState("");

  const [fdlotHeiferStartWeight, setFdlotHeiferStartWeight] = useState("");
  const [fdlotHeiferEndWeight, setFdlotHeiferEndWeight] = useState("");
  const [steerExists, setSteerExists] = useState(false);
  const [heiferExists, setHeiferExists] = useState(false);
  const [saveSuccessMsg, setSaveSuccessMsg] = useState(false);
  const [loading, setLoading] = useState(false);
  const currentUser = useSelector((state) => state.user);

  const handleFdlotSteerEndWeightChange = (e) => {
    setFdlotSteerEndWeight(e.target.value);
  };
  const handleFdlotSteerStartWeightChange = (e) => {
    setFdlotSteerStartWeight(e.target.value);
  };
  const handleFdlotHeiferEndWeightChange = (e) => {
    setFdlotHeiferEndWeight(e.target.value);
  };
  const handleFdlotHeiferStartWeightChange = (e) => {
    setFdlotHeiferStartWeight(e.target.value);
  };
  const validateInputs = (
    steerStWeight,
    steerEdWeight,
    heiferStWeight,
    heiferEdWeight
  ) => {
    let isValid = false;

    if (steerExists) {
      if (steerStWeight > 0 && steerEdWeight > 0) {
        isValid = true;
      }
    }

    if (heiferExists) {
      if (heiferStWeight > 0 && heiferEdWeight > 0) {
        isValid = true;
      }
    }

    return isValid;
  };
  const fetchFeedLotWeights = () => {
    setLoading(true);
    const fdlotWeightObj = {
      popid: fdlotid,
      ration: fdlotherdName,
    };
    post(GET_FEEDLOT_WEIGHTS, fdlotWeightObj)
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          setLoading(false);
        } else {
          setFdlotSteerStartWeight(res.data.steerStartWeight);
          setFdlotSteerEndWeight(res.data.steerEndWeight);
          setFdlotHeiferStartWeight(res.data.heiferStartWeight);
          setFdlotHeiferEndWeight(res.data.heiferEndWeight);
          setHeiferExists(res.data.heiferPopulnExists);
          setSteerExists(res.data.steerPopulnExists);

          validateInputs(
            res.data.steerStartWeight,
            res.data.steerEndWeight,
            res.data.heiferStartWeight,
            res.data.heiferEndWeight
          );
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const updateFeedLotWeights = () => {
    setLoading(true);
    const feedWeightsObj = {
      steerStartWeight: fdlotSteerStartWeight,
      steerEndWeight: fdlotSteerEndWeight,
      heiferStartWeight: fdlotHeiferStartWeight,
      heiferEndWeight: fdlotHeiferEndWeight,
      ration: fdlotherdName,
      popid: fdlotid,
    };
    post(UPDATE_FEEDLOT_WEIGHTS, feedWeightsObj)
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          setLoading(false);
        } else {
          // fetchFeedFactors();
          setSaveSuccessMsg(true);

          fdlotWeightComplete(true);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (fldlotRation === fdlotherdName) {
      fetchFeedLotWeights();
    }
  }, [expanded, fdlotPopFlag]);

  return (
    <CFBox width="100%">
      {loading && <CFLoading local />}
      {/* Main form */}
      <CFBox width="100%">
        <Accordion
          expanded={expanded === "Body Weight"}
          onChange={expandToggle("Body Weight")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: colors.text.light }} />}
            aria-controls="animalDetailsCard-content"
            id="animalDetailsCard-header"
            sx={{
              backgroundColor: colors.grey[500],
              borderStyle: "outset",
              borderRadius: ".3em",
              flexDirection: "row-reverse",
            }}
          >
            <Grid
              item
              container
              alignItems="center"
              justifyContent="flex-start"
            >
              {/* Year */}

              <CFBox
                xs={12}
                sx={{
                  textAlign: "left",
                  marginLeft: "1em",
                  marginRight: "1em",
                  fontSize: "medium",
                  fontWeight: "550",
                  color: colors.text.light,
                }}
              >
                Body Weight
              </CFBox>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container direction="row" xs={12} sm={12} md={12}>
              <Grid item xs={12}>
                <CFTypography
                  sx={{
                    fontSize: "0.9em",
                    fontWeight: "bold",
                    color: info.dullFocus,

                    marginBottom: "0.7em",
                  }}
                >
                  Ration Group Body Weight Details
                </CFTypography>
                <CFTypography
                  sx={{
                    fontSize: "0.7em",

                    color: info.dullFocus,

                    marginBottom: "0.7em",
                  }}
                >
                  Enter the starting and end average live body weight per head
                  for this ration.
                </CFTypography>
              </Grid>
            </Grid>

            <Grid container direction="row" xs={12} sm={12} md={12}>
              <Grid container direction="row">
                <Grid item xs={1.8}>
                  <CFBox
                    style={{ width: "6em" }}
                    sx={{
                      backgroundColor: colors.background.header,
                      padding: "10px",
                      marginLeft: "1em",
                      display: "flex",
                      marginTop: "3em",
                      borderStyle: "outset",
                    }}
                  >
                    <CFTypography
                      sx={{
                        fontSize: "12px",

                        color: info.dullFocus,
                        fontWeight: "bold",

                        marginBottom: "1em",
                        marginTop: "1em",
                      }}
                    >
                      Steers
                    </CFTypography>
                  </CFBox>
                </Grid>

                <Grid item xs={2}>
                  <CFBox
                    style={{ width: "8em" }}
                    sx={{
                      backgroundColor: colors.background.header,
                      padding: "10px",

                      display: "flex",
                      marginBottom: "0.5em",
                      borderStyle: "outset",
                    }}
                  >
                    <CFTypography
                      sx={{
                        fontSize: "12px",
                        color: info.dullFocus,
                        fontWeight: "bold",
                        marginBottom: "0.5em",
                      }}
                    >
                      Starting Weight (lbs)
                    </CFTypography>
                  </CFBox>

                  <CFBox
                    style={{ width: "8em" }}
                    sx={{
                      backgroundColor: colors.background.header,
                      padding: "10px",

                      display: "flex",
                      borderStyle: "outset",
                      marginBottom: "1em",
                    }}
                  >
                    <CFInput
                      sx={{
                        fieldset: {
                          borderColor: info.dullFocus,
                          borderStyle: "solid",
                          borderWidth: "2px",
                          marginBottom: "0.5em",
                        },
                      }}
                      style={{
                        width: "6em",
                        backgroundColor: !steerExists ? "grey" : "white",
                      }}
                      color="primary"
                      id="fdlotsteerstartweight"
                      name="fdlotsteerstartweight"
                      defaultValue="0"
                      value={fdlotSteerStartWeight}
                      onChange={handleFdlotSteerStartWeightChange}
                      placeholder=""
                      inputProps={{ disabled: !steerExists }}
                      fullWidth
                    />
                  </CFBox>
                </Grid>

                <Grid item xs={2}>
                  <CFBox
                    style={{ width: "8em" }}
                    sx={{
                      backgroundColor: colors.background.header,
                      padding: "10px",
                      marginLeft: "0.3em",
                      display: "flex",
                      marginBottom: "0.5em",
                      borderStyle: "outset",
                    }}
                  >
                    <CFTypography
                      sx={{
                        fontSize: "12px",

                        color: info.dullFocus,
                        fontWeight: "bold",

                        marginBottom: "0.5em",
                      }}
                    >
                      Ending Weight (lbs)
                    </CFTypography>
                  </CFBox>
                  <CFBox
                    style={{ width: "8em" }}
                    sx={{
                      backgroundColor: colors.background.header,
                      padding: "10px",
                      marginLeft: "0.3em",
                      display: "flex",
                      marginBottom: "0.5em",
                      borderStyle: "outset",
                    }}
                  >
                    <CFInput
                      sx={{
                        fieldset: {
                          borderColor: info.dullFocus,
                          borderStyle: "solid",
                          borderWidth: "2px",
                        },
                      }}
                      style={{
                        width: "6em",
                        backgroundColor: !steerExists ? "grey" : "white",
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      color="primary"
                      id="fdlotsteerendweight"
                      name="fdlotsteerendweight"
                      value={fdlotSteerEndWeight}
                      defaultValue="0"
                      onChange={handleFdlotSteerEndWeightChange}
                      inputProps={{ disabled: !steerExists }}
                      placeholder=""
                      fullWidth
                    />
                  </CFBox>
                </Grid>
              </Grid>
              <Grid container direction="row">
                <Grid item xs={1.8}>
                  <CFBox
                    style={{ width: "6em" }}
                    sx={{
                      backgroundColor: colors.background.header,
                      padding: "10px",
                      marginLeft: "1em",
                      display: "flex",
                      borderStyle: "outset",
                    }}
                  >
                    <CFTypography
                      sx={{
                        fontSize: "12px",

                        color: info.dullFocus,
                        fontWeight: "bold",

                        marginBottom: "1em",
                        marginTop: "1em",
                      }}
                    >
                      Heifers
                    </CFTypography>
                  </CFBox>
                </Grid>

                <Grid item xs={2}>
                  <CFBox
                    style={{ width: "8em" }}
                    sx={{
                      backgroundColor: colors.background.header,
                      padding: "10px",

                      display: "flex",
                      marginBottom: "1em",
                      borderStyle: "outset",
                    }}
                  >
                    <CFInput
                      sx={{
                        fieldset: {
                          borderColor: info.dullFocus,
                          borderStyle: "solid",
                          borderWidth: "2px",
                          marginBottom: "0.5em",
                        },
                      }}
                      style={{
                        width: "6em",
                        backgroundColor: !heiferExists ? "grey" : "white",
                      }}
                      color="primary"
                      id="fdlotheiferstartweight"
                      name="fdlotheiferstartweight"
                      defaultValue="0"
                      value={fdlotHeiferStartWeight}
                      onChange={handleFdlotHeiferStartWeightChange}
                      placeholder=""
                      inputProps={{ disabled: !heiferExists }}
                      fullWidth
                    />
                  </CFBox>
                </Grid>

                <Grid item xs={2}>
                  <CFBox
                    style={{ width: "8em" }}
                    sx={{
                      backgroundColor: colors.background.header,
                      padding: "10px",
                      marginLeft: "0.3em",
                      display: "flex",
                      marginBottom: "1em",
                      borderStyle: "outset",
                    }}
                  >
                    <CFInput
                      sx={{
                        fieldset: {
                          borderColor: info.dullFocus,
                          borderStyle: "solid",
                          borderWidth: "2px",
                        },
                      }}
                      style={{
                        width: "6em",
                        backgroundColor: !heiferExists ? "grey" : "white",
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      color="primary"
                      id="fdlotheiferendweight"
                      name="fdlotheiferendweight"
                      value={fdlotHeiferEndWeight}
                      defaultValue="0"
                      onChange={handleFdlotHeiferEndWeightChange}
                      inputProps={{ disabled: !heiferExists }}
                      placeholder=""
                      fullWidth
                    />
                  </CFBox>
                </Grid>
              </Grid>
            </Grid>

            <Grid container py={2} px={2} justifyContent="space-around">
              <Grid item xs={12} sm={12} md={12}>
                <CFButton
                  type="submit"
                  sx={{
                    float: "right",
                  }}
                  color="progress"
                  onClick={() => {
                    updateFeedLotWeights();
                  }}
                  disabled={
                    !validateInputs(
                      fdlotSteerStartWeight,
                      fdlotSteerEndWeight,
                      fdlotHeiferStartWeight,
                      fdlotHeiferEndWeight
                    )
                  }
                >
                  Save
                </CFButton>
              </Grid>
            </Grid>
            {setSaveSuccessMsg && (
              <Grid container justifyContent="space-around">
                <Grid item xs={2} sm={2} md={2} alignItems="center">
                  <CFBox id="feedSuccessMessage">
                    <CFTypography
                      sx={{
                        fontSize: "15px",
                        color: "green",

                        marginBottom: "0.5em",
                        marginTop: "0.5em",
                      }}
                    >
                      Save was successful.
                    </CFTypography>
                  </CFBox>
                </Grid>
              </Grid>
            )}
          </AccordionDetails>
        </Accordion>
      </CFBox>
    </CFBox>
  );
}
