import { useState, useEffect } from "react";

import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import breakpoints from "assets/theme/base/breakpoints";
// COMET Componets
import CFBox from "components/CFBox";
import CFTypography from "components/CFTypography";
import CFButton from "components/CFButton";
import CFInput from "components/CFInput";
import CFBulletList from "components/CFBulletList";
import bottomBarNavRoutes from "routes/bottomBarNavRoutes";
import { REGISTER_NEWSLETTER } from "constants/api";
import CFarmSocialMediaBottomBox from "./CFarmSocialMediaBottomBox";

const handleSubmit = (e) => {
  e.preventDefault();

  fetch(REGISTER_NEWSLETTER, {
    method: "POST",
    body: JSON.stringify({
      name: e.target.newslettername.value,
      email: e.target.newsletteremail.value,
    }),

    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.message) {
        console.log(data.message);
      }
    });
};

function RegisterForNewsletterComponent() {
  return (
    <CFBox
      component="form"
      method="post"
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <Container>
        <Grid container justifyContent="center">
          <Stack
            direction="column"
            alignItems="flex-start"
            spacing={1}
            sx={{ minWidth: 0 }}
          >
            <CFTypography fontWeight="bold" color="info">
              Newsletter
            </CFTypography>

            <CFInput
              label="Name"
              id="newsletterName"
              aria-label="Name for newsletter registration"
              color="primary"
              key="newslettername"
              name="newslettername"
              placeholder="Name"
              fullWidth
              sx={{ marginBottom: "5px" }}
            />

            <CFInput
              id="newsletterEmail"
              label="Email"
              aria-label="Email for newsletter registration"
              color="primary"
              key="newsletteremail"
              name="newsletteremail"
              placeholder="Email"
              fullWidth
              style={{ marginBottom: "5px" }}
            />
            <CFButton
              label="button to submit name and email for newsletter registration"
              fullWidth
              type="submit"
              color="primary"
              variant="gradient"
              aria-label="submit name and email for newsletter registration"
              sx={{
                marginTop: ".5em",
                flex: 1,
                width: "100%",
                fontSize: ".7em",
                textTransform: "none",
              }}
            >
              {" "}
              Subscribe!
            </CFButton>
          </Stack>
        </Grid>
      </Container>
    </CFBox>
  );
}

function CFarmBottomBar() {
  const [mobileView, setMobileView] = useState(false);

  useEffect(() => {
    // A function that sets the display state for the CfarmBottomBar.
    // Might not be needed actually, going to see how it looks in mobile
    function displayMobileNavbar() {
      if (window.innerWidth < breakpoints.values.lg) {
        setMobileView(true);
      } else {
        setMobileView(false);
      }
    }

    /** 
     The event listener that's calling the displayMobileNavbar function when 
     resizing the window.
    */
    window.addEventListener("resize", displayMobileNavbar);

    // Call the displayMobileNavbar function to set the state with the initial value.
    displayMobileNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", displayMobileNavbar);
  }, []);

  return (
    <CFBox
      width="100%"
      sx={{
        borderTop: "2px #eee solid",
        padding: ".1em",
      }}
    >
      <CFBox
        width="clamp(300px, 100%, 1200px)"
        sx={{
          margin: "auto",
          // borderTop: "2px #eee solid",
          // padding: ".1em",
          // marginTop: "5%",
        }}
        role="contentinfo"
      >
        {!mobileView && (
          <CFBox
            mb={10}
            display="flex"
            justifyContent="space-around"
            alignItems="top"
          >
            <CFarmSocialMediaBottomBox />
            <CFBulletList padding=".5em" point="arrow">
              {bottomBarNavRoutes[1]}
            </CFBulletList>
            <CFBulletList padding=".5em" point="arrow">
              {bottomBarNavRoutes[0]}
            </CFBulletList>
            <CFBox pt={1}>
              <RegisterForNewsletterComponent />
            </CFBox>
          </CFBox>
        )}
        {mobileView && (
          <CFBox>
            <CFBox
              display="flex"
              justifyContent="space-around"
              alignItems="top"
              mb={1}
            >
              <CFarmSocialMediaBottomBox />
              <RegisterForNewsletterComponent />
              <CFBulletList padding=".5em" point="arrow">
                {bottomBarNavRoutes[0]}
              </CFBulletList>
              <CFBulletList padding=".5em" point="arrow">
                {bottomBarNavRoutes[1]}
              </CFBulletList>
            </CFBox>
          </CFBox>
        )}
      </CFBox>
    </CFBox>
  );
}

// Setting default values for the props of CfarmBottomBar
CFarmBottomBar.defaultProps = {};

// Typechecking props for the CFarmBottomBar
CFarmBottomBar.propTypes = {};

export default CFarmBottomBar;
