import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CFarmPage from "components/CFarmParts/CFarmPage";
import CFBox from "components/CFBox";
import CFButton from "components/CFButton";
import CFTypography from "components/CFTypography";
import { VERIFY } from "constants/api";
import { updateLoading } from "store/reducers/mask";
import { post } from "utils/api";
import { popupError, useQuery } from "utils/generic";
import backgroundBarn from "assets/cFarmImages/MainPages/corner_barn.png";

function VerifyAccount() {
  const navigate = useNavigate();
  const query = useQuery();
  const dispatch = useDispatch();

  const [message, setMessage] = useState("");
  const [status, setStatus] = useState("");

  function confirmAccount() {
    dispatch(updateLoading({ loading: true }));
    post(VERIFY, {
      name: query.get("name"),
      key: query.get("key"),
    })
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          dispatch(updateLoading({ loading: false }));
        } else {
          setMessage(res.data);
          setStatus(res.status);
        }

        dispatch(updateLoading({ loading: false }));
      })
      .catch((err) => {
        console.log(err);
        dispatch(updateLoading({ loading: false }));
      });
  }

  useEffect(() => {
    confirmAccount();
  }, []);

  return (
    <CFarmPage>
      <CFBox
        sx={{
          backgroundImage: `url(${backgroundBarn})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right center",
          width: "100%",
        }}
      >
        <Grid
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          container
          component="main"
          style={{ marginTop: "10%", marginBottom: "10%" }}
        >
          <Grid item xs={6} sm={6} md={6}>
            <CFTypography
              textTransform="capitalize"
              fontWeight="bold"
              fontSize="20px"
              sx={{ marginTop: ".5em", top: "0px" }}
            >
              {message}{" "}
            </CFTypography>
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            {status === "OK" && (
              <CFButton
                size="large"
                color="primary"
                variant="gradient"
                type="submit"
                onClick={() => {
                  navigate("/login");
                }}
                sx={{ textTransform: "none" }}
              >
                Login
              </CFButton>
            )}
            {status !== "OK" && (
              <CFButton
                size="large"
                color="primary"
                variant="gradient"
                type="submit"
                onClick={() => {
                  navigate("/register");
                }}
                sx={{ textTransform: "none" }}
              >
                Register
              </CFButton>
            )}
          </Grid>
        </Grid>
      </CFBox>
    </CFarmPage>
  );
}
export default VerifyAccount;
