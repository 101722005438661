import { Grid } from "@mui/material";

import { PropTypes } from "prop-types";

import { displayedActivities } from "pages/ProjectManagement/ChooseActivities/constants";

export default function SelectedActivities({ activityArray }) {
  let locArray = activityArray;
  if (!activityArray) {
    locArray = [];
  }
  if (locArray) {
    return (
      <Grid container spacing={0} sx={{ maxWidth: "10em", marginTop: ".5em" }}>
        {displayedActivities.map((activityType) => (
          <Grid item xs={3} key={`selectedActivity${activityType.id}`}>
            <img
              src={activityType.icon}
              alt={activityType.description}
              style={{
                width: "100%",
                opacity: !locArray.includes(activityType.id) ? ".1" : "1",
                transition: "opacity .3s",
              }}
            />
          </Grid>
        ))}
      </Grid>
    );
  }
}

SelectedActivities.propTypes = {
  activityArray: PropTypes.arrayOf(PropTypes.number).isRequired,
};
