import { createSlice } from "@reduxjs/toolkit";

// initial state
const initialState = {
  validations: {},
};

const validation = createSlice({
  name: "validation",
  initialState,
  reducers: {
    updateValidations(state, action) {
      // eslint-disable-next-line no-param-reassign
      state.validations = action.payload;
    },
  },
});

export default validation.reducer;

export const { updateValidations } = validation.actions;
