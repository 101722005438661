import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CFarmPage from "components/CFarmParts/CFarmPage";
import CFBox from "components/CFBox";
import CFButton from "components/CFButton";
import CFTypography from "components/CFTypography";
import { VERIFY_RESET_PASSWORD_LINK, SET_NEW_PASSWORD } from "constants/api";
import { updateLoading } from "store/reducers/mask";
import { post } from "utils/api";
import { popupError, useQuery } from "utils/generic";
import { verifyPassword } from "utils/auth";
import CFInput from "components/CFInput";
import CFDialog from "components/CFDialog";
import backgroundBarn from "assets/cFarmImages/MainPages/corner_barn.png";

function ResetPassword() {
  const query = useQuery();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [message, setMessage] = useState("");
  const [status, setStatus] = useState("LOADING");
  const [localPopupState, updateLocalPopup] = useState({});
  const [passwordValidationError, setPasswordValidationError] = useState(false);
  const [passwordValidationErrorMessage, setPasswordValidationErrorMessage] =
    useState("");

  const [passwordMismatchError, setPasswordMismatchError] = useState(false);
  const [passwordMismatchErrorMessage, setPasswordMismatchErrorMessage] =
    useState("");

  function confirmAccount() {
    dispatch(updateLoading({ loading: true }));
    post(VERIFY_RESET_PASSWORD_LINK, {
      name: query.get("name"),
      key: query.get("key"),
    })
      .then((res) => {
        console.log("got back ", res);
        if (res.error) {
          popupError(res.error, dispatch);
          dispatch(updateLoading({ loading: false }));
        } else {
          setMessage(res.data);
          console.log(message);
          setStatus(res.status);
        }

        dispatch(updateLoading({ loading: false }));
      })
      .catch((err) => {
        console.log(err);
        dispatch(updateLoading({ loading: false }));
      });
  }

  useEffect(() => {
    confirmAccount();
  }, []);

  const handleSubmit = (e) => {
    dispatch(updateLoading({ loading: true }));
    console.log("submit", e);
    e.preventDefault();
    // send the username and email to the backend register for newsletter function which will handle it
    const vpas = verifyPassword(e.target.registrationPassword.value);
    let valid = true;
    if (vpas) {
      setPasswordValidationError(true);
      setPasswordValidationErrorMessage(vpas);
      valid = false;
    } else {
      setPasswordValidationError(false);
      setPasswordValidationErrorMessage("");
    }
    if (
      e.target.registrationPassword.value !==
      e.target.confirmRegistrationPassword.value
    ) {
      setPasswordMismatchError(true);
      setPasswordMismatchErrorMessage("Passwords do not match.");
      valid = false;
    } else {
      setPasswordMismatchError(false);
      setPasswordMismatchErrorMessage("");
    }

    if (valid) {
      fetch(SET_NEW_PASSWORD, {
        method: "POST",
        body: JSON.stringify({
          name: query.get("name"),
          key: query.get("key"),
          password: e.target.registrationPassword.value,
          confirmPassword: e.target.confirmRegistrationPassword.value,
        }),

        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.error) {
            popupError(data.error, dispatch);
          }
          if (data.data) {
            updateLocalPopup({
              message: data.data,
              title: "Reset Password Request Submitted",
              showPopup: true,
              closeAction: () => {
                navigate("/home");
              },
            });
          }
        })
        .catch((err) => {
          popupError(err, dispatch);
          console.log(err);
        })
        .finally(() => {
          dispatch(updateLoading({ loading: false }));
        });
    }
  };

  return (
    <CFarmPage>
      <CFBox
        sx={{
          backgroundImage: `url(${backgroundBarn})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right center",
          width: "100%",
        }}
      >
        {status === "OK" && ( // set a new password
          <CFBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            component="form"
            method="post"
            autoComplete="off"
            onSubmit={handleSubmit}
            sx={{
              marginLeft: "30%",
              width: "30%",
            }}
          >
            <Grid
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              container
              component="main"
              style={{ marginTop: "10%", marginBottom: "10%" }}
            >
              <Grid
                container
                flexDirection="column"
                alignItems="center"
                item
                xs={12}
                sm={12}
                md={12}
                component={Paper}
                style={{ border: "solid #64643c" }}
              >
                <Grid item xs={6} sm={6} md={6}>
                  <CFTypography sx={{ fontSize: "36px" }} fontWeight="bold">
                    Welcome
                  </CFTypography>
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <CFTypography sx={{ fontSize: "24px", marginBottom: "10px" }}>
                    Please change your password
                  </CFTypography>
                </Grid>

                <Grid
                  container
                  sx={{ paddingLeft: "8%", paddingRight: "8%" }}
                  xs={12}
                  sm={12}
                  md={12}
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="center"
                  item
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <CFInput
                      required
                      sx={{
                        padding: "10px",
                        fieldset: {
                          borderColor: passwordValidationError
                            ? "red"
                            : "#64643c",
                          borderStyle: "solid",
                          borderWidth: "2px",
                        },
                      }}
                      key="registrationPassword"
                      name="registrationPassword"
                      placeholder="Password"
                      type="password"
                      error={passwordValidationError}
                      label="Password"
                      helperText={passwordValidationErrorMessage}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <CFInput
                      required
                      sx={{
                        padding: "10px",
                        fieldset: {
                          borderColor: passwordMismatchError
                            ? "red"
                            : "#64643c",
                          borderStyle: "solid",
                          borderWidth: "2px",
                        },
                      }}
                      error={passwordMismatchError}
                      helperText={passwordMismatchErrorMessage}
                      key="confirmRegistrationPassword"
                      name="confirmRegistrationPassword"
                      placeholder="Confirm Password"
                      type="password"
                      label="Confirm Password"
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid sx={{ padding: "1em" }} item xs={8} sm={8} md={8}>
                  <CFButton
                    size="large"
                    color="primary"
                    variant="gradient"
                    type="submit"
                    sx={{ textTransform: "none" }}
                  >
                    Update Password
                  </CFButton>
                </Grid>
              </Grid>
            </Grid>
          </CFBox>
        )}

        {status === "ERROR" && ( // inform user the password reset link is invalid
          <CFTypography style={{ marginLeft: "35%" }}>
            The password reset link is invalid or expired. Please submit a new
            request to try again.
          </CFTypography>
        )}
        {status === "LOADING" && ( // loading
          <CFTypography style={{ marginLeft: "35%" }}>
            Verifying password reset link. Please wait.
          </CFTypography>
        )}
        {localPopupState && localPopupState.showPopup && (
          <CFDialog
            {...localPopupState}
            updatePopup={(e) => updateLocalPopup(e)}
          />
        )}
      </CFBox>
    </CFarmPage>
  );
}
export default ResetPassword;
