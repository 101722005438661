import CFBox from "components/CFBox";
import CFarmPage from "components/CFarmParts/CFarmPage";
import backgroundBarn from "assets/cFarmImages/MainPages/corner_barn.png";
import CFTypography from "components/CFTypography";

const LegacyInfoPage = (
  <div id="newsIntro">
    <h3>Thank you for your interest in the COMET-Farm tool!</h3>

    <h4>
      Please contact us via the{" "}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://cometfarm.freshdesk.com/support/home"
        style={{ color: "#000080" }}
      >
        help desk
      </a>{" "}
      or send an email to{" "}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="mailto:appnrel@colostate.edu"
        style={{ color: "#000080" }}
      >
        appnrel@colostate.edu
      </a>{" "}
      with any questions or comments or for any training requests.
    </h4>
    <br />
    <h3>
      New features and enhancements to COMET-Farm Version GUI-Experimental
      <br />
      30 September 2022
    </h3>
    <div id="features">
      <ul
        style={{
          listStyleType: "disc",
          paddingLeft: "15px",
          paddingTop: "10px",
        }}
      >
        <li style={{ marginBottom: "5px" }}>
          An updated version of the DayCent model moving to the 30cm depth
          version from the 20cm depth version. An overview of differences
          between versions is summarized here:{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://storage.googleapis.com/public-sourcecode-access/COMET_Versions_Comparison_Sept2022.pdf"
            style={{ color: "#000080" }}
          >
            COMET Versions Comparison – September 2022
          </a>
          .
        </li>
        <li style={{ marginBottom: "5px" }}>
          New direct N2O emissions estimates using the DayCent model.
        </li>
        <li style={{ marginBottom: "5px" }}>
          New conservation practices available on the conservation practice
          advisor (CPA) for use in future scenarios.
        </li>
        <li style={{ marginBottom: "5px" }}>
          Improved readability and accessibility for PDF help documents.
        </li>
        <li style={{ marginBottom: "5px" }}>
          New available water holding capacity report.
        </li>
        <li style={{ marginBottom: "5px" }}>Numerous bug and defect fixes.</li>
      </ul>
    </div>
    <div id="changelog">
      <h3>Change Log GUI-Experimental</h3>
      <ul
        style={{
          listStyleType: "disc",
          paddingLeft: "15px",
          paddingTop: "10px",
        }}
      >
        <li style={{ marginBottom: "5px" }}>
          10/11/22 - Enteric emissions reported estimates updated
        </li>
        <li style={{ marginBottom: "5px" }}>
          10/11/22 - Downloading detailed animal agriculture report bug resolved
        </li>
        <li style={{ marginBottom: "5px" }}>
          10/14/22 - Available Water Holding Capacity report visible for all
          users
        </li>
        <li style={{ marginBottom: "5px" }}>
          10/17/22 - New user registration email bug resolved
        </li>
        <li style={{ marginBottom: "5px" }}>
          10/25/22 - Cropland report generation bug fixed. Single user impacted.
        </li>
        <li style={{ marginBottom: "5px" }}>
          10/27/22 - Animal Agriculture project import failure resolved
        </li>
        <li style={{ marginBottom: "5px" }}>
          11/2/22 - Blank cropland detailed export baseline report resolved
        </li>
        <li style={{ marginBottom: "5px" }}>
          11/10/22 - Agroforestry management table resets after county change
        </li>
        <li style={{ marginBottom: "5px" }}>
          11/9/22 - Reported values on cropland export file updated. Impacting
          only projects where a cover crop was added in the final year of the
          scenario.
        </li>
        <li style={{ marginBottom: "5px" }}>
          11/14/22 - Agroforestry report not running with other accounting
          activities resolved.
        </li>
        <li style={{ marginBottom: "5px" }}>
          11/14/22 - Available water holding capacity report showing 0s for
          cover crops bug is resolved.
        </li>
        <li style={{ marginBottom: "5px" }}>
          11/15/22- Indirect N20 emissions equations updated
        </li>
      </ul>
    </div>
  </div>
);

function Version() {
  return (
    <CFarmPage>
      <CFBox
        sx={{
          fontSize: "1em",
          margin: "1em",
          paddingTop: "5%",
          backgroundImage: `url(${backgroundBarn})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right center",
        }}
      >
        <CFTypography variant="h1" pb=".5em">
          Versions{" "}
        </CFTypography>
        {LegacyInfoPage}
      </CFBox>
    </CFarmPage>
  );
}
export default Version;
