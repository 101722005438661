export default {
  width: "1200px",
};

export const croplandTableStyle = `
td{
 border:1px solid black;
 min-width:2em;
 padding:.5em;
}
table {
  padding:.5em;
  border-spacing: 0;
  border-collapse: collapse;
  margin-right:.5em;
}`;
