import { configureStore } from "@reduxjs/toolkit";

import createSagaMiddleware from "redux-saga";
import rootSaga from "../sagas";
import user from "./reducers/user";
import mask from "./reducers/mask";
import validation from "./reducers/validation";
import popup from "./reducers/popup";
import map from "./reducers/map";
import general from "./reducers/general";

// ==============================|| REDUX TOOLKIT - MAIN STORE ||============================== //

export default function configureAppStore(intialState) {
  const sagaMiddleware = createSagaMiddleware();

  const store = configureStore({
    reducer: {
      user,
      mask,
      validation,
      popup,
      map,
      general,
    },
    devTools: process.env.NODE_ENV !== "production",
    intialState,
    // replace thunk with sagas for async tasks
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ thunk: false }).prepend(sagaMiddleware),
  });

  sagaMiddleware.run(rootSaga);
  return store;
}
