// React
import { useEffect, useState } from "react";

import { useDispatch } from "react-redux";

// MUI
import { Grid, Dialog, Checkbox } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { pink, red, grey } from "@mui/material/colors";
import { Check, ArrowCircleRightTwoTone } from "@mui/icons-material";

import CFStep from "components/CFarmParts/CFStep";

import colors from "assets/theme/base/colors";
// Our Components
import CFBox from "components/CFBox";

import CFTypography from "components/CFTypography";
import CFButton from "components/CFButton";

import { post } from "utils/api";
import { popupError } from "utils/generic";
import {
  COPY_FEEDLOT_RATIONS,
  FETCH_ALL_RATION_GROUPS,
  FETCH_ALL_RATIONS_WITHOUT_DATA,
} from "constants/api";
import CFLoading from "components/CFLoading";

// Constants

export default function CopyRation({
  isRationOpen,
  setIsRationOpen,
  fdlotid,
  fdlotherdName,
  fdlotyearValue,
  fdlotanimalCategory,
  fdlotanimalCategoryId,
  fdlotanagobject,
  fdlotanagobjectChanged,
  allRations,
  copyRationClicked,
}) {
  const dispatch = useDispatch();

  const [noDataRationArr, setNoDataRationArr] = useState([]);

  const [loading, setLoading] = useState(false);

  const [checkedCells, setCheckedCells] = useState({});
  const [rows, setRows] = useState({});
  const { palette } = createTheme();
  const theme = createTheme({
    palette: {
      hasData: palette.augmentColor({ color: pink }),
    },
  });

  const fetchAllRationGroupsWithoutData = () => {
    setLoading(true);
    const popObj = {
      popid: fdlotid,
    };
    post(FETCH_ALL_RATIONS_WITHOUT_DATA, popObj)
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          setLoading(false);
        } else {
          const noDataRationList = JSON.parse(res.data);

          setNoDataRationArr(noDataRationList);
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const getRows = (data) => {
    const rtnRows = [
      {
        id: data.id,
      },
    ];
    if (data.rations["Ration Group 1"] !== undefined) {
      rtnRows[0].RationGroup1 = data.rations["Ration Group 1"];
    }

    if (data.rations["Ration Group 2"] !== undefined) {
      rtnRows[0].RationGroup2 = data.rations["Ration Group 2"];
    }
    if (data.rations["Ration Group 3"] !== undefined) {
      rtnRows[0].RationGroup3 = data.rations["Ration Group 3"];
    }
    if (data.rations["Ration Group 4"] !== undefined) {
      rtnRows[0].RationGroup4 = data.rations["Ration Group 4"];
    }
    if (data.rations["Ration Group 5"] !== undefined) {
      rtnRows[0].RationGroup5 = data.rations["Ration Group 5"];
    }
    return rtnRows;
  };
  const fetchCheckBoxRows = () => {
    setLoading(true);
    const popObj = {
      popid: fdlotid,
    };
    post(FETCH_ALL_RATION_GROUPS, popObj)
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          setLoading(false);
        } else {
          const rationRows = getRows(res.data);
          setRows(rationRows);
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const addArrow = (cell) => {
    const text = "Copy";

    return (
      <Grid container item sx={{ cursor: "pointer" }}>
        <Grid item xs={4}>
          <CFTypography>{text}</CFTypography>
        </Grid>
        <Grid item xs={2}>
          <ArrowCircleRightTwoTone
            color="primary"
            fontSize="medium"
            // onClick={() => copyRationChecked()}
          />
        </Grid>
      </Grid>
    );
  };
  const celllChecked = (checked, cell) => {
    const { id } = cell.row;

    const { field } = cell;
    const NewCheckedCells = { ...checkedCells };
    if (!NewCheckedCells[id]) {
      NewCheckedCells[id] = [];
    }
    NewCheckedCells[id][field] = checked;
    setCheckedCells(NewCheckedCells);
  };

  const getCell = (cell, rtnGrp) => {
    const rationVal = rtnGrp?.name;
    return (
      <ThemeProvider theme={theme}>
        {rationVal === fdlotherdName && (
          <Check
            checked
            sx={
              rationVal
                ? {
                    color: grey[900],
                    "&.Mui-checked": {
                      color: grey[900],
                    },
                  }
                : {}
            }
          />
        )}

        {rationVal !== fdlotherdName && (
          <Checkbox
            sx={
              !noDataRationArr.includes(rationVal)
                ? {
                    color: red[800],
                    "&.Mui-checked": {
                      color: red[600],
                    },
                  }
                : {
                    color: grey[900],
                    "&.Mui-checked": {
                      color: grey[900],
                    },
                  }
            }
            checked={
              checkedCells[cell.row.id]
                ? checkedCells[cell.row.id][cell.field] === true
                : false
            }
            onChange={(e) => {
              celllChecked(e.target.checked, cell);
            }}
          />
        )}
      </ThemeProvider>
    );
  };
  const columns = [
    {
      field: "id",
      headerName: "Ration Group",
      width: 200,
      renderCell: addArrow,
    },

    {
      field: "RationGroup1",
      headerName: "Group 1",
      width: 150,
      renderCell: (params) => getCell(params, params.row.RationGroup1),
    },

    {
      field: "RationGroup2",
      headerName: "Group 2",
      width: 150,
      renderCell: (params) => getCell(params, params.row.RationGroup2),
    },

    {
      field: "RationGroup3",
      headerName: "Group 3",
      width: 150,
      renderCell: (params) => getCell(params, params.row.RationGroup3),
    },

    {
      field: "RationGroup4",
      headerName: "Group 4",
      width: 150,
      renderCell: (params) => getCell(params, params.row.RationGroup4),
    },

    {
      field: "RationGroup5",
      headerName: "Group 5",
      width: 150,
      renderCell: (params) => getCell(params, params.row.RationGroup5),
    },
  ];

  const clickRationName = (cell) => {
    const { id, field } = cell;
    if (field !== "id") {
      return;
    }

    const NewCheckedCells = { ...checkedCells };

    let checked = true;

    if (
      NewCheckedCells[id] &&
      NewCheckedCells[id][columns[1].field] &&
      NewCheckedCells[id][columns[2].field] &&
      NewCheckedCells[id][columns[3].field] &&
      NewCheckedCells[id][columns[4].field] &&
      NewCheckedCells[id][columns[5].field]
    ) {
      checked = false;
    }

    for (let i = 1; i < columns.length; i += 1) {
      const ration = columns[i].field;

      if (!NewCheckedCells[id]) {
        NewCheckedCells[id] = [];
      }
      NewCheckedCells[id][ration] = checked;
    }
    setCheckedCells({ ...checkedCells, ...NewCheckedCells });
  };

  const getSelectedRations = (rationVals) => {
    const copyRtns = [];
    if (rationVals[0].RationGroup1 && allRations.includes("Ration Group 1")) {
      copyRtns.push("Ration Group 1");
    }
    if (rationVals[0].RationGroup2 && allRations.includes("Ration Group 2")) {
      copyRtns.push("Ration Group 2");
    }
    if (rationVals[0].RationGroup3 && allRations.includes("Ration Group 3")) {
      copyRtns.push("Ration Group 3");
    }
    if (rationVals[0].RationGroup4 && allRations.includes("Ration Group 4")) {
      copyRtns.push("Ration Group 4");
    }
    if (rationVals[0].RationGroup5 && allRations.includes("Ration Group 5")) {
      copyRtns.push("Ration Group 5");
    }
    return copyRtns;
  };
  const copyRations = () => {
    setLoading(true);

    const selRations = getSelectedRations(Object.values(checkedCells));

    const copyObj = {
      selration: fdlotherdName,
      popid: fdlotid,
      selyrations: selRations,
    };

    post(COPY_FEEDLOT_RATIONS, copyObj)
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          setLoading(false);
        } else {
          setIsRationOpen(false);
          const noDataRationList = JSON.parse(res.data);
          setIsRationOpen(false);

          copyRationClicked(noDataRationList);
          // setAnagCategory(json);
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const handleClose = () => {
    setIsRationOpen(false);
  };

  useEffect(() => {
    if (isRationOpen) {
      setCheckedCells({});
      fetchAllRationGroupsWithoutData();
      fetchCheckBoxRows();
    }
  }, [isRationOpen]);

  return (
    <Dialog
      open={isRationOpen}
      fullWidth
      maxWidth="xl"
      maxHeight="xl"
      sx={{ zIndex: 5 }}
    >
      {loading && <CFLoading local />}
      {/* Main form */}
      <CFBox width="100%" sx={{ padding: "2em", display: "flex" }}>
        {/* Grid with four rows: title, step one and two, step three, and action buttons */}
        <Grid container direction="row" xs={12} sm={12} md={12}>
          <Grid item container xs={12} alignItems="center" direction="row">
            <CFBox style={{ display: "flex" }}>
              <Grid item xs={9}>
                {/* Window Header */}
                <CFStep
                  stepLabel={`Copy ${fdlotherdName} Management`}
                  stepNumber=""
                  helpText="help"
                />

                <CFTypography
                  sx={{
                    fontSize: "14px",

                    fontWeight: "bold",

                    marginBottom: "1em",
                    marginLeft: "1em",
                  }}
                >
                  To copy ration group details to another ration group, select
                  the ration group to copy to below. Copying ration group
                  details to another ration group where data exists will
                  overwrite the existing data. Users may make changes to the
                  ration group following the copy. <br />
                </CFTypography>
              </Grid>
              <Grid item xs={3}>
                <CFBox
                  sx={{
                    right: 0,
                    margin: ".5em",
                    marginRight: "2em",
                    border: "1px solid black",
                    backgroundColor: "light grey",
                  }}
                >
                  <Grid item container xs={12}>
                    <Grid item container xs={12}>
                      <ThemeProvider theme={theme}>
                        <Check
                          sx={{
                            marginTop: "1em",
                            marginLeft: "0.5em",
                            color: grey[800],
                            "&.Mui-checked": {
                              color: grey[600],
                            },
                          }}
                        />
                      </ThemeProvider>
                      <CFTypography variant="h6" pl={2} py={2}>
                        group to be copied
                      </CFTypography>
                    </Grid>
                    <Grid item container xs={12}>
                      <ThemeProvider theme={theme}>
                        <Checkbox
                          checked
                          sx={{
                            color: grey[900],
                            "&.Mui-checked": {
                              color: grey[900],
                            },
                          }}
                        />
                      </ThemeProvider>
                      <CFTypography variant="h6" pl={2} py={2}>
                        group to copy data to
                      </CFTypography>
                    </Grid>
                    <Grid item container xs={12}>
                      <ThemeProvider theme={theme}>
                        <Checkbox
                          sx={{
                            color: red[800],
                            "&.Mui-checked": {
                              color: red[600],
                            },
                          }}
                        />
                      </ThemeProvider>
                      <CFTypography variant="h6" pl={2} py={2}>
                        group has data
                      </CFTypography>
                    </Grid>
                  </Grid>
                </CFBox>
              </Grid>
            </CFBox>
          </Grid>

          {/* Grid for steps one and two */}
          <Grid container direction="row" spacing={4}>
            <Grid item xs={12}>
              {isRationOpen && (
                <DataGrid
                  rows={rows}
                  columns={columns}
                  onCellClick={clickRationName}
                  // processRowUpdate={processRowUpdate}
                  // onProcessRowUpdateError={handleProcessRowUpdateError}

                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                    columns: {
                      columnVisibilityModel: {
                        RationGroup1: allRations.includes("Ration Group 1"),
                        RationGroup2: allRations.includes("Ration Group 2"),
                        RationGroup3: allRations.includes("Ration Group 3"),
                        RationGroup4: allRations.includes("Ration Group 4"),
                        RationGroup5: allRations.includes("Ration Group 5"),
                      },
                    },
                  }}
                  // checkboxSelection
                  disableColumnMenu
                  disableRowSelectionOnClick
                  hideFooter
                />
              )}
            </Grid>
            <Grid container py={2} px={2} justifyContent="space-around">
              {/* Return to projects */}
              <Grid item xs={6} sm={6} md={6}>
                <CFButton color="secondary" onClick={() => handleClose()}>
                  Cancel
                </CFButton>
              </Grid>

              {/* Management Details */}
              <Grid item xs={2} sm={2} md={2}>
                <CFButton
                  color="progress"
                  onClick={() => copyRations()}
                  // disabled={showNameError}
                >
                  Save and Copy
                </CFButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CFBox>
    </Dialog>
  );
}
