/* eslint-disable prefer-destructuring */
/* eslint-disable react/no-array-index-key */
import { useEffect, useState } from "react";
import {
  FormControlLabel,
  Grid,
  Input,
  InputAdornment,
  Radio,
  TextField,
  Typography,
} from "@mui/material";

// Date picker
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";

import { Box } from "@mui/system";

import colors from "assets/theme/base/colors";
import CFLookupPicker from "components/CroplandsComponents/CFLookupPicker/CFLookupPicker";
import CFManagementSelectionCard from "components/CroplandsComponents/CFManagementSelectionCard/CFManagementSelectionCard";
import ManHelp from "assets/cFarmImages/HelpImages/ManHelp.webp";
import { preloadImage } from "utils/generic";
import { croplandTableStyle } from "pages/Croplands/CroplandStyle";
import CFBox from "components/CFBox";
import HelpAndButton from "../../../../../components/CroplandsComponents/EditCropCard_StructuralComponents/HelpAndButton";
import DeleteButton from "../../../../../components/CroplandsComponents/EditCropCard_StructuralComponents/DeleteButton";
import WhiteBackgroundDatepicker from "../../../../../components/CroplandsComponents/EditCropCard_StructuralComponents/WhiteBackgroundDatepicker";

export default function ({
  CropEvent,
  SetPropertyFunction,
  feedStock,
  productionTechnology,
}) {
  useEffect(() => {
    CropEvent.bioCharAmendments.forEach((app) => {
      // eslint-disable-next-line no-param-reassign
      app.localhcRatio = app.hcRatio;
    });
  });

  const addNewBioChar = () => {
    const newCE = { ...CropEvent };

    newCE.bioCharAmendments.push({
      amountApplied: 0, // ton/ac

      hcRatio: 0.15, // Molar ratio of the H to the organic carbon content  //valid vaue is between .15 and .7

      feedstock: feedStock[0],

      productionTechnology: productionTechnology[0],

      id: 0,
    });

    SetPropertyFunction(newCE);
  };

  const deleteBioChar = (index) => {
    const newCE = { ...CropEvent };
    newCE.bioCharAmendments.splice(index, 1);

    SetPropertyFunction(newCE);
  };

  const SetAmountApplied = (newVal, index) => {
    const newCE = { ...CropEvent };

    const cleanedValue = Math.max(newVal, 0);

    newCE.bioCharAmendments[index].amountApplied = cleanedValue;

    SetPropertyFunction(newCE);
  };

  const SetMolarHC = (newVal, index) => {
    const newCE = { ...CropEvent };
    newCE.bioCharAmendments[index].localhcRatio = newVal;
    const cleanedValue = Math.max(Math.min(newVal, 0.7), 0.15);
    newCE.bioCharAmendments[index].hcRatio = cleanedValue;

    SetPropertyFunction(newCE);
  };

  const SetFeedStock = (newVal, index) => {
    const newCE = { ...CropEvent };

    newCE.bioCharAmendments[index].feedstock = newVal;

    SetPropertyFunction(newCE);
  };

  const SetProductionTechnology = (newVal, index) => {
    console.log(newVal);
    const newCE = { ...CropEvent };

    if (newVal.name === "Pyrolysis") {
      newCE.bioCharAmendments[index].productionTechnology =
        productionTechnology[0];
    } else {
      newCE.bioCharAmendments[index].productionTechnology =
        productionTechnology[1];
    }

    SetPropertyFunction(newCE);
  };

  return (
    <Grid alignItems="center" container flexDirection="row">
      <Grid item xs={12}>
        {CropEvent.bioCharAmendments.map((app, ind) => (
          <Grid
            item
            xs={12}
            container
            flexDirection="row"
            alignItems="center"
            key={`application${ind}`}
            sx={{ paddingTop: ".5em", marginTop: "1em" }}
          >
            <Grid
              item
              xs={12}
              container
              flexDirection="row"
              alignItems="center"
            >
              <Grid item xs={11} xl={4}>
                <CFManagementSelectionCard
                  label="Amount Applied"
                  labelWidth={5}
                  input={
                    <Input
                      label="ton/ac"
                      endAdornment={
                        <InputAdornment position="end">ton/ac</InputAdornment>
                      }
                      type="number"
                      sx={{
                        backgroundColor: colors.white.main,
                        fontSize: "1.25em",
                        paddingInline: ".3em",
                        borderRadius: ".3em",
                      }}
                      value={app.amountApplied}
                      onChange={(e) => SetAmountApplied(e.target.value, ind)}
                    />
                  }
                />
              </Grid>
              <Grid item xl={7} xs={11}>
                <CFManagementSelectionCard
                  label={
                    <Box>
                      Molar ratio of the H to the organic carbon content (H:C
                      <sub style={{ fontSize: ".5em" }}>org</sub>)
                    </Box>
                  }
                  labelWidth={8}
                  input={
                    <Box sx={{ display: "flex" }}>
                      <Input
                        label="(mol H/mol organic C)"
                        type="number"
                        inputProps={{
                          step: 0.01,
                        }}
                        sx={{
                          backgroundColor: colors.white.main,
                          fontSize: "1.25em",
                          paddingInline: ".3em",
                          borderRadius: ".3em",
                        }}
                        value={app.localhcRatio}
                        onChange={(e) => SetMolarHC(e.target.value, ind)}
                      />
                      <sub
                        style={{
                          marginLeft: "1em",
                          fontSize: ".65em",
                          width: "13em",
                        }}
                      >
                        <div style={{ float: "left" }}>
                          <div
                            style={{
                              borderBottom: "1px solid",

                              textAlign: "center",
                            }}
                          >
                            <sub> mol H</sub>
                          </div>
                          <div
                            style={{
                              textAlign: "center",
                            }}
                          >
                            <sub>mol organic C</sub>
                          </div>
                        </div>
                      </sub>
                    </Box>
                  }
                />
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              container
              flexDirection="row"
              alignItems="center"
            >
              <Grid item xs={11} xl={4}>
                <CFManagementSelectionCard
                  //  sx={{ marginTop: 0 }}
                  label="Feedstock"
                  labelWidth={4}
                  input={
                    <CFLookupPicker
                      label="Select a feedstock"
                      required
                      sx={{ width: "100%" }}
                      value={app.feedstock}
                      onChange={(event, newValue) => {
                        SetFeedStock(newValue, ind);
                      }}
                      options={feedStock}
                    />
                  }
                />
              </Grid>

              <Grid item xl={7} xs={11}>
                <CFManagementSelectionCard
                  label="Production Technology"
                  labelWidth={6}
                  input={
                    <CFBox sx={{ display: "flex" }}>
                      <FormControlLabel
                        value="Pyrolysis"
                        control={
                          <Radio
                            checked={
                              app.productionTechnology.name === "Pyrolysis"
                            }
                            onChange={(e) => {
                              SetProductionTechnology(
                                productionTechnology[0],
                                ind
                              );
                            }}
                            value
                            name="radio-buttons"
                            inputProps={{ "aria-label": "Pyrolysis" }}
                          />
                        }
                        label="Pyrolysis"
                        labelPlacement="end"
                      />

                      <FormControlLabel
                        value="Gasification"
                        control={
                          <Radio
                            checked={
                              app.productionTechnology.name !== "Pyrolysis"
                            }
                            onChange={(e) => {
                              SetProductionTechnology(
                                productionTechnology[1],
                                ind
                              );
                            }}
                            value
                            name="radio-buttons"
                            inputProps={{ "aria-label": "Gasification" }}
                          />
                        }
                        label="Gasification"
                        labelPlacement="end"
                      />
                    </CFBox>
                  }
                />
              </Grid>

              <Grid item xs={1}>
                <DeleteButton deleteAction={() => deleteBioChar(ind)} />
              </Grid>
            </Grid>
          </Grid>
        ))}
        <HelpAndButton
          buttonLabel="+ Add Biochar Amendment"
          buttonAction={addNewBioChar}
          userGuidePopupWidth="900px"
          helperText={
            <CFBox sx={{ overflow: "auto", height: "600px" }}>
              Biochar help text needed
            </CFBox>
          }
        />{" "}
      </Grid>
    </Grid>
  );
}
