/* eslint-disable prefer-destructuring */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint no-underscore-dangle: "OFF" */

import { useEffect, useRef } from "react";

/* Openlayers */
import BingMaps from "ol/source/BingMaps";
import Map from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import WKT from "ol/format/WKT";
import { Style, Text, Fill, Stroke, Icon } from "ol/style";
import { defaults } from "ol/interaction/defaults";
import "ol/ol.css";

import CFBox from "components/CFBox";
import pointMarkerSelectedComplete from "assets/images/field/field_point_complete_selected.png";
import { distinguishedColors } from "./waterHolding.utils";

function WhcMap({ location, soilComponents, color }) {
  const mapRef = useRef();

  // Default field style
  const fill = new Fill({
    color: "rgba(247,247,247,0.3)",
  });
  const stroke = new Stroke({
    color: "rgba(33,107,164,1)",
    width: 5,
  });

  // Generate label text for this map
  function getLabelText(feature) {
    const fieldName = location.Name;
    const areaAcres = location.Area.toFixed(0);
    let label = `Location: ${fieldName}\nTotal Parcel Area: ${areaAcres} (acres)\n\n`;
    if (soilComponents.length === 1) {
      const muName = soilComponents[0].soilComponent.Texture;
      const muId = soilComponents[0].map_unit_id;

      const mapUnitGeom = soilComponents[0].soilComponent.MapUnitGeometryString;
      const soilComponentFormat = new WKT();
      const mapunitFeature = soilComponentFormat.readFeature(mapUnitGeom, {
        dataProjection: "ESRI:3857",
      });
      const relMUsize =
        mapunitFeature.getGeometry().getArea() /
        feature.getGeometry().getArea();
      const MUareaAcres = (location.Area * relMUsize).toFixed(0);

      label =
        `Location: ${fieldName}\nTotal Parcel Area: ${areaAcres} (acres)\n\n` +
        `Map Unit Name: ${muName}\nMap Unit ID: ${muId}\nSoil Focus Area: ${MUareaAcres} (acres)`;
    }
    return label;
  }

  useEffect(() => {
    const mapLayers = [
      // Aerial map
      new TileLayer({
        visible: true,
        preload: Infinity,
        source: new BingMaps({
          key: "AipFgxAUWr-IAOTpXwf-Kac7KIdHCMD-9is8dpE8fnb690-CZAjGGGQumVN89a-E",
          imagerySet: "Aerial",
          placeholderTiles: false,
        }),
      }),
    ];

    // Read location geometry
    const format = new WKT();
    const geom = location.GeometryString;
    const feature = format.readFeature(geom, { dataProjection: "EPSG:3857" });

    // Generate label text
    const label = getLabelText(feature);
    const textStyle = new Text({
      fill: new Fill({ color: "rgba(255,255,255, 1" }),
      stroke: new Stroke({ color: "rgba(33, 107, 164,.7)", width: 3 }),

      font: "bold 12px Arial, Verdana, Helvetica, sans-serif",
      offsetY: 10,
      offsetX: -20,
      text: label,
      overflow: true,
      textAlign: "left",
    });
    const spatialOverlayStyle = new Style({
      fill,
      stroke,
      text: textStyle,
      image: new Icon({
        anchor: [0.5, 46],
        anchorXUnits: "fraction",
        anchorYUnits: "pixels",
        opacity: 0.75,
        src: pointMarkerSelectedComplete,
      }),
    });
    feature.setStyle(spatialOverlayStyle);

    // Add soil components to map
    const mapUnitFormat = new WKT();
    const vectorSourceFeature = new VectorSource({
      features: [feature],
    });
    const vectorFeat = new VectorLayer({
      source: vectorSourceFeature,
    });
    soilComponents.forEach((sc, mu) => {
      // Get soil component geometry
      const mapunitFeature = mapUnitFormat.readFeature(
        sc.soilComponent.MapUnitGeometryString,
        {
          dataProjection: "ESRI:3857",
        }
      );

      // Styling
      const mapunitFeatureFill = new Fill({
        color: color ?? distinguishedColors[mu % distinguishedColors.length],
      });
      const mapunitFeatureStroke = new Stroke({
        color: color ?? distinguishedColors[mu % distinguishedColors.length],
        width: 2,
      });
      const mapunitFeaturespatialOverlayStyle = new Style({
        fill: mapunitFeatureFill,
        stroke: mapunitFeatureStroke,

        image: new Icon({
          anchor: [0.5, 46],
          anchorXUnits: "fraction",
          anchorYUnits: "pixels",
          opacity: 0.75,
          src: "/images/map/field_point.gif",
        }),
      });
      mapunitFeature.setStyle(mapunitFeaturespatialOverlayStyle);

      // Generate layer and add to array
      const vectorSourceMapUNFeature = new VectorSource({
        features: [mapunitFeature],
      });
      const vectorMapUnFeat = new VectorLayer({
        source: vectorSourceMapUNFeature,
      });
      mapLayers.push(vectorMapUnFeat);
    });

    // Add label and field layer
    mapLayers.push(vectorFeat);

    // Create default map
    const baseMap = new Map({
      layers: mapLayers,
      interactions: defaults({
        mouseWheelZoom: false,
        doubleClickZoom: false,
      }),
      controls: [],
      view: new View({
        center: [-10713413.88445, 4931105.568733],
        zoom: 13,
      }),
    });

    // Center map on field location
    let lat;
    let long;
    const geometry = feature.getGeometry();
    if (geometry) {
      if (geometry.getType === "Point") {
        lat = geometry.getCoordinates()[0];
        long = geometry.getCoordinates()[1];
      } else {
        lat = geometry.getCoordinates()[0][0];
        long = geometry.getCoordinates()[0][1];
      }

      baseMap.getView().setCenter(long, lat);
      baseMap
        .getView()
        .fit(vectorSourceFeature.getExtent(), { size: [250, 250] });
    }

    baseMap.setTarget(mapRef.current);
    return () => baseMap.setTarget(undefined);
  }, []);

  return (
    <CFBox>
      <div ref={mapRef} style={{ width: "100%", height: "350px" }} />
    </CFBox>
  );
}
export default WhcMap;
