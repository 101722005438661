/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* React */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Tooltip, Link as ExternalLink } from "@mui/material";
import { Link } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/system";
import { EditTwoTone } from "@mui/icons-material";

/* OpenLayers */
import BingMaps from "ol/source/BingMaps";
import Map from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import { Draw, Modify, Select, Translate } from "ol/interaction";
import { XYZ } from "ol/source";
import { Attribution, defaults as defaultControls } from "ol/control";
import { createBox } from "ol/interaction/Draw";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import "ol/ol.css";

/* Our Components */
import { fetchUser } from "actions/user";
import CFDialog from "components/CFDialog";
import CFTypography from "components/CFTypography";
import CFarmPage from "components/CFarmParts/CFarmPage";
import CFRoadmap from "components/CFarmParts/CFarmPage/CFRoadmap/Roadmap";
import CFButton from "components/CFButton";
import {
  popupError,
  convertGeometry,
  convertCometFeatures,
  getMapStyle,
  // mathRoundRenderer,
} from "utils/generic";
import colors from "assets/theme/base/colors";

import { updateLoading } from "store/reducers/mask";
import { post } from "utils/api";
import { LOCATION_FETCH, UPDATE_LOCATION } from "constants/api";
import { SOILS, FIND, RECTANGLE, EDIT } from "constants/mapButtons";
import ImagerySetButtons from "./OpenlayersDivs/CFMapImageryButtons";
import CFMapPopup from "./CFMapButtonPopups/CFMapPopup";
import CFMapButtons from "./CFMapButtonPopups/CFMapButtons";
import "./CFMap.css";
import CFMapGridFooter from "./CFMapGridFooter";
import ModifyButtons from "./OpenlayersDivs/CFMapModifySaveControl";

const attribution = new Attribution({
  collapsible: false,
});

function LinkButton(props) {
  // eslint-disable-next-line react/prop-types
  const { label, to, sx, ...rest } = props;
  return (
    <CFButton
      {...rest}
      to={to}
      sx={{
        ...{
          fontSize: "1em",
          textTransform: "none",
        },
        ...sx,
      }}
      component={Link}
    >
      {label}
    </CFButton>
  );
}

function CFMap({ activityTypeId }) {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user);
  const [fieldObjectChange, setFieldObjectChange] = useState(false);
  const [mainMap, setMainMap] = useState();
  const mapRef = useRef();
  const [toggleSoil, setToggleSoil] = useState(false);
  const [imagerySet, setImagerySet] = useState("AerialWithLabelsOnDemand");
  const [source, setSource] = useState();
  const [features, setFeatures] = useState([]);
  const parcelWording = activityTypeId === 10 ? "Field" : "Stand";
  const [fieldsLabel, setFieldsLabel] = useState(`${parcelWording}s 0/50`);
  const [redrawMap, setRedrawMap] = useState(false);
  const [redrawBaseMap, setRedrawBaseMap] = useState(false);
  const [showDeleteChckbx, setShowDeleteChckbx] = useState(false);
  const [deleteFeatureList, setDeleteFeatureList] = useState([]);
  const [disableContinue, setDisableContinue] = useState(false);
  const [modifyOpen, setModifyOpen] = useState(false);

  // Use with Imagery Buttons
  const [btnToggleName, setBtnToggleName] = useState(null);
  const drawCtr = useRef(0);
  const feature = useRef();
  const btnValue = useRef("none");

  // Parcel Configurations and popup
  const [popupIsOpen, setPopupIsOpen] = useState(false);
  const [openHelpGuide, setOpenHelpGuide] = useState(false);
  const [parcelNames, setParcelNames] = useState([]);
  const [buttonClickObj, setButtonClickObj] = useState({
    feature: {},
    buttonType: "",
  });
  const [localPopupState, updateLocalPopup] = useState({
    message:
      "The selected project doesn't require field location data. Please select either croplands for forestry in the Choose Activities page to outline field locations",
    title: "Error",
    showPopup: false,
    closable: false,
    closeAction: () => {
      updateLocalPopup({
        showPopup: false,
      });
    },
    button1: {
      text: "Navigate to Choose Activities",
      href: "/projects",
      color: "primary",
    },
  });
  const [locationHolder, setLocationHolder] = useState({
    userId: "",
    projectId: "",
    featureCollection: {},
    activityTypeId: 10,
  });
  /* Redraw the entire map */
  const toggleRedrawBaseMap = () => {
    setRedrawBaseMap(!redrawBaseMap);
  };

  const toggleResetToggle = () => {
    setBtnToggleName(null);
  };

  let mapViewToggle = true;
  const toggleMapView = () => {
    const container = document.getElementById("buttonContainer");
    if (mapViewToggle) {
      container.style.display = "grid";
    } else {
      container.style.display = "none";
    }
    mapViewToggle = !mapViewToggle;
  };
  /* Redraw features on the map */
  const toggleRedrawMap = () => {
    setRedrawMap(!redrawMap);
  };

  const updateParcelNames = () => {
    const tempNamesList = locationHolder.featureCollection.features.map(
      (f) => f.properties.name
    );
    setParcelNames([...tempNamesList]);
    setFieldsLabel(`${parcelWording}s (${tempNamesList.length}/50)`);
  };

  const setLocationHolderFromRes = (res) => {
    try {
      const locationHolderObj = res.data;
      setLocationHolder({
        userId: locationHolderObj.userId,
        projectId: locationHolderObj.projectId,
        featureCollection: locationHolderObj.featureCollection,
        activityTypeId: locationHolderObj.activityTypeId,
      });
    } catch (e) {
      updateLocalPopup({
        message: "Error fetching field locations.  Please try again.",
        title: "Error",
        showPopup: true,
        closeAction: () => {
          updateLocalPopup({
            showPopup: false,
          });
        },
      });
    }
  };

  /* MERCATOR functions */
  /* const fromLatLngToPoint = (latLng) => {
  const siny = Math.min(
    Math.max(Math.sin(latLng.lat * (Math.PI / 180)), -0.9999),
    0.9999
  );
  return {
    x: 128 + latLng.lng * (256 / 360),
    y: 128 + 0.5 * Math.log((1 + siny) / (1 - siny)) * -(256 / (2 * Math.PI)),
  };
};


const getTileAtLatLng = (latLng, zoom) => {
  const t = 2 ** zoom;
  const s = 256 / t;
  const p = this.fromLatLngToPoint(latLng);
  return { x: Math.floor(p.x / s), y: Math.floor(p.y / s), z: zoom };
}; */

  const fromPointToLatLng = (point) => ({
    lat:
      (2 * Math.atan(Math.exp((point.y - 128) / -(256 / (2 * Math.PI)))) -
        Math.PI / 2) /
      (Math.PI / 180),
    lng: (point.x - 128) / (256 / 360),
  });

  // eslint-disable-next-line no-param-reassign
  const normalizeTile = (tile) => {
    const t = 2 ** tile.z;
    const x = ((tile.x % t) + t) % t;
    const y = ((tile.y % t) + t) % t;
    return { ...tile, x, y };
  };

  /* From old COMET-Farm had to switch the y values and make them positive. Might need other changes if we use other MERCATOR methods */
  const getTileBounds = (tile) => {
    const normalTile = normalizeTile(tile);
    const t = 2 ** tile.z;
    const s = 256 / t;
    const sw = {
      x: normalTile.x * s,
      y: normalTile.y * s,
    };
    const ne = {
      x: normalTile.x * s + s,
      y: normalTile.y * s + s,
    };
    return {
      sw: fromPointToLatLng(sw),
      ne: fromPointToLatLng(ne),
    };
  };

  /* END MERCATOR Functions */

  const getSsurgoTileImage = (bounds) => {
    const tileObj = {
      z: bounds[0],
      x: bounds[1],
      y: bounds[2],
    };

    const tileBounds = getTileBounds(tileObj);
    const tileExtent = [
      tileBounds.sw.lng,
      tileBounds.ne.lat,
      tileBounds.ne.lng,
      tileBounds.sw.lat,
    ];

    let url = "https://sdmdataaccess.nrcs.usda.gov/Spatial/SDM.wms";
    url += "?REQUEST=GetMap";
    url += "&SERVICE=WMS";
    url += "&VERSION=1.1.1";
    url += "&LAYERS=MapunitPoly";
    url += "&FORMAT=image/png";
    url += "&TRANSPARENT=true";
    url += "&SRS=EPSG:4326";
    url += `&BBOX=${tileExtent}`; // -96.6796875, 41.77131167976406, -96.50390625,41.90227704096369`;
    url += "&WIDTH=256";
    url += "&HEIGHT=256";
    return url;
  };

  const checkForMissingSoils = () => {
    if (locationHolder?.featureCollection?.features) {
      const missingSoils = [];
      for (
        let i = 0;
        i < locationHolder.featureCollection.features.length;
        i += 1
      ) {
        if (locationHolder.featureCollection.features[i]?.properties) {
          const { name, soilComponentStatus } =
            locationHolder.featureCollection.features[i].properties;

          if (soilComponentStatus !== "success") {
            missingSoils.push({ name, status: soilComponentStatus });
          }
        }
      }
      if (missingSoils.length > 0 && activityTypeId === 10) {
        setDisableContinue(true);
        updateLocalPopup({
          children: (
            <div style={{ padding: "2em" }}>
              The following fields do not have any simulatable soil components.
              We will be unable to generate a report.
              <ul>
                {missingSoils.map((s) => (
                  <li key={s.name}>{s.name}</li>
                ))}
              </ul>
            </div>
          ),
          title: "Error",
          showPopup: true,

          closable: true,
        });
      } else {
        setDisableContinue(false);
      }
    }
  };

  const fetchlocationHolder = () => {
    dispatch(updateLoading({ loading: true }));

    post(LOCATION_FETCH, {
      userId: currentUser.id,
      activityTypeId,
    })
      .then((res) => {
        if (res.error) {
          console.log("Error obj ", res.error);
          updateLocalPopup({
            message: "Error fetching field locations.  Please try again.",
            title: "Error",
            showPopup: true,
            closeAction: () => {
              updateLocalPopup({
                showPopup: false,
              });
            },
          });
          dispatch(updateLoading({ loading: false }));
        } else {
          setLocationHolderFromRes(res);
        }
        dispatch(updateLoading({ loading: false }));
      })
      .catch((err) => {
        updateLocalPopup({
          message:
            "The selected project doesn't require field location data. Please select either croplands for forestry in the Choose Activities page to outline field locations",
          title: "Error",
          showPopup: true,

          closable: false,
          button1: {
            text: "Navigate to Choose Activities",

            href: "/projects",
            color: "primary",
          },
        });
        dispatch(fetchUser(currentUser));
        dispatch(updateLoading({ loading: false }));
      });
  };
  const getBtnValue = () => btnValue.current.buttonType;

  const setBtnValue = ({ buttonType, buttonFeature }) => {
    btnValue.current = { buttonType, buttonFeature };
  };

  const soilOnSelect = (event) => {
    if (getBtnValue() === SOILS) {
      const pixel = event.map.getCoordinateFromPixel(event.pixel);
      setButtonClickObj({ buttonType: SOILS, feature: pixel });
    }
  };

  const showModifyButtons = (toShow) => {
    const modifyButtons = document.getElementById("modifyContainer");
    setModifyOpen(toShow);
    if (toShow) modifyButtons.style.display = "block";
    else modifyButtons.style.display = "none";
  };

  const deleteOldMapInteractions = () => {
    let modifyInt = null;
    let selectInt = null;
    let translateInt = null;
    let drawInt = null;
    if (mainMap) {
      mainMap.getInteractions().forEach((interaction) => {
        if (interaction instanceof Modify) {
          modifyInt = interaction;
        }
        if (interaction instanceof Select) {
          selectInt = interaction;
        }
        if (interaction instanceof Draw) {
          drawInt = interaction;
        }
        if (interaction instanceof Translate) {
          translateInt = interaction;
        }
      });
      if (selectInt) mainMap.removeInteraction(selectInt);
      if (modifyInt) mainMap.removeInteraction(modifyInt);
      if (drawInt) mainMap.removeInteraction(drawInt);
      if (translateInt) mainMap.removeInteraction(translateInt);
      showModifyButtons(false);
      setShowDeleteChckbx(false);
    }
  };
  const tooManyParcelsPopup = () => {
    updateLocalPopup({
      message: `Please delete a ${parcelWording.toLowerCase()} in order to create a new one. `,
      title: `Warning: 50 ${parcelWording} limit reached`,
      showPopup: true,
      closeAction: () => {
        updateLocalPopup({
          showPopup: false,
        });
        setBtnToggleName(null);
      },
    });
  };

  const addShapeInteractions = (shape) => {
    if (parcelNames.length < 50) {
      let geomFunc;
      let type = shape;
      if (shape === RECTANGLE) {
        type = "Circle";
        geomFunc = createBox();
      }
      const draw = new Draw({
        source,
        type,
        geometryFunction: geomFunc,
      });
      mainMap.addInteraction(draw);
      draw.on("drawend", (event) => {
        setButtonClickObj({
          feature: event.feature,
          buttonType: shape.toUpperCase(),
        });
      });
    } else {
      tooManyParcelsPopup();
    }
  };

  const addModifyInteractions = () => {
    showModifyButtons(true);
    const selectInt = new Select({
      source,
    });
    const modifyInt = new Modify({
      features: selectInt.getFeatures(),
    });
    const translateInt = new Translate({
      features: selectInt.getFeatures(),
    });
    mainMap.addInteraction(translateInt);
    mainMap.addInteraction(selectInt);
    mainMap.addInteraction(modifyInt);

    modifyInt.on("modifyend", (event) => {
      /* eslint-disable prefer-destructuring */
      feature.current = event.features.array_[0];
    });
    translateInt.on("translateend", (event) => {
      /* eslint-disable prefer-destructuring */
      feature.current = event.features.array_[0];
    });
  };
  const getFeature = () => feature.current;
  const modifySave = (event) => {
    event.preventDefault();
    const geometry = convertGeometry(getFeature());
    const parcel = feature.current.values_;
    dispatch(updateLoading({ loading: true }));
    post(UPDATE_LOCATION, {
      geometry,
      userId: currentUser.id,
      fieldId: parcel.id,
      activityTypeId,
      fieldName: parcel.name,
      area: parcel.area,
    })
      .then((res) => {
        dispatch(updateLoading({ loading: false }));
        if (res.error) {
          console.log("Error obj ", res.error);
          popupError("Error saving modifications. Please try again.", dispatch);
          dispatch(updateLoading({ loading: false }));
        } else {
          dispatch(fetchUser(currentUser));
          showModifyButtons(false);
          setLocationHolderFromRes(res);
        }
        toggleResetToggle();
      })
      .catch((err) => {
        toggleResetToggle();
        popupError("Error saving modifications. Please try again", dispatch);
        dispatch(updateLoading({ loading: false }));
      });
  };

  const modifyCancel = () => {
    showModifyButtons(false);
    toggleResetToggle();
    toggleRedrawBaseMap();
  };

  const getContinueToolTip = () => {
    if (modifyOpen)
      return "Either save or cancel modifications before continuing.";
    if (disableContinue)
      return `We don't have soil information for one or more of your ${parcelWording.toLowerCase()}s. Please move or delete this ${parcelWording.toLowerCase()}`;
    return "";
  };

  const setMapCopyright = () => {
    const r = document.querySelector(":root");
    r.style.setProperty("--display", "block");
  };

  /* Datagrid data and methods */

  const columns = [
    {
      field: "name",
      headerName: fieldsLabel,
      sortable: true,
      filter: true,
      flex: 1.9,
      valueGetter: ({ row }) => row.values_.name,
    },
    {
      field: "acres",
      headerName: "Acres",
      sortable: false,
      disableColumnMenu: true,
      flex: 1.1,
      valueGetter: ({ row }) => row.values_.acres,
    },
    {
      field: "action",
      headerName: "Edit",
      disableColumnMenu: true,
      sortable: false,
      flex: 0.9,
      renderCell: () => <EditTwoTone />,
    },
  ];
  const onCellClick = (event) => {
    if (event.field === "action") {
      setButtonClickObj({
        buttonType: EDIT,
        feature: event.row,
      });
    }
  };

  const onRowClick = (event) => {
    const featureExtent = event.row.getGeometry().getExtent();
    const geometryType = event.row.getGeometry().getType();
    mainMap.getView().fit(featureExtent);
    if (geometryType === "Point") mainMap.getView().setZoom(17);
    else if (geometryType === "Polygon")
      mainMap.getView().setZoom(mainMap.getView().getZoom() - 1);
  };
  const toggleDeleteChckbx = () => {
    setShowDeleteChckbx(!showDeleteChckbx);
  };

  const onDeleteToggle = (ids) => {
    const selectedIDs = new Set(ids);
    const selectedRowData = features.filter((row) =>
      selectedIDs.has(row.values_.id.toString())
    );
    setDeleteFeatureList(selectedRowData);
  };

  const getDeleteFeatureList = () => deleteFeatureList;

  useEffect(() => {
    // get most recent view of map before reloading
    let viewTemp;
    if (mainMap) {
      viewTemp = mainMap.getView();
    }

    const mapSource = new BingMaps({
      key: "AipFgxAUWr-IAOTpXwf-Kac7KIdHCMD-9is8dpE8fnb690-CZAjGGGQumVN89a-E",
      imagerySet,
      // placeholderTiles: false, // Optional. Prevents showing of BingMaps placeholder tiles
    });
    const featureSource = new VectorSource({ wrapX: false });
    const featureLayer = new VectorLayer({
      source: featureSource,
    });
    setSource(featureSource);
    const soilLayer = new TileLayer({
      title: "Soils Imagery",
      visible: toggleSoil,
      // preload: Infinity,
      source: new XYZ({
        tileUrlFunction: getSsurgoTileImage,
      }),
    });
    const controlsSet = [];
    controlsSet.push(
      new ImagerySetButtons(
        toggleSoil,
        setToggleSoil,
        imagerySet,
        setImagerySet,
        toggleMapView
      )
    );
    controlsSet.push(
      new ModifyButtons(parcelWording, modifySave, modifyCancel)
    );

    const baseMap = new Map({
      layers: [
        new TileLayer({
          visible: true,
          preload: Infinity,
          source: mapSource,
        }),
        featureLayer,
        soilLayer,
      ],
      controls: defaultControls({ attribution: false }).extend(controlsSet),
      view:
        viewTemp ||
        new View({
          center: [-10713413.88445, 4931105.568733],
          zoom: 13,
        }),
    });
    baseMap.setTarget(mapRef.current);
    baseMap.on("click", soilOnSelect);
    /* Get the default react geo digitize layer and set default styles */
    featureLayer.setStyle(getMapStyle());
    setMainMap(baseMap);
    if (drawCtr.current > 0) toggleRedrawMap();
    setMapCopyright();
    // what does this do?
    return () => baseMap.setTarget(undefined);
  }, [imagerySet, toggleSoil, redrawBaseMap]);

  useEffect(async () => {
    if (mainMap && source) {
      /* Removes old and sometimes problematic features */
      source.clear();

      const featureCnt = locationHolder.featureCollection.features.length;

      if (featureCnt > 0) {
        const convertedFeatures = convertCometFeatures(locationHolder);
        source.addFeatures(convertedFeatures);
        setFeatures(convertedFeatures);

        // This is the first time loading the maps page
        if (drawCtr.current === 0) {
          const featureExtent = source.getExtent();
          mainMap.getView().fit(featureExtent, mainMap.getSize());
          if (featureCnt === 1) {
            const geometryType = convertedFeatures[0].getGeometry().getType();
            if (geometryType === "Point") mainMap.getView().setZoom(17);
            else if (geometryType === "Polygon")
              mainMap.getView().setZoom(mainMap.getView().getZoom() - 1);
          }
        }
        // setRows(convertedFeatures);
      } else {
        setFeatures([]);
      }

      // This is the first time loading the maps page
      if (drawCtr.current === 0) {
        // Pulls up the search location and help onload
        if (featureCnt === 0) {
          setOpenHelpGuide(true);
          setButtonClickObj({ buttonType: FIND });
        }
        drawCtr.current += 1;
      }
      updateParcelNames();
    }
  }, [locationHolder, redrawMap]);
  useEffect(() => {
    fetchlocationHolder();
  }, [fieldObjectChange, activityTypeId]);
  useEffect(() => {
    checkForMissingSoils();
  }, [locationHolder]);

  // Demo info popup
  const [demoPopupShown, setDemoPopupShown] = useState(false);
  useEffect(() => {
    if (currentUser?.activeProject?.name.includes("Demo") && !demoPopupShown) {
      setDemoPopupShown(true);
      // Forestry
      if (activityTypeId === 14) {
        updateLocalPopup({
          title: "Forestry Demo Project",
          style: {
            minWidth: "750px",
            maxHeight: "75vh",
            overflow: "auto",
          },
          children: [
            <Grid container direction="column" alignItems="center" p={2}>
              <CFTypography variant="h4" py={1}>
                Demonstration Location
              </CFTypography>
              <p>
                The demo project site is on the University of Idaho Experimental
                Forest system, operated since 1933, and serves to demonstrate
                forest management and provide education and research. In this
                demonstration, we will focus on three hypothetical stands in the
                Big Meadow Unit of the forest. More information can be found
                here:{" "}
                <ExternalLink
                  href="https://www.uidaho.edu/cnr/ui-experimental-forest"
                  target="_blank"
                  underline="hover"
                  rel="noopener noreferrer"
                  sx={{ color: "mediumblue" }}
                >
                  Univ. of Idaho Experimental Forest
                </ExternalLink>
              </p>
            </Grid>,
          ],
          showPopup: true,
          closeAction: () => {
            updateLocalPopup({ showPopup: false });
          },
        });
      }
      // Croplands
      else if (activityTypeId === 10) {
        updateLocalPopup({
          title: "Croplands Demo Project",
          style: {
            minWidth: "750px",
            maxHeight: "75vh",
            overflow: "auto",
          },
          children: [
            <Grid container direction="column" alignItems="center" p={2}>
              <CFTypography variant="h4" py={1}>
                Demonstration Location
              </CFTypography>
              <p>
                The site is the Allee Farm, operated by Iowa State University
                since 1958 for whole-farm agricultural demonstrations focused on
                modest-sized, sustainable, family-based farming. A description
                of the site can be seen here:{" "}
                <ExternalLink
                  href="https://farms.cals.iastate.edu/project/allee-demonstration-farm"
                  target="_blank"
                  underline="hover"
                  rel="noopener noreferrer"
                  sx={{ color: "mediumblue" }}
                >
                  Allee Demonstration Farm
                </ExternalLink>
                .
              </p>
            </Grid>,
          ],
          showPopup: true,
          closeAction: () => {
            updateLocalPopup({ showPopup: false });
          },
        });
      }
    }
  }, [currentUser.activeProject]);

  return (
    <CFarmPage bgImage="" pageName="">
      <CFRoadmap />
      <CFMapPopup
        setIsOpen={setPopupIsOpen}
        isOpen={popupIsOpen}
        deleteOldMapInteractions={deleteOldMapInteractions}
        toggleResetToggle={toggleResetToggle}
        toggleDeleteChckbx={toggleDeleteChckbx}
        parcelWording={parcelWording}
        parcelNames={parcelNames}
        currentUser={currentUser}
        updateLocations={setLocationHolderFromRes}
        buttonClickObj={buttonClickObj}
        setButtonClickObj={setButtonClickObj}
        activityTypeId={activityTypeId}
        redrawMap={toggleRedrawMap}
        mainMap={mainMap}
      />
      <Grid
        container
        direction="row"
        style={{
          paddingBottom: "1em",
        }}
      >
        <Grid
          item
          xs={9}
          sm={9}
          md={9}
          lg={9}
          xl={9.5}
          sx={{
            borderRadius: "25px",
          }}
        >
          <CFMapButtons
            btnToggleName={btnToggleName}
            setBtnToggleName={setBtnToggleName}
            setBtnValue={setBtnValue}
            height="3em"
            addModifyInteractions={addModifyInteractions}
            addShapeInteractions={addShapeInteractions}
            deleteOldMapInteractions={deleteOldMapInteractions}
            toggleDeleteChckbx={toggleDeleteChckbx}
            updateLocalPopup={updateLocalPopup}
            parcelWording={parcelWording}
            setButtonClickObj={setButtonClickObj}
            parcelNames={parcelNames}
            modifyOpen={modifyOpen}
            showHelpModal={openHelpGuide}
          />
          <div ref={mapRef} className="comet-map" />
        </Grid>
        <Grid
          item
          xs={3}
          sm={3}
          md={3}
          lg={3}
          xl={2.5}
          style={{
            height: "33em",
            paddingLeft: "1em",
          }}
        >
          <Box style={{ height: "28em" }}>
            {/* eslint no-underscore-dangle: 0 */}
            <DataGrid
              columns={columns}
              getRowId={(row) => row.values_.id}
              rows={features}
              editable
              onCellClick={onCellClick}
              onRowSelectionModelChange={onDeleteToggle}
              maxColumns={3}
              onRowClick={onRowClick}
              hideFooterSelectedRowCount
              slots={{
                footer: CFMapGridFooter,
              }}
              slotProps={{
                footer: {
                  showButtons: showDeleteChckbx,
                  toggleDeleteButtons: toggleDeleteChckbx,
                  toggleResetToggle,
                  setButtonClickObj,
                  getDeleteFeatureList,
                  fieldLocationNumber: parcelNames.length,
                },
              }}
              pageSizeOptions={[5]}
              checkboxSelection={showDeleteChckbx}
            />
          </Box>
          <Tooltip title={getContinueToolTip()}>
            <div>
              <LinkButton
                to={activityTypeId === 10 ? "/croplands" : "/forestry"}
                disabled={disableContinue || modifyOpen}
                label={`Finished Defining ${parcelWording}s`}
                sx={{
                  marginTop: ".3em",
                  fontSize: "1em",
                  height: "4.7em",
                  width: "100%",
                  background: `linear-gradient(195deg, ${colors.progress.main}, ${colors.gradients.progress.state})`,
                }}
              />
            </div>
          </Tooltip>
        </Grid>
      </Grid>

      {localPopupState && localPopupState.showPopup && (
        <CFDialog
          {...localPopupState}
          updatePopup={(e) => updateLocalPopup(e)}
        />
      )}
    </CFarmPage>
  );
}
export default CFMap;
