/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { popupError } from "utils/generic";
import { post } from "utils/api";
import { CREATE_NEW_SCENARIO } from "constants/api";
import CFButton from "components/CFButton";
import CFBox from "components/CFBox";
import CFInput from "components/CFInput";
import CFManagementSelectionCard from "components/CroplandsComponents/CFManagementSelectionCard/CFManagementSelectionCard";
import CFLookupPicker from "components/CroplandsComponents/CFLookupPicker/CFLookupPicker";
import { fetchUser } from "actions/user";

const emptyOptional = {
  name: "(optional)",
  id: 0,
};

function NewScenarioComponent({
  copyFromScenarios,
  fetchProjectInformation,
  setLoading,
  returnHook,
  selectNewScenario,
}) {
  const dispatch = useDispatch();

  const currentUser = useSelector((state) => state.user);

  const [createEnabled, setCreateEnabled] = useState(false);
  const [newScenarioName, setNewScenarioName] = useState("");

  const [copyFrom, setcopyFrom] = useState(emptyOptional);

  const addNewScenario = () => {
    if (newScenarioName !== "") {
      setLoading("Creating new scenario");
      post(CREATE_NEW_SCENARIO, {
        user: currentUser,
        scenarioName: newScenarioName,
        copyScenarioId: copyFrom.id, // note: selectedScenario.id is the crop rotation ID, not the scenario ID
      })
        .then((res) => {
          if (res.error) {
            popupError(`Create New Scenario Error ${res.error}`, dispatch);
          } else {
            dispatch(fetchUser(currentUser));
            selectNewScenario(newScenarioName);
            fetchProjectInformation();
            // need to select the new scenario
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setcopyFrom(emptyOptional);
          setNewScenarioName("");
          if (returnHook) {
            returnHook();
          }
          setLoading("");
        });
    }
  };

  const checkScenarioName = (newName) => {
    // eslint-disable-next-line react/destructuring-assignment
    const scenarioNames = copyFromScenarios.map((x) => x.name);

    if (!newName || newName === "" || scenarioNames.indexOf(newName) > -1) {
      setNewScenarioName(newName);
      setCreateEnabled(false);
    } else {
      setCreateEnabled(true);
      setNewScenarioName(newName);
    }
  };

  return (
    <CFBox sx={{ margin: "1em" }}>
      <CFManagementSelectionCard
        label="Scenario Name"
        labelWidth={6}
        input={
          <CFInput
            label="new name"
            value={newScenarioName}
            onChange={(em) => checkScenarioName(em.target.value, copyFrom)}
            sx={{
              width: "100%",
              margin: "1em",
              paddingLeft: "1em",
              paddingRight: "1em",
            }}
          />
        }
      />

      <CFManagementSelectionCard
        label="Copy from existing scenario"
        labelWidth={8}
        input={
          <CFLookupPicker
            label="Select a scenario"
            required
            sx={{
              width: "100%",
              paddingLeft: "1em",
              paddingRight: "1em",
              margin: "1em",
            }}
            value={copyFrom}
            onChange={(event, newValue) => {
              setcopyFrom(newValue);
            }}
            options={copyFromScenarios}
          />
        }
      />

      <CFButton
        disabled={!createEnabled}
        color="progress"
        variant="gradient"
        sx={{ padding: "1em", margin: "1em", float: "right" }}
        onClick={() => addNewScenario()}
      >
        Create New Scenario
      </CFButton>
    </CFBox>
  );
}
export default NewScenarioComponent;
