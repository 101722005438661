import { useEffect } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
// react-router components
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

//  React routes
import routesWithComponents from "routes/routesWithComponents";
//  React themes
import theme from "./assets/theme";
import HomePage from "./pages/Static/Home";
import "./styles/global.css";

export default function App() {
  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (setOfRoutes) =>
    setOfRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }
      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={route.component}
            key={route.key ? route.key : route.route}
          />
        );
      }

      return null;
    });

  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPCHA_KEY}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          {" "}
          {getRoutes(routesWithComponents)}
          <Route path="/home" element={<HomePage />} />
          <Route path="*" element={<Navigate to="/home" />} />
        </Routes>
      </ThemeProvider>
    </GoogleReCaptchaProvider>
  );
}
