// React
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

// MUI
import {
  Grid,
  Dialog,
  Divider,
  IconButton,
  Tooltip,
  Checkbox,
  ListItem,
  Link,
  Radio,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Close } from "@mui/icons-material";
import { pink, red, grey } from "@mui/material/colors";
import dayjs from "dayjs";

import CPA345 from "assets/cFarmPDFs/CPA/345.pdf";
import colors from "assets/theme/base/colors";

// Our Components
import CFBox from "components/CFBox";
import CFTypography from "components/CFTypography";
import CFButton from "components/CFButton";
import CFStep from "components/CFarmParts/CFStep";
import CPA329 from "assets/cFarmPDFs/CPA/329.pdf";
import CPA340 from "assets/cFarmPDFs/CPA/340.pdf";
import CPA590 from "assets/cFarmPDFs/CPA/590.pdf";

import CPA332 from "assets/cFarmPDFs/CPA/332.pdf";

import CPA386 from "assets/cFarmPDFs/CPA/386.pdf";

import CPA390 from "assets/cFarmPDFs/CPA/390.pdf";

import CPA393 from "assets/cFarmPDFs/CPA/393.pdf";
import CPA412 from "assets/cFarmPDFs/CPA/412.pdf";
import CPAHelp from "assets/cFarmImages/HelpImages/cpahelp.webp";
import { preloadImage, popupError } from "utils/generic";
import CPA601 from "assets/cFarmPDFs/CPA/601.pdf";
import CPA327 from "assets/cFarmPDFs/CPA/327.pdf";

import { post } from "utils/api";
import { SAVE_CPA } from "constants/api";
import CFLoading from "components/CFLoading";
import CFLookupPicker from "components/CroplandsComponents/CFLookupPicker/CFLookupPicker";
import { fetchUser } from "actions/user";
import CPAHelperText, { StripsandBorders } from "./CPA_helper_text";

export default function CPACard({
  isOpen,
  setIsOpen,
  baselineManagement,
  locationName,
  scenarioName,
  coverCrops,
  coverCropsAndFertReduction,
  currentUser,
  fieldId,
  cropRotationId,
  fetchManagement,
}) {
  const dispatch = useDispatch();
  const handleClose = () => {
    setIsOpen(false);
  };
  preloadImage(CPAHelp);
  const [loadingMessage, setLoading] = useState("");
  const [selectedValue, setselectedValue] = useState("");
  const [additionalValue, setadditionalValue] = useState({});

  const [coverValue, setcoverValue] = useState({});
  const [covarAndReductionValue, setcovarAndReductionValue] = useState({});

  const handleChange = (val) => {
    setselectedValue({ id: val.target.value });
  };

  const handleChangeSelector = (val) => {
    setadditionalValue(val);
  };

  const SaveAndClose = () => {
    setLoading("Saving...");
    let cpa = selectedValue.id;
    let cover = coverValue.id ? coverValue.id : 0;
    if (selectedValue.id === "STRIPS") {
      cpa = "STRIPS";
      cover = additionalValue.secondId;
    }

    if (cpa === "CPA590") {
      cover = covarAndReductionValue.id;
    }
    if (fieldId === 0) {
      return;
    }
    post(SAVE_CPA, {
      user: currentUser,
      fieldid: fieldId,
      cropRotationId,
      consvtilloption: cpa,
      covercrop: cover,
      scenarioName,
    })
      .then((res) => {
        if (res.error) {
          popupError(`Save CPA Error ${res.error}`, dispatch);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading("");
        dispatch(fetchUser(currentUser));
        fetchManagement();
        handleClose();
      });
  };

  return (
    <Dialog
      open={isOpen}
      fullScreen
      PaperProps={{
        style: { borderRadius: ".3em" },
      }}
      sx={{ padding: "3em", zIndex: 5 }}
      onClose={handleClose}
    >
      {loadingMessage !== "" && <CFLoading message={loadingMessage} local />}{" "}
      <CFBox
        sx={{
          margin: "2em",
          position: "absolute",
          right: "2em",
        }}
      />
      <IconButton
        onClick={() => handleClose()}
        style={{ position: "absolute", right: 0, margin: ".5em", padding: 0 }}
      >
        <Tooltip title="Close">
          <Close />
        </Tooltip>
      </IconButton>
      <Grid container item xs={12}>
        <CFBox sx={{ padding: "2em" }}>
          <Grid container direction="row">
            {/* Window Header */}
            <Grid
              container
              item
              xs={12}
              sx={{ paddingLeft: "1em", paddingTop: "1em" }}
            />
            <CFStep
              stepLabel={`Apply a Conservation Practice to ${scenarioName} on ${locationName}`}
              helpText={<img src={CPAHelp} width="100%" alt="CPA Help" />}
              useGuideWidth="1450px"
            />

            <Grid
              container
              item
              xs={12}
              direction="column"
              sx={{ paddingLeft: "1em", paddingTop: "1em" }}
            >
              <CFTypography fontWeight="medium">
                CONSERVATION TILLAGE
              </CFTypography>
              <div>
                <Radio
                  checked={selectedValue?.id === "CPA345"}
                  onChange={handleChange}
                  value="CPA345"
                  name="radio-buttons"
                  inputProps={{ "area-label": "Convert to reduced tillage" }}
                />
                Convert to reduced tillage{" "}
                <Link target="_blank" href={CPA345}>
                  (CPS 345)
                </Link>
              </div>
              <div>
                <Radio
                  checked={selectedValue?.id === "CPA329"}
                  onChange={handleChange}
                  value="CPA329"
                  name="radio-buttons"
                  inputProps={{ "area-label": " Convert to no tillage" }}
                />
                Convert to no tillage{" "}
                <Link target="_blank" href={CPA329}>
                  (CPS 329)
                </Link>
              </div>
              <Divider />
              <div>
                <CFTypography fontWeight="medium">
                  COVER CROP AND FERTILIZER REDUCTION
                </CFTypography>
                <Radio
                  checked={selectedValue?.id === "CPA590"}
                  onChange={handleChange}
                  value="CPA590"
                  name="radio-buttons"
                  inputProps={{
                    "area-label":
                      "ADD COVER CROPS AND CORRESPONDINGLY ADJUST FERTILIZER APPLICATION RATES",
                  }}
                />
                Add cover crop{" "}
                <Link target="_blank" href={CPA340}>
                  (CPS 340)
                </Link>{" "}
                and correspondingly adjust fertilizer application rates{" "}
                <Link target="_blank" href={CPA590}>
                  (CPS 590)
                </Link>
              </div>
              <div>
                <span>
                  (Does not apply to scenarios where fallow years or cover crops
                  already exist)
                </span>
                <div />
                {selectedValue?.id === "CPA590" && (
                  <CFLookupPicker
                    sx={{ width: "100%" }}
                    value={covarAndReductionValue}
                    onChange={(event, newValue) => {
                      setcovarAndReductionValue(newValue);
                    }}
                    options={coverCropsAndFertReduction}
                  />
                )}
                {selectedValue?.id !== "CPA590" && (
                  <CFBox
                    sx={{
                      width: "100%",
                      height: "2.15em",
                    }}
                  />
                )}
              </div>
              <Divider />
              <div>
                <CFTypography fontWeight="medium">COVER CROP</CFTypography>
                <Radio
                  checked={selectedValue?.id === "CPA340"}
                  onChange={handleChange}
                  value="CPA340"
                  name="radio-buttons"
                  inputProps={{ "area-label": " ADD COVER CROPS" }}
                />
                Add cover crop{" "}
                <Link target="_blank" href={CPA340}>
                  (CPS 340)
                </Link>
              </div>
              <div>
                <span>
                  (Does not apply to scenarios where fallow years or cover crops
                  already exist)
                </span>
                <div />
                {selectedValue?.id === "CPA340" && (
                  <CFLookupPicker
                    sx={{ width: "100%" }}
                    value={coverValue}
                    onChange={(event, newValue) => {
                      setcoverValue(newValue);
                    }}
                    options={coverCrops}
                  />
                )}
                {selectedValue?.id !== "CPA340" && (
                  <CFBox
                    sx={{
                      width: "100%",
                      height: "2.15em",
                    }}
                  />
                )}
              </div>
              <Divider />
              <div>
                <CFTypography fontWeight="medium">FILTER STRIP</CFTypography>
                <Radio
                  checked={selectedValue?.id === "STRIPS"}
                  onChange={handleChange}
                  value="STRIPS"
                  name="radio-buttons"
                  inputProps={{ "area-label": " Strips &amp; Borders" }}
                />
                Strips &amp; Borders{" "}
              </div>
              {selectedValue?.id === "STRIPS" && (
                <div>
                  <CFLookupPicker
                    sx={{ width: "100%" }}
                    value={additionalValue}
                    onChange={(event, newValue) => {
                      handleChangeSelector(newValue);
                    }}
                    options={StripsandBorders}
                  />
                </div>
              )}
              {selectedValue?.id !== "STRIPS" && (
                <CFBox
                  sx={{
                    width: "100%",
                    height: "2.15em",
                  }}
                />
              )}
              <Divider />
              <CFTypography fontWeight="medium">
                CONSERVATION COVER{" "}
                <Link target="_blank" href={CPA327}>
                  (CPS 327)
                </Link>
              </CFTypography>{" "}
              <div>
                <Radio
                  checked={selectedValue?.id === "CPA327_1"}
                  onChange={handleChange}
                  value="CPA327_1"
                  name="radio-buttons"
                  inputProps={{
                    "area-label":
                      "Convert Cropland to Permanent Unfertilized Grass Cover",
                  }}
                />
                Convert Cropland to Permanent Unfertilized Grass Cover
              </div>
              <div>
                <Radio
                  checked={selectedValue?.id === "CPA327_2"}
                  onChange={handleChange}
                  value="CPA327_2"
                  name="radio-buttons"
                  inputProps={{
                    "area-label":
                      "Convert Cropland to Permanent Unfertilized Grass/Legume Cover",
                  }}
                />
                Convert Cropland to Permanent Unfertilized Grass/Legume Cover{" "}
              </div>{" "}
              <CFBox
                sx={{
                  width: "100%",
                  height: "2.15em",
                }}
              />
            </Grid>
          </Grid>
          <Grid container py={2} px={2} justifyContent="space-between">
            <CFButton color="secondary" onClick={handleClose}>
              Cancel
            </CFButton>

            <CFButton color="progress" onClick={SaveAndClose}>
              Save and Copy
            </CFButton>
          </Grid>
        </CFBox>
      </Grid>
    </Dialog>
  );
}
