// MUI
import { BarChart } from "@mui/x-charts/BarChart";
import { axisClasses } from "@mui/x-charts";
import { Grid, Select, MenuItem } from "@mui/material";

// React
import { useState, useEffect } from "react";

// CFarm Components
import colors from "assets/theme/base/colors";
import CFTypography from "components/CFTypography";
import CFBox from "components/CFBox";

function AnimalAgProjectGraph({ animalAgData, displayedScenarios }) {
  // Data display selection
  const [selectedData, setSelectedData] = useState(-1);
  const [dataset, setDataset] = useState([]);
  const [dataSeries, setDataSeries] = useState([]);

  // Chart display settings
  const chartSetting = {
    tooltip: { trigger: "item" },
    xAxis: [
      {
        scaleType: "band",
        dataKey: "scenario",
        label: "Scenario",
        labelStyle: { fontSize: 16, fontWeight: "bold" },
        tickLabelStyle: { fontSize: 16 },
      },
    ],
    yAxis: [
      {
        label: "Emissions (metric tonnes CO\u2082 - eq per year)",
      },
    ],
    legend: {
      direction: "row",
      position: {
        vertical: "bottom",
        horizontal: "middle",
      },
      // padding: -20,
    },
    margin: { top: 50, right: 75, left: 100, bottom: 100 },
    sx: {
      [`.${axisClasses.left} .${axisClasses.label}`]: {
        transform: "translate(-33px, 0px)",
        fontWeight: "bold",
      },
      [`.${axisClasses.bottom} .${axisClasses.label}`]: {
        transform: "translate(0px, 5px)",
      },
    },
  };

  // Format chart values and stack labels
  const valueFormatter = (value) => {
    const roundedVal = Math.round((value + Number.EPSILON) * 10) / 10;
    return `${roundedVal.toLocaleString("en-US")} (tonnes CO\u2082 equiv.)`;
  };
  function labelFormatter(value, total) {
    const roundedTotal = Math.round((total + Number.EPSILON) * 10) / 10;
    return `${value} (Total: ${roundedTotal.toLocaleString("en-US")})`;
  }

  // Generate dataset for chart (dataset and series)
  function GenerateDataset(animalGroup) {
    const tempData = [];
    const tempSeries = [];

    // Data set for baseline scenario
    const baseline = {
      totalMethane: null,
      totalNitrousOxide: null,
      scenario: "",
    };
    animalGroup.baselineScenario.emissionsCategories[0].emissionsSubcategories.forEach(
      (subcategory) => {
        switch (subcategory.name) {
          case "Methane":
            baseline.totalMethane = subcategory.value;
            break;
          case "Nitrous oxide":
            baseline.totalNitrousOxide = subcategory.value;
            break;
          default:
            break;
        }
      }
    );
    baseline.scenario = labelFormatter(
      "Baseline",
      baseline.totalMethane + baseline.totalNitrousOxide
    );
    tempData.push(baseline);

    // Map through scenario list, data object for each scenario
    animalGroup.scenarios.forEach((scenario) => {
      if (displayedScenarios.includes(scenario.id)) {
        const scenarioObj = {
          totalMethane: null,
          totalNitrousOxide: null,
          scenario: "",
        };

        scenario.emissionsCategories[0].emissionsSubcategories.forEach(
          (subcategory) => {
            switch (subcategory.name) {
              case "Methane":
                scenarioObj.totalMethane = subcategory.value;
                break;
              case "Nitrous oxide":
                scenarioObj.totalNitrousOxide = subcategory.value;
                break;
              default:
                break;
            }
            scenarioObj.scenario = labelFormatter(
              scenario.name,
              scenarioObj.totalMethane + scenarioObj.totalNitrousOxide
            );
          }
        );

        tempData.push(scenarioObj);
      }
    });

    // One series for each emission category
    const totalMethane = {
      dataKey: "totalMethane",
      stack: "Methane",
      label: "Methane",
      color: colors.graphColors.animalAg.methane,
    };
    const totalNitrousOxide = {
      dataKey: "totalNitrousOxide",
      stack: "Nitrous Oxide",
      label: "Nitrous Oxide",
      color: colors.graphColors.animalAg.nitrousOxide,
    };
    tempSeries.push(totalMethane);
    tempSeries.push(totalNitrousOxide);

    return [tempData, tempSeries];
  }
  useEffect(() => {
    // Update display when data or selection changes
    if (selectedData !== -1) {
      animalAgData.forEach((animalGroup) => {
        if (animalGroup.id === selectedData) {
          const chartData = GenerateDataset(animalGroup);

          setDataset(chartData[0]);
          setDataSeries(chartData[1]);
        }
      });
    }
  }, [animalAgData, displayedScenarios]);

  // User selection
  const SelectionChange = async (e) => {
    setSelectedData(e.target.value);

    // User makes selection, locate corresponding animal group
    if (e.target.value !== -1) {
      animalAgData.forEach((animalGroup) => {
        if (animalGroup.id === e.target.value) {
          const chartData = GenerateDataset(animalGroup);

          setDataset(chartData[0]);
          setDataSeries(chartData[1]);
        }
      });
    }
  };

  return (
    <Grid container direction="column" py={2}>
      {/* Scenario Selection */}
      <Grid item container direction="row" px={2} pb={2} xs={2}>
        <CFTypography variant="h6" fontWeight="regular" pr={1}>
          {"Select Animal Group: "}
        </CFTypography>
        {/* Display select */}
        <Select autoWidth value={selectedData} onChange={SelectionChange}>
          {/* Default value */}
          <MenuItem disabled value={-1}>
            Options...
          </MenuItem>
          {/* Available scenarios */}
          {animalAgData.map((animalGroup) => (
            <MenuItem key={animalGroup.id} value={animalGroup.id}>
              {animalGroup.name}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      {/* Chart Title */}
      <Grid item container direction="column" alignItems="center" xs={1}>
        <CFTypography variant="h5">
          {selectedData === -1
            ? "Total GHG Emissions (metric tonnes CO\u2082 - eq per year)"
            : animalAgData.map((group) => {
                if (group.id === selectedData) {
                  return `Total GHG Emissions (metric tonnes CO\u2082 - eq per year): ${group.name}`;
                }
                return null;
              })}
        </CFTypography>
      </Grid>
      {/* Chart */}
      <Grid item container direction="column" alignItems="center" xs={10}>
        <CFBox sx={{ height: 650, width: "100%" }}>
          <BarChart
            dataset={dataset}
            series={dataSeries.map((series) => ({
              ...series,
              highlightScope: {
                highlighted: "series",
                faded: "global",
              },
              valueFormatter,
            }))}
            grid={{ horizontal: true }}
            {...chartSetting}
          />
        </CFBox>
      </Grid>
    </Grid>
  );
}
export default AnimalAgProjectGraph;
