/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {
  Link as LinkBase,
  Checkbox,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import CFarmPage from "components/CFarmParts/CFarmPage";
import CFInput from "components/CFInput";
import CFButton from "components/CFButton";
import CFTypography from "components/CFTypography";
import colors from "assets/theme/base/colors";
import { verifyPassword } from "utils/auth";
import Checked from "assets/icons/Checked.png";
import Unchecked from "assets/icons/Unchecked.png";

import CFBox from "components/CFBox";
import { REGISTER, CONTINUE_WITHOUT_REGISTERING } from "constants/api";
import CFDialog from "components/CFDialog";
import { updateLoading } from "store/reducers/mask";
import { popupError } from "utils/generic";
import { Button } from "@mui/base";
import { submitDemoLogin } from "actions/auth";

const sectorOptions = [
  "Federal Government Agency",
  "State/Local Agency",
  "Conservation District",
  "Research Center/Laboratory",
  "NGO",
  "Education",
  "Agricultural Producer",
  "Agricultural Supplier",
  "Consulting",
  "Consumer Products",
  "Data Analytics",
  "Private Business",
  "General Public",
  "Other",
  "Prefer Not to Answer",
];

function Registration() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { info } = colors;
  const [passwordValidationError, setPasswordValidationError] = useState(false);
  const [passwordValidationErrorMessage, setPasswordValidationErrorMessage] =
    useState("");

  const [passwordMismatchError, setPasswordMismatchError] = useState(false);
  const [passwordMismatchErrorMessage, setPasswordMismatchErrorMessage] =
    useState("");
  const [token, setToken] = useState("");

  const [localPopupState, updateLocalPopup] = useState({});

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }

    setToken(await executeRecaptcha("REGISTER"));

    // Do whatever you want with the token
  }, [executeRecaptcha]);

  const handleSubmit = (e) => {
    dispatch(updateLoading({ loading: true }));

    e.preventDefault();
    // send the username and email to the backend register for newsletter function which will handle it
    const vpas = verifyPassword(e.target.registrationPassword.value);
    let valid = true;
    if (vpas) {
      setPasswordValidationError(true);
      setPasswordValidationErrorMessage(vpas);
      valid = false;
    } else {
      setPasswordValidationError(false);
      setPasswordValidationErrorMessage("");
    }
    if (
      e.target.registrationPassword.value !==
      e.target.confirmRegistrationPassword.value
    ) {
      setPasswordMismatchError(true);
      setPasswordMismatchErrorMessage("Passwords do not match.");
      valid = false;
    } else {
      setPasswordMismatchError(false);
      setPasswordMismatchErrorMessage("");
    }

    if (valid) {
      handleReCaptchaVerify().then(() => {
        fetch(REGISTER, {
          method: "POST",
          body: JSON.stringify({
            firstName: e.target.firstNameInput.value,
            lastName: e.target.lastNameInput.value,

            email: e.target.emailInput.value,
            password: e.target.registrationPassword.value,
            confirmPassword: e.target.confirmRegistrationPassword.value,

            companyName: e.target.optCompanyName.value,

            sectorName: e.target.optSectorName.value,
            subscribeNewsletter: e.target.subscribeNewsletter.checked,

            ReCapchaToken: token,
          }),

          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.error) {
              popupError(data.error, dispatch);
            }
            if (data.data) {
              updateLocalPopup({
                message: data.data,
                title: "Registration Submitted",
                showPopup: true,
                closeAction: () => {
                  navigate("/home");
                },
              });
            }
          })
          .catch((err) => {
            popupError(err, dispatch);
            console.log(err);
          })
          .finally(() => {
            console.log("infinally");
            dispatch(updateLoading({ loading: false }));
          });
      });
    } else {
      console.log("inelse");
      dispatch(updateLoading({ loading: false }));
    }
  };

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  const handleContinueUnregistered = () => {
    dispatch(updateLoading({ loading: true }));

    handleReCaptchaVerify().then(() => {
      fetch(CONTINUE_WITHOUT_REGISTERING, {
        method: "POST",
        body: JSON.stringify({
          ReCapchaToken: token,
        }),

        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.error) {
            popupError(res.error, dispatch);
          } else {
            // We get back a new demo username, use that to login
            const data = { userName: res.data.userName };
            dispatch(submitDemoLogin(data));
          }
        })
        .catch((err) => {
          popupError(err, dispatch);
          console.log(err);
        })
        .finally(() => {
          dispatch(updateLoading({ loading: false }));
        });
    });
  };

  return (
    // <CFarmPage bgImage="ag_20" pageName="">
    <CFarmPage>
      <CFBox
        component="form"
        method="post"
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <Grid
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          container
          component="main"
          style={{ marginTop: "10%", marginBottom: "10%" }}
        >
          <Grid
            container
            flexDirection="column"
            alignItems="center"
            item
            xs={8}
            sm={8}
            md={8}
            component={Paper}
            style={{
              boxShadow: "var(--cf-shadow-1)",
              padding: ".5em",
              backgroundColor: colors.white.main,
            }}
          >
            {/* Welcome label */}
            <Grid item xs={6} sm={6} md={6}>
              <CFTypography sx={{ fontSize: "36px" }} fontWeight="bold">
                Welcome
              </CFTypography>
            </Grid>
            {/* Register label */}
            <Grid item pb={3} xs={6} sm={6} md={6}>
              <CFTypography sx={{ fontSize: "24px" }}>
                Register for COMET-Farm Account
              </CFTypography>
            </Grid>
            {/* User info */}
            <Grid
              container
              px={10}
              xs={12}
              sm={12}
              md={12}
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              item
            >
              {/* First name */}
              <Grid item pr={2} pb={1} xs={6} sm={6} md={6}>
                <CFInput
                  required
                  sx={{
                    fieldset: {
                      borderColor: info.dullFocus,
                      borderStyle: "solid",
                      borderWidth: "2px",
                    },
                  }}
                  color="primary"
                  key="firstNameInput"
                  name="firstNameInput"
                  label="First Name (Required)"
                  placeholder="First Name"
                  fullWidth
                />
              </Grid>
              {/* Last name */}
              <Grid item pb={1} xs={6} sm={6} md={6}>
                <CFInput
                  required
                  sx={{
                    fieldset: {
                      borderColor: info.dullFocus,
                      borderStyle: "solid",
                      borderWidth: "2px",
                    },
                  }}
                  color="primary"
                  key="lastNameInput"
                  name="lastNameInput"
                  label="Last Name (Required)"
                  placeholder="Last Name"
                  fullWidth
                />
              </Grid>
              {/* Email */}
              <Grid item pb={1} xs={12} sm={12} md={12}>
                <CFInput
                  required
                  sx={{
                    fieldset: {
                      borderColor: info.dullFocus,
                      borderStyle: "solid",
                      borderWidth: "2px",
                    },
                  }}
                  color="primary"
                  key="emailInput"
                  name="emailInput"
                  label="Email (Required)"
                  placeholder="Email"
                  type="email"
                  fullWidth
                />
              </Grid>
              {/* Password */}
              <Grid item pb={1} xs={12} sm={12} md={12}>
                <CFInput
                  required
                  sx={{
                    fieldset: {
                      borderColor: passwordValidationError
                        ? "red"
                        : info.dullFocus,
                      borderStyle: "solid",
                      borderWidth: "2px",
                    },
                  }}
                  key="registrationPassword"
                  name="registrationPassword"
                  placeholder="Password"
                  type="password"
                  error={passwordValidationError}
                  label="Password"
                  helperText={passwordValidationErrorMessage}
                  fullWidth
                />
              </Grid>
              {/* Confirm password */}
              <Grid item pb={1} xs={12} sm={12} md={12}>
                <CFInput
                  required
                  sx={{
                    fieldset: {
                      borderColor: passwordMismatchError
                        ? "red"
                        : info.dullFocus,
                      borderStyle: "solid",
                      borderWidth: "2px",
                    },
                  }}
                  error={passwordMismatchError}
                  helperText={passwordMismatchErrorMessage}
                  key="confirmRegistrationPassword"
                  name="confirmRegistrationPassword"
                  placeholder="Confirm Password"
                  type="password"
                  label="Confirm Password"
                  fullWidth
                />
              </Grid>
              {/* Sector name */}
              <Grid item pb={1} xs={6} sm={6} md={6}>
                <FormControl fullWidth>
                  <InputLabel id="sector-select-label">Sector</InputLabel>
                  <Select
                    key="sectorName"
                    name="optSectorName"
                    required
                    labelId="sector-select-label"
                    label="Sector"
                    defaultValue=""
                    sx={{
                      height: "3em",
                      backgroundColor: "#ffffff",
                    }}
                  >
                    {sectorOptions.map((option) => (
                      <MenuItem
                        key={`sector_menu_option_${option}`}
                        value={option}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {/* Company name (optional) */}
              <Grid item pl={2} pb={1} xs={6} sm={6} md={6}>
                <CFInput
                  sx={{
                    fieldset: {
                      borderColor: info.dullFocus,
                      borderStyle: "solid",
                      borderWidth: "2px",
                    },
                  }}
                  key="companyName"
                  name="optCompanyName"
                  placeholder="Company Name"
                  color="primary"
                  label="Company /Organization Name (Optional)"
                  fullWidth
                />
              </Grid>
              {/* Terms and Conditions, Subscribe to Newsletter */}
              <Grid container direction="column" alignItems="start">
                {/* Terms and Conditions */}
                <Grid py={2}>
                  <FormControl fullWidth>
                    <FormControlLabel
                      required
                      control={
                        <Checkbox
                          icon={<img src={Unchecked} alt="" />}
                          checkedIcon={<img src={Checked} alt="" />}
                          key="agreeTermsConditions"
                          name="agreeTermsConditions"
                          style={{
                            fontFamily: "sans-serif",
                            transform: "scale(.35)",
                          }}
                        />
                      }
                      label={
                        <CFTypography
                          sx={{
                            fontSize: 18,
                            color: info.dullFocus,
                          }}
                        >
                          I have read and agree to the{" "}
                          <Button
                            onClick={() => {
                              updateLocalPopup({
                                title: "COMET-Farm Terms and Conditions",
                                children: (
                                  <Grid container direction="column" p={2}>
                                    <p>
                                      The methodologies supporting COMET-Farm
                                      are not intended as an accounting
                                      framework for emission reduction crediting
                                      or trading. Neither COMET-Farm nor the
                                      provided results constitute an offset
                                      protocol or a full lifecycle GHG analysis.
                                    </p>
                                    <br />
                                    <p>
                                      Users agree not to use the software or
                                      tools provided on this website for the
                                      purpose of engaging in an offset protocol
                                      or full lifecycle GHG analysis. Any user
                                      found in violation of this policy,
                                      including but not limited to using the
                                      software tool for purposes related to an
                                      offset protocol, may receive a warning.
                                      Continued violation of the policy may
                                      result in further action, including the
                                      suspension or termination of the
                                      user&apos;s access to the software tool.
                                    </p>
                                    <br />
                                    <p>
                                      COMET-Farm and related software is
                                      provided &quot;as is&quot; without any
                                      warranties, express or implied, including
                                      but not limited to the implied warranties
                                      of merchantability, fitness for a
                                      particular purpose, and non-infringement.
                                    </p>
                                    <br />
                                    <p>
                                      CSU or USDA makes no representations or
                                      warranties regarding the performance or
                                      results that may be obtained from the use
                                      of the COMET API and related Software.
                                    </p>
                                    <br />
                                    <p>
                                      In no event shall CSU or USDA be liable
                                      for any direct, indirect, incidental,
                                      special, or consequential damages arising
                                      out of or in any way connected with the
                                      use of the COMET API and related Software,
                                      even if CSU has been advised of the
                                      possibility of such damages.
                                    </p>
                                  </Grid>
                                ),
                                style: {
                                  minWidth: "750px",
                                  maxHeight: "75vh",
                                  overflow: "auto",
                                },
                                showPopup: true,
                                closeAction: () =>
                                  updateLocalPopup({ showPopup: false }),
                              });
                            }}
                            style={{
                              fontStyle: "italic",
                              fontSize: 18,
                              color: "mediumblue",
                              backgroundColor: colors.white.main,
                              textDecoration: "underline",
                              cursor: "pointer",
                              border: "none",
                            }}
                          >
                            Terms and Conditions
                          </Button>
                        </CFTypography>
                      }
                      sx={{ height: "50px" }}
                    />
                  </FormControl>
                </Grid>
                {/* Subscribe to Newsletter */}
                <Grid pb={2}>
                  <FormControl fullWidth>
                    <FormControlLabel
                      control={
                        <Checkbox
                          icon={<img src={Unchecked} alt="" />}
                          checkedIcon={<img src={Checked} alt="" />}
                          key="subscribeNewsletter"
                          name="subscribeNewsletter"
                          style={{
                            fontFamily: "sans-serif",
                            transform: "scale(.35)",
                          }}
                        />
                      }
                      label={
                        <CFTypography
                          sx={{
                            fontSize: 18,
                            color: info.dullFocus,
                          }}
                        >
                          Subscribe to COMET-Quarterly Newsletter and Tool
                          Updates
                        </CFTypography>
                      }
                      sx={{ height: "50px" }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            {/* Register */}
            <Grid p={2} item xs={5} sm={5} md={5}>
              <CFButton
                size="large"
                color="primary"
                variant="gradient"
                type="submit"
                // onClick={() => RegistrationClicked(firstNameInput, passwordInput)}
              >
                Register Account
              </CFButton>
            </Grid>
            {/* Login or Continue to Demo Projects */}
            <Grid
              item
              container
              spacing={0}
              justify="flex-end"
              alignItems="center"
            >
              <Grid
                container
                flexDirection="row"
                justifyContent="center"
                item
                xs={12}
                sm={12}
                md={12}
              >
                {/* Login */}
                <Grid item>
                  <LinkBase
                    to="/login"
                    style={{
                      fontStyle: "italic",
                      fontSize: 18,
                      color: info.dullFocus,
                      textDecoration: "underline",
                    }}
                    component={Link}
                  >
                    Login
                  </LinkBase>
                </Grid>
                {/* Or */}
                <Grid item px={1}>
                  <CFTypography
                    sx={{
                      fontStyle: "italic",
                      fontSize: 20,
                      color: info.dullFocus,
                    }}
                  >
                    OR
                  </CFTypography>
                </Grid>
                {/* Demo projects */}
                <Grid item>
                  <Button
                    onClick={handleContinueUnregistered}
                    style={{
                      fontStyle: "italic",
                      fontSize: 18,
                      color: info.dullFocus,
                      backgroundColor: colors.white.main,
                      textDecoration: "underline",
                      cursor: "pointer",
                      border: "none",
                    }}
                  >
                    Continue to Demo Projects without Registering
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CFBox>
      {localPopupState && localPopupState.showPopup && (
        <CFDialog
          {...localPopupState}
          updatePopup={(e) => updateLocalPopup(e)}
        />
      )}
    </CFarmPage>
  );
}
export default Registration;
