const cfarmFooterRoutes = [
  {
    name: "COMET-Farm Tool",
    collapse: [
      {
        name: "Tool Registration",
        description: "",
        route: "/register",
      },
      {
        name: "Tool Login",
        description: "",
        route: "/login",
      },
    ],
  },
  {
    name: "Resources",
    collapse: [
      {
        name: "Trainings",
        description: "COMET-Farm tutorial and 'how to'.",
        route: "/training",
      },
      {
        name: "Documentation and Methodology",
        description: "COMET-Farm and USDA bluebook documentation.",
        route: "/documentation",
      },
      {
        name: "Version Log",
        description: "",
        route: "/version",
      },
    ],
  },
];
export default cfarmFooterRoutes;
