import { useEffect, useState } from "react";
import { ToggleButtonGroup, Tooltip } from "@mui/material";
import { IoSearchOutline } from "react-icons/io5";
import {
  MdOutlineAddLocation,
  MdConstruction,
  MdDeleteForever,
  MdOutlineUploadFile,
  MdNotListedLocation,
  MdOutlineHelp,
} from "react-icons/md";
import { BiSolidFileExport } from "react-icons/bi";
import { PiPolygon } from "react-icons/pi";
import { LiaVectorSquareSolid } from "react-icons/lia";
import { TbVectorBezierCircle } from "react-icons/tb";
import {
  POLYGON,
  RECTANGLE,
  CIRCLE,
  POINT,
  DELETE,
  MODIFY,
  ESRI,
  FIND,
  SOILS,
  SOILS_DOWNLOAD,
} from "constants/mapButtons";
import CFBorderBox from "components/CFBorderBox";
import CFToggleButton from "components/CFToggleButton/CFToggleButtonRoot";
import UserGuidePopup from "components/CFUserGuidePopup/UserGuidePopup";
import CFBox from "components/CFBox";
import mapsHelp1 from "assets/images/field/mapping_help.jpg";
import soilViewHelp from "assets/images/field/SoilView.png";
import { croplandTableStyle } from "pages/Croplands/CroplandStyle";
import colors from "assets/theme/base/colors";

function CFMapButtons({
  btnToggleName,
  setBtnToggleName,
  setBtnValue,
  height,
  addModifyInteractions,
  addShapeInteractions,
  toggleDeleteChckbx,
  deleteOldMapInteractions,
  updateLocalPopup,
  parcelWording,
  setButtonClickObj,
  parcelNames,
  modifyOpen,
  showHelpModal,
}) {
  /* svg formatting */
  const baseColor = colors.white.main;
  const secondaryColor = colors.highlight.main;
  const [findBtnColor, setFindBtnColor] = useState(baseColor);
  const [polygonBtnColor, setPolygonBtnColor] = useState(baseColor);
  const [rectangleBtnColor, setRectangleBtnColor] = useState(baseColor);
  const [circleBtnColor, setCircleBtnColor] = useState(baseColor);
  const [pointBtnColor, setPointBtnColor] = useState(baseColor);
  const [soilBtnColor, setSoilBtnColor] = useState(baseColor);
  const [soilDwnlBtnColor, setSoilDwnlBtnColor] = useState(baseColor);
  const [modifyBtnColor, setModifyBtnColor] = useState(baseColor);
  const [deleteBtnColor, setDeleteBtnColor] = useState(baseColor);
  const [esriBtnColor, setEsriBtnColor] = useState(baseColor);

  /* Handle button clicks */

  let buttonList;
  const toggleColor = (prevColor, setNewColor, onToggleMethod) => {
    if (prevColor === baseColor) {
      setNewColor(secondaryColor);
      onToggleMethod();
    } else setNewColor(baseColor);
  };

  const colorBtns = (nextView) => {
    deleteOldMapInteractions();
    buttonList.forEach((button) => {
      // toggle this button
      if (button.name === nextView) {
        toggleColor(button.color, button.setMethod, button.toggleOnMethod);
        // toggle off/deactivate the rest
      } else {
        button.setMethod(baseColor);
      }
    });
  };

  const noParcelsPopup = () => {
    updateLocalPopup({
      message: `Please draw or upload a ${parcelWording.toLowerCase()} before trying to modify or delete.`,
      title: `Warning: No ${parcelWording.toLowerCase()}s`,
      showPopup: true,
      closeAction: () => {
        updateLocalPopup({
          showPopup: false,
        });
        setBtnToggleName(null);
      },
    });
  };

  /* Toggles the names while also handling their colors  */
  const handleToggleChange = (event, nextView) => {
    setBtnToggleName(nextView);
    setBtnValue({ buttonType: nextView });
  };

  const handleOnFind = () => {
    setButtonClickObj({
      buttonType: FIND,
    });
  };

  const handleOnDrawPolygon = () => {
    addShapeInteractions("Polygon");
  };

  const handleOnDrawRectangle = () => {
    addShapeInteractions(RECTANGLE);
  };

  const handleOnDrawCircle = () => {
    addShapeInteractions("Circle");
  };

  const handleOnDrawPoint = () => {
    addShapeInteractions("Point");
  };

  const handleOnDelete = () => {
    if (parcelNames.length > 0) {
      toggleDeleteChckbx();
    } else {
      noParcelsPopup();
    }
  };

  const handleOnSoilClick = () => {
    updateLocalPopup({
      message: `Click anywhere in the contiguous US to see the soil data of that spot.`,
      title: `Soil Data Click`,
      showPopup: true,
      closeAction: () => {
        updateLocalPopup({
          showPopup: false,
        });
      },
    });
  };

  const handleOnModifyClick = () => {
    if (parcelNames.length > 0) {
      updateLocalPopup({
        message: (
          <>
            You may drag your {parcelWording.toLowerCase()} or modify the shape.{" "}
            <b>Only modify one {parcelWording.toLowerCase()} per save!</b> Once
            you are done modifying the {parcelWording.toLowerCase()} click{" "}
            <i>Save</i> next to the <i>Map View</i> options to keep your{" "}
            changes.
          </>
        ),
        title: `Modifying ${parcelWording}`,
        showPopup: true,
        closeAction: () => {
          updateLocalPopup({
            showPopup: false,
          });
        },
      });
      addModifyInteractions();
    } else {
      noParcelsPopup();
    }
  };

  const handleOnDownloadSoilClick = () => {
    setButtonClickObj({
      buttonType: SOILS_DOWNLOAD,
    });
  };

  const handleOnEsriClick = () => {
    setButtonClickObj({
      buttonType: ESRI,
    });
  };
  buttonList = [
    {
      name: FIND,
      color: findBtnColor,
      setMethod: setFindBtnColor,
      toggleOnMethod: handleOnFind,
    },
    {
      name: POLYGON,
      color: polygonBtnColor,
      setMethod: setPolygonBtnColor,
      toggleOnMethod: handleOnDrawPolygon,
    },
    {
      name: RECTANGLE,
      color: rectangleBtnColor,
      setMethod: setRectangleBtnColor,
      toggleOnMethod: handleOnDrawRectangle,
    },
    {
      name: CIRCLE,
      color: circleBtnColor,
      setMethod: setCircleBtnColor,
      toggleOnMethod: handleOnDrawCircle,
    },
    {
      name: POINT,
      color: pointBtnColor,
      setMethod: setPointBtnColor,
      toggleOnMethod: handleOnDrawPoint,
    },
    {
      name: MODIFY,
      color: modifyBtnColor,
      setMethod: setModifyBtnColor,
      toggleOnMethod: handleOnModifyClick,
    },
    {
      name: SOILS,
      color: soilBtnColor,
      setMethod: setSoilBtnColor,
      toggleOnMethod: handleOnSoilClick,
    },
    {
      name: SOILS_DOWNLOAD,
      color: soilDwnlBtnColor,
      setMethod: setSoilDwnlBtnColor,
      toggleOnMethod: handleOnDownloadSoilClick,
    },
    {
      name: ESRI,
      color: esriBtnColor,
      setMethod: setEsriBtnColor,
      toggleOnMethod: handleOnEsriClick,
    },
    {
      name: DELETE,
      color: deleteBtnColor,
      setMethod: setDeleteBtnColor,
      toggleOnMethod: handleOnDelete,
    },
  ];

  useEffect(() => {
    colorBtns(btnToggleName);
  }, [btnToggleName]);
  return (
    <CFBorderBox
      sx={{
        height,
        backgroundColor: colors.primary.main,
        borderRadius: "0.3em 0.3em 0 0",
        paddingLeft: ".5em",
        justifyContent: "flex-start",
        alignItems: "center",
        display: "flex",
      }}
    >
      <UserGuidePopup
        showModal={showHelpModal}
        content={
          <CFBox
            sx={{ overflow: "scroll", height: "600px", maxHeight: "60vh" }}
          >
            {showHelpModal && (
              <>
                <p>
                  Use the icons in the upper-right corner of the map to define,
                  draw, upload, or modify {parcelWording.toLowerCase()}s. Also
                  included are options to view or export soil data.
                </p>
                <br />
                <img
                  src={mapsHelp1}
                  width="100%"
                  alt="Map page help infographic"
                />
                <br />
              </>
            )}
            <style>{croplandTableStyle}</style>
            <table>
              <tbody>
                <tr>
                  <td>Symbol</td>
                  <td>Function</td>
                </tr>
                <tr>
                  <td>
                    <IoSearchOutline
                      aria-label="Search"
                      className="svgFormatHelp"
                      color={secondaryColor}
                    />
                  </td>
                  <td>
                    <b>Find Location.</b> Users may create projects with
                    far-apart {parcelWording.toLowerCase()}s. Use the search
                    function to enter an address, zip code, or landmark to jump
                    to that location
                  </td>
                </tr>
                <tr>
                  <td>
                    <PiPolygon
                      aria-label="Draw Polygon"
                      className="svgFormatHelp"
                      color={secondaryColor}
                    />
                  </td>
                  <td>
                    <b>Define a {parcelWording.toLowerCase()} by a polygon.</b>{" "}
                    Use this tool to define the {parcelWording.toLowerCase()}{" "}
                    boundary of an irregular {parcelWording.toLowerCase()}{" "}
                    shape. Left-click to drop a vertice. Connect the polygon to
                    complete the shape. Double left-click at any time to
                    &quot;snap&quot; the polygon closed.
                  </td>
                </tr>
                <tr>
                  <td>
                    <LiaVectorSquareSolid
                      aria-label="Draw Rectangle"
                      className="svgFormatHelp"
                      color={secondaryColor}
                    />
                  </td>
                  <td>
                    <b>
                      Define a {parcelWording.toLowerCase()} by a rectangular
                      polygon.
                    </b>{" "}
                    Left-click to begin the rectangular{" "}
                    {parcelWording.toLowerCase()} definition. Move the cursor to
                    the edge of the {parcelWording.toLowerCase()} boundary.
                    Left-click again to close the rectangle.
                  </td>
                </tr>
                <tr>
                  <td>
                    <TbVectorBezierCircle
                      aria-label="Draw Circle"
                      className="svgFormatHelp"
                      color={secondaryColor}
                    />
                  </td>
                  <td>
                    <b>Define a {parcelWording.toLowerCase()} by circle.</b>{" "}
                    Left-click in the center of the{" "}
                    {parcelWording.toLowerCase()}. Move the cursor to the edge
                    of the {parcelWording.toLowerCase()} boundary. Left-click
                    again to close the circle.
                  </td>
                </tr>
                <tr>
                  <td>
                    <MdOutlineAddLocation
                      aria-label="Draw Point"
                      className="svgFormatHelp"
                      color={secondaryColor}
                    />
                  </td>
                  <td>
                    <b>Define a {parcelWording.toLowerCase()} by point.</b>{" "}
                    Left-click in the center of the
                    {parcelWording.toLowerCase()}. Enter the{" "}
                    {parcelWording.toLowerCase()} acreage when prompted. Using{" "}
                    {parcelWording.toLowerCase()} definition by point creates a
                    circular {parcelWording.toLowerCase()} boundary in the
                    database.
                  </td>
                </tr>
                <tr>
                  <td>
                    <MdConstruction
                      aria-label={`Modify ${parcelWording}`}
                      className="svgFormatHelp"
                      color={secondaryColor}
                    />
                  </td>
                  <td>
                    <b>Modify {parcelWording.toLowerCase()}.</b> Select a
                    previously defined {parcelWording.toLowerCase()} to modify
                    the boundary by adjusting the vertices and/or modifying the{" "}
                    {parcelWording.toLowerCase()} name.
                  </td>
                </tr>
                <tr>
                  <td>
                    <MdDeleteForever
                      aria-label={`Delete  ${parcelWording}(s)`}
                      className="svgFormatHelp"
                      color={secondaryColor}
                    />
                  </td>
                  <td>
                    <b>Delete a {parcelWording.toLowerCase()}.</b> Select the{" "}
                    {parcelWording.toLowerCase()} to be deleted. Deleting a
                    {parcelWording.toLowerCase()} will erase the{" "}
                    {parcelWording.toLowerCase()} boundary and respective
                    management. Users <i>cannot</i> undo a{" "}
                    {parcelWording.toLowerCase()} deletion.
                  </td>
                </tr>
                <tr>
                  <td>
                    <MdOutlineUploadFile
                      aria-label="Upload Shape File"
                      className="svgFormatHelp"
                      color={secondaryColor}
                    />
                  </td>
                  <td>
                    <b>Shapefile upload.</b> Users may upload a shapefile (.shp)
                    with a maximum of 50 {parcelWording.toLowerCase()}{" "}
                    defintions. For upload instructions and file specifications,
                    please review the shapefile upload section and/or solution
                    article.
                  </td>
                </tr>
                <tr>
                  <td>
                    <MdNotListedLocation
                      aria-label="Toggle View Soil By Click"
                      className="svgFormatHelp"
                      color={secondaryColor}
                    />
                  </td>
                  <td>
                    <b>View soil by click.</b> Select anywhere on the map within
                    the contiguous United States to view soil information: map
                    unit, texture, sand/silt/clay fractions, bulk density, and
                    if the soil is hydric. COMET uses SSURGO soil data.
                  </td>
                </tr>
                <tr>
                  <td>
                    <BiSolidFileExport
                      aria-label="Export Soil Information"
                      className="svgFormatHelp"
                      color={secondaryColor}
                    />
                  </td>
                  <td>
                    <b>Export soil data per {parcelWording.toLowerCase()}.</b> A{" "}
                    .csv file will download automatically and will include the{" "}
                    <i>&quot;view soil by click&quot;</i> data by the defined{" "}
                    {parcelWording.toLowerCase()}.
                  </td>
                </tr>
                <tr>
                  <td>
                    <img src={soilViewHelp} alt="Map View" />
                  </td>
                  <td>
                    <b>View SSURGO soil map units.</b> The map units describe
                    soils and other components that have unique properties,
                    interpretations, and productivity. This soil map data is
                    viewable via the{" "}
                    <a
                      href="https://websoilsurvey.nrcs.usda.gov/app/"
                      target="_blank"
                      rel="noreferrer"
                      aria-label="Open new page with the Web Soil Survey"
                    >
                      Web Soil Survey.
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </CFBox>
        }
        helpIcon={
          <Tooltip title="Help">
            <div>
              <MdOutlineHelp
                aria-label="Help"
                className="svgFormat2"
                color={baseColor}
              />
            </div>
          </Tooltip>
        }
        includeGuide={false}
        userGuideLabel={`Getting Started: Define ${parcelWording}s`}
        variant="h3"
        // sx={{ marginTop: ".2em", padding: ".1em" }}
      />
      <Tooltip
        title={
          modifyOpen && "Either save or cancel modifications before continuing."
        }
      >
        <ToggleButtonGroup
          orientation="horizontal"
          exclusive
          disabled={modifyOpen}
          onChange={handleToggleChange}
          value={btnToggleName}
        >
          <CFToggleButton value={FIND}>
            <Tooltip title="Find Location">
              <div>
                <IoSearchOutline
                  aria-label="Search"
                  className="svgFormat"
                  color={findBtnColor}
                />
              </div>
            </Tooltip>
          </CFToggleButton>
          <CFToggleButton value={POLYGON}>
            <Tooltip title="Draw Polygon">
              <div>
                <PiPolygon
                  aria-label="Draw Polygon"
                  className="svgFormat"
                  color={polygonBtnColor}
                />
              </div>
            </Tooltip>
          </CFToggleButton>
          <CFToggleButton value={RECTANGLE}>
            <Tooltip title="Draw Rectangle">
              <div>
                <LiaVectorSquareSolid
                  aria-label="Draw Rectangle"
                  className="svgFormat"
                  color={rectangleBtnColor}
                />
              </div>
            </Tooltip>
          </CFToggleButton>

          <CFToggleButton value={CIRCLE}>
            <Tooltip title="Draw Circle">
              <div>
                <TbVectorBezierCircle
                  aria-label="Draw Circle"
                  className="svgFormat"
                  color={circleBtnColor}
                />
              </div>
            </Tooltip>
          </CFToggleButton>

          <CFToggleButton value={POINT}>
            <Tooltip title="Draw Point">
              <div>
                <MdOutlineAddLocation
                  aria-label="Draw Point"
                  className="svgFormat"
                  color={pointBtnColor}
                />
              </div>
            </Tooltip>
          </CFToggleButton>
          <CFToggleButton value={MODIFY} title="Modify">
            <Tooltip title={`Modify ${parcelWording}`}>
              <div>
                <MdConstruction
                  aria-label={`Modify ${parcelWording}`}
                  className="svgFormat"
                  color={modifyBtnColor}
                />
              </div>
            </Tooltip>
          </CFToggleButton>
          <CFToggleButton value={DELETE}>
            <Tooltip title={`Delete  ${parcelWording}(s)`}>
              <div>
                <MdDeleteForever
                  aria-label={`Delete  ${parcelWording}(s)`}
                  className="svgFormat"
                  color={deleteBtnColor}
                />
              </div>
            </Tooltip>
          </CFToggleButton>

          <CFToggleButton value={ESRI}>
            <Tooltip
              title="Upload Shape File (shp)" //  Upload KML file (kml)"
            >
              <div>
                <MdOutlineUploadFile
                  aria-label="Upload Shape File"
                  className="svgFormat"
                  color={esriBtnColor}
                />
              </div>
            </Tooltip>
          </CFToggleButton>

          <CFToggleButton value={SOILS}>
            <Tooltip title="Toggle View Soil By Click">
              <div>
                <MdNotListedLocation
                  aria-label="Toggle View Soil By Click"
                  className="svgFormat"
                  color={soilBtnColor}
                />
              </div>
            </Tooltip>
          </CFToggleButton>

          <CFToggleButton value={SOILS_DOWNLOAD}>
            <Tooltip title="Export Soil Information">
              <div>
                <BiSolidFileExport
                  aria-label="Export Soil Information"
                  className="svgFormat"
                  color={soilDwnlBtnColor}
                />
              </div>
            </Tooltip>
          </CFToggleButton>
        </ToggleButtonGroup>
      </Tooltip>
    </CFBorderBox>
  );
}

export default CFMapButtons;
