/* eslint-disable react/no-unknown-property */
/* eslint-disable consistent-return */
/* eslint-disable react/button-has-type */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable arrow-body-style */
/* eslint-disable react/function-component-definition */
import React, { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CFBox from "components/CFBox";
import { CgArrowLongRight } from "react-icons/cg";
import { Grid } from "@mui/material";
import { useScrollYValue } from "hooks";
import UserGuidePopup from "components/CFUserGuidePopup/UserGuidePopup";
import { updateLoading } from "store/reducers/mask";

import CFDialog from "components/CFDialog";
import CFTypography from "components/CFTypography";

import styles from "./Roadmap.module.css";
import {
  EventStatus,
  RoadmapUserGuide,
  reportProps,
  selectedActivities,
  timelineProps,
} from "./roadmap.config";

export default function Roadmap() {
  const currentUser = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [localPopupState, setLocalPopupState] = useState({});
  const { pathname } = useLocation();

  const scrollY = useScrollYValue();
  const removeLabelsStyles = () => {
    if (scrollY < 50) {
      return "";
    }
    return styles.removeLabel;
  };
  const timelineActivities = selectedActivities();
  let displayReportProp = [...reportProps];

  const checkPath = () => {
    let propList = [];
    propList = propList.concat(timelineProps, timelineActivities, reportProps);
    let pathExist = false;
    for (let i = 0; i < propList.length; i += 1) {
      if (propList[i].to.toLowerCase() === pathname.toLowerCase())
        pathExist = true;
    }
    return pathExist;
  };

  if (
    timelineActivities.some(
      (item) => item.state.disabled || !item.state.completed
    )
  ) {
    displayReportProp = displayReportProp.map((arrayElem) => ({
      ...arrayElem,
      state: { disabled: true },
    }));
  }
  useEffect(() => {
    if (timelineActivities?.length > 0 && !checkPath()) {
      dispatch(updateLoading({ loading: true }));
      setLocalPopupState({
        zIndex: 1301, // just above help documentation
        title: "Page Navigation Error",
        children: [
          <Grid p={2} key={1}>
            <CFTypography variant="body2" fontWeight="regular">
              The currently selected project,{" "}
              <b>{currentUser.activeProject.name}</b>, does not include this
              activity. Please go back and edit your project to include current
              activity if that is your intention.
            </CFTypography>
          </Grid>,
        ],
        showPopup: true,
        button1: {
          onClick: () => navigate("/projects"),
          text: "Return to Projects page",
        },
        closable: false,
      });
    } else {
      setLocalPopupState({});
    }
  }, [currentUser?.activeProject?.activities]);
  return (
    <div
      className={`${styles.roadmapWrapper} ${removeLabelsStyles()}`}
      style={{ zIndex: 3 }} // Setting this to an arbitrarily high value so it's always in front, MUI plays games sometimes
    >
      <CFBox className={styles.roadmapContainer}>
        <ManagementLabel projectName={currentUser?.activeProject?.name} />
        <CFBox
          className={`
        ${styles.roadmapBody}
        ${removeLabelsStyles()}
        `}
        >
          <RoadmapSection trackerEvents={timelineProps} />
          <CgArrowLongRight
            style={{ height: "1.5em", width: "1.5em", marginBottom: ".3em" }}
          />
          <RoadmapSection
            style={{ flexGrow: 1 }}
            trackerEvents={timelineActivities}
            showArrows
          />
          <CgArrowLongRight
            style={{ height: "1.5em", width: "1.5em", marginBottom: ".3em" }}
          />
          <RoadmapSection trackerEvents={displayReportProp} />
        </CFBox>
        {localPopupState && localPopupState.showPopup && (
          <CFDialog
            {...localPopupState}
            updatePopup={(e) => setLocalPopupState(e)}
          />
        )}
      </CFBox>
    </div>
  );
}

const RoadmapSection = (props) => {
  const { trackerEvents, className, style, showArrows = false } = props;
  const lastEvent = trackerEvents[trackerEvents.length - 1];
  return (
    <CFBox
      className={`${styles.roadmapSection} ${className}`}
      style={{ padding: ".5em", ...style }}
    >
      {trackerEvents.map((e) => (
        <React.Fragment key={e.label}>
          <EventStatus {...e} />
          {showArrows && lastEvent !== e && (
            <CgArrowLongRight
              style={{ height: "1.5em", width: "1.5em", marginBottom: ".3em" }}
            />
          )}
        </React.Fragment>
      ))}
    </CFBox>
  );
};

const ManagementLabel = ({ projectName }) => (
  <em className={styles.roadmapLabel}>
    <UserGuidePopup
      label={`Project "${projectName}" Roadmap`}
      variant="h6"
      component="div"
      content={<RoadmapUserGuide />}
    />
  </em>
);

const SeparatingLine = () => (
  <div className={styles.separatingLine} style={{ marginBottom: ".3em" }} />
);
