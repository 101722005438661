/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

// initial state
const initialState = {
  title: "COMET-Farm Dialog",
  message: "",
  type: "info",
  showPopup: false,
};

const popup = createSlice({
  name: "popup",
  initialState,
  reducers: {
    updatePopup(state, action) {
      // eslint-disable-next-line no-param-reassign
      state.title = action.payload.title
        ? action.payload.title
        : initialState.title;
      state.message = action.payload.message
        ? action.payload.message
        : initialState.message;
      state.type = action.payload.type
        ? action.payload.type
        : initialState.type;
      state.showPopup = action.payload.showPopup
        ? action.payload.showPopup
        : initialState.showPopup;
    },
  },
});

export default popup.reducer;

export const { updatePopup } = popup.actions;
