// MUI
import { BarChart } from "@mui/x-charts/BarChart";
import { axisClasses } from "@mui/x-charts";
import { Grid, Select, MenuItem } from "@mui/material";

// React
import { useEffect, useState } from "react";

// CFarm Components
import colors from "assets/theme/base/colors";
import CFTypography from "components/CFTypography";
import CFBox from "components/CFBox";

function CroplandsProjectGraph({
  croplandsData,
  isPerAcre,
  displayedScenarios,
}) {
  // Data display selection
  const [selectedData, setSelectedData] = useState(-1);
  const [dataset, setDataset] = useState([]);
  const [dataSeries, setDataSeries] = useState([]);

  // Chart display settings
  const chartSetting = {
    tooltip: { trigger: "item" },
    xAxis: [
      {
        scaleType: "band",
        dataKey: "scenario",
        label: "Scenario",
        labelStyle: { fontSize: 16, fontWeight: "bold" },
        tickLabelStyle: { fontSize: 16 },
      },
    ],
    yAxis: [
      {
        label: "Emissions (metric tonnes CO\u2082 - eq per year)",
      },
    ],
    legend: {
      direction: "row",
      position: {
        vertical: "bottom",
        horizontal: "middle",
      },
      // padding: -20,
    },
    margin: { top: 50, right: 75, left: 100, bottom: 100 },
    sx: {
      [`.${axisClasses.left} .${axisClasses.label}`]: {
        transform: "translate(-33px, 0px)",
        fontWeight: "bold",
      },
    },
  };

  // Format chart values and stack labels
  const valueFormatter = (value) => {
    const roundedVal = Math.round((value + Number.EPSILON) * 10) / 10;
    return `${roundedVal.toLocaleString("en-US")} (tonnes CO\u2082 equiv.)`;
  };
  function labelFormatter(value, total) {
    const roundedTotal = Math.round((total + Number.EPSILON) * 10) / 10;
    return `${value} (Total: ${roundedTotal.toLocaleString("en-US")})`;
  }

  // Generate dataset for chart (dataset and series)
  function GenerateDataset(field) {
    const tempData = [];
    const tempSeries = [];

    if (!field.NeedsFetching) {
      // Data set for baseline scenario
      const baseline = {
        totalC: null,
        totalCO2: null,
        totalCO: null,
        directN2O: null,
        indirectN2O: null,
        totalCH4: null,
        scenario: "",
      };
      field.baselineRotation.ScenarioEmissions.Years[0].Categories.forEach(
        (category) => {
          switch (category.Name) {
            case "C":
              baseline.totalC = isPerAcre
                ? category.UPoint.value / field.area
                : category.UPoint.value;
              break;
            case "CO2":
              baseline.totalCO2 = isPerAcre
                ? category.UPoint.value / field.area
                : category.UPoint.value;
              break;
            case "CO":
              baseline.totalCO = isPerAcre
                ? category.UPoint.value / field.area
                : category.UPoint.value;
              break;
            case "N2O":
              category.SubCategories.forEach((subcategory) => {
                switch (subcategory.Name) {
                  case "Direct N2O Emissions":
                    baseline.directN2O = isPerAcre
                      ? subcategory.UPoint.value / field.area
                      : subcategory.UPoint.value;
                    break;
                  case "Indirect N2O Emissions":
                    baseline.indirectN2O = isPerAcre
                      ? subcategory.UPoint.value / field.area
                      : subcategory.UPoint.value;
                    break;
                  default:
                    break;
                }
              });
              break;
            case "CH4":
              baseline.totalCH4 = isPerAcre
                ? category.UPoint.value / field.area
                : category.UPoint.value;
              break;
            case "Total":
              baseline.scenario = labelFormatter(
                "baseline",
                isPerAcre
                  ? category.UPoint.value / field.area
                  : category.UPoint.value
              );
              break;
            default:
              break;
          }
        }
      );
      tempData.push(baseline);

      // Map through scenario list, data object for each scenario
      field.scenarioRotations.forEach((scenario) => {
        if (displayedScenarios?.includes(scenario.scenario.id)) {
          const scenarioObj = {
            totalC: null,
            totalCO2: null,
            totalCO: null,
            directN2O: null,
            indirectN2O: null,
            totalCH4: null,
            scenario: "",
          };

          scenario.ScenarioEmissions.Years[0].Categories.map((category) => {
            switch (category.Name) {
              case "C":
                scenarioObj.totalC = isPerAcre
                  ? category.UPoint.value / field.area
                  : category.UPoint.value;
                break;
              case "CO2":
                scenarioObj.totalCO2 = isPerAcre
                  ? category.UPoint.value / field.area
                  : category.UPoint.value;
                break;
              case "CO":
                scenarioObj.totalCO = isPerAcre
                  ? category.UPoint.value / field.area
                  : category.UPoint.value;
                break;
              case "N2O":
                category.SubCategories.map((subcategory) => {
                  switch (subcategory.Name) {
                    case "Direct N2O Emissions":
                      scenarioObj.directN2O = isPerAcre
                        ? subcategory.UPoint.value / field.area
                        : subcategory.UPoint.value;
                      break;
                    case "Indirect N2O Emissions":
                      scenarioObj.indirectN2O = isPerAcre
                        ? subcategory.UPoint.value / field.area
                        : subcategory.UPoint.value;
                      break;
                    default:
                      break;
                  }
                  return null;
                });
                break;
              case "CH4":
                scenarioObj.totalCH4 = isPerAcre
                  ? category.UPoint.value / field.area
                  : category.UPoint.value;
                break;
              case "Total":
                scenarioObj.scenario = labelFormatter(
                  scenario.scenario.name,
                  isPerAcre
                    ? category.UPoint.value / field.area
                    : category.UPoint.value
                );
                break;
              default:
                break;
            }

            return null;
          });

          tempData.push(scenarioObj);
        }
      });

      // One series for every emission category
      const totalCarbon = {
        dataKey: "totalC",
        stack: "carbon",
        label: "Carbon",
        color: colors.graphColors.croplands.carbon,
      };
      const totalCO2 = {
        dataKey: "totalCO2",
        stack: "CO2",
        label: "CO2",
        color: colors.graphColors.croplands.co2,
      };
      const totalCO = {
        dataKey: "totalCO",
        stack: "CO",
        label: "CO",
        color: colors.graphColors.croplands.co,
      };
      const directN2O = {
        dataKey: "directN2O",
        stack: "N2O",
        label: "Direct N2O",
        color: colors.graphColors.croplands.n2oDirect,
      };
      const indirectN2O = {
        dataKey: "indirectN2O",
        stack: "N2O",
        label: "Indirect N2O",
        color: colors.graphColors.croplands.n2oIndirect,
      };
      const totalCH4 = {
        dataKey: "totalCH4",
        stack: "CH4",
        label: "CH4",
        color: colors.graphColors.croplands.ch4,
      };
      tempSeries.push(totalCarbon);
      tempSeries.push(totalCO2);
      tempSeries.push(totalCO);
      tempSeries.push(directN2O);
      tempSeries.push(indirectN2O);
      tempSeries.push(totalCH4);
    }

    return [tempData, tempSeries];
  }
  useEffect(() => {
    // Update when display settings or data changes
    if (selectedData !== -1) {
      croplandsData.map((field) => {
        if (field.id === selectedData) {
          const chartData = GenerateDataset(field);

          setDataset(chartData[0]);
          setDataSeries(chartData[1]);
        }
        return null;
      });
    }
  }, [croplandsData, isPerAcre, displayedScenarios]);

  // User selection
  const SelectionChange = async (e) => {
    setSelectedData(e.target.value);

    // User makes selection, locate corresponding stand
    if (e.target.value !== -1) {
      croplandsData.map((field) => {
        if (field.id === e.target.value) {
          const chartData = GenerateDataset(field);

          setDataset(chartData[0]);
          setDataSeries(chartData[1]);
        }
        return null;
      });
    }
  };

  return (
    <Grid container direction="column" py={2}>
      {/* Scenario Selection */}
      <Grid item container direction="row" px={2} pb={2} xs={2}>
        <CFTypography variant="h6" fontWeight="regular" pr={1}>
          {"Select Field: "}
        </CFTypography>
        {/* Display select */}
        <Select autoWidth value={selectedData} onChange={SelectionChange}>
          {/* Default value */}
          <MenuItem disabled value={-1}>
            Options...
          </MenuItem>
          {/* Available scenarios */}
          {croplandsData.map((field) => (
            <MenuItem key={field.id} value={field.id}>
              {field.infoString}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      {/* Chart Title */}
      <Grid item container direction="column" alignItems="center" xs={1}>
        <CFTypography variant="h5">
          {selectedData === -1
            ? "Total GHG Emissions (metric tonnes CO\u2082 - eq per year)"
            : croplandsData.map((field) => {
                if (field.id === selectedData) {
                  return `Total GHG Emissions (metric tonnes CO\u2082 - eq per year): ${field.name}`;
                }
                return null;
              })}
        </CFTypography>
      </Grid>
      {/* Chart */}
      <Grid item container direction="column" alignItems="center" xs={10}>
        <CFBox sx={{ height: 650, width: "100%" }}>
          <BarChart
            dataset={dataset}
            series={dataSeries.map((series) => ({
              ...series,
              highlightScope: {
                highlighted: "series",
                faded: "global",
              },
              valueFormatter,
            }))}
            grid={{ horizontal: true }}
            {...chartSetting}
          />
        </CFBox>
      </Grid>
    </Grid>
  );
}
export default CroplandsProjectGraph;
