/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/prop-types */
import { React } from "react";
import { Autocomplete, Grid, Link } from "@mui/material";

import colors from "assets/theme/base/colors";
import CFInput from "components/CFInput";
import RequiredComplete from "components/CFarmParts/CFStep/RequiredComplete";

function RenderName(props, option) {
  const { name, dataComplete, link } = option;

  if (option && name) {
    if (dataComplete !== null && dataComplete !== undefined) {
      return (
        <li {...props}>
          {" "}
          <Grid container>
            <Grid item xs={11}>
              {name}
            </Grid>
            <Grid item xs={1}>
              <RequiredComplete complete={dataComplete} />
            </Grid>
          </Grid>{" "}
        </li>
      );
    }

    if (link !== null && link !== undefined && link) {
      return (
        <li {...props}>
          {" "}
          <Grid container>
            <Grid item xs={9}>
              {name}
            </Grid>
            <Grid item xs={3}>
              <Link
                target="_blank"
                sx={{ fontWeight: "medium" }}
                href={link.href}
              >
                {link.text}
              </Link>
            </Grid>
          </Grid>{" "}
        </li>
      );
    }
    return <li {...props}> {name}</li>;
  }

  return "";
}

export default function CFLookupPicker({
  onChange,
  options,
  defaultValue,
  label,
  width,
  value,
  ...rest
}) {
  return (
    <Autocomplete
      value={value && JSON.stringify(value) !== "{}" ? value : null}
      {...rest}
      sx={{ width: "100%" }}
      onChange={onChange}
      options={options}
      isOptionEqualToValue={(option, val) => {
        if (option?.id === val?.id || option?.id === val) {
          return true;
        }
        if (option?.name === val?.name || option?.name === val) {
          return true;
        }

        return false;
      }}
      getOptionLabel={(option) => (option?.name ? option.name : "")}
      disableClearable
      renderOption={(props, option) => RenderName(props, option)}
      renderInput={(params) => (
        <CFInput
          {...params}
          label={label || defaultValue?.name || "Select a field"}
          sx={{ backgroundColor: colors.white.main }}
          inputProps={{
            style: { padding: 0, fontSize: "1.25em" },
            ...params.inputProps,
          }}
          //  variant="standard"
        />
      )}
    />
  );
}
