/* eslint-disable no-plusplus */
import colors from "assets/theme/base/colors";
import CFarmPage from "components/CFarmParts/CFarmPage";
import CFStep from "components/CFarmParts/CFStep";
import CFBox from "components/CFBox";
import CFInput from "components/CFInput";
import CFTypography from "components/CFTypography";
import { useEffect, useState } from "react";
import {
  Grid,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import CFLookupPicker from "components/CroplandsComponents/CFLookupPicker/CFLookupPicker";
import { GET_QUICK_ENERGY, GET_ENERGY_COEFFICIENTS } from "constants/api";
import { get, post } from "utils/api";

import "./QuickEnergy.css";
import CFStepMarker from "components/CFStepMarker/CFStepMarker";
import CFLink from "components/CFLink/CFLink";
import CFButton from "components/CFButton";

function QuickEnergy() {
  const [zipcode, setZipcode] = useState(0);
  const [units, setUnits] = useState({ name: "pounds (lbs)" });
  const [unitsList, setUnitsList] = useState([]);
  const [biodieselList, setBiodieselList] = useState([]);
  const [projectName, setProjectName] = useState("");

  const defaultValues = [
    { number: 0, string: 0 },
    { number: 0, string: 0 },
    { number: 0, string: 0 },
    { number: 0, string: 0 },
    { number: 0, string: 0 },
    { number: 0, string: 0 },
    { number: 0, string: 0 },
    { number: 0, string: 0 },
  ];
  const defaultCoefficients = [[], [], [], [], [], [], [], [], [], [], [], []];

  const [no2DieselVal, setno2Diesel] = useState(0);
  const [dieselValues, setDieselValues] = useState(defaultValues);
  const [gasolineVal, setGasoline] = useState(0);
  const [gasolineValues, setGasolineValues] = useState(defaultValues);
  const [biodiesel, setBiodiesel] = useState(0);
  const [biodieselSelect, setBiodieselSelect] = useState({
    name: "Biodiesel B2",
  });
  const [biodieselValues, setBiodieselValues] = useState(defaultValues);
  const [biodieselFuel, setBiodieselFuel] = useState("b2");
  const [SVOVal, setSVO] = useState(0);
  const [SVOValues, setSVOValues] = useState(defaultValues);

  const [propaneVal, setPropane] = useState(0);
  const [propaneValues, setPropaneValues] = useState(defaultValues);
  const [naturalGasVal, setNaturalGas] = useState(0);
  const [naturalGasValues, setNaturalGasValues] = useState(defaultValues);
  const [CNGVal, setCNG] = useState(0);
  const [CNGValues, setCNGValues] = useState(defaultValues);

  const [electricityVal, setElectricity] = useState(0);
  const [electricityValues, setElectricityValues] = useState(defaultValues);
  const [totalValues, setTotalValues] = useState(defaultValues);

  const [coefficients, setCoefficientValues] = useState(defaultCoefficients);
  const [co2Equivalents, setCO2Equivalents] = useState([]);

  const unitsConversion = [];
  unitsConversion["pounds (lbs)"] = 1.0;
  unitsConversion["tonnes (metric)"] = 0.00045359237;

  /*
   * Helper function to format the calculation results
   * for display purposes.
   */
  const formatResult = (amount, inUnits = units.name) => {
    const nFormat = new Intl.NumberFormat(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    if (amount === 0.0) {
      return "0.00";
    }
    if (amount < 1.0 && amount > 0) {
      if (inUnits === "pounds (lbs)") {
        if (amount < 0.01) {
          return "~ 0.01";
        }
      } else {
        /* tonnes */
        return "< 1";
      }
    } else if (amount < 0.0 && amount > -1.0) {
      return "> -1";
    }
    return nFormat.format(amount);
  };

  const formatArray = (valueArray) => {
    const returnArray = [];
    for (let i = 0; i < valueArray.length; i++) {
      if (valueArray[i] !== undefined)
        returnArray[i] = {
          number: valueArray[i],
          string: formatResult(valueArray[i]),
        };
    }
    return returnArray;
  };

  /*
   * Helper function to format the coefficients
   * for display purposes.
   */
  const formatCoefficient = (coefficient) => {
    if (!coefficient) return "";
    if (coefficient === 0.0) {
      return "";
    }
    const nFormat = new Intl.NumberFormat(undefined, {
      minimumFractionDigits: 3,
      maximumFractionDigits: 5,
    });
    return nFormat.format(coefficient);
    // if (coefficient < 0.0001) {
    //   return Ext.util.Format.number(coefficient, "0.00000");
    // }
    // if (coefficient < 0.001 && coefficient >= 0.0001) {
    //   return Ext.util.Format.number(coefficient, "0.0000");
    // }
    // if (coefficient < 1 && coefficient >= 0.001) {
    //   return Ext.util.Format.number(coefficient, "0.000");
    // }
    // if (coefficient < 1000 && coefficient >= 1) {
    //   return Ext.util.Format.number(coefficient, "0.000");
    // }

    // return Ext.util.Format.number(coefficient, "0,000.00");
  };

  const getConversionFactor = () => unitsConversion[units.name];
  const getInLbs = (value) => {
    if (units.name === "pounds (lbs)") return formatResult(value);
    return formatResult(
      value / unitsConversion["tonnes (metric)"],
      "pounds (lbs)"
    );
  };

  const getInTonnes = (value) => {
    if (units.name === "tonnes (metric)") return formatResult(value);
    return formatResult(
      value * unitsConversion["tonnes (metric)"],
      "tonnes (metric)"
    );
  };

  // constants for "Values" array lookups or columns
  const MMBtu = 0;
  const co2 = 1;
  const n2o = 2;
  const ch4 = 3;
  const totalCO2 = 4;
  const so2 = 5;
  const nox = 6;
  const biogenicco2 = 7;

  // return the enum associated with the emission code string
  const getEmissionIndex = (emissionString) => {
    switch (emissionString) {
      case "co2":
        return co2;
      case "n2o":
        return n2o;
      case "ch4":
        return ch4;
      case "so2":
        return so2;
      case "nox":
        return nox;
      case "biogenicco2":
        return biogenicco2;
      default:
        return 0;
    }
  };

  // constants for fuel array lookups
  const no2diesel = 0;
  const gasoline = 1;
  const svo = 2;
  const propane = 3;
  const naturalgas = 4;
  const cng = 5;
  const electricity = 6;
  const b2 = 7;
  const b5 = 8;
  const b10 = 9;
  const b20 = 10;
  const b100 = 11;

  const getFuelIndex = (fuelType) => {
    switch (fuelType) {
      case "no2diesel":
        return no2diesel;
      case "gasoline":
        return gasoline;
      case "svo":
        return svo;
      case "propane":
        return propane;
      case "naturalgas":
        return naturalgas;
      case "cng":
        return cng;
      case "electricity":
        return electricity;
      case "b2":
        return b2;
      case "b5":
        return b5;
      case "b10":
        return b10;
      case "b20":
        return b20;
      case "b100":
        return b100;
      default:
        return undefined;
    }
  };
  const unitsLabel = () => {
    if (units.name === "pounds (lbs)") return "lbs";
    return "tonnes";
  };

  // row values
  const getFuelValuesSetter = (fuelType) => {
    switch (fuelType) {
      case no2diesel:
        return setDieselValues;
      case gasoline:
        return setGasolineValues;
      case svo:
        return setSVOValues;
      case propane:
        return setPropaneValues;
      case naturalgas:
        return setNaturalGasValues;
      case cng:
        return setCNGValues;
      case electricity:
        return setElectricityValues;
      default:
        // assuming all else are b values
        return setBiodieselValues;
    }
  };
  /*
   * Function: getBtuPerUnit
   * Returns the BTUs per unit of fuel for the given fuel
   * (lower-case version of lookup value code).
   */
  const getBtus = (fuel, amount) =>
    (coefficients[fuel][MMBtu] * amount) / 1000000;

  /*
   * Function: calculate
   * Calculates the emissions for a fuelType based on
   * the given amount (in gallons, CCF, kWh). Emissions are in
   * pounds (lbs.) per unit fuel.
   */
  function calculate(fuelType, amount) {
    const conversionFactor = getConversionFactor();
    // make sure the coefficients have been populated first
    if (coefficients[fuelType] != null) {
      let result = [];

      result[co2] = coefficients[fuelType][co2] * amount * conversionFactor;
      result[n2o] = coefficients[fuelType][n2o] * amount * conversionFactor;
      result[ch4] = coefficients[fuelType][ch4] * amount * conversionFactor;
      result[totalCO2] =
        result[co2] +
        result[n2o] * co2Equivalents[n2o] +
        result[ch4] * co2Equivalents[ch4];

      result[so2] = coefficients[fuelType][so2] * amount * conversionFactor;
      result[nox] = coefficients[fuelType][nox] * amount * conversionFactor;

      result[biogenicco2] =
        coefficients[fuelType][biogenicco2] * amount * conversionFactor;

      result[MMBtu] = getBtus(fuelType, amount); // British Thermal Units is currently calculated using fuel service.
      result = formatArray(result);
      getFuelValuesSetter(fuelType)(result);
    }
  }

  // const classes = useStyles();
  function createInput(name, value, setMethod, fuelCode, endUnits) {
    return (
      <CFInput
        value={value}
        key={`${name} input`}
        InputProps={{
          endAdornment: (
            <InputAdornment sx={{ fontSize: ".3em" }} position="end">
              <CFBox sx={{ color: "rgba(0, 0, 0, 0.54)", fontSize: "3.5em" }}>
                {endUnits}
              </CFBox>
            </InputAdornment>
          ),
          // classes: {
          //   adornedEnd: classes.endAdornment,
          // },
        }}
        onChange={(event) => {
          setMethod(event.target.value);
          calculate(getFuelIndex(fuelCode), event.target.value);
        }}
      />
    );
  }

  function createData(
    labelName,
    userInput,
    setUserInput,
    values,
    fuelCode,
    endUnits,
    cellClassName,
    className = "data-label-cell"
  ) {
    return {
      label: labelName,
      userInput: setUserInput
        ? createInput(labelName, userInput, setUserInput, fuelCode, endUnits)
        : userInput,
      MMBtu: values[MMBtu]?.string,
      co2: values[co2]?.string,
      n2o: values[n2o]?.string,
      ch4: values[ch4]?.string,
      co2Total: values[totalCO2]?.string,
      so2: values[so2]?.string,
      nox: values[nox]?.string,
      fuelCode: getFuelIndex(fuelCode),
      cellClassName,
      className,
    };
  }

  function createHeaderData(label) {
    return createData(
      label,
      null,
      null,
      [],
      null,
      null,
      "data-header-label-cell",
      "data-header-label-cell"
    );
  }
  function createBiodieselSelect() {
    return (
      <CFLookupPicker
        key="biodieselBlendsSelect"
        name="biodieselBlendsSelect"
        required
        value={biodieselSelect}
        onChange={(event, newValue) => {
          setBiodieselSelect(newValue);
          let fuelCode = "b2";
          switch (newValue.name) {
            case "Biodiesel B2":
              fuelCode = "b2";
              break;
            case "Biodiesel B5":
              fuelCode = "b5";
              break;
            case "Biodiesel 10":
              fuelCode = "b10";
              break;
            case "Biodiesel B20":
              fuelCode = "b20";
              break;
            case "Biodiesel B100":
              fuelCode = "b100";
              break;
            default:
              break;
          }
          setBiodieselFuel(fuelCode);
          calculate(getFuelIndex(fuelCode), biodiesel);
        }}
        options={biodieselList}
      />
    );
  }
  const rows = [
    createHeaderData("Liquids"),
    createData(
      "No. 2 Diesel",
      no2DieselVal,
      setno2Diesel,
      dieselValues,
      "no2diesel",
      "gal",
      "data-cell"
    ),
    createData(
      "Gasoline",
      gasolineVal,
      setGasoline,
      gasolineValues,
      "gasoline",
      "gal",
      "data-cell-dark"
    ),
    createData(
      createBiodieselSelect(),
      biodiesel,
      setBiodiesel,
      biodieselValues,
      biodieselFuel,
      "gal",
      "data-cell"
    ),
    createData(
      "SVO",
      SVOVal,
      setSVO,
      SVOValues,
      "svo",
      "gal",
      "data-cell-dark"
    ),

    createHeaderData("Gas"),
    createData(
      "Propane",
      propaneVal,
      setPropane,
      propaneValues,
      "propane",
      "gal",
      "data-cell"
    ),
    createData(
      "Natural Gas",
      naturalGasVal,
      setNaturalGas,
      naturalGasValues,
      "naturalgas",
      "CCF",
      "data-cell-dark"
    ),
    createData("CNG", CNGVal, setCNG, CNGValues, "cng", "CCF", "data-cell"),

    createHeaderData("Electric"),
    createData(
      "Electricity",
      electricityVal,
      setElectricity,
      electricityValues,
      "electricity",
      "kWh",
      "data-cell-dark"
    ),

    createData(
      "",
      "Total:",
      null,
      totalValues,
      "total",
      null,
      "total-data-cell",
      "data-input-bottom-cell"
    ),
  ];

  const columns = [
    {
      field: "label",
      headerName: "",
      width: 120,
      includeInCoeffs: true,
      className: "empty-header-cell",
    },
    {
      field: "userInput",
      headerName: "Enter Annual Fuel Savings",
      type: "number",
      className: "data-input-cell-top header-cell",
      width: 120,
      includeInCoeffs: false,
    },
    {
      field: "MMBtu",
      headerName: "MMBtu",
      type: "number",
      width: 90,
      className: "header-cell",
    },
    {
      field: "co2",
      headerName: (
        <>
          CO<sub>2</sub>
          <sup>1</sup>
        </>
      ),
      type: "number",
      width: 90,
      className: "header-cell",
      includeInCoeffs: true,
    },
    {
      field: "n2o",
      headerName: "N\u2082O",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 90,
      className: "header-cell",
      includeInCoeffs: true,
    },
    {
      field: "ch4",
      headerName: "CH\u2084",
      type: "number",
      width: 90,
      className: "header-cell",
      includeInCoeffs: true,
    },
    {
      field: "co2Total",
      headerName: "Total CO\u2082-Equivalent",
      type: "number",
      width: 90,
      className: "header-cell",
      includeInCoeffs: false,
    },
    {
      field: "so2",
      headerName: "SO\u2082",
      type: "number",
      width: 90,
      className: "header-cell",
      includeInCoeffs: true,
    },
    {
      field: "nox",
      headerName: "NO\u2093",
      type: "number",
      width: 90,
      className: "header-cell",
      includeInCoeffs: true,
    },
  ];

  /*
   * Function: setCoefficients
   * Parses the config into the coefficients array - stored by
   * fuel and emission (i.e. coefficients[fuel][emission]) for
   * easy retrieval in calculations.
   */
  const setCoefficients = (data) => {
    const coefficientsTemp = [];
    if (data != null) {
      for (let i = 0; i < data.length; i++) {
        const item = data[i];
        const fuel = getFuelIndex(item.fuelCode);
        const emission = getEmissionIndex(item.emissionCode);

        if (coefficientsTemp[fuel] === undefined) {
          coefficientsTemp[fuel] = [];
        }

        coefficientsTemp[fuel][emission] = item.value;
        coefficientsTemp[fuel][MMBtu] = item.fuelBtuPerUnit;
        if (co2Equivalents[emission] === undefined) {
          co2Equivalents[emission] = item.emissionCO2Equivalent;
        }
      }
    }
    setCoefficientValues(coefficientsTemp);
  };

  const isValidZipCode = (zipCode) => {
    if (zipCode.length > 5) return false;
    const re = /\d\d\d\d\d/; /* five-digit zip code */
    return re.test(zipCode);
  };
  /*
   * Function: QuickEnergy.handleZipCodeChange
   * Handles zip code data entry changes in the UI by calling the coefficients
   * service with the QuickEnergy.getCoefficientsCallback callback function
   * defined above.
   */
  function handleZipCodeChange(event) {
    const zipCode = event.target.value;
    setZipcode(zipCode);
    if (isValidZipCode(zipCode)) {
      post(GET_ENERGY_COEFFICIENTS, { zipCode })
        .then((res) => {
          if (res.error) {
            console.log("error", res.error);
            // popupError(res.error, dispatch);
          } else {
            console.log(res);
            setCoefficients(res.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setCoefficientValues(defaultCoefficients);
    }
  }

  useEffect(() => {
    get(GET_QUICK_ENERGY)
      .then((res) => {
        if (res.error) {
          console.log("error", res.error);
          // popupError(res.error, dispatch);
        } else {
          setUnitsList(res.data.unitsOfMeasurement);
          setBiodieselList(res.data.biodieselBlends);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    for (let i = 0; i < rows.length; i++) {
      if (rows[i].fuelCode !== undefined) {
        if (
          coefficients[rows[i].fuelCode] !== undefined &&
          coefficients[rows[i].fuelCode].length > 0
        ) {
          calculate(rows[i].fuelCode, rows[i].userInput.props.value);
        } else {
          getFuelValuesSetter(rows[i].fuelCode)(defaultValues);
        }
      }
    }
  }, [coefficients, units]);

  useEffect(() => {
    const listofValues = [
      dieselValues,
      gasolineValues,
      biodieselValues,
      SVOValues,
      propaneValues,
      naturalGasValues,
      CNGValues,
      electricityValues,
    ];
    let tempTotal = [];

    for (let i = 0; i < listofValues.length; i++) {
      for (let j = 0; j < listofValues[i].length; j++)
        if (listofValues[i][j] !== undefined) {
          if (!tempTotal[j]) tempTotal[j] = 0;
          tempTotal[j] += listofValues[i][j].number;
        }
    }
    tempTotal = formatArray(tempTotal);
    setTotalValues(tempTotal);
  }, [
    dieselValues,
    gasolineValues,
    biodieselValues,
    SVOValues,
    propaneValues,
    naturalGasValues,
    CNGValues,
    electricityValues,
  ]);
  // need to update on changing coefficients
  return (
    <CFarmPage>
      <CFBox sx={{ marginTop: "3%", marginBottom: "3%" }}>
        <CFBox>
          <CFStep
            stepLabel="COMET-Energy"
            subText="What are the annual emissions reductions associated with your annual fuel savings?"
            // helpText="Select values from the drop-down menus, or enter numerical values, to provide data for the emissions report."
          />
          <CFButton
            id="btnPrint"
            type="button"
            value="Print page"
            onClick={window.print}
            sx={{ float: "right", marginBottom: ".5em" }}
          >
            Print Page
          </CFButton>
        </CFBox>
        <CFBox>
          <Grid
            container
            direction="row"
            height="5em"
            spacing={1}
            sx={{
              padding: "0 .5em .5em 0",
              background: "#ddd",
              border: `solid .3em ${colors.secondary.focus} !important`,
              borderRadius: ".3em",
              margin: ".5em .5em .5em 0",
            }}
          >
            <Grid container direction="row" item xs={4}>
              <Grid
                container
                direction="row"
                item
                xs={8.5}
                spacing={1}
                alignContent="center"
              >
                <Grid item xs={2.5}>
                  <CFStepMarker label={1} style={{ fontSize: ".75em" }} />
                </Grid>
                <Grid item xs={9.5}>
                  <CFTypography
                    sx={{
                      fontSize: "16px",
                      color: colors.info.dullFocus,
                    }}
                  >
                    In what zip code is your operation located?{" "}
                  </CFTypography>
                </Grid>
              </Grid>
              <Grid item xs={3.5} alignContent="center">
                <CFInput
                  error={!isValidZipCode(zipcode)}
                  helperText={
                    !isValidZipCode(zipcode) ? "Invalid zip code." : ""
                  }
                  value={zipcode}
                  onChange={(event) => handleZipCodeChange(event)}
                />
              </Grid>
            </Grid>
            <Grid container direction="row" item xs={4}>
              <Grid item container direction="row" xs={8} alignContent="center">
                <Grid item xs={2}>
                  <CFStepMarker
                    label={2}
                    style={{ fontSize: ".75em", margin: "0 .3em 0 .3em" }}
                  />
                </Grid>
                <Grid item xs={10}>
                  <CFTypography
                    sx={{
                      fontSize: "16px",
                      color: colors.info.dullFocus,
                    }}
                  >
                    In what units do you want your emissions reported?
                  </CFTypography>
                </Grid>
              </Grid>
              <Grid item xs={4} alignContent="center">
                <CFLookupPicker
                  key="units"
                  name="units"
                  required
                  value={units}
                  onChange={(event, newValue) => {
                    setUnits(newValue);
                  }}
                  options={unitsList}
                />
              </Grid>
            </Grid>
            <Grid container direction="row" item xs={4}>
              <Grid item container direction="row" xs={5} alignContent="center">
                <Grid item xs={3}>
                  <CFStepMarker
                    label={3}
                    style={{ fontSize: ".75em", margin: "0 .3em 0 .3em" }}
                  />
                </Grid>
                <Grid item xs={9}>
                  <CFTypography
                    sx={{
                      fontSize: "16px",
                      color: colors.info.dullFocus,
                    }}
                  >
                    Project Name:{" "}
                  </CFTypography>
                </Grid>
              </Grid>

              <Grid item xs={7} alignContent="center">
                <CFInput
                  value={projectName}
                  onChange={(event) => {
                    setProjectName(event.target.value);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <TableContainer component={Paper} key="table cont">
            <Table
              sx={{ minWidth: 700 }}
              aria-label="spanning table"
              key="table"
            >
              <TableHead sx={{ display: "table-header-group" }} key="tableHead">
                <TableRow
                  key="tableHeader1"
                  sx={{ background: colors.primary.main }}
                >
                  <TableCell key="emptycell" colSpan={3} />
                  <TableCell
                    key="Greenhouse Gas Emissions"
                    align="center"
                    colSpan={4}
                    className="header-cell"
                  >
                    {`Greenhouse Gas Emissions (${unitsLabel()})`}
                  </TableCell>
                  <TableCell
                    key="AirPollutants"
                    colSpan={2}
                    className="header-cell"
                  >
                    {`Air Pollutants (${unitsLabel()})`}
                  </TableCell>
                </TableRow>
                <TableRow
                  key="tableHeader2"
                  sx={{ background: colors.highlight.focus }}
                >
                  {columns.map((column) => (
                    <TableCell
                      width={column.width}
                      key={column.headerName}
                      className={column.className}
                    >
                      {column.headerName}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody key="table body">
                {rows.map((row) => (
                  <TableRow key={row.label}>
                    <TableCell
                      key={`${row.label} label`}
                      className={`${row.className} ${row.cellClassName}`}
                    >
                      {row.label}
                      {row.label === "Electricity" && <sup>2</sup>}
                    </TableCell>
                    <TableCell
                      key={`${row.label} userInput`}
                      className={
                        row.userInput === "Total:"
                          ? row.className
                          : "data-input-cell"
                      }
                    >
                      {row.userInput}
                    </TableCell>
                    <TableCell
                      key={`${row.label} MMBtu`}
                      className={`${row.cellClassName} mmbtuCell`}
                    >
                      {row.MMBtu}
                    </TableCell>
                    <TableCell
                      key={`${row.label} co2`}
                      className={row.cellClassName}
                    >
                      {row.co2}
                    </TableCell>
                    <TableCell
                      key={`${row.label} n2o`}
                      className={row.cellClassName}
                    >
                      {row.n2o}
                    </TableCell>
                    <TableCell
                      key={`${row.label} ch4`}
                      className={row.cellClassName}
                    >
                      {row.ch4}
                    </TableCell>
                    <TableCell
                      key={`${row.label} co2total`}
                      className={`${row.cellClassName} totalCO2EValueCell`}
                    >
                      {row.co2Total}
                    </TableCell>
                    <TableCell
                      key={`${row.label} so2`}
                      className={row.cellClassName}
                    >
                      {row.so2}
                    </TableCell>
                    <TableCell
                      key={`${row.label} nox`}
                      className={row.cellClassName}
                    >
                      {row.nox}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>{" "}
          <CFBox className="footnotes">
            <sup>1</sup>&nbsp;Totals in this column include CO2 emissions from
            petroleum-based fuels only.
            <br />
            <sup>2</sup>&nbsp;Emissions from electricity are based on the zip
            code entered above.
          </CFBox>
        </CFBox>

        <div className="page-break">&nbsp;</div>
        <CFBox
          sx={{
            padding: "1em",
            background: "#ddd",
            border: `solid .3em ${colors.secondary.focus} !important`,
            borderRadius: ".3em",
            margin: ".5em 0em .5em 0em",
          }}
        >
          <CFTypography
            sx={{
              fontSize: "32px",
            }}
          >
            Summary
          </CFTypography>
          <CFTypography margin=".7em 0 0 1em">
            Your annual greenhouse gas emissions were{" "}
            <b>
              reduced by an estimated{" "}
              {getInLbs(
                totalValues[totalCO2].number + totalValues[biogenicco2].number
              )}{" "}
              pounds CO<sub>2</sub>-Equivalent / year{" "}
            </b>
            (
            {getInTonnes(
              totalValues[totalCO2].number + totalValues[biogenicco2].number
            )}{" "}
            tonnes / year). Of these emissions, fossil fuels accounted for{" "}
            {getInLbs(totalValues[totalCO2].number)} pounds CO<sub>2</sub>
            -Equivalent / year ({getInTonnes(totalValues[totalCO2].number)}{" "}
            tonnes / year) and biogenic-origin fuels accounted for{" "}
            {getInLbs(totalValues[biogenicco2].number)} pounds CO<sub>2</sub>
            -Equivalent / year ({getInTonnes(
              totalValues[biogenicco2].number
            )}{" "}
            tonnes / year). Your estimated annual energy savings is{" "}
            {totalValues[MMBtu].string} MMBtu per year.
          </CFTypography>
        </CFBox>
        <CFBox
          sx={{
            border: "solid 1px #d8d8d8 !important",
            borderRadius: ".3em",
            padding: "1em",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={5} padding="1em">
              <CFTypography
                sx={{
                  fontSize: "24px",
                  fontWeight: "bold",
                }}
              >
                How are your emmissions calculated?
              </CFTypography>
              <CFBox id="coefficientsDiv">
                <CFBox id="descriptionDiv" fontSize=".95em" marginLeft=".5em">
                  <CFBox id="topParagraph" marginTop="1em">
                    <CFTypography>
                      Emissions are calculated based on the reporting guidelines
                      defined in the U.S. Energy Information Administrations{" "}
                      <CFLink
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.eia.gov/environment/pdfpages/0608s(2009)index.php"
                      >
                        Voluntary Reporting of Greenhouse Gases Program
                      </CFLink>
                      <sup>3,4</sup>.<br />
                      <br />
                      Each emission is calculated using the following equation:
                    </CFTypography>
                  </CFBox>
                  <CFBox marginLeft="1em" fontWeight="bold" fontSize="18px">
                    <CFBox margin=".6em 0em .6em 0em">
                      emissions = QF * EC
                    </CFBox>
                    Where,
                    <CFBox id="variablesDiv" margin=".6em 0em .6em 1em">
                      QF = quantity of fuel
                      <br />
                      EC = emission coefficient
                    </CFBox>
                  </CFBox>
                  <CFBox id="bottomParagraph">
                    Fuel quantity values are taken from the inputs provided
                    above. Emission coefficients are taken from the{" "}
                    <CFLink
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.eia.gov/environment/pdfpages/0608s(2009)index.php"
                    >
                      Voluntary Reporting of Greenhouse Gases Program Guidelines
                    </CFLink>{" "}
                    and, for electricity, the{" "}
                    <CFLink
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.epa.gov/egrid/download-data"
                    >
                      U.S. Environmental Protection Agency&apos;s eGRID2022
                    </CFLink>
                    <sup>5</sup>
                    (see table to the right).
                  </CFBox>
                </CFBox>
              </CFBox>
            </Grid>
            <Grid item xs={7}>
              <TableContainer component={Paper} key="coeff table cont">
                <Table
                  sx={{ minWidth: 400 }}
                  aria-label="spanning table"
                  key="coeff table"
                >
                  <TableHead
                    sx={{ display: "table-header-group" }}
                    key="coeff table header"
                  >
                    <TableRow
                      key="coeff table headings"
                      sx={{ background: colors.primary.main }}
                    >
                      <TableCell
                        colSpan={6}
                        align="center"
                        className="header-cell-coeff"
                      >
                        Emission Coefficients (EC) (lbs. per unit fuel)
                      </TableCell>
                    </TableRow>
                    <TableRow
                      key="coeff table header 1"
                      sx={{ background: colors.primary.main }}
                    >
                      <TableCell colSpan={1} key="coeff empty header2" />
                      <TableCell
                        key="Greenhouse Gas EmissionsCoeff"
                        align="center"
                        colSpan={3}
                        className="header-cell"
                      >
                        Greenhouse Gas Emissions
                      </TableCell>
                      <TableCell
                        key="AirPollutantsCoeff"
                        align="center"
                        colSpan={2}
                        className="header-cell"
                      >
                        Air Pollutants
                      </TableCell>
                    </TableRow>
                    <TableRow
                      key="coeff table header 2"
                      sx={{ background: colors.highlight.focus }}
                    >
                      {columns.map((column) =>
                        column.includeInCoeffs ? (
                          <TableCell
                            width={column.width}
                            key={`${column.headerName} coeff`}
                            className={column.className}
                          >
                            {column.headerName}
                          </TableCell>
                        ) : null
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody key="coeff body">
                    {rows.map((row) =>
                      row.userInput !== "Total:" ? (
                        <TableRow key={`${row.label} coeff row`}>
                          <TableCell
                            key={`${row.label} label coeffs`}
                            className={row.className}
                          >
                            {row.fuelCode > 6
                              ? biodieselSelect.name
                              : row.label}
                          </TableCell>
                          {row.fuelCode !== undefined && (
                            <>
                              <TableCell
                                key={`${row.label} co2 coeffs`}
                                className={row.cellClassName}
                              >
                                {formatCoefficient(
                                  coefficients[row.fuelCode][co2]
                                )}
                              </TableCell>
                              <TableCell
                                key={`${row.label} n2o coeffs`}
                                className={row.cellClassName}
                              >
                                {formatCoefficient(
                                  coefficients[row.fuelCode][n2o]
                                )}
                              </TableCell>
                              <TableCell
                                key={`${row.label} ch4 coeffs`}
                                className={`${row.cellClassName} coeff-right-border-cell`}
                              >
                                {formatCoefficient(
                                  coefficients[row.fuelCode][ch4]
                                )}
                              </TableCell>
                              <TableCell
                                key={`${row.label} so2 coeffs`}
                                className={row.cellClassName}
                              >
                                {formatCoefficient(
                                  coefficients[row.fuelCode][so2]
                                )}
                              </TableCell>
                              <TableCell
                                key={`${row.label} nox coeffs`}
                                className={row.cellClassName}
                              >
                                {formatCoefficient(
                                  coefficients[row.fuelCode][nox]
                                )}
                              </TableCell>
                            </>
                          )}
                        </TableRow>
                      ) : null
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12}>
              <CFBox>
                <CFTypography className="footnotes">
                  <br />
                  <sup>3</sup>&nbsp;The fraction of combustion variable from the
                  equation is left off here because it is so close to 1.0 for
                  the fuels reported on here.
                  <br />
                  <sup>4</sup>&nbsp;Voluntary Reporting of Greenhouse Gases
                  Program Guidelines.{" "}
                  <CFLink
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.eia.gov/environment/pdfpages/0608s(2009)index.php"
                  >
                    https://www.eia.gov/environment/pdfpages/0608s(2009)index.php.
                  </CFLink>
                  <br />
                  <sup>5</sup>&nbsp;U.S. Environmental Protection Agency
                  eGRID2022{" "}
                  <CFLink
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.epa.gov/egrid/download-data"
                  >
                    https://www.epa.gov/egrid/download-data
                  </CFLink>
                </CFTypography>
              </CFBox>
            </Grid>
          </Grid>
        </CFBox>
      </CFBox>
    </CFarmPage>
  );
}

export default QuickEnergy;
