// React
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

// MUI
import {
  Grid,
  Dialog,
  Divider,
  IconButton,
  Tooltip,
  Box,
  Typography,
} from "@mui/material";

import { Close } from "@mui/icons-material";

import colors from "assets/theme/base/colors";

// Our Components
import CFBox from "components/CFBox";
import CFButton from "components/CFButton";

import CFStep from "components/CFarmParts/CFStep";
import { jsonCopy } from "utils/generic";
import CFLoading from "components/CFLoading";
import CroplandAccordionSection from "components/CroplandsComponents/CroplandAccordionSection/CroplandAccordionSection";
import UserGuidePopup from "components/CFUserGuidePopup/UserGuidePopup";

import PlantingAndHarvest from "./SubComponents/PlantingAndHarvest";
import TillageAndPlantingImplements from "./SubComponents/TillageAndPlantingImplements";
import CropAndOtherFieldOperation from "./SubComponents/CropAndOtherFieldOperation";
import FertilizerApplication from "./SubComponents/FertilizerApplication";
import IrrigationEvents from "./SubComponents/IrrigationEvents";
import ManureApplication from "./SubComponents/ManureApplication";
import LimingEvents from "./SubComponents/LimingEvents";

import GrazingEvents from "./SubComponents/GrazingEvents";
import NutrientCalculator from "./SubComponents/NutrientCalculator";
import BioCharAmendments from "./SubComponents/BioCharAmendments";

export default function EditCropCard({
  isOpen,
  setIsOpen,
  stepNumber,
  updateCropEvent,
  checkIfNeedToClear,
  allManagementOptions,
  cropEvent,
  field,
  fertilizerRatios,
  tillageImplements,
  tillageIntensities,
  previousPlantingDate,
  previousCrop,
  scenarioName,
  fullCropYear,
}) {
  // on previousPlantingDate: We can't change the crop in this EditCropCard so we should be able to grab the previousPlantingDate from the CropRotationManagment Layer
  // previousPlantingDate is null if there isn't 1, or the planting date of the same crop as this crop event's crop, from the prior year, (either the prior year)

  const fieldName = field.name;

  const [saveEnabled, setSaveEnabled] = useState(false);
  const [plantingValid, setPlantingValid] = useState(false);
  const [harvestValid, setHarvestValid] = useState(false);

  // I opted for a single project state object where we just overwrite the necessary fields,
  // instead of separate state variables for name, activities, etc
  const [CropEvent, setCropEvent] = useState({});
  const [OriginalCropEvent, setOriginalCropEvent] = useState({});

  useEffect(() => {
    if (isOpen) {
      jsonCopy(cropEvent).then((res) => {
        if (!res.irrigations) {
          res.irrigations = [];
        }
        if (!res.manureApplications) {
          res.manureApplications = [];
        }
        if (!res.nitrogenApplications) {
          res.nitrogenApplications = [];
        }
        if (!res.otherFieldOperations) {
          res.otherFieldOperations = [];
        }
        if (!res.grazings) {
          res.grazings = [];
        }
        if (!res.harvests) {
          res.harvests = [];
        }
        setCropEvent({ ...res });
      });

      if (!OriginalCropEvent.id) {
        setSaveEnabled(false);
        jsonCopy(cropEvent).then((res) => setOriginalCropEvent({ ...res }));
      }
    }
  }, [isOpen]);

  const sendingHarvUp = (harvV) => {
    setHarvestValid(harvV);
  };
  const checkForSave = (newCropEvent) => {
    let setsave = false;

    Object.keys(newCropEvent).forEach((key, index) => {
      for (let i = 0; i < 3; i += 1) {
        if (key !== "name" && newCropEvent[key] !== OriginalCropEvent[key]) {
          setsave = true;

          break;
        }
      }
    });
    setsave =
      setsave && (CropEvent?.id === 229 || (plantingValid && harvestValid));

    setSaveEnabled(setsave);
  };

  useEffect(() => {
    checkForSave(CropEvent);
  }, [CropEvent, plantingValid, harvestValid]);

  const SetPlantingProperties = (newProperties) => {
    const newCropEvent = { ...CropEvent };
    newCropEvent.plantingDate = newProperties.plantingDate;
    newCropEvent.harvests = [...newProperties.harvests];
    newCropEvent.continueFromPrevious = newProperties.continueFromPrevious;
    newCropEvent.treeEvent = newProperties.treeEvent;
    jsonCopy(newCropEvent).then((res) => {
      setCropEvent({ ...res });
    });
  };

  const SetTillageProperties = (newProperties) => {
    const newCropEvent = { ...CropEvent };
    newCropEvent.postHarvestTillage = { ...newProperties.postHarvestTillage };
    newCropEvent.prePlantingTillage = { ...newProperties.prePlantingTillage };
    newCropEvent.seedDrill = newProperties.seedDrill;

    jsonCopy(newCropEvent).then((res) => {
      setCropEvent({ ...res });
    });
  };

  const SetNitrogenApplications = (newProperties) => {
    const newCropEvent = { ...CropEvent };
    newCropEvent.nitrogenApplications = [...newProperties.nitrogenApplications];
    jsonCopy(newCropEvent).then((res) => {
      setCropEvent({ ...res });
    });
  };

  const SetIrrigationEvents = (newProperties) => {
    const newCropEvent = { ...CropEvent };
    newCropEvent.irrigations = [...newProperties.irrigations];
    jsonCopy(newCropEvent).then((res) => {
      setCropEvent({ ...res });
    });
  };

  const SetLiming = (newProperties) => {
    const newCropEvent = { ...CropEvent };
    newCropEvent.liming = { ...newProperties.liming };
    jsonCopy(newCropEvent).then((res) => {
      setCropEvent({ ...res });
    });
  };
  const SetGrazingEvents = (newProperties) => {
    const newCropEvent = { ...CropEvent };
    newCropEvent.grazings = [...newProperties.grazings];
    jsonCopy(newCropEvent).then((res) => {
      setCropEvent({ ...res });
    });
  };

  const SetBiocharAmendment = (newProperties) => {
    const newCropEvent = { ...CropEvent };
    newCropEvent.bioCharAmendments = [...newProperties.bioCharAmendments];
    if (newCropEvent.bioCharAmendments.hcRatio > 1) {
      newCropEvent.bioCharAmendments.hcRatio =
        newProperties.bioCharAmendments.hcRatio / 100.0;
    }
    jsonCopy(newCropEvent).then((res) => {
      setCropEvent({ ...res });
    });
  };

  const SetManureApplications = (newProperties) => {
    const newCropEvent = { ...CropEvent };
    newCropEvent.manureApplications = [...newProperties.manureApplications];
    jsonCopy(newCropEvent).then((res) => {
      setCropEvent({ ...res });
    });
  };

  const SetOtherFieldOperationsProperties = (newProperties) => {
    const newCropEvent = { ...CropEvent };
    newCropEvent.otherFieldOperations = [...newProperties.otherFieldOperations];
    jsonCopy(newCropEvent).then((res) => {
      setCropEvent({ ...res });
    });
  };

  // On form close
  const handleClose = () => {
    checkIfNeedToClear();
    setOriginalCropEvent({});
    setIsOpen(false);
  };

  const saveClicked = () => {
    jsonCopy(CropEvent).then((res) => {
      updateCropEvent({ ...res });
    });
    setOriginalCropEvent({});
    setIsOpen(false);
  };

  const catchsetPlantingValid = (isValid) => {
    setPlantingValid(isValid);
  };

  return (
    <Dialog
      open={isOpen}
      fullScreen
      PaperProps={{
        style: { borderRadius: ".3em" },
      }}
      sx={{ padding: "3em", zIndex: 5 }}
      onClose={handleClose}
    >
      <IconButton
        onClick={() => handleClose()}
        style={{
          position: "absolute",
          right: 0,
          margin: ".5em",
          padding: 0,
          // paddingLeft: ".5em",
        }}
      >
        <Tooltip title="Close">
          <Close />
        </Tooltip>
      </IconButton>
      <CFBox sx={{ overflowY: "scroll", height: "100%" }}>
        <Grid container item xs={12}>
          <CFBox sx={{ padding: "2em" }}>
            <Grid container direction="row">
              {/* Window Header */}
              <CFStep
                stepLabel={`Define ${scenarioName} Management`}
                stepNumber={stepNumber}
                helpText={`For a step-by-step guide on how to enter detailed baseline management, visit the "Entering Management Details" section of the COMET-Farm User Guide.`}
              />

              <Grid
                item
                container
                xs={12}
                id="step1Box"
                display="flex"
                alignItems="start"
                justifyContent="start"
              >
                <Typography pl={2} py={2} component="div">
                  Define
                  <Box fontWeight="fontWeightMedium" display="inline">
                    {` ${CropEvent.name} `}
                  </Box>
                  {scenarioName} Management in year{" "}
                  <Box fontWeight="fontWeightMedium" display="inline">
                    {" "}
                    {` ${CropEvent.cropYear} `}
                  </Box>{" "}
                  for{" "}
                  <Box fontWeight="fontWeightMedium" display="inline">
                    {` ${fieldName}`}
                  </Box>
                  .
                  <UserGuidePopup
                    content={
                      <div>
                        <p>
                          When entering management details, users will be asked
                          to enter specific information such as planting/harvest
                          dates, fertilizer application rates, and more. It is
                          best to enter accurate dates, especially regarding
                          fertilizer additions and irrigation. If unsure, users
                          are encouraged to enter details the best they can by
                          narrowing down to the week and entering the date from
                          the middle of the week when the event is estimated to
                          have occurred.
                        </p>{" "}
                        <br />
                        <p>
                          {" "}
                          When exact dates are not entered, the uncertainty of
                          the estimated emissions/reductions for scenarios
                          increases. For example, if the user inputs a general
                          planting date that is two weeks prior to the actual
                          planting date, the estimates will indicate two
                          additional weeks of plant growth that did not occur.
                        </p>
                      </div>
                    }
                    userGuideLabel="What if I don’t know my exact management details?"
                    underlineLink
                    label="What if I don't know my exact management details?"
                    variant="h3"
                    sx={{ marginTop: ".2em", padding: ".1em" }}
                  />
                </Typography>
              </Grid>
              {CropEvent && CropEvent.id && (
                <Grid container direction="column">
                  {CropEvent.id !== 229 && (
                    <CFBox>
                      <CroplandAccordionSection
                        summary={
                          <div>
                            Planting and Harvest{" "}
                            <div style={{ display: "inline" }}>✵</div>
                          </div>
                        }
                        startState={false}
                      >
                        <PlantingAndHarvest
                          CropEvent={CropEvent}
                          SetPropertyFunction={SetPlantingProperties}
                          field={field}
                          previousPlantingDate={previousPlantingDate}
                          fullCropYear={fullCropYear}
                          setPlantingValid={catchsetPlantingValid}
                          setHarvestValid={sendingHarvUp}
                          previousCrop={previousCrop}
                        />
                      </CroplandAccordionSection>
                      <Divider />{" "}
                    </CFBox>
                  )}

                  <CroplandAccordionSection
                    summary="Tillage System Intensity and Field Operations"
                    startState={false}
                  >
                    <CFBox sx={{ position: "relative" }}>
                      {CropEvent.id === 229 && (
                        <CFLoading
                          local
                          message="Tillage intensity disabled for fallow"
                          showCircle={false}
                        />
                      )}{" "}
                      <TillageAndPlantingImplements
                        CropEvent={CropEvent}
                        SetPropertyFunction={SetTillageProperties}
                        tillages={allManagementOptions.tillages}
                        field={field}
                        tillageImplements={tillageImplements}
                        tillageIntensities={tillageIntensities}
                      />
                    </CFBox>
                    <Divider />
                    <CropAndOtherFieldOperation
                      CropEvent={CropEvent}
                      SetPropertyFunction={SetOtherFieldOperationsProperties}
                      otherFieldOperations={
                        allManagementOptions.otherFieldOperations
                      }
                      yesBurnList={allManagementOptions.burn_ok_list}
                      FieldName={fieldName}
                      fullCropYear={fullCropYear}
                    />
                  </CroplandAccordionSection>

                  <Divider />
                  <CroplandAccordionSection
                    summary="Fertilizer and/or Organic Amendment Applications"
                    startState={false}
                  >
                    <NutrientCalculator
                      CropEvent={CropEvent}
                      fertilizerRatios={fertilizerRatios}
                    />
                    <FertilizerApplication
                      CropEvent={CropEvent}
                      SetPropertyFunction={SetNitrogenApplications}
                      nitrogenApplications={
                        allManagementOptions.nitrogenApplications
                      }
                      FieldName={fieldName}
                      fertilizerRatios={fertilizerRatios}
                    />
                    <Divider />
                    <ManureApplication
                      CropEvent={CropEvent}
                      SetPropertyFunction={SetManureApplications}
                      manureApplications={
                        allManagementOptions.manureApplications
                      }
                      FieldName={fieldName}
                      fertilizerRatios={fertilizerRatios}
                    />
                    <Divider />
                    <BioCharAmendments
                      CropEvent={CropEvent}
                      SetPropertyFunction={SetBiocharAmendment}
                      feedStock={allManagementOptions.feedStock}
                      productionTechnology={
                        allManagementOptions.productionTechnology
                      }
                    />
                  </CroplandAccordionSection>
                  <Divider />
                  <CroplandAccordionSection
                    summary="Irrigation Events"
                    startState={false}
                  >
                    <IrrigationEvents
                      CropEvent={CropEvent}
                      SetPropertyFunction={SetIrrigationEvents}
                      FieldName={fieldName}
                    />
                  </CroplandAccordionSection>
                  <Divider />
                  <CFBox sx={{ position: "relative" }}>
                    <CroplandAccordionSection
                      summary="Grazing Events"
                      startState={false}
                    >
                      {CropEvent.id === 229 && (
                        <CFLoading
                          local
                          message="Grazing disabled for fallow"
                          showCircle={false}
                        />
                      )}{" "}
                      <GrazingEvents
                        CropEvent={CropEvent}
                        SetPropertyFunction={SetGrazingEvents}
                        FieldName={fieldName}
                      />
                    </CroplandAccordionSection>
                  </CFBox>
                  <Divider />
                  <CroplandAccordionSection
                    summary={
                      <div style={{ display: "flex" }}>Liming Event </div>
                    }
                    startState={false}
                  >
                    <LimingEvents
                      CropEvent={CropEvent}
                      SetPropertyFunction={SetLiming}
                      limingApplications={
                        allManagementOptions.limingApplications
                      }
                      FieldName={fieldName}
                    />
                    <Divider />
                  </CroplandAccordionSection>
                </Grid>
              )}
            </Grid>
            <Grid container direction="row">
              <Grid
                item
                container
                xs={1}
                py={2}
                px={2}
                justifyContent="space-between"
              >
                <CFButton onClick={handleClose} color="secondary">
                  {" "}
                  Close
                </CFButton>
              </Grid>
              <Grid xs={10} item />
              <Grid
                item
                container
                xs={1}
                py={2}
                px={2}
                justifyContent="space-between"
              >
                <CFButton
                  onClick={() => saveClicked()}
                  color="progress"
                  disabled={!saveEnabled}
                >
                  {" "}
                  Save
                </CFButton>
              </Grid>
            </Grid>
          </CFBox>
        </Grid>
      </CFBox>
    </Dialog>
  );
}

EditCropCard.defaultProps = {
  isOpen: PropTypes.bool,
  cropEvent: PropTypes.shape({}),
  setIsOpen: PropTypes.func.isRequired,
  stepNumber: PropTypes.string.isRequired,
};
