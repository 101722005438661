// React
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

// MUI
import {
  Grid,
  Dialog,
  Divider,
  IconButton,
  Tooltip,
  Checkbox,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Close } from "@mui/icons-material";
import { pink, red, grey } from "@mui/material/colors";
import dayjs from "dayjs";
import colors from "assets/theme/base/colors";

// Our Components
import CFBox from "components/CFBox";
import CFTypography from "components/CFTypography";
import CFButton from "components/CFButton";

import CFStep from "components/CFarmParts/CFStep";

export default function ReviewInputsCard({
  isOpen,
  setIsOpen,
  baselineManagement,
  locationName,
  scenarioName,
  stepNumber,
}) {
  const [orderedTimelineThings, setOrderedTimelineThings] = useState([]);
  const [showYieldInfor, setShowYieldInfor] = useState(false);

  useEffect(() => {
    const unorderedLocalTimelineThing = [];
    const alreadyHadPlantingEvents = [];
    let showYieldInfo = false;
    for (const year in baselineManagement) {
      if (Object.prototype.hasOwnProperty.call(baselineManagement, year)) {
        const cropYearThing = baselineManagement[year];
        for (let cropNum = 0; cropNum < 3; cropNum += 1) {
          const cropEvent = cropYearThing.crops[cropNum];
          if (cropEvent && cropEvent?.id !== 0 && cropEvent.plantingDate) {
            try {
              const plantingDate = dayjs(cropEvent.plantingDate);
              // adding plantings
              if (
                alreadyHadPlantingEvents.indexOf(
                  `${plantingDate.format("LL")}_${cropEvent.name}`
                ) < 0
              ) {
                unorderedLocalTimelineThing.push({
                  date: plantingDate,
                  operation: "Planting",
                  crop: cropEvent.name,
                  method: "",
                });
                alreadyHadPlantingEvents.push(
                  `${plantingDate.format("LL")}_${cropEvent.name}`
                );
              } else {
                const continueDate = plantingDate.year(year);
                unorderedLocalTimelineThing.push({
                  date: continueDate,
                  operation: "Continue Crop",
                  crop: cropEvent.name,
                  method: "",
                });
              }

              let finalHarvestDate = dayjs(plantingDate);
              // adding harvests
              for (let i = 0; i < cropEvent.harvests.length; i += 1) {
                const newHarv = dayjs(cropEvent.harvests[i].harvestDate);
                if (newHarv > finalHarvestDate) {
                  finalHarvestDate = dayjs(newHarv);
                }
                if (cropEvent.harvests[i].isGrainHarvest) {
                  unorderedLocalTimelineThing.push({
                    date: newHarv,
                    operation: "Grain/Fruit Harvest",
                    crop: cropEvent.name,
                    method: `${cropEvent.harvests[i].strawStoverHayPct}% dry matter removal`,
                  });
                } else {
                  unorderedLocalTimelineThing.push({
                    date: newHarv,
                    operation: "Harvest",
                    crop: cropEvent.name,
                    method: `${cropEvent.harvests[i].strawStoverHayPct}% dry matter removal`,
                  });
                }
                unorderedLocalTimelineThing.push({
                  date: newHarv,
                  operation: (
                    <span>
                      Yield<sup style={{ fontSize: ".5em" }}>1</sup>
                    </span>
                  ),
                  operationPrint: "Yield",

                  crop: cropEvent.name,
                  method: `${cropEvent.harvests[i].harvestYield} Bushels/ac`,
                });
                showYieldInfo = true;
              }

              // grazings
              for (let i = 0; i < cropEvent.grazings.length; i += 1) {
                const theGraze = cropEvent.grazings[i];
                const gstart = dayjs(theGraze.grazingStart);
                const gend = dayjs(theGraze.grazingEnd);

                unorderedLocalTimelineThing.push({
                  date: gstart,
                  operation: "Grazing Start",
                  crop: cropEvent.name,
                  method: `Start ${theGraze.utilizationPct}% utilization with rest period of ${theGraze.restPeriod}`,
                });
                unorderedLocalTimelineThing.push({
                  date: gend,
                  operation: "Grazing End",
                  crop: cropEvent.name,
                  method: `End ${theGraze.utilizationPct}% utilization with rest period of ${theGraze.restPeriod}`,
                });
              }
              // irrigations

              if (cropEvent.id === 244) {
                for (let i = 0; i < cropEvent.irrigations.length; i += 1) {
                  const theIrrigation = cropEvent.irrigations[i];

                  const irrStart = dayjs(theIrrigation.applicationDate);
                  const irrEnd = dayjs(theIrrigation.floodEndDate);

                  unorderedLocalTimelineThing.push({
                    date: irrStart,
                    operation: "Flood Start",
                    crop: cropEvent.name,
                    method: ``,
                  });
                  unorderedLocalTimelineThing.push({
                    date: irrEnd,
                    operation: "Drain",
                    crop: cropEvent.name,
                    method: ``,
                  });
                }
              } else {
                for (let i = 0; i < cropEvent.irrigations.length; i += 1) {
                  const theIrrigation = cropEvent.irrigations[i];
                  const irrStart = dayjs(theIrrigation.applicationDate);
                  const irrEnd = dayjs(theIrrigation.endDate);
                  if (theIrrigation.autoIrrigation) {
                    unorderedLocalTimelineThing.push({
                      date: irrStart,
                      operation: "Start auto-irrigation",
                      crop: cropEvent.name,
                      method: `Start autoirrigation with soil threshold ${theIrrigation.soilThreshold}`,
                    });

                    unorderedLocalTimelineThing.push({
                      date: irrEnd,
                      operation: "End auto-irrigation",
                      crop: cropEvent.name,
                      method: `End autoirrigation with soil threshold ${theIrrigation.soilThreshold}`,
                    });
                  } else {
                    unorderedLocalTimelineThing.push({
                      date: irrStart,
                      operation: "Irrigation Application",
                      crop: cropEvent.name,
                      method: `${theIrrigation.totalApplied} inches`,
                    });

                    const daysAdd = theIrrigation.daysBetweenIrrigation + 1;

                    let currentIrrDate = irrStart.add(daysAdd, "day");
                    while (currentIrrDate < irrEnd) {
                      unorderedLocalTimelineThing.push({
                        date: currentIrrDate,
                        operation: "Irrigation Application",
                        crop: cropEvent.name,
                        method: `${theIrrigation.totalApplied} inches`,
                      });
                      currentIrrDate = currentIrrDate.add(daysAdd, "day");
                    }
                  }
                }
              }
              // liming
              if (
                cropEvent.liming.applicationMethod &&
                cropEvent.liming.applicationMethod.name &&
                cropEvent.liming.applicationMethod.description !== "None" &&
                cropEvent.liming.rate !== 0
              ) {
                unorderedLocalTimelineThing.push({
                  date: dayjs(cropEvent.liming.limingDate),
                  operation: "Liming",
                  crop: cropEvent.name,
                  method: `${cropEvent.liming.rate} ${cropEvent.liming.applicationMethod.description}`,
                });
              }
              // manure applications
              for (let i = 0; i < cropEvent.manureApplications.length; i += 1) {
                const theManureApplication = cropEvent.manureApplications[i];

                unorderedLocalTimelineThing.push({
                  date: dayjs(theManureApplication.applicationDate),
                  operation: "Manure Application",
                  crop: cropEvent.name,
                  method: `${theManureApplication.totalApplied} lbs/ac of ${theManureApplication.manureType.name}`,
                });
              }
              // nitrogen applications
              for (
                let i = 0;
                i < cropEvent.nitrogenApplications.length;
                i += 1
              ) {
                const theNitrogenApplication =
                  cropEvent.nitrogenApplications[i];

                unorderedLocalTimelineThing.push({
                  date: dayjs(theNitrogenApplication.applicationDate),
                  operation: "Fertilizer Application",
                  crop: cropEvent.name,
                  method: `${theNitrogenApplication.totalNApplied} lbs N/ac of ${theNitrogenApplication.fertilizerType.name}`,
                });
              }

              // preplant tillage
              if (cropEvent.prePlantingTillage?.tillage) {
                unorderedLocalTimelineThing.push({
                  date: plantingDate.add(-1, "day"),
                  operation: "PrePlanting tillage",
                  crop: cropEvent.name,
                  method: `${cropEvent.prePlantingTillage.tillage.name}`,
                });
              }

              // post harv tillage
              if (
                cropEvent.postHarvestTillage?.tillage &&
                finalHarvestDate > plantingDate
              ) {
                unorderedLocalTimelineThing.push({
                  date: finalHarvestDate.add(1, "day"),
                  operation: "PostHarvest tillage",
                  crop: cropEvent.name,
                  method: `${cropEvent.postHarvestTillage.tillage.name}`,
                });
              }
              // other field operations
              for (
                let i = 0;
                i < cropEvent.otherFieldOperations.length;
                i += 1
              ) {
                const theTill = cropEvent.otherFieldOperations[i];
                unorderedLocalTimelineThing.push({
                  date: dayjs(theTill.tillageDate),
                  operation: "Field operation",
                  crop: cropEvent.name,
                  method: `${theTill.tillage.name}`,
                });
              }
            } catch (e) {
              console.log(e);
            }
          }
        }
        setShowYieldInfor(showYieldInfo);
        unorderedLocalTimelineThing.sort((a, b) => (a.date > b.date ? 1 : -1));
        setOrderedTimelineThings([...unorderedLocalTimelineThing]);
      }
    }
  }, [baselineManagement]);

  const handleClose = () => {
    setIsOpen(false);
  };

  const MakeStringRow = (row) =>
    `${row.crop},${row.date.format("LL")},${
      row.operationPrint ?? row.operation
    }:  ${row.method}`;

  const printThis = () => {
    const data = new Blob(
      [
        `Field Name: ${locationName}\n`,
        `Scenario Name: ${scenarioName}\n`,
        "Crop,Date,Operation,Method\n",
        orderedTimelineThings.map((x) => MakeStringRow(x)).join("\n"),
      ],
      {
        type: "text/plain",
      }
    );
    const csvURL = window.URL.createObjectURL(data);
    const tempLink = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute(
      "download",
      `${`Review_Management_${scenarioName}_${locationName}`.replace(
        /[^a-zA-Z0-9]/g,
        ""
      )}.csv`
    );
    tempLink.click();
  };

  return (
    <Dialog
      open={isOpen}
      fullScreen
      sx={{ padding: "3em", zIndex: 5 }}
      onClose={handleClose}
    >
      <CFBox sx={{ margin: "2em", position: "absolute", right: "2em" }}>
        <CFButton color="secondary" onClick={printThis}>
          Download CSV
        </CFButton>
      </CFBox>
      <IconButton
        onClick={() => handleClose()}
        style={{
          position: "absolute",
          right: ".5em",
          margin: ".5em",
          padding: 0,
        }}
      >
        <Tooltip title="Close">
          <Close />
        </Tooltip>
      </IconButton>

      <Grid container item xs={12} sx={{ overflowY: "auto" }}>
        <CFBox sx={{ padding: "2em" }}>
          <Grid container direction="row">
            {/* Window Header */}
            <Grid
              container
              item
              xs={12}
              sx={{ paddingLeft: "1em", paddingTop: "1em" }}
            />
            <CFStep
              stepLabel={`Review Management for ${scenarioName} on ${locationName}`}
              stepNumber={stepNumber}
              helpText="Review all the inputs arranged chronologically"
            />

            <Grid
              container
              item
              xs={12}
              sx={{ paddingLeft: "1em", paddingTop: "1em" }}
            >
              <CFTypography fontWeight="bold">
                Management Information
              </CFTypography>
              <Grid container item xs={12} sx={{ paddingLeft: "1em" }}>
                <Grid container item xs={12}>
                  <CFTypography fontWeight="bold">Field Name: </CFTypography>{" "}
                  <CFTypography>{` ${locationName}`}</CFTypography>
                </Grid>
                <Grid container item xs={12}>
                  <CFTypography fontWeight="bold">Scenario: </CFTypography>{" "}
                  <CFTypography>{` ${scenarioName}`}</CFTypography>
                </Grid>
                <Grid item container xs={12} sx={{ paddingLeft: "1em" }}>
                  <Grid item container xs={2}>
                    <CFTypography fontWeight="bold"> Crop</CFTypography>
                  </Grid>
                  <Grid item container xs={2}>
                    <CFTypography fontWeight="bold"> Date</CFTypography>
                  </Grid>
                  <Grid item container xs={3}>
                    <CFTypography fontWeight="bold"> Operation</CFTypography>
                  </Grid>
                  <Grid item container xs={5}>
                    <CFTypography fontWeight="bold"> Method</CFTypography>
                  </Grid>

                  {orderedTimelineThings.map((time, ind) => (
                    <Grid
                      item
                      container
                      key={`${time.date.toISOString()}_${time.crop}_${
                        time.operation
                      }_${time.method}`}
                      sx={{
                        padding: ".5em",
                        backgroundColor:
                          ind % 2 === 0
                            ? colors.light.focus
                            : colors.light.main,
                      }}
                      xs={12}
                    >
                      <Grid item container xs={2}>
                        {time.crop}{" "}
                      </Grid>
                      <Grid item container xs={2}>
                        {time.date.format("LL")}
                      </Grid>
                      <Grid item container xs={3}>
                        {time.operation}{" "}
                      </Grid>
                      <Grid item container xs={5}>
                        {time.method}{" "}
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
                {showYieldInfor && (
                  <sub style={{ fontSize: ".5em", bottom: 0 }}>
                    {" "}
                    1. User-entered yield will only be used if a burning event
                    or rice is included in a rotation.
                  </sub>
                )}
              </Grid>
            </Grid>
          </Grid>
        </CFBox>
      </Grid>
    </Dialog>
  );
}
