import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Grid } from "@mui/material";

import { updateLoading } from "store/reducers/mask";
import { popupError } from "utils/generic";
import { post } from "utils/api";

import CFStep from "components/CFarmParts/CFStep";
import CFBox from "components/CFBox";
import CFTabView from "components/CFTabView";
import CFDialog from "components/CFDialog";

import PreDefinedUserHelp from "assets/cFarmImages/HelpImages/defined_scenario_agroforestry_help_docs.webp";
import UserDefinedHelp from "assets/cFarmImages/HelpImages/own_info_agroforestry_help_docs.webp";

import {
  AGROFORESTRY_SCENARIO_LIST,
  AGROFORESTRY_LIST_SPECIES_GROUPS,
  AGROFORESTRY_UPDATE_USER_SCENARIO,
  AGROFORESTRY_UPDATE_AND_SAVE,
  AGROFORESTRY_DELETE_DETAILS,
  AGROFORESTRY_REMOVE_OLD,
} from "constants/api";
import { fetchUser } from "actions/user";

import CFTypography from "components/CFTypography";
import CFButton from "components/CFButton";

import UserDefined from "./UserDefined";
import PreDefined from "./PreDefined";

function AgroforestryScenario({
  stepNumber,
  agroforestryOperation,
  setAgroforestryOp,
}) {
  // User info
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user);

  // Add detail to project
  const addScenarioAndSave = (newDetail, userScenarioName) => {
    dispatch(updateLoading({ loading: true }));

    post(AGROFORESTRY_UPDATE_AND_SAVE, {
      UserId: currentUser.id,
      ScenarioName: "Baseline",
      Detail: newDetail,
      UserDefinedName: userScenarioName,
    })
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          dispatch(updateLoading({ loading: false }));
        } else {
          setAgroforestryOp({
            ProjectId: res.data.projectId,
            ScenarioId: res.data.scenarioId,
            ScenarioName: res.data.scenarioName,
            UserDefinedName: res.data.userDefinedName,
            StateName: res.data.stateName,
            StateId: res.data.stateId,
            CountyName: res.data.countyName,
            CountyId: res.data.countyId,
            DetailsList: res.data.scenarioDetails
              ? res.data.scenarioDetails.map((detail) => ({
                  Id: detail.detailId,
                  Group: {
                    Id: detail.groupId,
                    CommonName: detail.groupName,
                  },
                  DBH: detail.dbh,
                  AmountPerAcre: detail.amountPerAcre,
                  HasType: detail.hasType,
                  TypeLookUp: {
                    Id: detail.typeLookUpId,
                    Afpractice: detail.typeLookUpName,
                  },
                  Type: { Id: detail.typeId },
                  Age: detail.age,
                  Size: detail.size,
                  HasAge: detail.hasAge,
                  Scenario: { Id: detail.scenarioId },
                  IsNew: detail.isNew,
                }))
              : [],
          });
          dispatch(fetchUser(currentUser));
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(updateLoading({ loading: false }));
      })
      .finally(() => {
        dispatch(updateLoading({ loading: false }));
      });
  };

  // Remove detail from project
  const removeDetails = (detailsList) => {
    dispatch(updateLoading({ loading: true }));

    post(AGROFORESTRY_DELETE_DETAILS, {
      UserId: currentUser.id,
      ScenarioName: "Baseline",
      Details: detailsList,
    })
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          dispatch(updateLoading({ loading: false }));
        } else {
          setAgroforestryOp({
            ProjectId: res.data.projectId,
            ScenarioId: res.data.scenarioId,
            ScenarioName: res.data.scenarioName,
            UserDefinedName: res.data.userDefinedName,
            StateName: res.data.stateName,
            StateId: res.data.stateId,
            CountyName: res.data.countyName,
            CountyId: res.data.countyId,
            DetailsList: res.data.scenarioDetails
              ? res.data.scenarioDetails.map((detail) => ({
                  Id: detail.detailId,
                  Group: {
                    Id: detail.groupId,
                    CommonName: detail.groupName,
                  },
                  DBH: detail.dbh,
                  AmountPerAcre: detail.amountPerAcre,
                  HasType: detail.hasType,
                  TypeLookUp: {
                    Id: detail.typeLookUpId,
                    Afpractice: detail.typeLookUpName,
                  },
                  Type: { Id: detail.typeId },
                  Age: detail.age,
                  Size: detail.size,
                  HasAge: detail.hasAge,
                  Scenario: { Id: detail.scenarioId },
                  IsNew: detail.isNew,
                }))
              : [],
          });
          dispatch(fetchUser(currentUser));
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(updateLoading({ loading: false }));
      })
      .finally(() => {
        dispatch(updateLoading({ loading: false }));
      });
  };

  // Remove any old species/groups from the scenario
  function RemoveOldData() {
    dispatch(updateLoading({ loading: true }));

    post(AGROFORESTRY_REMOVE_OLD, {
      UserId: currentUser.id,
      ScenarioName: "Baseline",
    })
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          dispatch(updateLoading({ loading: false }));
        } else {
          setAgroforestryOp({
            ProjectId: res.data.projectId,
            ScenarioId: res.data.scenarioId,
            ScenarioName: res.data.scenarioName,
            UserDefinedName: res.data.userDefinedName,
            StateName: res.data.stateName,
            StateId: res.data.stateId,
            CountyName: res.data.countyName,
            CountyId: res.data.countyId,
            DetailsList: res.data.scenarioDetails
              ? res.data.scenarioDetails.map((detail) => ({
                  Id: detail.detailId,
                  Group: {
                    Id: detail.groupId,
                    CommonName: detail.groupName,
                  },
                  DBH: detail.dbh,
                  AmountPerAcre: detail.amountPerAcre,
                  HasType: detail.hasType,
                  TypeLookUp: {
                    Id: detail.typeLookUpId,
                    Afpractice: detail.typeLookUpName,
                  },
                  Type: { Id: detail.typeId },
                  Age: detail.age,
                  Size: detail.size,
                  HasAge: detail.hasAge,
                  Scenario: { Id: detail.scenarioId },
                  IsNew: detail.isNew,
                }))
              : [],
          });
          dispatch(fetchUser(currentUser));
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(updateLoading({ loading: false }));
      })
      .finally(() => {
        dispatch(updateLoading({ loading: false }));
      });
  }

  // Duplicate scenario popup
  const [localPopupState, updateLocalPopup] = useState({});
  const [loadingMessage, setLoadingMessage] = useState(
    "Please Select a Location..."
  );

  // Pre-defined scenario info
  const [editingScenario, updateEditScenario] = useState({
    scenarioName: "",
    age: 0,
    size: 0,
  });
  const [availableScenarios, setAvailableScenarios] = useState([]);
  const fetchScenarioList = () => {
    dispatch(updateLoading({ loading: true }));

    post(AGROFORESTRY_SCENARIO_LIST, {
      UserId: currentUser.id,
      ScenarioName: "Baseline",
      ProjectId: currentUser.activeProject.id,
    })
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          dispatch(updateLoading({ loading: false }));
        } else {
          setAvailableScenarios(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(updateLoading({ loading: false }));
      })
      .finally(() => {
        dispatch(updateLoading({ loading: false }));
      });
  };
  function GetScenarioId(preDefinedScenarioName) {
    // Find a scenario with a matching name from the list
    let tempId = 0;

    availableScenarios.forEach((scen) => {
      if (scen.afpractice === preDefinedScenarioName) {
        tempId = scen.id;
      }
    });

    return tempId;
  }
  const AddPreDefinedScenario = () => {
    // Create new scenario object and add to list, then reset the state
    const newScenarioObj = {
      Id: 0,
      Group: { Id: 510, CommonName: "" },
      DBH: 0,
      AmountPerAcre: 0,
      HasType: true,
      TypeLookUp: {
        Id: GetScenarioId(editingScenario.scenarioName),
        Afpractice: editingScenario.scenarioName,
      },
      Type: { Id: 11111 },
      Age: editingScenario.age,
      Size: editingScenario.size,
      HasAge: false,
      Scenario: { Id: 0 },
    };

    addScenarioAndSave(newScenarioObj, "");

    updateEditScenario({
      scenarioName: "placeholder",
      age: 0,
      size: 0,
    });
  };

  // User-defined scenario info
  const [editingSpecies, updateEditSpecies] = useState({
    speciesGroup: "placeholder",
    dbh: 0,
    age: 0,
    totalNumber: 0,
  });
  const [availableSpeciesGroups, setAvailableSpeciesGroups] = useState([]);
  const [userDefinedName, setUserDefinedName] = useState(
    agroforestryOperation.UserDefinedName ?? ""
  );
  // Update the user-defined scenario (adding a species group will also do this)
  const UpdateUserScenario = () => {
    dispatch(updateLoading({ loading: true }));

    post(AGROFORESTRY_UPDATE_USER_SCENARIO, {
      UserId: currentUser.id,
      ScenarioName: "Baseline",
      UserDefinedName: userDefinedName,
    })
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          dispatch(updateLoading({ loading: false }));
        } else {
          setAgroforestryOp({
            ProjectId: res.data.projectId,
            ScenarioId: res.data.scenarioId,
            ScenarioName: res.data.scenarioName,
            UserDefinedName: res.data.userDefinedName,
            StateName: res.data.stateName,
            StateId: res.data.stateId,
            CountyName: res.data.countyName,
            CountyId: res.data.countyId,
            DetailsList: res.data.scenarioDetails
              ? res.data.scenarioDetails.map((detail) => ({
                  Id: detail.detailId,
                  Group: {
                    Id: detail.groupId,
                    CommonName: detail.groupName,
                  },
                  DBH: detail.dbh,
                  AmountPerAcre: detail.amountPerAcre,
                  HasType: detail.hasType,
                  TypeLookUp: {
                    Id: detail.typeLookUpId,
                    Afpractice: detail.typeLookUpName,
                  },
                  Type: { Id: detail.typeId },
                  Age: detail.age,
                  Size: detail.size,
                  HasAge: detail.hasAge,
                  Scenario: { Id: detail.scenarioId },
                  IsNew: detail.isNew,
                }))
              : [],
          });
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(updateLoading({ loading: false }));
      })
      .finally(() => {
        dispatch(updateLoading({ loading: false }));
      });
  };
  const fetchSpeciesGroups = () => {
    dispatch(updateLoading({ loading: true }));

    post(AGROFORESTRY_LIST_SPECIES_GROUPS, {
      UserId: currentUser.id,
      ScenarioName: "Baseline",
      ProjectId: currentUser.activeProject.id,
    })
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          dispatch(updateLoading({ loading: false }));
        } else {
          setAvailableSpeciesGroups(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(updateLoading({ loading: false }));
      })
      .finally(() => {
        dispatch(updateLoading({ loading: false }));
      });
  };
  function GetSpeciesGroupId(speciesGroupName) {
    // From the list of available species groups, find one with a matching name
    let tempId = 0;

    availableSpeciesGroups.forEach((specGroup) => {
      if (specGroup.speciesGroupName === speciesGroupName) {
        tempId = specGroup.id;
      }
    });

    return tempId;
  }
  const AddSpeciesGroup = () => {
    // Create new species object and add to list, then reset the state
    const newScenarioObj = {
      Id: 0,
      Group: {
        Id: GetSpeciesGroupId(editingSpecies.speciesGroup),
        CommonName: editingSpecies.speciesGroup,
      },
      DBH: editingSpecies.age === 0 ? editingSpecies.dbh : editingSpecies.age,
      AmountPerAcre: editingSpecies.totalNumber,
      HasType: false,
      TypeLookUp: { Id: 11111, Afpractice: "" },
      Type: { Id: 11111 },
      Age: 0,
      Size: 0,
      HasAge: editingSpecies.age !== 0,
      Scenario: { Id: 1 },
    };

    addScenarioAndSave(newScenarioObj, userDefinedName);

    updateEditSpecies({
      speciesGroup: "placeholder",
      dbh: 0,
      age: 0,
      totalNumber: 0,
    });
  };

  // Whenever the project is updated, make sure the location is still the same
  // Then, update the user-defined name and make sure the project doesn't contain outdated details
  const [oldDetailError, setOldError] = useState(false);
  const [defaultTab, setDefaultTab] = useState(0);
  useEffect(() => {
    if (agroforestryOperation.CountyName !== "Not Selected") {
      setLoadingMessage("");

      // Fetch scenarios and reset
      updateEditScenario({
        ...editingScenario,
        scenarioName: "placeholder",
        age: 0,
        size: 0,
      });
      fetchScenarioList();

      // Fetch species groups and reset
      updateEditSpecies({
        scenarioName: "",
        speciesGroup: "placeholder",
        dbh: 0,
        age: 0,
        totalNumber: 0,
      });
      fetchSpeciesGroups();

      if (
        agroforestryOperation.DetailsList &&
        agroforestryOperation.DetailsList.length > 0
      ) {
        if (
          agroforestryOperation.DetailsList.some((op) => op.IsNew === false)
        ) {
          setOldError(true);
        } else setOldError(false);

        setDefaultTab(agroforestryOperation.DetailsList[0].HasType ? 0 : 1);
      }

      setUserDefinedName(agroforestryOperation?.UserDefinedName);
    } else {
      setLoadingMessage("Please Select a Location...");
    }
  }, [agroforestryOperation]);

  // Display tabs, update when user changes a scenario
  function GenerateTabs() {
    const tempTabs = {
      TabsList: [
        {
          Tab: "Select a Pre-Defined Scenario",
          Data: (
            <PreDefined
              agroforestryOp={agroforestryOperation}
              loadingMessage={loadingMessage}
              updateLocalPopup={updateLocalPopup}
              availableScenarios={availableScenarios}
              currentlyEditing={editingScenario}
              updateEditScenario={updateEditScenario}
              addPreDefinedScenario={AddPreDefinedScenario}
              removePreDefinedScenarios={removeDetails}
            />
          ),
        },
        {
          Tab: "Enter My Own Information",
          Data: (
            <UserDefined
              agroforestryOp={agroforestryOperation}
              loadingMessage={loadingMessage}
              updateLocalPopup={updateLocalPopup}
              availableSpeciesGroups={availableSpeciesGroups}
              userDefinedName={userDefinedName}
              setUserDefinedName={setUserDefinedName}
              updateUserDefinedName={UpdateUserScenario}
              currentlyEditing={editingSpecies}
              updateEditSpecies={updateEditSpecies}
              addSpeciesGroup={AddSpeciesGroup}
              removeSpeciesGroups={removeDetails}
            />
          ),
        },
      ],
    };

    return tempTabs;
  }
  const [tabs, setTabs] = useState(GenerateTabs());
  // Update the tab view when the user edits a scenario to preserve info when switching tabs
  useEffect(() => {
    setTabs(GenerateTabs());
  }, [
    loadingMessage,
    availableScenarios,
    editingScenario,
    availableSpeciesGroups,
    editingSpecies,
    userDefinedName,
    agroforestryOperation,
  ]);

  return (
    <Grid container direction="row">
      <CFStep
        stepLabel="Agroforestry Scenario"
        subText="Enter information about your agroforestry practices"
        useGuideWidth="40vw"
        helpText={
          <CFBox
            sx={{
              overflow: "auto",
              height: "600px",
              maxHeight: "60vh",
            }}
          >
            <CFTypography variant="h4">
              Instructions for Pre-Defined Scenarios
            </CFTypography>
            <img
              src={PreDefinedUserHelp}
              width="100%"
              alt="Pre-Defined Scenario Instructions"
            />
            <CFTypography variant="h4" pt={5}>
              Instructions for Entering Own Information
            </CFTypography>
            <img
              src={UserDefinedHelp}
              width="100%"
              alt="User-Defined Scenario Instructions"
            />
          </CFBox>
        }
        stepNumber={stepNumber}
      />
      {oldDetailError && (
        <Grid py={2}>
          <CFTypography variant="h4" sx={{ color: "red" }}>
            ATTENTION: This project contains outdated species or groups that are
            no longer supported. These species or groups cannot be edited, and
            will not be visible in the report.
          </CFTypography>
          <Grid container direction="column" alignItems="center" pt={2}>
            <CFButton color="error" onClick={() => RemoveOldData()}>
              Remove Old Data
            </CFButton>
          </Grid>
        </Grid>
      )}
      <CFTabView defaultTab={defaultTab} my={1}>
        {tabs}
      </CFTabView>

      {localPopupState && localPopupState.showPopup && (
        <CFDialog
          {...localPopupState}
          updatePopup={(e) => updateLocalPopup(e)}
        />
      )}
    </Grid>
  );
}
export default AgroforestryScenario;
