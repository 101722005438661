export const loggedIn = () => {
  const expirationTime = localStorage.getItem("tokenExpirationTime");
  return new Date().getTime() / 1000 < parseInt(expirationTime, 10);
};

export const verifyPassword = (passwordToCheck) => {
  if (passwordToCheck.length < 6) {
    return "Password must be at least 6 characters in length.";
  }
  return "";
};

export const login = ({ token, tokenExpirationTime, id }) => {
  localStorage.setItem("token", token);
  localStorage.setItem("tokenExpirationTime", tokenExpirationTime);
  localStorage.setItem("id", id);
};

export const unlogin = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("tokenExpirationTime");
};

export const headerWithAuth = () =>
  // return 'fakeTok';
  ({
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });

export const headerOnlyAuth = () => ({
  Authorization: `Bearer ${localStorage.getItem("token")}`,
});

export const camelize = (str) =>
  str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
    if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });

/* Validation state should be an object with an optional message for each object.  
This is returned from the API.

If no key exisit for this object, no text should be displayed. 
*/
export const getHelperText = (validationState, key) => {
  let helperText = "";

  if (validationState)
    if (validationState[key]) {
      const correctState = validationState[key];
      // we have a string message
      if (correctState.isArray) {
        correctState.forEach((v) => {
          helperText += v.toString();
        });
      } else if (typeof helperText === "object") {
        helperText = correctState.message;

        // helper text is null
        if (!helperText)
          helperText =
            "Error message in incorrect format.  Check API endpoint return.";
      } else {
        helperText = correctState;
      }
    }

  return helperText;
};

export const getErrorState = (validationState, key) => {
  let errorState = false;

  if (validationState) if (validationState[key]) errorState = true;

  return errorState;
};
