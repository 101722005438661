/* eslint-disable react/no-array-index-key */
import { Checkbox, Grid, Input, InputAdornment } from "@mui/material";

// Date picker
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";

import { useEffect, useState } from "react";

import CFBox from "components/CFBox";
import colors from "assets/theme/base/colors";
import CFTypography from "components/CFTypography";

import UserGuidePopup from "components/CFUserGuidePopup/UserGuidePopup";
import CFLoading from "components/CFLoading";
import CFManagementSelectionCard from "components/CroplandsComponents/CFManagementSelectionCard/CFManagementSelectionCard";
import { croplandTableStyle } from "pages/Croplands/CroplandStyle";
import PlantingDateHelpImage from "assets/cFarmImages/HelpImages/planting-date.webp";
import { preloadImage } from "utils/generic";
import HelpAndButton from "../../../../../components/CroplandsComponents/EditCropCard_StructuralComponents/HelpAndButton";
import DeleteButton from "../../../../../components/CroplandsComponents/EditCropCard_StructuralComponents/DeleteButton";
import WhiteBackgroundDatepicker from "../../../../../components/CroplandsComponents/EditCropCard_StructuralComponents/WhiteBackgroundDatepicker";
import { GFSTRHelpText } from "./HelpText";

export default function ({
  CropEvent,
  SetPropertyFunction,
  field,
  previousPlantingDate,
  fullCropYear,
  setPlantingValid,
  setHarvestValid,
  previousCrop,
}) {
  if (!CropEvent) {
    return <CFBox>Planting and Harvest Component</CFBox>;
  }

  const isWoody = CropEvent.name.indexOf("(Woody") > -1;

  preloadImage(PlantingDateHelpImage);
  // console.log("previous planting", previousPlantingDate);
  // build the awareness of other crops growing string
  const [minDate, setMinDate] = useState(dayjs("1995-1-1 00:00"));

  const theOtherTwoCrops = [];
  for (let i = 0; i < Object.keys(fullCropYear.crops).length; i += 1) {
    const aCrop = fullCropYear.crops[i];
    // don't consider woody crops for the 1 at a time grow limit
    if (
      CropEvent.cropNumber !== aCrop.cropNumber &&
      aCrop.id !== 0 &&
      aCrop.name.indexOf("(Woody") < 0
    ) {
      theOtherTwoCrops.push(aCrop);
    }
  }
  let fistOtherCropPlanting = null;
  let fistOtherCropHarvest = null;
  let secondOtherCropPlanting = null;
  let secondOtherCropHarvest = null;

  if (theOtherTwoCrops.length > 0) {
    fistOtherCropPlanting = dayjs(theOtherTwoCrops[0].plantingDate);

    for (let i = 0; i < theOtherTwoCrops[0].harvests.length; i += 1) {
      if (
        !fistOtherCropHarvest ||
        fistOtherCropHarvest.isBefore(
          dayjs(theOtherTwoCrops[0].harvests[i].harvestDate)
        )
      ) {
        fistOtherCropHarvest = dayjs(
          theOtherTwoCrops[0].harvests[i].harvestDate
        );
      }
    }
  }

  if (theOtherTwoCrops.length > 1) {
    secondOtherCropPlanting = dayjs(theOtherTwoCrops[1].plantingDate);

    for (let i = 0; i < theOtherTwoCrops[1].harvests.length; i += 1) {
      if (
        !secondOtherCropHarvest ||
        secondOtherCropHarvest.isBefore(
          dayjs(theOtherTwoCrops[1].harvests[i].harvestDate)
        )
      ) {
        secondOtherCropHarvest = dayjs(
          theOtherTwoCrops[1].harvests[i].harvestDate
        );
      }
    }
  }

  let crop1Growing = "";
  if (fistOtherCropPlanting && fistOtherCropHarvest) {
    crop1Growing = `${theOtherTwoCrops[0].name}: ${fistOtherCropPlanting.format(
      "MMMM D YYYY"
    )} - ${fistOtherCropHarvest.format("MMMM D YYYY")} `;
  }

  let crop2Growing = "";
  if (secondOtherCropPlanting && secondOtherCropHarvest) {
    crop2Growing = `${
      theOtherTwoCrops[1].name
    }: ${secondOtherCropPlanting.format(
      "MMMM D YYYY"
    )} - ${secondOtherCropHarvest.format("MMMM D YYYY")} `;
  }

  // fullCropYear is all crop events in this year
  // use this to get all other crop growing periods
  // and forbid planting during other crops growing periods.

  // only make blocking growing seasons for harvest dates based on the last harvest
  // don't allow the user to define a harvest if its date would make a growing season that overlaps another crop's planting
  // dont allow a user to define a planting date that is between a crop's planting and final harvest

  // OK so we go in here, the other crops aren't going to change

  // we know other crops planting dates.

  // we know other crops harvest dates

  // this crop is forbidden to plant between other crops planting and harvest dates
  // if this crop is planted, then this crop is forbidden to have a harvest date such that a planting date falls between this crop planing and harvest

  // SO. planting calendar

  const overlapsPlanting = (TDate) => {
    if (fistOtherCropPlanting && fistOtherCropHarvest) {
      const fsDayMinus1 = fistOtherCropPlanting.add(-1, "day");
      if (fsDayMinus1.isBefore(TDate) && TDate.isBefore(fistOtherCropHarvest)) {
        return true;
      }
    }

    if (secondOtherCropPlanting && secondOtherCropHarvest) {
      const sDayMinus1 = secondOtherCropPlanting.add(-1, "day");
      if (
        sDayMinus1.isBefore(TDate) &&
        TDate.isBefore(secondOtherCropHarvest)
      ) {
        return true;
      }
    }

    // checks TDate, if it overlaps the growing season on the other crops then return true;
    return false;
  };

  const FieldName = field.name;
  const setPlantingDate = (newVal) => {
    const newCE = { ...CropEvent };
    if (dayjs(newVal, true).isValid()) {
      newCE.plantingDate = dayjs(newVal).toISOString();

      SetPropertyFunction(newCE);
      setPlantingValid(!dayjs(newCE.plantingDate).isBefore(minDate));
    }
  };

  useEffect(() => {
    if (isWoody && dayjs(CropEvent.plantingDate).isBefore(minDate)) {
      setPlantingDate(minDate);
    }
    if (isWoody) {
      setPlantingValid(true);
    }
  });

  const maxDate = dayjs(`${CropEvent.cropYear + 1}-1-1 00:00`);

  const HarvMax = dayjs(`${CropEvent.cropYear + 2}-1-1 00:00`);
  const [maxHarvDate, setMaxHarvDate] = useState(HarvMax);

  const resetMaxHarvestDate = () => {
    const plantingDate = dayjs(CropEvent.plantingDate);
    let newHarvMax = dayjs(`${CropEvent.cropYear + 2}-1-1 00:00`);

    if (fistOtherCropPlanting && fistOtherCropPlanting.isAfter(plantingDate)) {
      newHarvMax = fistOtherCropPlanting.add(-1, "day");
    }
    if (
      secondOtherCropPlanting &&
      secondOtherCropPlanting.isAfter(plantingDate)
    ) {
      newHarvMax = secondOtherCropPlanting.add(-1, "day");
    }
    setMaxHarvDate(newHarvMax);
  };

  const getYieldUnits = (cropId) => {
    switch (cropId) {
      case 238: // peas
      case 243: // potato
      case 228: // dryland beans
      case 244: // flooded rice
      case 256: // upland rice
      case 265: // fresh tomatoes
      case 267:
      case 268:
      case 270:
      case 271:
      case 272:
      case 273:
      case 274:
        return "cwt";
      case 221: // barley
      case 222: // corn
      case 232: // millet
      case 236: // oats
      case 246: // sorghum
      case 250: // soybean
      case 249: // spring wheat
      case 252: // winter wheat
        return "bu";
      case 219: // almond
      case 220: // apple N
      case 224: // cherry N
      case 227: // cotton
      case 242: // peanut
      case 241: // pecan N
      case 248: // sunflower
      case 251: // tobacco
        return "lbs";
      case 218: // alfalfa
      case 226: // clover
      case 230: // grape N
      case 231: // processing tomatoes
      case 210: // grass
      case 253: // grass-clover pasture
      case 237: // olives N
      case 239: // peach N
      case 240: // pear N
      case 247: // sugar beets
      case 223: // sugar can
      case 254: // walnut
      case 258: // corn silage
      case 259: // sorghum silage
      case 266:
      case 299: // switch grass
        return "tons";
      default:
        return "bu"; // should this be something else?
    }
  };

  const setPrune = (check) => {
    const newCE = { ...CropEvent };
    if (!newCE.treeEvent) {
      newCE.treeEvent = { prune: false, renew: false };
    }
    newCE.treeEvent.prune = check;

    // OK so digging through old comet-farm. here's the behavior:
    // when checked we need to see if we're in the first year of the current, or if the
    SetPropertyFunction(newCE);
  };
  const setRenew = (check) => {
    const newCE = { ...CropEvent };
    if (!newCE.treeEvent) {
      newCE.treeEvent = { prune: false, renew: false };
    }
    newCE.treeEvent.renew = check;

    // OK so digging through old comet-farm. here's the behavior:
    // when checked we need to see if we're in the first year of the current, or if the
    SetPropertyFunction(newCE);
  };

  const setContinueFromPrev = (check) => {
    const newCE = { ...CropEvent };
    newCE.continueFromPrevious = check;
    if (check && previousPlantingDate !== null) {
      // if checked and we have a valid previous year planting date, set the planting to that previous planting
      if (dayjs(previousPlantingDate, true).isValid()) {
        newCE.plantingDate = dayjs(previousPlantingDate).toISOString();
      }
    } else if (!check && previousPlantingDate !== null) {
      // if unchecking (and so was checked), set the planting date to this year
      if (dayjs(previousPlantingDate, true).isValid()) {
        const prevDate = dayjs(previousPlantingDate);
        const newDte = dayjs()
          .set("year", CropEvent.cropYear)
          .set("day", prevDate.day())
          .set("month", prevDate.month());
        newCE.plantingDate = newDte.toISOString();
      }
    }
    // OK so digging through old comet-farm. here's the behavior:
    // when checked we need to see if we're in the first year of the current, or if the
    SetPropertyFunction(newCE);
  };

  const isPerennialPossible = () =>
    CropEvent.parentId === 607 &&
    previousPlantingDate !== null &&
    previousCrop === CropEvent.id;

  const cropIsGrassOrFallow = () =>
    CropEvent.id === 229 || CropEvent.id === 210 || CropEvent.id === 253;

  // issue #379 https://gitlab.com/comet1/comet-farm/-/issues/379 no longer require valid harvest
  const harvValid = () => true; /* || 
    isPerennialPossible() ||
    cropIsGrassOrFallow() ||
    (CropEvent.harvests &&
      CropEvent.harvests[0] &&
      dayjs(CropEvent.harvests[0].harvestDate) >
        dayjs(CropEvent.plantingDate) &&
      dayjs(CropEvent.harvests[0].harvestDate) < maxHarvDate &&
      dayjs(CropEvent.harvests[0].harvestDate) > minDate); */

  const checkHarvValid = () => {
    if (harvValid()) {
      // console.log("setting harvest valid", true);
      setHarvestValid(true);
    } else {
      // console.log("setting harvest valid", false);
      setHarvestValid(false);
    }
  };

  useEffect(() => {
    resetMaxHarvestDate();
    checkHarvValid();

    setMinDate(
      CropEvent.continueFromPrevious
        ? dayjs("1995-1-1 00:00")
        : dayjs(`${CropEvent.cropYear - 1}-12-31 00:00`)
    );
  }, [CropEvent.plantingDate]);

  checkHarvValid();
  setPlantingValid(dayjs(CropEvent.plantingDate).isAfter(minDate));
  useEffect(() => {
    checkHarvValid();
  }, [CropEvent.plantingDate, CropEvent.harvests, minDate]);

  setPlantingValid(dayjs(CropEvent.plantingDate).isAfter(minDate));
  checkHarvValid();

  const addNewHarvestEvent = () => {
    const newCE = { ...CropEvent };

    newCE.harvests.push({
      harvestDate: dayjs(newCE.plantingDate).toISOString(),
      strawStoverHayPct: 0,
      isGrainHarvest: false,
      id: 0,
    });
    SetPropertyFunction(newCE);
    checkHarvValid();
  };

  const setHarvestDate = (newVal, index) => {
    const newCE = { ...CropEvent };
    if (dayjs(newVal, true).isValid()) {
      newCE.harvests[index].harvestDate = dayjs(newVal).toISOString();
      SetPropertyFunction(newCE);
    }
    checkHarvValid();
  };

  const deleteHarvest = (index) => {
    const newCE = { ...CropEvent };
    newCE.harvests.splice(index, 1);
    SetPropertyFunction(newCE);
    checkHarvValid();
  };

  const setYield = (InNewVal, index) => {
    let newVal = Number(InNewVal);

    if (Number.isNaN(newVal) || newVal < 0) {
      newVal = 0;
    }

    const newCE = { ...CropEvent };
    newCE.harvests[index].harvestYield = newVal;
    SetPropertyFunction(newCE);
  };

  const setDryMatter = (InNewVal, index) => {
    let newVal = Number(InNewVal);

    if (Number.isNaN(newVal) || newVal < 0) {
      newVal = 0;
    }
    if (newVal > 100) {
      newVal = 100;
    }
    const newCE = { ...CropEvent };
    newCE.harvests[index].strawStoverHayPct = newVal;
    SetPropertyFunction(newCE);
  };
  const setGrainHarvest = (newVal, index) => {
    const newCE = { ...CropEvent };
    newCE.harvests[index].isGrainHarvest = newVal;
    SetPropertyFunction(newCE);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {isWoody && (
        <Grid item xl={6} xs={11}>
          <Grid item xxl={11} xl={5.5} xs={11}>
            <CFManagementSelectionCard
              label={
                <span>
                  <span>Did you prune?</span>
                </span>
              }
              labelWidth={6}
              input={
                <Checkbox
                  color="secondary"
                  checked={CropEvent.treeEvent?.prune} // complicated
                  onChange={(e) => {
                    setPrune(e.target.checked);
                  }}
                />
              }
            />
          </Grid>
          <Grid item xxl={11} xl={5.5} xs={11}>
            <CFManagementSelectionCard
              label={
                <span>
                  <span>Did you Renew?</span>
                </span>
              }
              labelWidth={6}
              input={
                <Checkbox
                  color="secondary"
                  checked={CropEvent.treeEvent?.renew} // complicated
                  onChange={(e) => {
                    setRenew(e.target.checked);
                  }}
                />
              }
            />
          </Grid>
        </Grid>
      )}
      {!isWoody && (
        <Grid alignItems="center" container flexDirection="row">
          {(crop1Growing || crop2Growing) && (
            <Grid item xs={12} sx={{ marginLeft: "2em" }}>
              <CFTypography sx={{ fontSize: ".7em" }}>
                We can only model the growth of one crop at a time. Existing
                growing periods:
              </CFTypography>
              <CFTypography sx={{ fontSize: ".7em" }}>
                {crop1Growing}
              </CFTypography>
              <CFTypography sx={{ fontSize: ".7em" }}>
                {crop2Growing}
              </CFTypography>
            </Grid>
          )}
          <Grid item xl={6} xs={11}>
            <CFBox sx={{ display: "inline-flex", width: "100%" }}>
              <CFManagementSelectionCard
                disabled={CropEvent.continueFromPrevious}
                label={
                  <CFBox sx={{ display: "flex", alignItems: "center" }}>
                    Planting Date{" "}
                    <UserGuidePopup
                      content={
                        <div
                          style={{
                            overflow: "auto",
                            height: "750px",
                            maxHeight: "60vh",
                          }}
                        >
                          <p>
                            {" "}
                            <strong>Annual Crops Planting Dates-</strong> Within
                            the <em>Define Baseline Management</em> window,
                            users will first be prompted to enter the planting
                            date for their selected crop, for their selected
                            year, and on their selected field. Dates can be
                            entered by either clicking the calendar icon and
                            selecting the desired date, or by typing in values
                            as MM/DD/YYYY. Planting dates are required for all
                            annual crops.
                          </p>
                          <br />
                          <p>
                            <strong>
                              <em>
                                COMET-Farm cannot support multiple crops growing
                                at the same time
                              </em>
                            </strong>
                            , with the exception of predefined cover crop blends
                            and cover crops in an orchard/vineyard system. The
                            growing season (plant date to start date) for each
                            previous crop will be listed above the planting for
                            all crops except the first crop added to the
                            rotation. If the previous crop in the rotation has
                            an identified harvest date, users will be unable to
                            select a plant date for the second crop until after
                            the harvest event.
                          </p>
                          <img
                            src={PlantingDateHelpImage}
                            width="100%"
                            alt="Planting Date help"
                          />
                          <br />
                          <p>
                            For example, when a user enters the planting date
                            for their winter wheat in the following{" "}
                            <em>Corn-Winter Wheat-Soybean</em> rotation, the
                            dates before 8/04/2013 will be greyed out, requiring
                            a user to enter the planting date after the corn is
                            harvested.
                          </p>
                          <style>{croplandTableStyle}</style>
                          <table>
                            <tbody>
                              <tr>
                                <td>Crop</td>
                                <td>Planting Date</td>
                                <td>Harvest Date</td>
                              </tr>
                              <tr>
                                <td>Winter Wheat</td>
                                <td>10/31/2012</td>
                                <td>4/22/2013</td>
                              </tr>
                              <tr>
                                <td>Corn</td>
                                <td>5/18/2013</td>
                                <td>8/03/2013</td>
                              </tr>
                              <tr>
                                <td>Winter Wheat</td>
                                <td>10/31/2013</td>
                                <td>6/12/2014</td>
                              </tr>
                              <tr>
                                <td>Soybean</td>
                                <td>6/18/2014</td>
                                <td>10/09/2014</td>
                              </tr>
                            </tbody>
                          </table>
                          <br />
                          <p>
                            If an annual crop follows a cover crop that does not
                            have a harvest or termination date or an annual crop
                            is sewn into a cover crop, COMET-Farm will terminate
                            the previous crop the day before the annual crop is
                            planted.{" "}
                          </p>
                          <br />
                          <p>
                            <strong>Perennial Crops Planting Date-</strong> If a
                            perennial crop is planted in a given year in the
                            scenario, enter the planting date. An option to
                            select <em>“continue crop from previous year?”</em>{" "}
                            is available for perennial crops in subsequent
                            years. If a perennial crop, such as grass or
                            grass-legume, was never “planted” but rather the
                            “crop” of a pasture or rangeland, users may select “
                            <em>continue crop from previous year?</em>” on the
                            first year, and subsequent years, of the rotation.
                          </p>{" "}
                          <br />
                          <p>
                            <strong>Fallow Planting Date-</strong> If no crop is
                            planted in a given year, please enter{" "}
                            <em>fallow</em> as your selected “crop”. Users will
                            need to enter a “planting” date for their fallow;
                            this should be the date on which the fallow began in
                            that year. Users will be able to add other
                            management activity data for their fallow crop,
                            except for harvest, grazing, pre-plant/planting
                            season, and post-harvest tillage system intensities.
                          </p>
                        </div>
                      }
                      userGuideLabel="Planting Date"
                      variant="h3"
                      sx={{ marginTop: ".2em", padding: ".1em" }}
                    />
                  </CFBox>
                }
                labelWidth={5}
                input={
                  <WhiteBackgroundDatepicker
                    shouldDisableDate={overlapsPlanting}
                    views={["year", "month", "day"]}
                    label="Planting Date"
                    maxDate={maxDate}
                    minDate={minDate}
                    style={{ backgroundColor: colors.white.main }}
                    slotProps={{ textField: { variant: "outlined" } }}
                    value={
                      dayjs(CropEvent.plantingDate).isAfter(minDate)
                        ? dayjs(CropEvent.plantingDate)
                        : null
                    }
                    referenceDate={
                      dayjs(CropEvent.plantingDate).isAfter(minDate)
                        ? dayjs(CropEvent.plantingDate)
                        : dayjs(`${CropEvent.cropYear}-1-1 00:00`)
                    }
                    onChange={(newValue) => setPlantingDate(newValue)}
                  />
                }
              />
              <div style={{ display: "inline", color: "red" }}>✵</div>
            </CFBox>
          </Grid>
          <Grid item xl={5} xs={11}>
            {isPerennialPossible() && (
              <CFManagementSelectionCard
                label="Continue crop from previous year?"
                labelWidth={11}
                input={
                  <Checkbox
                    color="secondary"
                    checked={CropEvent.continueFromPrevious} // complicated
                    onChange={(e) => {
                      setContinueFromPrev(e.target.checked);
                    }}
                  />
                }
              />
            )}
          </Grid>
          <CFBox sx={{ position: "relative" }}>
            {" "}
            {CropEvent.id === 229 && (
              <CFLoading
                local
                message="Harvest events disabled for fallow"
                showCircle={false}
              />
            )}{" "}
            {CropEvent.harvests.map((harvest, ind) => (
              <Grid
                key={`harvest-${ind}`}
                item
                xs={12}
                container
                flexDirection="row"
                alignItems="center"
                sx={{ paddingTop: ".5em" }}
              >
                <Grid item xxl={4} xl={5} xs={11}>
                  <CFManagementSelectionCard
                    label="Harvest/Kill Date"
                    labelWidth={6}
                    input={
                      <WhiteBackgroundDatepicker
                        views={["year", "month", "day"]}
                        label="Harvest/Kill Date"
                        maxDate={maxHarvDate} // max date should be up to the next planting if it exists
                        minDate={dayjs(CropEvent.plantingDate)}
                        style={{ backgroundColor: colors.white.main }}
                        slotProps={{ textField: { variant: "outlined" } }}
                        value={
                          dayjs(harvest.harvestDate).isAfter(minDate) &&
                          dayjs(harvest.harvestDate).isBefore(maxHarvDate)
                            ? dayjs(harvest.harvestDate)
                            : null
                        }
                        onChange={(newValue) => setHarvestDate(newValue, ind)}
                      />
                    }
                  />
                </Grid>
                <Grid item xxl={3} xl={6} xs={11}>
                  <CFManagementSelectionCard
                    label="Dry Matter Removal"
                    labelWidth={9}
                    input={
                      <Input
                        label="%"
                        type="number"
                        endAdornment={
                          <InputAdornment position="end">%</InputAdornment>
                        }
                        sx={{
                          backgroundColor: colors.white.main,
                          fontSize: "1.25em",
                          paddingInline: ".3em",
                          borderRadius: ".3em",
                        }}
                        value={harvest.strawStoverHayPct}
                        onChange={(e) => setDryMatter(e.target.value, ind)}
                      />
                    }
                  />
                </Grid>
                <Grid item xxl={4} xl={5.5} xs={11}>
                  <CFManagementSelectionCard
                    label={
                      <UserGuidePopup
                        content={GFSTRHelpText}
                        label="Grain/Fruit/Seed/Root/Tuber?"
                      />
                    }
                    labelWidth={10}
                    input={
                      <Checkbox
                        color="secondary"
                        checked={harvest.isGrainHarvest} // complicated
                        onChange={(e) => {
                          setGrainHarvest(e.target.checked, ind);
                        }}
                      />
                    }
                  />
                </Grid>
                <Grid item xxl={11} xl={5.5} xs={11}>
                  <CFManagementSelectionCard
                    label={
                      <span>
                        <span>
                          Yield<sup style={{ fontSize: ".5em" }}>1</sup>
                        </span>
                      </span>
                    }
                    labelWidth={6}
                    input={
                      <Input
                        label="Yield"
                        type="number"
                        endAdornment={
                          <InputAdornment position="end">
                            {getYieldUnits(CropEvent.id)}/ac
                          </InputAdornment>
                        }
                        sx={{
                          backgroundColor: colors.white.main,
                          fontSize: "1.25em",
                          paddingInline: ".3em",
                          borderRadius: ".3em",
                        }}
                        value={harvest.harvestYield}
                        onChange={(e) => setYield(e.target.value, ind)}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={1}>
                  <DeleteButton deleteAction={() => deleteHarvest(ind)} />
                </Grid>
              </Grid>
            ))}
            <HelpAndButton
              buttonLabel="+ Add Harvest Event"
              buttonAction={addNewHarvestEvent}
              userGuideLabel="Harvest/Termination events"
              helperText={
                <div style={{ overflow: "auto", height: "750px" }}>
                  <style>{croplandTableStyle}</style>
                  Harvest/Termination dates should be entered when there is a
                  harvest/termination event. Select <em>
                    Add Harvest Event
                  </em>{" "}
                  to add additional harvest events (ex: hay events, mowing,
                  alfalfa harvest).
                  <table>
                    <tbody>
                      <tr>
                        <td>Harvest/Termination Table Input</td>
                        <td>Description</td>
                      </tr>
                      <tr>
                        <td>Date</td>
                        <td>
                          When an annual or perennial crop is harvested is
                          harvested/terminated; For annual crops, COMET will
                          simulate a LAST event in the DayCent schedule file on
                          this date;
                        </td>
                      </tr>
                      <tr>
                        <td>Dry Matter Removal %</td>
                        <td>
                          Percent of residue removed from field at harvest
                          event; Ex: Corn harvest where only ears are removed,
                          enter 0%; Silage event may have 85-95% residue
                          removed; mid-season alfalfa harvest may have 50%
                          residue removal
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Grain/Fruit/Seed/Tuber/Root{" "}
                          <Checkbox color="secondary" checked />
                        </td>
                        <td>
                          Selecting this option indicates that either a grain,
                          fruit, seed, root, or tuber is harvested at the
                          entered date; this should <string>not</string> be
                          selected for silage crops.
                        </td>
                      </tr>
                      <tr>
                        <td>Yield</td>
                        <td>
                          User entered yield will <strong>only</strong> be used
                          in soil C and methane estimates if flooded rice is
                          included in the rotation. With non-rice crops, yield
                          will only be used in methane estimates if a burning
                          event is also included in the management of the crop.
                          COMET-Farm does
                          <string>not</string> make yield estimates.
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              }
            />
            <sub style={{ fontSize: ".5em", bottom: 0 }}>
              {" "}
              1. User-entered yield will only be used if a burning event or rice
              is included in a rotation.
            </sub>
            {!harvValid() && (
              <div style={{ color: "red" }}>
                At least one valid harvest is required
              </div>
            )}
          </CFBox>
        </Grid>
      )}
    </LocalizationProvider>
  );
}
