import { Grid } from "@mui/material";
import { PropTypes } from "prop-types";
import colors from "assets/theme/base/colors";
import CFTypography from "components/CFTypography";
import UserGuidePopup from "components/CFUserGuidePopup/UserGuidePopup";

export default function AgroforestryInput({ label, help, control }) {
  return (
    <Grid
      container
      direction="row"
      m={1}
      p={1.5}
      sx={{
        backgroundColor: colors.background.header,
        borderRadius: ".3em",
        boxShadow: "0px 1px 3px 0px #bbb",
      }}
    >
      <Grid
        item
        xs={4}
        container
        direction="row"
        alignItems="center"
        justifyContent="start"
      >
        {help ? (
          <UserGuidePopup
            label={label}
            content={help}
            variant="h6"
            fontWeight="regular"
          />
        ) : (
          <CFTypography variant="h6" fontWeight="regular">
            {label}
          </CFTypography>
        )}
      </Grid>
      <Grid
        item
        xs={8}
        container
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
      >
        {control}
      </Grid>
    </Grid>
  );
}

AgroforestryInput.defaultProps = {
  help: "",
};

AgroforestryInput.propTypes = {
  label: PropTypes.string.isRequired,
  help: PropTypes.string,
  control: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
