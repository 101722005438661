import { forwardRef } from "react"; // prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import CFBox from "components/CFBox";

import CFBulletListRoot from "./CFBulletListRoot";

const CFBulletList = forwardRef(({ point, noname, ...rest }, ref) => (
  <CFBox {...rest} ref={ref}>
    <CFBulletListRoot point={point} {...rest} noname={noname}>
      {rest.children}
    </CFBulletListRoot>
  </CFBox>
));

CFBulletList.defaultProps = {
  point: "arrow",
  noname: false,
};

CFBulletList.propTypes = {
  point: PropTypes.oneOf(["arrow", "dot"]),
  noname: PropTypes.bool,
};

export default CFBulletList;
