/* eslint-disable func-names */
import { put, call } from "redux-saga/effects";
import { updateValidations } from "store/reducers/validation";
import { updateLoading } from "store/reducers/mask";
import { updatePopup } from "store/reducers/popup";
import { to } from "../actions/navigation";
import { receiveAuthData } from "../actions/auth";
import { LOGIN, DEMO_LOGIN } from "../constants/api";
import { post } from "../utils/api";
import { startApp } from "../actions/generic";
import { login } from "../utils/auth";
import { fetchUser } from "../actions/user";

const authSaga = (url, thanGoTo) =>
  function* ({ payload, redirect }) {
    try {
      // console.log("auth values : ", payload);
      console.log(redirect);
      yield put(updateLoading({ loading: true }));
      const apiToken = yield call(post, url, payload);
      // console.log(apiToken);
      yield put(
        receiveAuthData({
          token: apiToken.jwtToken,
          tokenExpirationTime: apiToken.tokenExpirationTime,
          id: apiToken.id,
        })
      );
      yield put(startApp());
      //   console.log(thanGoTo);
      yield put(to(thanGoTo));

      yield call(login, {
        token: apiToken.jwtToken,
        tokenExpirationTime: apiToken.tokenExpirationTime,
        id: apiToken.id,
      });
      yield put(fetchUser({ Id: apiToken.id }));

      yield put(updateLoading({ loading: false }));
    } catch ({ status, message }) {
      console.log("Error message obj: ", message, status);

      /* If the message type is a string, then we have no easy way of knowing what object this error belongs to. 
        In this case, just set error to general type and show popup with message.   */
      if (message) {
        if (typeof message === "string") {
          yield put(updateValidations({ general: message }));
          yield put(
            updatePopup({
              title: "Error while attempting to login.",
              popupMessage: message,
              type: "error",
              showPopup: true,
            })
          );
        } else {
          yield put(updateValidations(message));
        }
      } else {
        yield put(
          updateValidations([{ general: "Unknown error encountered." }])
        );
      }

      yield put(updateLoading({ loading: false }));
    }
  };

// eslint-disable-next-line import/prefer-default-export
export const submitLogin = authSaga(LOGIN, "showLoginConfirmed");
export const submitDemoLogin = authSaga(DEMO_LOGIN, "showLoginConfirmed");
