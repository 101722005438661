import { useState, useEffect } from "react";

import { Grid, Select, MenuItem } from "@mui/material";
import {
  DataGridPremium,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from "@mui/x-data-grid-premium";

import CFBox from "components/CFBox";
import CFLoading from "components/CFLoading";
import CFStepMarker from "components/CFStepMarker/CFStepMarker";
import CFButton from "components/CFButton";
import CFTypography from "components/CFTypography";
import CFInput from "components/CFInput";
import CFNumberInput from "components/CFNumberInput/CFNumberInput";
import colors from "assets/theme/base/colors";

import AgroforestryInput from "../AgroforestryInput";

function UserDefined({
  agroforestryOp,
  loadingMessage,
  updateLocalPopup,
  availableSpeciesGroups,
  userDefinedName,
  setUserDefinedName,
  updateUserDefinedName,
  currentlyEditing,
  updateEditSpecies,
  addSpeciesGroup,
  removeSpeciesGroups,
}) {
  // Editing species values
  const { speciesGroup, dbh, age, totalNumber } = {
    ...currentlyEditing,
  };

  // Species table columns
  const columns = [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      flex: 0.25,
      resizable: false,
      headerClassName: "agroforestry-column-header--theme",
    },
    {
      field: "speciesGroup",
      headerName: "Species Name",
      type: "string",
      flex: 1,
      resizable: false,
      headerClassName: "agroforestry-column-header--theme",
    },
    {
      field: "dbhOrAge",
      headerName: "DBH/Age",
      type: "number",
      flex: 0.5,
      resizable: false,
      headerClassName: "agroforestry-column-header--theme",
    },
    {
      field: "totalNumber",
      headerName: "Total Number of Trees",
      type: "number",
      flex: 0.75,
      resizable: false,
      headerClassName: "agroforestry-column-header--theme",
    },
  ];

  // Project details rows
  const [rows, setRows] = useState([]);
  useEffect(() => {
    const tempRows = [];

    agroforestryOp?.DetailsList.forEach((projScenario) => {
      if (!projScenario.HasType && projScenario.IsNew) {
        tempRows.push({
          speciesGroup: projScenario.Group.CommonName,
          speciesGroupId: projScenario.Group.Id,
          dbhOrAge: projScenario.DBH,
          hasAge: projScenario.HasAge,
          totalNumber: projScenario.AmountPerAcre,
          id: projScenario.Id,
        });
      }
    });

    setRows(tempRows);
  }, [agroforestryOp]);

  // DBH or Age entry
  const [typeOfEntry, setTypeOfEntry] = useState(() => {
    if (age !== 0) return "Age";
    if (dbh !== 0) return "DBH";
    return "placeholder";
  });
  const [entryValue, setEntryValue] = useState(() => {
    if (age !== 0) return age;
    if (dbh !== 0) return dbh;
    return 0;
  });

  // Add a species group to the project
  function AddSpecies() {
    // Only add if an identical species doesn't already exist
    if (
      !agroforestryOp?.DetailsList.some(
        (spec) =>
          spec.Group.CommonName === speciesGroup &&
          ((spec.HasAge && spec.DBH === age) ||
            (!spec.HasAge && spec.DBH === dbh)) &&
          spec.AmountPerAcre === totalNumber
      )
    ) {
      addSpeciesGroup();
      setTypeOfEntry("placeholder");
      setEntryValue(0);
    } else {
      updateLocalPopup({
        message:
          "This species group already exists in this project. Please change the species group parameters to add.",
        title: "Project Species Group Already Exists",
        showPopup: true,
        closeAction: () => {
          updateLocalPopup({ showPopup: false });
        },
      });
    }
  }

  // Remove selected species from the list
  const [selectedSpecies, setSelectedSpecies] = useState([]);

  return (
    <CFBox sx={{ position: "relative" }}>
      {loadingMessage !== "" && (
        <CFLoading
          local
          message={loadingMessage}
          showCircle={loadingMessage.indexOf("Location") === -1}
        />
      )}

      <Grid container direction="column" py={3} px={2}>
        {/* Scenario Name */}
        <Grid item container direction="row" alignItems="center" pt={2} pb={1}>
          <CFStepMarker label="a" />
          <Grid item xs={6.15} px={3}>
            <CFInput
              placeholder="Management Scenario Name"
              value={userDefinedName ?? ""}
              onChange={(e) => {
                setUserDefinedName(e.target.value);
              }}
              fullWidth
            />
          </Grid>
          <Grid>
            <CFButton
              variant="gradient"
              color="info"
              onClick={() => updateUserDefinedName()}
              disabled={userDefinedName === agroforestryOp?.UserDefinedName}
            >
              Update Scenario Name
            </CFButton>
          </Grid>
        </Grid>
        {/* Species Group */}
        <Grid item container direction="row" alignItems="center" py={1}>
          <CFStepMarker label="b" />
          <Grid item xs={6} px={2}>
            <AgroforestryInput
              label="Species Group"
              control={
                <Select
                  value={speciesGroup ?? "placeholder"}
                  onChange={(e) =>
                    updateEditSpecies({
                      ...currentlyEditing,
                      speciesGroup: e.target.value,
                    })
                  }
                  sx={{ width: "100%" }}
                >
                  <MenuItem disabled value="placeholder">
                    Options...
                  </MenuItem>
                  {availableSpeciesGroups.length > 0 ? (
                    availableSpeciesGroups.map((specGroup) => (
                      <MenuItem
                        key={specGroup.id}
                        value={specGroup.speciesGroupName}
                      >
                        {specGroup.speciesGroupName}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled value="none">
                      No species groups available for the selected location!
                    </MenuItem>
                  )}
                </Select>
              }
            />
          </Grid>
        </Grid>
        {/* DBH or Age */}
        <Grid item container direction="row" alignItems="center" py={1}>
          <CFStepMarker label="c" />
          <Grid item xs={6} px={2}>
            <AgroforestryInput
              label="Select Age or DBH"
              help="Please indicate if you will be using age or DBH for this species' management."
              control={
                <Select
                  value={typeOfEntry}
                  onChange={(e) => setTypeOfEntry(e.target.value)}
                  sx={{ width: "100%" }}
                >
                  <MenuItem disabled value="placeholder">
                    Select an Input...
                  </MenuItem>
                  <MenuItem value="Age">Age</MenuItem>
                  <MenuItem value="DBH">DBH</MenuItem>
                </Select>
              }
            />
          </Grid>
          {typeOfEntry !== "placeholder" && (
            <Grid
              item
              xs={5}
              container
              direction="row"
              alignItems="center"
              pl={4}
            >
              <AgroforestryInput
                label={typeOfEntry}
                control={
                  <CFNumberInput
                    min={0}
                    value={entryValue}
                    onChange={(e, value) => {
                      setEntryValue(value);

                      if (typeOfEntry === "Age") {
                        updateEditSpecies({
                          ...currentlyEditing,
                          dbh: 0,
                          age: value,
                        });
                      } else if (typeOfEntry === "DBH") {
                        updateEditSpecies({
                          ...currentlyEditing,
                          dbh: value,
                          age: 0,
                        });
                      }
                    }}
                    onInputChange={(e) => {
                      // onInputChange runs whenever the value of the text box changes, but it returns a string instead of a value, and doesn't handle error checking
                      if (Number(e.target.value) > 0) {
                        if (typeOfEntry === "Age") {
                          updateEditSpecies({
                            ...currentlyEditing,
                            dbh: 0,
                            age: Number(e.target.value),
                          });
                        } else if (typeOfEntry === "DBH") {
                          updateEditSpecies({
                            ...currentlyEditing,
                            dbh: Number(e.target.value),
                            age: 0,
                          });
                        }
                      } else {
                        updateEditSpecies({
                          ...currentlyEditing,
                          dbh: 0,
                          age: 0,
                        });
                      }
                    }}
                    adornment={typeOfEntry === "Age" ? "years" : "in."}
                  />
                }
              />
            </Grid>
          )}
        </Grid>
        {/* Total Number */}
        <Grid item container direction="row" alignItems="center" py={1}>
          <CFStepMarker label="d" />
          <Grid item xs={6} px={2}>
            <AgroforestryInput
              label="Total Number of Trees"
              control={
                <CFNumberInput
                  min={0}
                  value={totalNumber || 0}
                  onChange={(e, value) =>
                    updateEditSpecies({
                      ...currentlyEditing,
                      totalNumber: value,
                    })
                  }
                  onInputChange={(e) => {
                    // onInputChange runs whenever the value of the text box changes, but it returns a string instead of a value, and doesn't handle error checking
                    if (Number(e.target.value) > 0) {
                      updateEditSpecies({
                        ...currentlyEditing,
                        totalNumber: Number(e.target.value),
                      });
                    } else {
                      updateEditSpecies({
                        ...currentlyEditing,
                        totalNumber: 0,
                      });
                    }
                  }}
                />
              }
            />
          </Grid>
        </Grid>
        {/* "Add" Button */}
        <Grid item container direction="column" alignItems="end" py={1}>
          <CFButton
            variant="gradient"
            color="info"
            onClick={() => AddSpecies()}
            sx={{ width: "15%" }}
            disabled={
              speciesGroup === "placeholder" ||
              (dbh === 0 && age === 0) ||
              totalNumber === 0
            }
          >
            Add Species
          </CFButton>
        </Grid>
        {/* Species List */}
        <Grid item container direction="row" alignItems="start" py={3} pr={2}>
          <Grid item xs={10.5} pr={2}>
            <CFBox
              sx={{
                "& .agroforestry-column-header--theme": {
                  fontSize: "1.2rem",
                  color: "white.main",
                  backgroundColor: colors.primary.main,
                },
              }}
            >
              <DataGridPremium
                columns={columns}
                rows={rows}
                checkboxSelection
                rowSelectionModel={selectedSpecies}
                onRowSelectionModelChange={(newModel) =>
                  setSelectedSpecies(newModel)
                }
              />
            </CFBox>
          </Grid>
          <Grid item xs={1}>
            <CFButton
              variant="gradient"
              color="secondary"
              disabled={!selectedSpecies.length > 0}
              onClick={() => {
                removeSpeciesGroups(selectedSpecies);
                setSelectedSpecies([]);
              }}
            >
              Delete Selected
            </CFButton>
          </Grid>
        </Grid>
      </Grid>
    </CFBox>
  );
}
export default UserDefined;
