// MUI
import { Grid } from "@mui/material";
import { PropTypes } from "prop-types";

// CFarm
import colors from "assets/theme/base/colors";
import CFTypography from "components/CFTypography";
import CFBox from "components/CFBox";
import UserGuidePopup from "components/CFUserGuidePopup/UserGuidePopup";

export default function ForestryInput({ label, help, error, control }) {
  return (
    <Grid
      container
      direction="row"
      m={1}
      p={1.5}
      sx={{
        backgroundColor: colors.background.header,
        boxShadow: "0px 1px 3px 0px #bbb",
        borderRadius: ".3em",
      }}
    >
      <Grid
        item
        xs={6}
        container
        direction="row"
        alignItems="center"
        justifyContent="start"
      >
        {/* Add user guide popup if "Help" is defined, otherwise add label */}
        {help ? (
          <UserGuidePopup
            label={label}
            content={help}
            variant="h6"
            fontWeight="regular"
          />
        ) : (
          <CFTypography variant="h6" fontWeight="regular">
            {label}
          </CFTypography>
        )}
      </Grid>
      <Grid item xs={6} container direction="column" alignItems="start">
        <Grid
          item
          container
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          {control}
        </Grid>
        {error && (
          <Grid
            item
            container
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
          >
            <CFBox
              style={{
                fontSize: "0.67em",
                color: "red",
                marginTop: "0.25em",
              }}
            >
              {error}
            </CFBox>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

ForestryInput.defaultProps = {
  help: "",
  error: "",
};

ForestryInput.propTypes = {
  label: PropTypes.string.isRequired,
  help: PropTypes.string,
  error: PropTypes.string,
  control: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
