/* eslint-disable arrow-body-style */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/button-has-type */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
// import HelpIcon from "@mui/icons-material/Help";
import { HelpOutlineOutlined } from "@mui/icons-material";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";
import CFBox from "components/CFBox";
import CFButton from "components/CFButton";
import CFTypography from "components/CFTypography";
import { useToggle } from "hooks";
import colors from "assets/theme/base/colors";
import { Typography } from "@mui/material";
import styles from "./UserGuidePopup.module.css";

const stylesButton = {
  cursor: "pointer",
  backgroundColor: "transparent",
  border: "none",
  fontSize: ".9em",
};

export default function UserGuidePopup({
  component,
  label,
  userGuideLabel,
  content,
  className,
  width,
  underlineLink,
  includeGuide,
  helpIcon,
  helperLink,
  showModal,
  ...rest
}) {
  const [show, toggleModal] = useToggle(false);

  const handleClick = (e) => {
    e.preventDefault();

    if (helperLink) {
      window.open(helperLink, "_blank");
    } else {
      toggleModal();
    }
  };
  useEffect(() => {
    if (showModal) toggleModal();
  }, [showModal]);
  return (
    <CFBox component={component || "span"}>
      {content && !underlineLink && (
        <CFTypography
          {...rest}
          className={`${styles.buttonLabel} ${className}`}
        >
          {label}

          <button
            style={stylesButton}
            onClick={handleClick}
            aria-label="more info"
          >
            {helpIcon || (
              <HelpOutlineOutlined sx={{ color: colors.secondary.main }} />
            )}
          </button>
        </CFTypography>
      )}
      {content && underlineLink && (
        <Typography
          onClick={handleClick}
          style={{ textDecorationLine: "underline", cursor: "pointer" }}
        >
          {label}
        </Typography>
      )}

      {!content && (
        <CFTypography
          {...rest}
          className={`${styles.buttonLabel} ${className}`}
        >
          {label}
        </CFTypography>
      )}

      <Modal
        sx={{
          //   maxHeight: "60vh",
          // overflowY: "auto",
          display: "grid",
          placeItems: "center",
        }}
        open={show}
        onClose={toggleModal}
      >
        <Slide direction="down" in={show} timeout={500}>
          <CFBox
            position="relative"
            width={width || "750px"}
            display="flex"
            flexDirection="column"
            borderRadius="xl"
            bgColor="white"
            shadow="xl"
          >
            <CFBox
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              p={2}
            >
              <CFTypography variant="h5">
                {includeGuide ? "Guide: " : ""}
                {userGuideLabel || label}
              </CFTypography>
              <CloseIcon
                fontSize="medium"
                sx={{ cursor: "pointer" }}
                onClick={toggleModal}
              />
            </CFBox>
            <Divider sx={{ my: 0 }} />
            {/* === MAIN CONTENT OF USER GUIDE === */}
            <CFBox p={2} sx={{ whiteSpace: "pre-wrap" }}>
              {content}
            </CFBox>
            <Divider sx={{ my: 0 }} />
            <CFBox
              style={{ width: "100%" }}
              justifyContent="space-between"
              p={1.5}
              component="span"
            >
              <span style={{ float: "right" }}>
                <CFButton color="secondary" onClick={toggleModal}>
                  Close
                </CFButton>
              </span>
            </CFBox>
          </CFBox>
        </Slide>
      </Modal>
    </CFBox>
  );
}

UserGuidePopup.defaultProps = {
  component: "",
  className: "",
  userGuideLabel: null,
  label: null,
  includeGuide: true,
  helpIcon: null,
  showModal: false,
};
/**
 * The intention is that the @label you click on will be the title
 * of the UserGuide Modal by default, but you can override it with @userGuideLabel
 */

UserGuidePopup.propTypes = {
  component: PropTypes.string,
  label: PropTypes.node,
  userGuideLabel: PropTypes.node,
  className: PropTypes.string,
  content: PropTypes.node.isRequired,
  includeGuide: PropTypes.bool,
  helpIcon: PropTypes.node,
  showModal: PropTypes.bool,
};
