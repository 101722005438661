export const BACKEND = process.env.REACT_APP_MAIN_API_URL;
export const FRONTEND = process.env.REACT_APP_MAIN_URL;

/* Auth */
export const LOGIN = `${BACKEND}accountcore/login`;
export const DEMO_LOGIN = `${BACKEND}accountcore/demologin`;
export const REGISTER = `${BACKEND}accountcore/register`;
export const VERIFY = `${BACKEND}accountcore/verify`;
export const RESET = `${BACKEND}accountcore/reset`;
export const VERIFY_DEV = `${BACKEND}accountcore/checkdevpass`;

export const FORGOT_PASSWORD = `${BACKEND}accountcore/forgotpassword`;
export const VERIFY_RESET_PASSWORD_LINK = `${BACKEND}accountcore/verifyresetpassword`; // user clicks password reset link, this checks that link is valid
export const SET_NEW_PASSWORD = `${BACKEND}accountcore/setnewpassword`; // user sets their new password
export const RESEND_CONFIRMATION = `${BACKEND}accountcore/resend`;

/* Users */
export const USER_FETCH = `${BACKEND}accountcore/fetch`;
export const USER_UPDATE = `${BACKEND}accountcore/update`;
export const USER_FETCH_ALL = `${BACKEND}accountcore/fetchAll`;
export const USER_ADMIN_UPDATE = `${BACKEND}accountcore/adminUpdate`;
export const CONTINUE_WITHOUT_REGISTERING = `${BACKEND}accountcore/continuewithoutregistering`;

/* Projects */
export const FETCH_VERSION = `${BACKEND}commoncore/getversion`;
export const PROJECTS_FETCH_ALL = `${BACKEND}activitytypecore/getprojectlist`;
export const DOWNLOAD_EXPORT_PROJECT = `${BACKEND}activitytypecore/downloadexportproject`;
export const DOWNLOAD_PROJECT = `${BACKEND}activitytypecore/downloadproject`;
export const EMAIL_EXPORT_PROJECT = `${BACKEND}activitytypecore/emailexportproject`;
export const COPY_PROJECT = `${BACKEND}activitytypecore/CopyProject`;
export const DELETE_PROJECT = `${BACKEND}activitytypecore/DeleteProject`;
export const DELETE_PROJECTS = `${BACKEND}activitytypecore/DeleteProjects`;
export const BULK_EXPORT_PROJECTS = `${BACKEND}activitytypecore/emailexportprojects`;
export const EDIT_PROJECT = `${BACKEND}activitytypecore/editproject`;
export const CREATE_PROJECT = `${BACKEND}activitytypecore/createproject`;
export const LIST_PROJECT = `${BACKEND}activitytypecore/list`;
export const IMPORT_MANAGEMENT = `${BACKEND}activitytypecore/importData`;

/* Demo Project */
export const ADD_DEMO_PROJECT = `${BACKEND}activitytypecore/insertdemo`;

/* Cropland Management */
export const CROPLANDS_FETCH_LOCATIONLIST = `${BACKEND}croplandcore/getlocationsbyproject`;
export const CROPLANDS_MANAGEMENT = `${BACKEND}croplandcore/getmanagementandoptions`;
export const SAVE_CROPYEARS = `${BACKEND}croplandcore/savecropyears`;
export const COPY_MANAGEMENT_YEAR = `${BACKEND}croplandcore/copycropyears`;
export const CREATE_NEW_SCENARIO = `${BACKEND}croplandcore/newscenario`;
export const DELETE_SCENARIO = `${BACKEND}croplandcore/deletescenario`;
export const SAVE_CPA = `${BACKEND}croplandcore/InsertOtherConsv`;
export const CREATE_TEMPLATE_ROTATON = `${BACKEND}croplandcore/CreateTemplate`;
export const CHANGE_CROP_SCENARIO_NAME = `${BACKEND}croplandcore/renamescenario`;

/* Cropland Report */
export const GET_CROPLAND_REPORT = `${BACKEND}fieldreportcore/getreport`;
export const GET_CROPLAND_YEARS = `${BACKEND}fieldreportcore/getreportyears`;
export const GET_SINGLE_CROPLAND_REPORT = `${BACKEND}fieldreportcore/getsinglereport`;
export const DOWNLOAD_DAYCENT_FILES = `${BACKEND}fieldreportcore/downloaddaycentfiles`;
export const GET_WATER_HOLDING_CAPACITY_DATA = `${BACKEND}fieldreportcore/getsingleprojectjson`;

/* Location */
export const COPY_ROTATION = `${BACKEND}fieldlocationcore/CopyRotationToFields`;
export const LOCATION_FETCH = `${BACKEND}fieldlocationcore/getfieldlist`;
export const ADD_NEW_LOCATION = `${BACKEND}fieldlocationcore/addnewlocation`;
export const DELETE_LOCATION = `${BACKEND}fieldlocationcore/deletelocation`;
export const DELETE_SELECTED_LOCATIONS = `${BACKEND}fieldlocationcore/deleteselectedlocations`;
export const UPDATE_LOCATION = `${BACKEND}fieldlocationcore/updatelocation`;
export const UPLOAD_ESRI_FILE = `${BACKEND}fieldlocationcore/uploadesrishapefile`;
export const GET_SOIL_DATA = `${BACKEND}soilcomponentcore/detail`;
export const DOWNLOAD_SOIL_DATA = `${BACKEND}soilcomponentcore/downloadsoildata`;
export const HISTORIC_MANAGEMENT_FOR_FIELD_FETCH = `${BACKEND}fieldlocationcore/gethistoricmanagment`;
export const HISTORIC_MANAGEMENT_SAVE = `${BACKEND}fieldlocationcore/savehistoricmanagement`;
export const GEOLOCATE_START =
  "https://dev.virtualearth.net/REST/v1/Locations?query=";
export const GEOLOCATE_END =
  "&key=AodaDoXgognX4vhPqaouMx44frx2G9a5Swio1r9aGK0iOiUNyYu9jnqpHMT0_1hL";

/* Agroforestry */
export const AGROFORESTRY_FETCH_PROJECT = `${BACKEND}agroforestrymanagementcore/fetch`;
export const AGROFORESTRY_STATE_LIST = `${BACKEND}agroforestrylocationcore/fetchstatelist`;
export const AGROFORESTRY_COUNTY_LIST = `${BACKEND}agroforestrylocationcore/fetchcountylist`;
export const AGROFORESTRY_UPDATE_LOCATION = `${BACKEND}agroforestrylocationcore/updatelocation`;
export const AGROFORESTRY_SCENARIO_LIST = `${BACKEND}agroforestrymanagementcore/listlocationscenarios`;
export const AGROFORESTRY_LIST_SPECIES_GROUPS = `${BACKEND}agroforestrymanagementcore/listspeciesgroups`;
export const AGROFORESTRY_UPDATE_AND_SAVE = `${BACKEND}agroforestrymanagementcore/updateandsave`;
export const AGROFORESTRY_UPDATE_USER_SCENARIO = `${BACKEND}agroforestrymanagementcore/updateuserscenarioname`;
export const AGROFORESTRY_DELETE_DETAILS = `${BACKEND}agroforestrymanagementcore/deletedetails`;
export const AGROFORESTRY_DELETE_TYPE = `${BACKEND}agroforestrymanagementcore/deletetype`;
export const AGROFORESTRY_REMOVE_OLD = `${BACKEND}agroforestrymanagementcore/deleteold`;
export const AGROFORESTRY_GET_REPORT = `${BACKEND}agroforestryreportcore/getreport`;

/* Forestry */
export const FORESTRY_FETCH_PROJECT = `${BACKEND}forestrymanagementcore/fetchprojectdetails`;
export const FORESTRY_VALIDATE = `${BACKEND}forestrymanagementcore/validate`;
export const FORESTRY_CHECK_INCOMPLETE = `${BACKEND}forestrymanagementcore/incomplete`;
export const FORESTRY_UPDATE_DETAILS = `${BACKEND}forestrymanagementcore/updatedetails`;
export const FORESTRY_FETCH_MANAGEMENT_DETAILS = `${BACKEND}forestrymanagementcore/fetchdetails`;
export const FORESTRY_FETCH_FOREST_TYPES = `${BACKEND}forestrymanagementcore/fetchforesttypes`;
export const FORESTRY_GET_REPORT = `${BACKEND}forestryreportcore/getreport`;

/* Newsletter */
export const REGISTER_NEWSLETTER = `${BACKEND}commoncore/newsletter`;

/* Contact Us */
export const CONTACT_US = `${BACKEND}commoncore/contactus`;

/* Animal Ag */
export const FETCH_ANIMAL_OPERATION = `${BACKEND}animalagriculturelocationcore/fetchanimaloperation`;
export const FETCH_ALL_CATEGORIES = `${BACKEND}animalagriculturelocationcore/fetchallcategories`;
export const UPDATE_ANIMAL_CATEGORIES = `${BACKEND}animalagriculturelocationcore/updatecategories`;
export const FETCH_ALL_POPULATION = `${BACKEND}animalcharacteristicscore/detailpopbycategoryscenario`;
export const FETCH_POPULATIONS = `${BACKEND}animalcharacteristicscore/detailpopbyscenario`;
export const FETCH_ALL_CATEGORYNAMES = `${BACKEND}animalcharacteristicscore/fetchcategorynames`;
export const FETCH_ALL_PANELS = `${BACKEND}animalcharacteristicscore/fetchcategorybyname`;
export const UPDATE_DAIRY_POPULATIONS = `${BACKEND}animalcharacteristicscore/updatedairypopulations`;
export const FETCH_DAIRY_POPULATION = `${BACKEND}animalcharacteristicscore/fetchdairypopulation`;

export const FETCH_DAIRY_POPULATION_MONTHS = `${BACKEND}animalcharacteristicscore/fetchdairypopulationmonths`;
export const UPDATE_HOUSING_SYSTEM_DETAILS = `${BACKEND}animalcharacteristicscore/updatehousingsystemdetails`;
export const FETCH_HOUSING = `${BACKEND}animalcharacteristicscore/fetchhousing`;
export const FETCH_FEED_LOOKUPS = `${BACKEND}animalcharacteristicscore/fetchfeedlookups`;
export const POPULATE_FEEDS = `${BACKEND}animalagricultureoperationcore/populatefeedspanel`;
export const FETCH_FEEDS = `${BACKEND}animalcharacteristicscore/fetchfeedstabledata`;
export const GET_FEEDS = `${BACKEND}animalcharacteristicscore/fetchfeedfactors`;
export const GET_FEEDLOT_FEEDS = `${BACKEND}animalcharacteristicscore/fetchfeedlotfeedfactors`;
export const UPDATE_FEEDS = `${BACKEND}animalcharacteristicscore/updatefeedfactors`;
export const FETCH_FEED_ADDTVES_LOOKUPS = `${BACKEND}animalcharacteristicscore/fetchfeedaddtveslookups`;
export const UPDATE_FEED_ADDITIVES = `${BACKEND}animalcharacteristicscore/updatefeedadditives`;
export const FETCH_FEED_ADDITIVES = `${BACKEND}animalcharacteristicscore/fetchfeedadditives`;
export const SORT_FEEDS = `${BACKEND}animalcharacteristicscore/sortfeeds`;
export const UPDATE_ANIMAL_OPERATION = `${BACKEND}animalagriculturelocationcore/updateanimaloperation`;

export const FETCH_ALL_BARNNAMES = `${BACKEND}animalcharacteristicscore/fetchbarnnames`;
export const UPDATE_MANURE = `${BACKEND}animalcharacteristicscore/updatemanureops`;
export const FETCH_MANURE = `${BACKEND}animalcharacteristicscore/fetchmanure`;
export const VALIDATE_DAIRY = `${BACKEND}animalagricultureoperationcore/validatedairyinputs`;
export const FETCH_ALL_SCENARIOS = `${BACKEND}animalcharacteristicscore/fetchallscenarios`;
export const FETCH_POP_BY_SCENARIO = `${BACKEND}animalcharacteristicscore/fetchpopbyscenario`;
export const INSERT_SCENARIO = `${BACKEND}animalagriculturelocationcore/insertscenario`;
export const DELETE_ANIMAL_SCENARIO = `${BACKEND}animalcharacteristicscore/deleteanimalscenario`;
export const FETCH_BASELINE_SCENARIO = `${BACKEND}animalcharacteristicscore/fetchbaselinescenario`;
export const RUN_ANIMAL_REPORTS = `${BACKEND}animalreportcore/reportgriddata`;
export const FETCH_ALL_YEARS = `${BACKEND}animalcharacteristicscore/fetchallyears`;
export const COPY_YEARS = `${BACKEND}animalagriculturelocationcore/copyyears`;
export const FETCH_YEARS_WITH_DATA = `${BACKEND}animalcharacteristicscore/fetchyearswithdata`;
export const ADD_FEEDLOT_RATIONGROUP = `${BACKEND}animalagriculturelocationcore/addfeedlotrationgroup`;
export const UPDATE_FEEDLOT_POPULATIONS = `${BACKEND}animalcharacteristicscore/updatefeedlotpopulations`;
export const FETCH_ALL_RATION_GROUPS = `${BACKEND}animalcharacteristicscore/fetchallrationgroups `;
export const FETCH_ALL_FEEDLOT_RATION_GROUPS = `${BACKEND}animalcharacteristicscore/fetchallfeedlotrationgroups `;
export const FETCH_VALIDATE_FEEDLOT_RATION_GROUPS = `${BACKEND}animalcharacteristicscore/fetchvalidatefeedLotrationgroups `;
export const FETCH_ALL_FEEDLOT_POPULATIONS = `${BACKEND}animalcharacteristicscore/fetchallfeedlotpopulations `;
export const UPDATE_FEEDLOT_FEEDS = `${BACKEND}animalcharacteristicscore/updatefeedlotfeedfactors`;
export const POPULATE_FEEDLOT_FEEDS = `${BACKEND}animalagricultureoperationcore/populatefeedlotfeedspanel`;
export const COPY_FEEDLOT_RATIONS = `${BACKEND}animalagriculturelocationcore/copyfeedlotrations`;
export const UPDATE_FEEDLOT_WEIGHTS = `${BACKEND}animalcharacteristicscore/updatefeedlotweights`;
export const GET_FEEDLOT_WEIGHTS = `${BACKEND}animalcharacteristicscore/fetchfeedlotweights`;
export const UPDATE_FEEDLOT_ADDITIVES = `${BACKEND}animalcharacteristicscore/updatefeedlotfeedadditives`;
export const FETCH_FEEDLOT_ADDITIVES = `${BACKEND}animalcharacteristicscore/fetchfeedlotadditives`;
export const UPDATE_GRAZING_ADDITIVES = `${BACKEND}animalcharacteristicscore/updategrazingfeedadditives`;
export const FETCH_GRAZING_ADDITIVES = `${BACKEND}animalcharacteristicscore/fetchgrazingfeedadditives`;
export const FETCH_ALL_RATIONS_WITHOUT_DATA = `${BACKEND}animalcharacteristicscore/fetchrationswithoutdata `;
export const DELETE_RATION = `${BACKEND}animalcharacteristicscore/deleteration `;
export const FETCH_REMAINING_RATION_GROUPS = `${BACKEND}animalcharacteristicscore/fetchremainingrationgroups `;
export const UPDATE_SWINE_GROWING = `${BACKEND}animalcharacteristicscore/updateswinegrowing`;
export const UPDATE_SWINE_BREEDING = `${BACKEND}animalcharacteristicscore/updateswinebreeding`;
export const FETCH_SWINE = `${BACKEND}animalcharacteristicscore/fetchswine`;
export const UPDATE_GENERIC_POPULATIONS = `${BACKEND}animalcharacteristicscore/updategenericpopulations`;
export const FETCH_GENERIC_POPULATION = `${BACKEND}animalcharacteristicscore/fetchgenericpopulation`;

export const FETCH_POULTRY = `${BACKEND}animalcharacteristicscore/fetchpoultry`;
export const UPDATE_POULTRY = `${BACKEND}animalcharacteristicscore/updatepoultry`;

export const MANURE_FOLLOWUP_QUES = `${BACKEND}animalcharacteristicscore/manurefollowupexists `;
export const UPDATE_MANURE_FOLLOWUP = `${BACKEND}animalcharacteristicscore/updatemanurefollowup `;
export const UPDATE_MANURE_FOLLOWUP_LOOKUP = `${BACKEND}animalcharacteristicscore/FetchLookUpDataForManureFollowup`;
export const FETCH_MANURE_FOLLOWUP = `${BACKEND}animalcharacteristicscore/fetchmanurefollowup`;
export const FETCH_DAIRY_GROUPS = `${BACKEND}animalcharacteristicscore/fetchdairygroups`;
export const UPDATE_GRAZING_POPULATIONS = `${BACKEND}animalcharacteristicscore/updategrazingpopulations`;
export const FETCH_GRAZING_POPULATION = `${BACKEND}animalcharacteristicscore/fetchgrazingpopulation`;
export const GET_GRAZING_FEEDS = `${BACKEND}animalcharacteristicscore/fetchgrazingfeeds`;
export const UPDATE_GRAZING_FEEDS = `${BACKEND}animalcharacteristicscore/updategrazingfeeds`;
export const GET_GRAZING_FEEDS_LOOKUPS = `${BACKEND}animalcharacteristicscore/getgrazingfeedlookups`;
export const UPDATE_SHEEP_DMI = `${BACKEND}animalcharacteristicscore/updatesheepdmi`;
export const FETCH_SHEEP_DMI = `${BACKEND}animalcharacteristicscore/fetchsheepdmi`;
export const FETCH_SHEEP_FEED = `${BACKEND}animalcharacteristicscore/fetchsheepdmifeed`;
export const UPDATE_SHEEP_FEED = `${BACKEND}animalcharacteristicscore/updatesheepdmifeed`;
export const UPDATE_SHEEP_GOAT_WEIGHT = `${BACKEND}animalcharacteristicscore/UpdateSheepGoatWeight`;
export const UPDATE_SHEEP_GOAT_POPULATION = `${BACKEND}animalcharacteristicscore/updatesheepgoatpopulation`;
export const FETCH_SHEEP_GOAT_WEIGHT = `${BACKEND}animalcharacteristicscore/fetchsheepgoatweight`;
export const FETCH_SHEEP_GOAT_GEST_LACT = `${BACKEND}animalcharacteristicscore/fetchsheepgoatgestlact`;
export const UPDATE_SHEEP_GOAT_GEST_LACT = `${BACKEND}animalcharacteristicscore/updatesheepgoatgestlact`;
export const FETCH_SHEEP_WOOL = `${BACKEND}animalcharacteristicscore/fetchsheepwool`;
export const UPDATE_SHEEP_WOOL = `${BACKEND}animalcharacteristicscore/updatesheepwool`;
export const UPDATE_SHEEP_GOAT_FEEDS = `${BACKEND}animalcharacteristicscore/updatesheepgoatfeeds`;
export const POPULATE_SHEEP_GOAT_FEEDS = `${BACKEND}animalagricultureoperationcore/populatesheepgoatfeedspanel`;
export const FETCH_ANAG_STATES = `${BACKEND}animalagriculturelocationcore/fetchstatelist`;
export const FETCH_ANAG_COUNTIES = `${BACKEND}animalagriculturelocationcore/fetchcountylist`;
export const UPDATE_ANIMAL_COUNTY = `${BACKEND}animalagriculturelocationcore/updateanimalcounty`;
export const FETCH_ACTIVITY_PROGRESS = `${BACKEND}animalagriculturelocationcore/fetchuseractivityprogress`;
export const UPDATE_GOAT_POP = `${BACKEND}animalcharacteristicscore/updategoatpopulation`;
export const UPDATE_GOAT_FEED = `${BACKEND}animalcharacteristicscore/updategoatfeed`;
export const POPULATE_GOAT_FEED = `${BACKEND}animalagricultureoperationcore/populategoatfeedspanel`;
export const UPDATE_GOAT_WEIGHTS = `${BACKEND}animalcharacteristicscore/updategoatweights`;
export const FETCH_GOAT_GEST_LACT = `${BACKEND}animalcharacteristicscore/fetchgoatgestlact`;
export const UPDATE_GOAT_GEST_LACT = `${BACKEND}animalcharacteristicscore/updategoatgestlact`;
export const FETCH_GOAT_GEST_LACT_EXISTS = `${BACKEND}animalcharacteristicscore/fetchgoatgestlactexists`;

/* API */
export const GENERATE_NEW_API_KEY = `${BACKEND}apimain/generatenewapikey`;
export const UPLOAD_API_FILE = `${BACKEND}apimain/uploadapiqueue`;
export const TRIGGER_API_CHECK = `${BACKEND}apimain/InitiateApiCheck`;
export const GET_API_STATUS = `${BACKEND}apimain/GetAPiStatus`;

/* Quick Energy */
export const GET_QUICK_ENERGY = `${BACKEND}quickenergycore/index`;
export const GET_ENERGY_COEFFICIENTS = `${BACKEND}quickenergycore/listcoefficients`;
