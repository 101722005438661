// React
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

// MUI
import {
  Grid,
  Dialog,
  Divider,
  IconButton,
  Tooltip,
  Checkbox,
  ListItem,
  Link,
  Radio,
  Icon,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { AddCircle, Close, PlusOne } from "@mui/icons-material";
import { pink, red, grey } from "@mui/material/colors";
import dayjs from "dayjs";

import { DataGrid } from "@mui/x-data-grid";
import { TbPingPong } from "react-icons/tb";
import irrigatedFalse from "assets/cFarmImages/DragNDropTemplates/irrigated-false.png";
import irrigatedTrue from "assets/cFarmImages/DragNDropTemplates/irrigated-true.png";
import CPA345 from "assets/cFarmPDFs/CPA/345.pdf";
import colors from "assets/theme/base/colors";

// Our Components
import CFBox from "components/CFBox";
import CFTypography from "components/CFTypography";
import CFButton from "components/CFButton";

import { popupError, preloadImage } from "utils/generic";
import { post } from "utils/api";
import { SAVE_CPA, CREATE_TEMPLATE_ROTATON } from "constants/api";
import CFLoading from "components/CFLoading";
import CFStep from "components/CFarmParts/CFStep";
import CFLookupPicker from "components/CroplandsComponents/CFLookupPicker/CFLookupPicker";
import { fetchUser } from "actions/user";
import CropRotationBuilderHelpImage from "assets/cFarmImages/HelpImages/CropRotationBuilder-HelpImage.webp";
import DeleteButton from "../../../../../components/CroplandsComponents/EditCropCard_StructuralComponents/DeleteButton";

export default function PredefinedTemplatesWindow({
  isOpen,
  setIsOpen,
  currentUser,
  fieldId,
  cropRotationId,
  fetchManagement,
  allManagementOptions,
}) {
  const dispatch = useDispatch();
  const handleClose = () => {
    setIsOpen(false);
  };
  preloadImage(CropRotationBuilderHelpImage);

  const [loadingMessage, setLoading] = useState("");
  const [templateRows, settemplateRows] = useState([]);
  const [stateCount, setstateCount] = useState(2);
  const [saveEnabled, setSaveEnabled] = useState(false);
  useEffect(() => {
    settemplateRows([
      {
        crop: null,
        irrigation: false,
        preplant: null,
        postharvest: null,
        id: 0,
      },
      {
        crop: null,
        irrigation: false,
        preplant: null,
        postharvest: null,
        id: 1,
      },
    ]);
  }, [fieldId, cropRotationId, isOpen]);

  useEffect(() => {
    for (let i = 0; i < templateRows.length; i += 1) {
      if (!templateRows[i].crop) {
        setSaveEnabled(false);
        return;
      }
    }
    setSaveEnabled(true);
  }, [templateRows]);

  const togI = (id, tog) => {
    const theRow = templateRows.filter((x) => x.id === id)[0];

    const index = templateRows.indexOf(theRow);
    const newT = [...templateRows];

    newT[index].irrigation = tog;
    settemplateRows(newT);
  };

  const setCrop = (inxex, newCrop) => {
    const theRow = templateRows.filter((x) => x.id === inxex)[0];

    const index = templateRows.indexOf(theRow);
    const newT = [...templateRows];

    newT[index].crop = newCrop;
    settemplateRows(newT);
  };

  const renderCropColumns = (cell) => {
    const theRow = templateRows.filter((x) => x.id === cell.id)[0];
    return (
      <CFBox sx={{ display: "inline-flex", width: "100%" }}>
        <CFLookupPicker
          value={theRow[cell.field]}
          onChange={(event, newValue) => {
            setCrop(cell.id, newValue);
          }}
          label="Select a crop"
          options={allManagementOptions.templatecroplist}
        />
        <div style={{ color: "red", display: "inline" }}>✵</div>
      </CFBox>
    );
  };

  const renderIrrigation = (cell) => {
    if (cell.formattedValue) {
      return (
        <CFBox onClick={() => togI(cell.id, false)}>
          <img
            style={{ maxHeight: "3em" }}
            className="photo"
            src={irrigatedTrue}
            alt="irrigated"
          />
        </CFBox>
      );
    }
    return (
      <CFBox onClick={() => togI(cell.id, true)}>
        <img
          style={{ maxHeight: "3em" }}
          src={irrigatedFalse}
          alt="not-irrigated"
        />
      </CFBox>
    );
  };

  const addRow = () => {
    const newT = [...templateRows];

    newT.push({
      crop: null,
      irrigation: false,
      preplant: null,
      postharvest: null,
      id: stateCount,
    });
    setstateCount(stateCount + 1);
    settemplateRows(newT);
  };
  const Delete = (cellId) => {
    const theRow = templateRows.filter((x) => x.id === cellId)[0];
    const newT = [...templateRows];
    const index = newT.indexOf(theRow);
    newT.splice(index, 1);

    settemplateRows(newT);
  };
  const renderDelete = (cell) => {
    if (cell.id !== 0)
      return (
        <DeleteButton
          deleteAction={() => {
            Delete(cell.id);
          }}
        />
      );
    return null;
  };

  const setTilalge = (inxex, field, newCrop) => {
    const theRow = templateRows.filter((x) => x.id === inxex)[0];

    const index = templateRows.indexOf(theRow);
    const newT = [...templateRows];

    newT[index][field] = newCrop;
    settemplateRows(newT);
  };
  const renderTill = (cell) => {
    const theRow = templateRows.filter((x) => x.id === cell.id)[0];
    return (
      <CFLookupPicker
        sx={{ width: "100%" }}
        value={theRow[cell.field]}
        onChange={(event, newValue) => {
          setTilalge(cell.id, cell.field, newValue);
        }}
        label="Select Tillage Intensity"
        options={allManagementOptions.templatetillages}
      />
    );
  };
  const columns = [
    {
      field: "crop",
      headerName: "Crop",
      flex: 0.7,
      editable: false,
      disableReorder: true,
      sortable: false,
      filterable: false,
      groupable: false,
      hidable: false,
      pinnable: false,
      aggregable: false,
      renderCell: renderCropColumns,
    },
    {
      field: "irrigation",
      headerName: "Irrigation",
      flex: 0.3,
      editable: false,
      disableReorder: true,
      sortable: false,
      filterable: false,
      groupable: false,
      hidable: false,
      pinnable: false,
      aggregable: false,
      renderCell: renderIrrigation,
    },

    {
      field: "preplant",
      headerName: "Tillage-System Intensity Pre-plant",
      flex: 1,
      editable: false,
      disableReorder: true,
      sortable: false,
      filterable: false,
      groupable: false,
      hidable: false,
      pinnable: false,
      aggregable: false,
      renderCell: renderTill,
    },
    {
      field: "postharvest",
      headerName: "Tillage-System Intensity Post Harvest",
      flex: 1,
      editable: false,
      disableReorder: true,
      sortable: false,
      filterable: false,
      groupable: false,
      hidable: false,
      pinnable: false,
      aggregable: false,
      renderCell: renderTill,
    },
    {
      field: "delete",
      headerName: "Delete",
      flex: 0.3,
      editable: false,
      disableReorder: true,
      sortable: false,
      filterable: false,
      groupable: false,
      hidable: false,
      pinnable: false,
      aggregable: false,
      renderCell: renderDelete,
    },
  ];

  const SaveAndClose = () => {
    setLoading("Saving...");
    if (fieldId === 0) {
      return;
    }
    post(CREATE_TEMPLATE_ROTATON, {
      user: currentUser,
      fieldid: fieldId,
      cropRotationId,
      templateRows,
    })
      .then((res) => {
        if (res.error) {
          popupError(`Create Template Rotation Error ${res.error}`, dispatch);
        }
        dispatch(fetchUser(currentUser));
        fetchManagement();
        handleClose();
      })
      .catch((err) => {
        popupError(`Create Template Rotation Error ${err}`, dispatch);
        console.log(err);
      })
      .finally(() => {
        setLoading("");
      });
  };

  return (
    <Dialog
      open={isOpen}
      fullScreen
      PaperProps={{
        style: { borderRadius: ".3em" },
      }}
      sx={{ padding: "3em", zIndex: 5 }}
      onClose={handleClose}
    >
      {loadingMessage !== "" && <CFLoading message={loadingMessage} local />}{" "}
      <CFBox sx={{ margin: "2em", position: "absolute", right: "2em" }} />
      <IconButton
        onClick={() => handleClose()}
        style={{ position: "absolute", right: 0, margin: ".5em", padding: 0 }}
      >
        <Tooltip title="Close">
          <Close />
        </Tooltip>
      </IconButton>
      <Grid container item xs={12}>
        <CFBox sx={{ padding: "2em" }}>
          <Grid container direction="row">
            {/* Window Header */}
            <Grid
              container
              item
              xs={12}
              sx={{ paddingLeft: "1em", paddingTop: "1em" }}
            />
            <CFStep
              stepLabel="Crop Rotation Builder"
              helpText={
                <img
                  src={CropRotationBuilderHelpImage}
                  // width="100%"
                  alt="Layout of Baseline Management"
                />
              }
              useGuideWidth={`${Math.min(1440, window.innerWidth)}px`}
              subText={
                <div>
                  The Crop Rotation Builder allows users to build a crop
                  rotation with pre-defined management data for select dates.
                  Default data includes typical planting dates, harvest dates,
                  irrigation rates, residue removal, and fertilizer application
                  rates and dates appropriate for the field&apos;s location.
                  Users may modify or delete any default management from the
                  rotation builder. Using the rotation builder after you have
                  added crop and management details to a baseline or scenario{" "}
                  <strong>
                    will result in overwriting the existing rotation
                  </strong>{" "}
                  with Rotation Builder defaults.
                </div>
              }
            />
          </Grid>
          <Grid container item xs={12}>
            <DataGrid
              rows={templateRows}
              columns={columns}
              hideFooter
              disableRowSelectionOnClick
              disableColumnSelector
              disableColumnMenu
              disableColumnFilter
            />
          </Grid>
          <Grid container py={2} px={2} justifyContent="space-between">
            <CFButton color="secondary" onClick={handleClose}>
              Cancel
            </CFButton>
            <CFBox>
              <CFButton color="primary" onClick={addRow}>
                +Add Crop
              </CFButton>
              <CFButton
                sx={{ marginLeft: "2em" }}
                color="progress"
                disabled={!saveEnabled}
                onClick={SaveAndClose}
              >
                Save
              </CFButton>
            </CFBox>
          </Grid>
        </CFBox>
      </Grid>
    </Dialog>
  );
}
