/* eslint-disable react/no-array-index-key */
import { useEffect, useState } from "react";
import {
  Grid,
  Input,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";

// Date picker
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";

import { Box } from "@mui/system";

import colors from "assets/theme/base/colors";
import CFLookupPicker from "components/CroplandsComponents/CFLookupPicker/CFLookupPicker";
import CFManagementSelectionCard from "components/CroplandsComponents/CFManagementSelectionCard/CFManagementSelectionCard";
import ManHelp from "assets/cFarmImages/HelpImages/ManHelp.webp";
import { preloadImage } from "utils/generic";
import { croplandTableStyle } from "pages/Croplands/CroplandStyle";
import CFBox from "components/CFBox";
import HelpAndButton from "../../../../../components/CroplandsComponents/EditCropCard_StructuralComponents/HelpAndButton";
import DeleteButton from "../../../../../components/CroplandsComponents/EditCropCard_StructuralComponents/DeleteButton";
import WhiteBackgroundDatepicker from "../../../../../components/CroplandsComponents/EditCropCard_StructuralComponents/WhiteBackgroundDatepicker";

export default function ({
  CropEvent,
  SetPropertyFunction,
  FieldName,
  manureApplications,
  fertilizerRatios,
}) {
  preloadImage(ManHelp);
  const minDate = dayjs()
    .set("year", CropEvent.cropYear)
    .set("date", 1)
    .set("month", 0);
  const maxDate = dayjs()
    .set("year", CropEvent.cropYear + 1)
    .set("date", 1)
    .set("month", 0);

  const [LocalManures, setLocalManures] = useState([]);

  useEffect(() => {
    const LM = [...CropEvent.manureApplications];
    for (let i = 0; i < LM.length; i += 1) {
      const pDry = 1 - LM[i].percentWet / 100;

      LM[i].wetFert = parseFloat((LM[i].totalApplied / pDry).toFixed(2));
      LM[i].percentNWet = parseFloat((LM[i].percentN * pDry).toFixed(2));
    }
    setLocalManures([...LM]);
  }, [CropEvent]);

  const SetTotalApplied = (nval, index) => {
    let newVal = nval;
    if (newVal < 0) {
      newVal = 0;
    }
    if (newVal > 100) {
      newVal = 100;
    }
    const newCE = { ...CropEvent };
    newCE.manureApplications[index].wetFert = newVal;
    const pDry = 1 - newCE.manureApplications[index].percentWet / 100;
    newCE.manureApplications[index].totalApplied =
      newCE.manureApplications[index].wetFert * pDry;
    SetPropertyFunction(newCE);
  };

  const SetMoisture = (newVal, index) => {
    let setPercent = newVal;
    if (setPercent > 100) {
      setPercent = 100;
    }
    if (setPercent < 0) {
      setPercent = 0;
    }
    const newCE = { ...CropEvent };
    newCE.manureApplications[index].percentWet = setPercent;
    const pDry = 1 - newCE.manureApplications[index].percentWet / 100;
    newCE.manureApplications[index].totalApplied =
      newCE.manureApplications[index].wetFert * pDry;
    SetPropertyFunction(newCE);
  };

  const SetNitrogenPercent = (newVal, index) => {
    // user is setting the N percent of wet manure, and we're saving the N percent of dry manure
    let setPercent = newVal;
    if (setPercent > 100) {
      setPercent = 100;
    }
    if (setPercent < 0) {
      setPercent = 0;
    }
    const newCE = { ...CropEvent };
    newCE.manureApplications[index].percentNWet = setPercent;
    const pDry = 1 - newCE.manureApplications[index].percentWet / 100;
    newCE.manureApplications[index].percentN = setPercent / pDry;
    SetPropertyFunction(newCE);
  };

  const SetCNRatio = (newVal, index) => {
    const newCE = { ...CropEvent };
    newCE.manureApplications[index].carbonNitrogenRatio = newVal;

    SetPropertyFunction(newCE);
  };

  const addNewManureApplication = () => {
    const newCE = { ...CropEvent };
    const defaultFertRatio = fertilizerRatios[manureApplications[0].id];
    if (defaultFertRatio) {
      newCE.manureApplications.push({
        applicationDate: minDate.toISOString(),
        totalApplied: 0,

        percentN: defaultFertRatio.percentN,
        percentWet: defaultFertRatio.percentWet,
        carbonNitrogenRatio:
          Math.round(
            (defaultFertRatio.organicC / (defaultFertRatio.percentN / 100)) * 10
          ) / 10,
        manureType: manureApplications[0],
        id: 0,
      });
    } else {
      newCE.manureApplications.push({
        applicationDate: minDate.toISOString(),
        totalApplied: 0,

        percentN: 0,
        percentWet: 0,
        carbonNitrogenRatio: 0,
        manureType: manureApplications[0],
        id: 0,
      });
    }

    SetPropertyFunction(newCE);
  };

  const deleteManureApplication = (index) => {
    const newCE = { ...CropEvent };
    newCE.manureApplications.splice(index, 1);

    SetPropertyFunction(newCE);
  };

  const SetManureType = (newVal, index) => {
    const newCE = { ...CropEvent };

    newCE.manureApplications[index].manureType = newVal;

    const defaultFertRatio = fertilizerRatios[newVal.id];
    if (defaultFertRatio) {
      newCE.manureApplications[index].totalApplied = 0;

      newCE.manureApplications[index].wetFert = 0;
      newCE.manureApplications[index].percentN = defaultFertRatio.percentN;
      newCE.manureApplications[index].percentWet = defaultFertRatio.percentWet;
      newCE.manureApplications[index].carbonNitrogenRatio =
        Math.round(
          (defaultFertRatio.organicC / (defaultFertRatio.percentN / 100)) * 10
        ) / 10;
    } else {
      newCE.manureApplications[index].totalApplied = 0;

      newCE.manureApplications[index].wetFert = 0;
      newCE.manureApplications[index].percentN = 100;
      newCE.manureApplications[index].percentWet = 0;
      newCE.manureApplications[index].carbonNitrogenRatio = 0;
    }

    SetPropertyFunction(newCE);
  };

  const SetApplicationDate = (newVal, index) => {
    const newCE = { ...CropEvent };
    if (dayjs(newVal, true).isValid()) {
      newCE.manureApplications[index].applicationDate =
        dayjs(newVal).toISOString();
      SetPropertyFunction(newCE);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid alignItems="center" container flexDirection="row">
        <Grid item xs={12}>
          {LocalManures.map((app, ind) => (
            <Grid
              item
              xs={12}
              container
              flexDirection="row"
              alignItems="center"
              key={`application${ind}`}
              sx={{ paddingTop: ".5em", marginTop: "1em" }}
            >
              <Grid
                item
                xs={12}
                container
                flexDirection="row"
                alignItems="center"
              >
                <Grid item xl={5.5} xs={11}>
                  <CFManagementSelectionCard
                    label="Operation Date"
                    labelWidth={7}
                    input={
                      <WhiteBackgroundDatepicker
                        views={["year", "month", "day"]}
                        label="Date Applied"
                        maxDate={maxDate}
                        minDate={minDate}
                        style={{ backgroundColor: colors.white.main }}
                        slotProps={{ textField: { variant: "outlined" } }}
                        value={dayjs(app.applicationDate)}
                        onChange={(newValue) =>
                          SetApplicationDate(newValue, ind)
                        }
                      />
                    }
                  />
                </Grid>
                <Grid item xl={5.5} xs={11}>
                  <CFManagementSelectionCard
                    label="Amendment Type"
                    labelWidth={8}
                    input={
                      <CFLookupPicker
                        label="Select an amendment"
                        required
                        sx={{ width: "100%" }}
                        value={app.manureType}
                        onChange={(event, newValue) => {
                          SetManureType(newValue, ind);
                        }}
                        options={manureApplications}
                      />
                    }
                  />
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                container
                flexDirection="row"
                alignItems="center"
              >
                <Grid item xl={5.5} xs={11}>
                  <CFManagementSelectionCard
                    //  sx={{ marginTop: 0 }}
                    label=" Fresh Weight Applied ton/ac"
                    labelWidth={8}
                    input={
                      <Input
                        step="0.1"
                        label="ton/ac"
                        endAdornment={
                          <InputAdornment position="end">ton/ac</InputAdornment>
                        }
                        type="number"
                        sx={{
                          backgroundColor: colors.white.main,
                          fontSize: "1.25em",
                          paddingInline: ".3em",
                          borderRadius: ".3em",
                        }}
                        value={app.wetFert} // show total weight, but save dry weight
                        onChange={(e) => SetTotalApplied(e.target.value, ind)}
                      />
                    }
                  />
                </Grid>

                <Grid item xl={5.5} xs={11}>
                  <CFManagementSelectionCard
                    label="Moisture %"
                    labelWidth={8}
                    input={
                      <Input
                        label="%"
                        type="number"
                        endAdornment={
                          <InputAdornment position="end">%</InputAdornment>
                        }
                        sx={{
                          backgroundColor: colors.white.main,
                          fontSize: "1.25em",
                          paddingInline: ".3em",
                          borderRadius: ".3em",
                        }}
                        value={app.percentWet}
                        onChange={(e) => SetMoisture(e.target.value, ind)}
                      />
                    }
                  />
                </Grid>

                <Grid item xs={1}>
                  <DeleteButton
                    deleteAction={() => deleteManureApplication(ind)}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                container
                flexDirection="row"
                alignItems="center"
              >
                <Grid item xl={5.5} xs={11}>
                  <CFManagementSelectionCard
                    //  sx={{ marginTop: 0 }}
                    label="Nitrogen Content (%)"
                    labelWidth={8}
                    input={
                      <Input
                        label="%"
                        endAdornment={
                          <InputAdornment position="end">%</InputAdornment>
                        }
                        type="number"
                        sx={{
                          backgroundColor: colors.white.main,
                          fontSize: "1.25em",
                          paddingInline: ".3em",
                          borderRadius: ".3em",
                        }}
                        value={app.percentNWet}
                        onChange={(e) =>
                          SetNitrogenPercent(e.target.value, ind)
                        }
                      />
                    }
                  />
                </Grid>

                <Grid item xl={5.5} xs={11}>
                  <CFManagementSelectionCard
                    label="C/N Ratio"
                    labelWidth={8}
                    input={
                      <Input
                        type="number"
                        sx={{
                          backgroundColor: colors.white.main,
                          fontSize: "1.25em",
                          paddingInline: ".3em",
                          borderRadius: ".3em",
                        }}
                        value={app.carbonNitrogenRatio}
                        onChange={(e) => SetCNRatio(e.target.value, ind)}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={11}>
                  <CFManagementSelectionCard
                    label={`N Applied from ${app.manureType.name}`}
                    labelWidth={7}
                    input={
                      <Box fontWeight="fontWeightMedium" display="inline">
                        {` ${(
                          parseFloat(app.totalApplied) *
                          // 2000 *
                          (parseFloat(app.percentN) / 100)
                        ).toFixed(2)}ton/ac`}
                      </Box>
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          ))}
          <HelpAndButton
            buttonLabel="+ Add Organic Amendment"
            buttonAction={addNewManureApplication}
            userGuidePopupWidth="900px"
            helperText={
              <CFBox sx={{ overflow: "auto", height: "600px" }}>
                <p>
                  Users may add fertilizer, manure, or compost events in the{" "}
                  <em>Nutrient Management</em> activity data section. The total
                  nitrogen (lbs/ac) applied to the crop will be summed when
                  fertilizer and/or organic amendment events are added.
                </p>
                <br />
                <p>
                  To add organic amendment application(s), click{" "}
                  <strong>+Add Organic Amendment</strong>. Users will be
                  prompted to enter information on their application date,
                  select their manure type from a dropdown menu, and provide a
                  value for fresh weight applied (lb/ac). Fresh weight applied
                  can be entered as dry or wet amounts; slurry amounts are
                  entered in increments of 1,000 gal/acre, and manure is entered
                  as solid and organic fertilizers are entered as tons/acre.
                  Default values will populate for moisture (%), nitrogen
                  content (%), and C/N ratio (lbs N/ac), but users can edit
                  these values if desired. Please review an amendment lab
                  analysis if the parameters are unknown.
                </p>
                <style>{croplandTableStyle}</style>
                <table>
                  <tbody>
                    <tr>
                      <td>Organic Amendment Table Input</td>
                      <td>Description</td>
                    </tr>
                    <tr>
                      <td>Date</td>
                      <td>When the manure/compost is applied.</td>
                    </tr>
                    <tr>
                      <td>Amendment Type</td>
                      <td>
                        Selection of common amendment types (manures, slurries,
                        compost). When users select a type from the dropdown,
                        COMET will default N%, C:N, and moisture % from the NRCS
                        manure management handbook. Users may edit any defaults
                        COMET provides. If an amendment is unavailable, please
                        select “other manure” and enter the required details.
                      </td>
                    </tr>
                    <tr>
                      <td>Moisture %</td>
                      <td>Moisture percentage of the fresh weight applied.</td>
                    </tr>
                    <tr>
                      <td>Nitrogen Content (%)</td>
                      <td>
                        <p>
                          Percent Nitrogen content. Slurry analyses often
                          present Lbs N/1000 gal instead of N%. Example 15.19
                          lbs N/1000 gal to %N content conversion:
                        </p>
                        <br />
                        <blockquote>
                          {" "}
                          Average dairy slurry weight = 8.5lb/gal{" "}
                        </blockquote>
                        <table>
                          <tr>
                            <td style={{ border: "none" }}>
                              {" "}
                              <div style={{ float: "left" }}>
                                <div
                                  style={{
                                    borderBottom: "1px solid",
                                    fontSize: "small",
                                    textAlign: "center",
                                  }}
                                >
                                  15.19 <sub>lbs N</sub>
                                </div>
                                <div
                                  style={{
                                    fontSize: "small",
                                    textAlign: "center",
                                  }}
                                >
                                  1000 <sub>gal slurry</sub>
                                </div>
                              </div>
                            </td>
                            <td
                              style={{
                                border: "none",
                                fontSize: "small",
                                textAlign: "center",
                              }}
                            >
                              x
                            </td>
                            <td style={{ border: "none" }}>
                              {" "}
                              <div style={{ float: "left" }}>
                                <div
                                  style={{
                                    borderBottom: "1px solid",
                                    fontSize: "small",
                                    textAlign: "center",
                                  }}
                                >
                                  1 <sub>gal slurry</sub>
                                </div>
                                <div
                                  style={{
                                    fontSize: "small",
                                    textAlign: "center",
                                  }}
                                >
                                  8.5 <sub>lbs slurry</sub>
                                </div>
                              </div>
                            </td>
                            <td
                              style={{
                                border: "none",
                                fontSize: "small",
                                textAlign: "center",
                              }}
                            >
                              =
                            </td>

                            <td style={{ border: "none" }}>
                              {" "}
                              <div style={{ float: "left" }}>
                                <div
                                  style={{
                                    borderBottom: "1px solid",
                                    fontSize: "small",
                                    textAlign: "center",
                                  }}
                                >
                                  15.19 <sub>lbs N</sub>
                                </div>
                                <div
                                  style={{
                                    fontSize: "small",
                                    textAlign: "center",
                                  }}
                                >
                                  8500 <sub>gal slurry</sub>
                                </div>
                              </div>
                            </td>
                            <td
                              style={{
                                border: "none",
                                fontSize: "small",
                                textAlign: "center",
                              }}
                            >
                              =
                            </td>
                            <td style={{ border: "none" }}>
                              <div style={{ float: "left" }}>
                                <div
                                  style={{
                                    fontSize: "small",
                                    textAlign: "center",
                                  }}
                                >
                                  0.00179 <sub>Lbs N</sub> x 100
                                </div>
                              </div>
                            </td>
                            <td
                              style={{
                                border: "none",
                                fontSize: "small",
                                textAlign: "center",
                              }}
                            >
                              =
                            </td>
                            <td style={{ border: "none" }}>
                              <div style={{ float: "left" }}>
                                <div
                                  style={{
                                    fontSize: "small",
                                    textAlign: "center",
                                  }}
                                >
                                  0.179% <sub>N slurry</sub>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </table>{" "}
                      </td>
                    </tr>
                    <tr>
                      <td>C:N Ratio</td>
                      <td>Ratio of total carbon to total nitrogen</td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <p>
                  This section uses a mass balance approach to ensure that total
                  percentages of different portions do not exceed 100%. If users
                  modify the default values to exceed 100%, they will get the
                  following error message: The data entered indicates the
                  fractions of carbon, nitrogen, phosphorus, and water exceed
                  100% for this event. Please check the test report or product
                  label and adjust the percentages to correct this issue.
                </p>
                <br />

                <p>
                  Example: a grower applies 10,000 gallons of dairy slurry on
                  their field. The lab report indicates the slurry is 92% water,
                  0.13% nitrogen, and 0.06% P, with a C:N ratio of 7.7. A C:N
                  ratio of 7.7 indicates that the carbon fraction is: 0.13 (from
                  the nitrogen) x 7.7 = 1.001%. The remaining fraction (approx.
                  ~6.8%) comprises potassium, calcium, hydrogen, and oxygen
                  bound to carbon in the organic matter, and other trace
                  materials.
                </p>
                <br />

                <img src={ManHelp} width="100%" alt="Add Manure Help" />
              </CFBox>
            }
          />{" "}
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
}
