/* eslint-disable react/no-array-index-key */
import { useEffect, useState } from "react";
import {
  FormControlLabel,
  Grid,
  Input,
  InputAdornment,
  Radio,
} from "@mui/material";

// Date picker
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";

import CFTypography from "components/CFTypography";
import colors from "assets/theme/base/colors";
import CFBox from "components/CFBox";
import CFManagementSelectionCard from "components/CroplandsComponents/CFManagementSelectionCard/CFManagementSelectionCard";
import IrriHelpDoc from "assets/cFarmPDFs/Documentation/irrigation help doc ppt (1).pdf";
import FloodHelpImage from "assets/cFarmImages/HelpImages/flood irrigation help doc.webp";
import { preloadImage } from "utils/generic";
import HelpAndButton from "../../../../../components/CroplandsComponents/EditCropCard_StructuralComponents/HelpAndButton";
import DeleteButton from "../../../../../components/CroplandsComponents/EditCropCard_StructuralComponents/DeleteButton";
import WhiteBackgroundDatepicker from "../../../../../components/CroplandsComponents/EditCropCard_StructuralComponents/WhiteBackgroundDatepicker";

export default function ({ CropEvent, SetPropertyFunction, FieldName }) {
  preloadImage(FloodHelpImage);
  const minDate = dayjs()
    .set("year", CropEvent.cropYear)
    .set("date", 1)
    .set("month", 0);
  const maxDate = dayjs()
    .set("year", CropEvent.cropYear + 2)
    .set("date", 1)
    .set("month", 0);

  const setWHC = (newVal, index) => {
    let setVal = newVal;
    if (setVal > 100) {
      setVal = 100;
    }
    if (setVal < 0) {
      setVal = 0;
    }
    const newCE = { ...CropEvent };
    newCE.irrigations[index].soilThreshold = setVal;
    SetPropertyFunction(newCE);
  };

  const setAutoIrrigate = (newVal, index) => {
    const newCE = { ...CropEvent };
    console.log("set auto", newCE, index, newVal);
    newCE.irrigations[index].autoIrrigation = newVal;
    SetPropertyFunction(newCE);
  };

  const setDaysBetween = (newVal, index) => {
    let setVal = newVal;
    if (setVal < 0) {
      setVal = 0;
    }
    const newCE = { ...CropEvent };
    newCE.irrigations[index].daysBetweenIrrigation = setVal;
    SetPropertyFunction(newCE);
  };

  const setTotalApplied = (newVal, index) => {
    let setVal = newVal;
    if (setVal < 0) {
      setVal = 0;
    }
    const newCE = { ...CropEvent };
    newCE.irrigations[index].totalApplied = setVal;
    SetPropertyFunction(newCE);
  };

  const addNewIrrigation = () => {
    const newCE = { ...CropEvent };

    newCE.irrigations.push({
      applicationDate: minDate.toISOString(),
      totalApplied: 0,
      endDate: minDate.toISOString(),
      floodEndDate: minDate.toISOString(),
      autoIrrigation: false,
      daysBetweenIrrigation: 0,
      soilThreshold: 55,
      id: 0,
    });

    SetPropertyFunction(newCE);
  };

  const deleteIrrigation = (index) => {
    const newCE = { ...CropEvent };
    newCE.irrigations.splice(index, 1);

    SetPropertyFunction(newCE);
  };

  const setStartDate = (newVal, index) => {
    const newCE = { ...CropEvent };
    if (dayjs(newVal, true).isValid()) {
      const startDate = dayjs(newVal);
      let endDate = dayjs(newCE.irrigations[index].endDate);
      if (endDate <= startDate) {
        endDate = startDate.add(1, "day");
      }

      newCE.irrigations[index].applicationDate = startDate.toISOString();
      newCE.irrigations[index].endDate = endDate.toISOString();
      SetPropertyFunction(newCE);
    }
  };

  const setFloodEndDate = (newVal, index) => {
    const newCE = { ...CropEvent };
    if (dayjs(newVal, true).isValid()) {
      const endDate = dayjs(newVal);
      let startDate = dayjs(newCE.irrigations[index].applicationDate);
      if (endDate <= startDate) {
        startDate = endDate.add(-1, "day");
      }
      newCE.irrigations[index].applicationDate = startDate.toISOString();
      newCE.irrigations[index].floodEndDate = endDate.toISOString();
      SetPropertyFunction(newCE);
    }
  };

  const setEndDate = (newVal, index) => {
    const newCE = { ...CropEvent };
    if (dayjs(newVal, true).isValid()) {
      const endDate = dayjs(newVal);
      let startDate = dayjs(newCE.irrigations[index].applicationDate);
      if (endDate <= startDate) {
        startDate = endDate.add(-1, "day");
      }
      newCE.irrigations[index].applicationDate = startDate.toISOString();
      newCE.irrigations[index].endDate = endDate.toISOString();
      SetPropertyFunction(newCE);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid alignItems="center" container flexDirection="row">
        <Grid item xs={12} sx={{ paddingLeft: "1em" }}>
          {CropEvent.id === 244 && (
            <CFTypography>
              Enter flood irrigation management. One flood event is required.
              When entering pre-season or winter flooding begin the flood in the
              current year. The end year of the flood may be in the subsequent
              year.
            </CFTypography>
          )}
        </Grid>
        <Grid item xs={12}>
          {CropEvent.irrigations.map((app, ind) =>
            CropEvent.id === 244 ? (
              <Grid
                item
                xs={12}
                container
                flexDirection="row"
                alignItems="center"
                key={`irrigation${ind}`}
                sx={{ paddingTop: ".5em", marginTop: "1em" }}
              >
                <Grid
                  item
                  xs={12}
                  container
                  flexDirection="row"
                  alignItems="center"
                >
                  <Grid item xs={11} xl={5.5}>
                    <CFManagementSelectionCard
                      label="Flood Start Date"
                      labelWidth={6}
                      input={
                        <WhiteBackgroundDatepicker
                          views={["year", "month", "day"]}
                          label="Start Date"
                          maxDate={maxDate}
                          minDate={minDate}
                          style={{ backgroundColor: colors.white.main }}
                          slotProps={{ textField: { variant: "outlined" } }}
                          value={dayjs(app.applicationDate)}
                          onChange={(newValue) => setStartDate(newValue, ind)}
                        />
                      }
                    />
                  </Grid>{" "}
                  <Grid item xs={11} xl={5.5}>
                    <CFManagementSelectionCard
                      label="Flood End Date"
                      labelWidth={6}
                      input={
                        <WhiteBackgroundDatepicker
                          views={["year", "month", "day"]}
                          label="End Date"
                          maxDate={maxDate}
                          minDate={minDate}
                          style={{ backgroundColor: colors.white.main }}
                          slotProps={{ textField: { variant: "outlined" } }}
                          value={dayjs(app.floodEndDate)}
                          onChange={(newValue) =>
                            setFloodEndDate(newValue, ind)
                          }
                        />
                      }
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <DeleteButton deleteAction={() => deleteIrrigation(ind)} />
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid
                item
                xs={12}
                container
                flexDirection="row"
                alignItems="center"
                key={`irrigation${ind}`}
                sx={{ paddingTop: ".5em", marginTop: "1em" }}
              >
                <Grid item xs={11}>
                  <CFManagementSelectionCard
                    label=""
                    labelWidth={0}
                    input={
                      <CFBox
                        sx={{
                          display: "flex",
                          justifyContent: "space-around",

                          width: "100%",
                        }}
                      >
                        <FormControlLabel
                          value="Manually Enter Irrigation Events"
                          control={
                            <Radio
                              checked={!app.autoIrrigation}
                              onChange={(e) => {
                                setAutoIrrigate(false, ind);
                              }}
                              value
                              name="radio-buttons"
                              inputProps={{
                                "aria-label":
                                  "Manually Enter Irrigation Events",
                              }}
                            />
                          }
                          label={
                            <CFTypography sx={{ fontSize: "1.25em" }}>
                              Manually Enter Irrigation Events
                            </CFTypography>
                          }
                          labelPlacement="end"
                        />

                        <FormControlLabel
                          value="Automate Irrigation Events"
                          control={
                            <Radio
                              checked={app.autoIrrigation}
                              onChange={(e) => {
                                setAutoIrrigate(true, ind);
                              }}
                              value
                              name="radio-buttons"
                              inputProps={{
                                "aria-label": "Automate Irrigation Events",
                              }}
                            />
                          }
                          label={
                            <CFTypography sx={{ fontSize: "1.25em" }}>
                              Automate Irrigation Events
                            </CFTypography>
                          }
                          labelPlacement="end"
                        />
                      </CFBox>
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  container
                  flexDirection="row"
                  alignItems="center"
                >
                  <Grid item xs={11} xl={5.5}>
                    <CFManagementSelectionCard
                      label="Start Date"
                      labelWidth={6}
                      input={
                        <WhiteBackgroundDatepicker
                          views={["year", "month", "day"]}
                          label="Start Date"
                          maxDate={maxDate}
                          minDate={minDate}
                          style={{ backgroundColor: colors.white.main }}
                          slotProps={{ textField: { variant: "outlined" } }}
                          value={dayjs(app.applicationDate)}
                          onChange={(newValue) => setStartDate(newValue, ind)}
                        />
                      }
                    />
                  </Grid>{" "}
                  <Grid item xs={11} xl={5.5}>
                    <CFManagementSelectionCard
                      label="End Date"
                      labelWidth={6}
                      input={
                        <WhiteBackgroundDatepicker
                          views={["year", "month", "day"]}
                          label="End Date"
                          maxDate={maxDate}
                          minDate={minDate}
                          style={{ backgroundColor: colors.white.main }}
                          slotProps={{ textField: { variant: "outlined" } }}
                          value={dayjs(app.endDate)}
                          onChange={(newValue) => setEndDate(newValue, ind)}
                        />
                      }
                    />
                  </Grid>
                </Grid>
                {!app.autoIrrigation && (
                  <Grid
                    item
                    xs={12}
                    container
                    flexDirection="row"
                    alignItems="center"
                  >
                    <Grid item xs={11} xl={5.5}>
                      <CFManagementSelectionCard
                        label="Irrigation amount"
                        labelWidth={6}
                        input={
                          <Input
                            label="(in/application)"
                            endAdornment={
                              <InputAdornment position="end">
                                in/application
                              </InputAdornment>
                            }
                            type="number"
                            sx={{
                              backgroundColor: colors.white.main,
                              fontSize: "1.25em",
                              paddingInline: ".3em",
                              borderRadius: ".3em",
                            }}
                            value={app.totalApplied}
                            onChange={(e) =>
                              setTotalApplied(e.target.value, ind)
                            }
                          />
                        }
                      />
                    </Grid>
                    <Grid item xs={11} xl={5.5}>
                      <CFManagementSelectionCard
                        label="Days between irrigation"
                        labelWidth={10}
                        input={
                          <Input
                            label="Days without irrigation between applications"
                            type="number"
                            sx={{
                              backgroundColor: colors.white.main,
                              fontSize: "1.25em",
                              paddingInline: ".3em",
                              borderRadius: ".3em",
                            }}
                            value={app.daysBetweenIrrigation}
                            onChange={(e) =>
                              setDaysBetween(e.target.value, ind)
                            }
                          />
                        }
                      />
                    </Grid>

                    <Grid item xs={1}>
                      <DeleteButton
                        deleteAction={() => deleteIrrigation(ind)}
                      />
                    </Grid>
                  </Grid>
                )}
                {app.autoIrrigation && (
                  <Grid
                    item
                    xs={12}
                    container
                    flexDirection="row"
                    alignItems="center"
                  >
                    <Grid item xs={11}>
                      <CFManagementSelectionCard
                        label="Target Field Available Water Holding Capacity"
                        labelWidth={8.5}
                        input={
                          <Input
                            label="%"
                            type="number"
                            endAdornment={
                              <InputAdornment position="end">%</InputAdornment>
                            }
                            sx={{
                              backgroundColor: colors.white.main,
                              fontSize: "1.25em",
                              paddingInline: ".3em",
                              borderRadius: ".3em",
                            }}
                            value={app.autoIrrigation ? app.soilThreshold : ""}
                            onChange={(e) => setWHC(e.target.value, ind)}
                          />
                        }
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <DeleteButton
                        deleteAction={() => deleteIrrigation(ind)}
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            )
          )}
          <HelpAndButton
            buttonLabel={`+ Add ${
              CropEvent.id === 244 ? "Flood" : "Irrigation"
            } Event`}
            buttonAction={addNewIrrigation}
            userGuidePopupWidth="900px"
            helperLink={CropEvent.id === 244 ? null : IrriHelpDoc}
            helperText={
              CropEvent.id === 244 ? (
                <CFBox sx={{ overflow: "auto", height: "600px" }}>
                  {" "}
                  <img
                    src={FloodHelpImage}
                    width="100%"
                    alt="Add Irrigation Help"
                  />
                </CFBox>
              ) : (
                IrriHelpDoc
              )
            }
          />{" "}
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
}
