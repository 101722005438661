import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import CFTabViewRoot from "./CFTabViewRoot";

const CFTabView = forwardRef(
  ({ minHeight, verbose, defaultTab, ...rest }, ref) => (
    <CFTabViewRoot
      {...rest}
      minHeight={minHeight}
      verbose={verbose}
      defaultTab={defaultTab}
      ref={ref}
    />
  )
);

CFTabView.defaultProps = {
  minHeight: "16em",
  defaultTab: 0,
  verbose: false,
};

CFTabView.propTypes = {
  minHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultTab: PropTypes.number,
  verbose: PropTypes.bool,
};

export default CFTabView;
