import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Grid } from "@mui/material";

import CFarmPage from "components/CFarmParts/CFarmPage";
import CFRoadmap from "components/CFarmParts/CFarmPage/CFRoadmap/Roadmap";
import CFDialog from "components/CFDialog";
import {
  selectedActivities,
  getContinueText,
  HandleContinue,
  HandleGoBack,
  getGoBackText,
} from "components/CFarmParts/CFarmPage/CFRoadmap/roadmap.config";
import CFButton from "components/CFButton";

import { updateLoading } from "store/reducers/mask";
import { popupError } from "utils/generic";
import { post } from "utils/api";

import { AGROFORESTRY_FETCH_PROJECT } from "constants/api";

import CFTypography from "components/CFTypography";
import AgroforestryLocation from "./AgroforestryLocation";
import AgroforestryScenario from "./AgroforestryScenario/AgroforestryScenario";

function Agroforestry() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.user);
  const projectActivities = selectedActivities();
  const agroforestryId = 13;

  const [goBackText, setGoBackText] = useState("Go Back");
  const [continueText, setContinueText] = useState("Continue");

  const [agroforestryOperation, setAgroforestryOp] = useState({
    ProjectId: 0,
    ScenarioId: 0,
    ScenarioName: "Baseline",
    UserDefinedName: "",
    StateName: "Not Selected",
    StateId: 0,
    CountyName: "Not Selected",
    CountyId: 0,
    DetailsList: [],
  });
  const fetchProjectInfo = () => {
    dispatch(updateLoading({ loading: true }));
    post(AGROFORESTRY_FETCH_PROJECT, {
      UserId: currentUser.id,
      ProjectId: currentUser.activeProject.id,
      ScenarioName: "Baseline",
    })
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          dispatch(updateLoading({ loading: false }));
        } else {
          setAgroforestryOp({
            ProjectId: res.data.projectId,
            ScenarioId: res.data.scenarioId,
            ScenarioName: res.data.scenarioName,
            UserDefinedName: res.data.userDefinedName,
            StateName: res.data.stateName,
            StateId: res.data.stateId,
            CountyName: res.data.countyName,
            CountyId: res.data.countyId,
            DetailsList: res.data.scenarioDetails
              ? res.data.scenarioDetails.map((detail) => ({
                  Id: detail.detailId,
                  Group: {
                    Id: detail.groupId,
                    CommonName: detail.groupName,
                  },
                  DBH: detail.dbh,
                  AmountPerAcre: detail.amountPerAcre,
                  HasType: detail.hasType,
                  TypeLookUp: {
                    Id: detail.typeLookUpId,
                    Afpractice: detail.typeLookUpName,
                  },
                  Type: { Id: detail.typeId },
                  Age: detail.age,
                  Size: detail.size,
                  HasAge: detail.hasAge,
                  Scenario: { Id: detail.scenarioId },
                  IsNew: detail.isNew,
                }))
              : [],
          });
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(updateLoading({ loading: false }));
      })
      .finally(() => {
        dispatch(updateLoading({ loading: false }));
      });
  };
  useEffect(() => {
    if (currentUser.activeProject?.id > 0) fetchProjectInfo();
  }, [currentUser]);

  // Whenever the project is updated, if there is a mismatch between the id of the fetched project and the active project, fetch the project again
  useEffect(() => {
    if (
      agroforestryOperation.ProjectId !== 0 &&
      currentUser.activeProject?.id > 0 &&
      agroforestryOperation.ProjectId !== currentUser.activeProject.id
    ) {
      fetchProjectInfo();
    }
  }, [agroforestryOperation]);

  useEffect(() => {
    if (projectActivities.length > 0) {
      setGoBackText(getGoBackText(projectActivities, agroforestryId));
      setContinueText(getContinueText(projectActivities, agroforestryId));
    }
  }, [projectActivities]);

  // Demo info popup
  const [demoPopupShown, setDemoPopupShown] = useState(false);
  const [localPopupState, updateLocalPopup] = useState({});
  useEffect(() => {
    if (currentUser?.activeProject?.name.includes("Demo") && !demoPopupShown) {
      setDemoPopupShown(true);
      updateLocalPopup({
        title: "Agroforestry Demo Project",
        style: { minWidth: "750px", maxHeight: "75vh", overflow: "auto" },
        children: [
          <Grid container direction="column" alignItems="center" p={2}>
            <CFTypography variant="h4" py={1}>
              Operation Location
            </CFTypography>
            <p>
              This is the section where the project&apos;s state and county are
              entered. This information is used to select
              geographically-appropriate calculations and display agroforestry
              practices used in your area. For this demonstration, we will
              examine two different scenarios in north-central California: a
              healthy, well-stocked riparian buffer and a degraded,
              poorly-stocked riparian buffer.
            </p>
            <br />
            <CFTypography variant="h4" py={1}>
              Agroforestry Scenario
            </CFTypography>
            <p>
              Here you would enter in your agroforestry practices. You have two
              options. In the first option you can first select the species
              group which filters the available species. You then select a
              species, provide a DBH (diameter at breast height) or age, and
              provide the number of trees for that species and DBH or age. You
              can add the same or different species multiple times with
              different values of DBH, age, and number of trees for each entry.
              Each entry will be visible in the bottom box after clicking
              &#39;Add Species&#39;. If you need help entering information, the
              blue question mark buttons provide helpful resources. Telling us
              about your species lets us determine each tree&apos;s growth
              rates; DBH or age is used to determine how much carbon is stored
              per tree. If you do not know the specifics of your agroforestry
              practice, select &#39;I want to select a defined scenario&#39;.
              First, select the scenario that most similarly resembles your
              practice. The list of selections represent agroforestry most
              practiced in your area. Next, type in the age and area or size of
              that practice. You can add multiple practices, visible in the
              bottom box, by clicking &#39;Add Scenario&#39;.
            </p>
          </Grid>,
        ],
        showPopup: true,
        closeAction: () => {
          updateLocalPopup({ showPopup: false });
        },
      });
    }
  }, [currentUser.activeProject]);

  return (
    <CFarmPage bgImage="" pageName="">
      <CFRoadmap />
      <Grid
        container
        direction="column"
        mb="5%"
        mt="3%"
        sx={{ width: "75%", minWidth: "750px" }}
      >
        <Grid item>
          <AgroforestryLocation
            stepNumber="1"
            agroforestryOperation={agroforestryOperation}
            setAgroforestryOp={setAgroforestryOp}
          />
        </Grid>
        <Grid item py={3}>
          <AgroforestryScenario
            stepNumber="2"
            agroforestryOperation={agroforestryOperation}
            setAgroforestryOp={setAgroforestryOp}
          />
        </Grid>
        <Grid
          item
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item>
            <CFButton
              onClick={() =>
                HandleGoBack(projectActivities, navigate, agroforestryId)
              }
              variant="gradient"
              color="secondary"
            >
              {goBackText}
            </CFButton>
          </Grid>
          <Grid item>
            <CFButton
              onClick={() =>
                HandleContinue(projectActivities, navigate, agroforestryId)
              }
              variant="gradient"
              color="progress"
              disabled={!agroforestryOperation.DetailsList.length > 0}
            >
              {continueText}
            </CFButton>
          </Grid>
        </Grid>
      </Grid>

      {localPopupState && localPopupState.showPopup && (
        <CFDialog
          {...localPopupState}
          updatePopup={(e) => updateLocalPopup(e)}
        />
      )}
    </CFarmPage>
  );
}
export default Agroforestry;
