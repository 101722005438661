/// ///////////////////////////////////////////
// NOTE: This is a placeholder template, this reducer will be formally implemented in a separate branch
/// ///////////////////////////////////////////

import { createSlice } from "@reduxjs/toolkit";

// eslint-disable-next-line no-unused-vars
const initialState = {
  softwareVersion: "1.0.0",
};

const general = createSlice({
  name: "general",
  initialState,
  reducers: {},
});

export default general.reducer;
