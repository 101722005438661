import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { Tooltip } from "@mui/material";

import PropTypes from "prop-types"; // @mui material components

import CFBox from "components/CFBox";

import CFButton from "components/CFButton";

import CFDialog from "components/CFDialog";

import {
  selectedActivities,
  getContinueText,
  HandleContinue,
  HandleGoBack,
  getGoBackText,
} from "components/CFarmParts/CFarmPage/CFRoadmap/roadmap.config";
import ScenarioCard from "./ScenarioCard";
import style from "./AnimalAgStyle";

function AnimalFooter(props) {
  const {
    anagOpObject,
    anagObjectChanged,
    newScenarioSelected,
    disableFooter,
  } = props;
  const navigate = useNavigate();
  const [localPopupState, updateLocalPopup] = useState({});
  const [scenarioOpen, setScenarioOpen] = useState(false);
  const projectActivities = selectedActivities();
  const [goBackText, setGoBackText] = useState("Go Back");
  const [continueText, setContinueText] = useState("Continue");

  const animalActivityId = 212320;

  const openScenarioPopUp = () => {
    setScenarioOpen(true);
  };

  useEffect(() => {
    if (projectActivities.length > 0) {
      setGoBackText(getGoBackText(projectActivities, animalActivityId));
      setContinueText(getContinueText(projectActivities, animalActivityId));
    }
  }, [projectActivities]);
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        sx={{
          marginBottom: "1em",
          paddingTop: "1em",
          // borderTop: "thin solid #ddd",
        }}
      >
        <Grid xs={4} item justifyContent="flex-start">
          <CFButton
            color="secondary"
            onClick={() =>
              HandleGoBack(projectActivities, navigate, animalActivityId)
            }
          >
            {goBackText}
          </CFButton>
        </Grid>
        <Grid xs={8} container item direction="row" justifyContent="flex-end">
          <Grid item mr={1}>
            <Tooltip
              title={
                disableFooter &&
                "Please complete data for all animal categories in order to add a new scenario."
              }
            >
              <span>
                <CFButton
                  type="submit"
                  color="primary"
                  disabled={disableFooter}
                  onClick={openScenarioPopUp}
                >
                  Add Scenario
                </CFButton>
              </span>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip
              title={
                disableFooter &&
                "Please complete data for all animal categories in order to continue."
              }
            >
              <span>
                <CFButton
                  color="progress"
                  disabled={disableFooter}
                  onClick={() =>
                    HandleContinue(
                      projectActivities,
                      navigate,
                      animalActivityId
                    )
                  }
                >
                  {continueText}
                </CFButton>
              </span>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      <ScenarioCard
        isOpen={scenarioOpen}
        setIsOpen={setScenarioOpen}
        // fetchAllProjects={fetchAllProjects}
        scenarioSel={newScenarioSelected}
        animalobject={anagOpObject}
        animalobjectChanged={anagObjectChanged}
      />
      {localPopupState && localPopupState.showPopup && (
        <CFDialog
          {...localPopupState}
          updatePopup={(e) => updateLocalPopup(e)}
        />
      )}
    </>
  );
}
AnimalFooter.defaultProps = {
  disableFooter: true,
};

AnimalFooter.propTypes = {
  anagOpObject: PropTypes.oneOfType([PropTypes.object]).isRequired,
  anagObjectChanged: PropTypes.oneOfType([PropTypes.func]).isRequired,
  disableFooter: PropTypes.bool,
};
export default AnimalFooter;
