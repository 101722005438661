/* eslint-disable react/function-component-definition */
import React from "react";
// import "./SlideShowFullWindow.css";
import "./SlideShowRelativetoDiv.css";

const SlideShow = () => {
  const images = [1, 2, 3, 4, 5, 6];

  return (
    <ul className="ax-slideshow">
      {images.map((e) => (
        <li key={`${e}_slideShow_image`} />
      ))}
    </ul>
  );
};

export default SlideShow;
