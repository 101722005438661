// React
import { useState } from "react";

// MUI
import { Grid, Dialog, IconButton, Tooltip, Checkbox } from "@mui/material";

import { Close } from "@mui/icons-material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";
import { pink, red, grey } from "@mui/material/colors";
import colors from "assets/theme/base/colors";

// Our Components
import CFBox from "components/CFBox";

import CFButton from "components/CFButton";
import CFStep from "components/CFarmParts/CFStep";
import CopyHistoryHelp from "assets/cFarmImages/HelpImages/CopyHistoryHelp.webp";
import { preloadImage } from "utils/generic";
import CFTypography from "components/CFTypography";

const theme = createTheme({});

export default function CopyHistoryCard({
  title,
  isOpen,
  setIsOpen,
  locationName,
  fieldsInRegion,
  copyFieldsHistories,
}) {
  preloadImage(CopyHistoryHelp);
  const [selectedFields, setSelectedFields] = useState([]);

  const handleClose = () => {
    setIsOpen(false);
    setSelectedFields([]);
  };

  const saveClicked = () => {
    const retArray = [];

    for (const akey in selectedFields) {
      if (Object.prototype.hasOwnProperty.call(selectedFields, akey))
        for (const b in selectedFields[akey]) {
          if (Object.prototype.hasOwnProperty.call(selectedFields[akey], b))
            if (selectedFields[akey][b] === true) {
              retArray.push(`${akey}`);
            }
        }
    }

    copyFieldsHistories(retArray);
    handleClose();
  };

  const celllChecked = (checked, cell) => {
    const { id } = cell.row;

    const { field } = cell;
    const NewCheckedCells = { ...selectedFields };
    if (!NewCheckedCells[id]) {
      NewCheckedCells[id] = [];
    }
    NewCheckedCells[id][field] = checked;
    setSelectedFields(NewCheckedCells);
  };

  const renderCell = (cell) => {
    //  console.log("here is the cell", cell);
    const { formattedValue } = cell;

    //  console.log("here is the cell formented value", formattedValue);

    if (formattedValue) {
      return (
        <ThemeProvider theme={theme}>
          <Checkbox
            sx={{
              color: red[800],
              "&.Mui-checked": {
                color: red[600],
              },
            }}
            checked={
              selectedFields[cell.row.id]
                ? selectedFields[cell.row.id][cell.field] === true
                : false
            }
            onChange={(e) => {
              celllChecked(e.target.checked, cell);
            }}
          />
        </ThemeProvider>
      );
    }

    return (
      <Checkbox
        sx={{}}
        checked={
          selectedFields[cell.row.id]
            ? selectedFields[cell.row.id][cell.field] === true
            : false
        }
        onChange={(e) => {
          celllChecked(e.target.checked, cell);
        }}
      />
    );
  };

  const columns = [];
  columns.push({
    field: "name",
    headerName: "Field Location",
    flex: 1,
    editable: false,
    disableReorder: true,

    sortable: false,
  });
  columns.push({
    field: "dataComplete",
    headerName: `Copy ${title}`,
    flex: 1,
    editable: false,
    disableReorder: true,
    sortable: false,
    filterable: false,
    align: "center",
    headerAlign: "center",
    groupable: false,
    hidable: false,
    pinnable: false,
    aggregable: false,
    renderCell,
  });
  return (
    <Dialog open={isOpen} sx={{ padding: "3em", zIndex: 5 }} maxWidth="L">
      <IconButton
        onClick={() => handleClose()}
        style={{ position: "absolute", right: 0, margin: ".5em", padding: 0 }}
      >
        <Tooltip title="Cancel Copy and Close">
          <Close />
        </Tooltip>
      </IconButton>
      <Grid container item xs={12} sx={{ width: "100%" }}>
        <CFBox sx={{ padding: "2em", width: "100%" }}>
          <Grid container direction="row">
            {/* Window Header */}

            <Grid item container xs={11}>
              {" "}
              {title === "History" && (
                <CFStep
                  stepLabel={`Copy the  Historic Management from ${locationName}`}
                  helpText={
                    <div>
                      <img
                        src={CopyHistoryHelp}
                        width="100%"
                        alt="Layout of Copy History"
                      />
                      <div>
                        If historic management between fields located in the{" "}
                        <em>same</em> county is identical, select the “copy”
                        button to copy historic management from this field to
                        other fields. This action will overwrite any existing
                        historic management details in those fields. Users can
                        modify any copied historic management on that field’s
                        data entry page.
                      </div>
                    </div>
                  }
                  subText="To copy historic management from this field to other fields, use the checkbox(es) below to select which field(s) to copy historic management to. Users can modify any copied historic management on that field’s data entry page. Red checkboxes indicate that historic management data has already been added to that field, and selecting that box will overwrite any existing historic management details in those fields."
                />
              )}
              {title !== "History" && (
                <CFStep
                  stepLabel={`Copy the Crop Management from ${locationName}`}
                  helpText="The management for this scenario can be copied to other locations in the same project"
                />
              )}
              <Grid container item xs={12}>
                <CFBox
                  sx={{
                    paddingLeft: ".5em",
                    paddingRight: ".5em",
                    marginBottom: ".5em",
                    marginLeft: "auto",
                    border: "1px solid black",
                    backgroundColor: "light grey",
                  }}
                >
                  <Grid item container xs={12}>
                    <Grid item container xs={12}>
                      <ThemeProvider theme={theme}>
                        <Checkbox
                          sx={{
                            "&.Mui-checked": {},
                          }}
                        />
                      </ThemeProvider>
                      <CFTypography variant="h6" pl={2} py={2}>
                        No Data
                      </CFTypography>
                    </Grid>
                    <Grid item container xs={12}>
                      <ThemeProvider theme={theme}>
                        <Checkbox
                          sx={{
                            color: red[800],
                            "&.Mui-checked": {
                              color: red[600],
                            },
                          }}
                        />
                      </ThemeProvider>
                      <CFTypography variant="h6" pl={2} py={2}>
                        Data Already Present
                      </CFTypography>
                    </Grid>
                  </Grid>
                </CFBox>
              </Grid>
              <Grid container item xs={12}>
                <DataGrid
                  rows={[...fieldsInRegion]}
                  columns={columns}
                  hideFooter
                  disableRowSelectionOnClick
                  disableColumnSelector
                  disableColumnMenu
                  disableColumnFilter
                />
              </Grid>
              <Grid container py={2} px={2} justifyContent="space-between">
                <CFButton color="secondary" onClick={handleClose}>
                  Cancel
                </CFButton>
                <CFButton color="progress" onClick={saveClicked}>
                  Save and Copy
                </CFButton>
              </Grid>
            </Grid>
          </Grid>
        </CFBox>
      </Grid>
    </Dialog>
  );
}
