/* eslint-disable no-nested-ternary */
import {
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

import CFTypography from "components/CFTypography";
import colors from "assets/theme/base/colors";
import { makeShiaRowSummary } from "./waterHolding.utils";

const NotDeterminedMessage = `A value of ND (not determined) is reported when the clay content of soil is 
  outside of the range of samples used to create the predictive equations for plant available water.`;
const ZeroAwhcMessage = `A value of zero (0) means that there was no significant change in plant 
  available water holding capacity as a result of increase in soil organic carbon.`;
const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 1000,
  },
});

function WaterHoldingBaselineRow({
  soilComponents,
  baselineScenario,
  isSummary,
}) {
  const { awhcGalAcre, awhcGalInch } = makeShiaRowSummary(
    baselineScenario,
    soilComponents,
    true,
    isSummary,
    baselineScenario
  );

  return (
    <TableRow>
      <TableCell style={{ fontWeight: "bold" }}>
        {baselineScenario?.ScenarioName}
      </TableCell>
      <StyledTooltip
        title={
          awhcGalAcre === "ND"
            ? NotDeterminedMessage
            : awhcGalAcre === "0"
            ? ZeroAwhcMessage
            : ""
        }
        enterDelay={250}
        leaveDelay={200}
      >
        <TableCell style={{ fontWeight: "bold" }}>{awhcGalAcre}</TableCell>
      </StyledTooltip>
      <StyledTooltip
        title={
          awhcGalAcre === "ND"
            ? NotDeterminedMessage
            : awhcGalAcre === "0"
            ? ZeroAwhcMessage
            : ""
        }
        enterDelay={250}
        leaveDelay={200}
      >
        <TableCell style={{ fontWeight: "bold" }}>{awhcGalInch}</TableCell>
      </StyledTooltip>
    </TableRow>
  );
}

function WaterHoldingScenarioRow({
  isSummary,
  soilComponents,
  baselineScenario,
  isAlt,
  scenario,
}) {
  const { awhcGalAcre, awhcGalInch } = makeShiaRowSummary(
    scenario,
    soilComponents,
    false,
    isSummary,
    baselineScenario
  );

  return (
    <TableRow
      style={{
        backgroundColor: isAlt ? "#dddddd" : "inherit",
      }}
    >
      <TableCell>{scenario.ScenarioName}</TableCell>
      <StyledTooltip
        title={
          awhcGalAcre === "ND"
            ? NotDeterminedMessage
            : awhcGalAcre === "0"
            ? ZeroAwhcMessage
            : ""
        }
        enterDelay={500}
        leaveDelay={200}
      >
        <TableCell
          style={{ color: awhcGalAcre.includes("-") ? "#d84f4f" : "#2a82c5" }}
        >
          {awhcGalAcre}
        </TableCell>
      </StyledTooltip>
      <StyledTooltip
        title={
          awhcGalAcre === "ND"
            ? NotDeterminedMessage
            : awhcGalAcre === "0"
            ? ZeroAwhcMessage
            : ""
        }
        enterDelay={500}
        leaveDelay={200}
      >
        <TableCell
          style={{ color: awhcGalAcre.includes("-") ? "#d84f4f" : "#2a82c5" }}
        >
          {awhcGalInch}
        </TableCell>
      </StyledTooltip>
    </TableRow>
  );
}

function WaterHoldingTable({
  isSummary,
  location,
  soilComponents,
  title,
  color,
}) {
  const baseSen = location?.CropRotations.filter(
    (X) => X.ScenarioName === "Baseline"
  )[0];

  return (
    <Grid>
      {/* Title */}
      <CFTypography variant="h5">{title}</CFTypography>
      {/* Soil map unit color */}
      {color && (
        <div
          style={{
            width: "100%",
            height: "15px",
            margin: "0 0 .2em 0",
            borderRadius: ".2em",
            backgroundColor: color,
          }}
        />
      )}
      {/* Results table */}
      <TableContainer>
        <Table>
          <TableHead sx={{ display: "table-header-group" }}>
            <TableRow>
              <TableCell
                style={{ backgroundColor: colors.primary.main, color: "white" }}
              >
                Project Scenario(s)
              </TableCell>
              <TableCell
                style={{ backgroundColor: colors.primary.main, color: "white" }}
              >
                Estimated AWHC <br />
                in Top 6 inches of Soil
                <br />
                (gallons/acre)
              </TableCell>
              <TableCell
                style={{ backgroundColor: colors.primary.main, color: "white" }}
              >
                Estimated Absolute AWHC <br />
                in Top 6 inches of Soil
                <br />
                (inches water)
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* Baseline */}
            <WaterHoldingBaselineRow
              soilComponents={soilComponents}
              baselineScenario={baseSen}
              isSummary={isSummary}
            />
            {/* Scenarios */}
            {location?.CropRotations.filter(
              (cr) => cr.ScenarioName !== "Baseline"
            ).map((cr, i) => (
              <WaterHoldingScenarioRow
                isSummary={isSummary}
                soilComponents={soilComponents}
                baselineScenario={baseSen}
                key={cr.Id}
                isAlt={i % 2 === 0}
                scenario={cr}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}
export default WaterHoldingTable;
