import colors from "assets/theme/base/colors";

const { text } = colors;
export default {
  styleOverrides: {
    root: {
      color: text.main,
    },
  },
};
