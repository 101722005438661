import colors from "assets/theme/base/colors";
import { Control } from "ol/control";

class ModifyButtons extends Control {
  /**
   * @param {Object} [opt_options] Control options.
   */
  constructor(parcelWording, modifySave, modifyCancel, opt_options) {
    /* eslint camelcase: "off" */
    const options = opt_options || {};

    const divHolder = document.createElement("div");
    divHolder.style.width = "100%";

    const modifyElement = document.createElement("div");
    modifyElement.id = "modifyContainer";
    modifyElement.innerHTML = `Modify ${parcelWording}`;
    modifyElement.className = "CFMapImageryButtonsContainer";
    modifyElement.style.display = "none";

    const cancelButton = document.createElement("button");
    cancelButton.id = `modifyCancelButton`;
    cancelButton.innerHTML = "Cancel";
    cancelButton.className = "CFMapImageryButtons";
    cancelButton.style.color = "#fff";
    cancelButton.style.background = colors.secondary.main;
    cancelButton.addEventListener("click", modifyCancel, false);
    modifyElement.appendChild(cancelButton);

    const saveButton = document.createElement("button");
    saveButton.id = `modifySaveButton`;
    saveButton.innerHTML = "Save";
    saveButton.className = "CFMapImageryButtons";
    saveButton.style.color = "#fff";
    saveButton.style.background = colors.progress.main;
    saveButton.addEventListener("click", modifySave, false);
    modifyElement.appendChild(saveButton);
    divHolder.appendChild(modifyElement);
    super({
      element: divHolder,
      target: options.target,
    });
  }
}

export default ModifyButtons;
