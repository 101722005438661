import { KeyboardArrowUp } from "@mui/icons-material";
import { IconButton } from "@mui/material";

import colors from "assets/theme/base/colors";
import CFBorderBox from "components/CFBorderBox";
import CFBox from "components/CFBox";

export default function ({ onClick, ...rest }) {
  return (
    <CFBox onClick={() => onClick()} {...rest}>
      <IconButton
        style={{
          margin: 0,
          padding: 0,
          borderRadius: "100%",
          backgroundColor: colors.primary.main,
        }}
      >
        <KeyboardArrowUp color="white" fontSize="large" />
      </IconButton>
    </CFBox>
  );
}
