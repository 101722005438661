/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Divider, Grid, IconButton, Tooltip } from "@mui/material";
import { AddCircleOutline, EditTwoTone } from "@mui/icons-material";
import CFarmPage from "components/CFarmParts/CFarmPage";

import { useQuery, popupError, preloadImage, jsonCopy } from "utils/generic";
import { post } from "utils/api";
import {
  CROPLANDS_FETCH_LOCATIONLIST,
  DELETE_SCENARIO,
  CREATE_NEW_SCENARIO,
  CHANGE_CROP_SCENARIO_NAME,
} from "constants/api";
import CFRoadmap from "components/CFarmParts/CFarmPage/CFRoadmap/Roadmap";
import CFButton from "components/CFButton";
import colors from "assets/theme/base/colors";
import CFBox from "components/CFBox";
import RequiredComplete from "components/CFarmParts/CFStep/RequiredComplete";
import CFTypography from "components/CFTypography";
import CFDialog from "components/CFDialog";

import CFLoading from "components/CFLoading";
import CFStep from "components/CFarmParts/CFStep";
import CroplandAccordionSection from "components/CroplandsComponents/CroplandAccordionSection/CroplandAccordionSection";
import { fetchUser } from "actions/user";
import {
  selectedActivities,
  getContinueText,
  reportProps,
} from "components/CFarmParts/CFarmPage/CFRoadmap/roadmap.config";
import ScenarioHelp from "assets/cFarmImages/HelpImages/ScenarioManagementHelp.webp";
import { updateLoading } from "store/reducers/mask";
import ScenarioManagement from "./ScenarioManagement/ScenarioManagement";
import SelectField from "./SelectField";
import FloatingToTopArrow from "../../components/CroplandsComponents/FloatingToTopArrow/FloatingToTopArrow";
import CroplandStyle from "./CroplandStyle";

import DeleteButton from "../../components/CroplandsComponents/EditCropCard_StructuralComponents/DeleteButton";
import NewScenarioComponent from "./ScenarioManagement/NewScenarioComponent";

const emptyOptional = {
  name: "(optional)",
  id: 0,
};

const emptyField = {
  id: 0,
  name: "Select a field",
  baselineId: 0,
  scenarios: [],
  baselineStart: 2000,
};

function CroplandsScenarioPage() {
  const timelineActivities = [...selectedActivities(), ...reportProps];
  const timelineIndexOfScenarios = timelineActivities.findIndex(
    (x) => x.id === 10 && x.stage === 3
  );

  preloadImage(ScenarioHelp);
  const query = useQuery();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.user);

  const [nextClicked, setNextClicked] = useState(false);
  const [ReportEnabled, SetReportEnabled] = useState(false);
  const [pickNewScenario, SetpickNewScenario] = useState(false);

  const [selectedField, setSelectedField] = useState({
    id: 0,
    name: "Select a field",
    baselineId: 0,
    scenarios: [],
    baselineStart: 2000,
  });
  const [fields, setFields] = useState([emptyField]);
  const [fetchedFields, setFetchedFields] = useState([emptyField]);
  const [locationMapFeatureHolder, setLocationMapFeatureHolder] = useState({});
  const [globalLoading, setglobalLoading] = useState("");

  const [scenarioNameCompleteCollection, setscenarioNameCompleteCollection] =
    useState([]);
  const [triggerAccordian, settriggerAccordian] = useState(0);
  const [selectedScenario, setselectedScenario] = useState("");

  const [nextText, setNextText] = useState("Next");

  const [localPopupState, updateLocalPopup] = useState({});

  const [copyFromScenarios, setCopyFromScenarios] = useState([]);

  let newName = "";

  const goToTop = () => {
    window.scrollTo(0, 0);
  };

  const [selectedScenarioOnceAlready, setSelectedScenarioOnceAlready] =
    useState(false);
  const [selectedFieldOnceAlready, setSelectedFieldOnceAlready] =
    useState(false);
  const selectScenario = (scenarioid) => {
    setselectedScenario(
      scenarioNameCompleteCollection.filter((x) => x.id === scenarioid)[0]
    );
  };
  const pickNew = () => {
    if (pickNewScenario) {
      const s = scenarioNameCompleteCollection.filter(
        (x) => x.name === pickNewScenario
      )[0];

      setselectedScenario(s);
      SetpickNewScenario(false);
    }
  };
  useEffect(() => {
    const allScenariosCompleteness = {};

    for (let i = 0; i < fields.length; i += 1) {
      if (fields[i].scenarios) {
        for (let j = 0; j < fields[i].scenarios.length; j += 1) {
          const { name } = fields[i].scenarios[j];

          if (allScenariosCompleteness[name] !== undefined) {
            allScenariosCompleteness[name].dataComplete =
              allScenariosCompleteness[name].dataComplete &&
              fields[i].scenarios[j].dataComplete;
            allScenariosCompleteness[name].id = fields[i].scenarios[j].id;
          } else {
            allScenariosCompleteness[name] = {
              dataComplete: fields[i].scenarios[j].dataComplete,
              id: fields[i].scenarios[j].id,
            }; // any id, used for delete
          }
        }
      }
    }
    const scenariosWithCompleteness = [];
    Object.keys(allScenariosCompleteness).forEach((key) => {
      scenariosWithCompleteness.push({
        name: key,
        dataComplete: allScenariosCompleteness[key].dataComplete,
        id: allScenariosCompleteness[key].id,
      });
    });
    if (!selectedScenarioOnceAlready) {
      if (scenariosWithCompleteness.length > 0) {
        let fame = scenariosWithCompleteness.filter((x) => !x.dataComplete)[0];
        if (!fame) {
          [fame] = scenariosWithCompleteness;
        }

        setselectedScenario(fame);
        // selectScenario(fame.id);

        setSelectedScenarioOnceAlready(true);
      }
    }

    setscenarioNameCompleteCollection(scenariosWithCompleteness);
  }, [fields]);

  useEffect(() => {
    // pick the first unfinished field if exists once selected scenario changes
    if (fields.length > 0 && fields[0].id !== 0 && !selectedFieldOnceAlready) {
      setSelectedFieldOnceAlready(true);
      for (let i = 0; i < fields.length; i += 1) {
        if (fields[i].scenarios && selectedScenario) {
          const thisFieldsScenario = fields[i].scenarios.filter(
            (x) => x.name === selectedScenario.name
          );

          if (
            !thisFieldsScenario ||
            !thisFieldsScenario[0] ||
            !thisFieldsScenario[0].dataComplete
          ) {
            setSelectedField(fields[i]);

            return;
          }
        }
      }

      setSelectedField(fields[0]);
    }
  }, [selectedScenario, fields]);

  useEffect(() => {
    // pick the first unfinished field if exists once selected scenario changes
    if (scenarioNameCompleteCollection?.length === 0) {
      setSelectedField(emptyField);
    }
    pickNew();
  }, [scenarioNameCompleteCollection]);

  const populateCopyFromScenarios = () => {
    if (selectedField && selectedField.id !== 0) {
      if (selectedField.scenarios && selectedField.scenarios.length > 0) {
        const selectedRotation = selectedField.scenarios.filter(
          (x) => x.name === selectedScenario?.name
        )[0];
        setCopyFromScenarios([
          {
            name: "(optional)",
            id: 0,
          },
          {
            name: "baseline",
            id: selectedField.baselineId,
            dataComplete: true,
          },
          ...selectedField.scenarios,
        ]);
        if (selectedRotation) {
          /*    console.log("setting in this part", selectedRotation);
          setselectedCropRotation(selectedRotation);
          setselectedScenario(selectedRotation); */
        }
      } else {
        setCopyFromScenarios([
          {
            name: "(optional)",
            id: 0,
          },
          {
            name: "baseline",
            id: selectedField.baselineId,
            dataComplete: true,
          },
        ]);
      }
    }
  };

  // populateCopyFromScenarios();
  useEffect(() => {
    populateCopyFromScenarios();
  }, [selectedField, selectedScenario]);

  const setLoading = (message) => {
    setglobalLoading(message);
    /*  if (message !== "") {
      dispatch(updateLoading({ loading: true }));
    } else {
      dispatch(updateLoading({ loading: false }));
    } */
  };

  const updateFieldCompleteness = () => {
    if (fetchedFields && fetchedFields.fields)
      jsonCopy(fetchedFields.fields).then((appliedField) => {
        const arrImmutableVersion = [];
        for (let i = 0; i < appliedField.length; i += 1) {
          const e = appliedField[i];
          const dc =
            e.scenarios && e.scenarios.length > 0
              ? e.scenarios.filter((x) => x.name === selectedScenario?.name)[0]
                  ?.dataComplete
              : false;

          arrImmutableVersion.push({ ...e, dataComplete: dc });
        }
        setFields(arrImmutableVersion);

        if (selectedField) {
          const selectThisField = arrImmutableVersion.filter(
            (x) => x.id === selectedField.id
          )[0];

          if (selectThisField) {
            setSelectedField(selectThisField);
          }
        }

        for (let i = 0; i < arrImmutableVersion.length; i += 1) {
          fetchedFields.holder.featureCollection.features[
            i
          ].properties.dataComplete = arrImmutableVersion[i].dataComplete;
        }

        setLocationMapFeatureHolder(fetchedFields.holder);
      });
  };

  useEffect(() => {
    updateFieldCompleteness();
  }, [fetchedFields, selectedScenario, selectedField?.scenarios.length]);

  let oneFetchAtATime = false;

  const fetchProjectInformation = async (signal) => {
    // dispatch(updateLoading({ loading: true }));
    if (!oneFetchAtATime) {
      oneFetchAtATime = true;

      post(CROPLANDS_FETCH_LOCATIONLIST, {
        user: currentUser,
        projectId: query.get("project"),
      })
        .then((res) => {
          if (res.error) {
            popupError(`Fetching Locations Error ${res.error}`, dispatch);
          } else {
            if (signal && signal.aborted) {
              return;
            }
            setFetchedFields({
              fields: res.data.fieldLocationData,
              holder: res.data.locationHolder,
            });
            /*  const appliedField = res.data.fieldLocationData.map((x) => ({
            ...x,
            dataComplete: x.scenariosComplete,
          })); */

            // setFields(appliedField);
            // if there is a selected field, need to update it in the sate with this new information
          }
        })
        .catch((err) => {
          console.log(err);
          //  dispatch(updateLoading({ loading: false }));
        })
        .finally(() => {
          oneFetchAtATime = false;
        });
    }
  };

  const isSelectedScenario = (picker) =>
    selectedScenario && selectedScenario.id === picker.id;

  const ChangeName = (picker) => {
    if (picker.id && picker.id !== 0 && newName) {
      setLoading("Saving");
      post(CHANGE_CROP_SCENARIO_NAME, {
        user: currentUser,
        scenarioName: newName,
        scenarioToChangeName: picker.id,
      })
        .then((res) => {
          if (res.error) {
            popupError(`Scenario Name Change Error ${res.error}`, dispatch);
          } else {
            //  setSelectedScenario(empty);

            dispatch(fetchUser(currentUser));
            fetchProjectInformation();
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading("");
        });
    }

    updateLocalPopup({ showPopup: false });
  };
  const deleteCurrentScenario = (picker) => {
    if (picker.id && picker.id !== 0) {
      setLoading("deleting scenario");
      post(DELETE_SCENARIO, {
        user: currentUser,
        scenarioName: picker.name,
        scenarioToDeleteCropRotationId: picker.id,
      })
        .then((res) => {
          if (res.error) {
            popupError(`Deleting Scenario Error ${res.error}`, dispatch);
          } else {
            selectScenario(
              scenarioNameCompleteCollection.legnth > 1
                ? scenarioNameCompleteCollection.filter(
                    (x) => x.id !== picker.id
                  )[0].id
                : ""
            );

            dispatch(fetchUser(currentUser));
            setSelectedScenarioOnceAlready(false);
            // fetchProjectInformation();
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading("");
        });
    }

    updateLocalPopup({ showPopup: false });
  };

  const checkEditName = (e) => {
    newName = e.target.value.trim();
  };

  const checkDeleteEnabled = (e, picker) => {
    const nameTarget = picker.name.toLowerCase().trim();
    const typedTarget = e.target.value.toLowerCase().trim();

    if (nameTarget === typedTarget) {
      updateLocalPopup({
        title: `Delete ${picker.name} from all fields`,
        component: {
          type: "input",
          label: `type ${picker.name} to confirm delete`,

          defaultValue: "New Scenario",
          disabled: true,
        },

        button2: {
          onClick: () => deleteCurrentScenario(picker),
          text: `Delete ${picker.name} `,
          variant: "gradient",
          color: "progress",
        },

        showPopup: true,
      });
    }
  };

  const OpenEditScenarioNameWindow = (picker) => {
    updateLocalPopup({
      title: `Edit ${picker.name} scenario name`,
      component: {
        type: "input",
        label: `Change ${picker.name}`,

        onChange: (e) => checkEditName(e),
      },

      button2: {
        onClick: () => ChangeName(picker),
        text: `Save Name`,
        variant: "gradient",
        color: "progress",
      },

      showPopup: true,
    });
  };

  const OpenDeleteScenarioWindow = (picker) => {
    updateLocalPopup({
      title: `Delete ${picker.name} from all fields`,
      component: {
        type: "input",
        label: `type ${picker.name} to confirm delete`,

        onChange: (e) => checkDeleteEnabled(e, picker),
      },

      button2: {
        text: `Delete ${picker.name} `,
        variant: "gradient",
        color: "progress",
        disabled: true,
      },

      showPopup: true,
    });
  };

  const editNameClicked = (picker) => {
    // console.log("picker", picker);
    selectScenario(picker.id);
    OpenEditScenarioNameWindow(picker);
  };

  const deleteClicked = (picker) => {
    // console.log("picker", picker);
    selectScenario(picker.id);
    OpenDeleteScenarioWindow(picker);
  };

  const checkForReportReady = () => {
    // need to refetch the fields actually
    let ready = true;
    for (let i = 0; i < fields.length; i += 1) {
      if (fields[i].id !== 0) {
        ready = ready && fields[i].scenariosComplete;
      }
    }

    SetReportEnabled(ready);
    if (ready) {
      setNextText(getContinueText(timelineActivities, 10));
    } else {
      setNextText("Next");
    }
  };

  useEffect(() => {
    checkForReportReady();

    if (nextClicked) {
      setNextClicked(false);
      for (let i = 0; i < fields.length; i += 1) {
        if (!fields[i].dataComplete) {
          for (let j = 0; j < fields[i].scenarios.length; j += 1) {
            if (!fields[i].scenarios[j].dataComplete) {
              if (pickNewScenario) {
                pickNew(fields[i]);
              } else {
                selectScenario(
                  scenarioNameCompleteCollection.filter(
                    (x) => x.name === fields[i].scenarios[j].name
                  )[0].id
                );
              }

              setSelectedField(fields[i]);
              goToTop();
              return;
            }
          }
          // go to first detected unfinished field
        }
      }
      // go to scenarios page
      navigate(timelineActivities[timelineIndexOfScenarios + 1]?.to);
    }
  }, [fields]);

  useEffect(() => {
    const controller = new AbortController();
    fetchProjectInformation(controller.signal);
    return () => {
      // This function is called when the data is changing and the
      // effect callback is about to be re-run
      controller.abort();
    };
  }, []);

  const Next = () => {
    // next logic for cropland history and current management:
    // 1st if there is any field that is not dataComplete, select the first such field and scroll the screen to the top
    // 2. navigate to the next step
    setNextClicked(true);
    dispatch(updateLoading(true));
    fetchProjectInformation().finally(() => {
      dispatch(updateLoading(false));
    });
  };

  // Demo info popup
  const [demoPopupShown, setDemoPopupShown] = useState(false);
  useEffect(() => {
    if (currentUser?.activeProject?.name.includes("Demo") && !demoPopupShown) {
      setDemoPopupShown(true);
      updateLocalPopup({
        title: "Croplands Demo Project",
        style: { minWidth: "750px", maxHeight: "75vh", overflow: "auto" },
        children: [
          <Grid container direction="column" alignItems="center" p={2}>
            <CFTypography variant="h4" py={1}>
              Scenario Management
            </CFTypography>
            <p>
              The hypothetical future scenario developed for this demonstration
              depicts a conversion from conventional tillage to no-tillage. Both
              the grain corn and soybeans were converted to a no-tillage system.
              Everything else stayed the same.
            </p>
          </Grid>,
        ],
        showPopup: true,
        closeAction: () => {
          updateLocalPopup({ showPopup: false });
        },
      });
    }
  }, [currentUser.activeProject]);

  return (
    <CFarmPage bgImage="" pageName="">
      <CFRoadmap />
      <Grid
        container
        spacing={2}
        sx={{ marginTop: "1em", width: CroplandStyle.width }}
      >
        <CFStep
          stepLabel="Scenario Management"
          stepNumber="1"
          helpText={<img src={ScenarioHelp} width="100%" alt="Scenario Help" />}
          useGuideWidth="900px"
        />

        {globalLoading !== "" && <CFLoading message={globalLoading} />}
        <Grid item xs={12}>
          <CFBox
            sx={{
              backgroundColor: colors.background.header,
              width: "100%",
              padding: "1em",
              position: "relative",
            }}
          >
            <Grid
              container
              spacing={2}
              sx={{
                margin: "1em",
                width: "100%",
              }}
            >
              <Grid item xs={12}>
                <CFTypography align="center" variant="h5">
                  Add/View/Edit Scenario
                </CFTypography>
              </Grid>
            </Grid>
            {scenarioNameCompleteCollection.map((picker) => (
              <Grid
                key={picker.name}
                container
                spacing={1}
                sx={{
                  width: "95%",
                  paddingLeft: "1em",
                  marginLeft: "1em",
                  marginRight: "1em",
                  marginBottom: ".5em",
                  paddingRight: "1em",
                  cursor: "pointer",
                  borderRadius: ".3em",
                  backgroundColor: isSelectedScenario(picker)
                    ? colors.highlight.main
                    : colors.background.header,
                  boxShadow: isSelectedScenario(picker)
                    ? "0px 0px 6px 2px #333"
                    : "none",
                }}
                onClick={() => selectScenario(picker.id)}
              >
                <Grid
                  sx={{
                    position: "relative",
                    margin: ".5em",
                  }}
                  item
                  xs={1}
                >
                  <RequiredComplete complete={picker.dataComplete} />
                </Grid>
                <Grid
                  item
                  xs={8}
                  sx={{
                    margin: ".4em",
                  }}
                >
                  <CFTypography
                    color={isSelectedScenario(picker) ? "white" : "text"}
                    fontWeight="medium"
                  >
                    {picker.name}
                  </CFTypography>
                </Grid>
                <Grid item xs={2}>
                  <CFBox sx={{ display: "flex" }}>
                    <EditTwoTone
                      onClick={() => editNameClicked(picker)}
                      fontSize="large"
                      style={
                        isSelectedScenario(picker)
                          ? {
                              transition: "fill 1s",
                              fill: "white",
                              cursor: "pointer",
                              margin: ".5em",
                            }
                          : {
                              transition: "fill 1s",
                              cursor: "pointer",
                              margin: ".5em",
                            }
                      }
                    />
                    <DeleteButton
                      style={
                        isSelectedScenario(picker)
                          ? {
                              transition: "fill 1s",
                              fill: "white",
                            }
                          : { transition: "fill 1s" }
                      }
                      fontSize="large"
                      deleteAction={() => deleteClicked(picker)}
                    />
                  </CFBox>
                </Grid>
              </Grid>
            ))}
            <Divider />
            <CroplandAccordionSection
              programaticTrigger={triggerAccordian}
              startState={false}
              summary={
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <AddCircleOutline
                    fontSize="large"
                    style={{ marginRight: ".25em" }}
                  />{" "}
                  <CFTypography color="light" align="center" variant="h5">
                    Create New Scenario
                  </CFTypography>
                </div>
              }
            >
              <NewScenarioComponent
                copyFromScenarios={copyFromScenarios}
                fetchProjectInformation={fetchProjectInformation}
                returnHook={() => {
                  settriggerAccordian(triggerAccordian + 1);
                }}
                setLoading={setLoading}
                selectNewScenario={(newname) => {
                  SetpickNewScenario(newname);
                }}
              />
            </CroplandAccordionSection>
          </CFBox>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        sx={{ marginTop: "1em", width: CroplandStyle.width }}
      >
        <SelectField
          disabled={scenarioNameCompleteCollection?.length === 0}
          locationMapFeatureHolder={locationMapFeatureHolder}
          fields={fields}
          setSelectedField={setSelectedField}
          selectedField={selectedField}
          stepNumber="2"
          goToPrevious={() => {}}
          activityTypeId={10}
        />
      </Grid>
      <ScenarioManagement
        disabled={scenarioNameCompleteCollection?.length === 0}
        allOtherFields={fields}
        field={selectedField}
        stepNumber="3"
        fetchProjectInformation={fetchProjectInformation}
        goToPrevious={() => {}}
        selectedScenario={
          selectedScenario && selectedScenario.name
            ? selectedField.scenarios.filter(
                (x) => x.name === selectedScenario.name
              )[0]
            : selectedScenario
        }
      />
      <Grid
        container
        item
        sx={{ marginTop: "1em", width: CroplandStyle.width }}
        spacing={2}
        direction="row"
        alignItems="right"
        justifyContent="right"
      >
        {/* Left buttons */}
        <Grid item container justifyContent="flex-start" xs={4} pr={1}>
          <Grid item>
            <CFButton
              color="secondary"
              onClick={() => {
                navigate("/croplands");
              }}
              sx={{
                margin: "1em",
                padding: "1.5em",
              }}
            >
              Back to Field Management
            </CFButton>
          </Grid>
        </Grid>
        {/* Right buttons */}
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-end"
          xs={8}
          pr={1}
        >
          <Grid item xs={4}>
            <CFButton
              color="progress"
              onClick={Next}
              sx={{ float: "right", margin: "1em", padding: "1.5em" }}
            >
              {nextText}
            </CFButton>
          </Grid>
        </Grid>
      </Grid>
      {localPopupState && localPopupState.showPopup && (
        <CFDialog
          {...localPopupState}
          updatePopup={(e) => updateLocalPopup(e)}
        />
      )}
      <Tooltip title="Back to Top" placement="top">
        <div>
          <FloatingToTopArrow
            onClick={goToTop}
            sx={{ position: "fixed", right: "2em", bottom: "5em" }}
          />
        </div>
      </Tooltip>
    </CFarmPage>
  );
}
export default CroplandsScenarioPage;
