import { Grid } from "@mui/material";
import { PropTypes } from "prop-types";
import colors from "assets/theme/base/colors";

export default function CFHistoricSelectionCard({
  label,
  input,
  labelWidth,
  ...rest
}) {
  return (
    // We've been having trouble with the circle getting squashed horizontally if the space on the page is too small.
    // May have to figure out something other than height/width to enforce the circularness.
    <Grid
      sx={{
        backgroundColor: colors.background.header,
        borderRadius: ".3em",
        boxShadow: "0px 1px 3px 0px #bbb",
        margin: ".3em",

        padding: ".5em",
        width: "auto",
      }}
      alignItems="center"
      {...rest}
      container
      flexDirection="row"
    >
      <Grid item xs={labelWidth || 7}>
        {" "}
        {label}
      </Grid>

      <Grid
        xs={labelWidth ? 12 - labelWidth : 5}
        alignItems="center"
        justifyContent="flex-end"
        item
        container
        sx={{ padding: ".5em" }}
      >
        {" "}
        {input}
      </Grid>
    </Grid>
  );
}

CFHistoricSelectionCard.propTypes = {
  label: PropTypes.string.isRequired,
  input: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
