/* eslint-disable no-plusplus */
import { useState, useEffect } from "react";

export function useToggle(valueNaught) {
  const [currentValue, setValue] = useState(
    typeof valueNaught === "boolean" ? valueNaught : true
  );

  return [
    currentValue,
    (valueNext) =>
      setValue((e) => (typeof valueNext === "boolean" ? valueNext : !e)),
  ];
}

export function useBreakPointPxOnScroll(defaultValues) {
  const scrollPxBreakPoints = defaultValues || [50, 290];
  const [breakPoint, setBreakPoint] = useState([-1, 0]);

  const listenScrollEvent = () => {
    const checkValue = [[-1, 0]];
    scrollPxBreakPoints.forEach((e, i) => {
      if (window.pageYOffset >= e) {
        checkValue.push([i, window.pageYOffset]);
      }
    });
    setBreakPoint(checkValue.slice(-1)[0]);
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);

  return breakPoint;
}

export function useScreenSize() {
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return screenSize;
}

export const useScrollYValue = () => {
  const [scrollY, setScrollY] = useState(0);

  const handleScroll = () => {
    setScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return scrollY;
};

export const useBreakPointsByScrollY = (breakPoints) => {
  const [index, setIndex] = useState(0);
  const scrollY = useScrollYValue();
  useEffect(() => {
    if (Array.isArray(breakPoints)) {
      let i = 0;
      do {
        setIndex(i);
      } while (i < breakPoints.length && scrollY > breakPoints[i++]);
    }
  }, [scrollY]);

  return index;
};
