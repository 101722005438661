// React
import { PropTypes } from "prop-types";
import { useNavigate } from "react-router-dom";

// MUI
import {
  Checkbox,
  Grid,
  FormControlLabel,
  RadioGroup,
  Radio,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import {
  ContentCopy,
  IosShare,
  DeleteTwoTone,
  DescriptionOutlined,
  EditTwoTone,
  ExpandMore,
  FileUpload,
  SystemUpdate,
  SystemUpdateAlt,
  Input,
  ExitToApp,
} from "@mui/icons-material";

// Redux
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUser } from "actions/user";
import { updateUser } from "store/reducers/user";
import { updateLoading } from "store/reducers/mask";

// API Calls
import {
  DELETE_PROJECT,
  DOWNLOAD_EXPORT_PROJECT,
  EMAIL_EXPORT_PROJECT,
  LIST_PROJECT,
  COPY_PROJECT,
  IMPORT_MANAGEMENT,
} from "constants/api";
import { post, upload } from "utils/api";

// Utils
import { popupError } from "utils/generic";

// Assets
import Checked from "assets/icons/Checked.png";
import Unchecked from "assets/icons/Unchecked.png";
import colors from "assets/theme/base/colors";

// Our Components
import CFBox from "components/CFBox";
import CFInput from "components/CFInput";
import CFButton from "components/CFButton";
import CFTypography from "components/CFTypography";

import SelectedActivities from "./SelectedActivities";

export default function ProjectCard({
  project,
  checked,
  projectCheckChanged,
  editProject,
  localPopupState,
  updateLocalPopup,
  fetchAllProjects,
  expanded,
  expandToggle,
  bulkAction,
}) {
  const { name, activities, dateAccessed, reportReady, exportReady, id } =
    project;

  const inputFile = useRef(null);

  const currentUser = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function copyClicked(projectToCopy) {
    dispatch(updateLoading({ loading: true }));

    post(COPY_PROJECT, projectToCopy.id)
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
        } else {
          fetchAllProjects();
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        dispatch(updateLoading({ loading: false }));
      });
  }

  // Navigate to reports page, if project is complete
  function NavigateToReport(projectId) {
    dispatch(updateLoading({ loading: true }));

    // Effectively, this is the same as going to project management, but we just go to the report page instead
    const projectObj = {
      project: projectId,
      user: currentUser,
    };
    post(LIST_PROJECT, projectObj)
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          dispatch(updateLoading({ loading: false }));
        } else {
          dispatch(
            fetchUser({ ...currentUser, forceRecalculateProgress: true })
          );

          navigate("/report");
        }
      })

      .catch((err) => {
        updateLocalPopup({
          message: err,
          title: "Error",
          showPopup: true,
          closeAction: () => {
            updateLocalPopup({
              showPopup: false,
            });
          },
        });
      })
      .finally(() => {
        dispatch(updateLoading({ loading: false }));
      });
  }

  // Project export
  const [exportDestination, setExportDestination] = useState("local");
  const [alternateEmail, setAlternateEmail] = useState("");
  function ExportProject() {
    dispatch(updateLoading({ loading: true }));

    if (exportDestination === "local") {
      post(DOWNLOAD_EXPORT_PROJECT, id)
        .then((res) => {
          if (res.error) {
            popupError(res.error, dispatch);
          } else {
            const text = res.data;
            const a = document.createElement("a");
            a.style.display = "none";
            document.body.appendChild(a);

            // Set the HREF to a Blob representation of the data to be downloaded
            a.href = window.URL.createObjectURL(new Blob([text], { text }));

            // Use download attribute to set set desired file name
            a.setAttribute("download", `export_${id}_${name}.cmt`);

            // Trigger the download by simulating click
            a.click();

            // Cleanup
            window.URL.revokeObjectURL(a.href);
            document.body.removeChild(a);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          dispatch(updateLoading({ loading: false }));
        });
    } else {
      post(EMAIL_EXPORT_PROJECT, {
        ProjectId: id,
        ExportDestination: exportDestination,
        AlternateEmail: alternateEmail,
      })
        .then((res) => {
          if (res.error) {
            popupError(res.error, dispatch);
          } else {
            updateLocalPopup({
              // Popup is simple so can use the global popup
              title: "Project Export Started",
              message: "Please check the indicated email for the results",
              type: "OK",
              showPopup: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          dispatch(updateLoading({ loading: false }));
        });
    }
  }
  useEffect(() => {
    if (localPopupState && localPopupState.showPopup) {
      updateLocalPopup({
        title: "Project Export",
        children: [
          <Grid container px={2}>
            <CFTypography
              variant="body2"
              color="secondary"
              fontWeight="regular"
              pt={2}
            >
              Select a location for the exported project to be sent:
            </CFTypography>
            <Grid container direction="column" alignItems="center" py={2}>
              <RadioGroup
                value={exportDestination}
                onChange={(e) => {
                  setExportDestination(e.target.value);
                }}
              >
                <FormControlLabel
                  value="local"
                  control={<Radio />}
                  label="Download locally to this computer"
                />
                <FormControlLabel
                  value="accountEmail"
                  control={<Radio />}
                  label="Use the email associated with my account"
                />
                <Grid container direction="row" alignItems="center">
                  <FormControlLabel
                    value="otherEmail"
                    control={<Radio />}
                    label="Use an alternate email:"
                  />
                  <CFInput
                    label="Alternate Email"
                    color="primary"
                    placeholder="Alternate Email"
                    size="small"
                    disabled={exportDestination !== "otherEmail"}
                    value={alternateEmail}
                    onChange={(e) => {
                      setAlternateEmail(e.target.value);
                    }}
                  />
                </Grid>
              </RadioGroup>
            </Grid>
          </Grid>,
        ],
        button1: {
          text: "Export",
          onClick: () => {
            ExportProject();

            setExportDestination("local");
            setAlternateEmail("");
            updateLocalPopup({ showPopup: false });
          },
        },
        showPopup: true,
        closeAction: () => {
          setExportDestination("local");
          setAlternateEmail("");
          updateLocalPopup({ showPopup: false });
        },
      });
    }
  }, [exportDestination, alternateEmail]);

  function exportProjectClicked() {
    updateLocalPopup({
      title: "Project Export",
      children: [
        <Grid container px={2} key={id}>
          <CFTypography
            variant="body2"
            color="secondary"
            fontWeight="regular"
            pt={2}
          >
            Please note that exporting a project may take up to 5 minutes.
            Select a location for the exported project to be sent:
          </CFTypography>
          <Grid container direction="column" alignItems="center" py={2}>
            <RadioGroup
              value={exportDestination}
              onChange={(e) => {
                setExportDestination(e.target.value);
              }}
            >
              <FormControlLabel
                value="local"
                control={<Radio />}
                label="Download locally to this computer"
              />
              <FormControlLabel
                value="accountEmail"
                control={<Radio />}
                label="Use the email associated with my account"
              />
              <Grid container direction="row" alignItems="center">
                <FormControlLabel
                  value="otherEmail"
                  control={<Radio />}
                  label="Use an alternate email:"
                />
                <CFInput
                  label="Alternate Email"
                  color="primary"
                  placeholder="Alternate Email"
                  size="small"
                  disabled={exportDestination !== "otherEmail"}
                  value={alternateEmail}
                  onChange={(e) => {
                    setAlternateEmail(e.target.value);
                  }}
                />
              </Grid>
            </RadioGroup>
          </Grid>
        </Grid>,
      ],
      button1: {
        text: "Export",
        onClick: () => {
          ExportProject();

          setExportDestination("local");
          setAlternateEmail("");
          updateLocalPopup({ showPopup: false });
        },
      },
      showPopup: true,
      closeAction: () => {
        setExportDestination("local");
        setAlternateEmail("");
        updateLocalPopup({ showPopup: false });
      },
    });
  }

  function confirmDeleteClicked(projectId) {
    updateLocalPopup({
      showPopup: false,
    });
    dispatch(updateLoading({ loading: true }));

    post(DELETE_PROJECT, projectId)
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
        } else {
          fetchAllProjects();
          updateLocalPopup({
            title: "Project deleted.",
            message: res.Data,
            type: "OK",
            showPopup: true,
          });
          dispatch(updateUser({ ...currentUser, activeProject: null }));
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        dispatch(updateLoading({ loading: false }));
      });
  }

  function deleteConfirmChange(event, projectToDelete) {
    const nameTarget = projectToDelete.name.toLowerCase().trim();
    const typedTarget = event.target.value.toLowerCase().trim();

    if (nameTarget === typedTarget) {
      updateLocalPopup({
        title: `Are you sure you want to delete the project: ${projectToDelete.name}?`,
        component: {
          type: "input",
          label: `Delete is enabled`,

          defaultValue: projectToDelete.name,
          disabled: true,
        },
        button2: {
          onClick: () => confirmDeleteClicked(projectToDelete.id),
          text: `Delete ${projectToDelete.name}`,
          variant: "gradient",
          color: "error",
        },

        showPopup: true,
      });
    }
  }

  function deleteClicked(projectToDelete) {
    updateLocalPopup({
      title: `Are you sure you want to delete the project: ${projectToDelete.name}?`,
      message: `Type the project name to enable the delete button`,
      component: {
        type: "input",
        label: `${projectToDelete.name}`,
        onChange: (e) => deleteConfirmChange(e, projectToDelete),
      },

      button2: {
        text: `Delete ${projectToDelete.name}`,
        variant: "gradient",
        color: "progress",
        disabled: true,
      },

      // type: "OK",
      showPopup: true,
    });
  }

  const setSelectedProject = (projectid) => {
    dispatch(updateLoading({ loading: true }));
    const projectObj = {
      project: projectid,
      user: currentUser,
    };
    post(LIST_PROJECT, projectObj)
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          dispatch(updateLoading({ loading: false }));
        } else {
          const activityArr = JSON.parse(res.data);
          const selActivity = activityArr[0];
          const newActiveProject = {
            activities: [...activityArr],
            id,
            name,
          };
          // update store, this might be redundant because of how fetchUser works.
          dispatch(
            updateUser({ ...currentUser, activeProject: newActiveProject })
          );
          // then fetch from db
          dispatch(
            fetchUser({
              ...currentUser,
              activeProjectId: id,
              forceRecalculateProgress: true,
            })
          );

          /*
          10	"ACTIVITY_TYPE"	"AG"	"Cropland, Pasture, Range, Orchards/Vineyards"
          11	"ACTIVITY_TYPE"	"LIVESTCK"	"Livestock"
          12	"ACTIVITY_TYPE"	"ENGY_USE"	"On-Farm Energy Usage"
          13	"ACTIVITY_TYPE"	"AGRO"	"Agroforestry"
          14	"ACTIVITY_TYPE"	"FOR"	"Forestry"
          15	"ACTIVITY_TYPE"	"NTT"	"Water Quality"
           */
          if (selActivity === 10) {
            navigate("/map-croplands");
          } else if (selActivity === 212320) {
            navigate("/animal-agriculture");
          } else if (selActivity === 12) {
            navigate("/projects");
          } else if (selActivity === 13) {
            navigate("/agroforestry");
          } else if (selActivity === 14) {
            navigate("/map-forestry");
          } else if (selActivity === 15) {
            navigate("/animal-agriculture");
          } else {
            navigate("/projects");
          }
        }
      })

      .catch((err) => {
        updateLocalPopup({
          message: err,
          title: "Error",
          showPopup: true,
          closeAction: () => {
            updateLocalPopup({
              showPopup: false,
            });
          },
        });
      })
      .finally(() => {
        dispatch(updateLoading({ loading: false }));
      });
  };

  function GoToProjectManagement(projectId) {
    setSelectedProject(projectId);

    // window.location.href = "/managementEntry";
  }

  return (
    <Accordion expanded={expanded === name} onChange={expandToggle(name)}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="projectCard-content"
        id="projectCard-header"
      >
        {bulkAction ? (
          <Grid
            item
            container
            alignItems="center"
            justifyContent="space-between"
            key={`${id}grid`}
          >
            {/* Project bulk select */}
            <Grid
              item
              container
              xs={1}
              pr={2.5}
              sx={{ justifyContent: "center" }}
            >
              <CFBox
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                  height: "auto",
                  // border: "1px solid red",
                }}
              >
                <Checkbox
                  // size="tiny"
                  key={`${name}box`}
                  checked={checked}
                  icon={<img src={Unchecked} alt="" />}
                  checkedIcon={<img src={Checked} alt="" />}
                  onClick={(e) => {
                    projectCheckChanged(e, id);
                  }}
                  style={{
                    transform: "scale(.35)",
                    // padding: "1em",
                    // margin: 0,
                    // width: 30,
                    height: "3.8em",
                  }}
                />
              </CFBox>
            </Grid>
            {/* Name */}
            <Grid item container xs={4.2} pl={2.5}>
              <CFBox
                xs={4}
                sx={{
                  textAlign: "left",
                  marginLeft: "1em",
                  marginRight: "1em",
                }}
              >
                {name}
              </CFBox>
            </Grid>
            {/* Selected activities */}
            <Grid item container xs={3.8}>
              <CFBox xs={4}>
                {SelectedActivities({ activityArray: activities })}
              </CFBox>
            </Grid>
            {/* Date accessed */}
            <Grid item container xs={3} sx={{ justifyContent: "end" }}>
              <CFBox
                xs={4}
                sx={{
                  textAlign: "end",

                  marginLeft: "1em",
                  marginRight: "1em",
                }}
              >
                {dateAccessed.toLocaleDateString()}
              </CFBox>
            </Grid>
          </Grid>
        ) : (
          <Grid
            item
            container
            alignItems="center"
            justifyContent="space-between"
            key={`${id}grid`}
          >
            {/* Name */}
            <Grid item container xs={5.2}>
              <CFBox
                xs={4}
                sx={{
                  textAlign: "left",
                  marginLeft: "1em",
                  marginRight: "1em",
                }}
              >
                {name}
              </CFBox>
            </Grid>
            {/* Selected activities */}
            <Grid item container xs={3.8}>
              <CFBox xs={4}>
                {SelectedActivities({ activityArray: activities })}
              </CFBox>
            </Grid>
            {/* Date accessed */}
            <Grid item container xs={3} sx={{ justifyContent: "end" }}>
              <CFBox
                xs={4}
                sx={{
                  textAlign: "end",

                  marginLeft: "1em",
                  marginRight: "1em",
                }}
              >
                {dateAccessed.toLocaleDateString()}
              </CFBox>
            </Grid>
          </Grid>
        )}
      </AccordionSummary>
      {/* TODO: Set up accessibility features for drop-down actions */}
      <AccordionDetails>
        {/* Project Actions */}
        <Grid
          item
          container
          justifyContent={
            currentUser?.isVerifiedByEmail ? "space-between" : "center"
          }
          p={2}
        >
          {/* Edit */}
          {currentUser?.isVerifiedByEmail && (
            <IconButton
              style={{ cusor: "pointer" }}
              onClick={() => editProject(id)}
            >
              <EditTwoTone fontSize="large" color="black" />
              <CFTypography variant="h6" fontWeight="regular" pl={1}>
                Edit
              </CFTypography>
            </IconButton>
          )}
          {/* Delete */}
          {currentUser?.isVerifiedByEmail && (
            <IconButton
              style={{ cusor: "pointer" }}
              onClick={() => deleteClicked({ name, id })}
            >
              <DeleteTwoTone fontSize="large" color="black" />
              <CFTypography variant="h6" fontWeight="regular" pl={1}>
                Delete
              </CFTypography>
            </IconButton>
          )}
          {/* Copy */}
          {currentUser?.isVerifiedByEmail && (
            <IconButton
              style={{ cusor: "pointer" }}
              onClick={() => copyClicked({ name, id })}
            >
              <ContentCopy fontSize="large" color="black" />
              <CFTypography variant="h6" fontWeight="regular" pl={1}>
                Duplicate
              </CFTypography>
            </IconButton>
          )}
          {/* Download Report */}
          {currentUser?.isVerifiedByEmail && (
            <IconButton
              disabled={!reportReady}
              style={{
                cursor: reportReady ? "pointer" : "",
                opacity: reportReady ? "1" : ".4",
              }}
              onClick={reportReady ? () => NavigateToReport(id) : null}
            >
              <DescriptionOutlined fontSize="large" color="black" />
              <CFTypography variant="h6" fontWeight="regular" pl={1}>
                Go to Report
              </CFTypography>
            </IconButton>
          )}
          {/* Export Project */}
          {currentUser?.isVerifiedByEmail && (
            <IconButton
              disabled={!exportReady}
              style={{
                cursor: exportReady ? "pointer" : "",
                opacity: exportReady ? "1" : ".4",
              }}
              onClick={exportReady ? () => exportProjectClicked() : null}
            >
              <IosShare
                fontSize="large"
                color="black"
                style={{ transform: "rotate(90deg)" }}
              />
              <CFTypography variant="h6" fontWeight="regular" pl={1}>
                Export Project
              </CFTypography>
            </IconButton>
          )}

          {/* Go to Project */}
          <CFButton
            onClick={() => GoToProjectManagement(id)}
            size="medium"
            color="progress"
            sx={{
              backgroundColor: colors.info.dullFocus,
              color: "white.main",
            }}
          >
            Enter Management
          </CFButton>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
