// React
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

// MUI
import { Grid } from "@mui/material";
import Stack from "@mui/material/Stack";

import ArrowCircleRightTwoToneIcon from "@mui/icons-material/ArrowCircleRightTwoTone";
import Tooltip from "@mui/material/Tooltip";
import { set } from "lodash/fp";
import colors from "assets/theme/base/colors";

import CFInput from "components/CFInput";
// Our Components
import CFBox from "components/CFBox";

import CFTypography from "components/CFTypography";
import CFButton from "components/CFButton";
import { updateLoading } from "store/reducers/mask";
import { post } from "utils/api";
import { popupError } from "utils/generic";
import { UPDATE_SHEEP_FEED, FETCH_SHEEP_FEED } from "constants/api";
// Constants

export default function SheepDMIFeed({
  id,
  yearValue,
  animalCategory,
  animalCategoryId,
  groupId,
  anagObject,
  anagObjectChanged,
  verifyDone,
}) {
  const dispatch = useDispatch();

  const { info } = colors;

  const [averageDryMatterIntake, setAverageDryMatterIntake] = useState("");

  const [saveSuccessMsg, setSaveSuccessMsg] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dmiExists, setDmiExists] = useState(false);

  const handleAvgDryMatterIntakeChange = (event) => {
    setAverageDryMatterIntake(event.target.value);
    if (event.target.value > 0) {
      setDmiExists(true);
    }
  };
  const fetchSheepDMIFeeds = () => {
    setLoading(true);
    const popObj = {
      popid: id,
    };
    post(FETCH_SHEEP_FEED, popObj)
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          setLoading(false);
        } else {
          const json = JSON.parse(res.data);

          setAverageDryMatterIntake(json);
          if (json > 0) {
            setDmiExists(true);
            verifyDone("green");
          } else {
            verifyDone("grey");
          }
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const updateSheepDMIPops = () => {
    setLoading(true);
    const animalTypesObj = {
      avgDryMtrIntke: averageDryMatterIntake,
      popid: id,
    };
    post(UPDATE_SHEEP_FEED, animalTypesObj)
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          setLoading(false);
        } else {
          setSaveSuccessMsg(true);
          verifyDone("green");
          // fetchSheepDMIFeeds();

          // anagObjectChanged(true);
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchSheepDMIFeeds();
  }, [anagObject.activeAnimalCategories]);

  return (
    <CFBox width="100%">
      <Grid container item>
        {/* Main form */}
        <CFBox width="100%">
          <Grid container direction="row" xs={12} sm={12} md={12}>
            <Grid item container xs={12} direction="column">
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <CFBox
                    sx={{
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      marginTop: "1em",
                      borderRadius: ".3em",
                      display: "flex",
                      marginBottom: "1em",
                    }}
                  >
                    <CFTypography
                      sx={{
                        fontSize: "15px",
                        color: info.dullFocus,
                        fontWeight: "bold",
                        marginLeft: "1em",
                      }}
                    >
                      Average daily dry matter intake:
                    </CFTypography>

                    <CFInput
                      sx={{
                        padding: "10px",
                        fieldset: {
                          borderColor: info.dullFocus,
                          borderStyle: "solid",
                          borderWidth: "2px",
                        },
                      }}
                      color="primary"
                      id="avgDMI"
                      name="avgDMI"
                      value={averageDryMatterIntake}
                      onChange={handleAvgDryMatterIntakeChange}
                      placeholder=""
                      style={{ width: "30%", marginLeft: "2em" }}
                      InputProps={{
                        endAdornment: "lbs",
                      }}
                    />
                  </CFBox>
                </Grid>
              </Grid>
            </Grid>

            <Grid container py={2} px={2} justifyContent="space-around">
              <Grid item xs={12} sm={12} md={12}>
                <CFButton
                  type="submit"
                  sx={{
                    float: "right",
                  }}
                  color="progress"
                  onClick={() => updateSheepDMIPops()}
                  disabled={!dmiExists}
                >
                  Save
                </CFButton>
              </Grid>
            </Grid>
            {saveSuccessMsg && (
              <Grid container justifyContent="space-around">
                <Grid item xs={2} sm={2} md={2} alignItems="center">
                  <CFBox id="successMessage">
                    <CFTypography
                      sx={{
                        fontSize: "15px",
                        color: "green",

                        marginBottom: "0.5em",
                        marginTop: "0.5em",
                      }}
                    >
                      Save was successful.
                    </CFTypography>
                  </CFBox>
                </Grid>
              </Grid>
            )}
          </Grid>
        </CFBox>
      </Grid>
    </CFBox>
  );
}
