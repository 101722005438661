import PropTypes from "prop-types";
import CFBox from "components/CFBox";
import CFStepMarker from "components/CFStepMarker/CFStepMarker";
import UserGuidePopup from "components/CFUserGuidePopup/UserGuidePopup";
import CFTypography from "components/CFTypography";
import RequiredComplete from "./RequiredComplete";
import styles from "../../CFUserGuidePopup/UserGuidePopup.module.css";

export default function CFStep({
  stepLabel,
  stepNumber,
  helpText,
  subText,
  complete,
  useGuideWidth,
}) {
  return (
    <CFBox width="100%">
      <CFBox className="cf-flex" style={{ gap: ".5em" }}>
        {stepNumber && (
          <CFStepMarker label={stepNumber} style={{ fontSize: "1em" }} />
        )}

        {stepLabel && helpText && (
          <UserGuidePopup
            content={helpText}
            label={stepLabel}
            variant="h3"
            width={useGuideWidth}
          />
        )}
        {stepLabel && !helpText && (
          <CFTypography className={`${styles.buttonLabel}`} variant="h3">
            {stepLabel}
          </CFTypography>
        )}
        {complete !== null && (
          <RequiredComplete complete={complete} size="2em" else="fromstep" />
        )}
      </CFBox>
      {subText && (
        <CFBox sx={{ paddingTop: "1em", fontSize: "1em" }} color="text">
          {subText}
        </CFBox>
      )}
    </CFBox>
  );
}
CFStep.defaultProps = {
  helpText: null,
  subText: null,
  complete: null,
  stepNumber: null,
};

// Typechecking props for the CFStep
CFStep.propTypes = {
  stepLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  stepNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  helpText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  subText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  complete: PropTypes.oneOfType([PropTypes.bool]),
};
