// React
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

// MUI
import { Grid, Accordion, AccordionDetails } from "@mui/material";
import Stack from "@mui/material/Stack";

import ArrowCircleRightTwoToneIcon from "@mui/icons-material/ArrowCircleRightTwoTone";
import Tooltip from "@mui/material/Tooltip";
import { set } from "lodash/fp";
import colors from "assets/theme/base/colors";

import CFInput from "components/CFInput";
// Our Components
import CFBox from "components/CFBox";
import SwineHeader from "components/AnimalAgracultureComponents/SwineHeader";
import SwineInput from "components/AnimalAgracultureComponents/SwineInput";

import CFTypography from "components/CFTypography";
import CFButton from "components/CFButton";
import { updateLoading } from "store/reducers/mask";
import { post } from "utils/api";
import { popupError } from "utils/generic";
import { UPDATE_GOAT_POP, FETCH_SHEEP_DMI } from "constants/api";
import { getTextInput } from "./AnimalAgCommonFunc";

// Constants

export default function GoatPopulation({
  id,
  yearValue,
  animalCategory,
  animalCategoryId,
  groupId,
  anagObject,
  anagObjectChanged,
  verifyDone,
}) {
  const dispatch = useDispatch();

  const { info } = colors;

  const [malePopln, setMalePopln] = useState("");
  const [maleWght, setMaleWght] = useState("");
  const [maleWeaningWght, setMaleWeaningWght] = useState("");
  const [maleAvgWghtOneYr, setMaleAvgWghtOneYr] = useState("");
  const [femalePopln, setFemalePopln] = useState("");
  const [femaleWght, setFemaleWght] = useState("");
  const [femaleweaningWght, setFemaleWeaningWght] = useState("");
  const [femaleAvgWghtOneYr, setFemaleAvgWghtOneYr] = useState("");
  const [femalePregnant, setFemalePregnant] = useState("");

  const [femalePregWght, setFemalePregWght] = useState("");
  const [femalePregWeaningWght, setFemalePregWeaningWght] = useState("");
  const [femalePregAvgWghtOneYr, setFemalePregAvgWghtOneYr] = useState("");
  const [femaleLact, setFemaleLact] = useState("");
  const [femaleLactWght, setFemaleLactWght] = useState("");
  const [femaleLactWeaningWght, setFemaleLactWeaningWght] = useState("");
  const [femaleLactAvgWghtOneYr, setFemaleLactAvgWghtOneYr] = useState("");

  const [saveSuccessMsg, setSaveSuccessMsg] = useState(false);
  const [loading, setLoading] = useState(false);

  const validateFetchInputs = (data) => {
    let isValid = false;
    const dataIncompete = [];
    if (data.MalePopln > 0) {
      if (
        data.MaleWeight &&
        (data.MaleWeaningWgt ||
          data.FemaleNonPregNonLactWeaningWgt ||
          data.FemalePregnantWeaningWgt ||
          data.FemaleLactWeaningWgt) &&
        (data.MaleAvgWgtOneYr ||
          data.FemaleNonPregNonLactAvgWgtOneYr ||
          data.FemalePregnantAvgWgtOneYr ||
          data.FemaleLactAvgWgtOneYr)
      ) {
        dataIncompete.push(true);
      } else {
        dataIncompete.push(false);
      }
    }
    if (data.FemaleNonPregNonLactPopln > 0) {
      if (
        data.FemaleNonPregNonLactWeight &&
        (data.MaleWeaningWgt ||
          data.FemaleNonPregNonLactWeaningWgt ||
          data.FemalePregnantWeaningWgt ||
          data.FemaleLactWeaningWgt) &&
        (data.MaleAvgWgtOneYr ||
          data.FemaleNonPregNonLactAvgWgtOneYr ||
          data.FemalePregnantAvgWgtOneYr ||
          data.FemaleLactAvgWgtOneYr)
      ) {
        dataIncompete.push(true);
      } else {
        dataIncompete.push(false);
      }
    }
    if (data.FemalePregnantPopln > 0) {
      if (
        data.FemalePregnantWeight &&
        (data.MaleWeaningWgt ||
          data.FemaleNonPregNonLactWeaningWgt ||
          data.FemalePregnantWeaningWgt ||
          data.FemaleLactWeaningWgt) &&
        (data.MaleAvgWgtOneYr ||
          data.FemaleNonPregNonLactAvgWgtOneYr ||
          data.FemalePregnantAvgWgtOneYr ||
          data.FemaleLactAvgWgtOneYr)
      ) {
        dataIncompete.push(true);
      } else {
        dataIncompete.push(false);
      }
    }
    if (data.FemaleLactPopln > 0) {
      if (
        data.FemaleLactWeight &&
        (data.MaleWeaningWgt ||
          data.FemaleNonPregNonLactWeaningWgt ||
          data.FemalePregnantWeaningWgt ||
          data.FemaleLactWeaningWgt) &&
        (data.MaleAvgWgtOneYr ||
          data.FemaleNonPregNonLactAvgWgtOneYr ||
          data.FemalePregnantAvgWgtOneYr ||
          data.FemaleLactAvgWgtOneYr)
      ) {
        dataIncompete.push(true);
      } else {
        dataIncompete.push(false);
      }
    }
    isValid =
      dataIncompete.length > 0 && dataIncompete.every((v) => v === true);
    return isValid;
  };
  const weaningWeightExists = (w1, w2, w3) => {
    let wghtExists = false;
    if (w1 || w2 || w3) {
      wghtExists = true;
    }

    return wghtExists;
  };
  const birthWeightExists = (w1, w2, w3) => {
    let bWghtExists = false;
    if (w1 || w2 || w3) {
      bWghtExists = true;
    }
    return bWghtExists;
  };
  const fetchSheepDMIFeeds = () => {
    setLoading(true);
    const popObj = {
      popid: id,
    };
    post(FETCH_SHEEP_DMI, popObj)
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          setLoading(false);
        } else {
          const json = JSON.parse(res.data);
          const pop = json.GoatPopMonth;
          setMalePopln(pop.MalePopln);
          setMaleWght(pop.MaleWeight);
          setMaleWeaningWght(pop.MaleWeaningWgt);
          setMaleAvgWghtOneYr(pop.MaleAvgWgtOneYr);
          setFemalePopln(pop.FemaleNonPregNonLactPopln);
          setFemaleWght(pop.FemaleNonPregNonLactWeight);
          setFemaleWeaningWght(pop.FemaleNonPregNonLactWeaningWgt);
          setFemaleAvgWghtOneYr(pop.FemaleNonPregNonLactAvgWgtOneYr);
          setFemalePregnant(pop.FemalePregnantPopln);

          setFemalePregWght(pop.FemalePregnantWeight);
          setFemalePregWeaningWght(pop.FemalePregnantWeaningWgt);
          setFemalePregAvgWghtOneYr(pop.FemalePregnantAvgWgtOneYr);
          setFemaleLact(pop.FemaleLactPopln);
          setFemaleLactWght(pop.FemaleLactWeight);
          setFemaleLactWeaningWght(pop.FemaleLactWeaningWgt);
          setFemaleLactAvgWghtOneYr(pop.FemaleLactAvgWgtOneYr);
          if (validateFetchInputs(pop)) {
            verifyDone("green");
          } else {
            verifyDone("grey");
          }
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const updateGoatPopMonth = () => {
    setLoading(true);
    const animalTypesObj = {
      malePop: malePopln,
      maleWt: maleWght,
      maleWngWt: maleWeaningWght,
      maleAvgWt: maleAvgWghtOneYr,
      femalePNonLact: femalePopln,
      femaleWt: femaleWght,
      femaleWngWt: femaleweaningWght,
      femaleAvgWt: femaleAvgWghtOneYr,

      femalePreg: femalePregnant,

      femalePregWt: femalePregWght,
      femalePregWngWt: femalePregWeaningWght,
      femalePregAvgWt: femalePregAvgWghtOneYr,
      femaleLct: femaleLact,
      femaleLctWt: femaleLactWght,
      femaleLctWngWt: femaleLactWeaningWght,
      femaleLctAvgWt: femaleLactAvgWghtOneYr,
      popid: id,
    };
    post(UPDATE_GOAT_POP, animalTypesObj)
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          setLoading(false);
        } else {
          setSaveSuccessMsg(true);
          verifyDone("green");
          // fetchSheepDMIFeeds();

          // anagObjectChanged(true);
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const validateUserInputs = () => {
    let isValid = false;
    const dataIncompete = [];
    if (malePopln > 0) {
      if (
        maleWght &&
        (maleWeaningWght ||
          femaleweaningWght ||
          femalePregWeaningWght ||
          femaleLactWeaningWght) &&
        (maleAvgWghtOneYr ||
          femaleAvgWghtOneYr ||
          femalePregAvgWghtOneYr ||
          femaleLactAvgWghtOneYr)
      ) {
        dataIncompete.push(true);
      } else {
        dataIncompete.push(false);
      }
    }
    if (femalePopln > 0) {
      if (
        femaleWght &&
        (maleWeaningWght ||
          femaleweaningWght ||
          femalePregWeaningWght ||
          femaleLactWeaningWght) &&
        (maleAvgWghtOneYr ||
          femaleAvgWghtOneYr ||
          femalePregAvgWghtOneYr ||
          femaleLactAvgWghtOneYr)
      ) {
        dataIncompete.push(true);
      } else {
        dataIncompete.push(false);
      }
    }
    if (femalePregnant > 0) {
      if (
        femalePregWght &&
        (maleWeaningWght ||
          femaleweaningWght ||
          femalePregWeaningWght ||
          femaleLactWeaningWght) &&
        (maleAvgWghtOneYr ||
          femaleAvgWghtOneYr ||
          femalePregAvgWghtOneYr ||
          femaleLactAvgWghtOneYr)
      ) {
        dataIncompete.push(true);
      } else {
        dataIncompete.push(false);
      }
    }
    if (femaleLact > 0) {
      if (
        femaleLactWght &&
        (maleWeaningWght ||
          femaleweaningWght ||
          femalePregWeaningWght ||
          femaleLactWeaningWght) &&
        (maleAvgWghtOneYr ||
          femaleAvgWghtOneYr ||
          femalePregAvgWghtOneYr ||
          femaleLactAvgWghtOneYr)
      ) {
        dataIncompete.push(true);
      } else {
        dataIncompete.push(false);
      }
    }

    isValid =
      dataIncompete.length > 0 && dataIncompete.every((v) => v === true);

    return isValid;
  };

  useEffect(() => {
    fetchSheepDMIFeeds();
  }, [anagObject.activeAnimalCategories]);

  return (
    <CFBox width="100%">
      <Grid container item>
        {/* Main form */}
        <CFBox width="100%">
          <Grid container direction="row" xs={12} sm={12} md={12}>
            <Grid container direction="row" xs={12} sm={12} md={12}>
              <Grid item xs={12} sm={12} md={12}>
                <CFTypography
                  sx={{
                    fontSize: "0.7em",

                    color: info.dullFocus,

                    marginBottom: "0.7em",
                  }}
                >
                  Enter the average annual population and body weight details
                  for each goat category below. Population may be zero.
                </CFTypography>
              </Grid>
            </Grid>
            <Grid item container xs={12} direction="row">
              <Accordion
                defaultExpanded
                sx={{
                  borderRadius: ".3em",
                  marginBottom: ".5em",
                }}
              >
                <SwineHeader text="Male" />
                <AccordionDetails>
                  <Grid
                    container
                    xs={12}
                    spacing={2}
                    alignItems="center"
                    direction="row"
                  >
                    <SwineInput
                      question="Male"
                      value={malePopln}
                      onChange={setMalePopln}
                    />
                    <SwineInput
                      question="Average Live Body Weight"
                      value={maleWght}
                      onChange={setMaleWght}
                      endAdornment="lbs"
                    />
                    <SwineInput
                      question="Average Body Weight at Weaning"
                      value={maleWeaningWght}
                      onChange={setMaleWeaningWght}
                      endAdornment="lbs"
                      disabled={weaningWeightExists(
                        femaleweaningWght,
                        femalePregWeaningWght,
                        femaleLactWeaningWght
                      )}
                    />
                    <SwineInput
                      question="Average Body Weight at 1 Year"
                      value={maleAvgWghtOneYr}
                      onChange={setMaleAvgWghtOneYr}
                      endAdornment="lbs"
                      disabled={birthWeightExists(
                        femaleAvgWghtOneYr,
                        femalePregAvgWghtOneYr,
                        femaleLactAvgWghtOneYr
                      )}
                    />
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item container xs={12} direction="row">
              <Accordion
                defaultExpanded
                sx={{
                  borderRadius: ".3em",
                  marginBottom: ".5em",
                }}
              >
                <SwineHeader text="Female (Not Pregnant and Not Lactating)" />
                <AccordionDetails>
                  <Grid
                    container
                    xs={12}
                    spacing={2}
                    alignItems="center"
                    direction="row"
                  >
                    <SwineInput
                      question="Female (Not Pregnant and Not Lactating)"
                      value={femalePopln}
                      onChange={setFemalePopln}
                    />
                    <SwineInput
                      question="Average Live Body Weight"
                      value={femaleWght}
                      onChange={setFemaleWght}
                      endAdornment="lbs"
                    />
                    <SwineInput
                      question="Average Body Weight at Weaning"
                      value={femaleweaningWght}
                      onChange={setFemaleWeaningWght}
                      endAdornment="lbs"
                      disabled={weaningWeightExists(
                        maleWeaningWght,
                        femalePregWeaningWght,
                        femaleLactWeaningWght
                      )}
                    />
                    <SwineInput
                      question="Average Body Weight at 1 Year"
                      value={femaleAvgWghtOneYr}
                      onChange={setFemaleAvgWghtOneYr}
                      endAdornment="lbs"
                      disabled={birthWeightExists(
                        maleAvgWghtOneYr,
                        femalePregAvgWghtOneYr,
                        femaleLactAvgWghtOneYr
                      )}
                    />
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item container xs={12} direction="row">
              <Accordion
                defaultExpanded
                sx={{
                  borderRadius: ".3em",
                  marginBottom: ".5em",
                }}
              >
                <SwineHeader text="Female (Pregnant)" />
                <AccordionDetails>
                  <Grid
                    container
                    xs={12}
                    spacing={2}
                    alignItems="center"
                    direction="row"
                  >
                    <SwineInput
                      question="Female (Pregnant)"
                      value={femalePregnant}
                      onChange={setFemalePregnant}
                    />

                    <SwineInput
                      question="Average Live Body Weight"
                      value={femalePregWght}
                      onChange={setFemalePregWght}
                      endAdornment="lbs"
                    />
                    <SwineInput
                      question="Average Body Weight at Weaning"
                      value={femalePregWeaningWght}
                      onChange={setFemalePregWeaningWght}
                      endAdornment="lbs"
                      disabled={weaningWeightExists(
                        maleWeaningWght,
                        femaleweaningWght,
                        femaleLactWeaningWght
                      )}
                    />
                    <SwineInput
                      question="Average Body Weight at 1 Year"
                      value={femalePregAvgWghtOneYr}
                      onChange={setFemalePregAvgWghtOneYr}
                      endAdornment="lbs"
                      disabled={birthWeightExists(
                        maleAvgWghtOneYr,
                        femaleAvgWghtOneYr,
                        femaleLactAvgWghtOneYr
                      )}
                    />
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item container xs={12} direction="row">
              <Accordion
                defaultExpanded
                sx={{
                  borderRadius: ".3em",
                  marginBottom: ".5em",
                }}
              >
                <SwineHeader text="Female (Lactating)" />
                <AccordionDetails>
                  <Grid
                    container
                    xs={12}
                    spacing={2}
                    alignItems="center"
                    direction="row"
                  >
                    <SwineInput
                      question="Female (Lactating)"
                      value={femaleLact}
                      onChange={setFemaleLact}
                    />
                    <SwineInput
                      question="Average Live Body Weight"
                      value={femaleLactWght}
                      onChange={setFemaleLactWght}
                      endAdornment="lbs"
                    />
                    <SwineInput
                      question="Average Body Weight at Weaning"
                      value={femaleLactWeaningWght}
                      onChange={setFemaleLactWeaningWght}
                      endAdornment="lbs"
                      disabled={weaningWeightExists(
                        maleWeaningWght,
                        femaleweaningWght,
                        femalePregWeaningWght
                      )}
                    />
                    <SwineInput
                      question="Average Body Weight at 1 Year"
                      value={femaleLactAvgWghtOneYr}
                      onChange={setFemaleLactAvgWghtOneYr}
                      endAdornment="lbs"
                      disabled={birthWeightExists(
                        maleAvgWghtOneYr,
                        femaleAvgWghtOneYr,
                        femalePregAvgWghtOneYr
                      )}
                    />
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid container py={2} px={2} justifyContent="space-around">
              <Grid item xs={12} sm={12} md={12}>
                <CFButton
                  type="submit"
                  sx={{
                    float: "right",
                  }}
                  color="progress"
                  onClick={() => updateGoatPopMonth()}
                  disabled={!validateUserInputs()}
                >
                  Save
                </CFButton>
              </Grid>
            </Grid>
            {saveSuccessMsg && (
              <Grid container justifyContent="space-around">
                <Grid item xs={2} sm={2} md={2} alignItems="center">
                  <CFBox id="successMessage">
                    <CFTypography
                      sx={{
                        fontSize: "15px",
                        color: "green",

                        marginBottom: "0.5em",
                        marginTop: "0.5em",
                      }}
                    >
                      Save was successful.
                    </CFTypography>
                  </CFBox>
                </Grid>
              </Grid>
            )}
          </Grid>
        </CFBox>
      </Grid>
    </CFBox>
  );
}
