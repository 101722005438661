/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable arrow-body-style */
/* eslint-disable react/function-component-definition */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Avatar, Icon, Menu, MenuItem } from "@mui/material";
import CFBox from "components/CFBox";
import CFTypography from "components/CFTypography";
import nrcsDark from "assets/cfBranding/usda_nrcs_dark.png";
import csuDark from "assets/cfBranding/csu-ram-stacked-dark.png";
import CFAvatar from "components/CFAvatar";
import { unlogin, loggedIn } from "utils/auth";
import { useScreenSize } from "hooks";
import CFButton from "components/CFButton";
import colors from "assets/theme/base/colors";
import CFDialog from "components/CFDialog";
import { getInitials } from "./cfarmheader.config";

export const LinkButton = ({ to, label, onClick }) => {
  const passProps = to ? { to, component: Link } : { onClick };

  return (
    <CFButton {...passProps} sx={{ padding: 0 }} variant="text" color="primary">
      <CFTypography component="span" sx={{ fontSize: "1.15rem" }}>
        {label}
      </CFTypography>
    </CFButton>
  );
};

const ExternalOrInternalLink = (props) => {
  const { href, label, to } = props;
  return href ? (
    <a target="_blank" rel="noopener noreferrer" href={href}>
      <CFTypography fontSize="1.2em" variant="text">
        {label}
      </CFTypography>
    </a>
  ) : (
    <Link to={to}>
      <CFTypography fontSize="1.2em" variant="text">
        {label}
      </CFTypography>
    </Link>
  );
};
export const DropdownLink = (props) => {
  const { label, collapse } = props;
  const [dropdown, setDropdown] = useState(null);

  const openDropdown = ({ currentTarget }) => setDropdown(currentTarget);
  const closeDropdown = () => setDropdown(null);

  const iconStyles = {
    ml: 0.5,
    fontWeight: "bold",
    transition: "transform 200ms ease-in-out",
  };

  const dropdownIconStyles = {
    transform: dropdown ? "rotate(180deg)" : "rotate(0)",
    ...iconStyles,
  };

  const [localPopupState, updateLocalPopup] = useState({});
  const handleDropdownPopup = (item) => {
    updateLocalPopup({
      title: item.label,
      children: item.description,
      style: item.style,
      showPopup: true,
      closeAction: () => updateLocalPopup({ showPopup: false }),
    });
  };

  return (
    <CFBox>
      <LinkButton
        onClick={openDropdown}
        label={
          <CFBox className="cf-flex gap-zero">
            <span>{label}</span>
            <Icon sx={dropdownIconStyles}>expand_more</Icon>
          </CFBox>
        }
      />
      <Menu
        anchorEl={dropdown}
        open={Boolean(dropdown)}
        onClose={closeDropdown}
      >
        {collapse.map((e) => {
          return (
            <MenuItem onClick={closeDropdown} key={e.label}>
              {e.type === "link" ? (
                <ExternalOrInternalLink {...e} />
              ) : (
                <CFTypography
                  fontSize="1.2em"
                  variant="text"
                  onClick={() => handleDropdownPopup(e)}
                >
                  {e.label}
                </CFTypography>
              )}
            </MenuItem>
          );
        })}
      </Menu>

      {localPopupState && localPopupState.showPopup && (
        <CFDialog
          {...localPopupState}
          updatePopup={(e) => updateLocalPopup(e)}
        />
      )}
    </CFBox>
  );
};

export const Branding = () => {
  const { width } = useScreenSize();
  const defaultHeight = 30;
  const defaultTextSize = 20;
  const cometTextProps = {
    variant: "text",
    fontSize: defaultTextSize,
    lineHeight: "1em",
    sx: { fontWeight: "600" },
  };
  return (
    <CFBox className="cf-flex">
      <CFButton
        sx={{ padding: "0em", justifyItems: "right" }}
        to="/home"
        variant="text"
        color="primary"
        component={Link}
        className="cf-flex gap-zero column"
      >
        <CFTypography {...cometTextProps}>COMET</CFTypography>
        <CFTypography
          {...cometTextProps}
          sx={{
            textAlign: "right",
            width: "100%",
            fontWeight: "500",
            fontSize: defaultTextSize * 0.9,
          }}
        >
          Farm
        </CFTypography>
      </CFButton>
      {width > 970 && (
        <>
          <img src={nrcsDark} alt="nrcs-logo" height={defaultHeight} />
          <img src={csuDark} alt="csu-logo" height={defaultHeight * 1.2} />
        </>
      )}
    </CFBox>
  );
};

const avatarStyles = {
  backgroundColor: `${colors.primary[25]}`,
  color: `${colors.primary[50]}`,
};

const LoggedItAvatar = ({ currentUser }) => {
  const { image } = currentUser;
  return (
    <>
      {image ? (
        <CFAvatar src={image} alt={getInitials(currentUser)} size="sm" />
      ) : (
        <Avatar sx={avatarStyles}>{getInitials(currentUser)}</Avatar>
      )}
      <CFBox className="cf-flex gap-zero column" alignItems="start">
        {currentUser?.firstName}
        <Link to="/" onClick={unlogin} style={{ color: "inherit" }}>
          Sign Out
        </Link>
      </CFBox>
    </>
  );
};
const LoggedOutAvatar = () => {
  return (
    <>
      <Avatar sx={avatarStyles} />
      <CFBox className="cf-flex gap-zero column" alignItems="start">
        <Link to="/login" onClick={unlogin} style={{ color: "inherit" }}>
          Sign In
        </Link>
      </CFBox>
    </>
  );
};

export const UserAvatar = () => {
  const currentUser = useSelector((state) => state.user);
  return (
    <CFBox
      style={{ marginRight: ".5em" }}
      className="cf-flex gap-half"
      fontSize="small"
    >
      {loggedIn() && currentUser ? (
        <LoggedItAvatar currentUser={currentUser} />
      ) : (
        <LoggedOutAvatar />
      )}
    </CFBox>
  );
};
