import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Grid from "@mui/material/Grid";

import CFStep from "components/CFarmParts/CFStep";
import CFTypography from "components/CFTypography";
import CFBox from "components/CFBox";
import colors from "assets/theme/base/colors";
import CFButton from "components/CFButton";
import CFDialog from "components/CFDialog";
import { popupError } from "utils/generic";
import {
  FETCH_ANAG_COUNTIES,
  FETCH_ANAG_STATES,
  UPDATE_ANIMAL_COUNTY,
} from "constants/api";
import CFLookupPicker from "components/CroplandsComponents/CFLookupPicker/CFLookupPicker";
import CFSnackbar from "components/CFSnackbar";
import { post } from "utils/api";
import CFLoading from "components/CFLoading";
import style from "./AnimalAgStyle";

function AnimalZipCode(props) {
  const { anagOpObject, anagObjectChanged, currentUser } = props;
  const empty = { id: 212450, name: "Select" };
  const dispatch = useDispatch();
  const [localPopupState, updateLocalPopup] = useState({});
  const [countyVal, setCountyVal] = useState(empty);
  const [countyValId, setCountyValId] = useState("");
  const [countyList, setCountyList] = useState([]);

  const [stateVal, setStateVal] = useState(empty);
  const [stateValId, setStateValId] = useState("");
  const [stateList, setStateList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const { info } = colors;
  const fetchStateList = () => {
    setLoading(true);

    post(FETCH_ANAG_STATES, { userid: currentUser })
      .then((res) => {
        if (res.error) {
          console.log("fetch state", res.error);
          popupError(res.error, dispatch);
          setLoading(false);
        } else {
          setStateVal({
            id: res.data.selectedStateId,
            name: res.data.selectedStateName,
          });
          setStateValId(res.data.selectedStateId);
          setStateList(res.data.stateList);
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const fetchCountyList = (stateId) => {
    setLoading(true);
    const countyObj = {
      stid: stateId,
      userid: currentUser,
    };

    post(FETCH_ANAG_COUNTIES, countyObj)
      .then((res) => {
        if (res.error) {
          console.log("fetch county", res.error);
          popupError(res.error, dispatch);
          setLoading(false);
        } else {
          setCountyVal({
            id: res.data.selectedCountyId,
            name: res.data.selectedCountyName,
          });
          setCountyValId(res.data.selectedCountyId);
          setCountyList(res.data.countyList);
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  useEffect(() => {
    if (anagOpObject && anagOpObject.id) {
      fetchStateList();
    }
  }, [anagOpObject.id]);
  useEffect(() => {
    if (stateValId) fetchCountyList(stateValId);
  }, [stateValId]);

  const submitAnagCounty = (e) => {
    setLoading(true);
    e.preventDefault();

    const stCountyObj = {
      cty: countyValId,
      st: stateValId,
      id: anagOpObject.userId,
    };

    post(UPDATE_ANIMAL_COUNTY, stCountyObj)
      .then((res) => {
        if (res.error) {
          console.log("update county", res.error);
          popupError(res.error, dispatch);
          setLoading(false);
        } else {
          setSnackbarOpen(true);
          anagObjectChanged(true);
        }
      })
      .catch((err) => {
        popupError(err, dispatch);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <CFBox>
      {anagOpObject.id !== "" && (
        <CFBox>
          {loading && <CFLoading local />}
          <CFStep
            stepLabel="Animal Location"
            stepNumber="1"
            helpText={
              <p>
                COMET-Farm requires users to tie their animal ag management
                details to a specific location via a selected state and county.
                This information will only be used to determine climate zones
                for manure and housing related calculations. Using the dropdown
                menus, select the state and county of the general area of the
                operation being assessed. Click Save to verify and save this
                location. This selected location will be applied to all animal
                categories within a project. To assess an operation in another
                state/county, create a new project.
              </p>
            }
          />
          <CFBox
            component="form"
            onSubmit={submitAnagCounty}
            autoComplete="off"
            sx={{
              marginLeft: "1em",
              marginTop: "1em",
            }}
          >
            <Grid
              container
              flexDirection="row"
              alignItems="start"
              item
              xs={12}
              sm={12}
              md={12}
            >
              <Grid
                item
                xs={12}
                backgroundColor={colors.background.header}
                boxShadow="0px 1px 3px 0px #bbb"
                borderRadius=".3em"
                marginLeft="1em"
                marginRight="2em"
                marginBottom=".5em"
              >
                <CFBox
                  width="100%"
                  style={{
                    display: "flex",
                    marginTop: "1em",
                    marginBottom: "1em",
                    marginLeft: "2em",
                  }}
                >
                  <CFTypography
                    sx={{
                      fontSize: "16px",
                      width: "9em",
                      color: info.dullFocus,
                    }}
                  >
                    Select State:
                  </CFTypography>
                  <CFLookupPicker
                    key="anState"
                    name="anState"
                    required
                    style={{ width: "15em" }}
                    value={stateVal}
                    onChange={(event, newValue) => {
                      setStateValId(newValue.id);

                      setStateVal({
                        name: newValue.name,
                        id: newValue.id,
                      });
                    }}
                    options={stateList}
                  />
                </CFBox>
              </Grid>
              <Grid
                item
                xs={12}
                backgroundColor={colors.background.header}
                boxShadow="0px 1px 3px 0px #bbb"
                borderRadius=".3em"
                marginLeft="1em"
                marginRight="2em"
              >
                <CFBox
                  style={{
                    display: "flex",
                    marginTop: "1em",
                    marginBottom: "1em",
                    marginLeft: "2em",
                  }}
                >
                  <CFTypography
                    sx={{
                      fontSize: "16px",
                      width: "9em",
                      color: info.dullFocus,
                    }}
                  >
                    Select County:
                  </CFTypography>
                  <CFLookupPicker
                    key="anCounty"
                    name="anCounty"
                    required
                    style={{ width: "15em" }}
                    value={countyVal}
                    onChange={(event, newValue) => {
                      setCountyValId(newValue.id);

                      setCountyVal({
                        name: newValue.name,
                        id: newValue.id,
                      });
                    }}
                    options={countyList}
                  />
                </CFBox>
              </Grid>
              <Grid container py={2} px={2} justifyContent="space-around">
                <Grid item xs={12} sm={12} md={12}>
                  <CFButton
                    size="large"
                    color="progress"
                    variant="gradient"
                    type="submit"
                    sx={{ float: "right", textTransform: "none" }}
                  >
                    Save
                  </CFButton>
                </Grid>
              </Grid>
            </Grid>
          </CFBox>
          <CFSnackbar
            icon="saveSuccess"
            open={snackbarOpen}
            close={() => setSnackbarOpen(false)}
            title="State and County Updated"
          />
          {localPopupState && localPopupState.showPopup && (
            <CFDialog
              {...localPopupState}
              updatePopup={(e) => updateLocalPopup(e)}
            />
          )}
        </CFBox>
      )}
    </CFBox>
  );
}

export default AnimalZipCode;
