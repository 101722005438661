import CFBox from "components/CFBox";

export const GFSTRHelpText = (
  <CFBox sx={{ padding: "2em", overflowY: "scroll", maxHeight: "600px" }}>
    <p>
      This separates if you are harvesting the whole plant or just part of it
      (e.g. the top of the corn stalk vs the whole plant). In each case,
      checking this box will mean COMET Farm and Daycent will assume a default
      value for “Dry Matter Removal” (how much of the above ground biomass you
      removed at harvest).
    </p>
    <ul style={{ paddingLeft: "1em" }}>
      <li>
        For <strong>grains</strong>, checking this box means you only harvested
        the grain and not the full plant. There is a default assumed value for
        “dry matter removal” for each crop when this is checked and you don’t
        need to enter a value (e.g. 50-60% of a corn crop is assumed removed
        when only the grain is harvested).{" "}
      </li>
      <li>
        With <strong>barley or oats</strong>, they frequently grow it as a hay
        crop and will grow it really high and then harvest the whole plant. They
        won’t remove just the grain. In this case, you would not check this box
        and instead would enter that 100% of the dry matter was removed (e.g.
        100% of the above ground biomass was removed).{" "}
      </li>
      <li>
        For <strong>fruits</strong>, checking this box means you only harvested
        the fruit and not the whole plant. Again, there is a default assumed
        value for “dry matter removal” for each crop when this is checked and
        you do not need to enter a value (for most fruits, it is assumed that no
        dry matter is removed as the plant is left to grow fruit in the future).
      </li>
      <li>
        For <strong>root crops</strong> (potatoes, sugar beets, carrots, etc),
        checking this box means you harvested the tubers (roots) of the plant.
        The tuber is almost always the edible part of the plant. When roots are
        harvested (e.g. potatoes, carrots, sugar beets, etc.) usually none of
        the other aboveground biomass is harvested. There are some exceptions,
        however we generally assume 0% of the residual biomass is harvested and
        let the user change that if needed. Checking this box assumes 0% of the
        biomass was removed at harvest.
      </li>
    </ul>
  </CFBox>
);

export const Other = "";
