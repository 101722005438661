import { Checkbox, Grid } from "@mui/material";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";
import { pink, red, grey } from "@mui/material/colors";
import { useEffect } from "react";
import {
  ArrowCircleDownTwoTone,
  ArrowCircleRightTwoTone,
  Check,
} from "@mui/icons-material";

export default function CheckboxGrid({
  Data,
  selectedCropYear,
  selectedField,
  checkedCells,
  setCheckedCells,
  ...rest
}) {
  const columns = [];

  const addColumnArrow = (cell) => {
    const { headerName } = cell.colDef;
    // console.log("here is the cell formented value", cell, headerName);
    return (
      <Grid container item sx={{ cursor: "pointer" }}>
        <Grid item xs={5}>
          {headerName}
        </Grid>
        <Grid item xs={7}>
          <ArrowCircleDownTwoTone
            color="primary"
            fontSize="medium"
            style={{
              marginTop: ".6em",
              marginLeft: ".5em",

              // marginLeft: "2%",
              // marginTop: "1%",
            }}
          />
        </Grid>
      </Grid>
    );
  };
  const addArrow = (cell) => {
    const { formattedValue } = cell;
    //  console.log("here is the cell formented value", formattedValue);
    return (
      <Grid container item sx={{ cursor: "pointer" }}>
        <Grid item xs={10}>
          {formattedValue}
        </Grid>
        <Grid item xs={2}>
          <ArrowCircleRightTwoTone
            color="primary"
            fontSize="medium"
            style={
              {
                // marginLeft: "2%",
                // marginTop: "1%",
              }
            }
          />
        </Grid>
      </Grid>
    );
  };

  const clickFieldName = (cell) => {
    const { id, field } = cell;
    if (field !== "LocationName") {
      return;
    }

    const NewCheckedCells = { ...checkedCells };

    let checked = true;
    if (
      NewCheckedCells[id] &&
      NewCheckedCells[id][columns[1].field] &&
      NewCheckedCells[id][columns[2].field]
    ) {
      checked = false;
    }

    for (let i = 1; i < columns.length; i += 1) {
      const year = columns[i].field;

      if (!NewCheckedCells[id]) {
        NewCheckedCells[id] = [];
      }
      NewCheckedCells[id][year] = checked;
    }
    setCheckedCells(NewCheckedCells);
  };

  columns.push({
    field: "LocationName",
    headerName: "Field Location",
    width: 250,

    editable: false,
    disableReorder: true,
    sortable: false,
    renderCell: addArrow,
  });

  const ClickCoumn = (params, event, details) => {
    const { field } = params;
    const fieldIds = [...Data].map((a) => a.LocationId);

    const NewCheckedCells = { ...checkedCells };
    let checked = true;

    if (NewCheckedCells[fieldIds[0]] && NewCheckedCells[fieldIds[0]][field]) {
      checked = false;
    }

    for (let i = 0; i < fieldIds.length; i += 1) {
      const LocationId = fieldIds[i];

      if (!NewCheckedCells[LocationId]) {
        NewCheckedCells[LocationId] = [];
      }
      NewCheckedCells[LocationId][field] = checked;
    }
    setCheckedCells(NewCheckedCells);
  };

  const { palette } = createTheme();
  const theme = createTheme({
    palette: {
      hasData: palette.augmentColor({ color: pink }),
    },
  });

  const rows = [...Data];

  const firstRow = Data[Object.keys(Data)[0]];

  const celllChecked = (checked, cell) => {
    const { LocationId } = cell.row;

    const { field } = cell;
    const NewCheckedCells = { ...checkedCells };
    if (!NewCheckedCells[LocationId]) {
      NewCheckedCells[LocationId] = [];
    }
    NewCheckedCells[LocationId][field] = checked;
    setCheckedCells(NewCheckedCells);
  };

  useEffect(() => {
    setCheckedCells({});
  }, [selectedCropYear, selectedField]);

  const renderCell = (cell) => {
    //  console.log("here is the cell", cell);
    const { formattedValue } = cell;
    //  console.log("here is the cell formented value", formattedValue);
    return (
      <ThemeProvider theme={theme}>
        {cell.row.LocationId === selectedField &&
          cell.field === `${selectedCropYear}HasData` && (
            <Check
              sx={
                formattedValue
                  ? {
                      color: grey[900],
                      "&.Mui-checked": {
                        color: grey[900],
                      },
                    }
                  : {}
              }
              checked
            />
          )}
        {(cell.row.LocationId !== selectedField ||
          cell.field !== `${selectedCropYear}HasData`) && (
          <Checkbox
            sx={
              formattedValue
                ? {
                    color: red[800],
                    "&.Mui-checked": {
                      color: red[600],
                    },
                  }
                : {}
            }
            checked={
              checkedCells[cell.row.LocationId]
                ? checkedCells[cell.row.LocationId][cell.field] === true
                : false
            }
            onChange={(e) => {
              celllChecked(e.target.checked, cell);
            }}
          />
        )}
      </ThemeProvider>
    );
  };

  for (const key in firstRow) {
    if (Object.prototype.hasOwnProperty.call(firstRow, key)) {
      if (key.endsWith("HasData")) {
        columns.push({
          field: key,
          headerName: key.replace("HasData", ""),
          width: 80,
          editable: false,
          disableReorder: true,
          sortable: false,
          filterable: false,
          groupable: false,
          hidable: false,
          pinnable: false,
          aggregable: false,
          renderCell,
          renderHeader: addColumnArrow,
        });
      }
    }
  }

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      hideFooter
      /*  initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }} */
      onColumnHeaderClick={ClickCoumn}
      onCellClick={clickFieldName}
      // pageSizeOptions={[5]}
      autosizeOnMount
      disableRowSelectionOnClick
      disableColumnSelector
      disableColumnMenu
      disableColumnFilter
    />
  );
}
