/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

/* Openlayers */
import BingMaps from "ol/source/BingMaps";
import Map from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import { Attribution, defaults as defaultControls } from "ol/control";
import "ol/ol.css";

import { updateLoading } from "store/reducers/mask";
import { post } from "utils/api";
import { LOCATION_FETCH } from "constants/api";
import { convertCometFeatures, getMapStyle } from "utils/generic";
import CFButton from "components/CFButton";
import CFBox from "components/CFBox";
import "../CFMap.css";

const attribution = new Attribution({
  collapsible: false,
});

function LinkButton(props) {
  // eslint-disable-next-line react/prop-types
  const { label, to, sx } = props;
  return (
    <CFButton
      to={to}
      sx={{
        ...{
          fontSize: "1em",
          textTransform: "none",
        },
        ...sx,
      }}
      component={Link}
    >
      {label}
    </CFButton>
  );
}

function CFMiniMap({
  selectedParcel,
  currentUser,
  activityTypeId,
  locationMapFeatureHolder,
}) {
  const dispatch = useDispatch();
  const [mainMap, setMainMap] = useState();
  const [features, setFeatures] = useState([]);
  const mapRef = useRef();
  const [featureSource, setFeatureSource] = useState();
  const [locationHolder, setLocationHolder] = useState({
    userId: "",
    projectId: "",
    featureCollection: {},
    activityTypeId: 10,
  });
  const mapClass =
    activityTypeId === 10
      ? "comet-mini-map-croplands"
      : "comet-mini-map-forestry";
  const setMapCopyright = () => {
    const r = document.querySelector(":root");
    r.style.setProperty("--display", "none");
  };

  const fetchlocationHolder = () => {
    dispatch(updateLoading({ loading: true }));

    post(LOCATION_FETCH, {
      userId: currentUser.id,
      activityTypeId,
    })
      .then((res) => {
        if (res.error) {
          console.log("Error obj ", res.error);
          dispatch(updateLoading({ loading: false }));
        } else {
          const locationHolderObj = res.data;
          setLocationHolder({
            userId: locationHolderObj.userId,
            projectId: locationHolderObj.projectId,
            featureCollection: locationHolderObj.featureCollection,
            activityTypeId: locationHolderObj.activityTypeId,
          });
        }
        dispatch(updateLoading({ loading: false }));
      })
      .catch((err) => {
        dispatch(updateLoading({ loading: false }));
      });
  };
  /* Find and view selected feature */
  const updateView = (updatedFeaturesIn) => {
    const updatedFeatures = updatedFeaturesIn;
    if (!((selectedParcel && selectedParcel.id) || selectedParcel.LocationId)) {
      return;
    }
    let parcelId;
    let geometryType;

    if (activityTypeId === 14) {
      parcelId = selectedParcel.LocationId.toString();
    } else {
      parcelId = selectedParcel.id.toString();
    }
    for (let i = 0; i < updatedFeatures.length; i += 1) {
      if (updatedFeatures[i].values_.id === parcelId) {
        updatedFeatures[i].values_.selected = true;
        const featureExtent = updatedFeatures[i].getGeometry().getExtent();
        geometryType = updatedFeatures[i].getGeometry().getType();
        mainMap.getView().fit(featureExtent);
      } else {
        updatedFeatures[i].values_.selected = false;
      }
    }
    if (featureSource) {
      // need to clear for openlayers to register feature changes
      featureSource.clear();
      featureSource.addFeatures(updatedFeatures);
    }

    if (geometryType === "Point") mainMap.getView().setZoom(17);
    else if (geometryType === "Polygon")
      mainMap.getView().setZoom(mainMap.getView().getZoom() - 1);
  };

  useEffect(() => {
    setLocationHolder({ ...locationMapFeatureHolder });
  }, [locationMapFeatureHolder]);

  useEffect(() => {
    // when it comes from forestry for the moment
    if (!locationMapFeatureHolder) {
      fetchlocationHolder();
    }
    const fSource = new VectorSource({ wrapX: false });
    const featureLayer = new VectorLayer({
      source: fSource,
    });
    setFeatureSource(fSource);
    const baseMap = new Map({
      layers: [
        new TileLayer({
          visible: true,
          preload: Infinity,
          source: new BingMaps({
            key: "AipFgxAUWr-IAOTpXwf-Kac7KIdHCMD-9is8dpE8fnb690-CZAjGGGQumVN89a-E",
            imagerySet: "AerialWithLabelsOnDemand",
            placeholderTiles: false,
          }),
        }),
        featureLayer,
      ],
      controls: defaultControls({ attribution: false }).extend([attribution]),
      view: new View({
        center: [-10713413.88445, 4931105.568733],
        zoom: 13,
      }),
    });

    baseMap.setTarget(mapRef.current);
    featureLayer.setStyle(getMapStyle());
    setMainMap(baseMap);

    return () => baseMap.setTarget(undefined);
  }, []);

  useEffect(async () => {
    if (mainMap && featureSource) {
      const convertedFeatures = convertCometFeatures(locationHolder);
      if (convertedFeatures && convertedFeatures.length > 0) {
        setFeatures(convertedFeatures);
        featureSource.addFeatures(convertedFeatures);
        const featureExtent = featureSource.getExtent();
        mainMap.getView().fit(featureExtent, mainMap.getSize());

        if (convertedFeatures.length === 1) {
          const geometryType = convertedFeatures[0].getGeometry().getType();
          if (geometryType === "Point") mainMap.getView().setZoom(17);
          else if (geometryType === "Polygon")
            mainMap.getView().setZoom(mainMap.getView().getZoom() - 1);
        }
      }
      // As long as a real parcel is selected todo:check agroforestry
      if (selectedParcel && selectedParcel.id !== 0)
        updateView(convertedFeatures);
      setMapCopyright();
    }
  }, [locationHolder]);
  /* eslint no-underscore-dangle: "OFF" */
  useEffect(() => {
    updateView(features);
  }, [selectedParcel]);
  return (
    <CFBox>
      <div ref={mapRef} className={mapClass} />
    </CFBox>
  );
}
export default CFMiniMap;
