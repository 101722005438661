/**
 * The base colors for the COMET-Farm React.
 * You can add new color using this file.
 * You can customized the colors for the entire COMET-Farm React using thie file.
 */

export default {
  background: {
    default: "#f0f2f5",
    header: "#eee",
  },

  text: {
    main: "#7b809a",
    focus: "#7b809a",
    light: "#fff",
  },

  transparent: {
    main: "transparent",
  },

  white: {
    main: "#fafafa",
    focus: "#fafafa",
  },

  black: {
    light: "#000000",
    main: "#000000",
    focus: "#000000",
  },

  slate: {
    main: "#2c303c",
    focus: "#2a2c37",
    50: "#2c3c5880",
    25: "#c3cbd4",
  },
  // headers
  primary: {
    main: "#105456",
    75: "#105456c0",
    50: "#10545680",
    25: "#10545640",
    5: "#1054560a",
    focus: "#105456c0",
  },
  // back buttons/cancels
  secondary: {
    main: "#59595B",
    focus: "#59595B7f",
  },

  tertiary: {
    main: "#63421f",
    focus: "#ce7426",
  },

  // subheaders and highlights
  highlight: {
    main: "#008FB3",
    dark: "#006882",
    focus: "#008FB3B7",
  },

  // next/save/progress
  progress: {
    main: "#ffc038",
    focus: "#ffc0387f",
    dark: "#d39f2c",
  },

  // text and such
  info: {
    main: "#2f3324",
    focus: "#42c416",
    dullFocus: "#2f3324",
    dullFocusTransparent: "#2f332445",
    dullOff: "#8f8e7f",
  },

  success: {
    main: "#4CAF50",
    focus: "#67bb6a",
  },

  warning: {
    main: "#fb8c00",
    focus: "#fc9d26",
  },

  error: {
    main: "#F44335",
    focus: "#f65f53",
  },

  light: {
    main: "#f0f2f5",
    focus: "#f8f9fa",
  },

  dark: {
    main: "#2f3324",
    focus: "#2c3c58",
  },

  svgAdjusted: {
    main: "#0a0b08",
    focus: "#0a0b087F",
  },

  grey: {
    100: "#f8f9fa",
    200: "#f0f2f5",
    300: "#7d8393",
    400: "#ced4da",
    500: "#adb5bd",
    600: "#6c757d",
    700: "#495057",
    800: "#343a40",
    900: "#212529",
  },

  gradients: {
    primary: {
      main: "#105456",
      state: "#105456fe",
    },

    secondary: {
      main: "#59595B",
      state: "#808095",
    },

    progress: {
      main: "#ffc038",
      state: "#d39f2c",
    },

    highlight: {
      main: "#008FB3",
      state: "#028bad",
    },

    info: {
      main: "#b48b4b",
      state: "#d8891b",
    },

    success: {
      main: "#66BB6A",
      state: "#43A047",
    },

    warning: {
      main: "#FFA726",
      state: "#FB8C00",
    },

    error: {
      main: "#EF5350",
      state: "#E53935",
    },

    light: {
      main: "#EBEFF4",
      state: "#CED4DA",
    },

    dark: {
      main: "#42424a",
      state: "#191919",
    },
  },

  socialMediaColors: {
    white: {
      main: "#ffffff",
      focus: "#ffffff",
    },
    facebook: {
      main: "#3b5998",
      dark: "#344e86",
    },

    twitter: {
      main: "#55acee",
      dark: "#3ea1ec",
    },

    instagram: {
      main: "#125688",
      dark: "#0e456d",
    },

    linkedin: {
      main: "#0077b5",
      dark: "#00669c",
    },

    pinterest: {
      main: "#cc2127",
      dark: "#b21d22",
    },

    youtube: {
      main: "#e52d27",
      dark: "#d41f1a",
    },

    vimeo: {
      main: "#1ab7ea",
      dark: "#13a3d2",
    },

    slack: {
      main: "#3aaf85",
      dark: "#329874",
    },

    dribbble: {
      main: "#ea4c89",
      dark: "#e73177",
    },

    github: {
      main: "#24292e",
      dark: "#171a1d",
    },

    reddit: {
      main: "#ff4500",
      dark: "#e03d00",
    },

    tumblr: {
      main: "#35465c",
      dark: "#2a3749",
    },
  },

  badgeColors: {
    primary: {
      background: "#ab8e7b",
      text: "#7d3705",
    },

    secondary: {
      background: "#d7d9e1",
      text: "#6c757d",
    },

    progress: {
      main: "#ffc038",
      focus: "#42c416",
    },

    info: {
      background: "#c9c97f",
      text: "#90c609",
    },

    success: {
      background: "#bce2be",
      text: "#339537",
    },

    warning: {
      background: "#ffd59f",
      text: "#c87000",
    },

    error: {
      background: "#fcd3d0",
      text: "#f61200",
    },

    light: {
      background: "#ffffff",
      text: "#c7d3de",
    },

    dark: {
      background: "#8097bf",
      text: "#1e2e4a",
    },
  },

  coloredShadows: {
    primary: "#945712",
    secondary: "#110e0e",
    progress: "#110e0e",
    highlight: "#fff",
    info: "#4f605b",
    success: "#4caf4f",
    warning: "#ff9900",
    error: "#f44336",
    light: "#adb5bd",
    dark: "#404040",
  },

  mapColors: {
    complete: {
      fill: "#477d14BF",
      border: "#fff",
      text: "#000",
      textBorder: "#fff",
    },
    selectedComplete: {
      fill: "#477d14BF",
      border: "#dfcd17",
      text: "#000",
      textBorder: "#fff",
    },
    incomplete: {
      fill: "#267988BF", // "rgba(178, 222, 39, 0.6)",
      border: "#fff",
      text: "#000",
      textBorder: "#fff",
    },
    selectedIncomplete: {
      fill: "#267988BF",
      border: "#dfcd17",
      text: "#000",
      textBorder: "#fff",
    },

    invalidField: {
      fill: "#88001b", // "rgba(178, 222, 39, 0.6)",
      border: "#fff",
      text: "#000",
      textBorder: "#fff",
    },
    invalidFieldSelected: {
      fill: "#88001b",
      border: "#dfcd17",
      text: "#000",
      textBorder: "#fff",
    },
  },

  graphColors: {
    croplands: {
      carbon: "#1f77b4",
      co2: "#ff7f0e",
      co: "#2ca02c",
      n2oDirect: "#d62728",
      n2oIndirect: "#9467bd",
      ch4: "#8c564b",
    },

    animalAg: {
      methane: "#8c564b",
      nitrousOxide: "#d62728",
    },

    agroforestry: {
      speciesList: [
        "#1b9e77",
        "#d95f02",
        "#7570b3",
        "#e7298a",
        "#66a61e",
        "#e6ab02",
        "#a6761d",
        "#666666",
      ],
      liveTrees: "#66a61e",
      downedDead: "#666666",
      forestFloor: "#1b9e77",
      standingDead: "#a6761d",
      understory: "#7570b3",
    },

    forestry: {
      totalStand: "#437f29",
      harvested: "#bf9000",
    },
  },

  inputBorderColor: "#d2d6da",

  tabs: {
    indicator: { boxShadow: "#ddd" },
  },
};
