import CFBox from "components/CFBox";

export default function RecapchaDisclosure() {
  return (
    <CFBox sx={{ fontSize: "x-small", opacity: ".7", width: "max-content" }}>
      This site is protected by reCAPTCHA and the Google{" "}
      <a href="https://policies.google.com/privacy">Privacy Policy</a> and{" "}
      <a href="https://policies.google.com/terms">Terms of Service</a> apply.
    </CFBox>
  );
}
