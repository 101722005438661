// eslint-disable-next-line import/prefer-default-export, consistent-return
export const takeIfExists = (key, type = String) => {
  const item = localStorage.getItem(key);
  if (item) {
    // eslint-disable-next-line no-nested-ternary
    return type === Number
      ? Number.parseFloat(item)
      : type === Object
      ? JSON.parse(item)
      : item;
  }
};
