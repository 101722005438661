/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import CFRoadmap from "components/CFarmParts/CFarmPage/CFRoadmap/Roadmap";
import CFarmPage from "components/CFarmParts/CFarmPage";
import CFStepMarker from "components/CFStepMarker/CFStepMarker";
import { FETCH_ANIMAL_OPERATION, FETCH_ACTIVITY_PROGRESS } from "constants/api";
import { post } from "utils/api";
import { updateLoading } from "store/reducers/mask";
import colors from "assets/theme/base/colors";
import CFDialog from "components/CFDialog";
import CFButton from "components/CFButton";
import CFLink from "components/CFLink/CFLink";
import CFTypography from "components/CFTypography";
import { Grid, Link as ExternalLink } from "@mui/material";

import AnimalZipCode from "./AnimalZipCode";
import AnimalTypes from "./AnimalTypes";
import AnimalDetails from "./AnimalDetails";
import ScenarioSideBar from "./ScenarioSideBar";
import AnimalFooter from "./AnimalFooter";
import NewScenario from "./NewScenario";
import ManureFollowup from "./ManureFollowup";

function GridSection({ children, xs = 8, marginBlock, backgroundColor }) {
  return (
    <Grid
      container
      item
      xs={xs}
      border="thin solid #ddd"
      borderRadius=".3em"
      marginBottom=".5em"
      paddingBlockStart=".75em"
      paddingInline=".75em"
      marginBlock={marginBlock}
      backgroundColor={backgroundColor}
    >
      {children}
    </Grid>
  );
}

function AnimalAg() {
  const dispatch = useDispatch();
  const selScenarioId = 212450;
  const currentUser = useSelector((state) => state.user);
  const [animalObjectChange, setAnimalObjectChange] = useState(false);
  const [scenarioChange, setScenarioChange] = useState("");
  const [copyScenarioIdChange, setCopyScenarioIdChange] = useState("");
  const [addNewScenario, setAddNewScenario] = useState(false);
  const [animalDetailsEdited, setAnimalDetailsEdited] = useState("");
  const [localPopupState, updateLocalPopup] = useState({});
  const [anagOperation, setAnagOperation] = useState({
    id: "",
    zipcode: "",
    stateId: "",
    countyId: "",
    userId: "",
    projectId: "",
    unitsLookUpId: "",
    activeAnimalCategories: "",
    selectedScenario: "",
    selectedScenarioId: "",
    scenarioLength: "",
    allActiveCategories: "",
    activeCategoriesLength: "",
  });

  const handleAnimalObjectChanged = (flag) => {
    if (flag) {
      // make sure it's changed
      setAnimalObjectChange(!animalObjectChange);
    } else {
      setAnimalObjectChange(flag);
    }
  };

  const handleScenarioObjectChanged = (name) => {
    setScenarioChange(name);
  };
  const handleNewScenarioAdded = (flag, id) => {
    setAddNewScenario(flag);
    setCopyScenarioIdChange(id);
  };

  const fetchAnimalOperation = () => {
    const animalOpObj = {
      scname: scenarioChange,
      id: currentUser,
      projectId: currentUser.activeProject.id,
    };
    post(FETCH_ANIMAL_OPERATION, animalOpObj)
      .then((res) => {
        if (res.error) {
          console.log("fetch op", res);
          updateLocalPopup({
            message: res.error,
            title: "Error",
            showPopup: true,
            closeAction: () => {
              updateLocalPopup({
                showPopup: false,
              });
            },
          });
          dispatch(updateLoading({ loading: false }));
        } else {
          try {
            const animalObject = JSON.parse(res.data);

            setAnagOperation({
              id: animalObject.Id,
              stateId: animalObject.StateId,
              countyId: animalObject.CountyId,
              userId: animalObject.UserId,
              projectId: animalObject.ProjectId,
              unitsLookUpId: animalObject.UnitsLookUpId,
              activeAnimalCategories: animalObject.ActiveCategories,
              selectedScenario: animalObject.SelectedScenario,
              selectedScenarioId: animalObject.SelectedScenarioId,
              scenarioLength: animalObject.AllScenarios,
              allActiveCategories: animalObject.AllActiveCategories,
              activeCategoriesLength: animalObject.ActiveCategoriesCount,
            });
          } catch (e) {
            updateLocalPopup({
              message: e,
              title: "Error",
              showPopup: true,
              closeAction: () => {
                updateLocalPopup({
                  showPopup: false,
                });
              },
            });
          }
        }
      })
      .catch((err) => {
        updateLocalPopup({
          message:
            "Animal Agriculture Operation does not Exist. Please select Animal Agriculture module in the Choose Activities page",
          title: "Error",
          showPopup: true,

          closable: false,
          button1: {
            text: "Navigate to Choose Activities",

            href: "/projects",
            color: "primary",
          },
        });
      });
  };
  const fetchUserActivities = () => {
    const animalOpObj = {
      id: currentUser,
      projectId: currentUser.activeProject.id,
    };
    post(FETCH_ACTIVITY_PROGRESS, animalOpObj)
      .then((res) => {
        if (res.error) {
          updateLocalPopup({
            message: res.error,
            title: "Error",
            showPopup: true,
            closeAction: () => {
              updateLocalPopup({
                showPopup: false,
              });
            },
          });
          dispatch(updateLoading({ loading: false }));
        } else {
          try {
            const animalObject = JSON.parse(res.data);
            console.log("animalactivityprogress", animalObject);
            setAnimalDetailsEdited(animalObject);
          } catch (e) {
            updateLocalPopup({
              message: e,
              title: "Error",
              showPopup: true,
              closeAction: () => {
                updateLocalPopup({
                  showPopup: false,
                });
              },
            });
          }
        }
      })
      .catch((err) => {
        updateLocalPopup({
          message:
            "Animal Agriculture Operation does not Exist. Please select Animal Agriculture module in the Choose Activities page",
          title: "Error",
          showPopup: true,

          closable: false,
          button1: {
            text: "Navigate to Choose Activities",

            href: "/projects",
            color: "primary",
          },
        });
      });
  };
  const handleAnimalDetailsEdited = (flag) => {
    fetchUserActivities();
  };
  useEffect(() => {
    fetchAnimalOperation();
  }, [animalObjectChange]);
  useEffect(() => {
    // fetch progress count of user activities to Hide and show reports button
    fetchUserActivities();
  }, []);

  // Demo info popup
  const [demoPopupShown, setDemoPopupShown] = useState(false);
  const [scenarioDemoPopupShown, setShownScenarioDemoPopup] = useState(false);
  useEffect(() => {
    if (currentUser?.activeProject?.name.includes("Demo")) {
      // Beef Demo Popup
      if (
        anagOperation?.activeAnimalCategories.includes(21255) &&
        !demoPopupShown
      ) {
        setDemoPopupShown(true);
        updateLocalPopup({
          title: "Animal Agriculture Beef Demo Project",
          style: { minWidth: "750px", maxHeight: "75vh", overflow: "auto" },
          children: [
            <Grid container direction="column" alignItems="center" p={2}>
              <CFTypography variant="h4" py={1}>
                Animal Location
              </CFTypography>
              <p>
                The Property location for the demo project is located at{" "}
                <ExternalLink
                  href="https://caas.usu.edu/uaes/farms/south-farm"
                  target="_blank"
                  underline="hover"
                  rel="noopener noreferrer"
                  sx={{ color: "mediumblue" }}
                >
                  Utah State University&apos;s Animal Science Farm
                </ExternalLink>
                . The Farm is located south of the main campus located in Logan,
                Utah.
              </p>
              <br />
              <CFTypography variant="h4" py={1}>
                Animal Types
              </CFTypography>
              <p>
                The demo depicts a hypothetical feeding operation based on the
                Factsheet Grain Finishing Beef: Alternative Rations, Cattle
                Performance and Feeding Costs for Small Feeders from Utah State
                University. More information can be found here:{" "}
                <ExternalLink
                  href="https://digitalcommons.usu.edu/cgi/viewcontent.cgi?article=1802&context=extension_curall"
                  target="_blank"
                  underline="hover"
                  rel="noopener noreferrer"
                  sx={{ color: "mediumblue" }}
                >
                  Grain Finishing Beef
                </ExternalLink>
              </p>
              <br />
              <CFTypography variant="h4" py={1}>
                Baseline and Scenario Animal Management
              </CFTypography>
              <p>
                The herd is housed on dry lots. Manure is deposited within the
                pens and piled. It is removed and stored in long-term
                stockpiles. There are total of 500 head in the herd.
              </p>
              <br />
              <p>
                In the baseline example, the grazing stockers eat a low quality
                forage that has 80% forage content. The animals graze for 6
                months of the year until they reach their target weight.
              </p>
              <br />
              <p>
                After reviewing the animal management inputs, select USU 2 in
                the floating scenario gray box on the right hand of the screen.
                USU 2 is a scenario that describes an enhanced forage quality,
                to high quality, with the same forage content percentage. The
                better forage accelerates the stockers growth allowing them to
                reach target weight in 4 months.
              </p>
            </Grid>,
          ],
          showPopup: true,
          closeAction: () => {
            updateLocalPopup({ showPopup: false });
          },
        });
      }
      // Dairy Demo Popup
      else if (
        anagOperation?.activeAnimalCategories.includes(21260) &&
        !demoPopupShown &&
        (anagOperation.selectedScenario === "" ||
          anagOperation.selectedScenario === "Baseline" ||
          anagOperation.selectedScenario === null)
      ) {
        setDemoPopupShown(true);
        updateLocalPopup({
          title: "Animal Agriculture Dairy Demo Project",
          style: { minWidth: "750px", maxHeight: "75vh", overflow: "auto" },
          children: [
            <Grid container direction="column" alignItems="center" p={2}>
              <CFTypography variant="h4" py={1}>
                Animal Location
              </CFTypography>
              <p>
                The Cal Poly Dairy is located adjacent to the California
                Polytechnic State University campus in San Luis Obispo.
              </p>
              <br />
              <CFTypography variant="h4" py={1}>
                Animal Types
              </CFTypography>
              <p>
                The dairy milks 180 cows with a total population of over 350
                animals, including heifers and calves.
              </p>
              <br />
              <CFTypography variant="h4" py={1}>
                Baseline Animal Management
              </CFTypography>
              <p>
                Most of the herd is housed in free stall barns. About 90 percent
                of the manure is deposited on concrete and flushed with fresh or
                recycled water to a single-cell lagoon. The remaining ten
                percent is deposited in the corrals and collected only
                seasonally. Solids are separated from the flushed wastewater
                prior to storage in the lagoon, which has a volume of 19,000 m3,
                translating to 50 to 90 days of storage, depending upon the
                water used by the dairy.
              </p>
              <br />
              <p>
                After reviewing the animal management inputs, select Digester
                Install in the floating scenario gray box on the right hand of
                the screen.
              </p>
            </Grid>,
          ],
          showPopup: true,
          closeAction: () => {
            updateLocalPopup({ showPopup: false });
          },
        });
      } else if (
        anagOperation?.activeAnimalCategories.includes(21260) &&
        !scenarioDemoPopupShown &&
        !(
          anagOperation.selectedScenario === "" ||
          anagOperation.selectedScenario === "Baseline" ||
          anagOperation.selectedScenario === null
        )
      ) {
        setShownScenarioDemoPopup(true);
        updateLocalPopup({
          title: "Animal Agriculture Dairy Demo Project",
          style: {
            minWidth: "750px",
            maxHeight: "75vh",
            overflow: "auto",
          },
          children: [
            <Grid container direction="column" alignItems="center" p={2}>
              <CFTypography variant="h4" py={1}>
                Scenario Animal Management
              </CFTypography>
              <p>
                A methane recovery system has been constructed at the dairy. The
                initial design was based upon the present and anticipated herd
                size. The new lagoon, which has a liquid volume of 14,000 m3,
                was constructed next to the existing one. The first lagoon is
                being used for storage and the second for methane recovery. The
                new lagoon was covered with a flexible membrane incorporating
                buoyant material so that the cover floats on the surface, and a
                gas collection system was attached. The project will reduce
                odors, keep greenhouse gas out of the atmosphere, and cut down
                on water pollution through reduction of organic matter in the
                lagoon. Biogas that is captured from the anaerobic digester will
                be used to fuel a biogas-fired microturbine that can generate
                electricity that can be used on the dairy. The manure dropdown
                for each animal category has been changed to reflect the new
                anaerobic digester system.
              </p>
              <br />
              <p> When finished reviewing, proceed to the Report.</p>
            </Grid>,
          ],
          showPopup: true,
          closeAction: () => {
            updateLocalPopup({ showPopup: false });
          },
        });
      }
    }
  }, [currentUser.activeProject, anagOperation]);

  return (
    <CFarmPage bgImage="" pageName="">
      <CFRoadmap />
      <Grid
        container
        justifyContent="center"
        spacing={0.5}
        sx={{ marginTop: "1em", width: "1200px" }}
      >
        {!addNewScenario && (
          <Grid
            container
            justifyContent="center"
            item
            xs={7}
            xl={7}
            marginBottom=".5em"
          >
            <ScenarioSideBar
              anagOpObject={anagOperation}
              anagObjectChanged={handleAnimalObjectChanged}
              scenObjectChanged={handleScenarioObjectChanged}
            />
          </Grid>
        )}
        {(anagOperation.selectedScenario === "" ||
          anagOperation.selectedScenario === "Baseline" ||
          anagOperation.selectedScenario === null) &&
          !addNewScenario && (
            <>
              <GridSection>
                <AnimalZipCode
                  anagOpObject={anagOperation}
                  anagObjectChanged={handleAnimalObjectChanged}
                  currentUser={currentUser}
                />
              </GridSection>

              {anagOperation.countyId !== 0 && (
                <GridSection>
                  <AnimalTypes
                    anagOpObject={anagOperation}
                    anagObjectChanged={handleAnimalObjectChanged}
                    animalDetailsChanged={handleAnimalDetailsEdited}
                  />
                </GridSection>
              )}
            </>
          )}
        {addNewScenario && (
          <GridSection
            marginBlock="10em"
            xs={4.75}
            backgroundColor={colors.background.default}
          >
            <NewScenario
              anagOpObject={anagOperation}
              anagObjectChanged={handleAnimalObjectChanged}
              scenObjectChanged={handleScenarioObjectChanged}
              newScenarioSelected={handleNewScenarioAdded}
              copyScenario={copyScenarioIdChange}
            />
          </GridSection>
        )}
        {!addNewScenario && (
          <>
            {anagOperation.countyId !== 0 && (
              <GridSection>
                <AnimalDetails
                  anagOpObject={anagOperation}
                  anagObjectChanged={handleAnimalObjectChanged}
                  animalDetailsChanged={handleAnimalDetailsEdited}
                />
              </GridSection>
            )}
            {/* The ManureFollowup contains the GridSection so that it disappears when there is no ManureFollowup */}
            <ManureFollowup
              anagOpObject={anagOperation}
              anagObjectChanged={handleAnimalObjectChanged}
            />
          </>
        )}
        <Grid item xs={10} xl={10}>
          <AnimalFooter
            anagOpObject={anagOperation}
            anagObjectChanged={handleAnimalObjectChanged}
            newScenarioSelected={handleNewScenarioAdded}
            disableFooter={animalDetailsEdited !== 1}
          />
        </Grid>
      </Grid>
      {localPopupState && localPopupState.showPopup && (
        <CFDialog
          {...localPopupState}
          updatePopup={(e) => updateLocalPopup(e)}
        />
      )}
    </CFarmPage>
  );
}
export default AnimalAg;
