import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Card } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CFarmPage from "components/CFarmParts/CFarmPage";
import CFBox from "components/CFBox";
import CFButton from "components/CFButton";
import CFTypography from "components/CFTypography";
import { FORGOT_PASSWORD } from "constants/api";
import { updateLoading } from "store/reducers/mask";
import { popupError } from "utils/generic";
import CFInput from "components/CFInput";
import CFDialog from "components/CFDialog";
import CFLink from "components/CFLink/CFLink";
import styles from "../Login.module.css";

function ForgotPassword() {
  const navigate = useNavigate();
  const [localPopupState, updateLocalPopup] = useState({});
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    dispatch(updateLoading({ loading: true }));
    console.log("submit", e);
    e.preventDefault();
    // send the username and email to the backend register for newsletter function which will handle it

    fetch(FORGOT_PASSWORD, {
      method: "POST",
      body: JSON.stringify({
        email: e.target.emailInput.value,
      }),

      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          popupError(data.error, dispatch);
        }
        if (data.data) {
          updateLocalPopup({
            message: data.data,
            title: "Reset Password Request Submitted",
            showPopup: true,
            closeAction: () => {
              navigate("/home");
            },
          });
        }
      })
      .catch((err) => {
        popupError(err, dispatch);
        console.log(err);
      })
      .finally(() => {
        dispatch(updateLoading({ loading: false }));
      });
  };

  return (
    <CFarmPage>
      <CFBox className="cf-barn-bg">
        <Card
          component="form"
          method="post"
          autoComplete="off"
          onSubmit={handleSubmit}
          className={styles.loginCardWrapper}
        >
          <CFTypography variant="h2">Password Reset</CFTypography>
          <CFTypography variant="text">
            Request a password reset email
          </CFTypography>
          <CFInput
            required
            key="emailInput"
            name="emailInput"
            label="Email (Required)"
            placeholder="Email"
            type="email"
            fullWidth
          />
          <CFButton type="submit" label="Reset Password" fullWidth />
          <CFLink to="/login" label="Back to Login" />
        </Card>
        {localPopupState && localPopupState.showPopup && (
          <CFDialog
            {...localPopupState}
            updatePopup={(e) => updateLocalPopup(e)}
          />
        )}
      </CFBox>
    </CFarmPage>
  );
}
export default ForgotPassword;
