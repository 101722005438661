// React
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// MUI
import {
  Grid,
  Select,
  MenuItem,
  FormControlLabel,
  RadioGroup,
  Radio,
  TextField,
  Autocomplete,
} from "@mui/material";
import dayjs from "dayjs";
// CFarm components
import CFStep from "components/CFarmParts/CFStep";
import CFStepMarker from "components/CFStepMarker/CFStepMarker";
import CFNumberInput from "components/CFNumberInput/CFNumberInput";
import CFBox from "components/CFBox";
import CFButton from "components/CFButton";
import CFMiniMap from "pages/CFMap/CFMiniMap/CFMiniMap";

// Post
import {
  FORESTRY_FETCH_FOREST_TYPES,
  FORESTRY_UPDATE_DETAILS,
  FORESTRY_VALIDATE,
} from "constants/api";
import { updateLoading } from "store/reducers/mask";
import { popupError } from "utils/generic";
import { post } from "utils/api";

// Forestry
import ForestryInput from "./ForestryInput";
import CopyManagement from "./CopyManagement";

// Note: "managementTitle" prop is intended for use if Forestry is extended to include future scenarios
function StandManagement({ managementTitle, forestryOp, projectChanged }) {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user);

  // List of forest types available for selected stand
  const [forestTypes, setForestTypes] = useState([]);
  const FetchForestTypes = (locationId) => {
    dispatch(updateLoading({ loading: true }));

    post(FORESTRY_FETCH_FOREST_TYPES, {
      UserId: currentUser.id,
      LocationId: locationId,
    })
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          dispatch(updateLoading({ loading: false }));
        } else {
          const ForestTypes = JSON.parse(res.data.value);
          setForestTypes(ForestTypes.Value);
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(updateLoading({ loading: false }));
      })
      .finally(() => {
        dispatch(updateLoading({ loading: false }));
      });
  };

  // Management info for the selected stand. Updated when the project object is changed
  const [standDetails, setStandDetails] = useState(() => {
    if (forestryOp.OperationStands.length > 0) {
      // Default to first stand
      return {
        ...forestryOp.OperationStands[0].LocationDetails,
        LocationId: forestryOp.OperationStands[0].Id,
        Name: forestryOp.OperationStands[0].StandName,
        Size: forestryOp.OperationStands[0].Area,
        ActivityYears: forestryOp.OperationStands[0].LocationDetails
          .ActivityYears
          ? forestryOp.OperationStands[0].LocationDetails.ActivityYears.split(
              ", "
            )
          : [],
      };
    }

    return {
      LocationId: 0,
      Name: null,
      ForestType: { Id: 770, Name: null },
      Origin: { Id: 212450, Name: null },
      StandAge: 0,
      Volume: 0,
      MerchVolume: 0,
      Size: 0,
      Prescription: { Id: 212450, Name: null },
      ActivityYears: [],
    };
  });
  // Update local state when project state changes
  useEffect(() => {
    if (forestryOp.OperationStands.length > 0) {
      // If a stand hasn't already been selected, set to first stand in array
      if (!standDetails.Name && standDetails.LocationId === 0) {
        FetchForestTypes(forestryOp.OperationStands[0].Id);

        setStandDetails({
          ...forestryOp.OperationStands[0].LocationDetails,
          LocationId: forestryOp.OperationStands[0].Id,
          Name: forestryOp.OperationStands[0].StandName,
          Size: forestryOp.OperationStands[0].Area,
          ActivityYears: forestryOp.OperationStands[0].LocationDetails
            .ActivityYears
            ? forestryOp.OperationStands[0].LocationDetails.ActivityYears.split(
                ", "
              )
            : [],
        });
      } else {
        // Otherwise, find operation matching selected stand
        forestryOp.OperationStands.forEach((opStand) => {
          if (opStand.Id === standDetails.LocationId) {
            FetchForestTypes(opStand.Id);

            setStandDetails({
              ...opStand.LocationDetails,
              LocationId: opStand.Id,
              Name: opStand.StandName,
              Size: opStand.Area,
              ActivityYears: opStand.LocationDetails.ActivityYears
                ? opStand.LocationDetails.ActivityYears.split(", ")
                : [],
            });
          }
        });
      }
    }
  }, [forestryOp]);

  // List of potential management years
  const [managementYears, setManagementYears] = useState(() => {
    const tempYears = [];

    for (let i = 0; i < 51; i += 1) {
      const newYear = dayjs().add(i, "year").year().toString();
      tempYears.push(newYear);
    }

    return tempYears;
  });

  // Entry type and error messages
  const [typeOfEntry, setTypeOfEntry] = useState(() => {
    if (standDetails.StandAge !== 0) return "Age";
    if (standDetails.Volume !== 0) return "Total Volume";
    if (standDetails.MerchVolume !== 0) return "Merchantable Volume";
    return "placeholder";
  });
  const [entryValue, setEntryValue] = useState(() => {
    if (standDetails.StandAge !== 0) return standDetails.StandAge;
    if (standDetails.Volume !== 0) return standDetails.Volume;
    if (standDetails.MerchVolume !== 0) return standDetails.MerchVolume;
    return 0;
  });
  const [errorMessage, setError] = useState("");
  useEffect(() => {
    if (
      standDetails.StandAge !== 0 ||
      (standDetails.StandAge === 0 &&
        standDetails.Volume === 0 &&
        standDetails.MerchVolume === 0 &&
        typeOfEntry === "Age")
    ) {
      setTypeOfEntry("Age");
      setEntryValue(standDetails.StandAge);
    } else if (
      standDetails.Volume !== 0 ||
      (standDetails.StandAge === 0 &&
        standDetails.Volume === 0 &&
        standDetails.MerchVolume === 0 &&
        typeOfEntry === "Total Volume")
    ) {
      setTypeOfEntry("Total Volume");
      setEntryValue(standDetails.Volume);
    } else if (
      standDetails.MerchVolume !== 0 ||
      (standDetails.StandAge === 0 &&
        standDetails.Volume === 0 &&
        standDetails.MerchVolume === 0 &&
        typeOfEntry === "Merchantable Volume")
    ) {
      setTypeOfEntry("Merchantable Volume");
      setEntryValue(standDetails.MerchVolume);
    } else {
      setTypeOfEntry("placeholder");
      setEntryValue(0);
    }
  }, [standDetails]);

  // Update selected stand
  const HandleStandChange = (e) => {
    if (forestryOp.OperationStands.length > 0) {
      forestryOp.OperationStands.forEach((opStand) => {
        // Locate selected stand
        if (e.target.value === opStand.StandName) {
          FetchForestTypes(opStand.Id);

          setStandDetails({
            ...opStand.LocationDetails,
            LocationId: opStand.Id,
            Name: opStand.StandName,
            Size: opStand.Area,
            ActivityYears: opStand.LocationDetails.ActivityYears
              ? opStand.LocationDetails.ActivityYears.split(", ")
              : [],
          });
        }
      });
    }
  };

  // Update management info methods
  const HandleForestTypeChange = (e) => {
    // Find forest type with matching name
    forestTypes.forEach((forest) => {
      if (forest.ForestType === e.target.value) {
        setStandDetails({
          ...standDetails,
          ForestType: { Id: forest.Id, Name: forest.ForestType },
        });
      }
    });
  };
  const HandleOriginChange = (e) => {
    if (e.target.value === "Forest") {
      setStandDetails({
        ...standDetails,
        Origin: { Id: 212700, Name: "Forest" },
      });
    } else if (e.target.value === "Other") {
      setStandDetails({
        ...standDetails,
        Origin: { Id: 212701, Name: "Other" },
      });
    }
  };
  const HandleTypeOfEntryChange = (e) => {
    setTypeOfEntry(e.target.value);
    setError("");

    switch (e.target.value) {
      case "Age":
        setEntryValue(standDetails.StandAge);
        break;
      case "Total Volume":
        setEntryValue(standDetails.Volume);
        break;
      case "Merchantable Volume":
        setEntryValue(standDetails.MerchVolume);
        break;
      default:
        setEntryValue(0);
        break;
    }
  };
  const HandleInputChange = (e, value) => {
    setEntryValue(value);

    // Based on the type of entry, update the stand details and reset other values
    switch (typeOfEntry) {
      case "Age":
        setStandDetails({
          ...standDetails,
          StandAge: value,
          Volume: 0,
          MerchVolume: 0,
        });
        break;
      case "Total Volume":
        setStandDetails({
          ...standDetails,
          StandAge: 0,
          Volume: value,
          MerchVolume: 0,
        });
        break;
      case "Merchantable Volume":
        setStandDetails({
          ...standDetails,
          StandAge: 0,
          Volume: 0,
          MerchVolume: value,
        });
        break;
      default:
        break;
    }
  };
  const HandlePrescriptionChange = (e) => {
    if (e.target.value === "Grow only, no management activity") {
      setStandDetails({
        ...standDetails,
        Prescription: { Id: 212705, Name: "Grow only, no management activity" },
        ActivityYears: [],
      });
    } else if (e.target.value === "Clear-cut harvesting") {
      setStandDetails({
        ...standDetails,
        Prescription: { Id: 212706, Name: "Clear-cut harvesting" },
      });
    }
  };
  const HandleYearsChange = (e, value) => {
    const newSelection = [...value];
    const orderedSelection = newSelection.map(Number).sort();

    setStandDetails({
      ...standDetails,
      ActivityYears: orderedSelection.map(String),
    });
  };

  // Update stand details
  const UpdateManagement = () => {
    dispatch(updateLoading({ loading: true }));

    // Confirm value for age/volume/merch volume is valid
    post(FORESTRY_VALIDATE, {
      UserId: currentUser.id,
      LocationId: standDetails.LocationId,
      ForestType: standDetails.ForestType.Name,
      Origin: standDetails.Origin.Id,
      Age: standDetails.StandAge,
      Volume: standDetails.Volume,
      MerchVolume: standDetails.MerchVolume,
    })
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          dispatch(updateLoading({ loading: false }));
        } else if (res.status === "invalid") {
          setError(res.data);
        } else {
          // Reset error
          setError("");

          // Only pass what's needed
          const details = {
            LocationId: standDetails.LocationId,
            ForestTypeId: standDetails.ForestType.Id,
            OriginId: standDetails.Origin.Id,
            StandAge: standDetails.StandAge,
            Volume: standDetails.Volume,
            MerchantableVolume: standDetails.MerchVolume,
            Size: standDetails.Size,
            PrescriptionId: standDetails.Prescription.Id,
            ActivityYears: standDetails.ActivityYears.join(", "),
          };

          // Update
          post(FORESTRY_UPDATE_DETAILS, {
            UserId: currentUser.id,
            ManagementDetails: details,
          })
            .then((result) => {
              if (result.error) {
                popupError(result.error, dispatch);
                dispatch(updateLoading({ loading: false }));
              } else {
                projectChanged(true);
              }
            })
            .catch((err) => {
              console.log(err);
              dispatch(updateLoading({ loading: false }));
            });
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(updateLoading({ loading: false }));
      })
      .finally(() => {
        dispatch(updateLoading({ loading: false }));
      });
  };

  // Copy Management popup
  const [showCopyPopup, setShowCopy] = useState(false);

  return (
    <CFBox
      sx={{
        border: "2px #ddd solid",
        borderRadius: "10px",
      }}
    >
      <Grid px={2} pt={2} pb={4}>
        {/* Management Card Title */}
        <CFStep
          stepLabel={managementTitle}
          subText={`Enter information about your forestry management for "${standDetails.Name}"`}
          helpText="Select values from the drop-down menus, or enter numerical values, to provide data for the emissions report."
        />
        <Grid container direction="row">
          <Grid
            item
            container
            direction="column"
            xs={3}
            pl={2}
            pt={2}
            sx={{ minWidth: "200px" }}
          >
            {/* Select Stand */}
            <Grid item container direction="row" alignItems="center">
              <CFStepMarker label="1" />
              <Select
                value={standDetails.Name ? standDetails.Name : "placeholder"}
                onChange={HandleStandChange}
                sx={{ width: "75%", marginLeft: "1em" }}
              >
                <MenuItem disabled value="placeholder">
                  Select a Stand...
                </MenuItem>
                {forestryOp.OperationStands &&
                  forestryOp.OperationStands.map((opStand) => (
                    <MenuItem value={opStand.StandName} key={opStand.Id}>
                      {opStand.StandName}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            {/* Minimap */}
            <Grid item pt={2}>
              <CFMiniMap
                selectedParcel={standDetails}
                currentUser={currentUser}
                activityTypeId={14}
              />
            </Grid>
          </Grid>
          <Grid item container xs={9} pl={1.5} pt={2}>
            {/* Forest Type */}
            <Grid item container direction="row" alignItems="center" pb={1}>
              <CFStepMarker label="2" />
              <Grid item xs={11} px={2}>
                <ForestryInput
                  label="Forest Type"
                  help="Available types are determined based on selected location for Forestry operation."
                  control={
                    <Select
                      value={
                        standDetails.ForestType.Name && forestTypes.length > 0
                          ? standDetails.ForestType.Name
                          : "placeholder"
                      }
                      sx={{ width: "100%" }}
                      onChange={HandleForestTypeChange}
                    >
                      <MenuItem disabled value="placeholder">
                        Select a Forest Type...
                      </MenuItem>
                      {forestTypes.length > 0 &&
                        forestTypes.map((forest) => (
                          <MenuItem value={forest.ForestType} key={forest.Id}>
                            {forest.ForestType}
                          </MenuItem>
                        ))}
                    </Select>
                  }
                />
              </Grid>
            </Grid>
            {/* Past Land Cover */}
            <Grid item container direction="row" alignItems="center" py={1}>
              <CFStepMarker label="3" />
              <Grid item xs={9} px={2}>
                <ForestryInput
                  label="Past Land Cover"
                  help="Indicate the type of land cover this stand has previously experienced."
                  control={
                    <RadioGroup
                      row
                      value={
                        standDetails.Origin.Name
                          ? standDetails.Origin.Name
                          : null
                      }
                      onChange={HandleOriginChange}
                    >
                      <FormControlLabel
                        value="Forest"
                        control={<Radio />}
                        label="Forest"
                      />
                      <FormControlLabel
                        value="Other"
                        control={<Radio />}
                        label="Other"
                      />
                    </RadioGroup>
                  }
                />
              </Grid>
            </Grid>
            {/* Management Size Input */}
            <Grid item container direction="row" alignItems="center" py={1}>
              <CFStepMarker label="4" />
              <Grid item xs={9} px={2}>
                <ForestryInput
                  label="Management Input Selection"
                  help="Please indicate if you will be using age, total volume, or merchantable volume for this stand's management."
                  control={
                    <Select
                      value={typeOfEntry}
                      onChange={HandleTypeOfEntryChange}
                      sx={{ width: "100%" }}
                    >
                      <MenuItem disabled value="placeholder">
                        Select an Input...
                      </MenuItem>
                      <MenuItem value="Age">Age</MenuItem>
                      <MenuItem value="Total Volume">Total Volume</MenuItem>
                      <MenuItem value="Merchantable Volume">
                        Merchantable Volume
                      </MenuItem>
                    </Select>
                  }
                />
              </Grid>
            </Grid>
            {/* Entry */}
            {typeOfEntry !== "placeholder" && (
              <Grid item container direction="row" alignItems="center" pl={6}>
                <CFStepMarker label="4a" />
                <Grid item xs={9} px={2}>
                  <ForestryInput
                    label={typeOfEntry}
                    error={errorMessage}
                    control={
                      <CFNumberInput
                        min={0}
                        value={entryValue}
                        onChange={HandleInputChange}
                        error
                        adornment={typeOfEntry === "Age" ? "years" : "m^3/ha"}
                      />
                    }
                  />
                </Grid>
              </Grid>
            )}
            {/* Prescription */}
            <Grid item container direction="row" alignItems="center" py={1}>
              <CFStepMarker label="5" />
              <Grid item xs={11} px={2}>
                <ForestryInput
                  label="Prescription"
                  help="Select a prescription for the stand."
                  control={
                    <Select
                      value={
                        standDetails.Prescription.Name
                          ? standDetails.Prescription.Name
                          : "placeholder"
                      }
                      onChange={HandlePrescriptionChange}
                      sx={{ width: "100%" }}
                    >
                      <MenuItem disabled value="placeholder">
                        Select a Prescription...
                      </MenuItem>
                      <MenuItem value="Grow only, no management activity">
                        Grow only, no management activity
                      </MenuItem>
                      <MenuItem value="Clear-cut harvesting">
                        Clear-cut harvesting
                      </MenuItem>
                    </Select>
                  }
                />
              </Grid>
            </Grid>
            {/* Activity Years */}
            <Grid item container direction="row" alignItems="center" py={1}>
              <CFStepMarker label="6" />
              <Grid item xs={11} px={2}>
                <ForestryInput
                  label="Activity Years"
                  help="Select all applicable years for the provided management information"
                  control={
                    <Autocomplete
                      disabled={
                        standDetails.Prescription.Name !==
                        "Clear-cut harvesting"
                      }
                      multiple
                      options={managementYears}
                      value={standDetails?.ActivityYears}
                      onChange={HandleYearsChange}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          placeholder="Select All That Apply..."
                        />
                      )}
                      sx={{ minWidth: 350 }}
                    />
                  }
                />
              </Grid>
            </Grid>
            {/* Action Buttons */}
            <Grid
              item
              container
              direction="row"
              justifyContent="flex-end"
              pt={2}
              pr={4}
            >
              {/* Copy */}
              {forestryOp.OperationStands?.length > 1 && (
                <Grid item px={1}>
                  <CFButton
                    onClick={() => setShowCopy(true)}
                    variant="gradient"
                    color="primary"
                    disabled={
                      standDetails.ForestType.Name === null ||
                      standDetails.Origin.Name === null ||
                      (standDetails.StandAge === 0 &&
                        standDetails.Volume === 0 &&
                        standDetails.MerchVolume === 0) ||
                      standDetails.Prescription.Name === null ||
                      (standDetails.Prescription.Id === 212705 &&
                        standDetails.ActivityYears.length > 0) ||
                      (standDetails.Prescription.Id === 212706 &&
                        standDetails.ActivityYears.length === 0)
                    }
                  >
                    Copy
                  </CFButton>
                </Grid>
              )}
              {/* Save */}
              <Grid item pr={1}>
                <CFButton
                  variant="gradient"
                  color="progress"
                  onClick={UpdateManagement}
                  disabled={
                    standDetails.ForestType.Name === null ||
                    standDetails.Origin.Name === null ||
                    (standDetails.StandAge === 0 &&
                      standDetails.Volume === 0 &&
                      standDetails.MerchVolume === 0) ||
                    standDetails.Prescription.Name === null ||
                    (standDetails.Prescription.Id === 212705 &&
                      standDetails.ActivityYears.length > 0) ||
                    (standDetails.Prescription.Id === 212706 &&
                      standDetails.ActivityYears.length === 0)
                  }
                >
                  Save
                </CFButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {showCopyPopup && (
        <CopyManagement
          showCopy={showCopyPopup}
          updateShowCopy={setShowCopy}
          forestryOp={forestryOp}
          projectChanged={projectChanged}
        />
      )}
    </CFBox>
  );
}
export default StandManagement;
