// React
import { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

// Date picker
import dayjs from "dayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";

// MUI
import { Grid, Dialog, RadioGroup, Radio } from "@mui/material";
import { useNavigate } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import colors from "assets/theme/base/colors";

// Our Components
import CFBox from "components/CFBox";
import CFCheckbox from "components/CFCheckbox/CFCheckbox";
import CFInput from "components/CFInput";
import CFStepMarker from "components/CFStepMarker/CFStepMarker";
import CFTypography from "components/CFTypography";
import CFButton from "components/CFButton";
import CFDialog from "components/CFDialog";
import { updateLoading } from "store/reducers/mask";
import { updateUser } from "store/reducers/user";
import { post } from "utils/api";
import { popupError } from "utils/generic";
import { EDIT_PROJECT, CREATE_PROJECT, ADD_DEMO_PROJECT } from "constants/api";
import UserGuidePopup from "components/CFUserGuidePopup/UserGuidePopup";

// Constants
import { fetchUser } from "actions/user";
import { displayedActivities } from "./ChooseActivities/constants";

export default function NewProject({
  isOpen,
  setIsOpen,
  fetchAllProjects,
  projectNames,
  project,
  isDemo,
}) {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user);

  // If project is null then we're creating a new one, so we set up the skeleton here
  const getStartingProject = () => {
    if (project) {
      return {
        ...project,
        userId: currentUser.id,
        startYear: dayjs().year(project.startYear),
        endYear: dayjs().year(project.endYear),
      };
    }

    return {
      userId: currentUser.id,
      name: null,
      notes: null,
      activities: [],
      startYear: null,
      endYear: null,
    };
  };

  // I opted for a single project state object where we just overwrite the necessary fields,
  // instead of separate state variables for name, activities, etc
  const [projectState, setProjectState] = useState(getStartingProject());
  const [showNameError, setShowNameError] = useState(false);
  const navigate = useNavigate();
  // If project is changed or form is opened/closed
  useEffect(() => {
    setProjectState(getStartingProject());
    setShowNameError(false);
  }, [project, isOpen]);

  // Animal ag demo type popup
  const [localPopupState, updateLocalPopup] = useState({});

  // Project info
  const onNameChange = (event) => {
    setProjectState({ ...projectState, name: event.target.value });
    setShowNameError(projectNames.includes(event.target.value));
  };
  const onNotesChange = (event) => {
    setProjectState({ ...projectState, notes: event.target.value });
  };
  // Check to see if current project has an activity selected
  const [animalAgDemoType, setAnAgDemoType] = useState("dairy");
  const projectHasActivity = (activityTypeId) =>
    projectState.activities.includes(activityTypeId);
  // Toggle activity selection
  const toggleActivity = (id) => {
    let activitiesCopy;
    if (projectHasActivity(id)) {
      activitiesCopy = projectState.activities.filter((item) => item !== id);
    } else {
      activitiesCopy = [...projectState.activities];
      activitiesCopy.push(id);
    }

    // Copy the existing projectState object and overwrite just the activities field
    setProjectState({
      ...projectState,
      activities: activitiesCopy,
    });

    if (isDemo && id === 212320 && activitiesCopy.includes(212320)) {
      updateLocalPopup({
        title: "Animal Agriculture Demo Select",
        children: [
          <Grid container direction="column" alignItems="center" py={2}>
            <RadioGroup
              value={animalAgDemoType}
              onChange={(e) => {
                setAnAgDemoType(e.target.value);
              }}
            >
              <FormControlLabel
                value="dairy"
                control={<Radio />}
                label="Animal Agriculture Dairy Demo"
              />
              <FormControlLabel
                value="beef"
                control={<Radio />}
                label="Animal Agriculture Beef Demo"
              />
            </RadioGroup>
          </Grid>,
        ],
        showPopup: true,
        closeAction: () => {
          updateLocalPopup({ showPopup: false });
        },
      });
    }
  };
  useEffect(() => {
    if (localPopupState && localPopupState.showPopup) {
      updateLocalPopup({
        title: "Animal Agriculture Demo Select",
        children: [
          <Grid container direction="column" alignItems="center" py={2}>
            <RadioGroup
              value={animalAgDemoType}
              onChange={(e) => {
                setAnAgDemoType(e.target.value);
              }}
            >
              <FormControlLabel
                value="dairy"
                control={<Radio />}
                label="Animal Agriculture Dairy Demo"
              />
              <FormControlLabel
                value="beef"
                control={<Radio />}
                label="Animal Agriculture Beef Demo"
              />
            </RadioGroup>
          </Grid>,
        ],
        showPopup: true,
        closeAction: () => {
          updateLocalPopup({ showPopup: false });
        },
      });
    }
  }, [animalAgDemoType]);
  const activitiesError = projectState.activities.length === 0;

  const [startError, setStartError] = useState(null);
  const [endError, setEndError] = useState(null);
  const startMessage = useMemo(() => {
    switch (startError) {
      case "maxDate": {
        return "Please allow at least five years between baseline years.";
      }
      case "minDate": {
        return "The baseline start year can be no earlier than 2000.";
      }
      case "invalidRange": {
        return "Please allow at least five years between baseline years.";
      }
      case "invalidDate": {
        return "Please enter a valid date.";
      }
      default: {
        return "";
      }
    }
  }, [startError]);
  const endMessage = useMemo(() => {
    switch (endError) {
      case "maxDate": {
        return "The baseline end year can be no later than one year ago.";
      }
      case "minDate": {
        return "Please allow at least five years between baseline years.";
      }
      case "invalidRange": {
        return "Please allow at least five years between baseline years.";
      }
      case "invalidDate": {
        return "Please enter a valid date";
      }
      default: {
        return "";
      }
    }
  }, [endError]);
  // On start year change
  const handleStartChange = (newYear) => {
    if (
      newYear !== null &&
      projectState.endYear &&
      projectState.endYear.diff(newYear, "year") < 5
    ) {
      setStartError("invalidRange");
    } else {
      setProjectState({ ...projectState, startYear: newYear });
    }
  };
  // On end year change
  const handleEndChange = (newYear) => {
    if (
      newYear !== null &&
      projectState.startYear &&
      newYear.diff(projectState.startYear, "year") < 5
    ) {
      setEndError("invalidRange");
    } else {
      setProjectState({ ...projectState, endYear: newYear });
    }
  };

  const OpenEdit = () => {
    // Navigate to first module of project based on same priority as roadmap
    if (projectState.activities.includes(10)) {
      navigate("/map-croplands");
    } else if (projectState.activities.includes(212320)) {
      navigate("/animal-agriculture");
    } else if (projectState.activities.includes(12)) {
      navigate("/projects");
    } else if (projectState.activities.includes(13)) {
      navigate("/agroforestry");
    } else if (projectState.activities.includes(14)) {
      navigate("/map-forestry");
    } else if (projectState.activities.includes(15)) {
      navigate("/animal-agriculture");
    } else {
      navigate("/projects");
    }
  };

  // Create or set project for editing and close modal
  const handleSubmit = (event) => {
    event.preventDefault();

    dispatch(updateLoading({ loading: true }));
    // Default start/end years
    projectState.startYear = projectState.startYear
      ? projectState.startYear
      : dayjs("2000");
    projectState.endYear = projectState.endYear
      ? projectState.endYear
      : dayjs().subtract(1, "year");

    // Request info
    const requestObject = {
      ...projectState,
      startYear: projectState.startYear.year(),
      endYear: projectState.endYear.year(),
    };

    // Edit
    if (project) {
      post(EDIT_PROJECT, requestObject)
        .then((res) => {
          if (res.error) {
            popupError(res.error, dispatch);
          } else {
            fetchAllProjects();
            setIsOpen(false);
            // dispatch(updateLoading({ loading: false }));
          }
        })
        .catch((err) => {
          popupError(err, dispatch);
          console.log(err);
          fetchAllProjects();
          setIsOpen(false);
        })
        .finally(() => {
          dispatch(updateLoading({ loading: false }));
        });
    } // New
    else if (!project && !isDemo) {
      post(CREATE_PROJECT, requestObject)
        .then((res) => {
          if (res.error) {
            popupError(res.error, dispatch);
          } else {
            // Update store, this might be redundant because of how fetchUser works.
            dispatch(
              updateUser({
                ...currentUser,
                activeProject: {
                  id: res.id,
                  name: res.projectName,
                  activities: projectState.activities,
                },
              })
            );
            // Then fetch from db
            dispatch(
              fetchUser({
                ...currentUser,
                activeProjectId: res.id,
                forceRecalculateProgress: true,
              })
            );

            OpenEdit();

            // UpdateAnimalOperation()
            // fetchAllProjects();
            setIsOpen(false);
          }
        })
        .catch((err) => {
          popupError(err, dispatch);
          console.log(err);
        })
        .finally(() => {
          OpenEdit();
          dispatch(updateLoading({ loading: false }));
        });
    } // Demo
    else if (isDemo) {
      if (requestObject.activities.includes(212320)) {
        requestObject.animalAgDemoType = animalAgDemoType;
      }

      post(ADD_DEMO_PROJECT, { project: requestObject, user: currentUser })
        .then((res) => {
          if (res.error) {
            popupError(res.error, dispatch);
          } else {
            // Update store, this might be redundant because of how fetchUser works.
            dispatch(
              updateUser({
                ...currentUser,
                activeProject: {
                  id: res.data.demoId,
                  name: res.data.demoName,
                  activities: res.data.activities,
                },
              })
            );
            // Then fetch from db
            dispatch(
              fetchUser({
                ...currentUser,
                activeProjectId: res.data.demoId,
                forceRecalculateProgress: true,
              })
            );

            OpenEdit();
            setIsOpen(false);
          }
        })
        .catch((err) => {
          popupError(err, dispatch);
          console.log(err);
        })
        .finally(() => {
          OpenEdit();
          dispatch(updateLoading({ loading: false }));
        });
    }
  };

  return (
    <Dialog open={isOpen} fullWidth maxWidth="lg" sx={{ zIndex: 5 }}>
      <Grid container item xs={12}>
        {/* Main form */}
        <CFBox component="form" onSubmit={handleSubmit}>
          {/* Grid with four rows: title, step one and two, step three, and action buttons */}
          <Grid container direction="row">
            {/* Window Header */}
            {project ? (
              <CFTypography variant="h4" pl={2} py={2}>
                Edit Project
              </CFTypography>
            ) : (
              <CFTypography variant="h4" pl={2} py={2}>
                New Project
              </CFTypography>
            )}
            {/* Grid for steps one and two */}
            <Grid container direction="row">
              {/* Step one */}
              <Grid
                item
                container
                xs={6}
                id="step1Box"
                display="flex"
                alignItems="start"
                justifyContent="start"
              >
                <Grid item xs={1.5} pl={2}>
                  <CFStepMarker label="1" flexGrow={1} />
                </Grid>
                <Grid item xs={10.5}>
                  <CFInput
                    required={!isDemo}
                    fullWidth
                    placeholder={
                      isDemo
                        ? "Project Name (disabled for demo creation)"
                        : "Project Name (required)"
                    }
                    disabled={isDemo}
                    inputProps={{ maxLength: 25 }}
                    value={projectState.name || ""}
                    onChange={(e) => onNameChange(e)}
                  />
                  <CFBox
                    style={{
                      fontSize: "0.67em",
                      color: "red",
                      marginTop: "0.25em",
                    }}
                  >
                    {showNameError ? (
                      "A project with that name already exists. Please enter a different name."
                    ) : (
                      <br />
                    )}
                  </CFBox>
                  <CFInput
                    multiline
                    fullWidth
                    rows={9}
                    inputProps={{ maxLength: 1000 }}
                    placeholder="Project Notes (optional)"
                    value={projectState.notes || ""}
                    onChange={(e) => onNotesChange(e)}
                    sx={{ paddingTop: "0.25em" }}
                  />
                </Grid>
              </Grid>
              {/* Step two */}
              <Grid
                item
                container
                xs={6}
                id="step2box"
                display="flex"
                alignItems="start"
                justifyContent="start"
                px={2}
              >
                <Grid item xs={1.5} pl={2}>
                  <CFStepMarker label="2" flexGrow={1} />
                </Grid>
                <Grid item xs={10.5}>
                  <UserGuidePopup
                    label="Select Accounting Activities"
                    sx={{ fontWeight: "bold" }}
                    content={displayedActivities.map((activityType) => (
                      <Grid key={activityType.name}>
                        <CFBox>
                          <Grid container direction="row" alignItems="center">
                            <img
                              src={activityType.icon}
                              alt={activityType.name}
                              style={{
                                width: "2em",
                              }}
                            />
                            <CFTypography variant="h6" fontWeight="bold">
                              {` ${activityType.name}:`}
                            </CFTypography>
                          </Grid>
                          <CFTypography variant="h6" fontWeight="regular">
                            {`${activityType.message}\n\n`}
                          </CFTypography>
                        </CFBox>
                      </Grid>
                    ))}
                  />
                  <CFBox style={{ fontSize: "10pt" }}>
                    You may select multiple activities within one project;
                    however, reported emissions will be each generated in their
                    own report.
                  </CFBox>
                  <FormControl
                    required
                    error={activitiesError}
                    component="fieldset"
                    variant="filled"
                  >
                    <FormGroup>
                      {displayedActivities.map((activityType) => (
                        <FormControlLabel
                          key={`activityTypeControlLabel${activityType.id}`}
                          control={
                            <CFCheckbox
                              key={`activityType${activityType.id}`}
                              label={activityType.name}
                              icon={activityType.icon}
                              // disabled={activityType.id === 212320}
                              checked={projectHasActivity(activityType.id)}
                              onChange={() => toggleActivity(activityType.id)}
                            />
                          }
                        />
                      ))}
                    </FormGroup>
                    <FormHelperText>
                      Please select at least one activity.
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            {projectState.activities.includes(10) && !isDemo && (
              // Step three
              <Grid
                item
                container
                xs={12}
                id="step3"
                display="flex"
                alignItems="start"
                justifyContent="start"
                pt={2}
              >
                <Grid item container xs={0.75} pl={2}>
                  <CFStepMarker label="3" />
                </Grid>
                <Grid item container xs={11.25}>
                  <UserGuidePopup
                    label="Cropland, Pasture, Range, Orchard/Vineyard Baseline Years"
                    sx={{ fontWeight: "bold" }}
                    content="Your “baseline” refers to your current management, or your “business as usual” before a change in management that you want to compare to. COMET-Farm will default your baseline to be from 2000 to the year prior to our current year (so as of September 2023, the baseline will be 2000-2022). The only reason you’d want to adjust your baseline years is if the change in management that you’d like to assess took place prior to 2023. For example, if you switched from intensive to no till in 2018, you may want to adjust your baseline to be from 2000-2017."
                  />
                  <Grid container direction="row" alignItems="center" pt={1}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      {/* Baseline start */}
                      <Grid
                        item
                        xs={6}
                        container
                        direction="row"
                        alignItems="center"
                      >
                        <CFTypography variant="h6">
                          Baseline Start Year:
                        </CFTypography>
                        <CFBox pl={1}>
                          <DatePicker
                            views={["year"]}
                            openTo="year"
                            label="Baseline Start"
                            minDate={dayjs("2000")} // Minimum date is 2000
                            maxDate={
                              projectState.endYear
                                ? dayjs(projectState.endYear).subtract(
                                    5,
                                    "year"
                                  )
                                : dayjs().subtract(6, "year")
                            } // Allow range of ten years if endYear already selected, otherwise ten years from latest possible
                            value={projectState.startYear}
                            onChange={(newValue) => handleStartChange(newValue)}
                            onError={(newError) => setStartError(newError)}
                            slotProps={{
                              textField: {
                                helperText: startMessage,
                              },
                            }}
                          />
                        </CFBox>
                      </Grid>
                      {/* Baseline end */}
                      <Grid
                        item
                        xs={6}
                        container
                        direction="row"
                        alignItems="center"
                      >
                        <CFTypography variant="h6">
                          Baseline End Year:
                        </CFTypography>
                        <CFBox pl={1}>
                          <DatePicker
                            views={["year"]}
                            openTo="year"
                            label="Baseline End"
                            minDate={
                              projectState.startYear
                                ? dayjs(projectState.startYear).add(5, "year")
                                : dayjs("2005")
                            } // Allow range of ten years if startYear already selected, otherwise ten years from earliest possible
                            maxDate={dayjs().subtract(1, "year")} // Max is one year in the past
                            value={projectState.endYear}
                            onChange={(newValue) => handleEndChange(newValue)}
                            onError={(newError) => setEndError(newError)}
                            slotProps={{
                              textField: {
                                helperText: endMessage,
                              },
                            }}
                          />
                        </CFBox>
                      </Grid>
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {/* Action buttons */}
            <Grid
              container
              direction="row"
              py={2}
              px={2}
              alignItems="center"
              justifyContent="space-between"
            >
              {/* Return to projects */}
              <CFButton color="secondary" onClick={() => setIsOpen(false)}>
                Cancel
              </CFButton>
              {/* Management Details */}
              {project && !isDemo && (
                <CFButton
                  type="submit"
                  color="progress"
                  disabled={showNameError}
                >
                  Save Changes
                </CFButton>
              )}
              {!project && !isDemo && (
                <CFButton
                  type="submit"
                  color="progress"
                  disabled={
                    showNameError ||
                    !projectState.name ||
                    activitiesError ||
                    startError !== null ||
                    endError !== null
                  }
                >
                  Enter Management
                </CFButton>
              )}
              {!project && isDemo && (
                <CFButton
                  type="submit"
                  color="progress"
                  sx={{ marginRight: "1em" }}
                  disabled={activitiesError}
                >
                  Create Demo Project
                </CFButton>
              )}
            </Grid>
          </Grid>
        </CFBox>
      </Grid>

      {localPopupState && localPopupState.showPopup && (
        <CFDialog
          {...localPopupState}
          updatePopup={(e) => updateLocalPopup(e)}
        />
      )}
    </Dialog>
  );
}

NewProject.defaultProps = {
  isOpen: false,
  project: PropTypes.shape({
    name: PropTypes.string.isRequired,
    notes: PropTypes.string,
    activities: PropTypes.arrayOf(PropTypes.number).isRequired,
    id: PropTypes.number.isRequired,
    startYear: PropTypes.number.isRequired,
    endYear: PropTypes.number.isRequired,
  }),
  setIsOpen: PropTypes.func.isRequired,
  fetchAllProjects: PropTypes.func.isRequired,
  projectNames: PropTypes.arrayOf(PropTypes.string).isRequired,
};
