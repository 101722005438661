import { Dialog, Grid, IconButton, Tooltip } from "@mui/material";

import { Close } from "@mui/icons-material";

import CFBox from "components/CFBox";

import CFButton from "components/CFButton";
import colors from "assets/theme/base/colors";
import CFStep from "components/CFarmParts/CFStep";

export default function ConfirmCropChangePopup({
  areYouSureOpen,
  noEditCrop,
  areYouSurenewVal,
  yesEditCrop,
  oldValue,
  ...rest
}) {
  if (oldValue && areYouSurenewVal && areYouSurenewVal.name) {
    return (
      <Dialog
        open={areYouSureOpen}
        sx={{ padding: "3em", zIndex: 5 }}
        maxWidth="xl"
      >
        <IconButton
          onClick={noEditCrop}
          style={{ position: "absolute", right: 0, margin: ".5em", padding: 0 }}
        >
          <Tooltip title="Cancel Copy and Close">
            <Close />
          </Tooltip>
        </IconButton>

        <Grid container item xs={12} sx={{ width: "100%" }}>
          <CFBox sx={{ padding: "2em", width: "100%" }}>
            <Grid container direction="row">
              {/* Window Header */}

              <Grid item container xs={11}>
                {" "}
                <Grid item container xs={12}>
                  {areYouSurenewVal.name.indexOf("(Woody Crop)") < 0 &&
                    areYouSurenewVal.name.indexOf("Fallow") < 0 && (
                      <CFStep
                        stepLabel="Change Crop"
                        subText={`You have completed the crop data entry for ${oldValue.name}, but are now switching crop types. This action will delete all management added for the crop and you will need to enter management for your new crop. Do you want to continue to change your crop type or cancel?`}
                      />
                    )}
                  {areYouSurenewVal.name.indexOf("(Woody Crop)") > -1 && (
                    <CFStep
                      stepLabel="Change Crop"
                      subText={`You have completed the crop data entry for crop ${oldValue.name} but are now switching to an orchard/vineyard crop. This action will delete all management added for the crop. You will need to enter management for your new orchard/vineyard crop. Do you want to continue to change your crop type or cancel?`}
                    />
                  )}

                  {areYouSurenewVal.name.indexOf("Fallow") > -1 && (
                    <CFStep
                      stepLabel="Change Crop"
                      subText={`You have completed the crop data entry for ${oldValue.name}, this action will cause all entered management to be deleted. Do you want to continue?`}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid container py={2} px={2} justifyContent="space-between">
              <CFButton color="secondary" onClick={() => noEditCrop()}>
                Cancel
              </CFButton>

              <CFButton
                color="progress"
                sx={{
                  color: "white.main",
                }}
                onClick={yesEditCrop}
              >
                Continue
              </CFButton>
            </Grid>
          </CFBox>
        </Grid>
      </Dialog>
    );
  }

  return null;
}
