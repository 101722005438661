// React
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

// MUI
import {
  AccordionDetails,
  AccordionSummary,
  Accordion,
  Grid,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

// Our Components

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CFTypography from "components/CFTypography";
import CFBox from "components/CFBox";
import CFInput from "components/CFInput";

import colors from "assets/theme/base/colors";
import CFButton from "components/CFButton";

import { post } from "utils/api";
import { popupError } from "utils/generic";
import { GET_FEEDLOT_FEEDS, UPDATE_FEEDLOT_FEEDS } from "constants/api";
import CFLookupPicker from "components/CroplandsComponents/CFLookupPicker/CFLookupPicker";
import CFLoading from "components/CFLoading";

import FeedCalculator from "./FeedCalculator";

import { getTextInput, getAdditiveUnits } from "./AnimalAgCommonFunc";

// Constants

export default function FeedLotFeeds({
  fdlotid,
  fdlotherdName,
  fdlotyearValue,
  fdlotanimalCategory,
  fldlotRation,
  fdlotExpanded,
  fdlotanimalCategoryId,
  fdlotanagObject,
  fdlotanagObjectChanged,
  fdlotFeedsComplete,
  expanded,
  expandToggle,
}) {
  const dispatch = useDispatch();

  const { info } = colors;
  const empty = { id: 212450, name: "Select" };

  const [fdlotDmi, setFdlotDmi] = useState("");
  const [fdlotcrudeProtein, setFdlotCrudeProtein] = useState("");
  const [fdlotDEPercent, setFdlotDEPercent] = useState("");
  const [fdlotNdfPercent, setFdlotNdfPercent] = useState("");
  const [fdlotStarch, setFdlotStarch] = useState("");
  const [fdlotEE, setFdlotEE] = useState("");
  const [fdlotDaysonFeed, setFdlotDaysonFeed] = useState(1);

  const [calculatorOpen, setCalculatorOpen] = useState(false);
  const [grainTypeLst, setGrainTypeLst] = useState([]);
  const [grain, setGrain] = useState(212477);
  const [ionophore, setIonophore] = useState(212477);

  const [feedName, setFeedName] = useState([]);
  const [populateFeeds, setPopulateFeeds] = useState(false);
  const [saveSuccessMsg, setSaveSuccessMsg] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataExists, setDataExists] = useState(false);

  const [additiveTypeList, setAdditiveTypeList] = useState([]);
  const [dosage, setDosage] = useState(0);
  const [additiveTypeVal, setAdditiveTypeVal] = useState(empty);
  const [additiveUnits, setAdditiveUnits] = useState("");
  const handleFdlotDmiChange = (e) => {
    setFdlotDmi(e.target.value);
  };

  function openFeedCalculator() {
    setCalculatorOpen(true);
    // window.location.href = "/managementEntry";
  }

  const setAdditiveType = (val) => {
    setAdditiveTypeVal({ id: val.id, name: val.name });
    const addUnits = getAdditiveUnits(val.id);
    if (
      val.id === 215203 ||
      val.id === 215204 ||
      val.id === 215205 ||
      val.id === 215201
    ) {
      setDosage(0);
    }

    setAdditiveUnits(addUnits);
  };

  const handleFdlotCrudeProteinChange = (e) => {
    const value = getTextInput(e.target.value);
    setFdlotCrudeProtein(value);
  };
  const handleDEPercentChange = (e) => {
    const value = getTextInput(e.target.value);
    setFdlotDEPercent(value);
  };
  const handleFdlotNdfPercentChange = (e) => {
    const value = getTextInput(e.target.value);
    setFdlotNdfPercent(value);
  };
  const handlePopulateFeeds = () => {
    setPopulateFeeds(true);
  };

  const handleFdlotStarchChange = (e) => {
    const value = getTextInput(e.target.value);
    setFdlotStarch(value);
  };

  const handleFdlotEEChange = (e) => {
    const value = getTextInput(e.target.value);
    setFdlotEE(value);
  };
  const handleFdlotDaysOnFeedChange = (e) => {
    if (e.target.value < 1) setFdlotDaysonFeed(1);
    else if (e.target.value > 365) setFdlotDaysonFeed(365);
    else setFdlotDaysonFeed(e.target.value);
  };
  const handleGrainTypeChange = (e) => {
    setGrain(e.target.value);
  };
  const handleIonophoreTypeChange = (e) => {
    setIonophore(e.target.value);
  };
  const handleDosageChange = (e) => {
    setDosage(e.target.value);
  };
  const validateUserInputs = () => {
    let isValid = false;

    if (
      fdlotDmi > 0 &&
      fdlotcrudeProtein > 0 &&
      fdlotDEPercent > 0 &&
      fdlotNdfPercent > 0 &&
      fdlotDaysonFeed >= 1 &&
      ((additiveTypeVal.id === 215200 && dosage >= 0 && dosage <= 200) ||
        additiveTypeVal.id !== 215200)
    ) {
      isValid = true;
    }
    return isValid;
  };
  const fetchFeedLotFeeds = () => {
    setLoading(true);
    const popObj = {
      popid: fdlotid,
      ration: fdlotherdName,
    };
    post(GET_FEEDLOT_FEEDS, popObj)
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          setLoading(false);
        } else {
          const json = res.data;
          setFdlotDmi(json.dmi);
          setFdlotCrudeProtein(json.cp);
          setFdlotNdfPercent(json.ndf);
          setFdlotDEPercent(json.de);
          setFdlotStarch(json.starch);
          setFdlotEE(json.ee);
          setFdlotDaysonFeed(json.daysOnFeed > 0 ? json.daysOnFeed : 1);
          setGrain(json.grainTypeExistsId);
          setIonophore(json.addIonophoreId);
          setGrainTypeLst(json.grainsLookup);
          setAdditiveTypeVal({
            id: json.additiveTypeId,
            name: json.additiveTypeName,
          });
          setDosage(json.dosage);
          setAdditiveTypeList(json.additiveList);
          const addUnits = getAdditiveUnits(json.additiveTypeId);
          setAdditiveUnits(addUnits);
          if (json.dmi > 0 && json.cp > 0 && json.ndf > 0 && json.de > 0) {
            setDataExists(true);
          }
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const updateFeedLotFeeds = () => {
    setLoading(true);
    const animalTypesObj = {
      dmi: fdlotDmi,
      crudePrtn: fdlotcrudeProtein,
      ndf: fdlotNdfPercent,
      dege: fdlotDEPercent,
      starch: fdlotStarch,
      ee: fdlotEE,
      days: fdlotDaysonFeed,
      feedsName: feedName,
      ration: fdlotherdName,
      grainType: grain,
      addIonophore: ionophore,
      addType: additiveTypeVal.id,
      dsge: dosage,
      popid: fdlotid,
    };
    post(UPDATE_FEEDLOT_FEEDS, animalTypesObj)
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          setLoading(false);
        } else {
          setSaveSuccessMsg(true);
          fdlotFeedsComplete(true);
          // fetchFeedLotFeeds();
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (fldlotRation === fdlotherdName) {
      fetchFeedLotFeeds();
    }
  }, [expanded]);

  useEffect(() => {
    setPopulateFeeds(false);
    fetchFeedLotFeeds();
  }, [populateFeeds]);

  return (
    <CFBox width="100%">
      {loading && <CFLoading local />}
      {/* Main form */}
      <CFBox width="100%">
        <Accordion
          expanded={expanded === "Feeds"}
          onChange={expandToggle("Feeds")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: colors.text.light }} />}
            aria-controls="animalDetailsCard-content"
            id="animalDetailsCard-header"
            sx={{
              backgroundColor: colors.grey[500],
              borderStyle: "outset",
              borderRadius: ".3em",
              flexDirection: "row-reverse",
            }}
          >
            <Grid
              item
              container
              alignItems="center"
              justifyContent="flex-start"
            >
              {/* Year */}

              <CFBox
                xs={12}
                sx={{
                  textAlign: "left",
                  marginLeft: "1em",
                  marginRight: "1em",
                  fontSize: "medium",
                  fontWeight: "550",
                  color: colors.text.light,
                }}
              >
                Feeds
              </CFBox>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container direction="row" xs={12} sm={12} md={12}>
              <Grid item xs={12}>
                <CFTypography
                  sx={{
                    fontSize: "0.9em",
                    fontWeight: "bold",
                    color: info.dullFocus,

                    marginBottom: "0.7em",
                  }}
                >
                  Ration Feeds
                </CFTypography>
              </Grid>
            </Grid>
            <Grid container direction="row" xs={12} sm={12} md={12}>
              <Grid item xs={12} sm={12} md={12}>
                <CFTypography
                  sx={{
                    fontSize: "0.7em",

                    color: info.dullFocus,

                    marginBottom: "0.7em",
                  }}
                >
                  Enter the typical feed characteristics for this feedlot
                  ration. If you do not know the required feed characteristics
                  use the feed calculator.
                </CFTypography>
              </Grid>
            </Grid>
            <Grid container direction="row" xs={12} sm={12} md={12}>
              <Grid item xs={4} sm={4} md={4}>
                <CFButton
                  sx={{
                    backgroundColor: colors.info.dullFocus,
                    color: "white.main",
                    float: "left",
                    marginBottom: "1em",
                  }}
                  onClick={() => openFeedCalculator()}
                >
                  Feed Calculator
                </CFButton>
              </Grid>
            </Grid>
            <Grid container direction="row" xs={12} sm={12} md={12}>
              <Grid container direction="row">
                <Grid item xs={1.7} sm={1.7} md={1.7}>
                  <CFBox
                    style={{ width: "8em" }}
                    sx={{
                      backgroundColor: "#E0E0E0",
                      padding: "1em",
                      borderStyle: "outset",
                      display: "flex",
                      marginBottom: "0.5em",
                    }}
                  >
                    <CFTypography
                      sx={{
                        fontSize: "12px",
                        color: info.dullFocus,
                      }}
                    >
                      Dry Matter Intake
                    </CFTypography>
                  </CFBox>

                  <CFBox
                    style={{ width: "8em" }}
                    sx={{
                      backgroundColor: "#E0E0E0",
                      padding: "10px",
                      borderStyle: "outset",
                      display: "flex",
                    }}
                  >
                    <CFInput
                      sx={{
                        fieldset: {
                          borderColor: info.dullFocus,
                          borderStyle: "solid",
                          borderWidth: "2px",
                          marginBottom: "0.5em",
                        },
                      }}
                      style={{ width: "5em" }}
                      color="primary"
                      id="fdlotdmi"
                      name="fdlotdmi"
                      defaultValue="0"
                      value={fdlotDmi}
                      onChange={handleFdlotDmiChange}
                      placeholder=""
                      // inputProps={{ disabled: value }}
                      fullWidth
                    />
                    <CFTypography
                      sx={{
                        fontSize: "8px",
                        color: info.dullFocus,
                        fontWeight: "bold",
                        marginBottom: "0.5em",
                        marginTop: "0.5em",
                      }}
                    >
                      (lbs/day)
                    </CFTypography>
                  </CFBox>
                </Grid>
                <Grid item xs={1.7} sm={1.7} md={1.7}>
                  <CFBox
                    style={{ width: "8em" }}
                    sx={{
                      backgroundColor: "#E0E0E0",
                      padding: "1em",
                      borderStyle: "outset",
                      display: "flex",
                      marginBottom: "0.5em",
                    }}
                  >
                    <CFTypography
                      sx={{
                        fontSize: "12px",

                        color: info.dullFocus,
                      }}
                    >
                      Crude Protein
                    </CFTypography>
                  </CFBox>

                  <CFBox
                    style={{ width: "8em" }}
                    sx={{
                      backgroundColor: "#E0E0E0",
                      padding: "10px",
                      borderStyle: "outset",
                      display: "flex",
                      marginBottom: "1em",
                    }}
                  >
                    <CFInput
                      sx={{
                        fieldset: {
                          borderColor: info.dullFocus,
                          borderStyle: "solid",
                          borderWidth: "2px",
                        },
                      }}
                      style={{ width: "5em" }}
                      type="number"
                      InputProps={{
                        inputProps: { min: 0, max: 100 },
                      }}
                      color="primary"
                      id="crudeProtein"
                      name="crudeProtein"
                      value={fdlotcrudeProtein}
                      defaultValue="0"
                      onChange={handleFdlotCrudeProteinChange}
                      // inputProps={{ disabled: value }}
                      placeholder=""
                      fullWidth
                    />
                    <CFTypography
                      sx={{
                        fontSize: "8px",
                        color: info.dullFocus,
                        fontWeight: "bold",
                        marginBottom: "0.5em",
                        marginTop: "0.5em",
                        padding: "3px",
                      }}
                    >
                      (%)
                    </CFTypography>
                  </CFBox>
                </Grid>
                <Grid item xs={1.7} sm={1.7} md={1.7}>
                  <CFBox
                    style={{ width: "8em" }}
                    sx={{
                      backgroundColor: "#E0E0E0",
                      padding: "10px",
                      borderStyle: "outset",
                      display: "flex",
                      marginBottom: "0.5em",
                    }}
                  >
                    <CFTypography
                      sx={{
                        fontSize: "12px",

                        color: info.dullFocus,
                      }}
                    >
                      Neutral Detergent Fiber (NDF)
                    </CFTypography>
                  </CFBox>

                  <CFBox
                    style={{ width: "8em" }}
                    sx={{
                      backgroundColor: "#E0E0E0",
                      padding: "10px",
                      borderStyle: "outset",
                      display: "flex",
                      marginBottom: "1em",
                    }}
                  >
                    <CFInput
                      sx={{
                        padding: "10px",
                        fieldset: {
                          borderColor: info.dullFocus,
                          borderStyle: "solid",
                          borderWidth: "2px",
                        },
                      }}
                      style={{ width: "5em" }}
                      type="number"
                      InputProps={{
                        inputProps: { min: 0, max: 100 },
                      }}
                      color="primary"
                      id="ndfPercentCount"
                      name="ndfPercentCount"
                      defaultValue="0"
                      value={fdlotNdfPercent}
                      onChange={handleFdlotNdfPercentChange}
                      // inputProps={{ disabled: value }}
                      placeholder=""
                      fullWidth
                    />
                    <CFTypography
                      sx={{
                        fontSize: "8px",
                        color: info.dullFocus,
                        fontWeight: "bold",
                        marginBottom: "0.5em",
                        marginTop: "0.5em",
                        padding: "3px",
                      }}
                    >
                      (%)
                    </CFTypography>
                  </CFBox>
                </Grid>
                <Grid item xs={1.7} sm={1.7} md={1.7}>
                  <CFBox
                    style={{ width: "8em" }}
                    sx={{
                      backgroundColor: "#E0E0E0",
                      padding: "1em",
                      borderStyle: "outset",
                      display: "flex",
                      marginBottom: "0.5em",
                    }}
                  >
                    <CFTypography
                      sx={{
                        fontSize: "12px",

                        color: info.dullFocus,
                      }}
                    >
                      Digestible Energy
                    </CFTypography>
                  </CFBox>
                  <CFBox
                    style={{ width: "8em" }}
                    sx={{
                      backgroundColor: "#E0E0E0",
                      padding: "10px",
                      borderStyle: "outset",
                      display: "flex",
                      marginBottom: "1em",
                    }}
                  >
                    <CFInput
                      sx={{
                        padding: "10px",
                        fieldset: {
                          borderColor: info.dullFocus,
                          borderStyle: "solid",
                          borderWidth: "2px",
                        },
                      }}
                      style={{ width: "5em" }}
                      type="number"
                      InputProps={{
                        inputProps: { min: 0, max: 100 },
                      }}
                      color="primary"
                      id="depercent"
                      name="depercent"
                      defaultValue="0"
                      value={fdlotDEPercent}
                      onChange={handleDEPercentChange}
                      placeholder=""
                      // inputProps={{ disabled: value }}
                      fullWidth
                    />
                    <CFTypography
                      sx={{
                        fontSize: "8px",
                        color: info.dullFocus,
                        fontWeight: "bold",
                        marginBottom: "0.5em",
                        marginTop: "0.5em",
                        padding: "3px",
                      }}
                    >
                      (%)
                    </CFTypography>
                  </CFBox>
                </Grid>
                <Grid item xs={1.7} sm={1.7} md={1.7}>
                  <CFBox
                    style={{ width: "8em" }}
                    sx={{
                      backgroundColor: "#E0E0E0",
                      padding: "1em",
                      borderStyle: "outset",
                      display: "flex",
                      marginBottom: "0.5em",
                    }}
                  >
                    <CFTypography
                      sx={{
                        fontSize: "12px",

                        color: info.dullFocus,
                      }}
                    >
                      Starch
                    </CFTypography>
                  </CFBox>
                  <CFBox
                    style={{ width: "8em" }}
                    sx={{
                      backgroundColor: "#E0E0E0",
                      padding: "10px",
                      borderStyle: "outset",
                      display: "flex",
                      marginBottom: "1em",
                    }}
                  >
                    <CFInput
                      sx={{
                        padding: "10px",
                        fieldset: {
                          borderColor: info.dullFocus,
                          borderStyle: "solid",
                          borderWidth: "2px",
                        },
                      }}
                      style={{ width: "5em" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      type="number"
                      InputProps={{
                        inputProps: { min: 0, max: 100 },
                      }}
                      color="primary"
                      id="starchpercent"
                      name="starchpercent"
                      defaultValue="0"
                      value={fdlotStarch}
                      onChange={handleFdlotStarchChange}
                      // inputProps={{ disabled: value }}
                      placeholder=""
                      fullWidth
                    />
                    <CFTypography
                      sx={{
                        fontSize: "8px",
                        color: info.dullFocus,
                        fontWeight: "bold",
                        marginBottom: "0.5em",
                        marginTop: "0.5em",
                        padding: "3px",
                      }}
                    >
                      (%)
                    </CFTypography>
                  </CFBox>
                </Grid>{" "}
                <Grid item xs={1.7} sm={1.7} md={1.7}>
                  <CFBox
                    style={{ width: "8em" }}
                    sx={{
                      backgroundColor: "#E0E0E0",
                      padding: "1em",
                      borderStyle: "outset",
                      display: "flex",
                      marginBottom: "0.5em",
                    }}
                  >
                    <CFTypography
                      sx={{
                        fontSize: "12px",

                        color: info.dullFocus,
                      }}
                    >
                      Fat (EE)
                    </CFTypography>
                  </CFBox>
                  <CFBox
                    style={{ width: "8em" }}
                    sx={{
                      backgroundColor: "#E0E0E0",
                      padding: "10px",
                      borderStyle: "outset",
                      display: "flex",
                      marginBottom: "1em",
                    }}
                  >
                    <CFInput
                      sx={{
                        padding: "10px",
                        fieldset: {
                          borderColor: info.dullFocus,
                          borderStyle: "solid",
                          borderWidth: "2px",
                        },
                      }}
                      style={{ width: "5em" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      type="number"
                      InputProps={{
                        inputProps: { min: 0, max: 100 },
                      }}
                      color="primary"
                      id="eepercent"
                      name="eepercent"
                      defaultValue="0"
                      value={fdlotEE}
                      onChange={handleFdlotEEChange}
                      // inputProps={{ disabled: value }}
                      placeholder=""
                      fullWidth
                    />
                    <CFTypography
                      sx={{
                        fontSize: "8px",
                        color: info.dullFocus,
                        fontWeight: "bold",
                        marginBottom: "0.5em",
                        marginTop: "0.5em",
                        padding: "3px",
                      }}
                    >
                      (%)
                    </CFTypography>
                  </CFBox>
                </Grid>
                <Grid item xs={1.7} sm={1.7} md={1.7}>
                  <CFBox
                    style={{ width: "8em" }}
                    sx={{
                      backgroundColor: "#E0E0E0",
                      padding: "1em",
                      borderStyle: "outset",
                      display: "flex",
                      marginBottom: "0.5em",
                    }}
                  >
                    <CFTypography
                      sx={{
                        fontSize: "12px",

                        color: info.dullFocus,
                      }}
                    >
                      Days on Feed
                    </CFTypography>
                  </CFBox>

                  <CFBox
                    style={{ width: "8em" }}
                    sx={{
                      backgroundColor: "#E0E0E0",
                      padding: "10px",
                      borderStyle: "outset",
                      display: "flex",
                      marginBottom: "1em",
                    }}
                  >
                    <CFInput
                      sx={{
                        fieldset: {
                          borderColor: info.dullFocus,
                          borderStyle: "solid",
                          borderWidth: "2px",
                          marginBottom: "0.5em",
                        },
                      }}
                      style={{ width: "5em" }}
                      color="primary"
                      id="fdlotdaysonfeed"
                      name="fdlotdaysonfeed"
                      defaultValue={1}
                      type="number"
                      value={fdlotDaysonFeed}
                      onChange={handleFdlotDaysOnFeedChange}
                      placeholder=""
                      InputProps={{
                        inputProps: {
                          min: 1,
                          max: 365,
                        },
                      }}
                      fullWidth
                    />
                  </CFBox>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Stack direction="row">
                <CFTypography
                  sx={{
                    fontSize: "15px",
                    color: info.dullFocus,
                    marginTop: "0.5em",
                    marginLeft: "1em",
                  }}
                >
                  Is the grain in the animal diet unprocessed or dry-rolled corn
                  or sorghum ?
                </CFTypography>

                <RadioGroup
                  row
                  name="aerobicLagnRadioButton"
                  value={grain}
                  onChange={handleGrainTypeChange}
                  style={{ marginLeft: "1em" }}
                >
                  {grainTypeLst.map((grnType) => (
                    <FormControlLabel
                      value={grnType.id}
                      control={
                        <Radio
                          style={{ borderColor: "black" }}
                          sx={{
                            ".css-wljtfa-MuiButtonBase-root-MuiRadio-root .MuiSvgIcon-root":
                              "border: 0.0625rem solid black",
                          }}
                        />
                      }
                      label={grnType.name}
                    />
                  ))}
                </RadioGroup>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Stack direction="row">
                <CFTypography
                  sx={{
                    fontSize: "15px",
                    color: info.dullFocus,
                    marginTop: "0.5em",
                    marginLeft: "1em",
                  }}
                >
                  Is this ration fed an Ionophore?
                </CFTypography>

                <RadioGroup
                  row
                  name="ionophoreRadioButton"
                  value={ionophore}
                  onChange={handleIonophoreTypeChange}
                  style={{ marginLeft: "1em" }}
                >
                  {grainTypeLst.map((grnType) => (
                    <FormControlLabel
                      value={grnType.id}
                      control={
                        <Radio
                          style={{ borderColor: "black" }}
                          sx={{
                            ".css-wljtfa-MuiButtonBase-root-MuiRadio-root .MuiSvgIcon-root":
                              "border: 0.0625rem solid black",
                          }}
                        />
                      }
                      label={grnType.name}
                    />
                  ))}
                </RadioGroup>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Stack direction="row">
                <CFTypography
                  sx={{
                    fontSize: "15px",
                    color: info.dullFocus,
                    marginTop: "0.5em",
                    marginLeft: "1em",
                  }}
                >
                  Feed Additive Type :
                </CFTypography>

                <CFLookupPicker
                  key="additiveType"
                  name="additiveType"
                  // required
                  label="Select Additive Type"
                  style={{
                    width: "12em",
                    marginLeft: "1em",
                  }}
                  value={additiveTypeVal}
                  onChange={(event, newValue) => {
                    setAdditiveType(newValue);
                  }}
                  options={additiveTypeList}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Stack direction="row">
                <CFTypography
                  sx={{
                    fontSize: "15px",
                    color: info.dullFocus,
                    marginTop: "2em",
                    marginLeft: "1em",
                  }}
                >
                  Dosage :
                </CFTypography>

                <CFInput
                  sx={{
                    padding: "10px",
                    fieldset: {
                      borderColor: info.dullFocus,
                      borderStyle: "solid",
                      borderWidth: "2px",
                    },
                  }}
                  style={{
                    width: "6em",
                    marginTop: "1em",
                    marginLeft: "4.8em",
                  }}
                  type="number"
                  disabled={
                    additiveTypeVal.id === 215203 ||
                    additiveTypeVal.id === 215204 ||
                    additiveTypeVal.id === 215205 ||
                    additiveTypeVal.id === 215201
                  }
                  error={
                    additiveTypeVal.id === 215200 &&
                    (dosage < 0 || dosage > 200)
                  }
                  helperText={
                    additiveTypeVal.id === 215200 &&
                    (dosage < 0 || dosage > 200) &&
                    "3-NOP dosage must be between 0 and 200"
                  }
                  color="primary"
                  id="dsge"
                  name="dsge"
                  defaultValue="0"
                  value={dosage}
                  onChange={handleDosageChange}
                  // inputProps={{ disabled: value }}
                  placeholder=""
                  fullWidth
                />
                <CFTypography
                  sx={{
                    fontSize: "10px",
                    color: info.dullFocus,
                    fontWeight: "bold",
                    marginBottom: "0.5em",
                    marginTop: "3em",
                    padding: "3px",
                  }}
                  id="additiveUnits"
                  key="units"
                >
                  {additiveUnits}
                </CFTypography>
              </Stack>
            </Grid>
            <Grid container py={2} px={2} justifyContent="space-around">
              <Grid item xs={12} sm={12} md={12}>
                <CFBox
                  sx={{
                    width: "100%",

                    marginLeft: "1em",
                    padding: "6px",
                  }}
                >
                  <CFTypography
                    sx={{
                      fontSize: "12px",
                      color: info.dullFocus,
                      fontWeight: "bold",
                      marginBottom: "0.5em",
                      marginTop: "0.5em",
                    }}
                  >
                    {feedName}
                  </CFTypography>
                  <CFBox
                    sx={{
                      width: "100%",
                      marginLeft: "1em",

                      marginBottom: "1.5em",
                    }}
                  />
                </CFBox>
              </Grid>
            </Grid>
            <Grid container py={2} px={2} justifyContent="space-around">
              <Grid item xs={12} sm={12} md={12}>
                <CFButton
                  type="submit"
                  sx={{
                    float: "right",
                  }}
                  color="progress"
                  onClick={() => {
                    updateFeedLotFeeds();
                  }}
                  disabled={!validateUserInputs()}
                >
                  Save
                </CFButton>
              </Grid>
            </Grid>
            {saveSuccessMsg && (
              <Grid container justifyContent="space-around">
                <Grid item xs={2} sm={2} md={2} alignItems="center">
                  <CFBox id="feedSuccessMessage">
                    <CFTypography
                      sx={{
                        fontSize: "15px",
                        color: "green",

                        marginBottom: "0.5em",
                        marginTop: "0.5em",
                      }}
                    >
                      Save was successful.
                    </CFTypography>
                  </CFBox>
                </Grid>
              </Grid>
            )}

            <FeedCalculator
              isOpen={calculatorOpen}
              setIsOpen={setCalculatorOpen}
              // fetchAllProjects={fetchAllProjects}

              Id={fdlotid}
              YearVal={fdlotyearValue}
              rationName={fdlotherdName}
              AnimalCat={fdlotanimalCategory}
              AnimalCatId={fdlotanimalCategoryId}
              animalObj={fdlotanagObject}
              populateFeedsClicked={handlePopulateFeeds}
            />
          </AccordionDetails>
        </Accordion>
      </CFBox>
    </CFBox>
  );
}
