/* eslint-disable prettier/prettier */
import { createSlice } from "@reduxjs/toolkit";

// initial state
const initialState = {
  map: null,
};

const map = createSlice({
  name: "map",
  initialState,
  reducers: {
    updateMap(state, action) {
      // eslint-disable-next-line no-param-reassign
      state.map =
        action.payload.map != null
          ? action.payload.map
          : initialState.map;
    },
    removeMap(state) {
      // eslint-disable-next-line no-param-reassign
      state.map = null;
    },
  },
});

export default map.reducer;

export const { updateMap, removeMap } = map.actions;
