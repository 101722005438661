/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
import { Link } from "react-router-dom";
import CFBox from "components/CFBox";
import CFButton from "components/CFButton";
import CFTypography from "components/CFTypography";
import { loggedIn } from "utils/auth";

export default function CFarmHomeBox() {
  return (
    <CFBox
      sx={{
        backgroundColor: "#eeeeeed0",
        borderRadius: "var(--cf-border-radius)",
        width: "clamp(600px, 90%, 1000px)",
        margin: "1em",
        padding: "1em",
      }}
    >
      <CFTypography variant="h2" align="center" mb={2.5}>
        Estimate your whole farm and ranch carbon sequestration and greenhouse
        gas emissions using COMET-Farm
      </CFTypography>
      <CFTypography variant="h5" align="center">
        Describe your farm and ranch management to generate an estimate
        comparing soil carbon and greenhouse gas emissions between scenarios
      </CFTypography>
      <CFBox
        className="cf-flex column gap-half"
        sx={{
          margin: "auto",
          marginTop: "1em",
        }}
      >
        {loggedIn() ? (
          <LinkButton to="/projects" label="Projects" />
        ) : (
          <>
            <LinkButton label="Login" to="/login" />
            <Link to="/register">
              <CFTypography
                variant="text"
                color="tertiary"
                sx={{ textDecoration: "underline" }}
              >
                Register for an Account
              </CFTypography>
            </Link>
          </>
        )}
      </CFBox>
    </CFBox>
  );
}

const LinkButton = (props) => {
  const { label, to, sx } = props;
  return (
    <CFButton
      to={to}
      sx={{
        ...{
          fontSize: "1.2em",
          textTransform: "none",
          width: "10em",
        },
        ...sx,
      }}
      component={Link}
    >
      {label}
    </CFButton>
  );
};
