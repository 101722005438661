import { PropTypes } from "prop-types";
import GradeIcon from "@mui/icons-material/Grade";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CFBox from "components/CFBox";

export default function RequiredComplete({ complete, size, ...rest }) {
  let fontsize = size;
  if (!size) {
    fontsize = "24px";
  }

  return (
    <CFBox
      sx={{
        fontSize: fontsize,
        position: "absolute",
        right: "1em",
        // paddingRight: "1em",
      }}
    >
      {complete && <CheckCircleIcon style={{ color: "green" }} />}
      {!complete && <CheckCircleIcon style={{ opacity: 0.5 }} />}
    </CFBox>
  );
}
RequiredComplete.defaultProps = {
  complete: false,
  size: "24px",
};

RequiredComplete.propTypes = {
  complete: PropTypes.bool,
  size: PropTypes.string,
};
