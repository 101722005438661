// MUI
import {
  Grid,
  Switch,
  RadioGroup,
  Radio,
  FormControlLabel,
  CircularProgress,
  Checkbox,
} from "@mui/material";

// React
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// CFarm Components
import CFLoading from "components/CFLoading";
import CFButton from "components/CFButton";
import CFTypography from "components/CFTypography";
import CFTabView from "components/CFTabView";
import CFDialog from "components/CFDialog";

// Post
import { post } from "utils/api";
import {
  GET_CROPLAND_REPORT,
  CROPLANDS_FETCH_LOCATIONLIST,
  GET_SINGLE_CROPLAND_REPORT,
  GET_CROPLAND_YEARS,
  DOWNLOAD_DAYCENT_FILES,
} from "constants/api";
import { popupError, useQuery } from "utils/generic";
import { updateLoading } from "store/reducers/mask";

// Local
import CroplandsFieldTable from "./CroplandsFieldTable";
import CroplandsFieldGraph from "./CroplandsFieldGraph";
import CroplandsScenarioSelect from "./CroplandsScenarioSelect";
import CroplandsProjectGraph from "./CroplandsProjectGraph";
import WaterHoldingCapacity from "./WaterHoldingCapacity/WaterHoldingCapacity";

function CroplandsReport({
  reportInfo,
  setReport,
  waterHoldingInfo,
  setWaterHoldingInfo,
}) {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user);
  const query = useQuery();

  // Report data
  const [sampleData, setSampleData] = useState({});
  const [dataLoading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(
      sampleData &&
        sampleData.length > 0 &&
        sampleData.some((field) => field.NeedsFetching)
    );
  }, [sampleData]);

  // Display settings
  const [showUncertainty, setShowUncertainty] = useState(false);
  const [isPercent, setIsPercent] = useState(false);
  const [isPerAcre, setIsPerAcre] = useState(false);
  const [futureScenarios, setFutureScenarios] = useState(
    sampleData[0]?.scenarioRotations
  );
  const [displayedScenarios, setDisplayedScenarios] = useState([]);
  const [scenarioSelect, showScenarioSelect] = useState(false);
  const [scenarioYears, setScenarioYears] = useState("Scenario Years");

  const DownloadDaycentResults = () => {
    dispatch(updateLoading({ loading: true }));

    post(DOWNLOAD_DAYCENT_FILES, currentUser?.activeProject?.id)
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
        } else {
          const text = `data:application/zip;base64,${res.data}`;
          const a = document.createElement("a");
          a.style.display = "none";
          document.body.appendChild(a);

          // Set the HREF to a Blob representation of the data to be downloaded

          a.setAttribute("href", text);
          // Use download attribute to set set desired file name
          a.setAttribute(
            "download",
            `DaycentOutputs_${currentUser?.activeProject?.id}.zip`
          );

          // Trigger the download by simulating click
          a.click();

          // Cleanup
          window.URL.revokeObjectURL(a.href);
          document.body.removeChild(a);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        dispatch(updateLoading({ loading: false }));
      });
  };

  const FetchScenarioYears = () => {
    dispatch(updateLoading({ loading: true }));

    post(GET_CROPLAND_YEARS, {
      UserId: currentUser.id,
    })
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          dispatch(updateLoading({ loading: false }));
        } else {
          setScenarioYears(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(updateLoading({ loading: false }));
      })
      .finally(() => {
        dispatch(updateLoading({ loading: false }));
      });
  };
  useEffect(() => {
    FetchScenarioYears();
  }, []);
  useEffect(() => {
    setFutureScenarios(sampleData[0]?.scenarioRotations);
  }, [sampleData]);

  // Fetch report
  const [fields, setFields] = useState(null);
  const [fetchFlag, setFetchFlag] = useState(false);
  const [statusMessage, setStatusMessage] = useState(
    "Fetching Croplands Report"
  );
  const fetchReportStatus = () => {
    // console.log("fetching report status, old sampleData", sampleData);
    if (sampleData.length > 0) {
      const thingsToFetch = sampleData.filter((x) => x.NeedsFetching);

      if (thingsToFetch.length === 0) {
        post(GET_CROPLAND_REPORT, {
          user: currentUser,
          projectId: query.get("project"),
        })
          .then((res) => {
            if (res.error) {
              popupError(res.error, dispatch);
              // dispatch(updateLoading({ loading: false }));
            } else if (res.data.reportReady) {
              // console.log("report ready");
              setSampleData(JSON.parse(res.data.reportJson));
            } else {
              // show status message, set a timeout, call fetReportStatus again in about 5 seconds
              setStatusMessage(res.data.statusMessage);
              setTimeout(fetchReportStatus, 5000);
            }

            //  dispatch(updateLoading({ loading: false }));
          })
          .catch((err) => {
            setStatusMessage(err.toString());
            setTimeout(fetchReportStatus, 5000);
            console.log(err);
            //  dispatch(updateLoading({ loading: false }));
          });
      } else {
        const getProgress = [];

        for (let i = 0; i < thingsToFetch.length; i += 1) {
          getProgress.push(
            post(GET_SINGLE_CROPLAND_REPORT, {
              user: currentUser,
              projectId: query.get("project"),
              locationId: thingsToFetch[i].id,
            })
              .then((res) => {
                // console.log("got back for report", res);
                if (res.error) {
                  popupError(res.error, dispatch);
                  return null;
                  // dispatch(updateLoading({ loading: false }));
                }
                if (res.data.reportReady) {
                  //   console.log("report ready");
                  return JSON.parse(res.data.reportJson);
                }
                // show status message, set a timeout, call fetReportStatus again in about 5 seconds
                const newThing = { ...thingsToFetch[i] };
                newThing.infoString = `${newThing.name} -- Fetching report ${
                  JSON.parse(res.data.statusMessage).message
                }`;
                return newThing;
              })
              .catch((err) => {
                const newThing = { ...thingsToFetch[i] };
                newThing.infoString = `${
                  newThing.name
                } -- Fetching report ${err.toString()}`;
                return newThing;
              })
          );
        }

        const newSample = [...sampleData];

        Promise.all(getProgress).then((promiseResults) => {
          // console.log("promise results", promiseResults);
          for (let i = 0; i < promiseResults.length; i += 1) {
            const PR = promiseResults[i];
            // console.log("this is the PR", PR);
            let ThePR = {};
            if (PR.length !== undefined) {
              // eslint-disable-next-line prefer-destructuring
              ThePR = PR[0];
            } else {
              ThePR = PR;
            }
            newSample[
              newSample.indexOf(newSample.filter((x) => x.id === ThePR.id)[0])
            ] = { ...ThePR };
          }
          // console.log("setting new sample", newSample);
          setSampleData([...newSample]);
          setTimeout(() => setFetchFlag(!fetchFlag), 5000);
        });
      }
    }
  };
  let oneFetchAtATime = false;
  const fetchFieldsAndScenarios = () => {
    // Get locations and scenarios so we can lay out the report table and know which fields we need to fetch
    if (!oneFetchAtATime) {
      oneFetchAtATime = true;

      dispatch(updateLoading({ loading: true }));

      post(CROPLANDS_FETCH_LOCATIONLIST, {
        user: currentUser,
        projectId: query.get("project"),
      })
        .then((res) => {
          if (res.error) {
            popupError(res.error, dispatch);
          } else {
            setFields(res.data.fieldLocationData);
            const samp = [];
            for (let i = 0; i < res.data.fieldLocationData.length; i += 1) {
              const loc = res.data.fieldLocationData[i];
              samp.push({
                infoString: `${loc.name} -- Fetching report`,
                id: loc.id,
                name: loc.name,
                NeedsFetching: true,
                BaselineRotation: { scenario: { name: "Baseline" } },
                scenarioRotations: loc.scenarios.map((scen) => ({
                  scenario: scen,
                })),
              });
            }
            setSampleData([...samp]);
            setTimeout(() => setFetchFlag(!fetchFlag), 5000);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          oneFetchAtATime = false;
          dispatch(updateLoading({ loading: false }));
        });
    }
  };
  useEffect(() => {
    // Only use the top level if the report finished (this is really inefficient, and should be updated later)
    if (
      reportInfo &&
      reportInfo.length > 0 &&
      reportInfo.filter((x) => x.NeedsFetching).length === 0
    ) {
      setSampleData(reportInfo);
    } else fetchFieldsAndScenarios();
  }, []);
  useEffect(() => {
    fetchReportStatus();
  }, [fetchFlag]);

  // Overall project total emissions
  const ComputeProjectTotals = () => {
    if (sampleData && sampleData.length > 0) {
      let tempTotals = [];

      // Totals object for each column: baseline emissions, baseline uncertainty, scenario emissions, scenario emissions uncertainty, scenario emissions change from baseline, scenario emissions change from baseline uncertainty
      const baselineEmissions = {
        field: "baselineEmissions",
        value: 0,
      };
      const baselineUncertainty = {
        field: "baselineUncertainty",
        value: "",
      };

      tempTotals.push(baselineEmissions);
      tempTotals.push(baselineUncertainty);

      // Loop through each future scenario and create an object, add to array
      sampleData[0]?.scenarioRotations.forEach((scenario) => {
        const scenarioEmissions = {
          field: `${scenario?.scenario?.name}/ScenarioEmissions`,
          value: 0,
        };
        const scenarioUncertainty = {
          field: `${scenario?.scenario?.name}/ScenarioUncertainty`,
          value: "",
        };
        const scenarioEmissionsChange = {
          field: `${scenario?.scenario?.name}/ScenarioEmissionsChange`,
          value: 0,
        };
        const scenarioUncertaintyChange = {
          field: `${scenario?.scenario?.name}/ScenarioUncertaintyChange`,
          value: "",
        };

        tempTotals.push(scenarioEmissions);
        tempTotals.push(scenarioUncertainty);
        tempTotals.push(scenarioEmissionsChange);
        tempTotals.push(scenarioUncertaintyChange);
      });

      // Loop through each field, add value to total. Use Totals "field" for uncertainty values
      sampleData.forEach((field) => {
        if (!field.NeedsFetching) {
          // Uncertainties
          if (field.id === "TOTALS") {
            field.scenarioRotations.forEach((scenario) => {
              // Pull out baseline
              if (scenario.scenario.name === "Baseline") {
                baselineUncertainty.value = isPercent
                  ? scenario.ScenarioEmissions.Years[0].TotalUPoint
                      .plusMinusPercent
                  : scenario.ScenarioEmissions.Years[0].TotalUPoint
                      .plusMinusPercent;
              }

              // Uncertainty total
              tempTotals = tempTotals.map((totalObj) => {
                if (
                  totalObj.field ===
                  `${scenario.scenario.name}/ScenarioUncertainty`
                ) {
                  return {
                    ...totalObj,
                    value: isPercent
                      ? scenario.ScenarioEmissions.Years[0].TotalUPoint
                          .plusMinusPercent
                      : scenario.ScenarioEmissions.Years[0].TotalUPoint
                          .plusMinusPercent,
                  };
                }
                if (
                  totalObj.field ===
                  `${scenario.scenario.name}/ScenarioUncertaintyChange`
                ) {
                  return {
                    ...totalObj,
                    value: isPercent
                      ? scenario.ScenarioEmissions.Years[0].ChangeUPoint
                          .plusMinusPercent
                      : scenario.ScenarioEmissions.Years[0].ChangeUPoint
                          .plusMinusPercent,
                  };
                }
                return totalObj;
              });
            });
          } else {
            // Baseline
            tempTotals = tempTotals.map((totalObj) => {
              if (totalObj.field === "baselineEmissions") {
                let newTotal = totalObj.value;
                field.baselineRotation.ScenarioEmissions.Years[0].Categories.forEach(
                  (category) => {
                    if (category.Name === "Total") {
                      newTotal += isPerAcre
                        ? category.UPoint.value / field.area
                        : category.UPoint.value;
                    }
                  }
                );
                return { ...totalObj, value: newTotal };
              }
              return totalObj;
            });
            // Scenarios
            field.scenarioRotations.forEach((scenario) => {
              tempTotals = tempTotals.map((totalObj) => {
                // Scenario emissions
                if (
                  totalObj.field ===
                  `${scenario.scenario.name}/ScenarioEmissions`
                ) {
                  let newTotal = totalObj.value;
                  scenario.ScenarioEmissions.Years[0].Categories.forEach(
                    (category) => {
                      if (category.Name === "Total") {
                        newTotal += isPerAcre
                          ? category.UPoint.value / field.area
                          : category.UPoint.value;
                      }
                    }
                  );
                  return { ...totalObj, value: newTotal };
                }
                // Change from baseline
                if (
                  totalObj.field ===
                  `${scenario.scenario.name}/ScenarioEmissionsChange`
                ) {
                  let newTotal = totalObj.value;
                  scenario.ScenarioEmissions.Change[0].Categories.forEach(
                    (category) => {
                      if (category.Name === "Total") {
                        newTotal += isPerAcre
                          ? category.UPoint.value / field.area
                          : category.UPoint.value;
                      }
                    }
                  );
                  return { ...totalObj, value: newTotal };
                }
                return totalObj;
              });
            });
          }
        }
      });

      return tempTotals;
    }

    return [];
  };
  const [projectTotals, setProjectTotals] = useState(ComputeProjectTotals());
  useEffect(() => {
    setProjectTotals(ComputeProjectTotals());

    // Update top level if report is complete and new
    if (sampleData && sampleData.length > 0 && reportInfo !== sampleData) {
      setReport(sampleData);
    }
  }, [isPerAcre, isPercent, sampleData]);

  // Display setting
  const [display, setDisplay] = useState("dual");

  // Display tabs
  function CreateTabs() {
    const reportData =
      reportInfo && reportInfo.length > 0 ? reportInfo : sampleData;

    const tempTabs = { TabsList: [] };

    if (reportData && reportData.length > 0) {
      reportData.forEach((field) => {
        if (field.id !== "TOTALS") {
          tempTabs.TabsList.push({
            Tab: field.name,
            Data: field.NeedsFetching ? (
              <Grid
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
                minHeight="750px"
              >
                <CFTypography
                  variant="h2"
                  pb={4}
                  align="center"
                  sx={{ margin: "3em" }}
                >
                  {field.infoString}
                </CFTypography>
                <CircularProgress color="highlight" size="10rem" />
              </Grid>
            ) : (
              <Grid>
                <Grid pt={1}>
                  <CroplandsFieldTable
                    field={field}
                    projectTotals={projectTotals}
                    isPerAcre={isPerAcre}
                    showUncertainty={showUncertainty}
                    isPercent={isPercent}
                    displayedScenarios={displayedScenarios}
                    scenarioYears={scenarioYears}
                  />
                </Grid>
                <Grid>
                  <CroplandsFieldGraph
                    field={field}
                    isPerAcre={isPerAcre}
                    displayedScenarios={displayedScenarios}
                  />
                </Grid>
              </Grid>
            ),
          });
        }
      });
    }

    if (tempTabs.TabsList.length === 0) {
      const defaultTab = {
        Tab: "Croplands Report Data",
        Data: (
          <Grid container direction="column" columns={1} alignItems="center">
            Loading...
          </Grid>
        ),
      };
      tempTabs.TabsList.push(defaultTab);
    }

    return tempTabs;
  }
  const [croplandsTabs, setTabs] = useState(CreateTabs());
  useEffect(() => {
    setTabs(CreateTabs);
  }, [
    display,
    displayedScenarios,
    isPerAcre,
    projectTotals,
    showUncertainty,
    isPercent,
    reportInfo,
    sampleData,
  ]);

  // Demo info popup
  const [demoPopupShown, setDemoPopupShown] = useState(false);
  const [localPopupState, updateLocalPopup] = useState({});
  useEffect(() => {
    if (currentUser?.activeProject?.name.includes("Demo") && !demoPopupShown) {
      setDemoPopupShown(true);
      updateLocalPopup({
        title: "Croplands Demo Project",
        style: { minWidth: "750px", maxHeight: "75vh", overflow: "auto" },
        children: [
          <Grid container direction="column" alignItems="center" p={2}>
            <CFTypography variant="h4" py={1}>
              Croplands Report
            </CFTypography>
            <p>
              The results for this 60 acre field show an estimated carbon
              sequestration and reduced soil nitrous oxide emissions from
              conversion to no-tillage.
            </p>
          </Grid>,
        ],
        showPopup: true,
        closeAction: () => {
          updateLocalPopup({ showPopup: false });
        },
      });
    }
  }, [currentUser.activeProject]);

  if (sampleData === "" && statusMessage !== "") {
    return (
      <Grid container item sx={{ position: "relative" }}>
        <CFLoading message={statusMessage} local />{" "}
      </Grid>
    );
  }
  return (
    <Grid container direction="column" alignItems="center">
      {/* Title and Per Acre Swap */}
      <Grid container direction="column" alignItems="start" pl={1}>
        {/* Title and Table/Graph Swap */}
        <Grid
          item
          xs={6}
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          py={1}
        >
          <Grid>
            <CFTypography variant="h4" pl={1}>
              Croplands Report Summary
            </CFTypography>
          </Grid>
          <Grid
            item
            xs={6}
            container
            direction="row"
            justifyContent="end"
            alignItems="center"
          >
            {display === "dual" && (
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => setShowUncertainty(e.target.checked)}
                  />
                }
                label="Show Uncertainty"
                disabled={dataLoading}
              />
            )}
            {/* Display swap */}
            <CFTypography variant="h6" fontWeight="regular" pr={2}>
              Report Display:
            </CFTypography>
            <RadioGroup
              defaultValue="dual"
              row
              onChange={(e) => setDisplay(e.target.value)}
            >
              <FormControlLabel value="dual" control={<Radio />} label="Dual" />
              <FormControlLabel
                value="graph"
                control={<Radio />}
                label="Graph"
                disabled={dataLoading}
              />
              <FormControlLabel
                value="whc"
                control={<Radio />}
                label="Water Holding Capacity"
                disabled={dataLoading}
              />
            </RadioGroup>
          </Grid>
        </Grid>

        <Grid item container direction="row" alignItems="center" xs={1} pb={1}>
          {/* Units message */}
          <Grid item xs={6} pl={1}>
            <CFTypography variant="h6" fontWeight="regular" fontStyle="italic">
              All estimates are summarized in terms of average annual metric
              tonnes of CO<sub>2</sub>-equivalent over the ten-year scenario
              period.
            </CFTypography>
          </Grid>
          {/* Display settings */}
          <Grid
            item
            container
            direction="row"
            xs={6}
            justifyContent="flex-end"
            alignItems="center"
          >
            {display === "dual" && showUncertainty && (
              // Uncertainty value swap
              <Grid item>
                <FormControlLabel
                  control={
                    <Switch
                      checked={isPercent}
                      onChange={() => {
                        setIsPercent(!isPercent);
                      }}
                    />
                  }
                  label="Uncertainty as Percent"
                  disabled={dataLoading}
                />
              </Grid>
            )}
            {display !== "whc" && (
              // Field/Acre swap
              <Grid item>
                <FormControlLabel
                  control={
                    <Switch
                      checked={isPerAcre}
                      onChange={() => {
                        setIsPerAcre(!isPerAcre);
                      }}
                    />
                  }
                  label="Emissions Per Acre"
                  disabled={dataLoading}
                />
              </Grid>
            )}
            {/* Show future scenario dialog */}
            <Grid item pr={1}>
              {futureScenarios?.length > 0 && display !== "whc" && (
                <CFButton
                  variant="gradient"
                  color="progress"
                  onClick={() => showScenarioSelect(true)}
                  disabled={dataLoading}
                  sx={{ marginRight: 2 }}
                >
                  View Scenarios
                </CFButton>
              )}
              {currentUser.devAccess && (
                <CFButton
                  variant="gradient"
                  color="primary"
                  disabled={dataLoading}
                  onClick={() => DownloadDaycentResults()}
                >
                  Download Daycent Results
                </CFButton>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {display === "dual" && (
        // Graph Selection and Display
        <Grid m={1} maxWidth="99%" width="100%">
          <CFTabView verbose>{croplandsTabs}</CFTabView>
        </Grid>
      )}
      {display === "graph" && (
        // Graph Selection and Display
        <Grid m={1} maxWidth="99%" width="100%" alignItems="start">
          <CroplandsProjectGraph
            croplandsData={sampleData}
            isPerAcre={isPerAcre}
            displayedScenarios={displayedScenarios}
          />
        </Grid>
      )}
      {display === "whc" && (
        // Water holding capacity for each field
        <WaterHoldingCapacity
          waterHoldingInfo={waterHoldingInfo}
          setWaterHoldingInfo={setWaterHoldingInfo}
        />
      )}

      {scenarioSelect && (
        <CroplandsScenarioSelect
          isOpen={scenarioSelect}
          setIsOpen={showScenarioSelect}
          displayedScenarios={displayedScenarios}
          setDisplayedScenarios={setDisplayedScenarios}
          futureScenarios={futureScenarios}
        />
      )}

      {localPopupState && localPopupState.showPopup && (
        <CFDialog
          {...localPopupState}
          updatePopup={(e) => updateLocalPopup(e)}
        />
      )}
    </Grid>
  );
}
export default CroplandsReport;
