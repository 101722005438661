import { useState, useEffect } from "react";

import { Grid, Select, MenuItem } from "@mui/material";
import {
  DataGridPremium,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from "@mui/x-data-grid-premium";

import CFBox from "components/CFBox";
import CFLoading from "components/CFLoading";
import CFStepMarker from "components/CFStepMarker/CFStepMarker";
import CFButton from "components/CFButton";
import CFNumberInput from "components/CFNumberInput/CFNumberInput";
import colors from "assets/theme/base/colors";

import AgroforestryInput from "../AgroforestryInput";

function PreDefined({
  agroforestryOp,
  loadingMessage,
  updateLocalPopup,
  availableScenarios,
  currentlyEditing,
  updateEditScenario,
  addPreDefinedScenario,
  removePreDefinedScenarios,
}) {
  // Editing scenario values
  const { scenarioName, age, size } = { ...currentlyEditing };

  // Scenario table columns
  const columns = [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      flex: 0.25,
      resizable: false,
      headerClassName: "agroforestry-column-header--theme",
    },
    {
      field: "scenarioName",
      headerName: "Scenario",
      type: "string",
      flex: 1,
      resizable: false,
      headerClassName: "agroforestry-column-header--theme",
    },
    {
      field: "age",
      headerName: "Age",
      type: "number",
      flex: 0.3,
      resizable: false,
      headerClassName: "agroforestry-column-header--theme",
    },
    {
      field: "size",
      headerName: "Size",
      type: "number",
      flex: 0.3,
      resizable: false,
      headerClassName: "agroforestry-column-header--theme",
    },
  ];

  // Update rows when user adds or removes scenario from list
  const [rows, setRows] = useState([]);
  useEffect(() => {
    const tempRows = [];

    agroforestryOp?.DetailsList.forEach((projScenario) => {
      if (projScenario.HasType && projScenario.IsNew) {
        tempRows.push({
          scenarioName: projScenario.TypeLookUp.Afpractice,
          scenarioId: projScenario.TypeLookUp.Id,
          age: projScenario.Age,
          size: projScenario.Size,
          id: projScenario.Id,
        });
      }
    });

    setRows(tempRows);
  }, [agroforestryOp]);

  // Add a pre-defined scenario to the project
  function AddScenario() {
    // Only add if an identical scenario isn't already present
    if (
      !agroforestryOp.DetailsList.some(
        (scen) =>
          scen.TypeLookUp.Afpractice === scenarioName &&
          scen.Age === age &&
          scen.Size === size
      )
    ) {
      addPreDefinedScenario();
    } else {
      updateLocalPopup({
        message:
          "This scenario already exists in this project. Please change the scenario parameters to add.",
        title: "Project Scenario Already Exists",
        showPopup: true,
        closeAction: () => {
          updateLocalPopup({ showPopup: false });
        },
      });
    }
  }

  // Remove selected scenarios from the list
  const [selectedScenarios, setSelectedScenarios] = useState([]);

  return (
    <CFBox sx={{ position: "relative" }}>
      {loadingMessage !== "" && (
        <CFLoading
          local
          message={loadingMessage}
          showCircle={loadingMessage.indexOf("Location") === -1}
        />
      )}

      <Grid container direction="column" py={3} px={2}>
        {/* Scenario Select */}
        <Grid item container direction="row" alignItems="center" py={1}>
          <CFStepMarker label="a" />
          <Grid item xs={6} px={2}>
            <AgroforestryInput
              label="Scenario"
              help="Available scenarios are determined based on selected location for Agroforestry operation."
              control={
                <Select
                  value={scenarioName ?? "placeholder"}
                  onChange={(e) =>
                    updateEditScenario({
                      ...currentlyEditing,
                      scenarioName: e.target.value,
                    })
                  }
                  sx={{ width: "100%" }}
                >
                  <MenuItem disabled value="placeholder">
                    Options...
                  </MenuItem>
                  {availableScenarios.map((scen) => (
                    <MenuItem key={scen.id} value={scen.afpractice}>
                      {scen.afpractice}
                    </MenuItem>
                  ))}
                </Select>
              }
            />
          </Grid>
        </Grid>
        {/* Age */}
        <Grid item container direction="row" alignItems="center" py={1}>
          <CFStepMarker label="b" />
          <Grid item xs={6} px={2}>
            <AgroforestryInput
              label="Age"
              control={
                <CFNumberInput
                  min={0}
                  value={age || 0}
                  onChange={(e, value) =>
                    updateEditScenario({ ...currentlyEditing, age: value })
                  }
                  onInputChange={(e) => {
                    // onInputChange runs whenever the value of the text box changes, but it returns a string instead of a value, and doesn't handle error checking
                    if (Number(e.target.value) > 0) {
                      updateEditScenario({
                        ...currentlyEditing,
                        age: Number(e.target.value),
                      });
                    } else {
                      updateEditScenario({
                        ...currentlyEditing,
                        age: 0,
                      });
                    }
                  }}
                  adornment="years"
                />
              }
            />
          </Grid>
        </Grid>
        {/* Size */}
        <Grid item container direction="row" alignItems="center" py={1}>
          <CFStepMarker label="c" />
          <Grid item xs={6} px={2}>
            <AgroforestryInput
              label="Size"
              control={
                <CFNumberInput
                  min={0}
                  value={size || 0}
                  onChange={(e, value) =>
                    updateEditScenario({ ...currentlyEditing, size: value })
                  }
                  onInputChange={(e) => {
                    // onInputChange runs whenever the value of the text box changes, but it returns a string instead of a value, and doesn't handle error checking
                    if (Number(e.target.value) > 0) {
                      updateEditScenario({
                        ...currentlyEditing,
                        size: Number(e.target.value),
                      });
                    } else {
                      updateEditScenario({
                        ...currentlyEditing,
                        size: 0,
                      });
                    }
                  }}
                  adornment="acres"
                />
              }
            />
          </Grid>
        </Grid>
        {/* "Add" Button */}
        <Grid item container direction="row" alignItems="end" py={1}>
          <Grid
            item
            xs={6}
            container
            direction="row"
            justifyContent="flex-end"
            ml={2.5}
          >
            <CFButton
              variant="gradient"
              color="info"
              onClick={() => AddScenario()}
              disabled={
                scenarioName === "placeholder" || age === 0 || size === 0
              }
            >
              Add Scenario
            </CFButton>
          </Grid>
        </Grid>
        {/* Scenario List */}
        <Grid item container direction="row" alignItems="start" py={3} pr={2}>
          <Grid item xs={10.5} pr={2}>
            <CFBox
              sx={{
                "& .agroforestry-column-header--theme": {
                  fontSize: "1.2rem",
                  color: "white.main",
                  backgroundColor: colors.primary.main,
                },
              }}
            >
              <DataGridPremium
                columns={columns}
                rows={rows}
                checkboxSelection
                rowSelectionModel={selectedScenarios}
                onRowSelectionModelChange={(newModel) =>
                  setSelectedScenarios(newModel)
                }
              />
            </CFBox>
          </Grid>
          <Grid item xs={1}>
            <CFButton
              variant="gradient"
              color="secondary"
              disabled={!selectedScenarios.length > 0}
              onClick={() => {
                removePreDefinedScenarios(selectedScenarios);
                setSelectedScenarios([]);
              }}
            >
              Delete Selected
            </CFButton>
          </Grid>
        </Grid>
      </Grid>
    </CFBox>
  );
}
export default PreDefined;
