import { PropTypes } from "prop-types";

import UserGuidePopup from "components/CFUserGuidePopup/UserGuidePopup";
import CFButton from "components/CFButton";
import CFBox from "components/CFBox";

export default function HelpAndButton({
  helperText,
  helperLink,
  buttonLabel,
  buttonAction,
  userGuideLabel,
  userGuidePopupWidth,
  ...rest
}) {
  return (
    <CFBox sx={{ display: "flex", alignItems: "center", margin: "1em" }}>
      <CFButton onClick={() => buttonAction()}>{buttonLabel}</CFButton>

      <UserGuidePopup
        width={userGuidePopupWidth}
        content={helperText || null}
        helperLink={helperLink || null}
        userGuideLabel={userGuideLabel}
        variant="h3"
        sx={{ marginTop: ".2em", padding: ".1em" }}
      />
    </CFBox>
  );
}

HelpAndButton.propTypes = {
  buttonLabel: PropTypes.string.isRequired,
  buttonAction: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
    .isRequired,
};
