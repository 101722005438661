/* eslint-disable react/no-array-index-key */
import { Box, Grid, Typography } from "@mui/material";
import colors from "assets/theme/base/colors";

import { useEffect, useState } from "react";

export default function ({ CropEvent, fertilizerRatios }) {
  const [displayN, setDisplayN] = useState(0);

  const recalculateNSummary = () => {
    let runningNTotal = 0;
    for (let i = 0; i < CropEvent.nitrogenApplications.length; i += 1) {
      const app = CropEvent.nitrogenApplications[i];
      if (
        fertilizerRatios &&
        (!fertilizerRatios[app.fertilizerType.id] ||
          fertilizerRatios[app.fertilizerType.id].mineralN !== 0)
      )
        runningNTotal += parseFloat(app.totalNApplied);
    }
    for (let i = 0; i < CropEvent.manureApplications.length; i += 1) {
      const app = CropEvent.manureApplications[i];
      runningNTotal +=
        parseFloat(app.totalApplied) * 2000 * (parseFloat(app.percentN) / 100);
    }
    setDisplayN(runningNTotal.toFixed(2));
  };

  useEffect(recalculateNSummary, [
    CropEvent,
    CropEvent.manureApplications,
    CropEvent.nitrogenApplications,
  ]);

  return (
    <Grid alignItems="center" container flexDirection="row">
      <Grid item xs={12}>
        <Grid
          item
          backgroundColor={colors.highlight.main}
          xs={12}
          container
          flexDirection="row"
          alignItems="center"
          sx={{
            paddingTop: ".5em",
            borderRadius: ".3em",
            marginTop: "1em",
            marginLeft: "1em",
            marginRight: "1em",
            width: "95%",
            boxShadow: "1px 2px 3px #bbb",
          }}
        >
          <Grid item xs={12} container flexDirection="row" alignItems="center">
            <Typography
              sx={{
                color: colors.text.light,
              }}
              pl={2}
              pt={0.5}
              pb={2}
              component="div"
            >
              Total N Applied for {CropEvent.name} in {CropEvent.cropYear}:{" "}
              <Box fontWeight="fontWeightMedium" display="inline">
                {` ${displayN} lbs/ac`}
              </Box>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
