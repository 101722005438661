import CFBox from "components/CFBox";
import CPA329 from "assets/cFarmPDFs/CPA/329.pdf";
import CPA340 from "assets/cFarmPDFs/CPA/340.pdf";
import CPA590 from "assets/cFarmPDFs/CPA/590.pdf";

import CPA332 from "assets/cFarmPDFs/CPA/332.pdf";

import CPA386 from "assets/cFarmPDFs/CPA/386.pdf";

import CPA390 from "assets/cFarmPDFs/CPA/390.pdf";

import CPA393 from "assets/cFarmPDFs/CPA/393.pdf";
import CPA412 from "assets/cFarmPDFs/CPA/412.pdf";

import CPA601 from "assets/cFarmPDFs/CPA/601.pdf";
import CPA327 from "assets/cFarmPDFs/CPA/327.pdf";
import Link from "assets/theme/components/link";

export default function CPAHelperText() {
  return (
    <CFBox>
      <span>
        The Conservation Practice Advisor (CPA) is a new feature that provides
        users with a starting point to apply USDA-NRCS Conservation Practice
        Standards (CPS) to future scenarios. The CPS are based on the USDA-NRCS
        National Conservation Practice Standards. &quot;The conservation
        practice standard contains information on why and where the practice is
        applied, and it sets forth the minimum quality criteria that must be met
        during the application of that practice in order for it to achieve its
        intended purpose(s).&quot;{" "}
        <a
          target="_blank"
          href="https://www.nrcs.usda.gov/wps/portal/nrcs/main/national/technical/cp/ncps/"
          rel="noreferrer"
        >
          National Conservation Practice Standards
        </a>{" "}
        Users are encouraged to use the CPA as a template, and should be
        modified to reflect their unique management to meet the state and local
        criteria set by the USDA-NRCS State Office where the project is located.
        State conservation practices standards are available through the{" "}
        <a
          target="_blank"
          href="https://www.nrcs.usda.gov/resources/guides-and-instructions/field-office-technical-guides"
          rel="noreferrer"
        >
          Field Office Technical Guide
        </a>{" "}
        (FOTG). There are a limited number of available CPS on the COMET-Farm
        tool at this time. The COMET-Farm team is working on adding a
        comprehensive list of CPS to the tool.If you have questions or comments
        please contact the COMET-Farm at{" "}
        <a target="_blank" href="appnrel@colostate.edu">
          appnrel@colostate.edu.
        </a>
      </span>
    </CFBox>
  );
}

export const StripsandBorders = [
  {
    name: "Contour Buffer Strips: Convert Strips of Cropland to Permanent Unfertilized Grass Cover",
    id: "CPA332_1",
    secondId: 210,
    link: {
      href: { CPA332 },
      text: "(CPS 332)",
    },
  },
  {
    name: "Contour Buffer Strips: Convert Strips of Cropland to Permanent Unfertilized Grass/Legume Cover",
    id: "CPA332_2",
    secondId: 253,
    link: {
      href: { CPA332 },
      text: "(CPS 332)",
    },
  },
  {
    name: "Field Border: Convert Strips of Cropland to Permanent Unfertilized Grass Cover",
    id: "CPA386_1",
    secondId: 210,
    link: {
      href: { CPA386 },
      text: "(CPS 386)",
    },
  },
  {
    name: "Field Border: Convert Strips of Cropland to Permanent Unfertilized Grass/Legume Cover",
    id: "CPA386_2",
    secondId: 253,
    link: {
      href: { CPA386 },
      text: "(CPS 386)",
    },
  },
  {
    name: "Riparian Herbaceous Cover: Convert Strips of Cropland to Permanent Unfertilized Grass Cover",
    id: "CPA390_1",
    secondId: 210,
    link: {
      href: { CPA390 },
      text: "(CPS 390)",
    },
  },
  {
    name: "Riparian Herbaceous Cover: Convert Strips of Cropland to Permanent Unfertilized Grass/Legume Cover",
    id: "CPA390_2",
    secondId: 253,
    link: {
      href: { CPA390 },
      text: "(CPS 390)",
    },
  },
  {
    name: "Filter Strip: Convert Strips of Cropland to Permanent Unfertilized Grass Cover",
    id: "CPA393_1",
    secondId: 210,
    link: {
      href: { CPA393 },
      text: "(CPS 393)",
    },
  },
  {
    name: "Filter Strip: Convert Strips of Cropland to Permanent Unfertilized Grass/Legume Cover",
    id: "CPA393_2",
    secondId: 253,
    link: {
      href: { CPA393 },
      text: "(CPS 393)",
    },
  },
  {
    name: "Grassed Water Way: Convert Strips of Cropland to Permanent Unfertilized Grass Cover",
    id: "CPA412_1",
    secondId: 210,
    link: {
      href: { CPA412 },
      text: "(CPS 412)",
    },
  },
  {
    name: "Grassed Water Way: Convert Strips of Cropland to Permanent Unfertilized Grass/Legume Cover",
    id: "CPA412_2",
    secondId: 253,
    link: {
      href: { CPA412 },
      text: "(CPS 412)",
    },
  },
  {
    name: "Vegetative Barrier: Convert Strips of Cropland to Permanent Unfertilized Grass Cover",
    id: "CPA601_1",
    secondId: 210,
    link: {
      href: { CPA601 },
      text: "(CPS 601)",
    },
  },
  {
    name: "Vegetative Barrier: Convert Strips of Cropland to Permanent Unfertilized Grass/Legume Cover",
    id: "CPA601_2",
    secondId: 253,
    link: {
      href: { CPA601 },
      text: "(CPS 601)",
    },
  },
];
