import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import PropTypes from "prop-types"; // @mui material components
import CFarmPage from "components/CFarmParts/CFarmPage";
import CFStep from "components/CFarmParts/CFStep";
import CFTypography from "components/CFTypography";
import CFBox from "components/CFBox";
import colors from "assets/theme/base/colors";
import CFInput from "components/CFInput";
import CFButton from "components/CFButton";
import CFStepMarker from "components/CFStepMarker/CFStepMarker";
import CFDialog from "components/CFDialog";
import { updateLoading } from "store/reducers/mask";
import { popupError } from "utils/generic";
import { INSERT_SCENARIO } from "constants/api";

import { post } from "utils/api";
import CFLoading from "components/CFLoading";
import style from "./AnimalAgStyle";

function NewScenario(props) {
  const {
    anagOpObject,
    anagObjectChanged,
    scenObjectChanged,
    newScenarioSelected,
    copyScenario,
  } = props;

  const dispatch = useDispatch();
  const [localPopupState, updateLocalPopup] = useState({});

  const [scenarioName, setScenarioName] = useState("");
  const currentUser = useSelector((state) => state.user);
  const { info } = colors;
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    newScenarioSelected(false, copyScenario);
  };
  const handleScenarioNameChange = (e) => {
    setScenarioName(e.target.value);
  };

  const submitNewScenario = (e) => {
    e.preventDefault();

    setLoading(true);

    const insertObj = {
      newscname: scenarioName,
      sctocopy: copyScenario,
      id: currentUser,
    };
    post(INSERT_SCENARIO, insertObj)
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          setLoading(false);
        } else {
          const json = JSON.parse(res.data);
          scenObjectChanged(json);

          newScenarioSelected(false);
          anagObjectChanged(true);
        }
      })
      .catch((err) => {
        updateLocalPopup({
          message: err,
          title: "Error",
          showPopup: true,
          closeAction: () => {
            updateLocalPopup({
              showPopup: false,
            });
          },
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <CFBox sx={{ width: "21em", paddingLeft: ".5em" }}>
      {loading && <CFLoading local />}
      <CFStep
        stepLabel="Enter new scenario name:"
        helpText={
          <div>
            <p>
              Users may now define new scenarios for future management.
              COMET-Farm allows for a maximum of 10 scenarios. A short (25
              characters), descriptive title (e.g. Digester Install) is
              recommended for best interpreting reports.
            </p>
            <br />
            <p>
              Once created, users can select which scenario to view/edit using
              the gray panel in the upper righthand corner. Use the trash can
              icons to delete a scenario
            </p>
            <br />
            <p>
              Click <i>Save</i> to begin entering/modifying management for this
              new scenario.
            </p>
          </div>
        }
      />
      <CFBox
        component="form"
        onSubmit={submitNewScenario}
        autoComplete="off"
        sx={{
          marginLeft: "1em",
          marginTop: "1em",
          marginBottom: "2em",
        }}
      >
        <Grid
          flexDirection="row"
          justifyContent="start"
          alignItems="start"
          container
        >
          <Grid
            container
            flexDirection="row"
            alignItems="start"
            justifyContent="center"
            item
            xs={12}
            sm={12}
            md={12}
          >
            <Grid item xs={12} sm={12} md={12} />
            <CFInput
              sx={{
                padding: "10px",
                fieldset: {
                  borderColor: info.dullFocus,
                  borderStyle: "solid",
                  borderWidth: "2px",
                },
                marginBottom: ".5em",
              }}
              color="primary"
              id="anagScName"
              name="anagScName"
              value={scenarioName}
              onChange={handleScenarioNameChange}
              placeholder=""
              width="50%"
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <CFButton
              size="large"
              color="secondary"
              variant="gradient"
              onClick={() => handleClose()}
              sx={{ textTransform: "none", marginTop: "1em" }}
            >
              Cancel
            </CFButton>
            <CFButton
              size="large"
              color="progress"
              variant="gradient"
              type="submit"
              sx={{
                float: "right",
                textTransform: "none",
                marginTop: "1em",
                marginLeft: "8em",
              }}
            >
              Save
            </CFButton>
          </Grid>
        </Grid>
      </CFBox>
      {localPopupState && localPopupState.showPopup && (
        <CFDialog
          {...localPopupState}
          updatePopup={(e) => updateLocalPopup(e)}
        />
      )}
    </CFBox>
  );
}

export default NewScenario;
