import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Info } from "@mui/icons-material";

import CFBox from "components/CFBox";
import CFTypography from "components/CFTypography";
import CFInput from "components/CFInput";
import CFButton from "components/CFButton";
import colors from "assets/theme/base/colors";
import { updateLoading } from "store/reducers/mask";
import { post } from "utils/api";
import { UPDATE_POULTRY, FETCH_POULTRY } from "constants/api";
import { popupError } from "utils/generic";
import SwineHeader from "components/AnimalAgracultureComponents/SwineHeader";
import SwineInput from "components/AnimalAgracultureComponents/SwineInput";
import CFLoading from "components/CFLoading";

function BirdRadio({ question, value, onChange, options }) {
  return (
    <>
      <Grid item xs={6}>
        <CFTypography
          sx={{
            fontSize: "16px",
            // width: "50%",
            color: colors.info.dullFocus,
          }}
        >
          {question}
        </CFTypography>
      </Grid>
      <Grid item xs={2}>
        <RadioGroup
          row
          name={`birdRadio${question}`}
          value={value}
          onChange={(event) => onChange(event.target.value)}
        >
          {options.map((opt) => (
            <FormControlLabel
              key={opt.name}
              value={opt.value}
              control={<Radio />}
              label={opt.name}
            />
          ))}
        </RadioGroup>
      </Grid>
    </>
  );
}

export default function Poultry({
  id,
  yearValue,
  animalCategory,
  animalCategoryId,
  anagObject,
  anagObjectChanged,
  verifyDone,
}) {
  const dispatch = useDispatch();
  const [avgDMI, setAvgDMI] = useState(0);
  const [crudeProtein, setCrudeProtein] = useState(0);
  const [startWeight, setStartWeight] = useState(0);
  const [endWeight, setEndWeight] = useState(0);
  const [birdsPerFlock, setBirdsPerFlock] = useState(0);
  const [flocksPerYear, setFlocksPerYear] = useState(0);
  const [avgDayProductionPeriod, setAvgDayProductionPeriod] = useState(0);
  const [heavyLayer, setHeavyLayer] = useState(0);

  const [saveState, setSaveState] = useState("");
  const [loading, setLoading] = useState(false);

  const updatePoultry = () => {
    setLoading(true);

    const postCall = UPDATE_POULTRY;
    const postObj = {
      popId: id,
      avgDayProductionPeriod,
      flocksPerYear,
      birdsPerFlock,
      startWeight,
      endWeight,
      avgDMI,
      crudeProtein,
      heavyLayer,
    };

    post(postCall, postObj)
      .then((res) => {
        setLoading(false);
        if (res.error) {
          console.log("Error obj ", res.error);
          setSaveState("Error saving. Please try again.");
        } else {
          setSaveState("Save was successful");
          verifyDone("green");
        }
        setLoading(false);
      })
      .catch((err) => {
        setSaveState("Error saving. Please try again.");
        setLoading(false);
      });
  };
  const setPoultry = (data) => {
    setBirdsPerFlock(data.birdsPerFlock);

    setFlocksPerYear(data.flocksPerYear);
    setHeavyLayer(data.heavyLayer);

    setAvgDayProductionPeriod(data.avgDayProductionPeriod);
    setStartWeight(data.startWeight);
    setEndWeight(data.endWeight);
    setAvgDMI(data.avgDMI);
    setCrudeProtein(data.crudeProtein);
  };
  const validateUserInputs = (data) => {
    let isValid = false;

    if (
      (data !== undefined ? data.birdsPerFlock > 0 : birdsPerFlock > 0) &&
      (data !== undefined ? data.flocksPerYear > 0 : flocksPerYear > 0) &&
      (data !== undefined ? data.heavyLayer != null : heavyLayer != null) &&
      (data !== undefined
        ? data.avgDayProductionPeriod > 0
        : avgDayProductionPeriod > 0) &&
      (data !== undefined ? data.startWeight > 0 : startWeight > 0) &&
      (data !== undefined ? data.endWeight > 0 : endWeight > 0) &&
      (data !== undefined ? data.avgDMI > 0 : avgDMI > 0) &&
      (data !== undefined ? data.crudeProtein > 0 : crudeProtein > 0)
    ) {
      isValid = true;
    }

    return isValid;
  };

  useEffect(() => {
    post(FETCH_POULTRY, { popId: id })
      .then((res) => {
        setLoading(false);
        if (res.error) {
          popupError(res.error, dispatch);
          console.log("Error obj ", res.error);
        } else {
          setPoultry(res.data);
          if (validateUserInputs(res.data)) {
            verifyDone("green");
          } else {
            verifyDone("grey");
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);
  return (
    <CFBox width="100%">
      {loading && <CFLoading local />}
      <Grid container item>
        {/* Main form */}
        <CFBox width="100%">
          <Grid container direction="row" xs={12} sm={12} md={12}>
            <Accordion defaultExpanded marginBottom=".5em">
              <SwineHeader text="Flock Details" />
              <AccordionDetails>
                <Grid
                  container
                  xs={12}
                  spacing={2}
                  alignItems="center"
                  direction="row"
                >
                  <SwineInput
                    question="Average number of flocks per year:"
                    value={flocksPerYear}
                    onChange={setFlocksPerYear}
                    endAdornment="flocks"
                    min={0}
                  />
                  <SwineInput
                    question="Average number of birds per flock:"
                    value={birdsPerFlock}
                    onChange={setBirdsPerFlock}
                    endAdornment="birds"
                    min={0}
                  />
                  {animalCategoryId === 21273 && (
                    <SwineInput
                      question="Initial body weight at beginning of production period:"
                      value={startWeight}
                      onChange={setStartWeight}
                      endAdornment="lbs"
                      min={0}
                    />
                  )}
                  {animalCategoryId !== 21273 && (
                    <SwineInput
                      question="Initial body weight:"
                      value={startWeight}
                      onChange={setStartWeight}
                      endAdornment="lbs"
                      min={0}
                    />
                  )}
                  {animalCategoryId === 21273 && (
                    <SwineInput
                      question="Final body weight at the end of the production period:"
                      value={endWeight}
                      onChange={setEndWeight}
                      min={0}
                      endAdornment="lbs"
                    />
                  )}
                  {animalCategoryId !== 21273 && (
                    <SwineInput
                      question="Final body weight (slaughter weight):"
                      value={endWeight}
                      onChange={setEndWeight}
                      min={0}
                      endAdornment="lbs"
                    />
                  )}

                  <SwineInput
                    question="Average number of days in each production period:"
                    value={avgDayProductionPeriod}
                    onChange={setAvgDayProductionPeriod}
                    endAdornment="days"
                    min={0}
                  />

                  {animalCategoryId === 21273 && (
                    <BirdRadio
                      question="On average, are birds heavy or light layers?"
                      value={heavyLayer}
                      onChange={setHeavyLayer}
                      options={[
                        { name: "H", value: true },
                        { name: "L", value: false },
                      ]}
                    />
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <SwineHeader text="Feed Details" />
              <AccordionDetails>
                <Grid
                  container
                  xs={12}
                  spacing={2}
                  alignItems="center"
                  direction="row"
                >
                  <SwineInput
                    question="Average daily dry matter intake:"
                    value={avgDMI}
                    onChange={setAvgDMI}
                    endAdornment="lbs"
                  />
                  <SwineInput
                    question="Crude protein:"
                    value={crudeProtein}
                    onChange={setCrudeProtein}
                    endAdornment="%"
                    min={0}
                    max={100}
                  />
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid container py={2} px={2} justifyContent="space-around">
            <Grid item xs={12} sm={12} md={12}>
              <CFButton
                type="submit"
                color="progress"
                sx={{
                  float: "right",
                }}
                onClick={updatePoultry}
                disabled={!validateUserInputs()}
              >
                Save
              </CFButton>
            </Grid>
          </Grid>
          <Grid container justifyContent="space-around">
            <Grid item xs={2} sm={2} md={2} alignItems="center">
              <CFBox>
                <CFTypography
                  sx={{
                    fontSize: "15px",
                    color: !saveState.includes("Error") ? "green" : "red",

                    marginBottom: "0.5em",
                    marginTop: "0.5em",
                  }}
                >
                  {saveState}
                </CFTypography>
              </CFBox>
            </Grid>
          </Grid>
        </CFBox>
      </Grid>
    </CFBox>
  );
}
