import Login from "pages/CFarmStatic/Login";
import Documentation from "pages/CFarmStatic/Documentation";

import Registration from "pages/CFarmStatic/Registration";
import Training from "pages/CFarmStatic/Training";
import Version from "pages/CFarmStatic/Version";

import ApiAccess from "pages/CFarmStatic/ApiAccess/ApiAccessPage";
import ChooseActivities from "pages/ProjectManagement/ChooseActivities";
import ProjectReport from "pages/Reports/ProjectReport";
import RequireAuth from "components/CFarmParts/RequiresAuth";
import VerifyAccount from "pages/CFarmStatic/Registration/VerifyAccount";
import ForgotPassword from "pages/CFarmStatic/Login/ForgotPassword";
import ResetPassword from "pages/CFarmStatic/Login/ResetPassword";
import AnimalAg from "pages/AnimalAg/AnimalAg";
import Agroforestry from "pages/Agroforestry/Agroforestry";
import Forestry from "pages/Forestry/Forestry";
import CFMap from "pages/CFMap/CFMap";
import Roadmap from "components/CFarmParts/CFarmPage/CFRoadmap/Roadmap";
import CFarmPage from "components/CFarmParts/CFarmPage";
import CFStep from "components/CFarmParts/CFStep";
import CFTypography from "components/CFTypography";
import CroplandsHistoryAndCurrentPage from "pages/Croplands/CroplandsHistoryAndCurrentPage";
import CroplandsScenarioPage from "pages/Croplands/CroplandsScenarioPage";
import QuickEnergy from "pages/CFarmStatic/QuickEnergy/QuickEnergy";

const allRoutes = [
  {
    route: "/map-croplands",
    component: (
      <RequireAuth>
        <CFMap activityTypeId={10} />
      </RequireAuth>
    ),
  },
  {
    route: "/map-forestry",
    component: (
      <RequireAuth>
        <CFMap activityTypeId={14} />
      </RequireAuth>
    ),
  },
  {
    route: "/VerifyAccountEmail/*",
    component: <VerifyAccount />,
  },
  {
    route: "/ForgotPassword",
    component: <ForgotPassword />,
  },
  {
    route: "/ResetPassword/*",
    component: <ResetPassword />,
  },
  {
    route: "/projects",
    component: (
      <RequireAuth>
        <ChooseActivities />
      </RequireAuth>
    ),
  },
  {
    route: "/api-access",
    component: (
      <RequireAuth>
        <ApiAccess />
      </RequireAuth>
    ),
  },
  {
    route: "/report",
    component: (
      <RequireAuth>
        <ProjectReport />
      </RequireAuth>
    ),
  },
  {
    route: "/documentation",
    component: <Documentation />,
  },
  {
    route: "/training",
    component: <Training />,
  },

  {
    route: "/register",
    component: <Registration />,
  },
  {
    route: "/login",
    component: <Login />,
  },
  {
    route: "/quick-energy",
    component: <QuickEnergy />,
  },

  {
    route: "/version",
    component: <Version />,
  },
  {
    name: "roadmap",
    route: "/cfroadmap",
    component: (
      <CFarmPage>
        <Roadmap />
        <div
          style={{
            border: "blue 1px solid",
            margin: "10px 0",
            width: "100%",
            textAlign: "center",
          }}
        >
          PlaceHolder Div
        </div>
        <CFStep
          stepLabel="This is a String Label. Below is an element fragment"
          stepNumber={1}
          helpText="this is userguide text"
        />
        <CFStep
          stepLabel={
            <>
              Define <em>Dairy</em> Types for 2011
            </>
          }
          stepNumber={1}
          helpText="this is userguide text"
        />
        <div
          style={{
            border: "blue 1px solid",
            height: "80vh",
            marginTop: "10px",
          }}
        >
          PlaceHolder Div with height 80vh
        </div>
        <CFTypography variant="h1">H1 header</CFTypography>
      </CFarmPage>
    ),
  },
  {
    route: "/animal-agriculture",
    component: (
      <RequireAuth>
        <AnimalAg />
      </RequireAuth>
    ),
  },
  {
    route: "/croplands/*",
    component: (
      <RequireAuth>
        <CroplandsHistoryAndCurrentPage />
      </RequireAuth>
    ),
  },
  {
    route: "/croplandsScenario/*",
    component: (
      <RequireAuth>
        <CroplandsScenarioPage />
      </RequireAuth>
    ),
  },
  {
    route: "/agroforestry",
    component: (
      <RequireAuth>
        <Agroforestry />
      </RequireAuth>
    ),
  },
  {
    route: "/forestry",
    component: (
      <RequireAuth>
        <Forestry />
      </RequireAuth>
    ),
  },
];

export default allRoutes;
