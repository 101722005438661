// React
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

// MUI
import { Grid } from "@mui/material";
import Stack from "@mui/material/Stack";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import CFStepMarker from "components/CFStepMarker/CFStepMarker";

import colors from "assets/theme/base/colors";

import CFInput from "components/CFInput";
// Our Components
import CFBox from "components/CFBox";
import CFTypography from "components/CFTypography";
import CFButton from "components/CFButton";

import { post } from "utils/api";
import { popupError } from "utils/generic";
import { UPDATE_MANURE, FETCH_MANURE } from "constants/api";
import CFLookupPicker from "components/CroplandsComponents/CFLookupPicker/CFLookupPicker";
import CFLoading from "components/CFLoading";
import CFNumberInput from "components/CFNumberInput/CFNumberInput";
import { getTextInput } from "./AnimalAgCommonFunc";

// Constants

export default function AnimalManure({
  id,
  yearValue,
  animalCategory,
  animalCategoryId,
  groupId,
  barns,
  housingComplete,
  anagObject,
  anagObjectChanged,
  verifyDone,
}) {
  const dispatch = useDispatch();

  const { info } = colors;
  const empty = { id: 212450, name: "Select" };

  const [expanded, setExpanded] = useState(false);

  const [anagDigester, setAnagDigester] = useState(212477);

  const [openStorage, setOpenStorage] = useState(212477);
  const [showDgstrType, setShowDgstrType] = useState(false);

  const [anagDigesterLst, setAnagDigesterLst] = useState([]);
  const [openStorageList, setOpenStorageList] = useState([]);

  const [solidLiquidSelLst, setSolidLiquidSelLst] = useState([]);

  const [solidLiquidSepSelLst, setSolidLiquidSepSelLst] = useState([]);

  const [anaerobicDigesterType, setAnaerobicDigesterType] = useState(empty);
  const [anaerobicDigesterTypeId, setAnaerobicDigesterTypeId] =
    useState(212450);
  const [anaerobicDigesterTypeList, setAnaerobicDigesterTypeList] = useState(
    []
  );
  const [loading, setLoading] = useState(false);
  const [separatorTypeList, setSeparatorTypeList] = useState([]);
  const [solidManTypes, setSolidManTypes] = useState([]);
  const [liquidManTypes, setLiquidManTypes] = useState([]);
  const [barnTypeList, setBarnTypeList] = useState([]);
  const [saveSuccessMsg, setSaveSuccessMsg] = useState(false);
  const [invalidPercentError, setInvalidPercentError] = useState(false);
  const intitalBarnState = {
    215320: 0,
    215321: 0,
    215322: 0,
    215323: 0,
    215324: 0,
    215325: 0,
    215326: 0,
    215327: 0,
    215328: 0,
    215329: 0,

    215335: 0,
    215336: 0,
    215337: 0,
    215338: 0,
    215339: 0,
    215340: 0,
    215341: 0,
    215342: 0,
    215343: 0,
    215344: 0,
    solidLiquidSel: 216076,

    solidLiquidSepSelId: 212450,
    separatorType: empty,
    percentSolidRemoved: 0,
    showSolLiqSepType: false,
    // 216076 is solid manure so if liquid
    showSolLiqType: false,
  };

  const [freeStall1BarnInputs, setFreeStall1BarnInputs] =
    useState(intitalBarnState);
  const [freeStall2BarnInputs, setFreeStall2BarnInputs] =
    useState(intitalBarnState);
  const [freeStall3BarnInputs, setFreeStall3BarnInputs] =
    useState(intitalBarnState);
  const [pitStorageBarn1Inputs, setPitStorageBarn1Inputs] =
    useState(intitalBarnState);

  const [beddedPackBarn1Inputs, setBeddedPackBarn1Inputs] =
    useState(intitalBarnState);

  const [compostingBarn1Inputs, setCompostingBarn1Inputs] =
    useState(intitalBarnState);
  const [poultryBarnInputs, setPoultryBarnInputs] = useState(intitalBarnState);

  const [dryLotBarnInputs, setDryLotBarnInputs] = useState(intitalBarnState);
  const getBarnInputs = (barn) => {
    if (barn === 216200) {
      return freeStall1BarnInputs;
    }
    if (barn === 216201) {
      return freeStall2BarnInputs;
    }
    if (barn === 216202) {
      return freeStall3BarnInputs;
    }
    if (barn === 216203) {
      return pitStorageBarn1Inputs;
    }

    if (barn === 216204) {
      return beddedPackBarn1Inputs;
    }

    if (barn === 216205) {
      return compostingBarn1Inputs;
    }

    if (barn === 216206) {
      return dryLotBarnInputs;
    }

    if (barn === 216213 || barn === 216214 || barn === 216215) {
      return poultryBarnInputs;
    }

    return {};
  };

  const getShowSolLiqType = (barn) => {
    let solidLiqManTypesSel = false;
    solidLiqManTypesSel = getBarnInputs(barn).showSolLiqType;

    return solidLiqManTypesSel;
  };
  const getSolidManSelectionVal = (barn, solid) => {
    let solidManSelectionVal = 0;
    solidManSelectionVal = getBarnInputs(barn)[solid];

    return solidManSelectionVal;
  };

  const getLiquidManSelectionVal = (barn, liquid) => {
    let liquidManSelectionVal = 0;
    liquidManSelectionVal = getBarnInputs(barn)[liquid];

    return liquidManSelectionVal;
  };
  const handleSolidManSelectionChange = (e, barn) => {
    const updatedSolidVal = getTextInput(e.target.value);
    if (barn === 216200) {
      setFreeStall1BarnInputs({
        ...freeStall1BarnInputs,
        [e.target.name]: updatedSolidVal,
      });
    } else if (barn === 216201) {
      setFreeStall2BarnInputs({
        ...freeStall2BarnInputs,
        [e.target.name]: updatedSolidVal,
      });
    } else if (barn === 216202) {
      setFreeStall3BarnInputs({
        ...freeStall3BarnInputs,
        [e.target.name]: updatedSolidVal,
      });
    } else if (barn === 216203) {
      setPitStorageBarn1Inputs({
        ...pitStorageBarn1Inputs,
        [e.target.name]: updatedSolidVal,
      });
    } else if (barn === 216204) {
      setBeddedPackBarn1Inputs({
        ...beddedPackBarn1Inputs,
        [e.target.name]: updatedSolidVal,
      });
    } else if (barn === 216205) {
      setCompostingBarn1Inputs({
        ...compostingBarn1Inputs,
        [e.target.name]: updatedSolidVal,
      });
    } else if (barn === 216206) {
      setDryLotBarnInputs({
        ...dryLotBarnInputs,
        [e.target.name]: updatedSolidVal,
      });
    } else if (barn === 216213 || barn === 216214 || barn === 216215) {
      setPoultryBarnInputs({
        ...poultryBarnInputs,
        [e.target.name]: updatedSolidVal,
      });
    }
  };

  const handleLiquidManSelectionChange = (e, barn) => {
    const updatedLiquidVal = getTextInput(e.target.value);

    if (barn === 216200) {
      setFreeStall1BarnInputs({
        ...freeStall1BarnInputs,
        [e.target.name]: updatedLiquidVal,
      });
    } else if (barn === 216201) {
      setFreeStall2BarnInputs({
        ...freeStall2BarnInputs,
        [e.target.name]: updatedLiquidVal,
      });
    } else if (barn === 216202) {
      setFreeStall3BarnInputs({
        ...freeStall3BarnInputs,
        [e.target.name]: updatedLiquidVal,
      });
    } else if (barn === 216203) {
      setPitStorageBarn1Inputs({
        ...pitStorageBarn1Inputs,
        [e.target.name]: updatedLiquidVal,
      });
    } else if (barn === 216204) {
      setBeddedPackBarn1Inputs({
        ...beddedPackBarn1Inputs,
        [e.target.name]: updatedLiquidVal,
      });
    } else if (barn === 216205) {
      setCompostingBarn1Inputs({
        ...compostingBarn1Inputs,
        [e.target.name]: updatedLiquidVal,
      });
    } else if (barn === 216206) {
      setDryLotBarnInputs({
        ...dryLotBarnInputs,
        [e.target.name]: updatedLiquidVal,
      });
    } else if (barn === 216213 || barn === 216214 || barn === 216215) {
      setPoultryBarnInputs({
        ...poultryBarnInputs,
        [e.target.name]: updatedLiquidVal,
      });
    }
  };

  const expandToggle = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleAnagDigesterChange = (event) => {
    setAnagDigester(event.target.value);

    if (event.target.value === "212477") {
      setShowDgstrType(false);
    } else {
      setShowDgstrType(true);
    }
  };
  const handleOpenStorageChange = (event) => {
    setOpenStorage(event.target.value);
  };
  const getShowRBType = (barn) => {
    let showTypeVal = false;
    showTypeVal = getBarnInputs(barn).showSolLiqSepType;

    return showTypeVal;
  };

  const getSTotal = (barnState) => {
    let totalVal = 0;
    for (let i = 215320; i <= 215329; i += 1) {
      totalVal += Number(barnState[i]);
    }
    return totalVal;
  };
  const getLTotal = (barnState) => {
    let totalVal = 0;
    for (let i = 215335; i <= 215344; i += 1) {
      totalVal += Number(barnState[i]);
    }
    return totalVal;
  };
  const totalSolidManurePercent = (barn) => {
    let solidsTotal = 0;
    if (barn === 216200) {
      solidsTotal = getSTotal(freeStall1BarnInputs);
    } else if (barn === 216201) {
      solidsTotal = getSTotal(freeStall2BarnInputs);
    } else if (barn === 216202) {
      solidsTotal = getSTotal(freeStall3BarnInputs);
    } else if (barn === 216203) {
      solidsTotal = getSTotal(pitStorageBarn1Inputs);
    } else if (barn === 216204) {
      solidsTotal = getSTotal(beddedPackBarn1Inputs);
    } else if (barn === 216205) {
      solidsTotal = getSTotal(compostingBarn1Inputs);
    } else if (barn === 216206) {
      solidsTotal = getSTotal(dryLotBarnInputs);
    } else if (barn === 216213 || barn === 216214 || barn === 216215) {
      solidsTotal = getSTotal(poultryBarnInputs);
    }

    return solidsTotal;
  };
  const totalLiquidManurePercent = (barn) => {
    let liquidsTotal = 0;
    if (barn === 216200) {
      liquidsTotal = getLTotal(freeStall1BarnInputs);
    } else if (barn === 216201) {
      liquidsTotal = getLTotal(freeStall2BarnInputs);
    } else if (barn === 216202) {
      liquidsTotal = getLTotal(freeStall3BarnInputs);
    } else if (barn === 216203) {
      liquidsTotal = getLTotal(pitStorageBarn1Inputs);
    } else if (barn === 216204) {
      liquidsTotal = getLTotal(beddedPackBarn1Inputs);
    } else if (barn === 216205) {
      liquidsTotal = getLTotal(compostingBarn1Inputs);
    } else if (barn === 216206) {
      liquidsTotal = getLTotal(dryLotBarnInputs);
    } else if (barn === 216213 || barn === 216214 || barn === 216215) {
      liquidsTotal = getLTotal(poultryBarnInputs);
    }

    return liquidsTotal;
  };

  const getSolidLiquidError = (barnInputs) => {
    let solidsTotal = 0;
    let liquidsTotal = 0;

    for (let i = 215320; i <= 215329; i += 1) {
      solidsTotal += Number(barnInputs[i]);
    }

    for (let i = 215335; i <= 215344; i += 1) {
      liquidsTotal += Number(barnInputs[i]);
    }

    if (
      parseInt(barnInputs.solidLiquidSepSelId, 10) === 212477 &&
      parseInt(barnInputs.solidLiquidSel, 10) === 216075
    ) {
      return solidsTotal === 100;
    }
    if (
      parseInt(barnInputs.solidLiquidSepSelId, 10) === 212477 &&
      parseInt(barnInputs.solidLiquidSel, 10) === 216076
    ) {
      return liquidsTotal === 100;
    }
    if (parseInt(barnInputs.solidLiquidSepSelId, 10) === 212476) {
      return (
        parseInt(barnInputs.separatorType.id, 10) !== 212450 &&
        parseFloat(barnInputs.percentSolidRemoved) !== 0 &&
        solidsTotal === 100 &&
        liquidsTotal === 100
      );
    }
    return false;
  };

  const showManureStep = (barn) => {
    let stepNum = "7";
    if (showDgstrType && getShowRBType(barn)) {
      stepNum = "8";
    } else if (showDgstrType && !getShowRBType(barn)) {
      stepNum = "7";
    } else if (!showDgstrType && getShowRBType(barn)) {
      stepNum = "5";
    } else if (!showDgstrType && !getShowRBType(barn)) {
      stepNum = "4";
    }

    return stepNum;
  };
  const getBarnState = (event) => {
    const solLiqVal = parseInt(event.target.value, 10);
    const solLiqBarnState = {
      solidLiquidSel: solLiqVal,
      215320: 0,
      215321: 0,
      215322: 0,
      215323: 0,
      215324: 0,
      215325: 0,
      215326: 0,
      215327: 0,
      215328: 0,
      215329: 0,

      215335: 0,
      215336: 0,
      215337: 0,
      215338: 0,
      215339: 0,
      215340: 0,
      215341: 0,
      215342: 0,
      215343: 0,
      215344: 0,
      // 216076 is solid manure so if liquid
      showSolLiqType: solLiqVal !== 216076,
    };
    return solLiqBarnState;
  };

  const handleSolidLiquidSelChange = (event, barn) => {
    if (barn === 216200) {
      setFreeStall1BarnInputs({
        ...freeStall1BarnInputs,
        ...getBarnState(event),
      });
    } else if (barn === 216201) {
      setFreeStall2BarnInputs({
        ...freeStall2BarnInputs,
        ...getBarnState(event),
      });
    } else if (barn === 216202) {
      setFreeStall3BarnInputs({
        ...freeStall3BarnInputs,
        ...getBarnState(event),
      });
    } else if (barn === 216203) {
      setPitStorageBarn1Inputs({
        ...pitStorageBarn1Inputs,
        ...getBarnState(event),
      });
    } else if (barn === 216204) {
      setBeddedPackBarn1Inputs({
        ...beddedPackBarn1Inputs,
        ...getBarnState(event),
      });
    } else if (barn === 216205) {
      setCompostingBarn1Inputs({
        ...compostingBarn1Inputs,
        ...getBarnState(event),
      });
    } else if (barn === 216206) {
      setDryLotBarnInputs({ ...dryLotBarnInputs, ...getBarnState(event) });
    } else if (barn === 216213 || barn === 216214 || barn === 216215) {
      setPoultryBarnInputs({ ...poultryBarnInputs, ...getBarnState(event) });
    }
  };

  const getSolidLiquidSelValue = (barn) => {
    let solidLiquidSelVal = 0;
    solidLiquidSelVal = parseInt(getBarnInputs(barn).solidLiquidSel, 10);

    return solidLiquidSelVal;
  };

  // Return value associated with separator id from table 4-36
  const getBySepId = (sepId) =>
    separatorTypeList.find((a) => a.id === sepId).value;

  const handlePrntSldsRmvdChange = (event, val, barn) => {
    const solidRemovedPercent = getTextInput(val);
    if (barn === 216200) {
      setFreeStall1BarnInputs({
        ...freeStall1BarnInputs,
        percentSolidRemoved: solidRemovedPercent,
      });
    } else if (barn === 216201) {
      setFreeStall2BarnInputs({
        ...freeStall2BarnInputs,
        percentSolidRemoved: solidRemovedPercent,
      });
    } else if (barn === 216202) {
      setFreeStall3BarnInputs({
        ...freeStall3BarnInputs,
        percentSolidRemoved: solidRemovedPercent,
      });
    } else if (barn === 216203) {
      setPitStorageBarn1Inputs({
        ...pitStorageBarn1Inputs,
        percentSolidRemoved: solidRemovedPercent,
      });
    } else if (barn === 216204) {
      setBeddedPackBarn1Inputs({
        ...beddedPackBarn1Inputs,
        percentSolidRemoved: solidRemovedPercent,
      });
    } else if (barn === 216205) {
      setCompostingBarn1Inputs({
        ...compostingBarn1Inputs,
        percentSolidRemoved: solidRemovedPercent,
      });
    } else if (barn === 216206) {
      setDryLotBarnInputs({
        ...dryLotBarnInputs,
        percentSolidRemoved: solidRemovedPercent,
      });
    } else if (barn === 216213 || barn === 216214 || barn === 216215) {
      setPoultryBarnInputs({
        ...poultryBarnInputs,
        percentSolidRemoved: solidRemovedPercent,
      });
    }
  };

  const getPercentSolidsRemovedVal = (barn) => {
    let prcntSldsRemvdVal = 0;
    prcntSldsRemvdVal = getBarnInputs(barn).percentSolidRemoved;

    return prcntSldsRemvdVal;
  };

  const getSolidLiquidSepSelValue = (barn) => {
    let solidLiquidSepSelVal = 0;
    solidLiquidSepSelVal = parseInt(
      getBarnInputs(barn).solidLiquidSepSelId,
      10
    );
    return solidLiquidSepSelVal;
  };
  const getSeparatorType = (barn) => {
    let separatorTypeVal = 0;
    separatorTypeVal = getBarnInputs(barn).separatorType;

    return separatorTypeVal;
  };

  const handleSolidLiquidSepSelChange = (event, barn) => {
    const sepSelVal = parseInt(event.target.value, 10);
    if (barn === 216200) {
      setFreeStall1BarnInputs({
        ...freeStall1BarnInputs,

        solidLiquidSepSelId: sepSelVal,
        showSolLiqSepType: sepSelVal !== 212477,
      });
    } else if (barn === 216201) {
      setFreeStall2BarnInputs({
        ...freeStall1BarnInputs,

        solidLiquidSepSelId: sepSelVal,
        showSolLiqSepType: sepSelVal !== 212477,
      });
    } else if (barn === 216202) {
      setFreeStall3BarnInputs({
        ...freeStall1BarnInputs,

        solidLiquidSepSelId: sepSelVal,
        showSolLiqSepType: sepSelVal !== 212477,
      });
    } else if (barn === 216203) {
      setPitStorageBarn1Inputs({
        ...pitStorageBarn1Inputs,

        solidLiquidSepSelId: sepSelVal,
        showSolLiqSepType: sepSelVal !== 212477,
      });
    } else if (barn === 216204) {
      setBeddedPackBarn1Inputs({
        ...beddedPackBarn1Inputs,

        solidLiquidSepSelId: sepSelVal,
        showSolLiqSepType: sepSelVal !== 212477,
      });
    } else if (barn === 216205) {
      setCompostingBarn1Inputs({
        ...compostingBarn1Inputs,

        solidLiquidSepSelId: sepSelVal,
        showSolLiqSepType: sepSelVal !== 212477,
      });
    } else if (barn === 216206) {
      setDryLotBarnInputs({
        ...dryLotBarnInputs,

        solidLiquidSepSelId: sepSelVal,
        showSolLiqSepType: sepSelVal !== 212477,
      });
    } else if (barn === 216213 || barn === 216214 || barn === 216215) {
      setPoultryBarnInputs({
        ...poultryBarnInputs,

        solidLiquidSepSelId: sepSelVal,
        showSolLiqSepType: sepSelVal !== 212477,
      });
    }
  };

  const validateManurePanel = () => {
    let dataIncompete = false;
    verifyDone("grey");

    if (parseInt(anagDigester, 10) === 212476) {
      if (anaerobicDigesterTypeId !== 212450) {
        dataIncompete = true;
        verifyDone("green");
      }
    } else {
      const noMissingData = [];
      if (barns.some((e) => e.id === 216200)) {
        const freeStall1DataComplete =
          getSolidLiquidError(freeStall1BarnInputs);

        noMissingData.push(freeStall1DataComplete);
      }
      if (barns.some((e) => e.id === 216201)) {
        const freeStall2DataComplete =
          getSolidLiquidError(freeStall2BarnInputs);
        noMissingData.push(freeStall2DataComplete);
      }
      if (barns.some((e) => e.id === 216202)) {
        const freeStall3DataComplete =
          getSolidLiquidError(freeStall3BarnInputs);
        noMissingData.push(freeStall3DataComplete);
      }
      if (barns.some((e) => e.id === 216203)) {
        const pitStorageDataComplete = getSolidLiquidError(
          pitStorageBarn1Inputs
        );

        noMissingData.push(pitStorageDataComplete);
      }
      if (barns.some((e) => e.id === 216204)) {
        const beddedPackDataComplete = getSolidLiquidError(
          beddedPackBarn1Inputs
        );
        noMissingData.push(beddedPackDataComplete);
      }
      if (barns.some((e) => e.id === 216205)) {
        const compostDataComplete = getSolidLiquidError(compostingBarn1Inputs);
        noMissingData.push(compostDataComplete);
      }
      if (barns.some((e) => e.id === 216206)) {
        const dryLotDataComplete = getSolidLiquidError(dryLotBarnInputs);
        noMissingData.push(dryLotDataComplete);
      }
      if (
        barns.some((e) => e.id === 216213 || e.id === 216214 || e.id === 216215)
      ) {
        const poultryDataComplete = getSolidLiquidError(poultryBarnInputs);
        noMissingData.push(poultryDataComplete);
      }

      dataIncompete = noMissingData.every((v) => v === true);
      if (dataIncompete) {
        verifyDone("green");
      } else {
        verifyDone("grey");
      }
    }
    return dataIncompete;
  };
  const getBarnData = (barn, sepType) => {
    let barnDetails = {};

    barnDetails = {
      215320: barn.manureSolidTreatmentData.solidCompostInVesselPercent,
      215321: barn.manureSolidTreatmentData.solidCompostStaticPilePercent,
      215322: barn.manureSolidTreatmentData.solidCompostIntWindrowPercent,
      215323: barn.manureSolidTreatmentData.solidCompostPassWindrowPercent,
      215324: barn.manureSolidTreatmentData.solidTempStackLongTermPercent,
      215325: barn.manureSolidTreatmentData.solidTempStackCoveredPercent,
      215326: barn.manureSolidTreatmentData.solidTempStackBulkAgentPercent,
      215327: barn.manureSolidTreatmentData.solidTempStackAdditivesPercent,
      215328: barn.manureSolidTreatmentData.solidHoldPondPercent,
      215329: barn.manureSolidTreatmentData.solidRemovedOffSitePercent,

      215335: barn.manureLiquidTreatmentData.liquidStorageTankCoveredPercent,
      215336:
        barn.manureLiquidTreatmentData.liquidStorageTankUncovWCrustPercent,
      215337:
        barn.manureLiquidTreatmentData.liquidStorageTankUncovWOCrustPercent,
      215338: barn.manureLiquidTreatmentData.liquidAerobicLagoonForcedPercent,
      215339: barn.manureLiquidTreatmentData.liquidAerobicLagoonNaturalPercent,
      215340:
        barn.manureLiquidTreatmentData.liquidAnaerobicLagoonCoveredPercent,
      215341:
        barn.manureLiquidTreatmentData.liquidAnaerobicLagoonUncovWCrustPercent,
      215342:
        barn.manureLiquidTreatmentData.liquidAnaerobicLagoonUncovWOCrustPercent,
      215343: barn.manureLiquidTreatmentData.liquidHoldPondPercent,
      215344: barn.manureLiquidTreatmentData.liquidRemovedOffSitePercent,

      solidLiquidSel: barn.solidLiquiSel,

      solidLiquidSepSelId: barn.solidLiquiSepSelId,
      separatorType: sepType,
      percentSolidRemoved: barn.percentSlds,
      showSolLiqSepType:
        barn.solidLiquiSepSelId !== 212477 &&
        barn.solidLiquiSepSelId !== 212450,
      showSolLiqType:
        barn.solidLiquiSepSelId !== 212477 &&
        barn.solidLiquiSepSelId !== 212450,
    };
    return barnDetails;
  };
  const fetchManure = () => {
    setLoading(true);
    const popObj = {
      popid: id,
      anagcatid: animalCategoryId,
    };
    post(FETCH_MANURE, popObj)
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          setLoading(false);
        } else {
          const manureObj = res.data;
          const colorArr = [];
          setSolidManTypes(manureObj.solidManureTypeList);
          setLiquidManTypes(manureObj.liquidManureTypeList);

          setSeparatorTypeList(manureObj.separatorTypeList);
          setAnaerobicDigesterTypeList(manureObj.anaerobicDigstrList);
          setOpenStorageList(manureObj.yesNoSel);

          setAnagDigesterLst(manureObj.yesNoSel);
          setSolidLiquidSelLst(manureObj.manureType);
          setSolidLiquidSepSelLst(manureObj.yesNoSel);

          setBarnTypeList(manureObj.barnType);

          const adigstrSel =
            manureObj.anaerobicDigetserSel === false ? 212477 : 212476;
          const showDigSel = manureObj.anaerobicDigetserSel !== false;
          const opnStrgeSel =
            manureObj.openStorageSel === false ? 212477 : 212476;
          setShowDgstrType(showDigSel);
          setAnagDigester(adigstrSel);
          setOpenStorage(opnStrgeSel);
          const anagDigesterType = {
            id: manureObj.anaerobicDigetserTypeId,
            name: manureObj.anaerobicDigetserTypeName,
          };

          setAnaerobicDigesterType(anagDigesterType);

          setAnaerobicDigesterTypeId(manureObj.anaerobicDigetserTypeId);

          manureObj.manurBarnDetailsData.forEach((barn) => {
            const sepType = {
              id: barn.sepTypeId,
              name: barn.sepTypeName,
            };

            if (barn.barnTypeId === 216200) {
              setFreeStall1BarnInputs(getBarnData(barn, sepType));
            } else if (barn.barnTypeId === 216201) {
              setFreeStall2BarnInputs(getBarnData(barn, sepType));
            } else if (barn.barnTypeId === 216202) {
              setFreeStall3BarnInputs(getBarnData(barn, sepType));
            } else if (barn.barnTypeId === 216203) {
              setPitStorageBarn1Inputs(getBarnData(barn, sepType));
            } else if (barn.barnTypeId === 216204) {
              setBeddedPackBarn1Inputs(getBarnData(barn, sepType));
            } else if (barn.barnTypeId === 216205) {
              setCompostingBarn1Inputs(getBarnData(barn, sepType));
            } else if (barn.barnTypeId === 216206) {
              setDryLotBarnInputs(getBarnData(barn, sepType));
            } else if (
              barn.barnTypeId === 216213 ||
              barn.barnTypeId === 216214 ||
              barn.barnTypeId === 216215
            ) {
              setPoultryBarnInputs(getBarnData(barn, sepType));
            }
          });
          if (manureObj.manureComplete) {
            verifyDone("green");
          } else {
            verifyDone("grey");
          }
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const updateManure = () => {
    setLoading(true);

    const manureObj = {
      anaerbcDigester: anagDigester,
      anaerbcDigesterType: anaerobicDigesterTypeId,
      opnStrge: openStorage,
      freeStall1Inputs: freeStall1BarnInputs,
      freeStall2Inputs: freeStall2BarnInputs,
      freeStall3Inputs: freeStall3BarnInputs,
      pitStorage1Inputs: pitStorageBarn1Inputs,
      beddedPack1Inputs: beddedPackBarn1Inputs,
      composting1Inputs: compostingBarn1Inputs,
      drylotInputs: dryLotBarnInputs,
      poultryInputs: poultryBarnInputs,
      barnsArr: barns.map((a) => a.id),
      popid: id,
    };
    post(UPDATE_MANURE, manureObj)
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          setLoading(false);
        } else {
          setSaveSuccessMsg(true);

          // if (validateManurePanel()) {
          //
          verifyDone("green");
          // } else {
          //   verifyDone("grey");
          // }
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchManure();
  }, []);

  return (
    <CFBox width="100%">
      {loading && <CFLoading local />}
      <Grid container item>
        {/* Main form */}
        <CFBox width="100%">
          <Grid container direction="row" xs={12} sm={12} md={12}>
            <Grid container direction="row">
              <Grid item container xs={12} id="step2box" alignItems="center">
                <Grid
                  item
                  container
                  xs={12}
                  alignItems="center"
                  direction="row"
                >
                  {/* When pasture Range is selected, no need to ask for any
                  manure details */}

                  {barns.length === 0 && housingComplete && (
                    <Grid item xs={12}>
                      <CFTypography
                        sx={{
                          fontSize: "1em",
                          color: info.dullFocus,
                          padding: "9.5px",
                        }}
                      >
                        You have selected pasture range as your housing type,
                        hence you do not have to define anything on this panel.
                      </CFTypography>
                    </Grid>
                  )}
                  {barns.length > 0 && (
                    <Grid item xs={12}>
                      <CFBox
                        width="100%"
                        style={{
                          display: "flex",
                          marginTop: "1em",
                          marginBottom: "1em",
                        }}
                      >
                        <CFStepMarker
                          label="1"
                          style={{ fontSize: "0.5em", marginRight: "1em" }}
                        />
                        <CFTypography
                          sx={{
                            fontSize: "14px",
                            width: "50%",
                            color: info.dullFocus,
                          }}
                        >
                          Is an anaerobic digester used as the primary manure
                          treatment method?
                        </CFTypography>
                        <RadioGroup
                          row
                          name="anagDigesterRadioButton"
                          value={anagDigester}
                          onChange={handleAnagDigesterChange}
                        >
                          {anagDigesterLst.map((anagDgstr) => (
                            <FormControlLabel
                              key={anagDgstr.id}
                              value={anagDgstr.id}
                              control={<Radio />}
                              label={anagDgstr.name}
                            />
                          ))}
                        </RadioGroup>
                      </CFBox>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    {showDgstrType && barns.length > 0 && (
                      <CFBox
                        width="100%"
                        style={{
                          display: "flex",
                          marginTop: "1em",
                          marginBottom: "1em",
                        }}
                      >
                        <CFStepMarker
                          label="2"
                          style={{ fontSize: "0.5em", marginRight: "1em" }}
                        />
                        <CFTypography
                          sx={{
                            fontSize: "14px",
                            width: "30%",
                            color: info.dullFocus,
                          }}
                        >
                          Select the type of anaerobic digester:
                        </CFTypography>
                        <CFLookupPicker
                          key="anDigType"
                          name="anDigType"
                          required
                          style={{ width: "50%" }}
                          value={anaerobicDigesterType}
                          onChange={(event, newValue) => {
                            setAnaerobicDigesterTypeId(newValue.id);

                            setAnaerobicDigesterType({
                              name: newValue.name,
                              id: newValue.id,
                            });
                          }}
                          options={anaerobicDigesterTypeList}
                        />
                      </CFBox>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    {showDgstrType && barns.length > 0 && (
                      <CFBox
                        width="100%"
                        style={{
                          display: "flex",
                          marginTop: "1em",
                          marginBottom: "1em",
                        }}
                      >
                        <CFStepMarker
                          label="3"
                          style={{ fontSize: "0.5em", marginRight: "1em" }}
                        />
                        <CFTypography
                          sx={{
                            fontSize: "14px",
                            width: "50%",
                            color: info.dullFocus,
                          }}
                        >
                          Is the digestate stored in open air storage after the
                          digestion process?
                        </CFTypography>
                        <RadioGroup
                          row
                          name="openStorageRadioButton"
                          value={openStorage}
                          onChange={handleOpenStorageChange}
                        >
                          {openStorageList.map((opnStrge) => (
                            <FormControlLabel
                              key={opnStrge.id}
                              value={opnStrge.id}
                              control={<Radio />}
                              label={opnStrge.name}
                            />
                          ))}
                        </RadioGroup>
                      </CFBox>
                    )}
                  </Grid>
                  {!showDgstrType &&
                    barns.map((barn) => (
                      <CFBox width="80%" marginBottom=".5em">
                        <Accordion
                          expanded={expanded === barn.id}
                          onChange={expandToggle(barn.id)}
                        >
                          <AccordionSummary
                            expandIcon={
                              <ExpandMoreIcon
                                sx={{ color: colors.text.light }}
                              />
                            }
                            aria-controls="animalDetailsCard-content"
                            id="animalDetailsCard-header"
                            sx={{
                              backgroundColor: colors.highlight.main,
                              boxShadow: "0px 1px 3px 0px #bbb",
                              borderRadius: ".3em",
                            }}
                          >
                            <Grid
                              item
                              container
                              alignItems="center"
                              justifyContent="flex-start"
                            >
                              {/* Year */}

                              <CFBox
                                xs={4}
                                sx={{
                                  textAlign: "left",
                                  marginLeft: "1em",
                                  marginRight: "1em",
                                  fontSize: "medium",
                                  fontWeight: "550",
                                  color: colors.text.light,
                                }}
                              >
                                {barn.name}
                              </CFBox>
                            </Grid>
                          </AccordionSummary>

                          <AccordionDetails>
                            <>
                              <Grid item xs={12}>
                                <CFBox>
                                  <CFTypography
                                    sx={{
                                      fontSize: "12px",
                                      width: "100%",
                                      color: info.dullFocus,
                                    }}
                                  >
                                    <p style={{ color: "red" }}>
                                      If using a separator:
                                    </p>
                                    Allocate manure solids entering the solid
                                    and liquid manure storage or treatment from{" "}
                                    {barn.name}. First, enter the percent of
                                    solids from the solid-liquid separator
                                    entering each solid manure treatment or
                                    storage system below. Next, allocate the
                                    remaining solids from the solid-liquid
                                    separator remaining in the liquid fraction
                                    to the liquid manure treatment systems.
                                    <p style={{ color: "red" }}>
                                      If not using a separator:
                                    </p>
                                    Allocate manure solids entering the storage
                                    or treatment from {barn.name}. Enter the
                                    percent of solids entering each manure
                                    storage or treatment system below.
                                  </CFTypography>
                                </CFBox>
                                <CFBox
                                  width="100%"
                                  style={{
                                    display: "flex",
                                    marginTop: "1em",
                                    marginBottom: "1em",
                                  }}
                                >
                                  <CFStepMarker
                                    label="2"
                                    style={{
                                      fontSize: "0.5em",
                                      marginRight: "1em",
                                    }}
                                  />

                                  <CFTypography
                                    sx={{
                                      fontSize: "14px",
                                      width: "50%",
                                      color: info.dullFocus,
                                    }}
                                  >
                                    Do you use a solid liquid separator?
                                  </CFTypography>
                                  <RadioGroup
                                    row
                                    name="solidLiquidSepRadioButton"
                                    value={getSolidLiquidSepSelValue(barn.id)}
                                    onChange={(e) =>
                                      handleSolidLiquidSepSelChange(e, barn.id)
                                    }
                                  >
                                    {solidLiquidSepSelLst.map((solLiqSep) => (
                                      <FormControlLabel
                                        key={solLiqSep.id}
                                        value={solLiqSep.id}
                                        control={<Radio />}
                                        label={solLiqSep.name}
                                      />
                                    ))}
                                  </RadioGroup>
                                </CFBox>
                              </Grid>

                              <Grid item xs={12}>
                                {getShowRBType(barn.id) && (
                                  <CFBox
                                    width="100%"
                                    style={{
                                      display: "flex",
                                      marginTop: "1em",
                                      marginBottom: "1em",
                                    }}
                                  >
                                    <CFStepMarker
                                      label="3"
                                      style={{
                                        fontSize: "0.5em",
                                        marginRight: "1em",
                                      }}
                                    />
                                    <CFTypography
                                      sx={{
                                        fontSize: "14px",
                                        width: "50%",
                                        color: info.dullFocus,
                                      }}
                                    >
                                      What is the separator type?
                                    </CFTypography>
                                    <CFLookupPicker
                                      key="sepType"
                                      name="sepType"
                                      required
                                      style={{ width: "40%" }}
                                      value={getSeparatorType(barn.id)}
                                      onChange={(event, newValue) => {
                                        if (barn.id === 216200) {
                                          setFreeStall1BarnInputs({
                                            ...freeStall1BarnInputs,
                                            separatorType: {
                                              name: newValue.name,
                                              id: newValue.id,
                                            },
                                            percentSolidRemoved: getBySepId(
                                              newValue.id
                                            ),
                                          });
                                        } else if (barn.id === 216201) {
                                          setFreeStall2BarnInputs({
                                            ...freeStall2BarnInputs,
                                            separatorType: {
                                              name: newValue.name,
                                              id: newValue.id,
                                            },
                                            percentSolidRemoved: getBySepId(
                                              newValue.id
                                            ),
                                          });
                                        } else if (barn.id === 216202) {
                                          setFreeStall3BarnInputs({
                                            ...freeStall3BarnInputs,
                                            separatorType: {
                                              name: newValue.name,
                                              id: newValue.id,
                                            },
                                            percentSolidRemoved: getBySepId(
                                              newValue.id
                                            ),
                                          });
                                        } else if (barn.id === 216203) {
                                          setPitStorageBarn1Inputs({
                                            ...pitStorageBarn1Inputs,
                                            separatorType: {
                                              name: newValue.name,
                                              id: newValue.id,
                                            },
                                            percentSolidRemoved: getBySepId(
                                              newValue.id
                                            ),
                                          });
                                        } else if (barn.id === 216204) {
                                          setBeddedPackBarn1Inputs({
                                            ...beddedPackBarn1Inputs,
                                            separatorType: {
                                              name: newValue.name,
                                              id: newValue.id,
                                            },
                                            percentSolidRemoved: getBySepId(
                                              newValue.id
                                            ),
                                          });
                                        } else if (barn.id === 216205) {
                                          setCompostingBarn1Inputs({
                                            ...compostingBarn1Inputs,
                                            separatorType: {
                                              name: newValue.name,
                                              id: newValue.id,
                                            },
                                            percentSolidRemoved: getBySepId(
                                              newValue.id
                                            ),
                                          });
                                        } else if (barn.id === 216206) {
                                          setDryLotBarnInputs({
                                            ...dryLotBarnInputs,
                                            separatorType: {
                                              name: newValue.name,
                                              id: newValue.id,
                                            },
                                            percentSolidRemoved: getBySepId(
                                              newValue.id
                                            ),
                                          });
                                        } else if (
                                          barn.id === 216213 ||
                                          barn.id === 216214 ||
                                          barn.id === 216215
                                        ) {
                                          setPoultryBarnInputs({
                                            ...poultryBarnInputs,
                                            separatorType: {
                                              name: newValue.name,
                                              id: newValue.id,
                                            },
                                            percentSolidRemoved: getBySepId(
                                              newValue.id
                                            ),
                                          });
                                        }
                                      }}
                                      options={separatorTypeList}
                                    />
                                  </CFBox>
                                )}
                              </Grid>

                              <Grid item xs={12}>
                                {getShowRBType(barn.id) && (
                                  <CFBox
                                    width="100%"
                                    style={{
                                      display: "flex",
                                      marginTop: "1em",
                                      marginBottom: "1em",
                                    }}
                                  >
                                    <CFStepMarker
                                      label="4"
                                      style={{
                                        fontSize: "0.5em",
                                        marginRight: "1em",
                                      }}
                                    />
                                    <CFTypography
                                      sx={{
                                        fontSize: "14px",
                                        width: "50%",
                                        color: info.dullFocus,
                                      }}
                                    >
                                      Solids removed (%) :
                                    </CFTypography>
                                    <CFNumberInput
                                      sx={{
                                        padding: "10px 10px 10px 0",
                                        borderColor: info.dullFocusTransparent,
                                        borderStyle: "solid",
                                        borderWidth: "1px",
                                      }}
                                      color="primary"
                                      id="prcntSldsRmvd"
                                      name="prcntSldsRmvd"
                                      value={getPercentSolidsRemovedVal(
                                        barn.id
                                      )}
                                      defaultValue={0}
                                      min={0}
                                      max={100}
                                      onChange={(e, val) =>
                                        handlePrntSldsRmvdChange(
                                          e,
                                          val,
                                          barn.id
                                        )
                                      }
                                      placeholder=""
                                      style={{
                                        width: "20%",

                                        marginRight: "2em",
                                      }}
                                    />
                                  </CFBox>
                                )}
                              </Grid>

                              {!getShowRBType(barn.id) && ( // barn.solidLiquidSel
                                <Grid item xs={12}>
                                  <CFBox
                                    width="100%"
                                    style={{
                                      display: "flex",
                                      marginTop: "1em",
                                      marginBottom: "1em",
                                    }}
                                  >
                                    <CFStepMarker
                                      label={!barn.id ? "5" : "3"}
                                      style={{
                                        fontSize: "0.5em",
                                        marginRight: "1em",
                                      }}
                                    />
                                    <CFTypography
                                      sx={{
                                        fontSize: "14px",
                                        width: "60%",
                                        color: info.dullFocus,
                                      }}
                                    >
                                      Is the manure handled as a solid or a
                                      liquid?
                                    </CFTypography>
                                    <RadioGroup
                                      row
                                      name="solidLiquidRadioButton"
                                      value={getSolidLiquidSelValue(barn.id)}
                                      onChange={(e) =>
                                        handleSolidLiquidSelChange(e, barn.id)
                                      }
                                    >
                                      {solidLiquidSelLst.map((solLiq) => (
                                        <FormControlLabel
                                          key={solLiq.id}
                                          value={solLiq.id}
                                          control={<Radio />}
                                          label={solLiq.name}
                                        />
                                      ))}
                                    </RadioGroup>
                                  </CFBox>
                                </Grid>
                              )}

                              {getSolidLiquidSepSelValue(barn.id) === 212477 &&
                                (getSolidLiquidSelValue(barn.id) === 216075 ||
                                  getSolidLiquidSelValue(barn.id) ===
                                    216076) && (
                                  <Grid item xs={12}>
                                    <CFBox
                                      width="100%"
                                      style={{ display: "flex" }}
                                    >
                                      <CFStepMarker
                                        label={showManureStep(barn.id)}
                                        style={{
                                          fontSize: "0.5em",
                                          marginRight: "1em",
                                        }}
                                      />
                                      <CFBox
                                        sx={{
                                          backgroundColor:
                                            colors.background.header,
                                          paddingTop: "10px",
                                          paddingBottom: "10px",
                                          borderRadius: "0.3em",
                                          boxShadow: "0px 1px 3px 0px #bbb",
                                          marginBottom: "1em",
                                          width: "50%",
                                        }}
                                      >
                                        <CFTypography
                                          sx={{
                                            fontSize: "16px",
                                            fontWeight: "bold",
                                            color: info.dullFocus,
                                          }}
                                          style={{ marginLeft: "2em" }}
                                        >
                                          Manure System
                                        </CFTypography>
                                      </CFBox>
                                      <CFBox
                                        sx={{
                                          backgroundColor:
                                            colors.background.header,
                                          paddingTop: "10px",
                                          paddingBottom: "10px",
                                          marginLeft: "1em",
                                          boxShadow: "0px 1px 3px 0px #bbb",
                                          borderRadius: "0.3em",
                                          marginBottom: "1em",
                                          width: "30%",
                                        }}
                                      >
                                        <CFTypography
                                          sx={{
                                            fontSize: "14px",
                                            fontWeight: "bold",
                                            color: info.dullFocus,
                                          }}
                                          style={{ marginLeft: "2em" }}
                                        >
                                          % of total manure entering system
                                        </CFTypography>
                                      </CFBox>
                                    </CFBox>
                                  </Grid>
                                )}
                              {getSolidLiquidSepSelValue(barn.id) ===
                                212476 && (
                                <Grid item xs={12}>
                                  <CFBox
                                    width="100%"
                                    style={{ display: "flex" }}
                                  >
                                    <CFStepMarker
                                      label={showManureStep(barn.id)}
                                      style={{
                                        fontSize: "0.5em",
                                        marginRight: "1em",
                                      }}
                                    />
                                    <CFBox
                                      sx={{
                                        backgroundColor:
                                          colors.background.header,
                                        paddingTop: "10px",
                                        paddingBottom: "10px",
                                        borderRadius: "0.3em",

                                        boxShadow: "0px 1px 3px 0px #bbb",
                                        marginBottom: "1em",
                                        width: "50%",
                                      }}
                                    >
                                      <CFTypography
                                        sx={{
                                          fontSize: "16px",
                                          fontWeight: "bold",
                                          color: info.dullFocus,
                                        }}
                                        style={{ marginLeft: "2em" }}
                                      >
                                        Manure System : Solids
                                      </CFTypography>
                                    </CFBox>
                                    <CFBox
                                      sx={{
                                        backgroundColor:
                                          colors.background.header,
                                        borderRadius: "0.3em",
                                        paddingTop: "10px",
                                        paddingBottom: "10px",
                                        marginLeft: "1em",
                                        boxShadow: "0px 1px 3px 0px #bbb",
                                        marginBottom: "1em",
                                        width: "30%",
                                      }}
                                    >
                                      <CFTypography
                                        sx={{
                                          fontSize: "14px",
                                          fontWeight: "bold",
                                          color: info.dullFocus,
                                        }}
                                        style={{ marginLeft: "2em" }}
                                      >
                                        % of total solids entering system
                                      </CFTypography>
                                    </CFBox>
                                  </CFBox>
                                </Grid>
                              )}
                              {(getSolidLiquidSepSelValue(barn.id) === 212476 ||
                                (getSolidLiquidSepSelValue(barn.id) ===
                                  212477 &&
                                  getSolidLiquidSelValue(barn.id) ===
                                    216075)) && (
                                <CFBox>
                                  <Grid item xs={12}>
                                    {solidManTypes.map((solidManType) => (
                                      <CFBox
                                        width="100%"
                                        style={{ display: "flex" }}
                                      >
                                        <CFBox
                                          sx={{
                                            backgroundColor:
                                              colors.background.header,
                                            paddingTop: "10px",
                                            paddingBottom: "10px",
                                            boxShadow: "0px 1px 3px 0px #bbb",
                                            borderRadius: "0.3em",
                                            marginBottom: "1em",
                                            marginLeft: "1.5em",
                                            width: "50%",
                                          }}
                                        >
                                          <CFTypography
                                            sx={{
                                              fontSize: "16px",

                                              color: info.dullFocus,
                                            }}
                                            style={{ marginLeft: "2em" }}
                                          >
                                            {solidManType.name}
                                          </CFTypography>
                                        </CFBox>
                                        <CFBox
                                          sx={{
                                            backgroundColor:
                                              colors.background.header,
                                            paddingTop: "10px",
                                            paddingBottom: "10px",
                                            marginLeft: "1em",
                                            boxShadow: "0px 1px 3px 0px #bbb",
                                            marginBottom: "1em",
                                            borderRadius: "0.3em",
                                            width: "30%",
                                            display: "flex",
                                          }}
                                        >
                                          <CFInput
                                            sx={{
                                              padding: "10px",
                                              fieldset: {
                                                borderColor: info.dullFocus,
                                                borderStyle: "solid",
                                                borderWidth: "2px",
                                              },
                                            }}
                                            defaultValue={0}
                                            type="number"
                                            color="primary"
                                            id={solidManType.id}
                                            name={solidManType.id}
                                            value={getSolidManSelectionVal(
                                              barn.id,
                                              solidManType.id
                                            )}
                                            InputProps={{
                                              inputProps: {
                                                min: 0,
                                                max: 100,
                                              },
                                            }}
                                            onChange={(e) =>
                                              handleSolidManSelectionChange(
                                                e,
                                                barn.id
                                              )
                                            }
                                            placeholder=""
                                            style={{
                                              width: "60%",
                                              marginLeft: "2em",
                                              marginRight: "0.5em",
                                            }}
                                          />
                                          <CFTypography
                                            sx={{
                                              fontSize: "8px",
                                              color: info.dullFocus,
                                              fontWeight: "bold",
                                              float: "left",
                                              padding: "3px",
                                              marginTop: "1em",
                                            }}
                                          >
                                            (%)
                                          </CFTypography>
                                        </CFBox>
                                      </CFBox>
                                    ))}
                                  </Grid>
                                  <Grid item xs={9}>
                                    <CFTypography
                                      sx={{
                                        fontSize: "20px",
                                        fontWeight: "bold",
                                        color: info.dullFocus,
                                      }}
                                      style={{ float: "right" }}
                                    >
                                      = {totalSolidManurePercent(barn.id)}%
                                    </CFTypography>
                                  </Grid>
                                </CFBox>
                              )}
                              {getSolidLiquidSepSelValue(barn.id) === 212476 &&
                                totalSolidManurePercent(barn.id) !== 100 && (
                                  <Grid item xs={12}>
                                    <CFBox id="percentSolidsError">
                                      <CFTypography
                                        sx={{
                                          fontSize: "15px",

                                          color: "red",
                                          fontWeight: "bold",
                                          marginLeft: "1.7em",
                                          marginBottom: "0.5em",
                                          marginTop: "0.5em",
                                        }}
                                      >
                                        Percent of total solids entering system
                                        should be equal to 100 !
                                      </CFTypography>
                                    </CFBox>
                                  </Grid>
                                )}
                              {getSolidLiquidSepSelValue(barn.id) ===
                                212476 && (
                                <Grid item xs={12}>
                                  <CFBox
                                    width="100%"
                                    style={{ display: "flex" }}
                                  >
                                    <CFBox
                                      sx={{
                                        backgroundColor:
                                          colors.background.header,
                                        paddingTop: "10px",
                                        paddingBottom: "10px",
                                        marginLeft: "1.5em",
                                        boxShadow: "0px 1px 3px 0px #bbb",
                                        borderRadius: "0.3em",
                                        marginBottom: "1em",
                                        width: "50%",
                                      }}
                                    >
                                      <CFTypography
                                        sx={{
                                          fontSize: "16px",
                                          fontWeight: "bold",
                                          color: info.dullFocus,
                                        }}
                                        style={{ marginLeft: "2em" }}
                                      >
                                        Manure System : Liquids
                                      </CFTypography>
                                    </CFBox>
                                    <CFBox
                                      sx={{
                                        backgroundColor:
                                          colors.background.header,
                                        paddingTop: "10px",
                                        paddingBottom: "10px",
                                        marginLeft: "1em",
                                        boxShadow: "0px 1px 3px 0px #bbb",
                                        borderColor: "black",
                                        borderRadius: "0.3em",
                                        marginBottom: "1em",
                                        width: "30%",
                                      }}
                                    >
                                      <CFTypography
                                        sx={{
                                          fontSize: "14px",
                                          fontWeight: "bold",
                                          color: info.dullFocus,
                                        }}
                                        style={{ marginLeft: "2em" }}
                                      >
                                        % of total liquids entering system
                                      </CFTypography>
                                    </CFBox>
                                  </CFBox>
                                </Grid>
                              )}
                              {(getSolidLiquidSepSelValue(barn.id) === 212476 ||
                                (getSolidLiquidSepSelValue(barn.id) ===
                                  212477 &&
                                  getSolidLiquidSelValue(barn.id) ===
                                    216076)) && (
                                <CFBox>
                                  <Grid item xs={12}>
                                    {liquidManTypes.map((liquidManType) => (
                                      <CFBox
                                        width="100%"
                                        style={{ display: "flex" }}
                                      >
                                        <CFBox
                                          sx={{
                                            backgroundColor:
                                              colors.background.header,
                                            paddingTop: "10px",
                                            paddingBottom: "10px",
                                            boxShadow: "0px 1px 3px 0px #bbb",
                                            borderRadius: "0.3em",
                                            marginBottom: "1em",
                                            marginLeft: "1.5em",
                                            width: "50%",
                                          }}
                                        >
                                          <CFTypography
                                            sx={{
                                              fontSize: "16px",

                                              color: info.dullFocus,
                                            }}
                                            style={{ marginLeft: "2em" }}
                                          >
                                            {liquidManType.name}
                                          </CFTypography>
                                        </CFBox>
                                        <CFBox
                                          sx={{
                                            backgroundColor:
                                              colors.background.header,
                                            paddingTop: "10px",
                                            paddingBottom: "10px",
                                            marginLeft: "1em",
                                            marginRight: "2em",
                                            boxShadow: "0px 1px 3px 0px #bbb",
                                            borderRadius: "0.3em",
                                            marginBottom: "1em",
                                            width: "30%",
                                            display: "flex",
                                          }}
                                        >
                                          <CFInput
                                            sx={{
                                              padding: "10px",

                                              fieldset: {
                                                borderColor: info.dullFocus,
                                                borderStyle: "solid",
                                                borderWidth: "2px",
                                              },
                                            }}
                                            defaultValue={0}
                                            color="primary"
                                            id={liquidManType.id}
                                            name={liquidManType.id}
                                            value={getLiquidManSelectionVal(
                                              barn.id,
                                              liquidManType.id
                                            )}
                                            onChange={(e) =>
                                              handleLiquidManSelectionChange(
                                                e,
                                                barn.id
                                              )
                                            }
                                            inputProps={{
                                              type: "number",
                                              min: 0,
                                              max: 100,
                                            }}
                                            placeholder=""
                                            style={{
                                              width: "60%",
                                              marginLeft: "2em",
                                              marginRight: "0.5em",
                                            }}
                                          />
                                          <CFTypography
                                            sx={{
                                              fontSize: "8px",
                                              color: info.dullFocus,
                                              fontWeight: "bold",
                                              float: "left",
                                              padding: "3px",
                                              marginTop: "1em",
                                            }}
                                          >
                                            (%)
                                          </CFTypography>
                                        </CFBox>
                                      </CFBox>
                                    ))}
                                  </Grid>
                                  <Grid item xs={9}>
                                    <CFTypography
                                      sx={{
                                        fontSize: "20px",
                                        fontWeight: "bold",
                                        color: info.dullFocus,
                                      }}
                                      style={{ float: "right" }}
                                    >
                                      = {totalLiquidManurePercent(barn.id)}%
                                    </CFTypography>
                                  </Grid>
                                </CFBox>
                              )}

                              <Grid item xs={12}>
                                {getSolidLiquidSepSelValue(barn.id) ===
                                  212477 &&
                                  (getSolidLiquidSelValue(barn.id) === 216075 ||
                                    getSolidLiquidSelValue(barn.id) ===
                                      216076) &&
                                  totalSolidManurePercent(barn.id) !== 100 &&
                                  totalLiquidManurePercent(barn.id) !== 100 && (
                                    <CFBox id="percentManureError">
                                      <CFTypography
                                        sx={{
                                          fontSize: "15px",
                                          color: "red",
                                          fontWeight: "bold",
                                          marginLeft: "1.7em",

                                          marginBottom: "0.5em",
                                          marginTop: "0.5em",
                                        }}
                                      >
                                        Percent of total manure entering system
                                        should be equal to 100 !
                                      </CFTypography>
                                    </CFBox>
                                  )}
                                {getSolidLiquidSepSelValue(barn.id) ===
                                  212476 &&
                                  totalLiquidManurePercent(barn.id) !== 100 && (
                                    <CFBox id="percentLiquidsError">
                                      <CFTypography
                                        sx={{
                                          fontSize: "15px",
                                          color: "red",
                                          fontWeight: "bold",
                                          marginLeft: "1.7em",

                                          marginBottom: "0.5em",
                                          marginTop: "0.5em",
                                        }}
                                      >
                                        Percent of total liquids entering system
                                        should be equal to 100 !
                                      </CFTypography>
                                    </CFBox>
                                  )}
                              </Grid>
                            </>
                          </AccordionDetails>
                        </Accordion>
                      </CFBox>
                    ))}
                </Grid>
              </Grid>

              {barns.length > 0 && (
                <Grid container py={2} px={2} justifyContent="space-around">
                  <Grid item xs={12} sm={12} md={12}>
                    <CFButton
                      type="submit"
                      sx={{
                        float: "right",
                      }}
                      color="progress"
                      onClick={updateManure}
                      disabled={!validateManurePanel()}
                    >
                      Save
                    </CFButton>
                  </Grid>
                </Grid>
              )}
              {saveSuccessMsg && (
                <Grid container justifyContent="space-around">
                  <Grid item xs={2} sm={2} md={2} alignItems="center">
                    <CFBox id="housingSuccessMessage">
                      <CFTypography
                        sx={{
                          fontSize: "15px",
                          color: "green",

                          marginBottom: "0.5em",
                          marginTop: "0.5em",
                        }}
                      >
                        Save was successful.
                      </CFTypography>
                    </CFBox>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </CFBox>
      </Grid>
    </CFBox>
  );
}
