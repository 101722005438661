// React
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

// MUI
import { Grid, Dialog, IconButton, Tooltip, Checkbox } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  ArrowCircleDownTwoTone,
  ArrowCircleRightTwoTone,
  Check,
  Close,
} from "@mui/icons-material";
import { pink, red, grey } from "@mui/material/colors";
import colors from "assets/theme/base/colors";

// Our Components
import CFBox from "components/CFBox";
import CFTypography from "components/CFTypography";
import CFButton from "components/CFButton";
import CFLoading from "components/CFLoading";
import CFStep from "components/CFarmParts/CFStep";
import { jsonCopy, popupError } from "utils/generic";

import { COPY_MANAGEMENT_YEAR } from "constants/api";
import { post } from "utils/api";
import { fetchUser } from "actions/user";
import { croplandTableStyle } from "pages/Croplands/CroplandStyle";
import CheckboxGrid from "../../../../../components/CroplandsComponents/SubComponents/CheckboxGrid";

export default function CopyYearsCard({
  isOpen,
  setIsOpen,
  selectedField,
  selectedCropYear,
  fetchManagement,
  fieldAndCropYearFinishedData,
  scenarioName,
  upperSave,
}) {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user);

  const [loadingMessage, setLoading] = useState("");
  const [checkedCells, setCheckedCells] = useState({});

  // I opted for a single project state object where we just overwrite the necessary fields,
  // instead of separate state variables for name, activities, etc

  // On form close
  const handleClose = () => {
    setIsOpen(false);
  };

  const DontSaveChanges = () => {
    handleClose();
  };

  const templateClicked = () => {
    console.log("template clicked");
  };

  const saveClicked = () => {
    /// now format the checkedCells into the copy api request

    const retArray = [];
    //  console.log("checkedcells", checkedCells);
    for (const akey in checkedCells) {
      if (Object.prototype.hasOwnProperty.call(checkedCells, akey))
        for (const b in checkedCells[akey]) {
          if (Object.prototype.hasOwnProperty.call(checkedCells[akey], b))
            if (checkedCells[akey][b] === true) {
              const year = b.replace("HasData", "");
              retArray.push(`${akey}_${year}`);
            }
        }
    }
    // console.log("retArray", retArray);
    setLoading("Saving...");
    upperSave().then(() => {
      if (selectedField === 0) {
        return;
      }
      post(COPY_MANAGEMENT_YEAR, {
        user: currentUser,
        copyYear: selectedCropYear,
        fieldid: selectedField,
        copyDestinations: retArray,
        scenarioName,
      })
        .then((res) => {
          //  console.log("management: got back", res);
          if (res.error) {
            popupError(`Copy Years Save Error ${res.error}`, dispatch);
            setLoading("");
          } else {
            dispatch(fetchUser(currentUser));
            fetchManagement();
            setLoading("");
            handleClose();
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading("");
        });
    });
  };
  const { palette } = createTheme();
  const theme = createTheme({
    palette: {
      hasData: palette.augmentColor({ color: pink }),
    },
  });
  return (
    <Dialog open={isOpen} sx={{ padding: "3em", zIndex: 5 }} maxWidth="xl">
      {loadingMessage !== "" && <CFLoading message={loadingMessage} local />}{" "}
      <IconButton
        onClick={() => handleClose()}
        style={{ position: "absolute", right: 0, margin: ".5em", padding: 0 }}
      >
        <Tooltip title="Cancel Copy and Close">
          <Close />
        </Tooltip>
      </IconButton>
      <Grid container item xs={12} sx={{ width: "100%" }}>
        <CFBox sx={{ padding: "2em", width: "100%" }}>
          <Grid container direction="row">
            {/* Window Header */}

            <Grid item container xs={11}>
              {" "}
              <Grid item container xs={6}>
                <CFStep
                  stepLabel="Copy year's management"
                  helpText={
                    <div
                      style={{
                        overflow: "scroll",
                        height: "750px",
                        maxHeight: "60vh",
                      }}
                    >
                      <style>{croplandTableStyle}</style>
                      Users may copy all crops and their respective management
                      from one field and year to another field(s) and year(s).
                      Please review the copy window symbol descriptions below.
                      Copying management to a field/year with existing
                      management will overwrite the original management, and
                      while copied management can be edited,{" "}
                      <strong>
                        it cannot be <em>undone</em>
                      </strong>
                      .
                      <table>
                        <tbody>
                          <tr>
                            <td>Copy Window Symbol</td>
                            <td>Description</td>
                          </tr>
                          <tr>
                            <td>
                              <Check
                                aria-label="check image"
                                sx={{
                                  color: grey[900],
                                  "&.Mui-checked": {
                                    color: grey[900],
                                  },
                                }}
                                checked
                              />
                            </td>
                            <td>
                              Year to be copied. All crops and their management
                              will be copied <strong>from</strong> this year.
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <ArrowCircleRightTwoTone
                                aria-label="arrow image"
                                color="primary"
                                fontSize="medium"
                              />
                            </td>
                            <td>
                              Select all years in this field. Click again to
                              deselect all years. Users may deselect individual
                              years.
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <ArrowCircleDownTwoTone
                                aria-label="arrow image"
                                color="primary"
                                fontSize="medium"
                                style={{
                                  marginTop: ".6em",
                                  marginLeft: ".5em",
                                  color: "#65653f",
                                }}
                              />
                            </td>
                            <td>
                              Select all fields in this year. Click again to
                              deselect all fields for this year. Users may
                              deselect individual years.
                            </td>
                          </tr>
                          <ThemeProvider theme={theme}>
                            <tr>
                              <td>
                                <Checkbox aria-label="check image" checked />
                              </td>
                              <td>
                                No existing management data in this year/field.
                                Management data will be copied to this
                                field/year.
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <Checkbox
                                  aria-label="check image"
                                  sx={{
                                    color: red[800],
                                    "&.Mui-checked": {
                                      color: red[600],
                                    },
                                  }}
                                  checked
                                />
                              </td>
                              <td>
                                This year/field has management data. Management
                                data will be copied to this field/year,
                                overwriting the existing data. Users cannot undo
                                this action once _Save and Copy_ is selected.
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <Checkbox
                                  aria-label="check image"
                                  sx={{
                                    color: red[800],
                                    "&.Mui-checked": {
                                      color: red[600],
                                    },
                                  }}
                                />
                              </td>
                              <td>
                                This year/field has management data. If it
                                remains unselected, management data will not be
                                copied to this year.
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <Checkbox aria-label="check image" />
                              </td>
                              <td>
                                This year/field has no management data. If it
                                remains unselected, management data will not be
                                copied to this year.
                              </td>
                            </tr>
                          </ThemeProvider>
                        </tbody>
                      </table>
                    </div>
                  }
                />
              </Grid>
              <Grid item container xs={4}>
                <CFBox
                  sx={{
                    position: "absolute",
                    right: 0,
                    margin: ".5em",
                    marginRight: "2em",
                    border: "1px solid black",
                    backgroundColor: "light grey",
                  }}
                >
                  <Grid item container xs={12}>
                    <Grid item container xs={12}>
                      <Grid
                        item
                        container
                        xs={1}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <span style={{ marginLeft: "1em" }}>
                          <ThemeProvider theme={theme}>
                            <Check
                              sx={{
                                // marginLeft: "1em",
                                color: grey[900],
                                "&.Mui-checked": {
                                  color: grey[900],
                                },
                              }}
                              checked
                            />
                          </ThemeProvider>
                        </span>
                      </Grid>
                      <Grid item container xs={11}>
                        <CFTypography variant="h6" pl={2} py={2}>
                          year to be copied
                        </CFTypography>
                      </Grid>
                    </Grid>
                    <Grid item container xs={12}>
                      <Grid item container xs={1}>
                        <ThemeProvider theme={theme}>
                          <Checkbox
                            sx={{
                              color: red[800],
                              "&.Mui-checked": {
                                color: red[600],
                              },
                            }}
                          />
                        </ThemeProvider>
                      </Grid>
                      <Grid item container xs={11}>
                        <CFTypography variant="h6" pl={2} py={2}>
                          year has data
                        </CFTypography>
                      </Grid>
                    </Grid>
                  </Grid>
                </CFBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item container xs={5.5}>
              <CFTypography variant="h6" py={2}>
                {`If you would like to copy management details for all crops planted in a single year to another year/field for the ${scenarioName} scenario, select the other years and fields in the table below. Copying management to another field/year where management is already entered will overwrite the existing management. Copied management cannot be undone, but users may make changes to management following the copy.`}
              </CFTypography>
            </Grid>
            <Grid container item xs={12}>
              <CheckboxGrid
                Data={fieldAndCropYearFinishedData}
                selectedCropYear={selectedCropYear}
                selectedField={selectedField}
                checkedCells={checkedCells}
                setCheckedCells={setCheckedCells}
              />
            </Grid>
          </Grid>
          <Grid container py={2} px={2} justifyContent="space-between">
            <CFButton color="secondary" onClick={() => DontSaveChanges()}>
              Cancel
            </CFButton>

            <CFButton color="progress" onClick={saveClicked}>
              Save and Copy
            </CFButton>
          </Grid>
        </CFBox>
      </Grid>
    </Dialog>
  );
}
