import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Grid, Select, MenuItem } from "@mui/material";

import { GET_WATER_HOLDING_CAPACITY_DATA } from "constants/api";
import { post } from "utils/api";
import { popupError, useQuery } from "utils/generic";
import { updateLoading } from "store/reducers/mask";

import CFTypography from "components/CFTypography";

import USDAlogo from "assets/cfBranding/800px-USDA_logo.png";
import CSUlogo from "assets/cfBranding/csu_logo01_noWords.png";
import USBlogo from "assets/cfBranding/usb-logo-md-green_noWords.png";
import SHIlogo from "assets/cfBranding/white_soilhealth_noWords.png";

import WaterHoldingTable from "./WhcTable";
import WhcMap from "./WhcMap";
import {
  getSoilComponentArea,
  distinguishedColors,
} from "./waterHolding.utils";

function LocationWaterHoldingCapacity({ location, soilComponents }) {
  return (
    <Grid container direction="column">
      <Grid container direction="row" alignItems="center" py={2}>
        <Grid item xs={4}>
          <WhcMap location={location} soilComponents={soilComponents} />
        </Grid>
        <Grid item xs={8} py={2} pl={2}>
          <WaterHoldingTable
            isSummary
            location={location}
            soilComponents={soilComponents}
            title="Summary Results"
          />
        </Grid>
      </Grid>
      {soilComponents.map((soilElement, i) => {
        const { muArea, locArea } = getSoilComponentArea(soilElement, location);

        if (muArea > 100 || muArea / locArea > 0.01) {
          return (
            <Grid
              container
              direction="row"
              alignItems="center"
              py={2}
              key={soilElement.map_unit_id}
            >
              <Grid item xs={4}>
                <WhcMap
                  location={location}
                  soilComponents={[soilElement]}
                  color={distinguishedColors[i % distinguishedColors.length]}
                />
              </Grid>
              <Grid item xs={8} pl={2}>
                <WaterHoldingTable
                  isSummary={false}
                  location={location}
                  soilComponents={[soilElement]}
                  title={`Soil Map Unit: ${soilElement.soilComponent.Texture} - ${soilElement.map_unit_id}`}
                  color={distinguishedColors[i % distinguishedColors.length]}
                />
              </Grid>
            </Grid>
          );
        }
        return null;
      })}
    </Grid>
  );
}

function WaterHoldingCapacity({ waterHoldingInfo, setWaterHoldingInfo }) {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user);
  const query = useQuery();

  // Data
  const [waterHoldingData, setWaterHoldingData] = useState({});

  const FetchWaterHoldingData = () => {
    dispatch(updateLoading({ loading: true }));

    post(GET_WATER_HOLDING_CAPACITY_DATA, {
      user: currentUser,
      projectId: query.get("project"),
    })
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          dispatch(updateLoading({ loading: false }));
        } else {
          const resData = JSON.parse(res.data);
          setWaterHoldingData(() => {
            const retVal = { ...resData };
            const newSoilComponents = retVal.soilComponents.map((sc) => ({
              ...sc,
              soilComponent: JSON.parse(sc.soilComponent),
              daycent_results: JSON.parse(sc.daycent_results),
            }));

            retVal.soilComponents = newSoilComponents;
            return retVal;
          });
          setWaterHoldingInfo(() => {
            const retVal = { ...resData };
            const newSoilComponents = retVal.soilComponents.map((sc) => ({
              ...sc,
              soilComponent: JSON.parse(sc.soilComponent),
              daycent_results: JSON.parse(sc.daycent_results),
            }));

            retVal.soilComponents = newSoilComponents;
            return retVal;
          });
        }

        dispatch(updateLoading({ loading: false }));
      })
      .catch((err) => {
        console.log(err);
        dispatch(updateLoading({ loading: false }));
      });
  };
  useEffect(() => {
    if (waterHoldingInfo) {
      setWaterHoldingData(waterHoldingInfo);
    } else FetchWaterHoldingData();
  }, []);

  // Data display selection
  const [selectedData, setSelectedData] = useState(-1);

  return (
    <Grid container direction="column" py={2}>
      {/* Field Selection */}
      <Grid item container direction="row" px={2} pb={2} xs={2}>
        <CFTypography variant="h6" fontWeight="regular" pr={1}>
          {"Select Field: "}
        </CFTypography>
        {/* Display select */}
        <Select
          autoWidth
          value={selectedData}
          onChange={(e) => setSelectedData(e.target.value)}
        >
          {/* Default value */}
          <MenuItem disabled value={-1}>
            Options...
          </MenuItem>
          {/* Available scenarios */}
          {waterHoldingData.locations &&
            waterHoldingData.locations.map((field) => {
              if (!field.NeedsFetching) {
                return (
                  <MenuItem key={field.Id} value={field.Id}>
                    {field.Name}
                  </MenuItem>
                );
              }
              return null;
            })}
        </Select>
      </Grid>
      {/* Chart Title */}
      <Grid
        item
        container
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        xs={1}
        px={2}
      >
        <CFTypography variant="h3">
          {selectedData === -1
            ? "Available Water Holding Capacity Report"
            : `Available Water Holding Capacity Report: ${
                waterHoldingData?.locations.find(
                  (field) => field.Id === selectedData
                ).Name
              }`}
        </CFTypography>
        {/* TODO: Do we still need these logos? */}
        <Grid>
          <img
            style={{
              padding: "0 .5em",
              height: "50px",
              verticalAlign: "middle",
            }}
            src={SHIlogo}
            alt="SHI"
          />
          <img
            style={{
              padding: "0 .5em",
              height: "50px",
              verticalAlign: "middle",
            }}
            src={CSUlogo}
            alt="CSU"
          />
          <img
            style={{
              padding: "0 .5em",
              height: "50px",
              verticalAlign: "middle",
            }}
            src={USBlogo}
            alt="USB"
          />
          <img
            style={{
              padding: "0 .5em",
              height: "50px",
              verticalAlign: "middle",
            }}
            src={USDAlogo}
            alt="USDA"
          />
        </Grid>
      </Grid>
      <Grid item container direction="column" alignItems="start" xs={10}>
        {selectedData === -1 && (
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            minHeight="300px"
          >
            <CFTypography
              variant="h4"
              pb={4}
              align="center"
              sx={{ margin: "3em" }}
            >
              Select a Field to Begin
            </CFTypography>
          </Grid>
        )}
        {selectedData !== -1 &&
          waterHoldingData?.locations.map((field) => {
            if (field.Id === selectedData) {
              const soilComponents = waterHoldingData?.soilComponents.filter(
                (x) => x.location_id === field.Id
              );

              return (
                <Grid p={2} sx={{ width: "100%" }} key={field.Id}>
                  <LocationWaterHoldingCapacity
                    currentUser={currentUser}
                    location={field}
                    soilComponents={soilComponents}
                  />
                </Grid>
              );
            }
            return null;
          })}
      </Grid>
    </Grid>
  );
}
export default WaterHoldingCapacity;
