/* eslint-disable prettier/prettier */
import { createSlice } from "@reduxjs/toolkit";
import { loadingMaskText } from "constants/pageText";

// initial state
const initialState = {
  loading: false,
  message: loadingMaskText,
};

const mask = createSlice({
  name: "mask",
  initialState,
  reducers: {
    updateLoading(state, action) {
      // eslint-disable-next-line no-param-reassign
      state.loading =
        action.payload.loading != null
          ? action.payload.loading
          : initialState.loading;
      // eslint-disable-next-line no-param-reassign
      state.message =
        action.payload.message != null
          ? action.payload.message
          : initialState.message;
    },
  },
});

export default mask.reducer;

export const { updateLoading } = mask.actions;
