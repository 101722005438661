import CFarmPage from "components/CFarmParts/CFarmPage";
import CFBox from "components/CFBox";
import backgroundBarn from "assets/cFarmImages/MainPages/corner_barn.png";
import CFTypography from "components/CFTypography";

const LegacyTrainingPage = (
  <div style={{ marginTop: "50px" }}>
    <CFTypography variant="h1" pb=".5em">
      Trainings
    </CFTypography>
    <h5>
      Please contact us via email with any training requests at{" "}
      <a
        rel="noopener noreferrer"
        target="_blank"
        href="mailto:appnrel@colostate.edu"
        style={{ color: "#000080" }}
      >
        appnrel@colostate.edu
      </a>
      .
    </h5>
    <iframe
      title="CfarmTrainingCalandar"
      src="https://calendar.google.com/calendar/embed?title=COMET-Farm%20Events&amp;showCalendars=0&amp;showTz=0&amp;height=600&amp;wkst=1&amp;bgcolor=%23339999&amp;src=nagle22csu%40gmail.com&amp;color=%231B887A&amp;ctz=America%2FDenver"
      style={{ borderWidth: "0", marginTop: "10px" }}
      width="675"
      height="600"
    />
  </div>
);

function Training() {
  return (
    <CFarmPage>
      <CFBox
        sx={{
          width: "100%",
          paddingLeft: "5%",
          backgroundImage: `url(${backgroundBarn})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right center",
        }}
      >
        {LegacyTrainingPage}
      </CFBox>
    </CFarmPage>
  );
}
export default Training;
