import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  Slide,
  Select,
  MenuItem,
} from "@mui/material";
import {
  DataGridPremium,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from "@mui/x-data-grid-premium";

// Post
import { FORESTRY_UPDATE_DETAILS } from "constants/api";
import { updateLoading } from "store/reducers/mask";
import { popupError } from "utils/generic";
import { post } from "utils/api";

import colors from "assets/theme/base/colors";
import CFBox from "components/CFBox";
import UserGuidePopup from "components/CFUserGuidePopup/UserGuidePopup";
import CFButton from "components/CFButton";
import CFTypography from "components/CFTypography";
import { fetchUser } from "actions/user";

function CopyManagement({
  showCopy,
  updateShowCopy,
  forestryOp,
  projectChanged,
}) {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user);

  // Source stand, handle change, and check to see which stands have complete management info
  const [sourceStand, setSourceStand] = useState({});
  const SourceStandChange = (e) => {
    forestryOp.OperationStands.forEach((stand) => {
      if (stand.StandName === e.target.value) {
        const newStand = {
          Name: stand.StandName,
          Area: stand.Area,
          LocationId: stand.Id,
          ForestType: {
            Id: stand.LocationDetails.ForestType.Id,
            Name: stand.LocationDetails.ForestType.Name,
          },
          Origin: {
            Id: stand.LocationDetails.Origin.Id,
            Name: stand.LocationDetails.Origin.Name,
          },
          StandAge: stand.LocationDetails.StandAge,
          Volume: stand.LocationDetails.Volume,
          MerchVolume: stand.LocationDetails.MerchVolume,
          Prescription: {
            Id: stand.LocationDetails.Prescription.Id,
            Name: stand.LocationDetails.Prescription.Name,
          },
          ActivityYears: stand.LocationDetails.ActivityYears,
        };

        setSourceStand(newStand);
      }
    });
  };
  const DataComplete = (stand) => {
    let result = false;

    result = stand.LocationDetails.LocationId !== 0;
    result = stand.LocationDetails.ForestType.Id !== 0;
    result = stand.LocationDetails.Origin.Id !== 0;
    result = !(
      stand.LocationDetails.StandAge === 0 &&
      stand.LocationDetails.Volume === 0 &&
      stand.LocationDetails.MerchVolume === 0
    );
    result = stand.LocationDetails.Size !== 0;
    result = stand.LocationDetails.Prescription.Id !== 0;
    result = !(
      (stand.LocationDetails.Prescription.Id === 212706 &&
        stand.LocationDetails.ActivityYears === "") ||
      (stand.LocationDetails.Prescription.Id === 212705 &&
        stand.LocationDetails.ActivityYears !== "")
    );

    return result;
  };

  const [selectedStands, setSelectedStands] = useState([]);

  // Copy source stand management info to all selected stands
  const UpdateDetails = (id, size) => {
    dispatch(updateLoading({ loading: true }));

    const details = {
      LocationId: id,
      ForestTypeId: sourceStand.ForestType.Id,
      OriginId: sourceStand.Origin.Id,
      StandAge: sourceStand.StandAge,
      Volume: sourceStand.Volume,
      MerchantableVolume: sourceStand.MerchVolume,
      Size: size,
      PrescriptionId: sourceStand.Prescription.Id,
      ActivityYears: sourceStand.ActivityYears,
    };

    post(FORESTRY_UPDATE_DETAILS, {
      UserId: currentUser.id,
      ManagementDetails: details,
    })
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          dispatch(updateLoading({ loading: false }));
        } else {
          projectChanged(true);
          dispatch(fetchUser(currentUser));
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(updateLoading({ loading: false }));
      })
      .finally(() => {
        dispatch(updateLoading({ loading: false }));
      });
  };
  const HandleCopy = () => {
    forestryOp.OperationStands.forEach((stand) => {
      if (selectedStands.includes(stand.Id)) {
        UpdateDetails(stand.Id, stand.Area);
      }
    });

    updateShowCopy(false);
  };

  const [columns, setColumns] = useState([
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      flex: 0.25,
      resizable: false,
      headerClassName: "forestry-column-header--theme",
    },
    {
      field: "standName",
      headerName: "Stand Name",
      headerClassName: "forestry-column-header--theme",
      flex: 1,
      resizable: false,
    },
    {
      field: "standArea",
      headerName: "Stand Area",
      headerClassName: "forestry-column-header--theme",
      type: "number",
      flex: 0.5,
      resizable: false,
    },
  ]);

  // Stand rows (minus selected)
  function GenerateRows() {
    const tempRows = [];

    if (
      sourceStand.Name !== undefined &&
      forestryOp.OperationStands.length > 0
    ) {
      forestryOp.OperationStands.forEach((stand) => {
        if (stand.StandName !== sourceStand.Name) {
          const newRow = {
            id: stand.Id,
            standName: stand.StandName,
            standArea: stand.Area,
          };

          tempRows.push(newRow);
        }
      });
    }

    return tempRows;
  }
  const [rows, setRows] = useState([]);
  useEffect(() => {
    setRows(GenerateRows());
  }, [sourceStand]);

  return (
    <Dialog
      open={showCopy}
      fullWidth
      maxWidth="md"
      TransitionComponent={Slide}
      sx={{ zIndex: 5 }}
    >
      {/* Title */}
      <CFTypography variant="h4" color="secondary" p={2}>
        Copy Forestry Management Details
      </CFTypography>
      {/* Body */}
      <DialogContent dividers>
        <CFTypography variant="body2" fontWeight="regular" pb={2}>
          Follow the instructions below to select a stand to copy management
          details to other stands.
        </CFTypography>
        {/* Select source stand */}
        <Grid container direction="row" alignItems="center" pb={1}>
          <Grid item xs={3}>
            <UserGuidePopup
              label="Select Source Stand: "
              content="Select from the list of stands with complete management details. If no stands are available, then no stands have complete management information to copy."
              variant="h6"
              fontWeight="bold"
            />
          </Grid>
          <Grid item xs={4} px={2}>
            <Select
              value={sourceStand.Name ? sourceStand.Name : "placeholder"}
              sx={{ width: "100%" }}
              onChange={(e) => SourceStandChange(e)}
            >
              <MenuItem disabled value="placeholder">
                Stands...
              </MenuItem>
              {forestryOp.OperationStands.length > 0 &&
                forestryOp.OperationStands.map((stand) => {
                  if (DataComplete(stand)) {
                    return (
                      <MenuItem value={stand.StandName} key={stand.Id}>
                        {stand.StandName}
                      </MenuItem>
                    );
                  }
                  return null;
                })}
            </Select>
          </Grid>
        </Grid>
        {/* Data table of other stands */}
        <CFBox
          sx={{
            height: 300,
            width: "100%",
            "& .forestry-column-header--theme": {
              fontSize: "1.2rem",
              color: "white.main",
              backgroundColor: colors.primary.main,
            },
          }}
        >
          <DataGridPremium
            rows={rows}
            columns={columns}
            checkboxSelection
            rowSelectionModel={selectedStands}
            onRowSelectionModelChange={(newModel) =>
              setSelectedStands(newModel)
            }
          />
        </CFBox>
      </DialogContent>
      {/* Action Buttons */}
      <DialogActions>
        <Grid container justifyContent="space-between">
          <CFButton
            color="primary"
            variant="gradient"
            onClick={() => updateShowCopy(false)}
          >
            Close
          </CFButton>
          <CFButton
            color="warning"
            variant="gradient"
            onClick={HandleCopy}
            disabled={
              selectedStands.length === 0 || sourceStand.Name === undefined
            }
          >
            Copy
          </CFButton>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
export default CopyManagement;
