import { forwardRef } from "react"; // prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Custom styles for CFInput
import CFInputRoot from "components/CFInput/CFInputRoot";

const CFInput = forwardRef(({ error, success, disabled, ...rest }, ref) => (
  <CFInputRoot
    {...rest}
    ref={ref}
    ownerState={{ error, success, disabled }}
    sx={{
      backgroundColor: "#fff",
      borderRadius: ".3em",
    }}
  />
));

// Setting default values for the props of CFInput
CFInput.defaultProps = {
  error: false,
  success: false,
  disabled: false,
};

// Typechecking props for the CFInput
CFInput.propTypes = {
  error: PropTypes.bool,
  success: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default CFInput;
