import { forwardRef } from "react";

import {
  Unstable_NumberInput as NumberInput,
  numberInputClasses,
} from "@mui/base/Unstable_NumberInput";
import { styled } from "@mui/system";

// This is essentially the demo NumberInput object from the MUI website, without any fancy styling

const StyledInputRoot = styled("div")(
  () => `
  border: 1px solid ${"#fff"};
  border-radius: 8px;

  background: ${"#fff"};

  display: grid;
  grid-template-columns: 1fr 25px;
  grid-template-rows: 1fr 1fr;
  overflow: hidden;
`
);

const StyledInputElement = styled("input")(
  () => `
  line-height: 1.5;
  grid-column: 1/2;
  grid-row: 1/3;

  color: ${"#000"};
  background: inherit;
  
  margin-left: 10px;
  border: none;
  border-radius: inherit;
  padding-left: 5px;
  outline: 0;
  overflow: hidden;
`
);

const StyledButton = styled("button")(
  () => `
  width: 18px;
  margin-left: 8px;
  margin-right: 1px;
  background: ${"#fff"};
  border: 0;

  &:hover {
    background: ${"#ededed"};
    cursor: pointer;
  }

  &.${numberInputClasses.incrementButton} {
    grid-column: 3/3;
    grid-row: 1/2;
  }

  &.${numberInputClasses.decrementButton} {
    grid-column: 3/3;
    grid-row: 2/3;
  }
`
);

const InputAdornment = styled("div")(
  () => `
  margin-right: 20px;
  display: inline-flex;
  font-size: 15px;
  align-items: center;
  justify-content: center;
  grid-column: 2/3;
  grid-row: 1/3;
  color: ${"#8c8c8c"};
`
);

const StyledNumberInput = forwardRef((props, ref) => (
  <NumberInput
    slots={{
      root: StyledInputRoot,
      input: StyledInputElement,
      incrementButton: StyledButton,
      decrementButton: StyledButton,
    }}
    slotProps={{
      incrementButton: {
        children: "⏶",
      },
      decrementButton: {
        children: "⏷",
      },
    }}
    endAdornment={
      props.adornment ? (
        <InputAdornment>{props.adornment}</InputAdornment>
      ) : null
    }
    {...props}
    ref={ref}
  />
));

export default function CFNumberInput({
  value,
  min,
  step,
  adornment,
  ...rest
}) {
  return (
    <StyledNumberInput
      value={value}
      min={min}
      step={step}
      adornment={adornment}
      aria-label="CFNumberInput"
      placeholder="Enter a number…"
      {...rest}
    />
  );
}
