// React
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

// MUI
import { Grid } from "@mui/material";
import Stack from "@mui/material/Stack";

import ArrowCircleRightTwoToneIcon from "@mui/icons-material/ArrowCircleRightTwoTone";
import Tooltip from "@mui/material/Tooltip";
import { set } from "lodash/fp";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import colors from "assets/theme/base/colors";
import { margin } from "@mui/system";
import CFInput from "components/CFInput";
// Our Components
import CFBox from "components/CFBox";
import CFTypography from "components/CFTypography";
import CFButton from "components/CFButton";
import { updateLoading } from "store/reducers/mask";
import { post } from "utils/api";
import { popupError } from "utils/generic";
import { UPDATE_GOAT_GEST_LACT, FETCH_GOAT_GEST_LACT } from "constants/api";
import CFLoading from "components/CFLoading";
import { getTextInput } from "./AnimalAgCommonFunc";

// Constants

export default function GoatGestLact({
  id,
  yearValue,
  animalCategory,
  animalCategoryId,
  groupId,
  anagObject,
  anagObjectChanged,
  verifyDone,
  gestExists,
  lactExists,
}) {
  const dispatch = useDispatch();

  const { info } = colors;
  const intitalPercent = {
    percentSingles: 0,
    percentDoubles: 0,
    percentTriples: 0,
  };

  const [percentGestation, setPercentGestation] = useState(intitalPercent);
  const [averageWeightGain, setAverageWeightGain] = useState("");
  const [poundsOfMilk, setPoundsOfMilk] = useState("");
  const [knowAmtOfMilk, setKnowAmtOfMilk] = useState(212477);
  const [milkProdLst, setMilkProdLst] = useState([]);

  const [saveSuccessMsg, setSaveSuccessMsg] = useState(false);
  const [loading, setLoading] = useState(false);

  const handlePoundsOfMilkChange = (event) => {
    setPoundsOfMilk(event.target.value);
  };

  const handleAvgWeightGainChange = (event) => {
    setAverageWeightGain(event.target.value);
  };

  const handleKnwAmtOfMilkChange = (event) => {
    const knwnMilk = parseInt(event.target.value, 10);
    setKnowAmtOfMilk(knwnMilk);
  };
  const handlePercentSinglesChange = (e) => {
    const value = getTextInput(e.target.value);
    setPercentGestation({
      ...percentGestation,
      percentSingles: value,
    });
  };
  const handlePercentDoublesChange = (e) => {
    const value = getTextInput(e.target.value);
    setPercentGestation({
      ...percentGestation,
      percentDoubles: value,
    });
  };
  const handlePercentTriplesChange = (e) => {
    const value = getTextInput(e.target.value);
    setPercentGestation({
      ...percentGestation,
      percentTriples: value,
    });
  };
  const validateFetchInputs = (gestLactObj) => {
    let isGest = false;
    let hasGestDetails = false;
    let isLact = false;
    let hasLactDetails = false;
    let isValid = false;

    if (gestExists) {
      isGest = true;
      if (
        Number(gestLactObj.percentSingles) +
          Number(gestLactObj.percentDoubles) +
          Number(gestLactObj.percentTriples) ===
        100
      ) {
        hasGestDetails = true;
      }
    }
    if (lactExists) {
      isLact = true;
      if (
        gestLactObj.knowMilkAmt
          ? gestLactObj.lbsOfMilk > 0
          : gestLactObj.avgWeightGain > 0
      ) {
        hasLactDetails = true;
      }
    }

    if (isGest && hasGestDetails && isLact && hasLactDetails) {
      isValid = true;
    } else if (isGest && hasGestDetails && !isLact && !hasLactDetails) {
      isValid = true;
    } else if (!isGest && !hasGestDetails && isLact && hasLactDetails) {
      isValid = true;
    } else if (!isGest && !hasGestDetails && !isLact && !hasLactDetails) {
      isValid = false;
    }
    return isValid;
  };

  const fetchGoatGestLact = () => {
    setLoading(true);
    const popObj = {
      popid: id,
    };
    post(FETCH_GOAT_GEST_LACT, popObj)
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          setLoading(false);
        } else {
          const gestLactObj = res.data;
          console.log(gestLactObj);
          setPercentGestation({
            percentSingles: gestLactObj.percentSingles,
            percentDoubles: gestLactObj.percentDoubles,
            percentTriples: gestLactObj.percentTriples,
          });
          setAverageWeightGain(gestLactObj.avgWeightGain);
          setPoundsOfMilk(gestLactObj.lbsOfMilk);
          setMilkProdLst(gestLactObj.knowMilkAmtList);
          setKnowAmtOfMilk(!gestLactObj.knowMilkAmt ? 212477 : 212476);

          if (validateFetchInputs(gestLactObj)) {
            verifyDone("green");
          } else {
            verifyDone("grey");
          }
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const gestationTotal = () => {
    const gestTotal =
      Number(percentGestation.percentSingles) +
      Number(percentGestation.percentDoubles) +
      Number(percentGestation.percentTriples);
    return gestTotal;
  };

  const validateUserInputs = () => {
    let isGest = false;
    let hasGestDetails = false;
    let isLact = false;
    let hasLactDetails = false;
    let isValid = false;

    if (gestExists) {
      isGest = true;
      if (
        Number(percentGestation.percentSingles) +
          Number(percentGestation.percentDoubles) +
          Number(percentGestation.percentTriples) ===
        100
      ) {
        hasGestDetails = true;
      }
    }
    if (lactExists) {
      isLact = true;
      if (knowAmtOfMilk === 212476 ? poundsOfMilk > 0 : averageWeightGain > 0) {
        hasLactDetails = true;
      }
    }
    if (isGest && hasGestDetails && isLact && hasLactDetails) {
      isValid = true;
    } else if (isGest && hasGestDetails && !isLact && !hasLactDetails) {
      isValid = true;
    } else if (!isGest && !hasGestDetails && isLact && hasLactDetails) {
      isValid = true;
    } else if (!isGest && !hasGestDetails && !isLact && !hasLactDetails) {
      isValid = false;
    }
    return isValid;
  };
  const updateGoatGestLact = () => {
    setLoading(true);
    const animalTypesObj = {
      gestPercent: percentGestation,
      avgWgtGain: averageWeightGain,
      lbsOfMilk: poundsOfMilk,
      hasMilkAmt: knowAmtOfMilk,

      popid: id,
    };
    post(UPDATE_GOAT_GEST_LACT, animalTypesObj)
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          setLoading(false);
        } else {
          setSaveSuccessMsg(true);
          verifyDone("green");
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchGoatGestLact();
  }, [anagObject.activeAnimalCategories]);

  return (
    <CFBox width="100%">
      {loading && <CFLoading local />}
      <Grid container item>
        {/* Main form */}
        <CFBox width="100%">
          <Grid container direction="row" xs={12} sm={12} md={12}>
            {!gestExists && !lactExists && (
              <Grid container direction="row" xs={12} sm={12} md={12}>
                <Grid item xs={12} sm={12} md={12}>
                  <CFTypography
                    sx={{
                      fontSize: "0.7em",

                      color: info.dullFocus,

                      marginBottom: "0.7em",
                    }}
                  >
                    You did not enter populations for any Female (Pregnant) or
                    Female (Lactating) goat categories, hence you do not have to
                    define anything on this panel.
                  </CFTypography>
                </Grid>
              </Grid>
            )}

            {(gestExists || lactExists) && (
              <Grid container direction="row" xs={12} sm={12} md={12}>
                <Grid item xs={12} sm={12} md={12}>
                  <CFTypography
                    sx={{
                      fontSize: "0.7em",

                      color: info.dullFocus,

                      marginBottom: "0.7em",
                    }}
                  >
                    Enter the gestation and/or lactation details below for the
                    population of pregnant and lactating goats.
                  </CFTypography>
                </Grid>
              </Grid>
            )}
            {gestExists && (
              <Grid container direction="row">
                <Grid item container xs={12} direction="column">
                  <Grid item xs={12}>
                    <CFTypography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: info.dullFocus,
                      }}
                    >
                      Gestation Details
                    </CFTypography>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {gestExists && (
              <Grid container direction="row" xs={12} sm={12} md={12}>
                <Grid item xs={12} sm={12} md={12}>
                  <CFTypography
                    sx={{
                      fontSize: "0.7em",

                      color: info.dullFocus,

                      marginBottom: "0.7em",
                    }}
                  >
                    For the population of pregnant goats, enter the percent of
                    births that were singles, twins, or triplets. The total must
                    equal 100%.
                  </CFTypography>
                </Grid>
              </Grid>
            )}
            {gestExists && (
              <CFBox>
                <Grid container direction="row">
                  <Grid
                    item
                    container
                    xs={12}
                    justifyContent="flex-end"
                    direction="row"
                  >
                    <Grid item xs={9} justifyContent="flex-end">
                      <CFBox style={{ display: "flex" }}>
                        <Grid item xs={3}>
                          <CFBox
                            sx={{
                              backgroundColor: colors.background.header,
                              padding: "10px",

                              display: "flex",
                              marginBottom: "0.3em",
                              marginInline: "0.3em",

                              boxShadow: "0px 1px 3px 0px #bbb",
                            }}
                          >
                            <CFTypography
                              sx={{
                                fontSize: "15px",
                                color: info.dullFocus,
                                fontWeight: "bold",
                                padding: "10px",
                                width: "11em",
                              }}
                            >
                              Singles
                            </CFTypography>
                          </CFBox>
                        </Grid>
                        <Grid item xs={3}>
                          <CFBox
                            sx={{
                              backgroundColor: colors.background.header,
                              padding: "10px",

                              display: "flex",
                              marginBottom: "0.3em",
                              marginRight: "0.3em",
                              boxShadow: "0px 1px 3px 0px #bbb",
                            }}
                          >
                            <CFTypography
                              sx={{
                                fontSize: "15px",
                                color: info.dullFocus,
                                fontWeight: "bold",
                                padding: "10px",
                                width: "11em",
                              }}
                            >
                              Twins
                            </CFTypography>
                          </CFBox>
                        </Grid>
                        <Grid item xs={3}>
                          <CFBox
                            sx={{
                              backgroundColor: colors.background.header,
                              padding: "10px",
                              marginBottom: "0.3em",
                              display: "flex",
                              boxShadow: "0px 1px 3px 0px #bbb",
                            }}
                          >
                            <CFTypography
                              sx={{
                                fontSize: "15px",
                                color: info.dullFocus,
                                fontWeight: "bold",
                                padding: "10px",
                                width: "11em",
                              }}
                            >
                              Triplets
                            </CFTypography>
                          </CFBox>
                        </Grid>
                      </CFBox>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    alignItems="center"
                    direction="row"
                  >
                    <Grid item xs={3}>
                      <CFBox
                        sx={{
                          backgroundColor: colors.background.header,
                          padding: "10px",

                          display: "flex",

                          boxShadow: "0px 1px 3px 0px #bbb",
                        }}
                      >
                        <CFTypography
                          sx={{
                            fontSize: "15px",
                            color: info.dullFocus,
                            fontWeight: "bold",

                            marginBottom: "0.6em",
                            marginTop: "0.9em",
                            marginRight: "0.3em",
                          }}
                        >
                          Percent of total births
                        </CFTypography>
                      </CFBox>
                    </Grid>
                    <Grid item xs={9}>
                      <CFBox style={{ display: "flex" }}>
                        <Grid item xs={3}>
                          <CFBox
                            sx={{
                              backgroundColor: colors.background.header,
                              padding: "10px 2px 10px 2px",

                              display: "flex",
                              marginInline: ".3em",

                              boxShadow: "0px 1px 3px 0px #bbb",
                            }}
                          >
                            <CFInput
                              sx={{
                                padding: "10px",
                                fieldset: {
                                  borderColor: info.dullFocus,
                                  borderStyle: "solid",
                                  borderWidth: "2px",
                                },
                              }}
                              color="primary"
                              type="number"
                              InputProps={{
                                inputProps: { min: 0, max: 100 },
                                endAdornment: "%",
                              }}
                              style={{ width: "8em", marginLeft: "0.5em" }}
                              id="prcntSingles"
                              name="prcntSingles"
                              defaultValue="0"
                              value={percentGestation.percentSingles}
                              onChange={handlePercentSinglesChange}
                              placeholder=""
                            />
                          </CFBox>
                        </Grid>
                        <Grid item xs={3}>
                          <CFBox
                            sx={{
                              backgroundColor: colors.background.header,
                              padding: "10px 2px 10px 2px",

                              display: "flex",
                              marginRight: "0.3em",

                              boxShadow: "0px 1px 3px 0px #bbb",
                            }}
                          >
                            <CFInput
                              sx={{
                                padding: "10px",
                                fieldset: {
                                  borderColor: info.dullFocus,
                                  borderStyle: "solid",
                                  borderWidth: "2px",
                                },
                              }}
                              color="primary"
                              type="number"
                              InputProps={{
                                inputProps: { min: 0, max: 100 },
                                endAdornment: "%",
                              }}
                              style={{ width: "8em", marginLeft: "0.5em" }}
                              id="prcntDoubles"
                              name="prcntDoubles"
                              defaultValue="0"
                              value={percentGestation.percentDoubles}
                              onChange={handlePercentDoublesChange}
                              placeholder=""
                            />
                          </CFBox>
                        </Grid>
                        <Grid item xs={3}>
                          <CFBox
                            sx={{
                              backgroundColor: colors.background.header,
                              padding: "10px 2px 10px 2px",

                              display: "flex",

                              boxShadow: "0px 1px 3px 0px #bbb",
                            }}
                          >
                            <CFInput
                              sx={{
                                padding: "10px",
                                fieldset: {
                                  borderColor: info.dullFocus,
                                  borderStyle: "solid",
                                  borderWidth: "2px",
                                },
                              }}
                              color="primary"
                              type="number"
                              InputProps={{
                                inputProps: { min: 0, max: 100 },
                                endAdornment: "%",
                              }}
                              style={{ width: "8em", marginLeft: "0.5em" }}
                              id="prcntTriples"
                              name="prcntTriples"
                              defaultValue="0"
                              value={percentGestation.percentTriples}
                              onChange={handlePercentTriplesChange}
                              placeholder=""
                            />
                          </CFBox>
                        </Grid>
                        <Grid item xs={1.5}>
                          <CFTypography
                            sx={{
                              fontSize: "20px",
                              fontWeight: "bold",
                              color: info.dullFocus,
                              marginTop: "1em",
                            }}
                            style={{ float: "right" }}
                          >
                            = {gestationTotal()}%
                          </CFTypography>
                        </Grid>
                      </CFBox>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  alignItems="center"
                  direction="row"
                >
                  {gestationTotal() !== 100 && (
                    <CFBox id="gestTotalError">
                      <CFTypography
                        sx={{
                          fontSize: "15px",
                          color: "red",
                          fontWeight: "bold",
                          marginLeft: "25em",

                          marginBottom: "0.5em",
                          marginTop: "0.5em",
                        }}
                      >
                        Percent of total births should be equal to 100 !
                      </CFTypography>
                    </CFBox>
                  )}
                </Grid>
              </CFBox>
            )}
            {lactExists && (
              <Grid container direction="row">
                <Grid item container xs={12} direction="column">
                  <Grid item xs={12}>
                    <CFTypography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: info.dullFocus,
                        marginTop: "2em",
                      }}
                    >
                      Lactation Details
                    </CFTypography>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {lactExists && (
              <Grid container direction="row" xs={12} sm={12} md={12}>
                <Grid item xs={12} sm={12} md={12}>
                  <CFTypography
                    sx={{
                      fontSize: "0.7em",

                      color: info.dullFocus,

                      marginBottom: "0.7em",
                    }}
                  >
                    If known, enter the amount of milk produced per ewe each
                    day. If not known, select No and enter the average weight
                    gain of the goat between birth and weaning.
                  </CFTypography>
                </Grid>
              </Grid>
            )}
            {lactExists && (
              <Grid container direction="row">
                <Grid item container xs={9}>
                  <Grid item xs={9}>
                    <CFBox
                      sx={{
                        backgroundColor: colors.background.header,
                        padding: "10px",

                        display: "flex",
                        marginTop: "2em",

                        boxShadow: "0px 1px 3px 0px #bbb",
                      }}
                    >
                      <CFTypography
                        sx={{
                          fontSize: "14px",
                          marginRight: "1em",
                          color: info.dullFocus,
                        }}
                      >
                        Milk production known?
                      </CFTypography>
                      <RadioGroup
                        row
                        name="knwMilkRadioButton"
                        value={knowAmtOfMilk}
                        onChange={handleKnwAmtOfMilkChange}
                      >
                        {milkProdLst.map((milkProd) => (
                          <FormControlLabel
                            value={milkProd.id}
                            control={
                              <Radio
                                sx={{
                                  "& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)":
                                    {
                                      color: "dimgray",
                                    },
                                }}
                              />
                            }
                            label={milkProd.name}
                          />
                        ))}
                      </RadioGroup>
                    </CFBox>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {(knowAmtOfMilk === 212476 || knowAmtOfMilk === "212476") &&
              lactExists && (
                <Grid container direction="row">
                  <Grid item container xs={12}>
                    <Grid item xs={12}>
                      <CFBox
                        width="100%"
                        style={{
                          display: "flex",
                          marginTop: "1em",
                          marginBottom: "1em",
                        }}
                      >
                        <CFBox
                          sx={{
                            backgroundColor: colors.background.header,
                            padding: "10px",

                            display: "flex",

                            boxShadow: "0px 1px 3px 0px #bbb",
                          }}
                        >
                          <CFTypography
                            sx={{
                              fontSize: "14px",
                              marginRight: "1em",
                              color: info.dullFocus,
                            }}
                          >
                            Enter the amount of milk produced per ewe per day:
                          </CFTypography>
                          <CFInput
                            sx={{
                              padding: "10px",
                              fieldset: {
                                borderColor: info.dullFocus,
                                borderStyle: "solid",
                                borderWidth: "2px",
                              },
                            }}
                            InputProps={{
                              endAdornment: "lbs",
                            }}
                            style={{ width: "6em" }}
                            color="primary"
                            type="number"
                            id="lbsOfMilk"
                            name="lbsOfMilk"
                            defaultValue="0"
                            value={poundsOfMilk}
                            onChange={handlePoundsOfMilkChange}
                            placeholder=""
                          />
                        </CFBox>
                      </CFBox>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            {(knowAmtOfMilk === 212477 || knowAmtOfMilk === "212477") &&
              lactExists && (
                <Grid container direction="row">
                  <Grid item container xs={12}>
                    <Grid item xs={12}>
                      <CFBox
                        width="100%"
                        style={{
                          display: "flex",
                          marginTop: "1em",
                          marginBottom: "1em",
                        }}
                      >
                        <CFBox
                          sx={{
                            backgroundColor: colors.background.header,
                            padding: "10px",

                            display: "flex",

                            boxShadow: "0px 1px 3px 0px #bbb",
                          }}
                        >
                          <CFTypography
                            sx={{
                              fontSize: "14px",
                              marginRight: "1em",
                              color: info.dullFocus,
                            }}
                          >
                            Enter the average weight gain of the goat between
                            birth and weaning:
                          </CFTypography>
                          <CFInput
                            sx={{
                              padding: "10px",
                              fieldset: {
                                borderColor: info.dullFocus,
                                borderStyle: "solid",
                                borderWidth: "2px",
                              },
                            }}
                            InputProps={{
                              endAdornment: "lbs",
                            }}
                            style={{ width: "6em" }}
                            color="primary"
                            type="number"
                            id="avgWghtGain"
                            name="avgWghtGain"
                            defaultValue="0"
                            value={averageWeightGain}
                            onChange={handleAvgWeightGainChange}
                            placeholder=""
                          />
                        </CFBox>
                      </CFBox>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            <Grid container py={2} px={2} justifyContent="space-around">
              <Grid item xs={12} sm={12} md={12}>
                <CFButton
                  type="submit"
                  sx={{
                    float: "right",
                  }}
                  color="progress"
                  onClick={() => updateGoatGestLact()}
                  disabled={!validateUserInputs()}
                >
                  Save
                </CFButton>
              </Grid>
            </Grid>
            {saveSuccessMsg && (
              <Grid container justifyContent="space-around">
                <Grid item xs={2} sm={2} md={2} alignItems="center">
                  <CFBox id="successMessage">
                    <CFTypography
                      sx={{
                        fontSize: "15px",
                        color: "green",

                        marginBottom: "0.5em",
                        marginTop: "0.5em",
                      }}
                    >
                      Save was successful.
                    </CFTypography>
                  </CFBox>
                </Grid>
              </Grid>
            )}
          </Grid>
        </CFBox>
      </Grid>
    </CFBox>
  );
}
