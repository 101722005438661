// prop-types is a library for typechecking of props
import PropTypes from "prop-types"; // @mui material components
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";
import Fade from "@mui/material/Fade";
import VerifiedTwoToneIcon from "@mui/icons-material/VerifiedTwoTone";

// COMET Componets
import CFBox from "components/CFBox";
import CFTypography from "components/CFTypography";

// Custom styles for the CFSnackbar
import CFSnackbarIconRoot from "components/CFSnackbar/CFSnackbarIconRoot";

/* Create a snack bar with a header section with an optional datetime stamp. The optional content displays below the header. */
function CFSnackbar({
  color,
  icon,
  title,
  dateTime,
  content,
  close,
  bgWhite,
  ...rest
}) {
  let titleColor;
  let dateTimeColor;

  if (bgWhite) {
    titleColor = color;
    dateTimeColor = "dark";
  } else if (color === "light") {
    titleColor = "dark";
    dateTimeColor = "text";
  } else {
    titleColor = "white";
    dateTimeColor = "white";
  }

  return (
    <Snackbar
      TransitionComponent={Fade}
      autoHideDuration={5000}
      onClose={close}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      {...rest}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={close}
        >
          <Icon fontSize="small">close</Icon>
        </IconButton>
      }
    >
      <CFBox
        variant={bgWhite ? "contained" : "gradient"}
        bgColor={bgWhite ? "white" : color}
        minWidth="21.875rem"
        maxWidth="100%"
        shadow="md"
        borderRadius="md"
        p={1}
        sx={{
          backgroundColor: ({ palette }) =>
            palette[color] || palette.white.main,
        }}
      >
        <CFBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          color="dark"
          p={1.5}
        >
          <CFBox display="flex" alignItems="center" lineHeight={0}>
            <CFSnackbarIconRoot
              fontSize="small"
              ownerState={{ color, bgWhite }}
            >
              {icon === "saveSuccess" ? (
                <VerifiedTwoToneIcon
                  color="primary"
                  style={{
                    color: "green",
                  }}
                />
              ) : (
                icon
              )}
            </CFSnackbarIconRoot>
            <CFTypography
              variant="button"
              fontWeight="medium"
              color={titleColor}
              textGradient={bgWhite}
            >
              {title}
            </CFTypography>
          </CFBox>

          <CFBox display="flex" alignItems="center" lineHeight={0}>
            {dateTime && (
              <CFTypography variant="caption" color={dateTimeColor}>
                {dateTime}
              </CFTypography>
            )}
            <Icon
              sx={{
                color: ({ palette: { dark, white } }) =>
                  bgWhite || color === "light" ? dark.main : white.main,
                fontWeight: ({ typography: { fontWeightBold } }) =>
                  fontWeightBold,
                cursor: "pointer",
                marginLeft: 2,
                transform: "translateY(-1px)",
              }}
              onClick={close}
            >
              close
            </Icon>
          </CFBox>
        </CFBox>
        {content && (
          <>
            <Divider sx={{ margin: 0, opacity: 0.6 }} />
            <CFBox
              p={1.5}
              sx={{
                fontSize: ({ typography: { size } }) => size.sm,
                color: ({ palette: { white, text } }) =>
                  bgWhite || color === "light" ? text.main : white.main,
              }}
            >
              {content}
            </CFBox>
          </>
        )}
      </CFBox>
    </Snackbar>
  );
}

// Setting default values for the props of CFSnackbar
CFSnackbar.defaultProps = {
  bgWhite: false,
  color: "primary",
  dateTime: null,
  content: null,
};

// Typechecking props for CFSnackbar
CFSnackbar.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "progress",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  dateTime: PropTypes.string,
  content: PropTypes.node,
  close: PropTypes.func.isRequired,
  bgWhite: PropTypes.bool,
};

export default CFSnackbar;
