/* eslint-disable react/no-array-index-key */
import { Grid, Input, InputAdornment } from "@mui/material";

// Date picker
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { preloadImage } from "utils/generic";
import colors from "assets/theme/base/colors";
import LimingHelp from "assets/cFarmImages/HelpImages/Liming-Help.webp";
import CFLookupPicker from "components/CroplandsComponents/CFLookupPicker/CFLookupPicker";
import CFManagementSelectionCard from "components/CroplandsComponents/CFManagementSelectionCard/CFManagementSelectionCard";
import UserGuidePopup from "components/CFUserGuidePopup/UserGuidePopup";
import { useEffect } from "react";
import WhiteBackgroundDatepicker from "../../../../../components/CroplandsComponents/EditCropCard_StructuralComponents/WhiteBackgroundDatepicker";

export default function ({
  CropEvent,
  SetPropertyFunction,
  FieldName,
  limingApplications,
}) {
  preloadImage(LimingHelp);
  const minDate = dayjs()
    .set("year", CropEvent.cropYear)
    .set("date", 1)
    .set("month", 0);
  const maxDate = dayjs()
    .set("year", CropEvent.cropYear + 2)
    .set("date", 1)
    .set("month", 0);

  useEffect(() => {
    const ldate = dayjs(CropEvent.liming.limingDate);
    if (ldate.isBefore(minDate)) {
      // eslint-disable-next-line no-param-reassign
      CropEvent.liming.limingDate = minDate;
    }
  });

  const setAmountApplied = (newVal, index) => {
    const newCE = { ...CropEvent };
    if (!newCE.liming) {
      newCE.liming = {};
      newCE.liming.limingDate = minDate;
    }
    let setVal = newVal;
    if (setVal < 0) {
      setVal = 0;
    }

    newCE.liming.rate = setVal;

    SetPropertyFunction(newCE);
  };

  const setMaterial = (newVal) => {
    const newCE = { ...CropEvent };
    if (!newCE.liming) {
      newCE.liming = {};
      newCE.liming.limingDate = minDate;
    }

    newCE.liming.applicationMethod = newVal;

    SetPropertyFunction(newCE);
  };

  const SetApplicationDate = (newVal) => {
    const newCE = { ...CropEvent };
    if (dayjs(newVal, true).isValid()) {
      if (!newCE.liming) {
        newCE.liming = {};
      }
      newCE.liming.limingDate = dayjs(newVal).toISOString();
      SetPropertyFunction(newCE);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid alignItems="center" container flexDirection="row">
        <Grid item xs={12}>
          <Grid
            item
            xs={12}
            container
            flexDirection="row"
            alignItems="center"
            sx={{ paddingTop: ".5em", marginTop: "1em" }}
          >
            <Grid
              item
              xs={12}
              container
              flexDirection="row"
              alignItems="center"
            >
              <Grid item xs={11} xl={5.5} xxl={3}>
                <CFManagementSelectionCard
                  label="Material"
                  labelWidth={6}
                  input={
                    <CFLookupPicker
                      label="Select a material"
                      required
                      sx={{ width: "100%" }}
                      value={CropEvent.liming?.applicationMethod}
                      onChange={(event, newValue) => {
                        setMaterial(newValue);
                      }}
                      options={limingApplications}
                    />
                  }
                />
              </Grid>
              <Grid item xs={11} xl={5.5} xxl={4}>
                <CFManagementSelectionCard
                  label="Date Applied"
                  labelWidth={6}
                  input={
                    <WhiteBackgroundDatepicker
                      views={["year", "month", "day"]}
                      label="Date Applied"
                      maxDate={maxDate}
                      minDate={minDate}
                      style={{ backgroundColor: colors.white.main }}
                      slotProps={{ textField: { variant: "outlined" } }}
                      value={
                        dayjs(CropEvent.liming?.limingDate).isBefore(minDate)
                          ? minDate
                          : dayjs(CropEvent.liming?.limingDate)
                      }
                      onChange={(newValue) => SetApplicationDate(newValue)}
                    />
                  }
                />
              </Grid>
              <Grid item xs={11} xxl={4}>
                <CFManagementSelectionCard
                  //  sx={{ marginTop: 0 }}
                  label="Amount Applied"
                  labelWidth={8}
                  input={
                    <Input
                      label="ton/ac"
                      endAdornment={
                        <InputAdornment position="end">ton/ac</InputAdornment>
                      }
                      type="number"
                      sx={{
                        backgroundColor: colors.white.main,
                        fontSize: "1.25em",
                        paddingInline: ".3em",
                        borderRadius: ".3em",
                      }}
                      value={CropEvent.liming?.rate} // show total weight, but save dry weight
                      onChange={(e) => setAmountApplied(e.target.value)}
                    />
                  }
                />
              </Grid>
              <Grid item xs={1}>
                <UserGuidePopup
                  content={
                    <div
                      style={{
                        overflow: "auto",
                        height: "350px",
                        maxHeight: "60vh",
                      }}
                    >
                      <p>
                        Users may add a single liming event per crop. Users will
                        need to indicate the liming material, application date,
                        and application amount (tons/ac). If no liming event
                        occurs for a crop, none should be selected.
                      </p>
                      <img src={LimingHelp} width="100%" alt="Liming help" />
                    </div>
                  }
                  userGuideLabel="Liming Event"
                  variant="h3"
                  sx={{
                    marginTop: ".2em",
                    padding: ".1em",
                    marginLeft: ".5em",
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
}
