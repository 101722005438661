import { put, call } from "redux-saga/effects";
import { receiveUser } from "store/reducers/user";
import { updateLoading } from "store/reducers/mask";
import { USER_FETCH, USER_UPDATE } from "../constants/api";
import { post, patch } from "../utils/api";

import { to } from "../actions/navigation";

export function* fetchUser(action) {
  // console.log("calling fetch user PRE");
  try {
    // console.log("calling fetch user", action, action.payload);
    // console.trace();

    const data = yield call(post, USER_FETCH, action.payload);

    yield put(receiveUser(data));

    yield put(to("gotUser"));
  } catch (error) {
    yield put({ type: "FETCH_FAILED", error });
  }
}

export function* updateUser(action) {
  try {
    yield put(updateLoading({ loading: true }));
    /*
    // Json Patch way
    let arr = [];
    let opperations = {
      "op":"replace",
      "path":"/company",
      "value": "vals"
    }
    arr.push(opperations); // used for jsob=n patch way
    const data = yield call(patch, `${USER_UPDATE}/${action.payload.userId}`, arr); // Json Patch way
    */
    const data = yield call(patch, USER_UPDATE, action.payload);
    yield put(receiveUser(data));
    yield put(updateLoading({ loading: false }));
    yield put(to("showUpdateUserSuccess"));
  } catch (error) {
    yield put(to("showUpdateError"));
    yield put({ type: "FETCH_FAILED", error });
    yield put(updateLoading({ loading: false }));
  }
}

/*
export function* submitUserForm(action) {
  try {
    yield put(updateLoading({ loading: true }));
    const data = yield call(post, USER_UPDATE, action.payload.values);

    yield put(receiveUser(data));
    yield put(to("showUpdateUserSuccess"));

    yield put(updateLoading({ loading: false }));
  } catch (error) {
    yield put({ type: "FETCH_FAILED", error });
    yield put(updateLoading({ loading: false }));
  }
}

/*export function* deleteUser(action) {
  try {
    const data = yield call(post, USER_DELETE, action.payload);
    yield put(receiveUser(data));

    yield put(updateLoading({ loading: false }));
  } catch (error) {
    yield put({ type: "FETCH_FAILED", error });
    yield put(updateLoading({ loading: false }));
  }
} */
