import { Info } from "@mui/icons-material";
import { Grid, InputAdornment } from "@mui/material";
import colors from "assets/theme/base/colors";
import CFInput from "components/CFInput";
import CFTypography from "components/CFTypography";

export default function SwineInput({
  question,
  value,
  onChange,
  endAdornment,
  min,
  max,
  disabled = false,
}) {
  const onChangeValue = (event) => {
    const input = Number(event.target.value);
    // check is it a number and if there is a max, is it in the bounds?
    if (!Number.isNaN(input) && (!max || (input >= min && input <= max)))
      onChange(event.target.value);
  };

  let inputProps;
  if (min && max) {
    inputProps = { min, max };
  } else if (!min && max) {
    inputProps = { max };
  } else if (min && !max) {
    inputProps = { min };
  }

  return (
    <>
      <Grid item xs={6}>
        <CFTypography
          sx={{
            fontSize: "16px",
            // width: "50%",
            color: colors.info.dullFocus,
          }}
        >
          {question}
        </CFTypography>
      </Grid>
      <Grid item xs={2}>
        <CFInput
          sx={{
            fieldset: {
              borderColor: Info.dullFocus,
              borderStyle: "solid",
              borderWidth: "2px",
            },
          }}
          value={value}
          onChange={onChangeValue}
          InputProps={{
            inputProps,
            endAdornment: (
              <InputAdornment position="end">{endAdornment}</InputAdornment>
            ),
          }}
          disabled={disabled}
        />
      </Grid>
    </>
  );
}
