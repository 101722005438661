import { useEffect, useState } from "react";

import PropTypes from "prop-types";

import CFBox from "components/CFBox";

import colors from "assets/theme/base/colors";
import CFTypography from "components/CFTypography";
import borders from "assets/theme/base/borders";

function InPage({ children, minHeight, defaultTab, verbose, ...rest }) {
  const { TabsList } = children;
  const [activeTab, toggleActiveTab] = useState(TabsList[defaultTab].Tab);
  const [previousTab, setPreviousTab] = useState(TabsList[defaultTab].Tab);
  useEffect(() => {
    // Look for a tab matching the previously selected tab
    let tabFound = false;
    TabsList.forEach((newTab) => {
      if (newTab.Tab === previousTab) {
        toggleActiveTab(newTab.Tab);
        setPreviousTab(newTab.Tab);
        tabFound = true;
      }
    });
    // Otherwise, default to first tab
    if (!tabFound) {
      toggleActiveTab(TabsList[0].Tab);
      setPreviousTab(TabsList[0].Tab);
    }
  }, [TabsList]);
  // Update previously selected tab whenever the active tab is toggled
  useEffect(() => {
    setPreviousTab(activeTab);
  }, [activeTab]);
  // Update active tab if the defaultTab is changed
  useEffect(() => {
    toggleActiveTab(TabsList[defaultTab].Tab);
    setPreviousTab(TabsList[defaultTab].Tab);
  }, [defaultTab]);

  return (
    <CFBox
      sx={{
        minHeight: { minHeight },
        width: "100%",
        boxShadow: "0px 0px 10px #ccc",
        borderRadius: borders.borderRadius.lg,
        overflow: "hidden",
        backgroundColor: "#f5f5f5",
      }}
      {...rest}
    >
      <CFBox
        className="cf-flex grow"
        sx={{
          gap: ".1rem",
          borderBottom: "1px solid ",
          borderColor: colors.slate[50],
          overflow: "auto",
        }}
      >
        {TabsList.map((tab) => (
          <CFBox
            onClick={() => toggleActiveTab(tab.Tab)}
            key={tab.Tab}
            sx={{
              backgroundColor:
                tab.Tab === activeTab ? colors.primary.main : "#ddd",
              padding: ".5em",
              cursor: "pointer",
              minWidth: 250,
            }}
          >
            <CFTypography
              textAlign="center"
              sx={{
                color: tab.Tab === activeTab ? "#fffffff5" : colors.slate.main,
              }}
            >
              {tab.Tab}
            </CFTypography>
          </CFBox>
        ))}
      </CFBox>

      <CFBox>
        {TabsList.map((tab) => {
          if (tab.Tab === activeTab) {
            return <CFBox key={`data_${tab.Tab}`}>{tab.Data}</CFBox>;
          }
          if (verbose) {
            return (
              <CFBox
                key={`data_${tab.Tab}`}
                sx={{ height: 0, width: 0, visibility: "hidden" }}
              >
                {tab.Data}
              </CFBox>
            );
          }
          return null;
        })}
      </CFBox>
    </CFBox>
  );
}

InPage.propTypes = {
  minHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  verbose: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

function CFTabViewRoot(props) {
  return InPage(props);
}

export default CFTabViewRoot;
