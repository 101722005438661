import { Control } from "ol/control";

class ImagerySetButtons extends Control {
  /**
   * @param {Object} [opt_options] Control options.
   */
  constructor(
    toggleSoil,
    setToggleSoil,
    imagerySet,
    setImagerySet,
    toggleMapViewButtons,
    opt_options
  ) {
    /* eslint camelcase: "off" */
    const options = opt_options || {};
    const layers = [
      { text: "SOILS", imagery: "SOILS" },
      { text: "HYBRID", imagery: "AerialWithLabelsOnDemand" },
      { text: "ROAD", imagery: "RoadOnDemand" },
      { text: "DARK", imagery: "CanvasDark" },
      // { text: "ORDNANCE", imagery: "OrdnanceSurvey" },
    ];
    const element = document.createElement("div");
    element.innerHTML = "Map View";
    element.className = "CFMapImageryButtonsContainer";
    element.title = "Map View";
    element.addEventListener("click", () => toggleMapViewButtons(), false);
    const container = document.createElement("div");
    container.id = "buttonContainer";
    container.className = "CFMapButtonsInnerContainer";
    layers.forEach((layer) => {
      const button = document.createElement("button");
      button.id = `${layer.text}button`;
      button.title = layer.text;
      button.innerHTML = layer.text;
      if (
        layer.imagery === imagerySet ||
        (layer.imagery === "SOILS" && toggleSoil)
      ) {
        button.className = "CFMapImageryButtonsPressed";
      } else {
        button.className = "CFMapImageryButtons";
      }
      container.appendChild(button);
      // element.appendChild(button);

      if (layer.text === "SOILS")
        button.addEventListener(
          "click",
          () => setToggleSoil(!toggleSoil),
          false
        );
      else
        button.addEventListener(
          "click",
          () => setImagerySet(layer.imagery),
          false
        );
    });
    // }
    element.appendChild(container);

    super({
      element,
      target: options.target,
    });
  }
}

export default ImagerySetButtons;
