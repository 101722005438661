import React, { useState } from "react";

import CFStep from "components/CFarmParts/CFStep";
import CFBox from "components/CFBox";
import CroplandAccordionSection from "components/CroplandsComponents/CroplandAccordionSection/CroplandAccordionSection";
import colors from "assets/theme/base/colors";
import CropRotationManagement from "../CroplandComponents/CropRotationManagement/CropRotationManagement";

import style from "../CroplandStyle";

const empty = { name: "select scenario", id: 0 };
const emptyOptional = {
  name: "(optional)",
  id: 0,
};
function ScenarioManagement({
  allOtherFields,
  field,
  stepNumber,
  fetchProjectInformation,
  goToPrevious,
  selectedScenario,
  disabled,
}) {
  const [complete, setIsComplete] = useState(false);

  const setComplete = (isComplete) => {
    setIsComplete(isComplete);
    if (fetchProjectInformation) {
      fetchProjectInformation();
    }
  };
  return (
    <CFBox
      sx={{
        width: style.width,
        position: "relative",
        marginTop: "1em",
        opacity: disabled ? 0.5 : 1,
        pointerEvents: disabled ? "none" : "auto",
      }}
    >
      <CroplandAccordionSection
        startState
        bgColor={colors.background.header}
        summary={
          <CFStep
            stepLabel={
              <div>
                {selectedScenario &&
                selectedScenario.name &&
                selectedScenario.id !== 0
                  ? selectedScenario.name
                  : "Scenario"}{" "}
                Crops & Management: <em>{field.name}</em>
              </div>
            }
            stepNumber={stepNumber}
            complete={complete}
            helpText="Using the dropdown menu for each year, or using the Crop Templates, add crops(s) for each year.  Then Edit or Add Management for each crop year using the icon to the right of the crop drop down menu."
            subText="Using the dropdown menu for each year, or using the Crop Templates, add crops(s) for each year.  Then Edit or Add Management for each crop year using the icon to the right of the crop drop down menu."
          />
        }
      >
        <CropRotationManagement
          allOtherFields={allOtherFields}
          field={field}
          stepNumber={stepNumber}
          cropRotationId={selectedScenario ? selectedScenario.id : 0}
          scenarioName={
            selectedScenario && selectedScenario.name
              ? selectedScenario.name
              : "Select a Scenario"
          }
          goToPrevious={goToPrevious}
          fetchProjectInformation={fetchProjectInformation}
          setIsComplete={setComplete}
        />
      </CroplandAccordionSection>
    </CFBox>
  );
}
export default ScenarioManagement;
