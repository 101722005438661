import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { LicenseInfo } from "@mui/x-license-pro";
// eslint-disable-next-line import/no-extraneous-dependencies
import { loggedIn } from "./utils/auth";
import { fetchUser } from "./actions/user";
import { takeIfExists } from "./utils/localStorage";
import registerServiceWorker from "./registerServiceWorker";
import configureStore from "./store";
import App from "./App";
import "styles/react-geo.css";

registerServiceWorker();

// console.log("process env vars: ", process.env);

LicenseInfo.setLicenseKey(
  // "7faf749faef124b2b43542f82dc080d9Tz01MzQyMCxFPTE2OTg4MTUxMzg5ODAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
  process.env.REACT_APP_X_KEY
);

const store = configureStore();
// eslint-disable-next-line no-unused-expressions
loggedIn() && store.dispatch(fetchUser({ Id: takeIfExists("id") }));

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter basename="/">
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
