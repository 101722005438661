import PropTypes from "prop-types";
import { Navigate, useLocation } from "react-router-dom";
import { loggedIn } from "utils/auth";

function RequireAuth({ children }) {
  const location = useLocation();

  return loggedIn() === true ? (
    children
  ) : (
    <Navigate to="/login" replace state={{ path: location.pathname }} />
  );
}

RequireAuth.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
export default RequireAuth;
