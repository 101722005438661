/* eslint-disable react/no-array-index-key */
import { useEffect, useState } from "react";
import { Grid, Input, InputAdornment, TextField } from "@mui/material";

// Date picker
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";

import colors from "assets/theme/base/colors";
import CFLookupPicker from "components/CroplandsComponents/CFLookupPicker/CFLookupPicker";
import CFManagementSelectionCard from "components/CroplandsComponents/CFManagementSelectionCard/CFManagementSelectionCard";
import CFBox from "components/CFBox";
import FertHelp from "assets/cFarmImages/HelpImages/fertHelp.webp";
import { preloadImage } from "utils/generic";
import HelpAndButton from "../../../../../components/CroplandsComponents/EditCropCard_StructuralComponents/HelpAndButton";

import DeleteButton from "../../../../../components/CroplandsComponents/EditCropCard_StructuralComponents/DeleteButton";
import WhiteBackgroundDatepicker from "../../../../../components/CroplandsComponents/EditCropCard_StructuralComponents/WhiteBackgroundDatepicker";

export default function ({
  CropEvent,
  SetPropertyFunction,
  FieldName,
  nitrogenApplications,
  fertilizerRatios,
}) {
  preloadImage(FertHelp);
  const minDate = dayjs()
    .set("year", CropEvent.cropYear)
    .set("date", 1)
    .set("month", 0);
  const maxDate = dayjs()
    .set("year", CropEvent.cropYear + 2)
    .set("date", 1)
    .set("month", 0);

  const [FertTotals, setFertTotals] = useState([]);

  const getTotalNFromTotal = (totalFert, fertId) => {
    console.log(totalFert, fertId);
    if (fertilizerRatios) {
      console.log(fertilizerRatios);
      const rat = fertilizerRatios[fertId];
      console.log(rat);
      if (!rat || rat.mineralN === 0) {
        console.log("returning 1");
        return totalFert * 1;
      }
      console.log("returning 2");
      return totalFert * rat.mineralN;
    }
    console.log("returning 3");
    return totalFert;
  };

  const setAllTheFertTotals = (CE) => {
    if (!FertTotals || FertTotals.length === 0) {
      const ft = [...CE.nitrogenApplications];
      for (let i = 0; i < ft.length; i += 1) {
        if (fertilizerRatios) {
          const rat =
            fertilizerRatios[CE.nitrogenApplications[i].fertilizerType.id];
          if (!rat || rat.mineralN === 0) {
            ft[i].totalFert = (
              CE.nitrogenApplications[i].totalNApplied * 1
            ).toFixed(2);
          } else {
            ft[i].totalFert = (
              (CE.nitrogenApplications[i].totalNApplied * 1) /
              rat.mineralN
            ).toFixed(2);
          }
        } else {
          ft[i].totalFert = (
            CE.nitrogenApplications[i].totalNApplied * 1
          ).toFixed(2);
        }
      }

      setFertTotals([...ft]);
    } else {
      for (let i = 0; i < CE.nitrogenApplications.length; i += 1) {
        if (i >= FertTotals.length) {
          FertTotals.push(CE.nitrogenApplications[i]);
        }

        if (fertilizerRatios) {
          const rat =
            fertilizerRatios[CE.nitrogenApplications[i].fertilizerType.id];
          if (!rat || rat.mineralN === 0) {
            FertTotals[i].totalFert = (
              CE.nitrogenApplications[i].totalNApplied * 1
            ).toFixed(2);
          } else {
            FertTotals[i].totalFert = (
              (CE.nitrogenApplications[i].totalNApplied * 1) /
              rat.mineralN
            ).toFixed(2);
          }
        } else {
          FertTotals[i].totalFert = (
            CE.nitrogenApplications[i].totalNApplied * 1
          ).toFixed(2);
        }
      }

      setFertTotals([...FertTotals]);
    }
  };
  const SetTotalFertilizerApplied = (nval, index) => {
    let newVal = parseFloat(nval);
    if (newVal < 0) {
      newVal = 0;
    }

    const newf = [...FertTotals];
    newf[index].totalFert = newVal;
    setFertTotals([...newf]);

    const newCE = { ...CropEvent };
    // newCE.nitrogenApplications[index].totalFert = newVal;

    newCE.nitrogenApplications[index].totalNApplied = getTotalNFromTotal(
      newVal,
      newCE.nitrogenApplications[index].fertilizerType.id
    ).toFixed(2);
    //  setAllTheFertTotals(newCE);
    SetPropertyFunction(newCE);
  };
  const addNewFertilizerApplication = () => {
    const newCE = { ...CropEvent };

    newCE.nitrogenApplications.push({
      applicationDate: minDate.toISOString(),
      totalNApplied: 0,
      fertilizerType: nitrogenApplications[0],
      percentAmmonia: 0,
      id: 0,
    });
    setAllTheFertTotals(newCE);
    SetPropertyFunction(newCE);
  };

  const deleteFertilizerApplication = (index) => {
    const newCE = { ...CropEvent };
    newCE.nitrogenApplications.splice(index, 1);
    setAllTheFertTotals(newCE);
    SetPropertyFunction(newCE);
  };

  const SetFertilizerType = (newVal, index) => {
    const newCE = { ...CropEvent };

    newCE.nitrogenApplications[index].fertilizerType = newVal;
    setAllTheFertTotals(newCE);
    SetPropertyFunction(newCE);
  };
  const SetApplicationDate = (newVal, index) => {
    if (dayjs(newVal, true).isValid()) {
      const newCE = { ...CropEvent };
      newCE.nitrogenApplications[index].applicationDate =
        dayjs(newVal).toISOString();
      SetPropertyFunction(newCE);
    }
  };
  const SetTotalNApplied = (nval, index) => {
    let newVal = nval;
    if (newVal < 0) {
      newVal = 0;
    }
    const newCE = { ...CropEvent };
    newCE.nitrogenApplications[index].totalNApplied = newVal;
    setAllTheFertTotals(newCE);
    SetPropertyFunction(newCE);
  };

  useEffect(() => {
    setAllTheFertTotals(CropEvent);
  }, []);

  const showAmmonium = (application) =>
    application.fertilizerType.id === 1106 ||
    application.fertilizerType.id === 1117 ||
    application.fertilizerType.id === 1118;

  const showAmmoniumAmount = (app) => {
    if (
      fertilizerRatios[app.fertilizerType.id] &&
      fertilizerRatios[app.fertilizerType.id].mineralN === 0
    ) {
      return 11;
    }
    if (showAmmonium(app)) {
      return 4;
    }
    return 5.5;
  };

  const SetPercentAmmonia = (newVal, index) => {
    const newCE = { ...CropEvent };
    let percentAmm = newVal;
    if (percentAmm < 0) {
      percentAmm = 0;
    }
    if (percentAmm > 100) {
      percentAmm = 100;
    }
    newCE.nitrogenApplications[index].percentAmmonia = percentAmm;
    SetPropertyFunction(newCE);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid alignItems="center" container flexDirection="row">
        <Grid item xs={12}>
          {FertTotals.length > 0 &&
            CropEvent.nitrogenApplications.map((app, ind) => (
              <Grid
                item
                xs={12}
                container
                flexDirection="row"
                alignItems="center"
                key={`fertilizer${ind}`}
                sx={{ paddingTop: ".5em", marginTop: "1em" }}
              >
                <Grid
                  item
                  xs={12}
                  container
                  flexDirection="row"
                  alignItems="center"
                >
                  <Grid item xs={11} xl={5.5}>
                    <CFManagementSelectionCard
                      label="Operation Date"
                      labelWidth={6.5}
                      input={
                        <WhiteBackgroundDatepicker
                          views={["year", "month", "day"]}
                          label="Operation Date"
                          maxDate={maxDate}
                          minDate={minDate}
                          style={{ backgroundColor: colors.white.main }}
                          slotProps={{ textField: { variant: "outlined" } }}
                          value={dayjs(app.applicationDate)}
                          onChange={(newValue) =>
                            SetApplicationDate(newValue, ind)
                          }
                        />
                      }
                    />
                  </Grid>
                  <Grid item xs={11} xl={5.5}>
                    <CFManagementSelectionCard
                      label="Fertilizer Type"
                      labelWidth={6.5}
                      input={
                        <CFLookupPicker
                          label="Select a fertilizer"
                          required
                          sx={{ width: "100%" }}
                          value={app.fertilizerType}
                          onChange={(event, newValue) => {
                            SetFertilizerType(newValue, ind);
                          }}
                          options={nitrogenApplications}
                        />
                      }
                    />
                  </Grid>

                  <Grid item xs={1}>
                    <DeleteButton
                      deleteAction={() => deleteFertilizerApplication(ind)}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  container
                  flexDirection="row"
                  alignItems="center"
                >
                  <Grid
                    item
                    xs={11}
                    xxl={showAmmoniumAmount(app)}
                    xl={
                      fertilizerRatios[app.fertilizerType.id] &&
                      fertilizerRatios[app.fertilizerType.id].mineralN === 0
                        ? 11
                        : 5.5
                    }
                  >
                    <CFManagementSelectionCard
                      //  sx={{ marginTop: 0 }}
                      label="Total Amount Applied"
                      labelWidth={6.5}
                      input={
                        <Input
                          label="lbs/ac"
                          endAdornment={
                            <InputAdornment
                              position="end"
                              sx={{ width: "6em" }}
                            >
                              lbs/ac
                            </InputAdornment>
                          }
                          type="number"
                          sx={{
                            backgroundColor: colors.white.main,
                            fontSize: "1.25em",
                            paddingInline: ".3em",
                            borderRadius: ".3em",
                          }}
                          value={FertTotals[ind].totalFert}
                          onChange={(e) =>
                            SetTotalFertilizerApplied(e.target.value, ind)
                          }
                        />
                      }
                    />
                  </Grid>
                  {(!fertilizerRatios[app.fertilizerType.id] ||
                    fertilizerRatios[app.fertilizerType.id].mineralN !== 0) && (
                    <Grid
                      item
                      xs={11}
                      xxl={showAmmonium(app) ? 4 : 5.5}
                      xl={5.5}
                    >
                      <CFManagementSelectionCard
                        label="Total N Applied"
                        labelWidth={6.5}
                        input={
                          <Input
                            label="lbs/ac"
                            endAdornment={
                              <InputAdornment
                                position="end"
                                sx={{ width: "6em" }}
                              >
                                lbs/ac
                              </InputAdornment>
                            }
                            type="number"
                            sx={{
                              backgroundColor: colors.white.main,
                              fontSize: "1.25em",
                              paddingInline: ".3em",
                              borderRadius: ".3em",
                            }}
                            value={app.totalNApplied}
                            onChange={(e) =>
                              SetTotalNApplied(e.target.value, ind)
                            }
                          />
                        }
                      />
                    </Grid>
                  )}
                  {showAmmonium(app) &&
                    (!fertilizerRatios[app.fertilizerType.id] ||
                      fertilizerRatios[app.fertilizerType.id].mineralN !==
                        0) && (
                      <Grid item xs={11} xxl={3}>
                        <CFManagementSelectionCard
                          label="Ammonium"
                          labelWidth={6.5}
                          input={
                            <Input
                              label="%"
                              endAdornment={
                                <InputAdornment position="end">
                                  %{" "}
                                </InputAdornment>
                              }
                              type="number"
                              sx={{
                                backgroundColor: colors.white.main,
                                fontSize: "1.25em",
                                paddingInline: ".3em",
                                borderRadius: ".3em",
                              }}
                              value={app.percentAmmonia}
                              onChange={(e) =>
                                SetPercentAmmonia(e.target.value, ind)
                              }
                            />
                          }
                        />
                      </Grid>
                    )}
                </Grid>
              </Grid>
            ))}
          <HelpAndButton
            buttonLabel="+ Add Fertilizer Application"
            buttonAction={addNewFertilizerApplication}
            helperText={
              <CFBox sx={{ overflow: "auto", height: "600px" }}>
                <p>
                  Users may add fertilizer, manure, or compost events in the
                  Nutrient Management activity data section. The total nitrogen
                  (lbs/ac) applied to the crop will be summed when fertilizer
                  and/or organic amendment events are added.
                </p>
                <br />
                <p>
                  To include fertilizer application(s) as a part of management,
                  select <strong>+Add Fertilizer Application</strong>. Users
                  will be prompted to select the date of their application, as
                  well as their fertilizer type from a dropdown menu. Once a
                  value is entered for either Total Amount Applied or Total N
                  Applied, the other value will update by default - and vice
                  versa - based on the selected fertilizer type. For instance,
                  if a user selects Urea Ammonium Nitrate (30-0-0) and enters 11
                  lbs of N applied, COMET will default the Total Amount Applied
                  to 34.38 lbs/ac.
                </p>
                <br />
                <p>
                  DayCent cannot support multiple fertilizer events on the same
                  day. To accommodate for this limitation, users can either:
                </p>
                <br />
                <ul
                  style={{
                    listStyleType: "disc",
                    marginLeft: "2em",
                  }}
                >
                  <li>
                    Enter sequential dates for each fertilizer type. For
                    example, if two fertilizer events occurred on May 15th,
                    enter one event on May 15 and one event on May 14.
                  </li>
                  <li>
                    Sum the total amount of nitrogen applied across fertilizer
                    events and add it as one singular event on one day
                  </li>
                </ul>
                <img src={FertHelp} width="100%" alt="Add Fertilizer Help" />
              </CFBox>
            }
          />{" "}
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
}
