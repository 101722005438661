/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { InputAdornment, Link } from "@mui/material";
import CFInput from "components/CFInput";
import CFTypography from "components/CFTypography";
import CFLookupPicker from "components/CroplandsComponents/CFLookupPicker/CFLookupPicker";
import CFBox from "components/CFBox";
import { post } from "utils/api";
import { popupError } from "utils/generic";
import CFButton from "components/CFButton";

import ManFllwUp from "assets/cFarmImages/HelpImages/manure follow up one pager.webp";
import {
  MANURE_FOLLOWUP_QUES,
  UPDATE_MANURE_FOLLOWUP,
  UPDATE_MANURE_FOLLOWUP_LOOKUP,
  FETCH_MANURE_FOLLOWUP,
} from "constants/api";

import colors from "assets/theme/base/colors";
import CFStep from "components/CFarmParts/CFStep";
import CFLoading from "components/CFLoading";

function GridSection({ children }) {
  return (
    <Grid
      container
      xs={8}
      border="thin solid #ddd"
      borderRadius=".3em"
      marginBottom=".5em"
      paddingBlockStart=".75em"
      paddingInline=".75em"
    >
      {children}
    </Grid>
  );
}

function ManureFollowup(props) {
  const { anagOpObject, anagObjectChanged, scenObjectChanged } = props;
  const dispatch = useDispatch();
  const empty = { id: 0, name: "select" };

  const [holdingPondTime, setHoldingPondTime] = useState(empty);

  const [holdingPondTimeList, setHoldingPondTimeList] = useState([]);

  const [storageTankArea, setStorageTankArea] = useState("");
  const [storageTankTime, setStorageTankTime] = useState(empty);
  const [storageTankTimeList, setStorageTankTimeList] = useState([]);
  const [anaerobicLagoonArea, setAnaerobicLagoonArea] = useState("");
  const currentUser = useSelector((state) => state.user);
  const [expanded, setExpanded] = useState("");
  const [expandedParent, setExpandedParent] = useState("");
  const [anaerobicLagoonQues, setAnaerobicLagoonQues] = useState(false);
  const [storageTankQues, setStorageTankQues] = useState(false);
  const [sldHoldPondQues, setSldHoldPondQues] = useState(false);
  const [lqdHoldPondQues, setLqdHoldPondQues] = useState(false);
  const [saveSuccessMsg, setSaveSuccessMsg] = useState(false);
  const [loading, setLoading] = useState(false);
  const { info } = colors;
  const holdPondTime = (holdPondObj) => {
    const sldHoldTime = {
      id: holdPondObj.sldHoldPondTimeId,
      name: holdPondObj.sldHoldPondTimeName,
    };
    const lqdHoldTime = {
      id: holdPondObj.lqdHoldPondTimeId,
      name: holdPondObj.lqdHoldPondTimeName,
    };
    const defaultVal = { id: 0, name: "select" };

    if (sldHoldPondQues && !lqdHoldPondQues) {
      return sldHoldTime;
    }
    if (!sldHoldPondQues && lqdHoldPondQues) {
      return lqdHoldTime;
    }
    if (sldHoldPondQues && lqdHoldPondQues) {
      return sldHoldTime;
    }
    if (!sldHoldPondQues && !lqdHoldPondQues) {
      return defaultVal;
    }
    return defaultVal;
  };

  const fetchManureFollowup = () => {
    setLoading(true);
    const flwUpObj = {
      id: currentUser,
      scenarioId: anagOpObject.selectedScenarioId,
    };
    post(FETCH_MANURE_FOLLOWUP, flwUpObj)
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          setLoading(false);
        } else {
          const strgeTankTime = {
            id: res.data.storageTankTimeId,
            name: res.data.storageTankTimeName,
          };

          const sldHoldPondTime = {
            id: res.data.sldHoldPondTimeId,
            name: res.data.sldHoldPondTimeName,
          };
          const lqdHoldPondTime = {
            id: res.data.lqdHoldPondTimeId,
            name: res.data.lqdHoldPondTimeName,
          };

          const hldPondTime = sldHoldPondQues
            ? sldHoldPondTime
            : lqdHoldPondTime;
          setHoldingPondTime(hldPondTime);

          setStorageTankTime(strgeTankTime);
          setStorageTankArea(res.data.storageTankArea);
          setAnaerobicLagoonArea(res.data.anaerobicLagoonArea);
          setSaveSuccessMsg(false);
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const manureFollowupExists = () => {
    setLoading(true);

    const followupObj = {
      id: currentUser,
      scenarioId: anagOpObject.selectedScenarioId,
    };
    post(MANURE_FOLLOWUP_QUES, followupObj)
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          setLoading(false);
        } else {
          setAnaerobicLagoonQues(res.data.askAnaerobicLagoonQues);
          setStorageTankQues(res.data.askStorageTankQuest);
          setSldHoldPondQues(res.data.askSldHoldPondQuest);
          setLqdHoldPondQues(res.data.askLqdHoldPondQuest);
          setSaveSuccessMsg(false);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleStorageTankAreaChange = (event) => {
    setStorageTankArea(event.target.value);
  };
  const handleAnaerobicLagoonAreaChange = (event) => {
    setAnaerobicLagoonArea(event.target.value);
  };

  const fetchManureFollowUpLookUps = () => {
    setLoading(true);

    const lookUpObj = {
      storageTime: "HOUSTRGETME",
    };
    post(UPDATE_MANURE_FOLLOWUP_LOOKUP, lookUpObj)
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          setLoading(false);
        } else {
          const json = JSON.parse(res.data);

          setHoldingPondTimeList(json.storageTimeList);

          setStorageTankTimeList(json.storageTimeList);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const updateManureFollowup = () => {
    setLoading(true);
    const animalTypesObj = {
      anlArea: anaerobicLagoonArea,
      sTankArea: storageTankArea,
      sTnkTime: storageTankTime.id,
      sldHldPndTime: sldHoldPondQues ? holdingPondTime.id : 212450,
      lqdHldPndTime: lqdHoldPondQues ? holdingPondTime.id : 212450,
      scenarioId: anagOpObject.selectedScenarioId,
      id: currentUser,
    };
    post(UPDATE_MANURE_FOLLOWUP, animalTypesObj)
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          setLoading(false);
        } else {
          setSaveSuccessMsg(true);
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    setExpanded("");
    setExpandedParent("");
  }, [anagOpObject.selectedScenario]);
  // ...
  useEffect(() => {
    if (anagOpObject.id !== "" && anagOpObject.id !== 0) {
      anagObjectChanged(false);
      // adding this fetch call here since I removed the accordion
      manureFollowupExists();
      fetchManureFollowup();
      fetchManureFollowUpLookUps();
    }
  }, [anagOpObject.activeAnimalCategories]);

  return (
    <>
      {loading && <CFLoading local />}

      {anagOpObject.id !== "" &&
        anagOpObject.countyId !== 0 &&
        (anaerobicLagoonQues ||
          storageTankQues ||
          sldHoldPondQues ||
          lqdHoldPondQues) && (
          <GridSection>
            <CFBox>
              <CFStep
                stepLabel="Manure Follow-up Questions"
                stepNumber="4"
                helpText={
                  <CFBox
                    sx={{
                      overflow: "auto",
                      height: "600px",
                      maxHeight: "60vh",
                    }}
                  >
                    <img
                      src={ManFllwUp}
                      width="100%"
                      alt="Manure Follow-up Help"
                    />
                  </CFBox>
                }
              />
              <br />
              <CFBox>
                <CFTypography
                  sx={{
                    fontSize: "14px",
                    marginBottom: "2em",
                    marginLeft: "2em",
                  }}
                  fontWeight="normal"
                >
                  Based on the manure storage or treatment option chosen, enter
                  the following details for the entire operation.
                </CFTypography>
              </CFBox>

              <Grid container direction="row" xs={12} sm={12} md={12}>
                {(sldHoldPondQues || lqdHoldPondQues) && (
                  <Grid item xs={12}>
                    <CFBox
                      sx={{
                        backgroundColor: colors.background.header,
                        boxShadow: "0px 1px 3px 0px #bbb",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        marginInline: "2em",
                        marginBottom: "1em",
                      }}
                    >
                      <CFTypography
                        sx={{
                          fontSize: "15px",
                          color: info.dullFocus,

                          marginLeft: "2em",
                        }}
                        style={{ width: "53%" }}
                      >
                        What is the average length of time manure is stored in
                        all runoff holding pond(s)?
                      </CFTypography>
                      <CFLookupPicker
                        key="holdPondTime"
                        name="holdPondTime"
                        label="Select storage length"
                        required
                        style={{
                          marginTop: "1em",
                          marginLeft: "2em",
                          width: "40%",
                        }}
                        value={holdingPondTime}
                        onChange={(event, newValue) => {
                          setHoldingPondTime({
                            name: newValue.name,
                            id: newValue.id,
                          });
                        }}
                        options={holdingPondTimeList}
                      />
                    </CFBox>
                  </Grid>
                )}

                {storageTankQues && (
                  <Grid item xs={12}>
                    <CFBox
                      sx={{
                        backgroundColor: colors.background.header,
                        boxShadow: "0px 1px 3px 0px #bbb",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        marginInline: "2em",
                        marginBottom: "1em",
                      }}
                    >
                      <CFTypography
                        sx={{
                          fontSize: "15px",
                          color: info.dullFocus,

                          marginLeft: "2em",
                        }}
                      >
                        What is the average length of time manure is stored in
                        all storage tank(s)?
                      </CFTypography>
                      <CFLookupPicker
                        key="strgeTankTime"
                        name="strgeTankTime"
                        label="Select storage length"
                        required
                        style={{
                          marginTop: "1em",
                          marginLeft: "2em",
                          width: "40%",
                        }}
                        value={storageTankTime}
                        onChange={(event, newValue) => {
                          setStorageTankTime({
                            name: newValue.name,
                            id: newValue.id,
                          });
                        }}
                        options={storageTankTimeList}
                      />
                    </CFBox>
                  </Grid>
                )}
                {storageTankQues && (
                  <Grid item xs={12}>
                    <CFBox
                      sx={{
                        backgroundColor: colors.background.header,
                        boxShadow: "0px 1px 3px 0px #bbb",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        marginInline: "2em",
                        marginBottom: "1em",
                      }}
                    >
                      <CFTypography
                        sx={{
                          fontSize: "15px",
                          color: info.dullFocus,

                          marginLeft: "2em",
                        }}
                      >
                        Enter the total exposed surface area of all uncovered
                        storage tanks with a crust:
                      </CFTypography>
                      <CFBox
                        sx={{
                          display: "flex",
                        }}
                      >
                        <CFInput
                          sx={{
                            padding: "10px",
                            fieldset: {
                              borderColor: info.dullFocus,
                              borderStyle: "solid",
                              borderWidth: "2px",
                            },
                          }}
                          color="primary"
                          id="strgeTankArea"
                          name="strgeTankArea"
                          value={storageTankArea}
                          onChange={handleStorageTankAreaChange}
                          placeholder=""
                          endAdornment={
                            <InputAdornment position="end">sqft</InputAdornment>
                          }
                          style={{ width: "30%", marginLeft: "2em" }}
                        />
                        <CFTypography
                          sx={{
                            fontSize: "15px",
                            color: info.dullFocus,
                            marginRight: "3em",
                            marginLeft: "1em",
                          }}
                          style={{ width: "10%" }}
                        >
                          sqft
                        </CFTypography>
                      </CFBox>
                    </CFBox>
                  </Grid>
                )}
                {anaerobicLagoonQues && (
                  <Grid item xs={12}>
                    <CFBox
                      sx={{
                        backgroundColor: colors.background.header,
                        boxShadow: "0px 1px 3px 0px #bbb",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        marginInline: "2em",
                        marginBottom: "1em",
                      }}
                    >
                      <CFTypography
                        sx={{
                          fontSize: "15px",
                          color: info.dullFocus,

                          marginLeft: "2em",
                        }}
                      >
                        Enter the total exposed surface area of all uncovered
                        anaerobic lagoons with a crust:
                      </CFTypography>
                      <CFBox
                        sx={{
                          display: "flex",
                        }}
                      >
                        <CFInput
                          sx={{
                            padding: "10px",
                            fieldset: {
                              borderColor: info.dullFocus,
                              borderStyle: "solid",
                              borderWidth: "2px",
                            },
                          }}
                          color="primary"
                          id="anarbcLgnArea"
                          name="anarbcLgnArea"
                          value={anaerobicLagoonArea}
                          onChange={handleAnaerobicLagoonAreaChange}
                          placeholder=""
                          style={{ width: "30%", marginLeft: "2em" }}
                        />
                        <CFTypography
                          sx={{
                            fontSize: "15px",
                            color: info.dullFocus,
                            marginRight: "3em",
                            marginLeft: "1em",
                          }}
                          style={{ width: "10%" }}
                        >
                          sqft
                        </CFTypography>
                      </CFBox>
                    </CFBox>
                  </Grid>
                )}
              </Grid>

              <Grid container py={2} px={2} justifyContent="space-around">
                <Grid item xs={12} sm={12} md={12}>
                  <CFButton
                    type="submit"
                    sx={{
                      float: "right",
                    }}
                    color="progress"
                    onClick={() => updateManureFollowup()}
                    // disabled={showNameError}
                  >
                    Save
                  </CFButton>
                </Grid>
              </Grid>
              {saveSuccessMsg && (
                <Grid container justifyContent="space-around">
                  <Grid item xs={12} sm={12} md={12} alignItems="center">
                    <CFBox
                      id="successMessage"
                      justifyContent="center"
                      display="grid"
                    >
                      <CFTypography
                        sx={{
                          fontSize: "15px",
                          color: "green",
                          marginBottom: "0.5em",
                          marginTop: "0.5em",
                        }}
                      >
                        Save was successful.
                      </CFTypography>
                    </CFBox>
                  </Grid>
                </Grid>
              )}
            </CFBox>
          </GridSection>
        )}
    </>
  );
}
export default ManureFollowup;
