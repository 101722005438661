import {
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Tooltip,
} from "@mui/material";

import { useEffect, useState } from "react";

import { Close } from "@mui/icons-material";
import CFTypography from "components/CFTypography";

import CFManagementSelectionCard from "components/CroplandsComponents/CFManagementSelectionCard/CFManagementSelectionCard";
import CFLookupPicker from "components/CroplandsComponents/CFLookupPicker/CFLookupPicker";
import TillageHelpImage from "assets/cFarmImages/HelpImages/TillageHelpImage.webp";
import { preloadImage } from "utils/generic";
import HelpAndButton from "../../../../../components/CroplandsComponents/EditCropCard_StructuralComponents/HelpAndButton";
import TilalgeImplementCalculator from "./TillageImplementCalculator";

const empty = { id: 0, name: "None" };
export default function ({
  CropEvent,
  SetPropertyFunction,
  field,
  tillages,
  tillageImplements,
  tillageIntensities,
}) {
  preloadImage(TillageHelpImage);
  const [tilalgeCalculatorOpen, setTilalgeCalculatorOpen] = useState(false);

  const [PostHarvTillName, setPostHarvTillName] = useState("NONE");
  const [PrePlantingTillageName, setPrePlantingTillageName] = useState("NONE");

  useEffect(() => {
    setPostHarvTillName(
      CropEvent.postHarvestTillage?.tillage
        ? CropEvent.postHarvestTillage.tillage.name
        : "NONE"
    );
    setPrePlantingTillageName(
      CropEvent.prePlantingTillage?.tillage
        ? CropEvent.prePlantingTillage.tillage.name
        : "NONE"
    );
  }, [
    CropEvent.prePlantingTillage?.tillage,
    CropEvent.postHarvestTillage?.tillage,
  ]);

  const openTillageIntensityCalculator = () => {
    setTilalgeCalculatorOpen(true);
  };
  const FieldName = field.name;
  const setPostHarvestTillage = (newVal) => {
    const newCE = { ...CropEvent };
    if (newVal === null || newVal.name === "None") {
      // TODO NEED TO FIX DELETE SO IT ACTUALLY WORKS
      newCE.postHarvestTillage = null;
    } else if (newCE.postHarvestTillage) {
      newCE.postHarvestTillage.tillage = newVal;
    } else {
      newCE.postHarvestTillage = {
        id: 0,
        tillage: newVal,
        tillageDate: "0001-01-01T00:00:00",
      };
    }

    SetPropertyFunction(newCE);
  };
  const setPrePlantingTillage = (newVal) => {
    const newCE = { ...CropEvent };
    console.log("prep", newVal);
    if (newVal === null || newVal.name === "None") {
      // TODO NEED TO FIX DELETE SO IT ACTUALLY WORKS
      newCE.prePlantingTillage = null;
    } else if (newCE.prePlantingTillage) {
      newCE.prePlantingTillage.tillage = newVal;
    } else {
      newCE.prePlantingTillage = {
        id: 0,
        tillage: newVal,
        tillageDate: "0001-01-01T00:00:00",
      };
    }

    SetPropertyFunction(newCE);
  };

  const setBoth = (pre, post) => {
    const newCE = { ...CropEvent };
    if (pre === null || pre.name === "None") {
      // TODO NEED TO FIX DELETE SO IT ACTUALLY WORKS
      newCE.prePlantingTillage = pre;
    } else if (newCE.prePlantingTillage) {
      newCE.prePlantingTillage.tillage = pre;
    } else {
      newCE.prePlantingTillage = {
        id: 0,
        tillage: pre,
        tillageDate: "0001-01-01T00:00:00",
      };
    }
    if (post === null || post.name === "None") {
      // TODO NEED TO FIX DELETE SO IT ACTUALLY WORKS
      newCE.postHarvestTillage = post;
    } else if (newCE.postHarvestTillage) {
      newCE.postHarvestTillage.tillage = post;
    } else {
      newCE.postHarvestTillage = {
        id: 0,
        tillage: post,
        tillageDate: "0001-01-01T00:00:00",
      };
    }
    SetPropertyFunction(newCE);
  };

  const handleSeedingMethodChange = (radioIn) => {
    const newCE = { ...CropEvent };

    newCE.seedDrill = radioIn.target.value;
    SetPropertyFunction(newCE);
  };

  return (
    <Grid alignItems="center" container flexDirection="row">
      <Grid item xs={12} sx={{ paddingLeft: "2em" }}>
        <CFTypography fontSize="1em">
          Please use the Tillage Intensity Calculator below to identify and
          populate the tillage intensity of your system.
        </CFTypography>
      </Grid>
      <Grid item xs={12}>
        <HelpAndButton
          buttonLabel="Tillage Intensity Calculator"
          buttonAction={openTillageIntensityCalculator}
          helperText={
            <div>
              As outlined in the{" "}
              <a href="https://www.usda.gov/oce/entity-scale-ghg-methods">
                {" "}
                USDA Inventory Methods document
              </a>
              , tillage implements and timing are required for the Tier 3 method
              using DayCent. Users are strongly encouraged to enter a tillage
              system intensity for all crops where a planting and harvest date
              is specified. Tillage intensities are categorized into full
              intensive tillage, reduced till, and no-till depending on the
              tillage implements and the number of passes. The{" "}
              <strong>Tillage Intensity Calculator</strong> helps users identify
              the tillage intensities that best represent their operation.
              <img
                src={TillageHelpImage}
                width="100%"
                alt="Tillage Intensity Help"
              />
            </div>
          }
        />{" "}
      </Grid>
      <Grid item xs={11}>
        <CFManagementSelectionCard
          label="Pre-Planting/Planting Season Tillage System Intensity"
          labelWidth={6}
          input={
            <CFLookupPicker
              label="Select An Intensity"
              key="preplantingtillage"
              name="preplantingtillage"
              required
              sx={{ width: "100%" }}
              value={
                CropEvent.prePlantingTillage?.tillage
                  ? CropEvent.prePlantingTillage?.tillage
                  : empty
              }
              onChange={(event, newValue) => {
                setPrePlantingTillage(newValue);
              }}
              options={tillages}
            />

            /*
            <Grid container>
              <Grid item xs={11}>
                <CFTypography justifyContent="flex-end" display="flex">
                  {PrePlantingTillageName}
                </CFTypography>
              </Grid>
              <Grid item xs={1}>
                {CropEvent.prePlantingTillage?.tillage && (
                  <IconButton
                    onClick={() => setPrePlantingTillage(null)}
                    style={{
                      // right: ".5em",
                      padding: 0,

                      paddingLeft: "1em",
                    }}
                  >
                    <Tooltip title="Clear Pre-Planting Tillage">
                      <Close
                        size="large"
                        style={{
                          fontWeight: 800,
                          color: "red",
                          transform: "scale(1.8)",
                          paddingRight: ".2em",
                        }}
                      />
                    </Tooltip>
                  </IconButton>
                }
              </Grid>
            </Grid> */
          }
        />
      </Grid>
      <Grid item xs={11}>
        <CFManagementSelectionCard
          label="Post-Harvest/Harvest Season Tillage System Intensity"
          labelWidth={6}
          input={
            <CFLookupPicker
              label="Select An Intensity"
              key="postHavesttillage"
              name="postHavesttillage"
              required
              sx={{ width: "100%" }}
              value={
                CropEvent.postHarvestTillage?.tillage
                  ? CropEvent.postHarvestTillage?.tillage
                  : empty
              }
              onChange={(event, newValue) => {
                setPostHarvestTillage(newValue);
              }}
              options={tillages}
            />
            /*
            <Grid container>
              <Grid item xs={11}>
                <CFTypography justifyContent="flex-end" display="flex">
                  {PostHarvTillName}
                </CFTypography>
              </Grid>
              <Grid item xs={1}>
                {CropEvent.postHarvestTillage?.tillage && (
                  <IconButton
                    onClick={() => setPostHarvestTillage(null)}
                    style={{
                      // right: ".5em",
                      padding: 0,

                      paddingLeft: "1em",
                    }}
                  >
                    <Tooltip title="Clear Post-Harvest Tillage">
                      <Close
                        size="large"
                        style={{
                          fontWeight: 800,
                          color: "red",
                          transform: "scale(1.8)",
                          paddingRight: ".2em",
                        }}
                      />
                    </Tooltip>
                  </IconButton>
                }
              </Grid>
            </Grid> */
          }
        />
      </Grid>
      {CropEvent.id === 244 && field.stateCode === "CA" && (
        <Grid item xs={11}>
          <CFManagementSelectionCard
            label="Seeding method:"
            labelWidth={8}
            input={
              <RadioGroup
                row
                name="seederMethodRadio"
                value={CropEvent.seedDrill}
                onChange={handleSeedingMethodChange}
              >
                {/* to be decided on the backend. Not even sure how we'll save or use this */}
                <FormControlLabel
                  value="water"
                  control={<Radio />}
                  label="Water seeded"
                />
                <FormControlLabel
                  value="drill"
                  control={<Radio />}
                  label="Drill seeded"
                />
              </RadioGroup>
            }
          />
        </Grid>
      )}
      <TilalgeImplementCalculator
        isOpen={tilalgeCalculatorOpen}
        setIsOpen={setTilalgeCalculatorOpen}
        tillageImplements={tillageImplements}
        tillageIntensities={tillageIntensities}
        setPrePlantingTillage={setPrePlantingTillage}
        setPostHarvestTillage={setPostHarvestTillage}
        setBoth={setBoth}
        hidePlanting={CropEvent.continueFromPrevious}
      />
    </Grid>
  );
}
