/**
=========================================================
*  React React - v1.0.0
=========================================================

* Product Page: https://comet-farm.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://comet-farm.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef } from "react"; // prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Custom styles for CFButton
import CFButtonRoot from "components/CFButton/CFButtonRoot";

const CFButton = forwardRef(
  (
    { label, color, variant, size, circular, iconOnly, children, ...rest },
    ref
  ) => (
    <CFButtonRoot
      {...rest}
      ref={ref}
      variant={variant === "gradient" ? "contained" : variant}
      size={size}
      ownerState={{ color, variant, size, circular, iconOnly }}
      style={{ textTransform: "none" }}
    >
      {children || label}
    </CFButtonRoot>
  )
);

// Setting default values for the props of CFButton
CFButton.defaultProps = {
  size: "large",
  variant: "gradient",
  color: "primary",
  circular: false,
  iconOnly: false,
  label: "",
};

// Typechecking props for the CFButton
CFButton.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
  variant: PropTypes.oneOf(["text", "contained", "outlined", "gradient"]),
  color: PropTypes.oneOf([
    "default",
    "white",
    "primary",
    "secondary",
    "progress",
    "highlight",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  circular: PropTypes.bool,
  iconOnly: PropTypes.bool,
  label: PropTypes.node,
  children: PropTypes.node.isRequired,
};

export default CFButton;
