// React
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

// MUI
import { Grid, Dialog } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import colors from "assets/theme/base/colors";

// Our Components
import CFBox from "components/CFBox";

import CFTypography from "components/CFTypography";
import CFButton from "components/CFButton";
import { updateLoading } from "store/reducers/mask";
import { post } from "utils/api";
import { popupError } from "utils/generic";
import { FETCH_ALL_SCENARIOS } from "constants/api";
import CFLoading from "components/CFLoading";

// Constants

export default function ScenarioCard({
  isOpen,
  setIsOpen,
  scenarioSel,

  animalobject,
  animalobjectChanged,
}) {
  const dispatch = useDispatch();

  const { info } = colors;

  const [scenarioArr, setScenarioArr] = useState([]);
  const [scenarioSelected, setScenarioSelected] = useState("");

  const currentUser = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
  };
  const handleContinue = () => {
    scenarioSel(true, scenarioSelected);
    setIsOpen(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // setLoading(true);
  };

  const handleScenarioSelChange = (event) => {
    setScenarioSelected(event.target.value);
  };

  const fetchAllScenarios = () => {
    setLoading(true);
    const scenarioObj = {
      id: currentUser,
    };

    post(FETCH_ALL_SCENARIOS, scenarioObj)
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          setLoading(false);
        } else {
          const json = res.data;
          setScenarioArr(json);
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchAllScenarios();
  }, [isOpen]);
  return (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth="md"
      maxHeight="md"
      sx={{ zIndex: 5 }}
    >
      {loading && <CFLoading local />}
      <Grid container item>
        {/* Main form */}
        <CFBox
          component="form"
          onSubmit={handleSubmit}
          width="100%"
          sx={{ padding: "2em" }}
        >
          {/* Grid with four rows: title, step one and two, step three, and action buttons */}
          <Grid container direction="row" item xs={12} sm={12} md={12}>
            {/* Window Header */}

            {/* Grid for steps one and two */}
            <Grid container direction="row">
              {/* Step one */}

              {/* Step two */}
              <Grid
                item
                container
                xs={12}
                id="step2box"
                display="flex"
                alignItems="start"
                justifyContent="start"
              >
                <CFTypography
                  sx={{
                    fontSize: "20px",
                    color: info.dullFocus,
                    fontWeight: "bold",

                    marginBottom: "0.5em",
                    marginTop: "0.1em",
                  }}
                >
                  Copy Management to New Scenario
                </CFTypography>
              </Grid>
              <Grid
                item
                container
                xs={12}
                id="step2box"
                display="flex"
                alignItems="start"
                justifyContent="start"
              >
                <CFTypography
                  sx={{
                    fontSize: "12px",
                    color: info.dullFocus,
                    fontWeight: "bold",

                    marginBottom: "0.5em",
                    marginTop: "0.1em",
                  }}
                >
                  To copy management details from an existing scenario to a new
                  scenario, select the completed scenario from the list below.
                  All management practices from the completed scenario will be
                  copied to the new scenario. The new scenario may be edited
                  once completed.
                </CFTypography>
              </Grid>
            </Grid>
            <Grid container direction="row" item xs={12} sm={12} md={12}>
              <Grid item xs={12}>
                <RadioGroup
                  name="scenarioSel"
                  value={scenarioSelected}
                  onChange={(e) => handleScenarioSelChange(e)}
                >
                  <FormControlLabel
                    value={212450}
                    control={<Radio />}
                    label="Continue without copying management"
                    style={{ marginLeft: "1em" }}
                  />
                  {scenarioArr.map((sce) => (
                    <FormControlLabel
                      key={sce.animalScenarioId}
                      value={sce.animalScenarioId}
                      control={<Radio />}
                      label={sce.animalScenarioName}
                      style={{ marginLeft: "1em" }}
                    />
                  ))}
                </RadioGroup>
              </Grid>
            </Grid>
            <Grid container direction="row" item xs={12} sm={12} md={12}>
              <Grid item xs={6} sm={6} md={6}>
                <CFButton
                  sx={{ marginLeft: "8em" }}
                  color="secondary"
                  onClick={() => handleClose()}
                >
                  Cancel
                </CFButton>
              </Grid>

              <Grid item xs={6} sm={6} md={6}>
                <CFButton
                  type="submit"
                  color="progress"
                  variant="gradient"
                  onClick={() => handleContinue()}
                  // disabled={showNameError}
                >
                  Continue
                </CFButton>
              </Grid>
            </Grid>
          </Grid>
        </CFBox>
      </Grid>
    </Dialog>
  );
}
ScenarioCard.defaultProps = {
  isOpen: false,
};

ScenarioCard.propTypes = {
  isOpen: PropTypes.bool,

  setIsOpen: PropTypes.func.isRequired,
  scenarioSel: PropTypes.func.isRequired,

  animalobject: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
    .isRequired,
  animalobjectChanged: PropTypes.func.isRequired,
};
