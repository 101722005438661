import WKT from "ol/format/WKT";

// Map colors
export const distinguishedColors = [
  "#e6194B",
  "#3cb44b",
  "#ffe119",
  "#4363d8",
  "#f58231",
  "#911eb4",
  "#42d4f4",
  "#f032e6",
  "#bfef45",
  "#fabed4",
  "#469990",
  "#dcbeff",
  "#9A6324",
  "#fffac8",
  "#800000",
  "#aaffc3",
  "#808000",
  "#ffd8b1",
  "#000075",
  "#a9a9a9",
  "#ffffff",
  "#000000",
];

// Get area of soil component in location
export function getSoilComponentArea(soilComponent, location) {
  const mapUnitformat = new WKT();

  const mapUnitGeom = soilComponent.soilComponent.MapUnitGeometryString;
  const geom = location.GeometryString;

  const feature = mapUnitformat.readFeature(geom, {
    dataProjection: "EPSG:3857",
  });
  const mapunitFeature = mapUnitformat.readFeature(mapUnitGeom, {
    dataProjection: "ESRI:3857",
  });

  const muArea = mapunitFeature.getGeometry().getArea();
  const locArea = feature.getGeometry().getArea();

  return { muArea, locArea };
}

// Get SOC for a given scenario
function getScenarioSocChange(baseScenId, cRiD, soilComponent) {
  const { BulkDensity } = soilComponent.soilComponent;

  // Scenario SOC
  const cRDaycentResults = soilComponent.daycent_results.filter(
    (x) => x.rotation === `${cRiD}`
  )[0];
  const cRsomsc = cRDaycentResults.Scenario_Lis_output.somsc[1].somsc_end;
  const cRsoc = cRsomsc / BulkDensity / 3000;

  // Baseline SOC
  const baseDaycentResults = soilComponent.daycent_results.filter(
    (x) => x.rotation === `${baseScenId}`
  )[0];
  const baseSomsc = baseDaycentResults.Scenario_Lis_output.somsc[1].somsc_end;
  const baseSoc = baseSomsc / BulkDensity / 3000;

  return cRsoc - baseSoc;
}

// Calculate result
function awhcCalc(somsc, bulkDensity, sandPer, clayPer, SHIsummaryval) {
  const sand = sandPer; // This value comes in as percentage
  const clay = clayPer; // This value comes in as percentage
  const OM = somsc / bulkDensity / 3000;

  // Non-calcareous computation
  if (!SHIsummaryval) {
    // Soil component wilting point
    const wp =
      7.222 +
      0.296 * clay +
      -0.074 * sand +
      -0.309 * OM +
      0.022 * sand * OM +
      0.022 * clay * OM;
    // OLD: 7.189 + 0.299C - 0.074S - 0.328SOC + 0.023(S*SOC) + 0.023(C*SOC), saving for now GM 8/13

    // Soil field capacity
    const fc =
      37.217 +
      -0.14 * clay +
      -0.304 * sand +
      -0.222 * OM +
      0.051 * sand * OM +
      0.085 * clay * OM +
      0.002 * clay * sand;
    // OLD: 37.178 - 0.139C - 0.304S - 0.197SOC + 0.051(S*SOC) + 0.085(C*SOC) + 0.002(C*S), saving for now GM 8/13

    // Soil water holding
    const awhc = fc - wp;

    return awhc;
  }

  // Calcareous computation
  // Soil component wilting point
  const wp =
    7.907 + 0.236 * clay + -0.082 * sand + 0.441 * OM + 0.002 * clay * sand;
  // OLD:  7.862 + 0.253C - 0.08S + 0.458SOC + 0.002(C*S), saving for now GM 8/13

  // Soil field capacity
  const fc =
    33.351 +
    0.02 * clay +
    -0.446 * sand +
    1.398 * OM +
    0.052 * sand * OM +
    -0.077 * clay * OM +
    0.011 * clay * sand;
  // OLD: 33.733 + 0.021C - 0.446S + 1.108SOC + 0.051(S*SOC) - 0.063(C*SOC) + 0.01(C*S), saving for now GM 8/13

  // Soil water holding
  const awhc = fc - wp;

  return awhc;
}

// Calculate result per inch
function awhcGalInchNum(soilComponent, cRDaycentResults) {
  try {
    const { FractionClay, FractionSand, BulkDensity } =
      soilComponent.soilComponent;
    const somsc = cRDaycentResults.Scenario_Lis_output.somsc[1].somsc_end;

    // Return "Not Determined" if clay is too great
    if (
      (soilComponent.SHIsummaryval && FractionClay > 35) ||
      (!soilComponent.SHIsummaryval && FractionClay > 60)
    ) {
      return "ND";
    }

    const awhc = awhcCalc(
      somsc,
      BulkDensity,
      FractionSand,
      FractionClay,
      soilComponent.SHIsummaryval
    );
    /* convert to water/(6 in ^ 3) */

    return (awhc / 100) * 6;
  } catch (e) {
    return "Missing Data Please Rerun Daycent";
  }
}

// Calculate result per acre
function awhcGalAcreNum(soilComponent, cRDaycentResults) {
  try {
    const { FractionClay, FractionSand, BulkDensity } =
      soilComponent.soilComponent;
    const somsc = cRDaycentResults.Scenario_Lis_output.somsc[1].somsc_end;

    // Return "Not Determined" if clay is too great
    if (
      (soilComponent.SHIsummaryval && FractionClay > 35) ||
      (!soilComponent.SHIsummaryval && FractionClay > 60)
    ) {
      return "ND";
    }

    const awhc = awhcCalc(
      somsc,
      BulkDensity,
      FractionSand,
      FractionClay,
      soilComponent.SHIsummaryval
    );

    return (awhc / 100) * 162925.71;
  } catch (e) {
    console.log(e);
    return "Missing Data Please Rerun Daycent";
  }
}

// Get baseline scenario values for single soil component
function getBaseValues(cR, soilComponent) {
  const ret = {};
  const cRDaycentResults = soilComponent?.daycent_results.filter(
    (x) => x.rotation === `${cR.Id}`
  )[0];
  ret.awhcGalAcre = awhcGalAcreNum(soilComponent, cRDaycentResults, 0);
  ret.awhcGalInch = awhcGalInchNum(soilComponent, cRDaycentResults, 0);
  ret.id = cR.Id;

  return ret;
}

// Get baseline scenario values for full field
function getBaseValuesSummary(cR, soilComponents) {
  const ret = {};

  let totArea = 0.0;
  let awhcGalAcre = 0;
  let awhcGalInch = 0;

  // Get total area and size of each soil component
  const mapUnitformat = new WKT();
  const soilComponentsWithArea = soilComponents.map((sc) => {
    const mapUnitGeom = sc.soilComponent.MapUnitGeometryString;

    const mapunitFeature = mapUnitformat.readFeature(mapUnitGeom, {
      dataProjection: "ESRI:3857",
    });

    const size = mapunitFeature.getGeometry().getArea();

    totArea += size;
    return { ...sc, size };
  });

  // Iterate through each soil component
  soilComponentsWithArea.forEach((sc) => {
    const cRDaycentResults = sc.daycent_results.filter(
      (x) => x.rotation === `${cR.Id}`
    )[0];

    const tmpAcre = awhcGalAcreNum(sc, cRDaycentResults);
    if (!Number.isNaN(tmpAcre)) awhcGalAcre += tmpAcre * (sc.size / totArea);

    const tmpInch = awhcGalInchNum(sc, cRDaycentResults);
    if (!Number.isNaN(tmpInch)) awhcGalInch += tmpInch * (sc.size / totArea);
  });

  ret.awhcGalAcre = awhcGalAcre;
  ret.awhcGalInch = awhcGalInch;
  ret.id = cR.Id;

  return ret;
}

// Get row values for display. Used for scenarios and baseline values
export function makeShiaRowSummary(
  cR,
  soilComponents,
  isBase = false,
  isSummary,
  baseScen
) {
  let awhcGalAcre = 0.0;
  let someValidAcre = false;
  let awhcGalInch = 0.0;
  let someValidInch = false;
  let totArea = 0.0;

  // Get total area and size of each soil component
  const mapUnitformat = new WKT();
  const soilComponentsWithArea = soilComponents.map((sc) => {
    const mapUnitGeom = sc.soilComponent.MapUnitGeometryString;

    const mapunitFeature = mapUnitformat.readFeature(mapUnitGeom, {
      dataProjection: "ESRI:3857",
    });

    const size = mapunitFeature.getGeometry().getArea();

    totArea += size;
    return { ...sc, size };
  });

  // Iterate through soil components
  soilComponentsWithArea.forEach((sc) => {
    const cRDaycentResults = sc.daycent_results.filter(
      (x) => x.rotation === `${cR.Id}`
    )[0];

    const soilBaseVals = getBaseValues(baseScen, sc);

    let tmpAcre = awhcGalAcreNum(sc, cRDaycentResults);
    // If delta AWHC < 0 and delta SOC is > 0, set AWHC to baseline values so delta is zero
    if (!isBase) {
      if (
        !Number.isNaN(tmpAcre) &&
        typeof tmpAcre !== "string" &&
        tmpAcre - soilBaseVals.awhcGalAcre < 0 &&
        getScenarioSocChange(soilBaseVals.id, cR.Id, sc) > 0
      ) {
        tmpAcre = soilBaseVals.awhcGalAcre;
      }
    }
    if (!Number.isNaN(tmpAcre) && typeof tmpAcre !== "string") {
      awhcGalAcre += tmpAcre * (sc.size / totArea);
      someValidAcre = true;
    }

    let tmpInch = awhcGalInchNum(sc, cRDaycentResults);
    // If delta AWHC < 0 and delta SOC is > 0, set AWHC to baseline values so delta is zero
    if (!isBase) {
      if (
        !Number.isNaN(tmpInch) &&
        typeof tmpInch !== "string" &&
        tmpInch - soilBaseVals.awhcGalInch < 0 &&
        getScenarioSocChange(soilBaseVals.id, cR.Id, sc) > 0
      ) {
        tmpInch = soilBaseVals.awhcGalInch;
      }
    }
    if (!Number.isNaN(tmpInch) && typeof tmpInch !== "string") {
      awhcGalInch += tmpInch * (sc.size / totArea);
      someValidInch = true;
    }
  });

  let awhcGalAcreString = someValidAcre
    ? awhcGalAcre.toLocaleString(undefined, {
        maximumFractionDigits: 0,
      })
    : "ND";
  let awhcGalInchString = someValidInch
    ? awhcGalInch.toLocaleString(undefined, {
        maximumFractionDigits: 3,
      })
    : "ND";

  if (!isBase) {
    const baseValues = isSummary
      ? getBaseValuesSummary(baseScen, soilComponents)
      : getBaseValues(baseScen, soilComponents[0]);

    // Convert scenario values to a change from baseline
    awhcGalAcre -= baseValues.awhcGalAcre;
    awhcGalInch -= baseValues.awhcGalInch;

    awhcGalAcreString = someValidAcre
      ? awhcGalAcre.toLocaleString(undefined, {
          maximumFractionDigits: 0,
        })
      : "ND";
    awhcGalInchString = someValidInch
      ? awhcGalInch.toLocaleString(undefined, {
          maximumFractionDigits: 3,
        })
      : "ND";

    if (awhcGalAcre > 0) {
      awhcGalAcreString = `+${awhcGalAcreString}`;
    }
    if (awhcGalInch > 0) {
      awhcGalInchString = `+${awhcGalInchString}`;
    }
  }

  return { awhcGalAcre: awhcGalAcreString, awhcGalInch: awhcGalInchString };
}
