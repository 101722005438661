import { Accordion, AccordionSummary } from "@mui/material";
import { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import colors from "assets/theme/base/colors";

export default function CroplandAccordionSection({
  summary,
  startState,
  bgColor,
  programaticTrigger,
  ...rest
}) {
  const { children } = rest;
  const [expanded, setExpanded] = useState(
    startState !== null && startState !== undefined ? startState : true
  );

  useEffect(() => {
    if (programaticTrigger && programaticTrigger !== 0) {
      // console.log("triggering");
      setExpanded(!expanded);
    }
  }, [programaticTrigger]);
  return (
    <Accordion
      sx={{
        borderRadius: ".3em",
      }}
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: colors.text.light }} />}
        sx={{
          backgroundColor: bgColor || colors.primary.main,
          borderRadius: ".3em",
          color: colors.text.light,
          borderStyle: "outset",
          flexDirection: "row-reverse",
        }}
      >
        {summary}
      </AccordionSummary>
      {children}
    </Accordion>
  );
}
