import { useState } from "react";
import CFStep from "components/CFarmParts/CFStep";
import CFBox from "components/CFBox";
import CroplandAccordionSection from "components/CroplandsComponents/CroplandAccordionSection/CroplandAccordionSection";
import colors from "assets/theme/base/colors";

import CropManagmentLayoutHelp from "assets/cFarmImages/HelpImages/CropManagmentLayoutHelp.webp";
import { preloadImage } from "utils/generic";
import { ContentCopy } from "@mui/icons-material";
import CropRotationManagement from "../CroplandComponents/CropRotationManagement/CropRotationManagement";
import style, { croplandTableStyle } from "../CroplandStyle";

function BaselineManagement({
  allOtherFields,
  field,
  stepNumber,
  goToPrevious,
  fetchProjectInformation,
}) {
  preloadImage(CropManagmentLayoutHelp);
  const { name } = field;
  const [complete, setIsComplete] = useState(false);

  const setComplete = (isComplete) => {
    setIsComplete(isComplete);
    if (fetchProjectInformation) {
      fetchProjectInformation();
    }
  };
  return (
    <CFBox
      id="Step3"
      sx={{ width: style.width, position: "relative", marginTop: "1em" }}
    >
      <CroplandAccordionSection
        startState
        bgColor={colors.background.header}
        summary={
          <CFStep
            stepLabel={
              <div>
                Baseline Crops & Management: <em>{name}</em>
              </div>
            }
            stepNumber={stepNumber}
            complete={complete}
            helpText={
              <div
                style={{ overflow: "auto", height: "600px", maxHeight: "60vh" }}
              >
                <style>{croplandTableStyle}</style>
                <img
                  src={CropManagmentLayoutHelp}
                  width="100%"
                  alt="Layout of Baseline Management"
                />
                <table>
                  <tbody>
                    <tr>
                      <td colSpan="2">Baseline Management Page Layout guide</td>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td>
                        Baseline Management section progress tracker. The
                        checkmark will remain grey until each year in the
                        defined baseline includes all required management
                        activity data (at least one crop per year with a
                        planting date or continued growth from previous year).
                        The checkmark will turn green when all requirement
                        management activity data for all baseline years is
                        entered.
                      </td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>
                        Crop Rotation Builder. This allows users to import crop
                        rotations with predefined management. Users will be able
                        to modify or remove most defaulted management activity
                        data.
                      </td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>
                        Crop selection drop down. All crops (annual, cover,
                        grass, orchard/vineyard, and fallow) are included in
                        this drop-down. Users are required to select from COMET
                        list of crops.
                      </td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>
                        Crop management activity edit. Use this button to make
                        any management changes to the corresponding crop. The
                        edit button refers to editing the crop to the left of
                        the pencil icon.
                      </td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>
                        Copy the year’s management to other years and/or fields.
                        This action will copy all crops and their respective
                        management within a calendar year to other crop years
                        and fields.
                      </td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>
                        Crop progress tracker. Users are required to add at
                        least one crop with a planting date, or a continued crop
                        from previous year, for every year. When users enter all
                        required management activity data for a given crop, the
                        grey check mark will turn green.
                      </td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>
                        Review all entered management activity data for all
                        crops in all years of a baseline for the working field.
                      </td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>
                        Copy Rotation to Other Field. This action will copy the
                        selected field’s entire crop rotation and all the
                        associated management to another field in the user’s
                        project. Note that some crops (e.g., rice) will not be
                        able to be copied to another field if it is not
                        available in that field’s location.
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            }
            subText={
              <CFBox sx={{ paddingLeft: "1em" }}>
                <ol>
                  <li>
                    Using the dropdown menu for each year, or using the{" "}
                    {`"Crop
                  Template"`}{" "}
                    button, add crop(s) that were
                    <strong> planted </strong>
                    for each year. COMET cannot support multiple crops growing
                    at the same time, with the exception of predefined cover
                    crop blends and cover crops in an orchard/vineyard system.
                  </li>
                  <li>
                    Using the {`"pencil"`} icon, edit or add management for each
                    crop year.
                  </li>
                  <li>
                    If no crop is <strong> planted </strong> in a given year,
                    select fallow as your {`"crop"`}.
                  </li>
                  <li>
                    When applicable, use the{" "}
                    <ContentCopy
                      color="black"
                      fontSize="large"
                      style={{ marginBottom: "-.2em" }}
                    />{" "}
                    to copy management from one year/field to another.
                  </li>
                </ol>
              </CFBox>
            }
          />
        }
      >
        <CropRotationManagement
          allOtherFields={allOtherFields}
          stepNumber={stepNumber}
          field={field}
          cropRotationId={field.baselineId}
          scenarioName="Baseline"
          goToPrevious={goToPrevious}
          fetchProjectInformation={fetchProjectInformation}
          setIsComplete={setComplete}
        />
      </CroplandAccordionSection>
    </CFBox>
  );
}
export default BaselineManagement;
