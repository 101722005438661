import { select } from "redux-saga/effects";

const enters = {};

export function* enterPage() {
  const state = yield select();
  const pageName = state.navigation.page;
  const entersFunc = enters[pageName];
  if (entersFunc) yield entersFunc(state);
}

export function startApp() {
  console.log("in history", window.history);

  window.history.pushState({}, "", "projects");
  window.location.reload();
}

const exits = {};

export function* exitPage({ payload }) {
  const state = yield select();

  const exitsFunc = exits[payload];
  if (exitsFunc) yield exitsFunc(state);
}
