import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { AccordionSummary, Grid } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Info } from "@mui/icons-material";

import CFBox from "components/CFBox";
import CFTypography from "components/CFTypography";
import CFInput from "components/CFInput";
import CFButton from "components/CFButton";
import colors from "assets/theme/base/colors";
import { updateLoading } from "store/reducers/mask";
import { post } from "utils/api";
import { UPDATE_POULTRY, FETCH_POULTRY } from "constants/api";
import { popupError } from "utils/generic";
import CFManagementSelectionCard from "components/CroplandsComponents/CFManagementSelectionCard/CFManagementSelectionCard";
import CFLookupPicker from "components/CroplandsComponents/CFLookupPicker/CFLookupPicker";
import CFLoading from "components/CFLoading";

const houseSelectionOptions = [
  { name: "", id: 0 },
  { name: "With litter", id: 216213 },
  { name: "Without litter", id: 216214 },
  { name: "With litter and use of acidifying agent", id: 216215 },
];

export default function PoultryHousing({
  id,
  yearValue,
  animalCategory,
  animalCategoryId,
  anagObject,
  anagObjectChanged,
  verifyDone,
}) {
  const dispatch = useDispatch();

  const [houseSelection, setHouseSelection] = useState({ name: "", id: 0 });

  const [saveState, setSaveState] = useState("");
  const [loading, setLoading] = useState(false);
  const validateUserInputs = () => {
    let isValid = false;
    if (houseSelection != null) {
      if (
        houseSelection.id === 216213 ||
        houseSelection.id === 216214 ||
        houseSelection.id === 216215
      ) {
        isValid = true;
      }
    }

    return isValid;
  };

  const validateFetchInputs = (data) => {
    let isValid = false;
    if (data != null) {
      if (data.id === 216213 || data.id === 216214 || data.id === 216215) {
        isValid = true;
      }
    }

    return isValid;
  };

  const updatePoultry = () => {
    setLoading(true);

    const postCall = UPDATE_POULTRY;
    const postObj = {
      popId: id,
      housingType: houseSelection.id,
    };

    post(postCall, postObj)
      .then((res) => {
        setLoading(false);
        if (res.error) {
          console.log("Error obj ", res.error);
          setSaveState("Error saving. Please try again.");
        } else {
          setSaveState("Save was successful");
          verifyDone("green");
        }
        setLoading(false);
      })
      .catch((err) => {
        setSaveState("Error saving. Please try again.");
        setLoading(false);
      });
  };

  useEffect(() => {
    post(FETCH_POULTRY, { popId: id })
      .then((res) => {
        setLoading(false);
        if (res.error) {
          popupError(res.error, dispatch);
          console.log("Error obj ", res.error);
        } else {
          setHouseSelection(res.data.housingTypeViewModel);
          console.log(res.data.housingTypeViewModel);
          if (validateFetchInputs(res.data.housingTypeViewModel)) {
            verifyDone("green");
          } else {
            verifyDone("grey");
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);
  return (
    <CFBox width="100%">
      {loading && <CFLoading local />}
      <Grid container item>
        {/* Main form */}
        <CFBox width="100%" marginBlock=".5em">
          <Grid container direction="row" xs={12} sm={12} md={12}>
            <Grid
              container
              xs={12}
              spacing={2}
              alignItems="center"
              direction="row"
            >
              <CFManagementSelectionCard
                label="Select poultry housing type:"
                style={{
                  fontSize: "16px",
                  color: colors.info.dullFocus,
                  backgroundColor: colors.background.header,
                }}
                labelWidth={8}
                input={
                  <CFLookupPicker
                    required
                    sx={{ width: "100%" }}
                    value={houseSelection}
                    onChange={(event, newValue) => {
                      setHouseSelection(newValue);
                    }}
                    options={houseSelectionOptions}
                  />
                }
              />
            </Grid>
          </Grid>
          <Grid container py={2} px={2} justifyContent="space-around">
            <Grid item xs={12} sm={12} md={12}>
              <CFButton
                type="submit"
                sx={{
                  float: "right",
                }}
                color="progress"
                onClick={updatePoultry}
                disabled={!validateUserInputs()}
              >
                Save
              </CFButton>
            </Grid>
          </Grid>
          <Grid container justifyContent="space-around">
            <Grid item xs={2} sm={2} md={2} alignItems="center">
              <CFBox>
                <CFTypography
                  sx={{
                    fontSize: "15px",
                    color: !saveState.includes("Error") ? "green" : "red",

                    marginBottom: "0.5em",
                    marginTop: "0.5em",
                  }}
                >
                  {saveState}
                </CFTypography>
              </CFBox>
            </Grid>
          </Grid>
        </CFBox>
      </Grid>
    </CFBox>
  );
}
