/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from "react-redux";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { InputAdornment } from "@mui/material";

import CFStep from "components/CFarmParts/CFStep";
import CFTypography from "components/CFTypography";
import CFInput from "components/CFInput";
import CFBox from "components/CFBox";
import CFButton from "components/CFButton";
import CFDialog from "components/CFDialog";
import colors from "assets/theme/base/colors";
import CFCheckbox from "components/CFCheckbox/CFCheckbox";
import CFSnackbar from "components/CFSnackbar";
import { popupError } from "utils/generic";
import { post } from "utils/api";
import {
  FETCH_ALL_CATEGORIES,
  UPDATE_ANIMAL_CATEGORIES,
  FETCH_DAIRY_GROUPS,
} from "constants/api";
import CFLoading from "components/CFLoading";
import style, { animalAgTableStyle } from "./AnimalAgStyle";

const { primary, info, text } = colors;
function ATAccordionSummary({ children }) {
  return (
    <AccordionSummary
      expandIcon={<GridExpandMoreIcon sx={{ color: colors.text.light }} />}
      sx={{
        backgroundColor: primary.main,
        borderRadius: ".3em",
        color: text.light,
        fontStyle: "bold",
        marginBottom: ".5em",
      }}
    >
      {children}
    </AccordionSummary>
  );
}

function AnimalTypes(props) {
  const { anagOpObject, anagObjectChanged, animalDetailsChanged } = props;

  const dispatch = useDispatch();
  const [anagCatArr, setAnagCatArr] = useState([]);

  const existingCat = [];
  const [localPopupState, updateLocalPopup] = useState({});
  // console.log("validation state: ", validationState);
  const [expandedCattle, setExpandedCattle] = useState("");
  const [expandedPoultry, setExpandedPoultry] = useState("");
  const [expandedSwine, setExpandedSwine] = useState("");
  const [expandedOtherRuminants, setExpandedOtherRuminants] = useState("");
  const [expandedOtherNonRuminants, setExpandedOtherNonRuminants] =
    useState("");
  const [dairyLactGroupCount, setDairyLactGroupCount] = useState("1");
  const [dairyHeiferGroupCount, setDairyHeiferGroupCount] = useState("1");
  const [dairyDryGroupCount, setDairyDryGroupCount] = useState("1");
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const currentUser = useSelector((state) => state.user);
  const expandToggleCattle = (panel) => (event, newExpanded) => {
    setExpandedCattle(newExpanded ? panel : false);
  };
  const expandTogglePoultry = (panel) => (event, newExpanded) => {
    setExpandedPoultry(newExpanded ? panel : false);
  };
  const expandToggleSwine = (panel) => (event, newExpanded) => {
    setExpandedSwine(newExpanded ? panel : false);
  };
  const expandToggleOtherRuminants = (panel) => (event, newExpanded) => {
    setExpandedOtherRuminants(newExpanded ? panel : false);
  };
  const expandToggleOtherNonRuminants = (panel) => (event, newExpanded) => {
    setExpandedOtherNonRuminants(newExpanded ? panel : false);
  };
  const handleDairyLactGroupCount = (e) => {
    if (e.target.value > 3) {
      setDairyLactGroupCount(3);
    } else if (e.target.value === 0) {
      setDairyLactGroupCount(1);
    }
    setDairyLactGroupCount(e.target.value);
  };
  const handleDairyHeiferGroupCount = (e) => {
    if (e.target.value > 3) {
      setDairyHeiferGroupCount(3);
    } else if (e.target.value === 0) {
      setDairyHeiferGroupCount(1);
    }
    setDairyHeiferGroupCount(e.target.value);
  };
  const handleDairyDryGroupCount = (e) => {
    if (e.target.value > 3) {
      setDairyDryGroupCount(3);
    } else if (e.target.value === 0) {
      setDairyDryGroupCount(1);
    }
    setDairyDryGroupCount(e.target.value);
  };
  const animalSelArr = [];
  const updateCheckBoxSel = {
    21247: false,
    21250: false,
    21253: false,
    21255: false,
    21260: false,
    21261: false,
    21262: false,
    21267: false,
    21268: false,
    21271: false,
    21273: false,
    21274: false,
    21275: false,
    21280: false,
    21281: false,
    21283: false,
    21284: false,
    21290: false,
    21291: false,
    21295: false,
    21296: false,
    21297: false,
  };
  const [animalTypeSel, setAnimalTypesSel] = useState(updateCheckBoxSel);
  const handleSelectionChange = (e) => {
    setAnimalTypesSel({
      ...animalTypeSel,
      [e.target.name]: e.target.checked,
    });
    if (e.target.name === 21260) {
      setDairyHeiferGroupCount("1");
    }
    if (e.target.name === 21261) {
      setDairyDryGroupCount("1");
    }
    if (e.target.name === 21262) {
      setDairyLactGroupCount("1");
    }
  };
  const GetNonActiveCategories = (activecategories) => {
    const allCatArr = [
      21247, 21250, 21253, 21255, 21260, 21261, 21262, 21267, 21268, 21271,
      21273, 21274, 21275, 21280, 21281, 21283, 21284, 21290, 21291, 21295,
      21296, 21297,
    ];
    const nonActiveArr = allCatArr.filter((x) => !activecategories.includes(x));
    return nonActiveArr;
  };

  const fetchAllCategories = () => {
    setLoading(true);
    post(FETCH_ALL_CATEGORIES)
      .then((res) => {
        if (res.error) {
          updateLocalPopup({
            message: res.error,
            title: "Error",
            showPopup: true,
            closeAction: () => {
              updateLocalPopup({
                showPopup: false,
              });
            },
          });
          setLoading(false);
        } else {
          try {
            const allCategoriesObj = res.data;

            setAnagCatArr(allCategoriesObj);
          } catch (e) {
            updateLocalPopup({
              message: e,
              title: "Error",
              showPopup: true,
              closeAction: () => {
                updateLocalPopup({
                  showPopup: false,
                });
              },
            });
          }
        }

        setLoading(false);
      })
      .catch((err) => {
        updateLocalPopup({
          message: err,
          title: "Error",
          showPopup: true,
          closeAction: () => {
            updateLocalPopup({
              showPopup: false,
            });
          },
        });
        setLoading(false);
      });
  };

  const getCatLabel = (cat) => {
    if (cat.id === 21260 || cat.id === 21261 || cat.id === 21262) {
      return (
        <CFBox style={{ width: "24em" }}>{cat.name} - How many groups?</CFBox>
      );
    }
    return cat.name;
  };

  useEffect(() => {
    fetchAllCategories();
  }, [anagOpObject.countyId]);
  const fetchDairyGroups = () => {
    setLoading(true);
    const popObj = {
      userid: currentUser,
      scenarioid: anagOpObject.selectedScenarioId,
    };
    post(FETCH_DAIRY_GROUPS, popObj)
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          setLoading(false);
        } else {
          const json = JSON.parse(res.data);

          setDairyHeiferGroupCount(json.heiferGroupCount);
          setDairyDryGroupCount(json.dryGroupCount);
          setDairyLactGroupCount(json.lactGroupCount);
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const animalSelState = () => {
    const activeCatergoriesObj = anagOpObject.activeAnimalCategories;

    const currAnagSel = { ...animalTypeSel };
    const nonActiveCategories = GetNonActiveCategories(activeCatergoriesObj);
    activeCatergoriesObj.map((cat) => {
      currAnagSel[cat] = true;
      return currAnagSel;
    });

    nonActiveCategories.map((noncat) => {
      currAnagSel[noncat] = false;
      return currAnagSel;
    });

    setAnimalTypesSel(currAnagSel);
  };
  useEffect(() => {
    if (
      anagOpObject.activeAnimalCategories !== "" &&
      anagOpObject.activeAnimalCategories.length > 0
    ) {
      animalSelState();
      fetchDairyGroups();
    }
  }, [anagOpObject.activeAnimalCategories]);
  const updateAnimalCategories = () => {
    const selectedAnimalEntries = Object.entries(animalTypeSel).filter(
      (x) => x[1] === true
    );

    const selectedAnimalCategories = selectedAnimalEntries.map((x) => x[0]);

    const animalTypesObj = {
      categories: selectedAnimalCategories,
      dairyHeiferCount: dairyHeiferGroupCount,
      dairyDryCount: dairyDryGroupCount,
      dairyLactCount: dairyLactGroupCount,
      id: anagOpObject.userId,
    };
    post(UPDATE_ANIMAL_CATEGORIES, animalTypesObj)
      .then((res) => {
        if (res.error) {
          popupError(res.error, dispatch);
          setLoading(false);
        } else {
          setSnackbarOpen(true);
          anagObjectChanged(true);
          animalDetailsChanged(true);
          document.getElementById("animalManagement").scrollIntoView();
        }
      })

      .catch((err) => {
        updateLocalPopup({
          message: err,
          title: "Error",
          showPopup: true,
          closeAction: () => {
            updateLocalPopup({
              showPopup: false,
            });
          },
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const resetDairyGroups = () => {
    if (anagOpObject.allActiveCategories.includes(21260)) {
      setDairyHeiferGroupCount(
        anagOpObject.allActiveCategories.filter((v) => v === 21260).length
      );
    }
    if (anagOpObject.allActiveCategories.includes(21261)) {
      setDairyDryGroupCount(
        anagOpObject.allActiveCategories.filter((v) => v === 21261).length
      );
    }
    if (anagOpObject.allActiveCategories.includes(21262)) {
      setDairyLactGroupCount(
        anagOpObject.allActiveCategories.filter((v) => v === 21262).length
      );
    }
  };
  const categoriesUpdatedWarning = () => {
    updateLocalPopup({
      message: `You have completed the data entry for selected animal categories/selected animal group(s), but are now switching animal categories or changing the number of groups. This action will delete all management and scenarios added for the animal/group and you will need to enter management for your new animal type/group. Do you want to continue to change your animal category/group number or cancel?`,
      title: `Change Animal Category`,
      closable: false,
      button1: {
        onClick: () => {
          animalSelState();
          resetDairyGroups();

          updateLocalPopup({
            showPopup: false,
          });
        },
        text: `Cancel`,
        variant: "gradient",
        color: "secondary",
      },
      button2: {
        text: "Continue",
        onClick: () => {
          updateAnimalCategories();

          updateLocalPopup({ showPopup: false });
        },
      },

      showPopup: true,
    });
  };
  const getCategoryCountWithGroup = (cats) => {
    let initLength = cats.length;
    if (initLength > 0) {
      if (cats.includes("21260")) {
        initLength += Number(dairyHeiferGroupCount) - 1;
      }
      if (cats.includes("21261")) {
        initLength += Number(dairyDryGroupCount) - 1;
      }
      if (cats.includes("21262")) {
        initLength += Number(dairyLactGroupCount) - 1;
      }
    }
    return initLength;
  };
  const getHeiferCount = () => {
    const heiferCount = anagOpObject.allActiveCategories.filter(
      (v) => v === 21260
    ).length;
    const dairyHeiferCount = heiferCount === 0 ? 1 : heiferCount;
    return dairyHeiferCount;
  };
  const getDryCount = () => {
    const dryCount = anagOpObject.allActiveCategories.filter(
      (v) => v === 21261
    ).length;
    const dairyDryCount = dryCount === 0 ? 1 : dryCount;
    return dairyDryCount;
  };
  const getLactCount = () => {
    const lactCount = anagOpObject.allActiveCategories.filter(
      (v) => v === 21262
    ).length;
    const dairyLactCount = lactCount === 0 ? 1 : lactCount;
    return dairyLactCount;
  };
  const submitAnimalCategories = (e) => {
    setLoading(true);
    e.preventDefault();

    const selectedAnimalEntries = Object.entries(animalTypeSel).filter(
      (x) => x[1] === true
    );
    const selectedAnimalCategories = selectedAnimalEntries.map((x) => x[0]);
    const finalAnimalEntriesWithGroup = getCategoryCountWithGroup(
      selectedAnimalCategories
    );
    const dairyHeiferCount = getHeiferCount();
    const dairyDryCount = getDryCount();
    const dairyLactCount = getLactCount();

    if (
      (anagOpObject.activeCategoriesLength !== finalAnimalEntriesWithGroup ||
        dairyHeiferGroupCount !== dairyHeiferCount ||
        dairyDryGroupCount !== dairyDryCount ||
        dairyLactGroupCount !== dairyLactCount) &&
      anagOpObject.scenarioLength > 1
    ) {
      setLoading(false);
      categoriesUpdatedWarning();
    } else {
      updateAnimalCategories();
    }
  };

  return (
    <CFBox>
      {loading && <CFLoading local />}

      {anagOpObject.id !== "" &&
        anagOpObject.countyId !== 0 &&
        (anagOpObject.selectedScenario === "Baseline" ||
          anagOpObject.selectedScenario === "") && (
          <CFBox>
            <CFStep
              stepLabel="Select Animal Categories"
              stepNumber="2"
              helpText={
                <CFBox
                  sx={{ overflow: "auto", height: "600px", maxHeight: "60vh" }}
                >
                  <div>
                    <p>
                      The Animal Categories dropdown menus allow users to define
                      any animals involved in their greenhouse gas accounting.
                      Each specific animal type is housed within a category:
                      Cattle, Poultry, Swine, Other Ruminants, and Other
                      Non-Ruminants. To select an animal type, click on the
                      animal category (e.g., Cattle) to expand the list. Then,
                      use the checkboxes to select the animal type(s) to include
                      in the assessment.{" "}
                      <b>
                        Selections made for animal categories cannot be changed
                        once management has been entered.
                      </b>
                    </p>
                    <br />
                    <p>
                      <b>Note:</b>For dairy cattle (Dairy-Heifer Replacements,
                      Dairy-Dry Cows, and Dairy-Lactating Cows), users also have
                      the option to include up to 3 <b>groups</b> within each of
                      these categories. Groups are defined as animals within the
                      same category that are managed differently in terms of
                      what they&apos;re fed, where they&apos;re housed, or how
                      their manure is handled. For instance, if a user has 85
                      dairy-heifer replacements that spent the majority of their
                      time in pasture and 32 that are housed primarily in a
                      covered barn, these should be considered two separate
                      groups within the same animal category.{" "}
                      <b>Groups are only available for dairy cattle.</b>
                    </p>
                    <br />
                    Refer to the table below for descriptions of each animal
                    type:
                    <style>{animalAgTableStyle}</style>
                    <table>
                      <tr>
                        <td>
                          <b>Animal Category</b>
                        </td>
                        <td>
                          <b>Animal Type</b>
                        </td>
                        <td>
                          <b>Description</b>
                        </td>
                      </tr>
                      <tr>
                        <td>Cattle</td>
                        <td>Dairy-Heifer Replacements</td>
                        <td>
                          These are immature cows prior to their first calving
                          and lactation period which are being raised to replace
                          the mature, lactating cows currently in the herd.
                        </td>
                      </tr>
                      <tr>
                        <td aria-label="no text" />
                        <td>Dairy-Dry Cows</td>
                        <td>
                          Mature cows not producing milk during the recovery
                          period between lactation and calving fall in this
                          category
                        </td>
                      </tr>
                      <tr>
                        <td aria-label="no text" />
                        <td>Dairy-Lactating Cows</td>
                        <td>
                          Lactating bovines from which the milk produced is
                          intended for human consumption, or is fed to calves
                          and replacement dairy heifers.
                        </td>
                      </tr>
                      <tr>
                        <td aria-label="no text" />
                        <td>Grazing Cow-Calf Pairs</td>
                        <td>
                          Previously categorized as Beef-Mature Cows/Cow-Calf,
                          these are sexually mature female bovines that have
                          calved at least once. Cow-calf pairs with suckling or
                          weaned calves in a breeding herd fall into this
                          category.
                        </td>
                      </tr>
                      <tr>
                        <td aria-label="no text" />
                        <td>Grazing Stockers</td>
                        <td>
                          Weaned bovines (castrated if male) fed high-roughage
                          diets (including grass hay and on pasture/rangeland)
                          before going into a feedlot.
                        </td>
                      </tr>
                      <tr>
                        <td aria-label="no text" />
                        <td>Grazing Bulls</td>
                        <td>
                          Mature bovine males of breeding age fall in this
                          category.
                        </td>
                      </tr>
                      <tr>
                        <td aria-label="no text" />
                        <td>Feedlot Cattle</td>
                        <td>
                          These are steers and heifers, and mature cattle fed
                          concentrates in a feedlot, usually until market weight
                          is reached.
                        </td>
                      </tr>
                      <tr>
                        <td>Poultry</td>
                        <td>Broilers</td>
                        <td>
                          Chickens, sometimes called “fryers”, reared primarily
                          for meat.
                        </td>
                      </tr>
                      <tr>
                        <td aria-label="no text" />
                        <td>Ducks</td>
                        <td>Ducks reared for eggs or meat.</td>
                      </tr>
                      <tr>
                        <td aria-label="no text" />
                        <td>Laying Hens</td>
                        <td>Chickens reared to lay eggs</td>
                      </tr>
                      <tr>
                        <td aria-label="no text" />
                        <td>Turkeys</td>
                        <td>Turkeys reared for meat.</td>
                      </tr>
                      <tr>
                        <td>Swine</td>
                        <td>Growing Swine</td>
                        <td>
                          Growing swine includes grow-finish pigs, male and
                          female pigs being fed concentrate after nursery and
                          before market. Growing swine&apos;s function is to
                          grow quickly and efficiently before market.
                        </td>
                      </tr>
                      <tr>
                        <td aria-label="no text" />
                        <td>Breeding Swine</td>
                        <td>
                          Includes gestating sows, lactating sows, and weaning
                          pigs; these are pigs raised to produce large litters
                          of piglets
                        </td>
                      </tr>
                      <tr>
                        <td>Other Ruminants</td>
                        <td>Sheep</td>
                        <td>Feeder or flock sheep</td>
                      </tr>
                      <tr>
                        <td aria-label="no text" />
                        <td>American Bison</td>
                        <td>American “buffalo” raised for meat.</td>
                      </tr>
                      <tr>
                        <td aria-label="no text" />
                        <td>Goats</td>
                        <td>Goats raised for meat or milk production.</td>
                      </tr>
                      <tr>
                        <td aria-label="no text" />
                        <td>Llama & Alpaca</td>
                        <td>
                          Camelids raised for fiber or as pack/guardian animals
                        </td>
                      </tr>
                      <tr>
                        <td aria-label="no text" />
                        <td>Deer</td>
                        <td>Cervids raised for meat or other commodity</td>
                      </tr>
                      <tr>
                        <td>Other Non-Ruminants</td>
                        <td>Rabbits</td>
                        <td>
                          Domestic rabbits raised or bred as livestock for meat,
                          fur, or wool
                        </td>
                      </tr>
                      <tr>
                        <td aria-label="no text" />
                        <td>Horses</td>
                        <td>
                          Includes any horses raised on the operation, whether
                          for field work, transport, or other tasks
                        </td>
                      </tr>
                      <tr>
                        <td aria-label="no text" />
                        <td>Mules/Asses</td>
                        <td>
                          Includes any mules, asses, or donkeys raised on the
                          operation, whether for field work, transport, or other
                          tasks
                        </td>
                      </tr>
                      <tr>
                        <td aria-label="no text" />
                        <td>Other Fur Bearing</td>
                        <td>
                          Includes animals which bear fur of marketable value
                          such as foxes, minks, squirrels, etc.
                        </td>
                      </tr>
                    </table>
                  </div>
                </CFBox>
              }
            />

            <CFBox
              component="form"
              onSubmit={submitAnimalCategories}
              autoComplete="off"
              sx={{
                marginLeft: "1em",
                marginTop: "1em",
                opacity:
                  anagOpObject.selectedScenario !== "Baseline" &&
                  anagOpObject.selectedScenario !== ""
                    ? 0.5
                    : 1,
                pointerEvents:
                  anagOpObject.selectedScenario !== "Baseline" &&
                  anagOpObject.selectedScenario !== ""
                    ? "none"
                    : "auto",
              }}
            >
              <Grid
                flexDirection="row"
                justifyContent="start"
                alignItems="start"
                container
              >
                <Grid
                  container
                  flexDirection="row"
                  alignItems="start"
                  item
                  xs={12}
                  sm={12}
                  md={12}
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <CFTypography
                      sx={{
                        fontSize: "14px",
                        marginBottom: "2em",
                        marginLeft: "2em",
                      }}
                      fontWeight="regular"
                    >
                      Please use the checkboxes below to select your animal
                      category or categories to include in your assessment.
                    </CFTypography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  flexDirection="column"
                  alignItems="start"
                  item
                  xs={12}
                  sm={12}
                  md={12}
                >
                  <CFBox sx={{ marginLeft: "1em", width: "30em" }}>
                    <Accordion
                      expanded={expandedCattle === "Cattle"}
                      onChange={expandToggleCattle("Cattle")}
                    >
                      <ATAccordionSummary>Cattle</ATAccordionSummary>
                      <AccordionDetails>
                        <FormControl
                          component="fieldset"
                          sx={{ marginLeft: "1em" }}
                        >
                          <FormGroup>
                            {anagCatArr
                              .filter(
                                (cat) => cat.id <= 21262 && cat.id >= 21247
                              )
                              .map((cat) => (
                                <CFBox key={cat.id} style={{ display: "flex" }}>
                                  <CFCheckbox
                                    checked={animalTypeSel[cat.id]}
                                    onChange={handleSelectionChange}
                                    label={getCatLabel(cat)}
                                    key={cat.id}
                                    name={cat.id.toString()}
                                  />

                                  {cat.id === 21260 && (
                                    <CFInput
                                      sx={{
                                        fieldset: {
                                          borderColor: info.dullFocus,
                                          borderStyle: "solid",
                                          borderWidth: "2px",
                                        },
                                      }}
                                      // label="- How many groups?"
                                      type="number"
                                      // defaultValue="1"
                                      InputProps={{
                                        inputProps: {
                                          min: 1,
                                          max: 3,
                                        },
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            {" "}
                                            <CFTypography
                                              sx={{
                                                fontSize: "1.2em",
                                                color: info.dullFocus,
                                              }}
                                            >
                                              {dairyHeiferGroupCount === 1
                                                ? "group"
                                                : "groups"}
                                            </CFTypography>
                                          </InputAdornment>
                                        ),
                                      }}
                                      color="primary"
                                      id="idDairyHeiferGroupCount"
                                      name="dairyHeiferGroupCount"
                                      value={dairyHeiferGroupCount}
                                      onChange={handleDairyHeiferGroupCount}
                                      placeholder=""
                                    />
                                  )}
                                  {cat.id === 21261 && (
                                    <CFInput
                                      sx={{
                                        fieldset: {
                                          borderColor: info.dullFocus,
                                          borderStyle: "solid",
                                          borderWidth: "2px",
                                        },
                                      }}
                                      // defaultValue="1"
                                      InputProps={{
                                        inputProps: { min: 1, max: 3 },
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            {" "}
                                            <CFTypography
                                              sx={{
                                                fontSize: "1.2em",
                                                color: info.dullFocus,
                                              }}
                                            >
                                              {dairyDryGroupCount === 1
                                                ? "group"
                                                : "groups"}
                                            </CFTypography>
                                          </InputAdornment>
                                        ),
                                      }}
                                      type="number"
                                      color="primary"
                                      id="idDairyDryGroupCount"
                                      name="dairyDryGroupCount"
                                      value={dairyDryGroupCount}
                                      onChange={handleDairyDryGroupCount}
                                      placeholder=""
                                    />
                                  )}
                                  {cat.id === 21262 && (
                                    <CFInput
                                      sx={{
                                        fieldset: {
                                          borderColor: info.dullFocus,
                                          borderStyle: "solid",
                                          borderWidth: "2px",
                                        },
                                      }}
                                      // defaultValue="1"
                                      InputProps={{
                                        inputProps: { min: 1, max: 3 },
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <CFTypography
                                              sx={{
                                                fontSize: "1.2em",
                                                color: info.dullFocus,
                                              }}
                                            >
                                              {dairyLactGroupCount === 1
                                                ? "group"
                                                : "groups"}
                                            </CFTypography>
                                          </InputAdornment>
                                        ),
                                      }}
                                      type="number"
                                      color="primary"
                                      id="idDairyLactGroupCount"
                                      name="dairyLactGroupCount"
                                      value={dairyLactGroupCount}
                                      onChange={handleDairyLactGroupCount}
                                      placeholder=""
                                    />
                                  )}
                                </CFBox>
                              ))}
                          </FormGroup>
                        </FormControl>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion
                      expanded={expandedPoultry === "Poultry"}
                      onChange={expandTogglePoultry("Poultry")}
                    >
                      <ATAccordionSummary>Poultry</ATAccordionSummary>
                      <AccordionDetails>
                        <FormControl component="fieldset">
                          <FormGroup>
                            {anagCatArr
                              .filter(
                                (cat) => cat.id <= 21275 && cat.id >= 21271
                              )
                              .map((cat) => (
                                <CFCheckbox
                                  sx={{ borderColor: "red" }}
                                  checked={animalTypeSel[cat.id]}
                                  onChange={handleSelectionChange}
                                  label={cat.name}
                                  key={cat.id}
                                  name={cat.id.toString()}
                                />
                              ))}
                          </FormGroup>
                        </FormControl>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      expanded={expandedSwine === "Swine"}
                      onChange={expandToggleSwine("Swine")}
                    >
                      <ATAccordionSummary>Swine</ATAccordionSummary>
                      <AccordionDetails>
                        <FormControl component="fieldset">
                          <FormGroup>
                            {anagCatArr
                              .filter(
                                (cat) => cat.id <= 21284 && cat.id >= 21283
                              )
                              .map((cat) => (
                                <CFCheckbox
                                  checked={animalTypeSel[cat.id]}
                                  onChange={handleSelectionChange}
                                  label={cat.name}
                                  key={cat.id}
                                  name={cat.id.toString()}
                                />
                              ))}
                          </FormGroup>
                        </FormControl>{" "}
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      expanded={expandedOtherRuminants === "Other Ruminants"}
                      onChange={expandToggleOtherRuminants("Other Ruminants")}
                    >
                      <ATAccordionSummary>Other Ruminants</ATAccordionSummary>
                      <AccordionDetails>
                        <FormControl component="fieldset">
                          <FormGroup>
                            {anagCatArr
                              .filter(
                                (cat) =>
                                  (cat.id <= 21268 && cat.id >= 21267) ||
                                  (cat.id <= 21281 && cat.id >= 21280) ||
                                  (cat.id <= 21291 && cat.id >= 21290)
                              )
                              .map((cat) => (
                                <CFCheckbox
                                  checked={animalTypeSel[cat.id]}
                                  onChange={handleSelectionChange}
                                  label={cat.name}
                                  key={cat.id}
                                  name={cat.id.toString()}
                                />
                              ))}
                          </FormGroup>
                        </FormControl>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion
                      expanded={
                        expandedOtherNonRuminants === "Other Non-Ruminants"
                      }
                      onChange={expandToggleOtherNonRuminants(
                        "Other Non-Ruminants"
                      )}
                    >
                      <ATAccordionSummary>
                        Other Non-Ruminants
                      </ATAccordionSummary>
                      <AccordionDetails>
                        <FormControl component="fieldset">
                          <FormGroup>
                            {anagCatArr
                              .filter(
                                (cat) => cat.id <= 21298 && cat.id >= 21295
                              )
                              .map((cat) => (
                                <CFCheckbox
                                  checked={animalTypeSel[cat.id]}
                                  onChange={handleSelectionChange}
                                  label={cat.name}
                                  key={cat.id}
                                  name={cat.id.toString()}
                                />
                              ))}
                          </FormGroup>
                        </FormControl>
                      </AccordionDetails>
                    </Accordion>
                  </CFBox>
                </Grid>
              </Grid>
              <Grid container py={2} px={2} justifyContent="space-around">
                <Grid item xs={12} sm={12} md={12}>
                  <CFButton
                    size="large"
                    color="progress"
                    variant="gradient"
                    type="submit"
                    sx={{
                      float: "right",
                      textTransform: "none",
                      marginLeft: "1em",
                      marginTop: "1em",
                    }}
                  >
                    Save
                  </CFButton>
                </Grid>
              </Grid>
            </CFBox>
            <CFSnackbar
              icon="saveSuccess"
              open={snackbarOpen}
              close={() => setSnackbarOpen(false)}
              title="Animal Categories Saved"
            />
            {localPopupState && localPopupState.showPopup && (
              <CFDialog
                {...localPopupState}
                updatePopup={(e) => updateLocalPopup(e)}
              />
            )}
          </CFBox>
        )}
    </CFBox>
  );
}
export default AnimalTypes;
