import { Input } from "@mui/base";
import { Close } from "@mui/icons-material";
import { Dialog, Grid, IconButton, Tooltip } from "@mui/material";
import { fetchUser } from "actions/user";
import colors from "assets/theme/base/colors";
import CFBox from "components/CFBox";
import CFButton from "components/CFButton";
import CFInput from "components/CFInput";
import CFLoading from "components/CFLoading";
import CFarmPage from "components/CFarmParts/CFarmPage";
import CFManagementSelectionCard from "components/CroplandsComponents/CFManagementSelectionCard/CFManagementSelectionCard";
import {
  GET_API_STATUS,
  TRIGGER_API_CHECK,
  UPLOAD_API_FILE,
  GENERATE_NEW_API_KEY,
} from "constants/api";

import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import user from "store/reducers/user";
import { post } from "utils/api";

export default function ApiAccessPage() {
  const fileuploadref = useRef();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user);

  const [loading, setloading] = useState(false);

  const [email, setemail] = useState("");
  const [webhook, setwebhook] = useState("");
  const [file, setfile] = useState("");

  const [returnMessage, setreturnMessage] = useState("");
  const [apiStatusMessage, setapiStatusMessage] = useState(
    "Fetching API Status"
  );
  const handleClose = () => {
    setreturnMessage("");
  };

  const initiateApiCheck = () => {
    dispatch(fetchUser(currentUser));
    fetch(TRIGGER_API_CHECK, {
      method: "GET",
    }).catch((e) => console.log("Trigger API check failed", e));
  };

  let count = 0;
  useEffect(() => {
    initiateApiCheck();

    const interval = setInterval(() => {
      count += 1;
      if (count % 6 === 0) {
        count = 0;
        initiateApiCheck();
      }
      fetch(GET_API_STATUS, { method: "GET" }).then((res) =>
        res
          .text()
          .then((mes) => setapiStatusMessage(mes))
          .catch((e) => console.log("Get API Status failed", e))
      );
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  const uploadAPIFile = () => {
    console.log(email, webhook, file, currentUser.apiKey);
    const fm = new FormData();
    fm.append("email", email);
    fm.append("url", webhook);
    fm.append("apikey", currentUser.apiKey);
    fm.append("Files", file);

    setloading(true);
    fetch(UPLOAD_API_FILE, {
      method: "POST",
      body: fm,
    })
      .then((ret) =>
        ret.text().then((data) => {
          setreturnMessage(data);
          if (data.toLowerCase().indexOf("error") === -1) {
            setfile("");
            fileuploadref.current.lastChild.value = "";
          }
        })
      )
      .finally(() => {
        setloading(false);
        initiateApiCheck();
      });
  };

  const generateNewKey = () => {
    setloading(true);
    post(GENERATE_NEW_API_KEY, currentUser.id)
      .then((data) => {
        dispatch(fetchUser(currentUser));
        if (data.status !== "Success") {
          console.log(data.message);
        }
      })
      .finally(() => {
        setloading(false);
      });
  };

  return (
    <CFarmPage bgImage="" pageName="">
      {loading && <CFLoading />}
      <Grid container>
        <Grid container item xs={12}>
          <Grid item xs={4}>
            User
          </Grid>
          <Grid item xs={8}>
            {currentUser.emailId}
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={4}>
            API Key
          </Grid>
          <Grid item xs={4}>
            {currentUser.apiKey}
          </Grid>
          <Grid item xs={4}>
            <CFButton onClick={generateNewKey}>Generate New Key</CFButton>
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={4}>
            Daily run limit
          </Grid>
          <Grid item xs={8}>
            {currentUser.apiLimit}
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={4}>
            Limit remaining for today
          </Grid>
          <Grid item xs={8}>
            {currentUser.apiLimitRemaining}
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={4}>
            Purchased API runs
          </Grid>
          <Grid item xs={8}>
            {currentUser.purchasedAPIRuns}
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={12}
          sx={{ border: "1px solid", padding: "1em" }}
        >
          Attention: COMETFarm has undergone a number of changes in 2024. This
          means that API files that worked with the old API may not work with
          the new API. Please check that all pratices and implementations in the
          API files are present in the new COMETFarm.
        </Grid>
        <Grid
          item
          container
          xs={12}
          sx={{ border: "1px solid", padding: "1em" }}
        >
          <Grid item xs={12}>
            Upload Cropland API file
          </Grid>
          <Grid item xs={12}>
            {" "}
            <CFManagementSelectionCard
              label="Return Email Address"
              labelWidth={3}
              input={
                <Input
                  type="email"
                  sx={{
                    backgroundColor: colors.white.main,
                    fontSize: "1.25em",
                    paddingInline: ".3em",
                    borderRadius: ".3em",
                  }}
                  onChange={(e) => setemail(e.target.value)}
                />
              }
            />
          </Grid>
          <Grid item xs={12}>
            {" "}
            <CFManagementSelectionCard
              label="Webhook Return Url"
              labelWidth={3}
              input={
                <Input
                  type="url"
                  sx={{
                    backgroundColor: colors.white.main,
                    fontSize: "1.25em",
                    paddingInline: ".3em",
                    borderRadius: ".3em",
                  }}
                  onChange={(e) => setwebhook(e.target.value)}
                />
              }
            />
          </Grid>
          <Grid item xs={12}>
            {" "}
            <CFManagementSelectionCard
              label="File Upload"
              labelWidth={3}
              input={
                <Input
                  ref={fileuploadref}
                  type="file"
                  sx={{
                    backgroundColor: colors.white.main,
                    fontSize: "1.25em",
                    paddingInline: ".3em",
                    borderRadius: ".3em",
                  }}
                  onChange={(e) => {
                    setfile(e.target.files[0]);
                  }}
                />
              }
            />
          </Grid>
          <Grid item>
            {" "}
            <CFButton onClick={uploadAPIFile}>Submit</CFButton>{" "}
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={12}
          sx={{ border: "1px solid", padding: "1em" }}
        >
          <div dangerouslySetInnerHTML={{ __html: apiStatusMessage }} />
        </Grid>
      </Grid>
      <Dialog
        open={returnMessage !== ""}
        sx={{ padding: "3em", zIndex: 5 }}
        maxWidth="sm"
        onClose={handleClose}
      >
        <IconButton
          onClick={handleClose}
          style={{ position: "absolute", right: 0, margin: ".5em", padding: 0 }}
        >
          <Tooltip title="Close">
            <Close />
          </Tooltip>
        </IconButton>
        <CFBox sx={{ padding: "2em", width: "100%" }}>{returnMessage}</CFBox>
      </Dialog>
    </CFarmPage>
  );
}
