import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import PropTypes from "prop-types";
import CFBox from "components/CFBox";

export default function CFCheckbox({ label, icon, ...rest }) {
  return (
    <FormControlLabel
      style={{
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
      }}
      control={<Checkbox />}
      label={
        <Grid
          direction="row"
          container
          alignItems="center"
          sx={{ flexWrap: "nowrap" }}
        >
          <CFBox>
            {icon && (
              <img
                src={icon}
                alt={label}
                style={{
                  width: "2em",
                }}
              />
            )}
          </CFBox>
          <CFBox pl={1}>{label}</CFBox>
        </Grid>
      }
      {...rest}
    />
  );
}

CFCheckbox.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.node,
};

CFCheckbox.defaultProps = {
  label: "",
  icon: "",
};
