// Assets
import CroplandP from "assets/cFarmImages/Projects/CroplandP.png";
import AnimalAgP from "assets/cFarmImages/Projects/AnimalAgP.png";
import ForestryP from "assets/cFarmImages/Projects/ForestryP.png";
import AgroForestryP from "assets/cFarmImages/Projects/AgroForestryP.png";

const activityTypes = {
  14: {
    id: 14,
    code: "FOR",
    name: "Forestry",
    description: "Forestry",
    icon: ForestryP,
    message:
      "Uses empirical calculations to estimate emissions resulting from forestry management practices such as clear-cut harvesting.",
  },
  10: {
    id: 10,
    code: "AG",
    name: "Cropland, Pasture, Range, Orchards/Vineyards",
    description: "Cropland, Pasture, Range, Orchard/Vineyards Activities",
    icon: CroplandP,
    message:
      "Uses DayCent to account for soil-related emissions resulting from management practices such as planting dates, tillage, and fertilizer application. Emission estimates will only relate to soil-related emissions within the defined entity or entities (i.e., fields, pastures, vineyards, etc.).",
  },
  11: {
    id: 11,
    code: "LIVESTCK",
    name: "Livestock",
    description: "Livestock Activities",
  },
  12: {
    id: 12,
    code: "ENGY_USE",
    name: "On-Farm Energy Usage",
    description: "Energy Usage Activities",
  },
  13: {
    id: 13,
    code: "AGRO",
    name: "Agroforestry",
    description: "Agroforestry Activities",
    icon: AgroForestryP,
    message:
      "Uses empirical calculations to estimate emissions resulting from agroforestry practices such as silvopasture systems, riparian buffers, or windbreaks.",
  },
  20030: {
    id: 20030,
    code: "ENGY_WITHFUEL",
    name: "with Actual Fuel Usage",
    description: "with Actual Fuel Usage",
  },
  20031: {
    id: 20031,
    code: "ENGY_WITHOUTFUEL",
    name: "without Actual Fuel Usage",
    description: "without Actual Fuel Usage",
  },
  212320: {
    id: 212320,
    code: "AAG",
    name: "Animal Agriculture",
    description: "Animal Agriculture",
    icon: AnimalAgP,
    message:
      "Uses empirical calculations to estimate emissions resulting from livestock management practices, such as how the animals are housed or how manure is handled. Emission estimates will only relate to emissions related to livestock within the defined entity (i.e., heads of cattle, poultry, swine, etc.).",
  },
  15: {
    id: 15,
    code: "NTT",
    name: "Water Quality",
    description: "Water Quality",
  },
};

const displayedActivities = [
  activityTypes[10],
  activityTypes[212320],
  activityTypes[13],
  activityTypes[14],
];

const devDemoProjects = [
  {
    Name: "Project 1",
    Activities: [10, 212320],
    DateAccessed: new Date("may 30,2022"),
    ReportAvailable: true,
  },
  {
    Name: "Apple Keys",
    Activities: [10],
    DateAccessed: new Date("may 5,2021"),
    ReportAvailable: false,
  },
  {
    Name: "Goally",
    Activities: [10],
    DateAccessed: new Date("august 23,2020"),
    ReportAvailable: true,
  },
  {
    Name: "SIMMM",
    Activities: [212320],
    DateAccessed: new Date("april 3,2023"),
    ReportAvailable: false,
  },
  {
    Name: "INGLidiai@@",
    Activities: [10],
    DateAccessed: new Date("November 3,2022"),
    ReportAvailable: true,
  },
  {
    Name: "FireWlor",
    Activities: [10, 14, 212320],
    DateAccessed: new Date("November 3,2022"),
    ReportAvailable: false,
  },
  {
    Name: "[]09432;3ewqd",
    Activities: [14],
    DateAccessed: new Date("september 7,2022"),
    ReportAvailable: true,
  },
  {
    Name: "PPPP REe",
    Activities: [212320],
    DateAccessed: new Date("july 5,2022"),
    ReportAvailable: false,
  },
  {
    Name: "Yotta TA",
    Activities: [10],
    DateAccessed: new Date("may 13,2023"),
    ReportAvailable: false,
  },
];

export { activityTypes, devDemoProjects, displayedActivities };
