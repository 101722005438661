// React
import { useState } from "react";
import { useDispatch } from "react-redux";

// MUI
import { Divider, Grid } from "@mui/material";
import colors from "assets/theme/base/colors";

// Our Components
import CFBox from "components/CFBox";
import CFTypography from "components/CFTypography";
import CFInput from "components/CFInput";
import CFButton from "components/CFButton";
import { ADD_NEW_LOCATION, UPDATE_LOCATION } from "constants/api";
import { post } from "utils/api";
import { updateLoading } from "store/reducers/mask";
import { popupError } from "utils/generic";
import CFNumberInput from "components/CFNumberInput/CFNumberInput";
import { EDIT } from "constants/mapButtons";
import { fetchUser } from "actions/user";
import { styled } from "@mui/system";
import Input from "assets/theme/components/form/input";

export default function ConfigureParcel({
  setIsOpen,
  buttonType,
  parcelNames,
  parcelWording,
  parcel,
  geometry,
  currentUser,
  activityTypeId,
  updateLocations,
  showArea,
  toggleResetToggle,
  deleteOldMapInteractions,
  redrawMap,
}) {
  const dispatch = useDispatch();

  const [parcelName, setParcelName] = useState(parcel ? parcel.name : "");
  const [showNameError, setShowNameError] = useState(false);
  const [nameError, setNameError] = useState("");
  const [area, setArea] = useState(parcel ? parcel.area : 1);
  const [showAreaError, setShowAreaError] = useState(false);

  const handleSubmit = () => {
    let postValue = ADD_NEW_LOCATION;

    if (buttonType === EDIT) postValue = UPDATE_LOCATION;
    if (!(showAreaError && showNameError)) {
      dispatch(updateLoading({ loading: true }));
      post(postValue, {
        geometry,
        fieldName: parcelName,
        userId: currentUser.id,
        activityTypeId,
        area,
        fieldId: parcel ? parcel.id : 0,
      })
        .then((res) => {
          deleteOldMapInteractions();
          dispatch(updateLoading({ loading: false }));
          if (res.error) {
            console.log("Error obj ", res.error);
            popupError(res.error, dispatch);
            redrawMap();
          } else {
            updateLocations(res);
            dispatch(fetchUser(currentUser));
          }
          toggleResetToggle();
          setIsOpen(false);
        })
        .catch((err) => {
          popupError(
            `Error saving ${parcelWording.toLowerCase()} locations.  Please try again.`,
            dispatch
          );
          redrawMap();
          toggleResetToggle();
          console.log(err);
        });
    }
  };
  const InputAdornment = styled("div")(
    ({ theme }) => `
    margin: 8px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    grid-row: 1/3;
    color: ${colors.info.dullFocus};
  `
  );

  const onCancelClicked = () => {
    redrawMap();
    toggleResetToggle();
    setIsOpen(false);
  };

  const onNameChange = (event) => {
    const name = event.target.value;
    setParcelName(name);
    if (
      parcelNames &&
      parcelNames.includes(name) &&
      !(parcel && name === parcel.name)
    ) {
      setNameError(
        `A ${parcelWording.toLowerCase()} with that name already exists. Please enter a different name.`
      );
      setShowNameError(true);
    } else if (name.length > 16) {
      setNameError(`${parcelWording} names must be 16 or less characters.`);
      setShowNameError(true);
    } else setShowNameError(false);
    if (showArea && area <= 0) setShowAreaError(true);
  };

  const onAreaChange = (event, val) => {
    // this keeps it from automatically going to handleSubmit
    setShowAreaError(val > 1200 || val <= 0);
    setArea(val);
  };

  return (
    <Grid container direction="row">
      {/* Window Header */}
      <CFBox display="flex" alignItems="left" pl={1.5} pt={1} pb={0.5}>
        <CFTypography variant="h6">
          {parcelWording} Location Attributes
        </CFTypography>
      </CFBox>
      <Divider sx={{ width: "100%", mt: 0, mb: 2 }} />
      <Grid item xs={10.5}>
        <CFInput
          required
          label={`${parcelWording} Name`}
          placeholder={`${parcelWording} Name (required)`}
          fullWidth
          className="cfmap-input"
          value={parcelName || ""}
          onChange={(e) => onNameChange(e)}
          InputProps={{
            endAdornment: (
              <InputAdornment
                // @TODO make this smaller
                // sx={{ fontWeight: 500, fontSize: { xs: 0.9 } }}
                position="end"
              >{`${parcelName.length}/16`}</InputAdornment>
            ),
          }}
        />

        {showArea && (
          <CFNumberInput
            required
            className="cfmap-input"
            placeholder="Area"
            label="Field Size (acres)"
            value={area}
            min={1}
            max={1200}
            sx={{
              borderColor: colors.info.dullFocusTransparent,
              borderStyle: "solid",
              borderWidth: "1px",
              width: "100%",
              padding: "4px",
              marginTop: "1em",
            }}
            onChange={(e, val) => onAreaChange(e, val)}
            endAdornment={<InputAdornment>acres</InputAdornment>}
          />
        )}
        {showNameError && (
          <CFBox
            style={{
              fontSize: "0.67em",
              color: "red",
              paddingLeft: "2.1em",
            }}
          >
            {nameError}
          </CFBox>
        )}
        {showAreaError && (
          <CFBox
            style={{
              fontSize: "0.67em",
              color: "red",
              paddingLeft: "2.1em",
            }}
          >
            Area must be between 0 and 1200 acres to simulate.
          </CFBox>
        )}
      </Grid>
      {/* Action buttons */}
      <Grid container py={2} px={2} justifyContent="space-between">
        {/* Return to map page */}
        <CFButton color="secondary" onClick={onCancelClicked}>
          Cancel
        </CFButton>
        {/* Submit changes */}
        <CFButton
          color="progress"
          onClick={handleSubmit}
          disabled={showNameError || showAreaError}
        >
          Save Changes
        </CFButton>
      </Grid>
    </Grid>
  );
}
