export const POLYGON = "POLYGON";
export const RECTANGLE = "RECTANGLE";
export const CIRCLE = "CIRCLE";
export const POINT = "POINT";
export const DELETE = "DELETE";
export const EDIT = "EDIT";
export const MODIFY = "MODIFY";
export const ESRI = "ESRI";
export const FIND = "FIND";
export const HELP = "HELP";
export const SOILS = "SOILS";
export const SOILS_DOWNLOAD = "SOILS_DOWNLOAD";
